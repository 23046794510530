import React from "react";
import { Breadcrumb, BreadcrumbItem } from "@pai-ui/core/components/breadcrumb";
import SupersetPlaceholder from "../../../superset-dashboard-placeholder"

const SingleReport = ({ report, onGoBack }) => {
  console.log("inside SingleReport", report);
  return (
    <>
      <Breadcrumb style={{ marginBottom: 20 }}>
        <BreadcrumbItem
          style={{ fontSize: 16, color: "#15afd0", cursor: "pointer" }}
          onClick={onGoBack}
        >
          All Reports
        </BreadcrumbItem>
        <BreadcrumbItem style={{ fontSize: 16 }}>{report.report_name}</BreadcrumbItem>
      </Breadcrumb>

      <SupersetPlaceholder dashboardId={report.dashboard_id} />
    </>
  );
};

export default SingleReport;
