import React from "react";
import { Col } from '@pai-ui/core/components/col';
import { Row } from '@pai-ui/core/components/row';
import { ExpressionVariable } from "./common-components";
import "./../style.css";

const IncrementalFormula2 = (props) => {
  var selectedKpi = props?.allValues?.["kpi 1"] || null;

  var selectedKpi3 = props?.allValues?.["kpi 2"] || null;

  return (
    <Col span={24} className="kpi-formula-wrapper">
      <div style={{ position: "relative" }}>
        <Row>
          <span className="formula-statics">(</span>
          <ExpressionVariable
            selected={selectedKpi}
            label={selectedKpi ?? "KPI 1"}
            variantName={"Test"}
          />

          <span className="operator">-</span>
          <ExpressionVariable
            selected={selectedKpi}
            label={selectedKpi ?? "KPI 1"}
            variantName={"Control"}
          />
          <span className="formula-statics operator">)</span>
          <span className="operator">X</span>
          <ExpressionVariable
            selected={selectedKpi3}
            label={selectedKpi3 ?? "KPI 2"}
            variantName={"Overall"}
          />
        </Row>
      </div>
    </Col>
  );
};

export default IncrementalFormula2;
