import { DeleteOutlined, EditOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Input,
  Popconfirm,
  Button,
  Tabs,
  Table,
  Tag,
} from "antd";
import React from "react";
import { ThumbsDown, ThumbsUp } from "styled-icons/feather";
import "./review-table.css";

export const ReviewComponent = (props) => {
  const allVariants = props.allData.variants.variants;

  const approved = props.data.approved || [];

  const deleted = props.data.deleted || [];

  const deployed = props.data.deployed || [];

  const comments = props.data.comments || [];

  const allData = props.allData.content.variants
    .map((v, i) =>
      v.taglines.map((t) => ({
        ...t,
        tag: allVariants[i].name,
        variantIndex: i,
        comment:
          comments.find((c) => c.id === t.id) &&
          comments.find((c) => c.id === t.id).comment,
        approved_at:
          approved.find((a) => a.id === t.id) &&
          approved.find((a) => a.id === t.id).approved_at,
      }))
    )
    .flat();

  const draftItems = allData.filter(
    (d) =>
      !approved.find((a) => a.id === d.id) &&
      deleted.indexOf(d.id) === -1 &&
      deployed.indexOf(d.id) === -1
  );

  const approvedItems = allData.filter((d) =>
    approved.find((a) => a.id === d.id)
  );
  const deletedItems = allData.filter((d) => deleted.indexOf(d.id) !== -1);

  return (
    <div style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}>
      <Tabs
        tabBarStyle={{
          position: "sticky",
          top: -30,
          zIndex: 99,
          backgroundColor: "#191b20",
          paddingTop: 0,
        }}
        onChange={() => { }}
      >
        {[
          {
            label: "Draft Variants",
            key: "tab_draft",
            children: (
              <div>
                <Table
                  className="custom-table-review"
                  style={{ borderTop: 0 }}
                  size="middle"
                  bordered
                  columns={[
                    {
                      title: "Marketing Copy",
                      width: "40%",
                      dataIndex: "text",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.text}
                                onChange={(e) => {
                                  let newContentVariants = [
                                    ...props.allData.content.variants,
                                  ];
                                  newContentVariants[
                                    record.variantIndex
                                  ].taglines = newContentVariants[
                                    record.variantIndex
                                  ].taglines.map((tagline) => {
                                    if (tagline.id === record.id) {
                                      return {
                                        ...tagline,
                                        text: e.target.value,
                                      };
                                    }

                                    return tagline;
                                  });
                                  props.updateParentEntry(
                                    "content",
                                    "variants",
                                    newContentVariants
                                  );
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <>
                                <p style={{ margin: 5 }}>{record.text}</p>
                                {record.tag.split(",").map((tone, index) => (
                                  <Tag key={index}>{tone.trim()}</Tag>
                                ))}
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Comments",
                      width: "40%",
                      dataIndex: "comment",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.comment}
                                onChange={(e) => {
                                  let newComments = comments.map((c) => {
                                    if (c.id === record.id) {
                                      return {
                                        ...c,
                                        comment: e.target.value,
                                      };
                                    }

                                    return c;
                                  });
                                  if (
                                    !newComments.find((c) => c.id === record)
                                  ) {
                                    newComments.push({
                                      id: record.id,
                                      comment: e.target.value,
                                    });
                                  }
                                  props.updateEntry("comments", newComments);
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <p style={{ margin: 5 }}>{record.comment}</p>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Actions",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {props.data.editing !== record.id && (
                              <>
                                <ThumbsUp
                                  width={22}
                                  height={22}
                                  color="#15afd0"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    let newUnApproved =
                                      props.data.unapproved || [];
                                    newUnApproved = newUnApproved.filter(
                                      (u) => u !== record.id
                                    );
                                    let newApproved = props.data.approved || [];
                                    newApproved.push({
                                      id: record.id,
                                      approved_at:
                                        new Date().toLocaleDateString("en-US"),
                                    });
                                    props.updateEntries([
                                      {
                                        key: "approved",
                                        value: newApproved,
                                      },
                                      {
                                        key: "unapproved",
                                        value: newUnApproved,
                                      },
                                    ]);
                                  }}
                                />
                                <ThumbsDown
                                  width={22}
                                  height={22}
                                  style={{ marginRight: 10 }}
                                  color="#15afd0"
                                  fill={
                                    (props.data.unapproved || []).indexOf(
                                      record.id
                                    ) !== -1
                                      ? "#15afd0"
                                      : "transparent"
                                  }
                                  onClick={() => {
                                    let newUnApproved =
                                      props.data.unapproved || [];
                                    newUnApproved.push(record.id);
                                    props.updateEntry(
                                      "unapproved",
                                      newUnApproved
                                    );
                                  }}
                                />
                                <Popconfirm
                                  title="Are you sure you want to delete this item?"
                                  onConfirm={() => {
                                    props.updateEntries([
                                      {
                                        key: "unapproved",
                                        value: (
                                          props.data.unapproved || []
                                        ).filter((a) => a !== record.id),
                                      },
                                      {
                                        key: "approved",
                                        value: (
                                          props.data.approved || []
                                        ).filter((a) => a.id !== record.id),
                                      },
                                      {
                                        key: "deleted",
                                        value: [
                                          ...(props.data.deleted || []),
                                          record.id,
                                        ],
                                      },
                                    ]);
                                  }}
                                >
                                  <DeleteOutlined
                                    style={{
                                      marginRight: 10,
                                      fontSize: "20px",
                                      color: "#15afd0",
                                    }}
                                  />
                                </Popconfirm>
                                <EditOutlined
                                  onClick={() => {
                                    props.updateEntry("editing", record.id);
                                  }}
                                  style={{
                                    marginRight: 10,
                                    fontSize: "20px",
                                    color: "#15afd0",
                                  }}
                                />
                              </>
                            )}
                            {props.data.editing === record.id && (
                              <>
                                <Button
                                  size="small"
                                  type="primary"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={draftItems}
                ></Table>
              </div>
            ),
          },
          {
            label: "Approved Variants",
            key: "tab_approved",
            children: (
              <div>
                <Table
                  className="custom-table-review"
                  style={{ borderTop: 0 }}
                  size="middle"
                  bordered
                  columns={[
                    {
                      title: "Marketing Copy",
                      width: "40%",
                      dataIndex: "text",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.text}
                                onChange={(e) => {
                                  let newContentVariants = [
                                    ...props.allData.content.variants,
                                  ];
                                  newContentVariants[
                                    record.variantIndex
                                  ].taglines = newContentVariants[
                                    record.variantIndex
                                  ].taglines.map((tagline) => {
                                    if (tagline.id === record.id) {
                                      return {
                                        ...tagline,
                                        text: e.target.value,
                                      };
                                    }

                                    return tagline;
                                  });
                                  props.updateParentEntry(
                                    "content",
                                    "variants",
                                    newContentVariants
                                  );
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <>
                                <p style={{ margin: 5 }}>{record.text}</p>
                                {record.tag.split(",").map((tone, index) => (
                                  <Tag key={index}>{tone.trim()}</Tag>
                                ))}
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Comments",
                      width: "40%",
                      dataIndex: "comment",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.comment}
                                onChange={(e) => {
                                  let newComments = comments.map((c) => {
                                    if (c.id === record.id) {
                                      return {
                                        ...c,
                                        comment: e.target.value,
                                      };
                                    }

                                    return c;
                                  });
                                  if (
                                    !newComments.find((c) => c.id === record)
                                  ) {
                                    newComments.push({
                                      id: record.id,
                                      comment: e.target.value,
                                    });
                                  }
                                  props.updateEntry("comments", newComments);
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <p style={{ margin: 5 }}>{record.comment}</p>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Actions",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {props.data.editing !== record.id && (
                              <>
                                <UndoOutlined
                                  width={22}
                                  height={22}
                                  style={{
                                    marginRight: 10,
                                    fontSize: "20px",
                                    color: "#15afd0",
                                  }}
                                  onClick={() => {
                                    let approved = props.data.approved || [];

                                    approved = approved.filter(
                                      (a) => a.id !== record.id
                                    );
                                    props.updateEntry("approved", approved);
                                  }}
                                />
                                <Popconfirm
                                  title="Are you sure you want to delete this item?"
                                  onConfirm={() => {
                                    props.updateEntries([
                                      {
                                        key: "unapproved",
                                        value: (
                                          props.data.unapproved || []
                                        ).filter((a) => a !== record.id),
                                      },
                                      {
                                        key: "approved",
                                        value: (
                                          props.data.approved || []
                                        ).filter((a) => a.id !== record.id),
                                      },
                                      {
                                        key: "deleted",
                                        value: [
                                          ...(props.data.deleted || []),
                                          record.id,
                                        ],
                                      },
                                    ]);
                                  }}
                                >
                                  <DeleteOutlined
                                    style={{
                                      marginRight: 10,
                                      fontSize: "20px",
                                      color: "#15afd0",
                                    }}
                                  />
                                </Popconfirm>
                                <EditOutlined
                                  onClick={() => {
                                    props.updateEntry("editing", record.id);
                                  }}
                                  style={{
                                    marginRight: 10,
                                    fontSize: "20px",
                                    color: "#15afd0",
                                  }}
                                />
                              </>
                            )}
                            {props.data.editing === record.id && (
                              <>
                                <Button
                                  size="small"
                                  type="primary"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Approved at",
                      align: "center",
                      dataIndex: "approved_at",
                    },
                  ]}
                  dataSource={approvedItems}
                ></Table>
              </div>
            ),
          },
          {
            label: "Deactivated Variants",
            key: "tab_deactivated",
            children: (
              <div>
                <Table
                  className="custom-table-review"
                  style={{ borderTop: 0 }}
                  size="middle"
                  bordered
                  columns={[
                    {
                      title: "Marketing Copy",
                      width: "40%",
                      dataIndex: "text",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.text}
                                onChange={(e) => {
                                  let newContentVariants = [
                                    ...props.allData.content.variants,
                                  ];
                                  newContentVariants[
                                    record.variantIndex
                                  ].taglines = newContentVariants[
                                    record.variantIndex
                                  ].taglines.map((tagline) => {
                                    if (tagline.id === record.id) {
                                      return {
                                        ...tagline,
                                        text: e.target.value,
                                      };
                                    }

                                    return tagline;
                                  });
                                  props.updateParentEntry(
                                    "content",
                                    "variants",
                                    newContentVariants
                                  );
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <>
                                <p style={{ margin: 5 }}>{record.text}</p>
                                {record.tag.split(",").map((tone, index) => (
                                  <Tag key={index}>{tone.trim()}</Tag>
                                ))}
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Comments",
                      width: "40%",
                      dataIndex: "comments",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            {props.data.editing === record.id && (
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={record.comment}
                                onChange={(e) => {
                                  let newComments = comments.map((c) => {
                                    if (c.id === record.id) {
                                      return {
                                        ...c,
                                        comment: e.target.value,
                                      };
                                    }

                                    return c;
                                  });
                                  if (
                                    !newComments.find((c) => c.id === record)
                                  ) {
                                    newComments.push({
                                      id: record.id,
                                      comment: e.target.value,
                                    });
                                  }
                                  props.updateEntry("comments", newComments);
                                }}
                              />
                            )}
                            {props.data.editing !== record.id && (
                              <p style={{ margin: 5 }}>{record.comment}</p>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Actions",
                      align: "center",
                      render: (text, record) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {props.data.editing !== record.id && (
                              <>
                                <UndoOutlined
                                  width={22}
                                  height={22}
                                  style={{
                                    marginRight: 10,
                                    fontSize: "20px",
                                    color: "#15afd0",
                                  }}
                                  onClick={() => {
                                    let deleted = props.data.deleted || [];

                                    deleted = deleted.filter(
                                      (a) => a !== record.id
                                    );
                                    props.updateEntry("deleted", deleted);
                                  }}
                                />
                                <EditOutlined
                                  onClick={() => {
                                    props.updateEntry("editing", record.id);
                                  }}
                                  style={{
                                    marginRight: 10,
                                    fontSize: "20px",
                                    color: "#15afd0",
                                  }}
                                />
                              </>
                            )}
                            {props.data.editing === record.id && (
                              <>
                                <Button
                                  size="small"
                                  type="primary"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    props.updateEntry("editing", "");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            )}
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={deletedItems}
                ></Table>
              </div>
            ),
          },
        ].map((tab) => (
          <Tabs.TabPane key={tab.key} tab={tab.label}>
            {tab.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
