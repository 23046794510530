import React from "react";
import { Form } from "antd";
import {
  ZsCol,
  ZsRow,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

export const FormItemRow = ({
  text,
  span,
  offset,
  children,
  itemSpan,
  itemName,
  formItemReqd,
  rules
}) => {
  if(span){
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzz");
    console.log({
      text,
      span,
      offset,
      children,
      itemSpan,
      itemName,
      formItemReqd,
    });
  }
  return (
    <ZsRow style={{ paddingRight: "10px" }}>
      <ZsCol span={span != undefined ? span : 5}  >
        <div >{text}</div>
      </ZsCol>
      <ZsCol offset={offset} span={itemSpan != undefined ? itemSpan : 19}>
        <Form.Item rules={rules} name={itemName}>{children}</Form.Item>
      </ZsCol>
    </ZsRow>
  );
};
