import React from "react";
import { Collapse, CollapsePanel } from "@pai-ui/core/components/collapse";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Divider } from "@pai-ui/core/components/divider";
import { Button } from "@pai-ui/core/components/button";
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig.js";

export const ReviewComponent = (props) => {
  const { config } = useSubjectLineFormConfig();

  return (
    <>
      <div
        style={{
          height: "85vh",
          overflowY: "hidden",
          overflowX: "hidden",
          paddingBottom: "15px",
        }}
      >
        <Collapse accordion={true} defaultActiveKey={["1"]}>
          <CollapsePanel header="Summary" key="1">
            <div style={{ padding: "20px" }}>
              {Object.entries(config)?.map((dataArray, index) => {
                return (
                  typeof dataArray?.[1] !== "object" &&
                  ["simpleLayout", "selectedVariant"].indexOf(
                    dataArray?.[0]
                  ) === -1 && (
                    <div style={{ padding: "0px" }}>
                      {index !== 0 && (
                        <Divider
                          style={{ padding: "0px", margin: "10px" }}
                          orientation="left"
                        />
                      )}
                      <Row>
                        <Col span={10} style={{ opacity: "0.7" }}>
                          {dataArray?.[0]
                            ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                            .charAt(0)
                            .toUpperCase() +
                            dataArray?.[0]
                              ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                              .slice(1)}
                          :
                        </Col>
                        <Col span={12} style={{ fontSize: "14px" }}>
                          {dataArray?.[1]}
                        </Col>
                      </Row>
                    </div>
                  )
                );
              })}
            </div>
          </CollapsePanel>
        </Collapse>
      </div>
      <div
        style={{
          height: 70,
          flexShrink: 0,
          display: "block",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderTop: "1px solid rgba(255,255,255,0.25)",
          borderLeft: "1px solid rgb(24 27 32)",
          margin: "0 -25px",
          background: "#111419",
        }}
      >
        <div
          style={{
            padding: "17px 25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              props.addDraftExperiment();
              props.setCurrentStep(props.currentStep - 1);
            }}
            type="ghost"
          >
            Previous
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              console.log("config", config);
              // form.validateFields().then(() => {
              //   props?.addDraftExperiment();
              //   props.setCurrentStep(props.currentStep + 1);
              // });

              // props?.addDraftExperiment();
              //   props.setCurrentStep(props.currentStep + 1);

              // #TODO: Publish method to be implemented.
            }}
            disabled={true} // need to enable post teams confirmation.
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  );
};
