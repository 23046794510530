import React from "react";

export const AspectRatio = (props) => {
  return (
    <div
      style={{
        paddingBottom: `${(props.height * 100) / props.width}%`,
        position: "relative",
      }}
    >
      <div
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        {props.children}
      </div>
    </div>
  );
};
