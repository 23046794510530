import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import { Link } from "react-router-dom";
import { WaveLoading } from "styled-spinkit";
import DataServiceHelper from "@xai/pages/data-integrations/service-helper";
import { UserContext } from "@xai/providers/with-auth";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";

const TileList = ({ title, children }) => [
    <>
        <h3 style={{ color: "#FFF", margin: "0 0 15px 0" }} key={"key"}>{title}</h3>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", marginBottom: 20 }}>
            {children}
        </div>
    </>
]

class Tile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modal: false
        }
    }
    //`${process.env.REACT_APP_S3_BUCKET}/tnl/${subRoute}${item.img}`
    render() {
        const { name, title, png, bordered, link, width } = this.props;
        console.log(this.props)
        return [
            <Link to={link || `../../tests`} key={"key"}>
                <div key={"key"} className="hover-tile" style={{ width: 160, border: bordered ? "1px solid #1890ff2e" : "", height: 160, maxWidth: 160, maxHeight: 160, marginRight: 15, marginBottom: 15, flexShrink: 0, background: "rgba(255,255,255,0.05)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {png &&
                        <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/data/${name}.png`} width={width || "125"} />
                    }
                    {!png && <SVG src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/data/${name}.svg`} width="75" className="blue" />}
                    {title &&
                        <p style={{ marginBottom: 0, textAlign: "center", marginTop: 2, color: "#FFF", fontSize: "14px", letterSpacing: "0px" }}>{title}</p>
                    }
                </div>
            </Link>
        ]
    }
}

const Integrations = () => {

    const [data, setData] = useState()
    const [load, setload] = useState(true)
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

    const { token } = React.useContext(UserContext);
    const service = new DataServiceHelper(token);

    const loaddata = async () => {
        await service.getIntegrations()
            .then(async (res) => {
                console.log('getIntegrations Data', res);
                setData(res);
                // setLineChartData(null);
                setload(false);
            })
            .catch((err) => {
                console.log('getIntegrations Error', err);
            });
    }
    useEffect(() => {
        loaddata();
    }, []);
    useEffect(() => {
        if (data && Object.keys(data).length > 0)
            setload(false)
    }, [data]);
    return (load ? (<WaveLoading color="#15afd0" />) : (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", flexWrap: "wrap", maxWidth: "879px", width: "100%", marginLeft: "auto", marginRight: "auto", minHeight: "calc(111vh - 48px)" }}
            className={'opacity-0-55'}
        >
            <GenericModalWithResultComponent
                isRequestModalOpen={isRequestModalOpen}
                setIsRequestModalOpen={setIsRequestModalOpen}
            />
            <TileList>


                {data.images.map(x => {
                    if (!x.png && !x.link)
                        return (<Tile name={x.name} title={x.title} width={x.width} />)
                    if (x.png && !x.link)
                        return (<Tile name={x.name} title={x.title} png width={x.width} />)
                    if (x.png && x.link)
                        return (<Tile name={x.name} title={x.title} png link={x.linkValue} width={x.width} />)
                })}

            </TileList>
        </div>
    ))
}

export default Integrations