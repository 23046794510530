import React from "react";
import { Modal } from "@pai-ui/core/components/modal";
import { Space } from "@pai-ui/core/components/space";
// import { Button } from "@pai-ui/core/components/button";
import { Result } from "@pai-ui/core/components/result";

const ConfirmNavigationPopup = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  warningTitle = "Unsaved changes will be lost",
  warningSubTitle = "Are you sure you want to continue?",
  confirmationType = "warning",
  additionalConfirmationButtons,
}) => {
  return (
    <>
      <Modal
        open={showDialog}
        onOk={confirmNavigation}
        onCancel={cancelNavigation}
        withCloseButton={true}
        withoutFooterButtons={true}
        bodyStyle={{
          // height: "55vh",
          /* overflowY: 'auto', */
          background: "#181b20",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // width={"65vw"}
      >
        {/* <h1>Unsaved Changes will be Lost.</h1>
                <h1>Are you Sure to Navigate?</h1> */}
        <Result
          status={confirmationType}
          title={warningTitle}
          subTitle={warningSubTitle}
          extra={
            <Space style={{ flex: "auto" }} size={"middle"}>
              {/* <Button type="ghost" key="close" onClick={cancelNavigation}>
                Close to continue
              </Button> */}
              {additionalConfirmationButtons && additionalConfirmationButtons()}
            </Space>
          }
        />
      </Modal>
    </>
  );
};

export default ConfirmNavigationPopup;
