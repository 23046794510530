import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Visualization,
  TwoByTwoChartConfig,
} from "@pai-ui/core/components/visualization";
import { Button } from "@pai-ui/core/components/button";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getExecutiveAtAGlanceExperimentTwoByTwoChartConfig } from "@xai/pages/executive-at-a-glance-overview/transformer";
import ExecutiveServiceHelper from "@xai/pages/executive-at-a-glance-overview/service-helper";
import { UserContext } from "@xai/providers/with-auth";

const OverviewVizChartDots = ({ filters }) => {
  // const { handleSetFavorite, onMarkFavFinishLoad } = props;

  const { token /*, format */ } = React.useContext(UserContext);
  const service = new ExecutiveServiceHelper(token);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [referenceLineArray, setReferenceLineArray] = useState([]);
  //States for Error Handling
  const [chartDataError, setChartDataError] = useState(false);

  const LoadTwoByTwoChart = async () => {
    let dataconfig = getExecutiveAtAGlanceExperimentTwoByTwoChartConfig(
      filters?.dateRange?.[0],
      filters?.dateRange?.[1]
    );
    setChartDataError(false);
    setLoading(true);
    await service
      .getExecutiveAtAGlanceTwobyTwoChart(dataconfig)
      .then(async (res) => {
        console.log("LoadTwoByTwoChart res", res);
        setReferenceLineArray(
          res?.chart_data?.map((item) => {
            return {
              value: item?.order * 200,
              month: moment(item?.time_metric).format("MMM"),
            };
          })
        );
        setChartData(res?.chart_data);
      })
      .catch((err) => {
        console.log("LoadTwoByTwoChart Error", err);
        setChartDataError(true);
      })
      .finally(() => {
        setLoading(false);
        // #TODO : Below code to be commented later during actual api integration and use the above.
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1500);
      });
  };

  useEffect(() => {
    if (filters?.dateRange?.length !== 0) {
      LoadTwoByTwoChart();
    }
  }, [filters]);

  const viewConfig = React.useMemo(() => {
    return new TwoByTwoChartConfig({
      id: "scatter-border",
      classes: {
        zoomBtn: "zoomBtn",
        zoomControls: "zoomControls",
      },
      tooltip: {
        show: false,
      },
      type: "scatter",
      scatterProps: {
        border: false,
      },
      series: {
        specificConfig: [
          {
            name: "experimentname",
            fieldName: {
              name: "experimentname",
              x: "year",
              y: "value",
              z: "dollarMagnitude",
            },
          },
        ],
      },
      referenceLines: [
        ...referenceLineArray?.map((m) => ({
          label: m.month,
          color: "transparent",
          id: m.value,

          placement: "bottom-with-gap",
          alignment: "perpendicular",
          x: m.value,
        })),
        // {
        //   color: 'grey',
        //   // id: 'zero',
        //   y: 0,
        // },
      ],
      axes: {
        x: {
          show: false,
          label: "Revenue",
        },
        y: {
          label: "% Impact",
          format: {
            value: "0.0%",
          },
        },
      },
    });
  }, []);

  if (chartDataError) {
    return (
      <LegacyNoData height={"44vh"} error={true}>
        <Button onClick={() => LoadTwoByTwoChart()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return loading ? (
    <LegacyNoData height={"44vh"} loading={true} style={{ zoom: "1.11111" }} />
  ) : (
    referenceLineArray && (
      <div>
        <Visualization
          title="Results From Experiments"
          height={"42vh"}
          viewConfig={viewConfig}
          dataConfig={{
            structureType: "processed",
          }}
          dataset={{
            data: chartData,
          }}
        />
      </div>
    )
  );
};

export default OverviewVizChartDots;
