import { DI_TOKENS } from '../constants/di';
import { ConfigService } from '../../services/config';

class DiContainer {
  entities = {};

  set(token, entity) {
    this.entities[token] = new entity();
  }

  get(token) {
    return this.entities[token];
  }
}

const diContainer = new DiContainer();

const entitiesConfig = [
  { diToken: DI_TOKENS.configService, entity: ConfigService },
];

entitiesConfig.forEach(({ diToken, entity }) => {
  diContainer.set(diToken, entity);
});


export { diContainer };
