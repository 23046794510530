import React from 'react';
import { Loading } from '@pai-ui/core/components/loading';
import { EmptyData } from '@pai-ui/core/components/empty-data';

import './style.css';

export const Table = (props) => {
  const { columns = [], getData, data = [], loading } = props;

  React.useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="configurable-ui-management-table configurable-ui-management-table__loading">
        <Loading />
      </div>  
    );
  }

  if (!loading && !data.length) {
    return (
      <div className="configurable-ui-management-table">
        <EmptyData />
      </div>
    )
  }

  return (
    <div className="configurable-ui-management-table">
      {data.map((item) => {
        return (
          <div key={item.id} className="configurable-ui-management-table__row">
            {columns.map((col) => {
              const content = col.render
                ? col.render(item)
                : item[col.fieldName];

              return (
                <div key={col.id} style={{ width: col.width }} className="configurable-ui-management-table__row__cell">
                  {content}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};