import React from "react";
import { Grid, GridItem } from "@xai/library/containers/grid";
import { UserContext } from "@xai/providers/with-auth";
import * as Comps from "./components";

const MeasureResultsDetailedViewTab = ({
  detailedViewData,
  selectedVariants,
  selectedDateRangeVal,
  loadDetailedViewTabData,
  loadDetailedViewTabCorrelationChartData,
  selectedAudience,
  endOfExportFileName,
}) => {
  const { experimentModule } = React.useContext(UserContext);

  const gridItems = {
    CorrelationChart: (
      <GridItem area="CorrelationChart">
        <Comps.CorrelationChart
          chartData={detailedViewData?.correlationChart?.data}
          loading={detailedViewData?.correlationChart?.loading}
          error={detailedViewData?.correlationChart?.error}
          selectedVariants={selectedVariants}
          loadDetailedViewTabCorrelationChartData={
            loadDetailedViewTabCorrelationChartData
          }
          selectedDateRangeVal={selectedDateRangeVal}
          selectedAudience={selectedAudience}
          endOfExportFileName={endOfExportFileName}
        />
      </GridItem>
    ),
    ParameterVariantComparisionTable: (
      <GridItem area="ParameterVariantComparisionTable">
        <Comps.ParameterVariantComparisionTable
          tableData={detailedViewData?.table?.data}
          error={detailedViewData?.table?.error}
          selectedVariants={selectedVariants}
          loadDetailedViewTabData={loadDetailedViewTabData}
          selectedDateRangeVal={selectedDateRangeVal}
          selectedAudience={selectedAudience}
        />
      </GridItem>
    ),
    ParameterVariantComparisionTable2: (
      <GridItem area="ParameterVariantComparisionTable2">
        <Comps.ParameterVariantComparisionTable2
          tableData={detailedViewData?.table?.data}
          error={detailedViewData?.table?.error}
          selectedVariants={selectedVariants}
          loadDetailedViewTabData={loadDetailedViewTabData}
          selectedDateRangeVal={selectedDateRangeVal}
          selectedAudience={selectedAudience}
          endOfExportFileName={endOfExportFileName}
        />
      </GridItem>
    ),
  };

  const gridAreas1 = ["CorrelationChart", "ParameterVariantComparisionTable"];
  const gridAreas2 = ["CorrelationChart", "ParameterVariantComparisionTable2"];

  return (
    <>
      <div>
        <Grid
          template={
            experimentModule?.selectedExperiment?.experiment_data
              ?.variant_setup?.[0]?.audience_group_name
              ? gridAreas2.map((x) => {
                  return [
                    ...Array(24)
                      .fill()
                      .map(() => x),
                  ];
                })
              : gridAreas1.map((x) => {
                  return [
                    ...Array(24)
                      .fill()
                      .map(() => x),
                  ];
                })
          }
        >
          {experimentModule?.selectedExperiment?.experiment_data
            ?.variant_setup?.[0]?.audience_group_name
            ? gridAreas2.map((x) => gridItems[x])
            : gridAreas1.map((x) => gridItems[x])}
        </Grid>
      </div>
    </>
  );
};

export default MeasureResultsDetailedViewTab;
