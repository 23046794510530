import React from "react";
import { AspectRatio } from "../aspect-ratio/AspectRatio";
import { Button } from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  ExpandOutlined,
} from "@ant-design/icons";

export const ImageCardDisplay = (props) => {
  const aspectRatio = props.aspect_ratio.split(":").map((i) => parseInt(i));

  return (
    <AspectRatio width={aspectRatio[0]} height={aspectRatio[1]}>
      <div
        onClick={() => {
          props.onClick && props.onClick();
        }}
        style={{
          height: "100%",
          backgroundImage: `url(${props.image_url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: props.radius || 20,
          backgroundPosition: "center center",
          position: "relative",
        }}
        className={props.selectOnly ? "" : "hover-tile"}
      >
        <div
          className="hover-additional-info"
          style={{
            position: "absolute",
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
            background: "rgba(0,0,0,0.05)",
            padding: 20,
            display: "flex",
            flexDirection: "column",
            borderRadius: props.radius || 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#FFF",
            }}
          >
            <Button
              shape="round"
              icon={
                props.selected ? (
                  <CheckOutlined fontSize={"24px"} style={{ color: "white" }} />
                ) : (
                  <CheckOutlined fontSize={"24px"} />
                )
              }
              type={props.selected ? "primary" : "outline"}
              style={{
                marginRight: 10,
                minWidth: 0,
                borderRadius: "50%",
                width: 32,
                padding: 4,
                border: "1px solid rgba(255,255,255,0.5)",
              }}
              onClick={(e) => {
                props.onSelect && props.onSelect();
                e.stopPropagation();
                e.preventDefault();
              }}
            ></Button>
            {!props.selectOnly && (
              <>
                <Button
                  shape="round"
                  icon={<EditOutlined />}
                  style={{
                    marginRight: 10,
                    minWidth: 0,
                    padding: "5px 8px",
                    background: "rgba(255,255,255,0.3)",
                  }}
                  onClick={(e) => {
                    props.onEdit && props.onEdit();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                ></Button>
                <Button
                  shape="round"
                  icon={<ExpandOutlined />}
                  style={{
                    marginLeft: "auto",
                    minWidth: 0,
                    padding: "5px 8px",
                    background: "rgba(255,255,255,0.3)",
                  }}
                  onClick={(e) => {
                    props.onExpand && props.onExpand();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                ></Button>
              </>
            )}
          </div>
          {!props.selectOnly && (
            <div
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                color: "#FFF",
              }}
            >
              <Button
                shape="round"
                icon={<DeleteOutlined />}
                style={{
                  marginLeft: "auto",
                  minWidth: 0,
                  padding: "5px 8px",
                  background: "rgba(255,255,255,0.3)",
                }}
                onClick={(e) => {
                  props.onDelete && props.onDelete();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              ></Button>
            </div>
          )}
        </div>
      </div>
    </AspectRatio>
  );
};
