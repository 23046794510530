import React, { useEffect, useState } from "react"; 
import PaiForm from "@xai/assets/generic-components/form/index";
import {
  SupersetFormConfig,
  SupersetFormDefaultValue,
} from "../../config.js";
import { CreateGenericFormFieldsConfig } from "@xai/assets/generic-components/form/transformer";

const RouteConfigForm = ({selectedDashboard,  ddnMaster, handleSubmit}) => {
    const [formConfig, setFormConfig] = useState(null);
    const [currReqdFormData, setcurrReqdFormData] = useState(null);

      useEffect(() => {
        let formConfig = SupersetFormConfig();
        let defaultVals = SupersetFormDefaultValue(selectedDashboard);
        console.log("before form", formConfig, ddnMaster, defaultVals);
        let config = CreateGenericFormFieldsConfig({
          config: formConfig,
          ddnMaster,
        });
        console.log("superset config", config);
        setFormConfig(config);
        setcurrReqdFormData(defaultVals);
      }, []);

      return (
        <>
          {
            formConfig ? <PaiForm
            formConfig={formConfig}
            handleSubmit={handleSubmit}
            defaultValuesObj={currReqdFormData}
            saveLabelName={'Save'}
            buttonProps={{
              style: {
                position: 'absolute',
                bottom: '10px',
                right: '12px',
              }
            }}
          />
          :
          <>Loading</>
        }
          
        </>
      );
    };
    
    export default RouteConfigForm;