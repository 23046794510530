import React from "react";

export const Columns = () => {
  return [
    {
      title: "",
      dataIndex: "control",
      key: "control",
      width: "17%",
      render: (data) => (
        <span style={{ color: "#FFF", float: "left" }}>{data != "" ? data + " :" : ""}</span>
      ),
    },
    {
      width: "30%",
      title: (
        <React.Fragment>
          <span style={{ cursor: "pointer" }}></span>
        </React.Fragment>
      ),
      dataIndex: "response",
      key: "response",
      render: (data) => <span style={{ opacity: "0.65" }}>{data}</span>,
    },
    {
      width: "20%",
      title: "",
      dataIndex: "control1",
      key: "control1",
      render: (data) => (
        <span style={{ color: "#FFF", float: "left" }}>{data != "" ? data + " :" : ""}</span>
      ),
    },
    {
      width: "30%",
      title: (
        <React.Fragment>
          <span style={{ cursor: "pointer" }}></span>
        </React.Fragment>
      ),
      dataIndex: "response1",
      key: "response1",
      render: (data) => <span style={{ opacity: "0.65" }}>{data}</span>,
    },
  ];
};

export const FetchSummaryData = (
  elements, formConfig
) => {
  console.log('aayushelements', elements, formConfig)
  if (!elements) return [];

  const data_basic_details = [
    {
      key: "2",
      control: "Experiment Name",
      response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "experiment_name1").value,

      control1: "Hypothesis",
      response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "underlying_hypothesis")[0].value
    },
    {
      key: "3",
      control: "Target Variable",
      response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "variable")[0].value,
      control1: "",
      response1: ""
    },

    {
      key: "8",
      control: "Test Duration",
      response:
        Array.isArray(elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value)
          ? elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[0].format("MMM") +
          " " +
          elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[0].format("YYYY") +
          " - " +
          elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[1].format("MMM") +
          " " +
          elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[1].format("YYYY")
          : "",

      control1: "Response Metric",
      response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "metric")[0].value
    },


    {
      key: "12",
      control: "Expected Lift",
      response:
        elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "baseline")[0].value
          ?
          elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "baseline")[0].value
          : "",
      control1: "",
      response1: ""
    },
  ]
  return data_basic_details;
}
export const getAdvancedData = (
  elements, formConfig
) => {
  console.log('aayushelements', elements, formConfig)
  if (!elements) return [];

  const data_basic_details = [
    {
      key: "2",
      control: "Statistical Power",
      response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "Statistical")[0].value,
      control1: "Significance​",
      response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "Significance​")[0].value
    },
    {
      key: "3",
      control: "Load from saved templates",
      control1: "",
      response1: "",
      response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "SavedTemplates")[0].value,
    },


  ]
  return data_basic_details;
}
