import MeasureResultsSummaryTab from "./summary-tab";
import MeasureResultsDetailedViewTab from "./detailed-view-tab";
import MeasureResultsVariantComparisonTab from "./variant-comparison-tab";
import MeasureResultsSegmentImpactExplorerTab from "./segment-impact-explorer-tab";

export {
  MeasureResultsSummaryTab,
  MeasureResultsDetailedViewTab,
  MeasureResultsVariantComparisonTab,
  MeasureResultsSegmentImpactExplorerTab,
};
