import React from "react";
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { message } from '@pai-ui/core/components/message';
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import {
    postExperimentVariantImageUploadConfig
} from "@xai/pages/measure-results/components/measure-results-tab/transformer";
import MeasureResultsServiceHelper from "@xai/pages/measure-results/service-helper";

const ImageUploader = ({ experiment_audience_group_variant_id, summaryData, setSummaryData }) => {

    const { token } = React.useContext(UserContext);
    const service = new MeasureResultsServiceHelper(token);

    const validUploadFileFormats = [
        "image/jpeg",
        "image/png",
        "image/jpg",
    ];

    const toasterMessageText = {
        invalidFileFormat: "You can only upload JPG/JPEG/PNG file !",
        validFileUpload: "File uploaded successfully",
        invalidSizeLimit: "Image must be smaller than 512KB !",
        APIUploadFailed: "File upload failed !"
    };

    const validUploadFileSize = 0.5; // 0.5 === 512KB 

    // ref : https://codesandbox.io/s/convert-file-to-base64-in-react-lqi1e?file=/src/App.js:144-636

    const covertObjectToBase64 = (obj) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
            // Convert the file to base64 text
            reader.readAsDataURL(obj);
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    }

    const handleOnChange = async ({ file }) => {

        const limit = file.size / 1024 / 1024 < validUploadFileSize;

        if (validUploadFileFormats.includes(file.type)) {
            if (!limit) {
                message.error(toasterMessageText["invalidSizeLimit"]);
            } else {
                let dataconfig = postExperimentVariantImageUploadConfig(
                    experiment_audience_group_variant_id,
                    await covertObjectToBase64(file)
                        .then(result => {
                            console.log('result', result);
                            return [result];
                        })
                );
                console.log('postExperimentVariantImageUploadConfig dataconfig', dataconfig);
                await service
                    .postExperimentVariantImageUpload(dataconfig)
                    .then(async (res) => {
                        console.log("postExperimentVariantImageUpload res", res);
                        if (res?.upload_status?.toLowerCase() === "success") {
                            message.success(`${file.name} ${toasterMessageText["validFileUpload"]}`);
                            let tempSummaryData = { ...summaryData?.card?.data };
                            tempSummaryData.experiment_variant_summary_cards = tempSummaryData.experiment_variant_summary_cards.map(summaryCard => {
                                if (summaryCard.experiment_audience_group_variant_id === experiment_audience_group_variant_id) {
                                    let tempSummaryCard = { ...summaryCard };
                                    tempSummaryCard.product_variant_mapping = [
                                        ...tempSummaryCard?.product_variant_mapping,
                                        {
                                            image_hyperlink: null,
                                            image: dataconfig?.images_base64
                                        }
                                    ];
                                    return tempSummaryCard
                                }
                                return summaryCard;
                            });
                            setSummaryData({
                                ...summaryData,
                                card: {
                                    ...summaryData?.card,
                                    data: tempSummaryData
                                }
                            });
                            console.log(tempSummaryData, "Updated SummaryData");
                        } else if (res?.upload_status?.toLowerCase() === "failure") {
                            message.error(`${file.name} ${toasterMessageText["APIUploadFailed"]}`);
                        }
                    })
                    .catch((err) => {
                        console.log("postExperimentVariantImageUpload Error", err);
                        message.error(`${file.name} ${toasterMessageText["APIUploadFailed"]}`);
                    })
            }
        } else {
            message.error(toasterMessageText["invalidFileFormat"]);
        }
    }

    return (
        <>
            <Upload
                showUploadList={false}
                onChange={(e) => handleOnChange(e)}
                beforeUpload={() => false}
            >
                <Button ghost icon={<UploadOutlined />}>Upload Images</Button>
            </Upload>
        </>
    )
};

export default ImageUploader;