import capitalize from "lodash/capitalize";
import { uniq } from "lodash";
import moment from "moment";

export const clone = (json) =>
  JSON.parse(JSON.stringify(json));

export const formatEnum = {
  1: "0.0%",
  2: "0.0a",
  3: "0.0",
  4: "0,0",
  5: "0,0a",
  6: "$0,0a",
  7: "$0.0a",
  8: "0.00",
};

export const getFormat = (formatKey) => formatEnum[formatKey];


export const getWeek = (param) => {
  const date = moment(param);
  const yearWeek = date.weeks();
  const mon = date.format("DD MMM");

  return `Week ${yearWeek} (${mon})`;
};

export const parseLabel = (str = '') => capitalize(str.replace(/_/g, ' '));

export const loadingTypeEnum = {
  none: false,
  overall: 1,
  infiniteScroll: 2,
};

// Ref : https://www.30secondsofcode.org/js/s/debounce-promise
export const debouncePromise = (fn, ms = 500) => {
  let timeoutId;
  const pending = [];
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentPending = [...pending];
        pending.length = 0;
        Promise.resolve(fn.apply(this, args)).then(
          data => {
            currentPending.forEach(({ resolve }) => resolve(data));
          },
          error => {
            currentPending.forEach(({ reject }) => reject(error));
          }
        );
      }, ms);
      pending.push({ resolve: res, reject: rej });
    });
};

export const modifyCorrelationVizData = (dataSet, rowField, columnField, valueField) => {
  // Modify dataSet for correlation matrix
  let responseData = {
    data: [],
    fields: [],
  };

  let uniquerowFieldlist = uniq(dataSet?.data?.map((item) => item[rowField]));
  console.log("Correlation uniquerowFieldlist", uniquerowFieldlist);

  uniquerowFieldlist.forEach((rowItem, rowItemIndx) => {
    if (rowItemIndx == uniquerowFieldlist.length - 1) {
      responseData.fields.push(rowField);
    }
    let rowData = {
      [rowField]: rowItem,
    };
    dataSet?.data
      ?.filter((item) => item[rowField] == rowItem)
      .map((item) => {
        rowData[item[columnField]] = parseFloat(item[valueField]);
        if (rowItemIndx == uniquerowFieldlist.length - 1) {
          responseData.fields.push(item[columnField]);
        }
      });

    responseData.data.push(rowData);
  });

  console.log("Correlation responseData", responseData);
  return responseData;
};
