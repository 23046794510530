import axios from "axios";
import { dotNetApi /* , javaDagApi */ } from "@xai/library/clients";

// let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");
let mock = true;

export const getExperimnetsRadarChartData = (dataConfig /*, authToken */) =>
  new Promise((resolve, reject) => {
    // if (mock) {
    //   // mock response for this api call
    axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimnetsRadarChartData.json`,
      {
        skipInterceptors: true
      })
      .then((res) => {
        const { data } = res;
        console.log(
          "GetExperimnetsRadarChartData dataConfig",
          dataConfig,
          data
          /* authToken */
        );
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
    // } else {
    //   console.log("GetAllExperimntTaskRequestlist dataConfig");
    //   dotNetApi
    //     .post(
    //       `/Experiments/GetExperimentResultDetailedViewComparisionTable`,
    //       dataConfig
    //       // #TODO : use headers if required
    //       // {
    //       //   headers: {
    //       //     "x-api-key": process.env.REACT_APP_API_KEY,
    //       //     Authorization: "Bearer " + authToken,
    //       //   },
    //       // }
    //     )
    //     .then((res) => {
    //       const { data } = res;
    //       resolve(data);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // #TODO: Need to make associated changes across various files due to change in response structure
    // }
  });

export const getEnterpriseLedgerDendogram = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/getExecutiveEnterpriseLedgerDendogram.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getEnterpriseLedgerDendogram data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getEnterpriseLedgerDendogram dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getEnterpriseLedgerDendogram data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveEnterpriseLedgerRevenueOverTimeChart = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveEnterpriseLedgerRevenueOverTimeChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExecutiveEnterpriseLedgerRevenueOverTimeChart dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerRevenueOverTimeChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveEnterLedgerExperimentRevenueCorrelationChart = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveEnterLedgerExperimentRevenueCorrelationChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getExecutiveEnterLedgerExperimentRevenueCorrelationChart data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExecutiveEnterLedgerExperimentRevenueCorrelationChart dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveAtAGlanceKPIMetrics = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveAAGMetrics.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("GetExecutiveAAGMetrics data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetExecutiveAAGMetrics dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveAtAGlanceExperimentListTable = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveAAGExperimentListTable.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("GetExecutiveAAGExperimentListTable data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetExecutiveAAGExperimentListTable dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExecutiveAtAGlanceTwobyTwoChart = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveAAGTwobyTwoChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getExecutiveAtAGlanceTwobyTwoChart data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExecutiveAtAGlanceTwobyTwoChart dataConfig", authToken);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`, //#TODO Change the fetch URL
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          console.log(
            "getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart data",
            res,
          );
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });