/* eslint-disable no-unused-vars */
import { Select, SelectOption } from "@pai-ui/core/components/select";
import { Switch } from "@pai-ui/core/components/switch";
import { Tag } from "@pai-ui/core/components/tag";
import React from "react";
import { FormItem } from "@pai-ui/core/components/form-item";
import { Popover } from "@pai-ui/core/components/popover";
import ZsInfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";

import './index.css';

const SelectWithSwitch = ({ item, formItem, formMetaData }) => {
  // console.log("inside selectwithswich", item, formItem, formMetaData);
  const fieldValue = formMetaData.value || [];

  const handleSelectChange = (value) => { 
    const processedValue = value.map((id) => {
      const currentValue = fieldValue.find(({ sectionId }) => sectionId.toString() === id.toString());

      return {
        sectionId: Number(id),
        isLiftRequired: currentValue?.isLiftRequired ?? false,
      }
    });

    formMetaData.onChange(formMetaData.name, processedValue);
  };

  const handleSwitchChange = (sectionId) => (checked) => {
    const processedValue = fieldValue.map((value) => {
      return {
        sectionId: value.sectionId,
        isLiftRequired: value.sectionId.toString() === sectionId.toString() ? checked : value.isLiftRequired
      };
    });

    formMetaData.onChange(formMetaData.name, processedValue);
  };

  const renderTag = React.useCallback((props) => {
    const isLiftRequired = fieldValue.find((x) => x.sectionId.toString() === props.value.toString())?.isLiftRequired ?? false;
    
    return (
      <Tag>
        {props.label[0]}
        {isLiftRequired ? ' Lift enabled' : formItem.options.find(x=>x.id==props.value)?.isLiftAllowed ? ' Lift disabled': ''}
      </Tag>
    );
  }, [fieldValue, formItem.options]);
  // console.log('fieldValue', fieldValue);
  return (
    <FormItem
      className="pai-form-lib-item"
      classes={{
        label: "pai-form-lib-item-label",
      }}
      key={item.label}
      labelWidth={150}
      // component={item.component}
      field={formMetaData}
      // label={item.label}
      label={
        <>
          <span style={{ paddingRight: "5px" }}> {item.label}</span>
          {item.info && (
            <Popover
              placement="top"
              title={""}
              content={item.info}
              trigger="hover"
            >
              <ZsInfoCircleOutlined style={{ fontSize: 13 }} />
            </Popover>
          )}
        </>
      }
    >
      <Select
        mode="multiple"
        placeholder="Select item"
        style={{ width: '100%' }}
        tagRender={renderTag}
        value={fieldValue.map((x) => x.sectionId)}
        onChange={handleSelectChange} 
      >
        {formItem.options.map((x) => (
          <SelectOption value={x.id} key={x.id} className="section-select-option">
            {x.name}
            { x.isLiftAllowed &&
          <span className="section-select-switch-container">
            <span className="section-select-switch-label">{"Lift Required"}</span>
            <Switch 
              disabled={!fieldValue.find(({ sectionId }) => x.id == sectionId)}
              checked={fieldValue.find(({ sectionId }) => x.id == sectionId)?.isLiftRequired ?? false}
              onClick={(_, e) => e.stopPropagation()}
              onChange={handleSwitchChange(x.id)}
            />
            </span>
}
          </SelectOption>
        ))}
      </Select>
    </FormItem>
  );
};

export default SelectWithSwitch;
