import React, { useState, useEffect } from "react";
import { size, startCase } from "lodash";
import { Space } from "@pai-ui/core/components/space";
import { InputSearch } from "@pai-ui/core/components/input";
import { Select } from "@pai-ui/core/components/select";
import { Button } from '@pai-ui/core/components/button';
import { EmptyData } from "@pai-ui/core/components/empty-data";
import { Pagination } from "@pai-ui/core/components/pagination";
import { UserContext } from "@xai/providers/with-auth";
import withExperiment from "@xai/providers/with-experiment";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import { ZsFilterFilled } from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

import ExperimentTasksManagementServiceHelper from "./service-helper";
import { ListItem } from "./listItem";

const ExperimentTasksManagementDashboard = () => {
  const userState = React.useContext(UserContext);
  const { status: statusOptions, genericOptions } = userState;
  const service = new ExperimentTasksManagementServiceHelper(userState.token);

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState(null);

  // Pagination - related states and variables
  const pageSize = 7;

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPagination, setDataPagination] = useState({});
  const [paginationCountVisible, setPaginationCount] = useState(0);
  const [filteredData, setFilteredData] = useState(null);

  // Filter states
  const [experimentFilter, setExperimentFilter] = useState("");
  const [status, setStatus] = useState([]);
  const [taskRequestType, setTaskRequestType] = useState([]);
  const [requestFromScreen, setRequestFromScreen] = useState([]);
  const [userFilter, setUserFilter] = useState("");

  const [error, setError] = useState(false);

  const GetDataForPagination = (Data) => {
    let rawData = Data ? [...Data] : [...listData];
    let dataPagination = {};

    for (let i = 0; i < rawData.length; i++) {
      let page = Math.floor(i / pageSize);

      if (dataPagination[page]) {
        dataPagination[page].push(rawData[i]);
      } else {
        let arr = [];
        arr.push(rawData[i]);
        dataPagination[page] = arr;
      }
    }

    setDataPagination(dataPagination);
    setFilteredData(dataPagination[0]);
    setPaginationCount(Data ? Data.length : listData.length);
    setCurrentPage(1);
    setLoading(false);
  };

  const handlePaginationChange = (pageNumber) => {
    if (currentPage !== pageNumber) {
      let paginatedCampaigns = [...dataPagination[pageNumber - 1]];
      setCurrentPage(pageNumber);
      setFilteredData(paginatedCampaigns);
    }
  };

  const load = async () => {
    // #region - api integration Start
    setLoading(true);
    // console.log("userState", userState);
    await service
      .getAllExperimntTaskRequestlist()
      .then(async (res) => {
        setError(false);
        console.log("getAllExperimntTaskRequestlist - ", res);
        await setListData(res.items);
        // await setLoading(false);
      })
      .catch(async () => {
        await setListData(null);
        await setError(true);
        await setLoading(false);
      });

    // #endregion - api integration End
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (listData) {
      let filteredData = [...listData]
        ?.filter((a) =>
          size(taskRequestType) == 0
            ? true
            : taskRequestType.includes(a.request_type)
        )
        ?.filter((a) =>
          size(requestFromScreen) == 0
            ? true
            : requestFromScreen.includes(a.request_from_screen)
        )
        ?.filter((a) => (size(status) == 0 ? true : status.includes(a.status)))
        ?.filter((a) =>
          a.experiment_name
            .toLowerCase()
            .includes(experimentFilter.toLowerCase())
        )
        ?.filter((a) =>
          a.created_by.toLowerCase().includes(userFilter.toLowerCase())
        );
      console.log("filtered", filteredData);
      // setFilteredCampaigns(filtered);
      GetDataForPagination(filteredData);
    }
  }, [
    listData,
    experimentFilter,
    requestFromScreen,
    taskRequestType,
    status,
    userFilter,
  ]);

  if (loading) return <LegacyNoData height={"95vh"} loading={true} />;

  if (error) {
    return (
      <LegacyNoData height={"95vh"} error={true}>
        <Button onClick={() => load()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return (
    <div>
      {/* search and filter components  */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
        }}
      >
        <Space style={{ /* marginLeft: "10px", */ flex: "auto" }}>
          <span>
            <ZsFilterFilled />
            Filters:{" "}
          </span>
          <InputSearch
            placeholder={"Search Experiment"}
            onChange={(e) => {
              setExperimentFilter(e.target.value);
            }}
            style={{
              width: "14vw",
              // marginBottom: "25px",
              paddingLeft: "10px",
            }}
          />
          <Select
            showSearch
            style={{ width: "14vw", paddingLeft: "10px" }}
            mode="multiple"
            allowClear
            placeholder="Status"
            options={statusOptions ?
              Object.entries(statusOptions)?.map((item) => {
                return { id: parseInt(item[0]), name: startCase(item[1]) };
              })
              : null}
            onChange={(e) => setStatus(e)}
            maxTagCount={1}
          />
          <Select
            showSearch
            style={{ width: "15vw", paddingLeft: "10px" }}
            mode="multiple"
            allowClear
            placeholder="Screen"
            options={genericOptions?.request_from_screen?.map(
              ({ id, name }) => ({
                id: id,
                name: startCase(name),
              })
            )}
            onChange={(e) => {
              setRequestFromScreen(e);
            }}
            maxTagCount={1}
          />
          <Select
            showSearch
            style={{ width: "15vw", paddingLeft: "10px" }}
            mode="multiple"
            allowClear
            placeholder="Task Request Type"
            options={genericOptions?.task_request?.map(({ id, name }) => ({
              id: id,
              name: startCase(name),
            }))}
            onChange={(e) => {
              setTaskRequestType(e);
            }}
            maxTagCount={1}
          />
          <InputSearch
            placeholder={"Search User"}
            onChange={(e) => {
              setUserFilter(e.target.value);
            }}
            style={{
              width: "14vw",
              // marginBottom: "25px",
              paddingLeft: "10px",
            }}
          />
        </Space>
        <Space
          style={{
            flex: "auto",
            justifyContent: "flex-end",
          }}
        >
          {/* <Link to="./build">
            <Button
              style={{ float: "right" }}
              type="primary"
              // value={"Add a new Journey"}
              label={"Add Assessment"}
            />
          </Link> */}
        </Space>
      </div>
      {/* Listing view */}
      <div style={{ height: "85vh", paddingRight: "10px", overflowY: "auto" }}>
        {filteredData?.length > 0 ? (
          filteredData.map((item, index) => {
            return (
              <ListItem
                key={index}
                item={item}
              // handleDeleteExperiment={handleDeleteExperiment}
              />
            );
          })
        ) : (
          // <span>Empty Data</span>
          <LegacyPanel
            className={"hover-section"}
            round
            disableOverflow
            style={{ height: "inherit" }}
          >
            <LegacyPanel
              flexible
              background="rgba(255,255,255,0.03)"
              paddingX
              paddingY
            >
              <LegacyPanel flexible paddingY center>
                <EmptyData />
              </LegacyPanel>
            </LegacyPanel>
          </LegacyPanel>
        )}
      </div>
      {/* 
      TODO : 
      Additional feature - choose number of items per page, go to page.
       */}
      <Pagination
        style={{ paddingTop: "16px", float: "right" }}
        current={currentPage}
        onChange={(page) => handlePaginationChange(page)}
        showSizeChanger={false}
        total={paginationCountVisible}
        pageSize={pageSize}
        hideOnSinglePage={true}
      />
    </div>
  );
};

export default withExperiment(ExperimentTasksManagementDashboard);
