import React, { useMemo } from "react";
// import numeral from "numeral";
import {
  Visualization,
  TableConfig,
} from "@pai-ui/core/components/visualization";
// import { Table } from "@pai-ui/core";
import { Button } from "@pai-ui/core/components/button";
import { UserContext } from "@xai/providers/with-auth";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import { RangePercentage } from "../rangePercentage";
// import { CustomBoxPlot } from "../custom-box-plot";
import { CustomVizBoxPlot } from "../custom-viz-box-plot";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";

import "./index.css";

export const SegmentImpactExplorerTable = ({
  segmentImpactExplorerTabParams,
  segmentimpactTabFilters,
  segmentimpactTabData,
  // segmentImpactDataLoading,
  // segmentImpactTabTableError,
  loadSegmentImpactExplorerTabData,
}) => {
  const { format } = React.useContext(UserContext);

  const {
    selectedSegment,
    selectedParameter,
    selectedTestGroup,
    selectedControlGroup,
  } = segmentimpactTabFilters;

  let selectedControlGroupObject =
    segmentImpactExplorerTabParams?.data?.variant_groups_control_test_list?.find(
      (control) => control.id === selectedControlGroup
    );

  let selectedTestGroupObject = selectedControlGroupObject?.test_groups?.find(
    (test) => test.id === selectedTestGroup
  );

  let localSegmentImpactTabledata = segmentimpactTabData?.data?.segment_table_data;

  //GenericModalWithResultComponent State to be removed later
  const [isRequestModalOpen, setIsRequestModalOpen] = React.useState(true);

  const columns = useMemo(() => {
    if (segmentimpactTabData?.data?.segment_table_data?.length > 0) {
      // Find Min and Max values

      let max = localSegmentImpactTabledata?.reduce((m, x) =>
        m["box_plot"]?.["max_value"] > x["box_plot"]?.["max_value"] ? m : x
      )?.["box_plot"]?.["max_value"];
      let min = localSegmentImpactTabledata?.reduce((m, x) =>
        m["box_plot"]?.["min_value"] < x["box_plot"]?.["min_value"] ? m : x
      )?.["box_plot"]?.["min_value"];
      let max_limit = max > 0 ? max * 1.15 : max * 0.85;
      let min_limit = min < 0 ? min * 1.15 : min * 0.85;

      // console.log(
      //   "segmentimpactTabData",
      //   segmentimpactTabData,
      //   max,
      //   max_limit,
      //   min,
      //   min_limit
      // );

      return [
        {
          title: splitByDelimiterAndToStartCase(selectedSegment),
          dataKey: "segment_cohort",
          dataIndex: "segment_cohort",
          width: "14%",
          render: (text /*, row */) => {
            // return <>{!row.is_control ? text : ""}</>;
            return <>{text}</>;
          },
          // onCell: (_, index) => {
          //   // Blindly expecting the first and second item come as a group
          //   //  this needs to be handled and made sure from backend API
          //   if (index % 2 === 1) {
          //     return { rowSpan: 0 };
          //   }
          //   // These two are merged into above cell
          //   else {
          //     return { rowSpan: 2 };
          //   }
          // },
        },
        {
          title: "",
          dataKey: "is_control",
          dataIndex: "is_control",
          width: "2%",
          colSpan: 0,
          render: (_) => {
            return <>{!_ ? "T" : "C"}</>;
          },
        },
        {
          title: "",
          dataKey: "parameter",
          dataIndex: "parameter",
          width: "7%",
          colSpan: 0,
          render: (_) => {
            return (
              <RangePercentage
                primaryValue={_?.value}
                primaryValueFormat={format?.[_?.value_format]}
                secondaryValue={_?.buffer_value}
                secondaryValueFormat={format?.[_?.buffer_value_format]}
                fontSize="12px"
              />
            );
          },
        },
        {
          title: selectedParameter, // chart item to be added
          //   dataKey: "",
          //   width: 200,
          dataKey: "box_plot",
          dataIndex: "box_plot",
          width: "35%",
          colSpan: 3,
          render: (_, rowData) => {
            // return <CustomBoxPlot box_plot_data={_} />;
            return (
              <CustomVizBoxPlot
                box_plot_data={_}
                is_control={rowData?.is_control}
                selectedTestGroup={selectedTestGroupObject?.name}
                selectedControlGroup={selectedControlGroupObject?.name}
                selectedParameter={selectedParameter}
                min_limit={min_limit}
                max_limit={max_limit}
              />
            );
          },
        },
        {
          title: "Incremental",
          dataKey: "incremental",
          dataIndex: "incremental",
          width: "7%",
          align: "right",
          render: (_) => {
            return _?.value ? (
              <PosNegValue
                value={_?.value}
                valueFormat={format?.[_?.value_format]}
                textColor={"rgba(225,225,225,0.85)"}
                showCaret={_?.PosNeg}
                fontSize="14px"
                ZeroRange={{
                  minNegZero:
                    _?.PosNeg == "negative"
                      ? _?.value > 0
                        ? _?.value * 1.01
                        : _?.value * 0.99
                      : _?.value,
                  minPosZero:
                    _?.PosNeg == "positive"
                      ? _?.value > 0
                        ? _?.value * 0.99
                        : _?.value * 1.01
                      : _?.value,
                }}
              />
            ) : (
              ""
            );
          },
        },
        {
          title: "Lift",
          dataKey: "lift",
          dataIndex: "lift",
          width: "7%",
          align: "right",
          render: (_) => {
            return _?.value ? (
              <PosNegValue
                value={_?.value}
                valueFormat={format?.[_?.value_format]}
                textColor={"rgba(225,225,225,0.85)"}
                showCaret={_?.PosNeg}
                fontSize="14px"
                ZeroRange={{
                  minNegZero:
                    _?.PosNeg == "negative"
                      ? _?.value > 0
                        ? _?.value * 1.01
                        : _?.value * 0.99
                      : _?.value,
                  minPosZero:
                    _?.PosNeg == "positive"
                      ? _?.value > 0
                        ? _?.value * 0.99
                        : _?.value * 1.01
                      : _?.value,
                }}
              />
            ) : (
              ""
            );
          },
        },
        {
          title: "P-Value",
          dataKey: "p-Value",
          dataIndex: "p-Value",
          width: "7%",
          align: "right",
          render: (_) => {
            return _?.value !== null ? (
              <PosNegValue
                value={_?.value}
                valueFormat={format?.[_?.value_format]}
                textColor={"rgba(225,225,225,0.85)"}
                showCaret={_?.PosNeg}
                fontSize="14px"
                ZeroRange={{
                  minNegZero:
                    _?.PosNeg == "negative"
                      ? _?.value > 0
                        ? _?.value * 1.01
                        : _?.value * 0.99
                      : _?.value,
                  minPosZero:
                    _?.PosNeg == "positive"
                      ? _?.value > 0
                        ? _?.value * 0.99
                        : _?.value * 1.01
                      : _?.value,
                }}
              />
            ) : (
              "--"
            );
          },
        },
      ];
    } else {
      return [];
    }
  }, [
    localSegmentImpactTabledata,
    selectedSegment,
    selectedParameter,
    selectedControlGroup,
    selectedTestGroup,
  ]);

  if (segmentimpactTabData?.loading) {
    return <LegacyNoData height={"55vh"} loading />;
  }

  return (
    <>
      {segmentimpactTabData?.error ? (
        <LegacyNoData height={"55vh"} error={true}>
          <Button
            onClick={() => {
              loadSegmentImpactExplorerTabData();
            }}
          >
            Try Again
          </Button>
        </LegacyNoData>
      ) : localSegmentImpactTabledata ? (
        <>
          {/* <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              textTransform: "capitalize",
              marginBottom: "10px",
            }}
          >
            {`${splitByDelimiterAndToStartCase(
              selectedSegment
            )} by ${splitByDelimiterAndToStartCase(selectedParameter)}`}
          </div>
          <Table
            className="custom-border-comparison-table"
            id="comparision"
            loading={!segmentimpactTabData}
            columns={columns}
            dataSource={segmentimpactTabData || []}
            scroll={{ y: "calc(43vh)" }}
            width={"99.9%"}
          /> */}
          <Visualization
            title={`${splitByDelimiterAndToStartCase(
              selectedSegment
            )} by ${selectedParameter}`}
            height={"calc(50vh)"}
            withCard={false}
            dataConfig={{
              structureType: "processed",
            }}
            classes={{
              root: "segmentImpactTabTable",
            }}
            viewConfig={
              new TableConfig({
                sticky: true,
                loading: !localSegmentImpactTabledata,
                columns: columns,
              })
            }
            dataset={{
              data: localSegmentImpactTabledata || [],
            }}
          />
        </>
      ) : (
        <>
          <GenericModalWithResultComponent
            isRequestModalOpen={isRequestModalOpen}
            setIsRequestModalOpen={setIsRequestModalOpen}
          />
          <LegacyNoData height={"71vh"} />
        </>
      )}
    </>
  );
};

export default SegmentImpactExplorerTable;
