import React, { useState, useEffect } from "react";
import { Carousel } from "@pai-ui/core/components/carousel";
import { Button } from '@pai-ui/core/components/button';
// import { Row } from "@pai-ui/core/components/row";
// import { Col } from "@pai-ui/core/components/col";
import { UserContext } from "@xai/providers/with-auth";
import ChartWrapper from "@xai/assets/generic-components/visualization/components/wrapper";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import SummaryCard from "../summary-card";
import "./index.css";

//FIXME
// let groupByN = (n, data) => {
//   console.log("groupByN", data);
//   let result = [];
//   for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
//   return result;
// };

const settings = {
  slidesToShow: 4,
  slidesToScroll: 4,
  hoverEffect: false,
  infinite: false,
};

export const SummaryCardList = ({ summaryData, error, loading, setSummaryData, selectedVariants, selectedDateRangeVal, loadSummaryTabCardsData, selectedAudience }) => {

  const SummaryCardListData =
    summaryData?.experiment_variant_summary_cards?.sort(
      (a, b) =>
        b.audience_group_variant_is_active - a.audience_group_variant_is_active
    );
  const { experimentModule } = React.useContext(UserContext);


  console.log("SummaryCardList", SummaryCardListData);

  const [maxKeyValuePairs, setMaxKeyValuePairs] = useState(0);
  const [variantLabelCountLimit, setvariantLabelCountLimit] = useState();
  const [fontSizeCardLabel, setfontSizeCardlabel] = useState();
  const [filteredSummaryData, setFilteredSummaryData] = useState(summaryData?.experiment_variant_summary_cards);
  const ExperimentAudienceGroupAvailable = experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]?.audience_group_name;

  const loadData = (CardListData) => {
    const datametric = CardListData || [];

    const getMaxWidthFontSizeCalc = (charCount) => {
      let locCharCount = "";
      let locFontSize = "";
      switch (true) {
        case (charCount <= 40):
          locCharCount = 40;
          locFontSize = "28px"
          break;
        case (charCount <= 60 && charCount > 40):
          locCharCount = 60;
          locFontSize = "26px"
          break;
        case (charCount <= 80 && charCount > 60):
          locCharCount = 80;
          locFontSize = "24px"
          break;
        case (charCount <= 100 && charCount > 80):
          locCharCount = 100;
          locFontSize = "22px"
          break;
        case (charCount <= 120 && charCount > 100):
          locCharCount = 120;
          locFontSize = "20px"
          break;
        case (charCount <= 250 && charCount > 120):
          locCharCount = 160;
          locFontSize = "18px"
          break;
        case (charCount > 250):
          locCharCount = 180;
          locFontSize = "16px"
          break;
      }
      return {
        charCount: locCharCount,
        fontSize: locFontSize
      }
    };
    setFilteredSummaryData(datametric);

    if (datametric?.length) {
      const allKpisLength = [];
      const locLabelsArray = datametric?.map((cardObj) => cardObj?.audience_group_variant_label?.length);
      datametric.forEach((x) => {
        let keyValuePairs = x?.kpi_list?.length || 0;
        x?.response_metric_kpi_value ? keyValuePairs++ : null;
        x?.lift_kpi_value ? keyValuePairs++ : null;
        allKpisLength.push(keyValuePairs);
      });
      // Below code is to dynamically calc the Font size and character count split in Image Coursal Fallback scenario
      const { charCount, fontSize } = getMaxWidthFontSizeCalc(Math.max(...locLabelsArray));

      setMaxKeyValuePairs(Math.max(...new Set(allKpisLength)));
      setvariantLabelCountLimit(charCount);
      setfontSizeCardlabel(fontSize);
    }
  };

  useEffect(() => {
    let tempSummaryData = [];
    console.log("selectedVariants, selectedAudience]", selectedVariants, SummaryCardListData);
    if (selectedVariants?.length > 0) {
      tempSummaryData = SummaryCardListData;
      tempSummaryData = ExperimentAudienceGroupAvailable
        ? tempSummaryData
          ?.filter(
            (cardObj) =>
              cardObj?.parent_audience_group_variant_name === selectedAudience
          )
          ?.filter((cardObj) =>
            selectedVariants?.includes(cardObj?.audience_group_variant_name)
          )
        : tempSummaryData?.filter((cardObj) =>
          selectedVariants?.includes(cardObj?.audience_group_variant_name)
        );
      // setFilteredSummaryData(tempSummaryData);
    }
    loadData(tempSummaryData);

  }, [selectedVariants]);

  // useEffect(() => {
  //   loadData();
  // }, [SummaryCardListData]);

  if (error) {
    return (
      <LegacyNoData height={"61vh"} error={true}>
        <Button onClick={() => loadSummaryTabCardsData(selectedDateRangeVal)}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return (
    <>
      {loading ?
        <LegacyNoData height={"71vh"} loading={true} />
        :
        <>
          {filteredSummaryData && filteredSummaryData?.length > 0 ?
            <div className={"sumary"}>
              <ChartWrapper>
                {({ width, /* height */ }) => (
                  <div style={{ width: width ? width - 10 : "104vw", height: "auto" }}>
                    <Carousel {...settings}>
                      {filteredSummaryData?.map((m, i) => {
                        return <SummaryCard
                          key={`group_${i}`}
                          product={m}
                          height={(65 * maxKeyValuePairs)}
                          setSummaryData={setSummaryData}
                          summaryData={summaryData}
                          variantLabelCountLimit={variantLabelCountLimit}
                          fontSizeCardLabel={fontSizeCardLabel}
                        />;
                      })}
                    </Carousel>
                  </div>
                )}
              </ChartWrapper>
            </div>
            : <LegacyNoData height={"71vh"} />
          }
        </>
      }
    </>
  );
};

export default SummaryCardList;
