import axios from "axios";

export const Api = axios.create({
  baseURL:
    // process.env.REACT_APP_MOCK
    //   ? window.location.origin
    //   :
    process.env.REACT_APP_MICROSERVICE_HOST + "/api",
  timeout: 300000,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

export const Auth = axios.create({
  baseURL:
    // process.env.REACT_APP_AUTH_MOCK
    //   ? "./sample_data/auth"
    //   :
    process.env.REACT_APP_MICROSERVICE_HOST + "/auth",
  timeout: 300000,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

export const dotNetApi = axios.create({
  baseURL:
    // process.env.REACT_APP_MOCK
    //   ? window.location.origin
    //   :
    process.env.REACT_APP_DOTNET_MICROSERVICE_HOST + "/api",
  timeout: 300000,
  headers: {
    // "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

export const javaDagApi = axios.create({
  baseURL:
    // process.env.REACT_APP_MOCK
    //   ? window.location.origin
    //   :
    process.env.REACT_APP_JAVA_MICROSERVICE_HOST + "/dag",
  timeout: 300000,
  headers: {
    // "x-api-key": process.env.REACT_APP_API_KEY,
  },
});
