import React, { useState, useEffect } from "react"; //{ useEffect, useState }
import { Button } from "@pai-ui/core/components/button";
import { Card } from "@pai-ui/core/components/card";
import { Space } from "@pai-ui/core/components/space";
import { Metric } from "@pai-ui/core/components/metric";
import { Select } from "@pai-ui/core/components/select";
import { Layout3 } from "@styled-icons/remix-line/Layout3";
import { SupersetFormDdnMaster } from "./config";
import AddDashboardPopupContent from "./components/AddDashboardPopup";
import { UserContext } from "@xai/providers/with-auth";
import { deleteRoute, getReportsConfigured, getScreensConfigured, updateRoute } from "@xai/services/superset-integration";
import { useLayoutContextToUpdateMenu } from "@xai/providers/layout";
import { ConfigurableUiManagement } from "./components/configurable-ui-management";
import { ModalProvider } from "styled-react-modal";
import styled from "styled-components";
import { Input } from "@pai-ui/core/components/input";
import { Loading } from "@pai-ui/core";
import { Link } from "react-router-dom";

export const MessageComponent = ({ Icon, text1, text2, buttonReqd, buttonText, linkState }) => {
  console.log("linkState", linkState);
  return (
    <>
      <div className="flex items-end w-full justify-center h-screen/2">{Icon}</div>
      <div className="flex text-base w-full justify-center h-3vh text-white-50">
        <p>
          <b>{text1}</b>
        </p>
      </div>
      <div className="flex text-xs text-white-50 w-full justify-center height-5vh">{text2}</div>
      {buttonReqd && (
        <div className="flex text-base text-white-50 w-full justify-center" style={{ marginTop: '5px' }}>
          <Button type="primary">
            <Link
              to={{
                pathname: "/classification/customers",
                state: linkState,
              }}>
              {buttonText}
            </Link>
          </Button>
        </div>
      )}
    </>
  );
};

const SupersetIntegration = () => {
  let isLocalhost = process.env.REACT_APP_IS_LOCALHOST == "true";
  const userState = React.useContext(UserContext);
  const authToken = userState.token;
  const { updateMenu } = useLayoutContextToUpdateMenu();
  const [ddnMaster, setDdnMaster] = useState({});
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const iframeRef = React.useRef();
  const [configurableUiOpened, setConfigurableUiOpened] = React.useState(false);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(false);

  const verifyPassword = () => {
    let passwords = document.getElementById("password").value;
    let allowedPasswords = ["Personalize02"];

    if (allowedPasswords.includes(passwords)) {
      localStorage.setItem("login", "true");
      setLogin(true);
      setLoading(true);
    }
  };

  let cookie =
    "authservice_session=MTY5NTYzMTgwMHxOd3dBTkZGSlJVcEdTME5GUlU5T1NGRklRVU5NVlZOSFdGcFpWRTlFVERWWldFbFRUalZQUmxoV1V6UkVXRnBYUWxCQ1RsVlJVRkU9fAwfj8KxrtkJt83xNf9QFmwIQDgVDB46tmcn6jZFIpDt; session=.eJwljkFqQzEMBe_idRe2ZFlSLvORbImWhgb-T1Yld6-hy3kww_stR55xfZbb83zFRzm-VrkVVjCsnBRoEWZ1yMpGUMFtDnCeffaagyuiM8kiZqcQBYUNmuwCS4Wha7ZQFqUFPqRPmIRoUqUq0OhgYorQUPpyHLuVZF72kdcV5_8b6JvndebxfHzHz14GOnJjs-GDJwpxGuSOVW5A2VqNnrlse_fHtHtsZ4vvP_NdQh8.ZRJ0bA.OH5EQppx17j1lxMadWS_WFANiwE";
  let superSetDomain = `${process.env.REACT_APP_SUPERSET_HOST}`;
  const iconStyle = {
    height: "50%",
    color: "rgba(180, 183, 184,0.55)",
    fillOpacity: "0.15",
  };

  const getAccessToken = async () => {
    let [username, password] = `${process.env.REACT_APP_SUPERSET_CRED}`.split("|||||");

    let body = {
      username,
      password,
      provider: "db",
    };

    try {
      let options = {
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(body),
      };

      let fetchRes = await fetch(
        `${superSetDomain}/api/v1/security/login`,
        options
      );
      // fetchRes.then((res) => ));
      return fetchRes.json().then((x) => {
        // setAccessToken(x);
        setToken(x["access_token"]);
        setLoading(false);
        return x["access_token"];
      });
    } catch (error) {
      console.error("Error fetching access token: ", error);
      setLoading(false);
      // Handle error appropriately
    }
  };

  const getDashboards = async (aToken) => {
    console.log("getDashboards called with accesstoken:", aToken);

    let options = {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Cookie: cookie,
        Authorization: "Bearer " + aToken,
      },
    };

    // console.log("before api");
    let fetchRes = await fetch(`${superSetDomain}/api/v1/dashboard`, options);
    // fetchRes.then((res) => ));
    return fetchRes.json().then((x) => {
      // setGuestToken(x);
      console.log("value after getDashboards api call", x);
      let res = x;
      if (x?.result?.length) {
        res = {
          ...res,
          result: res?.result?.filter((key) => key?.published)
        }
      }
      return res;
    });
  };

  // eslint-disable-next-line no-unused-vars
  const makeAPICalls = async () => {
    // // Comment before pushing
    if (isLocalhost) {
      let ddn = SupersetFormDdnMaster(null);
      setDdnMaster(ddn);
    } else {
      // uncomment before pushing
      console.log("makeAPICalls called");
      let aToken = await getAccessToken();
      console.log("aToken received", aToken);
      let res = await getDashboards(aToken);
      console.log("dashboards received", res);
      let d = res.result?.map((x) => ({
        id: x.id,
        name: `${x.dashboard_title} - ${x.created_by?.first_name}`,
      }));
      setDashboards(d);
      let ddn = SupersetFormDdnMaster(d);
      setDdnMaster(ddn);

      // //call ss login api
      // axios
      // .get(`${process.env.REACT_APP_SUPERSET_HOST}/login/`, { //"https://pai-kubeflow-sbox.pai.zssandbox.com/ss/login/"
      //     headers: { "Kubeflow-Userid": "souvik.saha@zs.com" },
      //   })
      //   .then((response) => {
      //     // If request is good...
      //     console.log("superset login successful");
      //   })
      //   .catch((error) => {
      //     console.log("login error " + error);
      //   });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (login) {
      makeAPICalls();
    } else {
      if (localStorage.getItem("login") && localStorage.getItem("login") == "true") {
        setLogin(true);
        setLoading(true);
      }
    }
  }, [login]);

  useEffect(() => {
    const reactEnv = process.env.REACT_APP_ENV_VALUE;
    console.log("******ENVIRONMENT********", reactEnv);
  }, []);

  const handleAddDashboardClick = () => {
    setShowPopup(!showPopup);
  };

  const handleRouteUpdate = React.useCallback(
    async (route) => {
      await updateRoute(route, authToken);
      updateMenu(authToken);
    },
    [authToken, updateMenu]
  );

  const handleRouteDelete = React.useCallback(
    async (routeId) => {
      await deleteRoute(routeId, authToken);
      updateMenu(authToken);
    },
    [authToken, updateMenu]
  );

  const SpecialModalBackground = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 111vw;
    height: 111vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.65);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  if (loading) {
    return <Loading />
  }

  if (!(localStorage.getItem("login") && localStorage.getItem("login") == "true") || !token) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div>
          <div
            style={{
              margin: "0",
              color: "hsla(0,0%,100%,.85)",
              fontSize: "14px",
              fontFamily: "SF Text",
              fontVariant: "tabular-nums",
              lineHeight: "1.5715",
              backgroundColor: "#111419",
            }}
          >
            {" "}
            This page is password protected.{" "}
          </div>
          <Input
            style={{
              background: "#2d2f31",
              height: "30px",
              border: "1px solid #3f4c58 !important",
              borderRadius: "5px",
              padding: "2px",
              width: "100%",
            }}
            type="password"
            placeholder="Password"
            id="password"
          ></Input>

          <Button
            label="Submit"
            style={{
              marginTop: "10px",
              display: "flex",
              float: "right",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => verifyPassword()}
          ></Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        {localStorage.getItem("login") &&
          localStorage.getItem("login") == "true" ? (
          <>
            <ConfigurableUiManagement
              open={configurableUiOpened}
              close={() => setConfigurableUiOpened(false)}
              getReports={() => getReportsConfigured(authToken)}
              updateReport={handleRouteUpdate}
              deleteReport={handleRouteDelete}
              getScreens={() => getScreensConfigured(authToken)}
              updateScreen={handleRouteUpdate}
              deleteScreen={handleRouteDelete}
            />
            <div style={{ display: "flex" }}>
              <Space size={"middle"} style={{ flex: "auto" }}>
                {
                  <Metric
                    title="Configure Screen"
                    subTitle="Using Superset Dashboard"
                    highlightedElement="subTitle"
                    withBackground={false}
                  />
                }
                <Select
                  options={dashboards}
                  style={{ width: "300px" }}
                  onChange={(value) => setSelectedDashboard(value)}
                  placeholder="Select Dashboard"
                  value={selectedDashboard}
                />
              </Space>
              <div>
                <Button
                  ghost
                  style={{ marginRight: 12 }}
                  onClick={() => setConfigurableUiOpened(true)}
                >
                  Manage Dashboards
                </Button>
                {selectedDashboard || isLocalhost ? (
                  <Button
                    ghost
                    style={{ float: "right" }}
                    onClick={() => handleAddDashboardClick()}
                  >
                    Add Dashboard to App
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {selectedDashboard ? (
              <iframe
                ref={iframeRef}
                style={{ border: "none", marginTop: 20, height: "100vh" }}
                title="superset-content"
                className="dashboard-preview"
                src={`${process.env.REACT_APP_SUPERSET_HOST}/superset/dashboard/${selectedDashboard}/?standalone=1&&show_filters=0`}
                height="88%"
                width="100%"
              />
            ) : (
              <Card style={{ height: "100%", marginTop: 20 }}>
                <div>
                  <MessageComponent
                    Icon={<Layout3 style={iconStyle} />}
                    text1={"No Dashboard Selected"}
                    text2={
                      "Please select an existing Superset Dashboard to begin with"
                    }
                  />
                </div>
              </Card>
            )}
            <AddDashboardPopupContent
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              selectedDashboard={selectedDashboard}
              ddnMaster={ddnMaster}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",

              justifyContent: "center",

              alignItems: "center",

              minHeight: "100vh",
            }}
          >
            <div>
              <div
                style={{
                  margin: "0",

                  color: "hsla(0,0%,100%,.85)",

                  fontSize: "14px",

                  fontFamily: "SF Text",

                  fontVariant: "tabular-nums",

                  lineHeight: "1.5715",

                  backgroundColor: "#111419",
                }}
              >
                {" "}
                This page is password protected.{" "}
              </div>

              <Input
                style={{
                  background: "#2d2f31",

                  height: "30px",

                  border: "1px solid #3f4c58 !important",

                  borderRadius: "5px",

                  padding: "2px",

                  width: "100%",
                }}
                type="password"
                placeholder="Password"
                id="password"
              ></Input>

              <Button
                label="Submit"
                style={{
                  marginTop: "10px",
                  display: "flex",
                  float: "right",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={(e) => verifyPassword(e)}
              ></Button>
            </div>
          </div>
        )}
      </ModalProvider>
    </>
  );
};

export default SupersetIntegration;
