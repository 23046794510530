import styled from 'styled-components'

const flexAligns = {
    "center": "center",
    "start": "flex-start",
    "end": "flex-end",
    "stretch":"stretch",
    "between": "space-between"
}

const directionMap = {
    "h": "row",
    "v": "column",
    "rh": "row-reverse",
    "rv": "column-reverse"
}

const Box = styled.div`
    display: ${props => props.block ? "block": "flex"};
    position: ${props => props.position ?? props.relative ? "relative" : "unset"};
    flex-direction: ${props => directionMap[props.direction || "c"]};
    justify-content: ${props => flexAligns[props.justify || "start"]};
    align-items: ${props => flexAligns[props.align || "start"]};
`

export default Box