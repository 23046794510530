import React, { } from 'react';
import moment from "moment";
import { Row, Col } from 'antd';
import { Input, Button, Card, RuleBuilder, TextArea, DateRangePicker } from '@pai-ui/core';
import './style.css';

const FirstStepRevamp = ({ ExpData, forthStepData, formData, setFormData, }) => {

    const config = React.useMemo(
        () => formData.basicInfo.rule_builder_config,
        [formData.basicInfo.rule_builder_config],
    );

    const DefaultRuleBuilderConfig = {
        "rules": [
            {
                "name": "R1",
                "value": {
                    "id": "activeusers",
                    "operator": "equals",
                    "value": 150,
                }
            }
        ]
    }

    const dateFormat = "YYYY-MM-DD";

    const changeHandler = (event, parmeter) => {
        const value = event?.target?.value || event;
        const tempFormData = { ...formData };
        if (parmeter) {
            tempFormData.basicInfo[parmeter] = value;
            setFormData(tempFormData);
        }
    }

    const ruleBuilderHandler = (value, par, index) => {
        const tempFormData = { ...formData };
        // tempFormData.basicInfo[par] = value;
        let tempRulesData = tempFormData.basicInfo[par];
        tempRulesData = tempRulesData.map((rulesObj) => {
            if (rulesObj.identifier === index) {
                return {
                    ...value,
                    identifier: rulesObj.identifier,
                    audience_group_name: rulesObj.audience_group_name
                };
            }
            return rulesObj;
        })
        tempFormData.basicInfo[par] = tempRulesData;//[...tempFormData.basicInfo[par], value];
        setFormData(tempFormData);
        // console.log('formData rule_builder', value, [...tempFormData.basicInfo[par], value]);
    }

    const addNewAudiencegroup = (parameter) => {
        const tempFormData = { ...formData };
        let tempRulesData = tempFormData.basicInfo[parameter];
        let ExistingMaxIdentifierRulesObj = Math.max(...tempRulesData.map((item) => item.identifier))
        tempRulesData = [...tempRulesData, {
            ...DefaultRuleBuilderConfig,
            identifier: ExistingMaxIdentifierRulesObj + 1,
            audience_group_name: `Audience Group ${ExistingMaxIdentifierRulesObj + 1}`
        }];
        tempFormData.basicInfo[parameter] = tempRulesData;//[...tempFormData.basicInfo[par], value];
        setFormData(tempFormData);
        //DefaultRuleBuilderConfig
    }

    const editAudienceGroupName = (parameter, identifier, value) => {
        const tempFormData = { ...formData };
        let tempRulesData = tempFormData.basicInfo[parameter];
        tempRulesData = tempRulesData.map((rulesObj) => {
            if (rulesObj.identifier === identifier) {
                return {
                    ...rulesObj,
                    audience_group_name: value
                };
            }
            return rulesObj;
        })
        tempFormData.basicInfo[parameter] = tempRulesData;
        setFormData(tempFormData);
    }

    const deleteAudienceGroup = (parameter, identifier) => {
        const tempFormData = { ...formData };
        let tempRulesData = tempFormData.basicInfo[parameter];
        tempRulesData = tempRulesData.filter(rulesObj => rulesObj.identifier !== identifier);
        tempFormData.basicInfo[parameter] = tempRulesData;
        setFormData(tempFormData);
    }

    console.log('formData', formData.basicInfo.rule_builder_defaultValue)


    return (
        <Row>
            <Col span={12}>
                <div style={{ marginRight: "20px" }}>
                    <div style={{ marginBottom: "20px" }} >
                        <Card className="custom-ant-card-no-padding">
                            <div>
                                <Row style={{ display: "flex", justifyContent: "space-between", }} >
                                    <Col style={{ margin: "auto 20px" }}>
                                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                                            {ExpData.elements.find(it => it.itemKey === "response_metric").primary_label}
                                            {ExpData.elements.find(it => it.itemKey === "response_metric").mandatory && "*"}
                                        </p>
                                        <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                            {ExpData.elements.find(it => it.itemKey === "response_metric").secondary_label}
                                        </p>
                                    </Col>
                                    <Col>
                                        <Row style={{ padding: "20px", gap: "20px" }} >
                                            {
                                                ExpData.elements.find(it => it.itemKey === "response_metric").options.map((it, index) => (
                                                    <div key={index}>
                                                        <Card
                                                            className="card-section"
                                                            style={{
                                                                background:
                                                                    ((formData?.basicInfo?.response_metric === it.label) ?
                                                                        `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))` :
                                                                        ""),
                                                                width: "145px",
                                                                height: "145px",
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                                border: "1px solid rgba(67, 67, 67)"
                                                            }}

                                                            onClick={() => {
                                                                changeHandler(it.label, "response_metric")
                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}>
                                                                <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/${it.img}`} /* src={`${process.env.REACT_APP_S3_BUCKET}/tnl/${subRoute}${it.img}`} */ width={30} alt='' />
                                                                <p style={{}}>{it.label}</p>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </div>
                    <div style={{ marginBottom: "20px" }} >
                        <Card className="custom-ant-card-no-padding">
                            <div>
                                <Row style={{ display: "flex", justifyContent: "space-between", }}>
                                    <Col style={{ margin: "auto 20px" }}>
                                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                                            {ExpData.elements.find(it => it.itemKey === "message").primary_label}
                                            {ExpData.elements.find(it => it.itemKey === "message").mandatory && "*"}
                                        </p>
                                        <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                            {ExpData.elements.find(it => it.itemKey === "message").secondary_label}
                                        </p>
                                    </Col>
                                    <Col>
                                        <Row style={{ padding: "20px", gap: "20px" }} >
                                            {
                                                ExpData.elements.find(it => it.itemKey === "message").options.map((it, index) => (
                                                    <Card
                                                        key={index}
                                                        className="card-section"
                                                        style={{
                                                            background:
                                                                ((formData?.basicInfo?.message === it.label) ?
                                                                    `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))` :
                                                                    ""),
                                                            width: "145px",
                                                            height: "145px",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            border: "1px solid rgba(67, 67, 67)"
                                                        }}

                                                        onClick={() => {
                                                            changeHandler(it.label, "message")
                                                        }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/${it.img}`} width={30} alt='' />
                                                            <p style={{}}>{it.label}</p>
                                                        </div>
                                                    </Card>
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </div>
                    <div style={{ marginBottom: "20px" }} >
                        <Card className="custom-ant-card-no-padding">
                            <div>
                                <Row style={{ display: "flex", justifyContent: "space-between", }}>
                                    <Col style={{ margin: "auto 20px" }}>
                                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                                            {ExpData.elements.find(it => it.itemKey === "variant_allocation").primary_label}
                                            {ExpData.elements.find(it => it.itemKey === "variant_allocation").mandatory && "*"}
                                        </p>
                                        <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                            {ExpData.elements.find(it => it.itemKey === "variant_allocation").secondary_label}
                                        </p>
                                    </Col>
                                    <Col>
                                        <Row style={{ padding: "20px", gap: "20px" }} >
                                            {
                                                ExpData.elements.find(it => it.itemKey === "variant_allocation").options.map((it, index) => (
                                                    <Card
                                                        key={index}
                                                        className="card-section"
                                                        style={{
                                                            background:
                                                                ((formData?.basicInfo?.variant_allocation === it.label) ?
                                                                    `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))` :
                                                                    ""),
                                                            width: "145px",
                                                            height: "145px",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            border: "1px solid rgba(67, 67, 67)"
                                                        }}

                                                        onClick={() => {
                                                            changeHandler(it.label, "variant_allocation")
                                                        }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/${it.img}`} width={30} alt='' />
                                                            <span style={{}}>{it.label}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <Card className="custom-ant-card-no-padding" style={{ marginBottom: "20px" }}>
                    <div style={{ /* marginBottom: "20px", marginLeft: "20px", padding: "20px", height: "87vh" */ }}>
                        <Row style={{ margin: "25px" }}>
                            Basic Info
                        </Row>
                        <Row style={{ justifyContent: "space-between", margin: "25px" }}>
                            <Col span={6}>
                                <p style={{ margin: "0px" }}>{forthStepData.elements.find(it => it.itemKey === "experimentName")?.primary_label}</p>
                            </Col>
                            <Col span={18} offset={0}>
                                <Row >
                                    <TextArea
                                        rows={1}
                                        style={{ width: "100%" }}
                                        value={formData.basicInfo.experimentName}
                                        onChange={(e) => { changeHandler(e, "experimentName") }}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: "space-between", margin: "25px" }}>
                            <Col span={6}>
                                <p style={{ margin: "0px" }}>Test Duration</p>
                            </Col>
                            <Col span={18} offset={0}>
                                <Row >
                                    <DateRangePicker
                                        style={{ width: "100%" }}
                                        value={[
                                            moment(formData.basicInfo.time_range[0]),
                                            moment(formData.basicInfo.time_range[1])
                                        ]}
                                        format={dateFormat}
                                        onChange={(e) => { changeHandler(e) }}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card className="custom-ant-card-no-padding" style={{ marginBottom: "20px" }}>
                    <div style={{ height: "52.5vh", overflowY: "auto", }}>
                        <Row style={{ margin: "20px" }}>
                            <Col span={24}>
                                {
                                    formData?.basicInfo?.audience.map((ruleBuilder, index) => (
                                        <>
                                            <div style={{ width: "100%" }}>
                                                <Row>
                                                    <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <Input
                                                            value={ruleBuilder.audience_group_name}
                                                            placeholder={"Audience Group Name"}
                                                            onChange={(e) => editAudienceGroupName("audience", ruleBuilder.identifier, e.target.value)}
                                                        />
                                                        {(formData.basicInfo.audience.length !== 1 && index !== 0) &&
                                                            <img
                                                                src="./img/results/delete.png"
                                                                alt=""
                                                                style={{ width: "30px", cursor: "pointer" }}
                                                                onClick={() => { deleteAudienceGroup("audience", ruleBuilder.identifier) }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='custom-rule-builder'>
                                                        <RuleBuilder
                                                            className='custom-rule-builder'
                                                            withRelationshipInput={false}
                                                            withBackgroundOnRule={false}
                                                            validationType="onChange"
                                                            defaultValue={{
                                                                identifier: 1,
                                                                rules: formData.basicInfo.rule_builder_defaultValue.rules
                                                            }}
                                                            config={config}
                                                            onChange={(e) => ruleBuilderHandler(e, "audience", ruleBuilder.identifier)}
                                                        />
                                                    </div>
                                                </Row>
                                            </div>
                                        </>
                                    ))
                                }
                            </Col>
                        </Row>
                        <Row style={{ margin: "20px" }}>
                            <Button ghost onClick={() => addNewAudiencegroup("audience")}>
                                Add New Audience Group
                            </Button>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default FirstStepRevamp