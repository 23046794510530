import React, { useState, useEffect } from "react";
import { WaveLoading } from "styled-spinkit";
import { Grid, GridItem } from "./../../library/containers/grid";
import * as Comps from "./components";
// import { useHistory } from "react-router-dom"; // for Router 5
import { useNavigate } from "react-router-dom"; // for Router 6
// import { getS3DataNew } from "../transformer";
import DataServiceHelper from "@xai/pages/design-max-ai-applications/service-helper";
// import { UserContext } from "@xai/providers/with-auth";
import TagLineExperimentRevamp from "./TagLineExperimentRevamp";

const MaxAIApplications = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [data, setData] = useState();

  // const history = useHistory(); // for Router 5
  let navigate = useNavigate(); // for Router 6
  const token = "need-some-token-to-work-with-adding-dummy-text-for-now";
  const service = new DataServiceHelper(token);

  const Load = async () => {
    await service
      .getMaxAIApplicationsData()
      .then(async (res) => {
        console.log("Data from S3 max-ai-applications.json", res);
        setData(res);
      })
      .catch((err) => {
        console.log("Error from S3 max-ai-applications.json", err);
      });
  };

  useEffect(() => {
    Load();
  }, []);

  if (selectedTemplate) {
    return (
      <TagLineExperimentRevamp
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
    );
  }

  return (
    <>
      {data ? (
        <div
          style={
            {
              /* width: "70%" */
            }
          }
        >
          {data?.data?.map((sectionObject /* index */) => (
            <>
              <h1 style={{ fontWeight: 400 }}>{sectionObject?.title}</h1>
              {sectionObject?.cards && sectionObject?.cards?.length > 0 && (
                <Grid
                  template={[
                    [
                      "templateCard-1",
                      "templateCard-2",
                      "templateCard-3",
                      "templateCard-4",
                    ],
                  ]}
                >
                  {sectionObject?.cards?.map((template) => (
                    <GridItem
                      key={template?.id}
                      area={`templateCard-${template?.id}`}
                      onClick={() => {
                        if (template?.onClickOpenForm) {
                          setSelectedTemplate(template);
                          return;
                        }

                        if (template?.link) {
                          // history.push(template?.link); // for Router 5
                          navigate(template?.link) // for Router 6
                        }
                      }}
                    >
                      <Comps.TemplateCard templateData={template} />
                    </GridItem>
                  ))}
                </Grid>
              )}
            </>
          ))}
        </div>
      ) : (
        <WaveLoading color="#15afd0" />
      )}
    </>
  );
};

export default MaxAIApplications;
