import React from 'react'
import styled from 'styled-components'

const AspectRatioContainer = styled.div`
    height: 0;
    padding-top: ${props => {
        const split = props.ratio.split(":");
        return split[1]*100/split[0]
    }}%;
    position: relative;
`

const AspectRatioChild = styled.div`
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    > div{
        flex: 1;
    }
`

const AspectRatio = ({ ratio, children }) => {
    if(!ratio || ratio.toLowerCase() === "none"){
        return children
    }

    return (
        <AspectRatioContainer ratio={ratio}>
            <AspectRatioChild>
                {children}
            </AspectRatioChild>
        </AspectRatioContainer>
    )
}

export default AspectRatio
