import React, { useEffect, useState } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import moment from "moment";
import { InputSearch } from "@pai-ui/core/components/input";
import SortableItem from "./Item";
import { UnassignedItem } from "./Item";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";

const genericContainerStyle = {
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  minHeight: "15vh",
};

const deploymentChildContainerStyle = {
  ...genericContainerStyle,
  padding: "0px 10px",
};

const deploymentParentContainerStyle = {
  ...genericContainerStyle,
  padding: "10px",
  // boxShadow: "0px 0px 4px 0px #686868",
  border: "1px solid #3f3f3f",
};

const unassignedChildContainerStyle = {
  ...genericContainerStyle,
  padding: "0px 10px",
  height: "90vh",
  overflowY: "auto",
};

const unassignedParentContainerStyle = {
  ...genericContainerStyle,
  padding: "10px",
  // boxShadow: "0px 0px 4px 0px #686868",
  border: "1px solid #3f3f3f",
  height: "90vh",
};

export default function Container(props) {
  const { id, items, deploymentDates } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  const [filters, setFilters] = useState({
    title_bodycopy: "",
  });
  const [filtersData, setFiltersData] = useState(items);

  const filterChangeHandler = (value, key) => {
    let tempFiltersData = { ...filters };
    tempFiltersData = {
      ...tempFiltersData,
      [key]: value,
    };
    setFilters(tempFiltersData);
  };

  useEffect(() => {
    let tempVariants = [...items];
    tempVariants = tempVariants?.filter(
      (variants) =>
        variants?.subjectLine
          ?.toLowerCase()
          ?.includes(filters?.title_bodycopy?.toLowerCase()) ||
        variants?.bodyCopy
          ?.toLowerCase()
          ?.includes(filters?.title_bodycopy?.toLowerCase())
    );

    setFiltersData(tempVariants);
  }, [filters, items]);

  console.log("container ", { props });

  if (props?.id === "unassigned") {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div style={unassignedParentContainerStyle}>
          {/* <div>Unassigned Variants</div>
           */}
          <div
            style={{
              // margin: "10px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Unassigned Variants
            {
              // filtersData?.length > 0 &&
              <InputSearch
                placeholder={"Search"}
                style={{ width: "200px" }}
                value={filters?.["title_bodycopy"]}
                onChange={(e) => {
                  filterChangeHandler(e?.target?.value, "title_bodycopy");
                }}
              />
            }
          </div>
          <>
            {filtersData.length === 0 && <LegacyNoData height={"82vh"} />}
            <SortableContext
              id={id}
              items={filtersData || []}
              strategy={verticalListSortingStrategy}
            >
              <div
                ref={setNodeRef}
                style={
                  filtersData.length === 0
                    ? {
                        /* ...unassignedChildContainerStyle, */
                        height: "100%",
                        marginTop: "-84vh",
                      }
                    : unassignedChildContainerStyle
                }
              >
                {filtersData?.map((item) => (
                  <UnassignedItem
                    key={item.id}
                    id={item}
                    deploymentDates={deploymentDates}
                  />
                ))}
              </div>
            </SortableContext>
          </>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div style={deploymentParentContainerStyle}>
        <div
          style={{
            // margin: "10px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{`Deployment Date: ${moment(id).format("DD-MMM-YYYY")}`}</span>
          {
            // filtersData?.length > 0 &&
            <InputSearch
              placeholder={"Search"}
              style={{ width: "200px" }}
              value={filters?.["title_bodycopy"]}
              onChange={(e) => {
                filterChangeHandler(e?.target?.value, "title_bodycopy");
              }}
            />
          }
        </div>
        <>
          {filtersData.length === 0 && <LegacyNoData height={"15vh"} />}
          <SortableContext
            id={id}
            items={filtersData || []}
            strategy={verticalListSortingStrategy}
          >
            <div
              ref={setNodeRef}
              style={
                filtersData.length === 0
                  ? {
                      // ...deploymentChildContainerStyle,
                      height: "15vh",
                      marginTop: "-16vh",
                    }
                  : deploymentChildContainerStyle
              }
            >
              {filtersData.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item}
                  deploymentDates={deploymentDates}
                />
              ))}
            </div>
          </SortableContext>
        </>
      </div>
    </div>
  );
}
