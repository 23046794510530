import React from "react";

const SupersetPlaceholder = ({ dashboardId }) => {
  //native_filters=(NATIVE_FILTER-WjmbC8ewV:(__cache:(label:'2021-09-01T00:00:00+00:00',validateStatus:!f,value:!('2021-09-01T00:00:00+00:00')),extraFormData:(filters:!((col:level_1,op:IN,val:!('2021-09-01T00:00:00+00:00')))),filterState:(label:'2021-09-01T00:00:00+00:00',validateStatus:!f,value:!('2021-09-01T00:00:00+00:00')),id:NATIVE_FILTER-WjmbC8ewV,ownState:()))
  //https://pai-kubeflow-sbox.pai.zssandbox.com/ss/superset/dashboard/7/?native_filters=(NATIVE_FILTER-tsUl1X4Yh:(__cache:(label:'ManageRes Bundles (Web)',validateStatus:!f,value:!('ManageRes Bundles (Web)')),extraFormData:(filters:!((col:level_2,op:IN,val:!('ManageRes Bundles (Web)')))),filterState:(label:'ManageRes Bundles (Web)',validateStatus:!f,value:!('ManageRes Bundles (Web)')),id:NATIVE_FILTER-tsUl1X4Yh,ownState:()))

  return (<>
  <iframe
    style={{border:'none', height:'100vh'}}
    title="superset-content"
    src={`${process.env.REACT_APP_SUPERSET_HOST}/superset/dashboard/${dashboardId}/?standalone=2&&show_filters=0`}
    height="100%"
    width="100%"
  />
  </>)
};

export default SupersetPlaceholder;
