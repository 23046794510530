/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useRef, useState } from "react";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import { Collapse, CollapsePanel } from "@pai-ui/core/components/collapse";
import { EditableFormula } from "@pai-ui/core/components/editable-formula";
import "./style.css";
import { Popover } from "@pai-ui/core/components/popover";
import ZsInfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

let aggregators = [
  {
    id: "sum_id",
    name: "SUM",
  },
];
// let kpis = [
//   {
//     id: "OTP Revenue",
//     name: "OTP Revenue",
//   },
//   {
//     id: "Bundle Profit",
//     name: "Bundle Profit",
//   },
//   {
//     id: "#PNR",
//     name: "#PNR",
//   },
//   {
//     id: "Bundle Revenue",
//     name: "Bundle Revenue",
//   },
//   {
//     id: "Total Profit",
//     name: "Total Profit",
//   },
//   {
//     id: "Total Revenue",
//     name: "Total Revenue",
//   },
//   {
//     id: "# of Sessions",
//     name: "# of Sessions",
//   },
//   {
//     id: "Sessions Fulfilled",
//     name: "Sessions Fulfilled",
//   },
//   {
//     id: "OTP Profit",
//     name: "OTP Profit",
//   },
//   {
//     id: "Revenue",
//     name: "Revenue",
//   },
//   {
//     id: "Profit",
//     name: "Profit",
//   },
//   {
//     id: "Incremental Revenue",
//     name: "Incremental Revenue",
//   },
// ];

// eslint-disable-next-line no-unused-vars
function test(str, aggregators, numericalColumns) {
  const entities = [
    ...aggregators.map((a) => ({ value: a, type: "type1" })),
    ...numericalColumns.map((n) => ({ value: n, type: "type2" })),
  ];

  for (const entity of entities) {
    const regex = new RegExp(`\\b${entity.value}\\b`, "g");
    const replacement = `@[${entity.value}](${
      entity.type
    }:${entity.value.replace(/ /g, "")})`;
    str = str.replace(regex, replacement);
  }

  return str;
}

const CustomFormulaEditor = (props) => {
  const ref = useRef();

  const [value, setValue] = useState("");

  // function convertToComplex(str, aggregators, fields) {
  //   // Replace aggregator instances in the string
  //   for (let i = 0; i < aggregators.length; i++) {
  //     let currAggregator = aggregators[i];
  //     let regex = new RegExp(`\\b${currAggregator.name}\\b`, "g"); // match whole word only
  //     str = str.replace(
  //       regex,
  //       `@[${currAggregator.name}](type1:${currAggregator.id})`
  //     );
  //   }

  //   // debugger;
  //   // Replace numerical column instances in the string
  //   for (let i = 0; i < fields.length; i++) {
  //     let column = fields[i];
  //     //   let regex = new RegExp(`\\b[${column}]\\b`, "g"); // match whole word only
  //     str = str.replace(
  //       `[${column.name}]`,
  //       `@[${column.name}](type2:${column.id})`
  //     );
  //   }

  //   return str;
  // }

  // function insertAtCursor(myValue) {
  //   console.log("insertAtCursor", myValue);
  //   let input = ref.current;
  //   if (input.selectionStart || input.selectionStart === 0) {
  //     let startpos = input.selectionStart;
  //     let endpos = input.selectionEnd;
  //     let restoreTop = input.scrollTop;
  //     // eslint-disable-next-line no-debugger
  //     debugger;
  //     let newValue = `${input.value.substring(
  //       0,
  //       startpos
  //     )} ${myValue} ${input.value.substring(endpos, input.value.length)}`;

  //     newValue = convertToComplex(newValue, aggregators, kpis);
  //     console.log("newValue", newValue);
  //     setValue(newValue);

  //     if (restoreTop > 0) {
  //       input.scrollTop = restoreTop;
  //     }
  //     input.focus();
  //     input.selectionStart = startpos;
  //     input.selectionEnd = endpos;
  //   } else {
  //     input.value += myValue;
  //     input.focus();
  //   }
  // }

  const { onChange } = props.formMetaData;
  // {
  //   console.log("EditableFormula", props);
  // }
  return (
    <>
      {props.formItem?.ddnOptions.aggregations?.length &&
        props.formItem?.ddnOptions.kpis?.length && (
          <Row>
            <Col span={11}>
              <EditableFormula
                inputStyle={{
                  control: {
                    fontSize: 14,
                    height:'38vh'
                  },
                  input: {
                    color: 'transparent',
                    caretColor: 'white',
                  },
                }}
                onChange={(e) => {
                  console.log("main onChange", e);
                  onChange(props.item.fieldName, e);
                }}
                defaultValue={props.formMetaData?.value}
                // columnsMentionProps={{
                //   appendSpaceOnAdd: true,
                // }}
                classes={{
                  component: "pai-editable-formula-component",
                  entityAggregation: "pai-entity-aggregation",
                  entityCommand: "pai-entity-fields",
                }}
                placeholder="Write formula"
                aggregations={{
                  trigger: /.?(@([a-zA-Z0-9]*))$/,
                  backgroundColor: "transparent",
                  list: props.formItem?.ddnOptions.aggregations,
                }}
                columns={{
                  backgroundColor: "transparent",
                  list: props.formItem?.ddnOptions.kpis?.map((x) => ({
                    ...x,
                    nameForInputDisplay: `[${x.name}]`,
                  })),
                }}
              />
              <div className='instruction-container'>
              <div>Note1:</div>
              <div>
                <span>
                  {" "}
                  <ZsInfoCircleOutlined
                    style={{ color: "#15afd0", fontSize: 13 }}
                  />
                </span>
                <span style={{ color: "rgba(255, 255, 255,0.6)", marginLeft:'1%' }}>
                  Start typing with &apos;@&apos; for Aggregation and
                  &apos;[&apos; for Columns suggestions
                </span>
              </div>
              </div>
            </Col>
            <Col
              span={11}
              offset={1}
              style={{ height: "50vh", overflow: "hidden auto" }}
            >
              <Collapse
                defaultActiveKey={["aggregations", "numeric-columns"]}
                collapsible="header"
                bordered={false}
                className="config-kpi-collapse"
              >
                <CollapsePanel
                  key="aggregations"
                  className="editable-formula-collaps-panel"
                  header={
                    <>
                      <span style={{ paddingRight: "5px" }}>Aggregations</span>
                      <Popover
                        placement="bottom"
                        title={""}
                        content={
                          "Start typing with '@' to get Aggregation suggestions"
                        }
                        trigger="hover"
                      >
                        <ZsInfoCircleOutlined
                          style={{ color: "#15afd0", fontSize: 13 }}
                        />
                      </Popover>
                    </>
                  }
                >
                  {props.formItem?.ddnOptions.aggregations.map((agg, i) => {
                    return (
                      <div
                        key={"agg-" + i}
                        className="predefined-collapse-item"
                        // style={{}}
                        // onClick={() => {
                        //   insertAtCursor(agg.name);
                        // }}
                      >
                        <span style={{ color: "#15afd0" }}>#</span>
                        <span className="kpi-accordion-item">{agg.name}</span>
                      </div>
                    );
                  })}
                </CollapsePanel>
                <CollapsePanel
                  key="numeric-columns"
                  header={
                    <>
                      <span style={{ paddingRight: "5px" }}>
                        Numeric Columns
                      </span>
                      <Popover
                        placement="bottom"
                        title={""}
                        content={
                          "Start typing with '[' to get Columns suggestions"
                        }
                        trigger="hover"
                      >
                        <ZsInfoCircleOutlined
                          style={{ color: "#15afd0", fontSize: 13 }}
                        />
                      </Popover>
                    </>
                  }
                >
                  {props.formItem?.ddnOptions.kpis.map((nc, i) => {
                    return (
                      <div
                        key={"nc-" + i}
                        className="predefined-collapse-item"
                        // onClick={() => {
                        //   insertAtCursor(`[${nc.name}]`);
                        // }}
                      >
                        <span style={{ color: "#15afd0" }}>#</span>
                        <span className="kpi-accordion-item">{nc.name}</span>
                      </div>
                    );
                  })}
                </CollapsePanel>
              </Collapse>
            </Col>
          </Row>
        )}
    </>
  );
};

export default CustomFormulaEditor;
