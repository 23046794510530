import React, { useEffect, useState } from "react";
import {
  Visualization,
  PieChartConfig,
} from "@pai-ui/core/components/visualization";
import { Button } from "@pai-ui/core/components/button";
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import ExecutiveServiceHelper from "@xai/pages/executive-at-a-glance-overview/service-helper";
import { getRadarVizChartConfig } from "@xai/pages/executive-at-a-glance-overview/transformer";

const OverviewRadarVizChart = ({ filters }) => {
  const { token } = React.useContext(UserContext);
  const service = new ExecutiveServiceHelper(token);

  const [loading, setLoading] = useState(true);
  const [radarVizChartData, setRadarVizChartData] = useState(null);

  //States for Error Handling
  const [radarVizChartError, setRadarVizChartError] = useState(false);

  const loadRadarVizChartData = async (/* prop */) => {
    let dataconfig = getRadarVizChartConfig(
      filters?.dateRange?.[0],
      filters?.dateRange?.[1]
    );
    console.log("loadRadarVizChartData call", dataconfig);
    setRadarVizChartError(false);
    await service
      .getExperimnetsRadarChartData(dataconfig)
      .then(async (res) => {
        console.log("loadRadarVizChartData res", res);
        setRadarVizChartData(res);
      })
      .catch((err) => {
        console.log("loadRadarVizChartData Error", err);
        setRadarVizChartError(true);
      })
      .finally(() => {
        // setLoading(false);
        // #TODO : Below code to be commented later during actual api integration and use the above.
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  };

  useEffect(() => {
    if (filters?.dateRange?.length !== 0) {
      loadRadarVizChartData();
    }
  }, [filters]);

  return loading ? (
    <LegacyNoData height={"33vh"} loading={true} />
  ) : radarVizChartError ? (
    <LegacyNoData height={"33vh"} error={true}>
      <Button onClick={() => loadRadarVizChartData()}>Try Again</Button>
    </LegacyNoData>
  ) : (
    // <div>
    //   <span>OverviewRadarVizChart</span>
    // </div>
    <div width={"100%"}>
      <div
        style={{
          position: "relative",
          width: "inherit",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: "40",
            margin: "38% auto 0",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h2
            style={{
              margin: 0,
              color: "#FFF",
              fontSize: "24px",
              fontWeight: "400",
              fontFamily: "SF Display",
              textAlign: "center",
              lineHeight: "1.5rem",
            }}
          >
            {radarVizChartData?.experimentCount}
            {/* 56 */}
          </h2>
          <div
            style={{
              fontSize: "12px",
              color: "rgb(21, 175, 208)",
              textAlign: "center",
            }}
          >
            Experiments
          </div>
        </div>
      </div>
      <Visualization
        height={"33vh"}
        dataConfig={{
          structureType: "processed",
        }}
        viewConfig={
          new PieChartConfig({
            outerRadius: "97%",
            innerRadius: "73%",
            series: {
              getConfig: (fieldName) => {
                const config = {
                  enigma: {
                    name: "Digital",
                  },
                  space: {
                    name: "Marketing",
                  },
                  roof: {
                    name: "Product",
                  },
                  lemon: {
                    name: "Store Layout and Operations",
                  },
                  universalMusic: {
                    name: "OmniChannel Strategy",
                  },
                };

                return config[fieldName];
              },
            },
          })
        }
        dataset={
          radarVizChartData
          // {
          //     data: [
          //       {
          //         enigma: 2002,
          //         space: 200,
          //         roof: 90,
          //         lemon: 300,
          //         universalMusic: 23,
          //       },
          //     ],
          // }
        }
      />
    </div>
  );
};

export default OverviewRadarVizChart;
