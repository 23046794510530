import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { CSVImporter, ExcelImporter } from "@xai/library/legacy/popups";
import DataServiceHelper from "@xai/pages/data-data/service-helper";
import { UserContext } from "@xai/providers/with-auth";
import { WaveLoading } from "styled-spinkit";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";

const TileList = ({ title, children }) => [
  <>
    <h3 style={{ color: "#FFF", margin: "0 0 15px 0" }} key={"key"}>{title}</h3>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: 20,
      }}
    >
      {children}
    </div>
  </>
];

const importers = {
  text: CSVImporter,
  excel: ExcelImporter,
};

class Tile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }

  render() {
    const { name, title, png, bordered } = this.props;
    const Importer = importers[name];

    return [
      <div
        key={"key"}
        className="hover-tile"
        onClick={() => this.setState({ modal: true })}
        style={{
          width: 160,
          border: bordered ? "1px solid #1890ff2e" : "",
          height: 160,
          maxWidth: 160,
          maxHeight: 160,
          marginRight: 15,
          marginBottom: 15,
          flexShrink: 0,
          background: "rgba(255,255,255,0.05)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {png && <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/data/${name}.png`} width="125" />}
        {!png && (
          <SVG src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/data/${name}.svg`} width="75" className="blue" />
        )}
        {title && (
          <p
            style={{
              marginBottom: 0,
              marginTop: 2,
              color: "#FFF",
              fontSize: "14px",
              letterSpacing: "0px",
            }}
          >
            {title}
          </p>
        )}
      </div>,
      <Modal
        key={"key"}
        visible={this.state.modal}
        centered
        onCancel={() => this.setState({ modal: false })}
        onClickOutside={() => this.setState({ modal: false })}
        header={[]}
        footer={[
          <Button key="back" onClick={() => this.setState({ modal: false })}>
            Import Another
          </Button>,
          <Link to={`../../tests`} key={"key"}>
            <a href="/" key={"key"}>
              <Button key="submit" type="primary">
                Next
              </Button>
            </a>
          </Link>,
        ]}
      >
        {Importer && (
          <Importer
            onComplete={() => {
              this.setState({
                modal: false,
              });
              window.location.href = "/";
            }}
          />
        )}
      </Modal>,
    ];
  }
}

const DataView = () => {

  const [data, setData] = useState()
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);


  const { token } = React.useContext(UserContext);
  const service = new DataServiceHelper(token);

  const load = async () => {
    await service.getDataScreenData()
      .then(async (res) => {
        console.log('getDataScreenData Data', res);
        setData(res);
        // setLineChartData(null);
        // setload(false);
      })
      .catch((err) => {
        console.log('getDataScreenData Error', err);
      });
  }
  useEffect(() => {
    load()
  }, []);

  return (data && data?.config ?
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: "879px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "calc(111vh - 83px)",
      }}
      className={'opacity-0-55'}
    >
      <GenericModalWithResultComponent
        isRequestModalOpen={isRequestModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
      />
      {
        data.config.map((item) => (
          <>
            <TileList title={item.title}>
              {
                item.list.map((i, index) => (
                  <Tile key={index} name={i.name} title={i.title} png={i.png ? i.png : ""} />
                ))
              }
            </TileList>
          </>
        ))
      }
    </div> : <WaveLoading color="#15afd0" />
  );
};

export default DataView;
