import React from "react";
import "antd/dist/antd.css";
import { Card } from "antd";

const { Meta } = Card;

export const ZsMeta = (props) => {
  return <Meta {...props} />;
};

export const ZsCard = (props) => {
  return (
    <React.Fragment>
      <Card {...props}>{props.children}</Card>
    </React.Fragment>
  );
};
// export default ZsCard;
