import * as DataService from "@xai/services/Data";

class DataIntegrationsServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getIntegrations() {
    const data = await DataService.getIntegrations(
      this.authToken
    );
    if (!data) return null;
    return data;
  }
}

export default DataIntegrationsServiceHelper;
