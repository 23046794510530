import React, { useEffect } from "react";
import { Grid, GridItem } from "@xai/library/containers/grid";
import { UserContext } from "@xai/providers/with-auth";
import { SegmentImpactExplorerTable } from "./components/segment-impact-explorer-table";
import { SegmentImpactExplorerTopFilterSection } from "./components/top-filter-section";

const MeasureResultsSegmentImpactExplorerTab = ({
  segmentImpactExplorerTabParams,
  segmentimpactTabFilters,
  setSegmentimpactTabFilters,
  segmentimpactTabData,
  // segmentImpactTabTableError,
  loadSegmentImpactExplorerTabData,
  // segmentImpactDataLoading,
  exportSegmentImpactExplorerTabData
}) => {
  const {
    selectedControlGroup,
  } = segmentimpactTabFilters;
  const userState = React.useContext(UserContext);

  // console.log("MeasureResultsTab", userState.experimentModule);
  const ExperimentResponseMetric = userState?.experimentModule?.selectedExperiment?.response_metric;
  const load = () => {
    if (segmentImpactExplorerTabParams?.data) {
      // set default values
      setSegmentimpactTabFilters({
        selectedSegment: segmentImpactExplorerTabParams?.data?.segment_list?.[0],
        selectedParameter: segmentImpactExplorerTabParams?.data?.parameter_list?.includes(ExperimentResponseMetric) ? ExperimentResponseMetric : segmentImpactExplorerTabParams?.data?.parameter_list?.[0],
        selectedControlGroup:
          segmentImpactExplorerTabParams?.data
            ?.variant_groups_control_test_list?.[0]?.["id"],
      });
    }
  };

  useEffect(() => {
    console.log(
      "change oon segmentImpactExplorerTabParams",
      segmentImpactExplorerTabParams,
      segmentimpactTabFilters,
      segmentimpactTabData
    );
    load();
  }, [segmentImpactExplorerTabParams?.data]);

  useEffect(() => {
    if (selectedControlGroup) {
      setSegmentimpactTabFilters({
        ...segmentimpactTabFilters,
        selectedTestGroup:
          segmentImpactExplorerTabParams?.data?.variant_groups_control_test_list?.find(
            (option) => option.id === selectedControlGroup
          )?.["test_groups"]?.[0]?.["id"],
      });
    }
  }, [selectedControlGroup]);

  console.log(segmentimpactTabData, 'segmentimpactTabData');

  return (
    <>
      {/* <span>MeasureResultsSegmentImpactExplorerTab</span> */}
        <Grid template={[["top-row"], ["SegmentImpactExplorer-table"]]}>
          <GridItem area="top-row">
            <SegmentImpactExplorerTopFilterSection
              segmentImpactExplorerTabParams={segmentImpactExplorerTabParams?.data}
              segmentimpactTabFilters={segmentimpactTabFilters}
              setSegmentimpactTabFilters={setSegmentimpactTabFilters}
              exportSegmentImpactExplorerTabData={exportSegmentImpactExplorerTabData}
              segmentimpactTabData={segmentimpactTabData?.data?.segment_table_data}
            />
          </GridItem>
          <GridItem area="SegmentImpactExplorer-table">
            <SegmentImpactExplorerTable
              segmentImpactExplorerTabParams={segmentImpactExplorerTabParams}
              segmentimpactTabFilters={segmentimpactTabFilters}
              // segmentimpactTabData={segmentimpactTabData?.segment_table_data}
              segmentimpactTabData={segmentimpactTabData}
              // segmentImpactDataLoading={segmentImpactExplorerTabParams?.loading}
              // segmentImpactTabTableError={segmentImpactExplorerTabParams?.error}
              loadSegmentImpactExplorerTabData={loadSegmentImpactExplorerTabData}
            />
          </GridItem>
        </Grid>
    </>
  );
};

export default MeasureResultsSegmentImpactExplorerTab;
