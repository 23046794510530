import React from 'react'
import { Tabs } from '@pai-ui/core';
import { Button } from '@pai-ui/core/components/button';
import TabContent from "./TabContent.js";
import { message } from 'antd';
import { EmptyData } from '@pai-ui/core/components/empty-data';

const TabGrid = ({ firstStepTagLinesList, setfirstStepTagLinesList, showList, noList, APIErrorFlag, generateHandler }) => {

    // const { TabPane } = Tabs;

    const tabMenu = [
        {
            tab: 'All',
            id: 'All'
        },
        {
            tab: 'Favorites',
            id: 'Favorites'
        }
    ];

    const [activeTab, setActiveTab] = React.useState(tabMenu[0].id);
    const [APIRetriggerCount, setAPIRetriggerCount] = React.useState(0);

    const onTabChange = (key) => {
        setActiveTab(key);
    }
    if (APIErrorFlag) {
        return (
            <EmptyData error={true}>
                <Button
                    onClick={() => {
                        APIRetriggerCount < 2 && generateHandler();
                        APIRetriggerCount < 2 ? setAPIRetriggerCount(APIRetriggerCount => APIRetriggerCount + 1) : message.error("Something went wrong, kindly try again after some time!!");
                    }}>
                    Try Again
                </Button>
            </EmptyData>
        );
    }

    return (
        <>
            <Tabs
                items={tabMenu}
                defaultActiveKey={activeTab}
                onChange={onTabChange}
            />
            {activeTab === 'All' && (
                <TabContent data={firstStepTagLinesList} setfirstStepTagLinesList={setfirstStepTagLinesList} showList={showList} noList={noList} />
            )}
            {activeTab === 'Favorites' && (
                <TabContent data={firstStepTagLinesList.filter(it => it.fav === true)} setfirstStepTagLinesList={setfirstStepTagLinesList} showList={showList} noList={noList} />
            )}
        </>
    )
}

export default TabGrid