import React, { Component } from 'react'
import { Steps, Button } from 'antd'
import { Panel } from './layout';
const { Step } = Steps

class Wizard extends Component {
    constructor(props){
        super(props)

        this.state = {
            currentStep: 0
        }
    }

    incrementStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    decrementStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        })
    } 

    render() {
        const StepComponent = this.props.steps[this.state.currentStep].component
        return [
            <Panel paddingY  background="#181b20" round key={"key"}>
                <Panel paddingX paddingY>
                    <Steps size="small" current={this.state.currentStep}>
                        {this.props.steps.map(screen => (
                            <Step key={"key"} title={screen.title} />
                        ))}
                    </Steps>
                </Panel>
                <Panel flexible paddingY background="#181b20">
                    <StepComponent moveNext={this.incrementStep} />
                </Panel>
                {this.props.steps[this.state.currentStep].buttons !== false && 
                <Panel stack="horizontal" paddingY center>
                    {this.state.currentStep !== 0 && <Button onClick={this.decrementStep} style={{margin:"0 5px"}}>Previous</Button>}
                    {this.state.currentStep !== this.props.steps.length - 1 && <Button onClick={this.incrementStep} style={{margin:"0 5px"}}>Next</Button>}
                    {this.state.currentStep == this.props.steps.length - 1 && <Button type="primary" onClick={()=>{
                        this.props.onFinish()
                        this.setState({
                            currentStep: 0
                        })
                    }} style={{margin:"0 5px"}}>Finish</Button>}
                </Panel>
                }
            </Panel>
        ]
    }

}

export default Wizard