import React from "react";
import { EmptyData } from "@pai-ui/core/components/empty-data";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import WaveLoader from "@xai/assets/generic-components/wave-loader";

const LegacyNoData = ({
  height = "71vh",
  imageUrl,
  description,
  children,
  error,
  loading,
}) => {
  return (
    <div style={{ height: height }}>
      <LegacyPanel
        className={"hover-section"}
        round
        disableOverflow
        style={{ height: "inherit" }}
      >
        <LegacyPanel
          flexible
          background="rgba(255,255,255,0.03)"
          paddingX
          // paddingY
        >
          <LegacyPanel
            flexible
            // paddingY
            center
          >
            {loading && <WaveLoader />}
            {error && (
              <EmptyData
                image={imageUrl}
                description={description || "Something went wrong...!"}
              >
                {children}
              </EmptyData>
            )}
            {!loading && !error && <EmptyData style={{ margin: "0" }} />}
          </LegacyPanel>
        </LegacyPanel>
      </LegacyPanel>
    </div>
  );
};

export default LegacyNoData;
