import React from "react";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Metric } from "@pai-ui/core/components/metric";
import { Ellipsis } from "@pai-ui/core/components/ellipsis";
import { Button } from "@pai-ui/core/components/button";
import { Loading } from "@pai-ui/core/components/loading";
import moment from "moment";
import lodash from "lodash";
import { Card } from "@pai-ui/core/components/card";
import { Tag } from "@pai-ui/core/components/tag";
import { message } from "@pai-ui/core/components/message";
import { MetricClass } from "@xai/assets/common/cssClasses";
import { statusEnum } from "@xai/assets/common/enums";
import { UserContext } from "@xai/providers/with-auth";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";
import { postTableDataTransformer } from "@xai/pages/design-variant-bank-approval/components/design-variant-bank-approval-table/transformer";

export const VariantCard = ({ variant, ExperimentTagGroups }) => {
  const { token, experimentModule } = React.useContext(UserContext);
  const service = new DesignVariantBankApprovalServiceHelper(token);

  const [variantObj, setVariantObj] = React.useState(variant);
  const [actionLoading, setActionLoading] = React.useState(false);

  const dateFormat = "DD-MMM-YYYY";
  const [columnSpan, setColumnSpan] = React.useState({
    title: null,
    titlewidth: null,
    bodycopy: null,
    bodycopywidth: null,
    // status: 4,
    // deploymentdetails: 4,
    // deactivatevariant: 3,
    // #TODO : To be changed back, when deactivate variant inf is planned to be added.
    status: 7,
    deploymentdetails: 4,
    deactivatevariant: null,
  });
  const localStatusEnum = {
    deployed: "Active",
    deactivated: `Deactivated on ${moment(variantObj?.deactivatedDate).format(
      dateFormat
    )}`,
    Queued: `To be Deactivated`,
  };

  const checkStatus = () => {
    let status = variantObj?.action_status;
    if (
      !variantObj?.deactivatedDate &&
      variantObj?.action_status.toLowerCase() === "deactivated"
    ) {
      status = "Queued";
    }
    return status;
  };

  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;

  React.useEffect(() => {
    if (experimentTextVariantsType) {
      let tempColSpan = { ...columnSpan };
      switch (experimentTextVariantsType?.toLowerCase()) {
        case "subjectlineonly":
          tempColSpan = {
            ...tempColSpan,
            title: 13,
            bodycopy: 0,
            titlewidth: "45vw",
          };
          break;
        case "bodycopyonly":
          tempColSpan = {
            ...tempColSpan,
            title: 0,
            bodycopy: 13,
            bodycopywidth: "45vw",
          };
          break;
        case "subjectlineandbodycopy":
          tempColSpan = {
            ...tempColSpan,
            title: 6,
            bodycopy: 7,
            titlewidth: "20vw",
            bodycopywidth: "23vw",
          };
          break;
      }
      setColumnSpan(tempColSpan);
    }
  }, [experimentTextVariantsType]);

  const updateVariantActionStatus = async () => {
    setActionLoading(true);
    let updatedVariant = { ...variantObj };
    let newStatus =
      variantObj?.action_status?.toLowerCase() === "deployed"
        ? "deactivated"
        : variantObj?.action_status?.toLowerCase() === "deactivated"
        ? "deployed"
        : "deployed";
    updatedVariant.action_status = newStatus;
    console.log("updateVariantActionStatus", variantObj, updatedVariant);

    const finalDataSet = await postTableDataTransformer([updatedVariant]);
    console.log("handle updateVariantActionStatus api call", finalDataSet);

    // API Call to be made for updateVariantDeploymentDate
    await service
      .bulkUpdateExperimentVarianBankList(finalDataSet)
      .then((res) => {
        console.log(
          "updateVariantActionStatus bulkUpdateExperimentVarianBankList res",
          res
        );
        setVariantObj(updatedVariant);
        message["success"]("Variant Status modified successfully!");
      })
      .catch((err) => {
        console.log(
          "updateVariantDeploymentDate bulkUpdateExperimentVarianBankList Error",
          err
        );
        message["error"]("Variant Status modification failed");

        // On error show toaster that notifies user about failure on approve click.
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const ActionButtonTitle = () =>
    variantObj?.deactivatedDate
      ? "Deactivated"
      : variantObj?.action_status?.toLowerCase() === "deployed"
      ? "Deactivate"
      : "Reactivate";

  return (
    <>
      {experimentTextVariantsType ? (
        <Card bordered={false}>
          <Row span={24} align="middle">
            <Col span={columnSpan?.title} style={{ alignSelf: "flex-start" }}>
              <Metric
                withBackground={false}
                classes={MetricClass}
                title="Subject Line"
                subTitle={
                  <>
                    <Ellipsis
                      maxWidth={columnSpan?.titlewidth}
                      text={variantObj?.subjectLine}
                    />
                    <br />
                    {variantObj?.variantTitleTags?.map((varTag, index) => {
                      return (
                        <Tag
                          key={index}
                          color={
                            ExperimentTagGroups?.find(
                              (tagObj) =>
                                tagObj?.tag_group?.toLowerCase() ===
                                varTag?.tag_group?.toLowerCase()
                            )?.tag_color /* tag_group tag?.color */
                          }
                          size="small"
                        >
                          {varTag?.name}
                        </Tag>
                      );
                    })}
                  </>
                }
              />
            </Col>
            <Col
              span={columnSpan?.bodycopy}
              style={{ alignSelf: "flex-start" }}
            >
              <Metric
                withBackground={false}
                classes={MetricClass}
                title="Body Copy"
                style={{ width: "inherit" }}
                subTitle={
                  <>
                    <Ellipsis
                      maxWidth={columnSpan?.bodycopywidth}
                      text={variantObj?.bodyCopy}
                    />
                    <br />
                    {variantObj?.variantBodyContentTags?.map(
                      (varTag, index) => {
                        return (
                          <Tag
                            key={index}
                            color={
                              ExperimentTagGroups?.find(
                                (tagObj) =>
                                  tagObj?.tag_group?.toLowerCase() ===
                                  varTag?.tag_group?.toLowerCase()
                              )?.tag_color /* tag_group tag?.color */
                            }
                            size="small"
                          >
                            {varTag?.name}
                          </Tag>
                        );
                      }
                    )}
                  </>
                }
              />
            </Col>
            <Col span={columnSpan?.status} style={{ alignSelf: "flex-start" }}>
              <Metric
                withBackground={false}
                classes={MetricClass}
                title="Status"
                subTitle={
                  // below variant is not changed to variantObj, since the intial loaded status is default and
                  localStatusEnum[variantObj?.action_status] ? (
                    <Tag
                      color={
                        Object.values(statusEnum)?.find(
                          (enumObj) =>
                            enumObj?.name === lodash.upperFirst(checkStatus())
                        )?.color
                      }
                    >
                      {localStatusEnum?.[checkStatus()]}
                    </Tag>
                  ) : (
                    "--"
                  )
                }
              />
            </Col>
            <Col
              span={columnSpan?.deploymentdetails}
              style={{ alignSelf: "flex-start" }}
            >
              <Metric
                withBackground={false}
                classes={MetricClass}
                title="Details"
                subTitle={
                  <>
                    {
                      <>
                        {`Approved  : ${
                          variantObj?.approvedDate
                            ? moment(variantObj?.approvedDate).format(
                                dateFormat
                              )
                            : "--"
                        }`}
                        <br />
                      </>
                    }
                    {
                      <>
                        {`Deployed  : ${
                          variantObj?.deploymentDate
                            ? moment(variantObj?.deploymentDate).format(
                                dateFormat
                              )
                            : "--"
                        }`}
                      </>
                    }
                    {/* {variantObj?.deploymentDate ? (
                      <div>{`Deployed on ${moment(
                        variantObj?.deploymentDate
                      ).format(dateFormat)}`}</div>
                    ) : (
                      "--"
                    )} */}
                  </>
                }
              />
            </Col>
            {columnSpan?.deactivatevariant && (
              <Col
                span={columnSpan?.deactivatevariant}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  label={ActionButtonTitle()}
                  disabled={variantObj?.deactivatedDate}
                  title={ActionButtonTitle()}
                  onClick={updateVariantActionStatus}
                  loading={actionLoading}
                  ghost={
                    variantObj?.action_status.toLowerCase() === "deactivated"
                  }
                />
              </Col>
            )}
          </Row>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};
