import React, {
  useState,
  useEffect /* , useRef, createContext, useContext */,
} from "react";
import download from "downloadjs";
import Highlighter from "react-highlight-words";
import { Download as ExportCsv } from "@styled-icons/boxicons-regular/Download";
import { Upload as ImportCsv } from "@styled-icons/boxicons-regular/Upload";
import { SearchOutlined } from "@ant-design/icons";
// import Input from "antd/lib/input";
import Form from "antd/lib/form";
import InputNumber from "antd/lib/input-number";
// import Popconfirm from "antd/lib/popconfirm";
// import Typography from "antd/lib/typography";

// import { Form } from "@pai-ui/core/components/form";
import { Input } from "@pai-ui/core/components/input";
// import { InputNumber } from "@pai-ui/core/components/input-number";
// import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
// import { Typography } from "@pai-ui/core/components/typography";
import { TextArea } from "@pai-ui/core/components/text-area";
import { palette } from "@pai-ui/core/theme/units/palette";
import { Button } from "@pai-ui/core/components/button";
import { Space } from "@pai-ui/core/components/space";
import { Tag } from "@pai-ui/core/components/tag";
import {
  Visualization,
  TableConfig,
} from "@pai-ui/core/components/visualization";
import { UserContext } from "@xai/providers/with-auth";
// import { Grid, GridItem } from "@xai/library/containers/grid";
import { isArrayOfObjectsEqual } from "@xai/pages/transformer";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";
import { FileUploadPopUp } from "@xai/assets/generic-components/file-upload-popup";
import { getColumnConfig } from "./transformer";

export const exportCsv_ = (
  <ExportCsv
    width={30}
    height={30}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);

const importCsv_ = (
  <ImportCsv
    width={30}
    height={30}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);

const EditableCell = ({
  editing,
  dataKey,
  title,
  inputType,
  // eslint-disable-next-line no-unused-vars
  record,
  // eslint-disable-next-line no-unused-vars
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "textarea" ? (
      <TextArea rows={3} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataKey}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: ["comments"].indexOf(dataKey) > -1 ? false : true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const DesignVariantBankApprovalTable = ({
  selectedTab,
  tableData,
  updateTableData,
  tabButtonStatus,
  globalSearchText,
  selectedTags,
  tagFilters,
  loadVariantBankList,
}) => {
  // const userState = React.useContext(UserContext);
  const { token, experimentModule } = React.useContext(UserContext);
  const service = new DesignVariantBankApprovalServiceHelper(token);
  const Experiment_Id = experimentModule?.selectedExperiment?.id;
  const Experiment_Name = experimentModule?.selectedExperiment?.name;
  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;

  // console.log("DesignVariantBank", userState.experimentModule);

  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [filterdData, setFilterdData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [editingKey, setEditingKey] = useState("");

  const [showDialog, setShowDialog] = React.useState(false);

  const isEditing = (record) => record.variantbankId === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      subjectLine: "",
      bodyCopy: "",
      action_status: "",
      comments: "",
      ...record,
    });
    setEditingKey(record.variantbankId);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.variantbankId);
      console.log("save", key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  /**
   *
   */
  const handleAction = (record, action, additionalFeedbackFromPopup) => {
    const row = {
      ...record,
      action_status: action,
      comments: !record?.comments
        ? additionalFeedbackFromPopup
        : record?.comments.concat(" ... \n\n", additionalFeedbackFromPopup),
    };

    const newData = [...data];
    const index = newData.findIndex(
      (item) => record.variantbankId === item.variantbankId
    );
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    console.log("handleAction", record.variantbankId, record);
    setData(newData);
  };

  // const mergedColumns = getColumnConfig(
  //   isEditing,
  //   editingKey,
  //   edit,
  //   cancel,
  //   save
  // );

  useEffect(() => {
    setTableLoading(true);
    // console.log("useEffect selectedTab, tabButtonStatus", selectedTab, data);
    if (selectedTab === "draftVariants") {
      setData(JSON.parse(JSON.stringify(tableData?.modifiedDraftDataList)));
    } else if (selectedTab === "approvedVariants") {
      setData(JSON.parse(JSON.stringify(tableData?.modifiedApprovedDataList)));
    } else if (selectedTab === "deployedVariants") {
      setData(JSON.parse(JSON.stringify(tableData?.deployedDataList)));
    } else if (selectedTab === "deactivatedVariants") {
      setData(JSON.parse(JSON.stringify(tableData?.deactivatedDataList)));
    } else {
      setData([]);
    }
  }, [selectedTab, tabButtonStatus]);

  useEffect(() => {
    if (
      data &&
      ((!isArrayOfObjectsEqual(
        data,
        JSON.parse(JSON.stringify(tableData?.modifiedDraftDataList))
      ) &&
        selectedTab === "draftVariants") ||
        (!isArrayOfObjectsEqual(
          data,
          JSON.parse(JSON.stringify(tableData?.modifiedApprovedDataList))
        ) &&
          selectedTab === "approvedVariants") ||
        (!isArrayOfObjectsEqual(
          data,
          JSON.parse(JSON.stringify(tableData?.deployedDataList))
        ) &&
          selectedTab === "deployedVariants") ||
        (!isArrayOfObjectsEqual(
          data,
          JSON.parse(JSON.stringify(tableData?.deactivatedDataList))
        ) &&
          selectedTab === "deactivatedVariants"))
    ) {
      updateTableData(data);
    }
    // console.log("useEffect data", data);
  }, [data]);

  useEffect(() => {
    let selectedTagsArray = [];
    selectedTags?.forEach((tag) => {
      tag?.length > 1
        ? selectedTagsArray.push(tag[1])
        : tagFilters
          ?.find((tagGroup) => tagGroup?.value === tag[0])
          ?.children?.forEach((tag) => {
            selectedTagsArray.push(tag?.value);
          });
    });

    let filteredRecords = data
      ?.filter((record) => {
        // Filter records by combining various text props and filtering on this combined test
        let text_toCheck = "".concat(
          record?.subjectLine,
          " ",
          record?.variantTitleTags?.map((x) => x.name).join(" |||| "),
          " ",
          record?.bodyCopy,
          " ",
          record?.variantBodyContentTags?.map((x) => x.name).join(" |||| "),
          " ",
          record?.comments,
          " ",
          record?.action_status?.toLowerCase() === "approved"
            ? record?.experiment_variant_bank_user_logs
              ?.sort((a, b) => Date.parse(b.time) - Date.parse(a.time))
              ?.find(
                (statusObj) => statusObj?.action?.toLowerCase() === "approved"
              )?.userName || ""
            : " "
        );

        return text_toCheck
          ? text_toCheck
            ?.toString()
            .toLowerCase()
            .includes(globalSearchText?.toLowerCase())
          : "";
      })
      ?.filter((record) =>
        selectedTagsArray?.length == 0
          ? true
          : // Ref: https://stackoverflow.com/a/39893636/4672396
          record?.variantTitleTags?.some(
            (tags) => selectedTagsArray?.indexOf(tags?.name) >= 0
          ) ||
          record?.variantBodyContentTags?.some(
            (tags) => selectedTagsArray?.indexOf(tags?.name) >= 0
          )
      );

    console.log(
      "useEffect globalSearchText",
      globalSearchText,
      selectedTags,
      tagFilters,
      selectedTagsArray,
      data,
      filteredRecords
    );
    setFilterdData(filteredRecords);
    setTableLoading(false);
  }, [data, globalSearchText, selectedTags]);

  /** */

  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm();
    setState({
      searchText: "",
      searchedColumn: dataIndex,
    });
  };

  const getTitleTagColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block", width: "100%" }}
        />
        <Space>
          <Button
            // type="primary"
            ghost
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            ghost
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      let text_toCheck = record?.[dataIndex];
      let tagtext = record?.[
        dataIndex === "subjectLine"
          ? "variantTitleTags"
          : dataIndex === "bodyCopy"
            ? "variantBodyContentTags"
            : ""
      ]
        ?.map((x) => x.name)
        .join(" |||| ");
      // combine title and tags text
      text_toCheck = text_toCheck?.concat(" ", tagtext);
      // console.log(
      //   "onFilter",
      //   text_toCheck,
      //   tagtext,
      //   text_toCheck?.toString().toLowerCase().includes(value.toLowerCase())
      // );

      /** */

      return text_toCheck
        ? text_toCheck?.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this?.searchInput && this?.searchInput?.select(), 100);
      }
    },
    render: (_, record) => {
      return (
        <>
          <div style={{ marginBottom: "5px" }}>
            {state.searchedColumn === dataIndex ? (
              <>
                <Highlighter
                  highlightStyle={{
                    backgroundColor: palette().primary,
                    padding: 0,
                  }}
                  searchWords={[state.searchText]}
                  autoEscape
                  textToHighlight={_ ? _.toString() : ""}
                />
              </>
            ) : (
              <div>
                <span>{_}</span>
              </div>
            )}
          </div>
          <div>
            {record?.[
              dataIndex === "subjectLine"
                ? "variantTitleTags"
                : dataIndex === "bodyCopy"
                  ? "variantBodyContentTags"
                  : ""
            ]?.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  color={
                    ExperimentTagGroups?.find(
                      (tagObj) =>
                        tagObj?.tag_group?.toLowerCase() ===
                        tag?.tag_group?.toLowerCase()
                    )?.tag_color /* tag_group tag?.color */
                  }
                  size="small"
                >
                  {tag?.name}
                </Tag>
              );
            })}
          </div>
        </>
      );
    },
  });

  const csvExportFeature = async () => {
    console.log(
      "csvExportFeature",
      "experiment_id",
      "action_status list",
      "templete"
    );
    // #TODO : API Call to Export draft table data
    let actionStatus = selectedTab == "draftVariants" ? [null, "refused", "cancelled"] : selectedTab == "approvedVariants" ? ["approved", "liked"] : "";
    let dataConfig = {
      experiment_id: Experiment_Id,
      action_status: actionStatus, // variant records that are present in draft tab
      templete: experimentTextVariantsType,
    };
    await service
      .csvExportFeatureApi(dataConfig)
      .then(async (res) => {
        console.log("csvExportFeatureApi res", res);
        const blob = res;
        download(blob, `${Experiment_Id}_${Experiment_Name}_${selectedTab}.csv`, "text/csv");
        // require("downloadjs")(res, "Report.xlsx");
      })
      .catch((err) => {
        console.log("exportSegmentImpactExplorerTabData Error", err);
      });
  };

  const csvImportFeature = () => {
    console.log("csvImportFeature", "Template", "uploadedFile");
    setShowDialog(true);
  };

  return (
    <>
      <FileUploadPopUp
        showDialog={showDialog}
        closePopUp={() => {
          setShowDialog(false);
        }}
        fileUploadURL={`${process.env.REACT_APP_DOTNET_MICROSERVICE_HOST + "/api"
          }/Experiments/ExperimentVariantBanks/CsvImport?Template=${experimentTextVariantsType}`}
        fileNameAttribute={"uploadedFile"} // csvImportFeature endpoint file name attribute
        uploadSubText={""}
        onUploadCompletion={() => {
          console.log("onUploadCompletion - DesignVariantBankApprovalTable");
          setTimeout(() => {
            loadVariantBankList(); // reload variants
          }, 750);
        }}
        topExtraContent={() => (
          <div>
            <h2 style={{ textAlign: "center" }}>
              Please upload the file with right experiment-variant template.
            </h2>
            <div>
              <ul>
                <li>Experiment Id: {Experiment_Id}</li>
                <li>Experiment Name: {Experiment_Name}</li>
                <li>Variant template : {experimentTextVariantsType}</li>
              </ul>
            </div>
          </div>
        )}
      />
      <Form form={form} component={false}>
        <Visualization
          // title="Music services popularity"
          // subTitle="The most popular services were taken"
          height={"94%"}
          // withCard={false}
          className="common-table-class"
          dataConfig={{
            structureType: "processed",
          }}
          headerContent={{
            // left: <h6>LEFT</h6>,
            // center: <h6>CENTER</h6>,
            // right: <h6>right</h6>,
            left: (
              <>
                {ExperimentTagGroups?.map((tagItem, index) => (
                  <Tag
                    key={index}
                    color={tagItem?.tag_color /* tag_group tag?.color */}
                    size="small"
                  >
                    {tagItem?.tag_group}
                  </Tag>
                ))}
              </>
            ),
            right: (
              <>
                {
                  // Hide on X.ai panera-dev and panera-prod
                  // process.env.REACT_APP_MICROSERVICE_HOST.toLowerCase().indexOf(
                  //   "dev"
                  // ) === -1 &&
                  // process.env.REACT_APP_MICROSERVICE_HOST.toLowerCase().indexOf(
                  //   "prod"
                  // ) === -1 &&
                  (
                    <Space
                      gap={"middle"}
                    >
                      {/* <Button ghost shape="circle" icon={importCsv_} title={'Import'}/>
                <Button ghost shape="circle" icon={exportCsv_} title={'Export'}/> */}
                      <div
                        title={"Import"}
                        style={{
                          display:
                            "none",
                        }}
                        onClick={csvImportFeature}>
                        {importCsv_}
                      </div>
                      <div title={"Export"} onClick={csvExportFeature}>
                        {exportCsv_}
                      </div>
                    </Space>
                  )
                }
              </>
            ),
          }}
          viewConfig={
            new TableConfig({
              pagination: false,
              sticky: true,
              idProperty: "variantbankId",
              tableLayout: "fixed",
              loading: tableLoading, //data ? (data?.length > 0 ? false : true) : true,
              columns: getColumnConfig({
                ExperimentTagGroups,
                experimentTextVariantsType,
                getTitleTagColumnSearchProps,
                selectedTab,
                isEditing,
                editingKey,
                edit,
                cancel,
                save,
                handleAction,
              }),
              components: {
                body: {
                  cell: EditableCell,
                },
              },
            })
          }
          dataset={{
            data: filterdData,
          }}
        />
      </Form>
    </>
  );
};

export default DesignVariantBankApprovalTable;
