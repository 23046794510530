import React from "react";
import EditOutlined from '@ant-design/icons/EditOutlined'
import { Row } from '@pai-ui/core/components/row';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Spin from 'antd/lib/spin';
import { Input } from '@pai-ui/core/components/input';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import SaveIcon from '@ant-design/icons/SaveOutlined';
import { Table } from "../table";
import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
import { inject } from "@xai/core/di/utils";
import { DI_TOKENS } from "@xai/core/constants/di";
import { showNotificationMessage } from "@xai/assets/generic-components/toaster-message";

import './style.css';

export const ReportsConfigured = (props) => {
  const loggerService = inject(DI_TOKENS.loggerService);
  const { getReports, deleteReport, updateReport } = props;
  const [reports, setReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [activeEditingRow, setActiveEditingRow] = React.useState(undefined);
  const [itemIdLoading, setItemIdLoading] = React.useState(undefined);

  const getReportsLocal = React.useCallback(async () => {
    try {
      setLoading(true);
      const reps = await getReports();
      setReports(reps);
    } catch (err) {
      loggerService.error('failed to get reports configured', err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to get reports',
      );
    } finally {
      setLoading(false);
    }
  }, [getReports]);

  const handleScreenNameChange = React.useCallback((e) => {
    setActiveEditingRow((x) => ({
      ...x,
      report_name: e.target.value
    }));
  }, []);

  const renderEditableCell = React.useCallback((item) => {
    if (activeEditingRow?.id === item.id) {
      return (
        <Input
          style={{ height: '20px' }}
          size="small"
          onChange={handleScreenNameChange}
          value={activeEditingRow.report_name}
        />
      );
    } 

    return item.report_name;
  }, [activeEditingRow, handleScreenNameChange]);

  const handleReportDelete = React.useCallback(async (item) => {
    try {
      setItemIdLoading(item.id);
      await deleteReport(item.id);
      const reps = await getReports();
      setReports(reps);
      setActiveEditingRow(false);
      showNotificationMessage(
        "top",
        "success",
        'Report has been successfully deleted',
      );
    } catch (err) {
      loggerService.error('failed to delete report', err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to delete report',
      );
    } finally {
      setItemIdLoading(undefined);
    }
  }, [getReports, deleteReport]);

  const handleReportUpdate = React.useCallback(async () => {
    try {
      setItemIdLoading(activeEditingRow.id);
      await updateReport(activeEditingRow);
      const reps = await getReports();
      setReports(reps);
      setActiveEditingRow(false);
      showNotificationMessage(
        "top",
        "success",
        'Report has been successfully updated',
      );
    } catch (err) {
      loggerService.error('failed to update report', err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to update report',
      );
    } finally {
      setItemIdLoading(undefined);
    }
  }, [getReports, updateReport, activeEditingRow]);

  const renderActions = React.useCallback((item) => {
    if (itemIdLoading === item.id) {
      return (
        <Spin size="small" indicator={<LoadingOutlined />} />
      );
    }

    return (
      <Row className="configurable-ui-management__reports__actions">
        {activeEditingRow?.id === item.id 
          ? <SaveIcon onClick={() => handleReportUpdate(item)} />
          : <EditOutlined onClick={() => setActiveEditingRow(item)} />}
          <PopConfirm
            title="Delete Report?"
            onConfirm={() => handleReportDelete(item)}
          >
            <DeleteOutlined className="configurable-ui-management__reports__delete-icon" />
          </PopConfirm>
      </Row>
    );
  }, [handleReportDelete, handleReportUpdate, activeEditingRow?.id, itemIdLoading]);

  const columns = [
    {
      width: '45%',
      id: 'report-name',
      render: renderEditableCell,
      fieldName: 'report_name'
    },
    {
      width: '45%',
      id: 'dashboard-name',
      fieldName: 'dashboardName',
    },
    {
      width: '10%',
      id: 'actions',
      render: renderActions
    }
  ];

  return (
    <Table 
      loading={loading}
      getData={getReportsLocal}
      data={reports}
      columns={columns}
    />
  );
};