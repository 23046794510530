import * as DesignService from "@xai/services/Design";

class DesignOptimizationsServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getAllDesignOptimizations() {
    const data = await DesignService.getAllDesignOptimizations(
      this.authToken
    );
    if (!data) return null;
    return data;
  }

}

export default DesignOptimizationsServiceHelper;
