import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { Button } from "@pai-ui/core/components/button";
import { Metric } from "@pai-ui/core/components/metric";
import { PeopleAudience } from "@styled-icons/fluentui-system-filled/PeopleAudience";
import { Money } from "@styled-icons/fluentui-system-regular/Money";
import { ArrowTrendingLines } from "@styled-icons/fluentui-system-filled/ArrowTrendingLines";
import { ArrowUpCircle } from "@styled-icons/bootstrap/ArrowUpCircle";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import ExecutiveServiceHelper from "@xai/pages/executive-at-a-glance-overview/service-helper";
import { UserContext } from "@xai/providers/with-auth";
import { getExecutiveAtAGlanceKPIMetricsConfig } from "@xai/pages/executive-at-a-glance-overview/transformer";
import { Grid, GridItem } from "@xai/library/containers/grid";
import "./index.css";

const OverviewKpiCards = ({ filters }) => {
  // const { handleSetFavorite, onMarkFavFinishLoad } = props;

  const { token, format } = React.useContext(UserContext);
  const service = new ExecutiveServiceHelper(token);

  const [loading, setLoading] = useState(true);

  const [kpiMetricsData, setKpiMetricsData] = useState(null);

  //States for Error Handling
  const [kpiMetricsError, setKpiMetricsError] = useState(false);

  const loadKpiMetricsData = async (/* prop */) => {
    let dataconfig = getExecutiveAtAGlanceKPIMetricsConfig(
      filters?.dateRange?.[0],
      filters?.dateRange?.[1]
    );
    console.log("loadRadarVizChartData call", dataconfig);
    setKpiMetricsError(false);
    await service
      .getExecutiveAtAGlanceKPIMetrics(dataconfig)
      .then(async (res) => {
        console.log("loadKpiMetricsData res", res);
        setKpiMetricsData(res?.metrics);
      })
      .catch((err) => {
        console.log("loadKpiMetricsData Error", err);
        setKpiMetricsError(true);
      })
      .finally(() => {
        // setLoading(false);
        // #TODO : Below code to be commented later during actual api integration and use the above.
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "user":
        return (
          <PeopleAudience
            style={{ width: "35", color: "rgba(255, 255, 255, 0.6)" }}
          />
        );
      case "money":
        return (
          <Money style={{ width: "35", color: "rgba(255, 255, 255, 0.6)" }} />
        );
      case "growth":
        return (
          <ArrowUpCircle
            style={{ width: "35", color: "rgba(255, 255, 255, 0.6)" }}
          />
        );
      case "lift":
        return (
          <ArrowTrendingLines
            style={{ width: "35", color: "rgba(255, 255, 255, 0.6)" }}
          />
        );

      default:
        <></>;
    }
  };

  useEffect(() => {
    if (filters?.dateRange?.length !== 0) {
      loadKpiMetricsData();
    }
  }, [filters]);

  const classes = {
    root: "cs-root",
    title: "cs-title",
    subTitle: "cs-subTitle",
  };

  if (kpiMetricsError) {
    return (
      <LegacyNoData height={"58vh"} error={true}>
        <Button onClick={() => loadKpiMetricsData()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return loading ? (
    <LegacyNoData height={"33vh"} loading={true} style={{ zoom: "1.11111" }} />
  ) : (
    <>
      <Grid
        template={[
          ["area-1", "area-2"],
          ["area-3", "area-4"],
        ]}
      >
        {kpiMetricsData?.map((kpiMetric, index) => {
          return (
            <GridItem key={index} area={`area-${index + 1}`}>
              <div className={"overview-kpi-card-section"}>
                <Metric
                  classes={classes}
                  prefix={getIcon(kpiMetric?.icon)}
                  textAlign="right"
                  title={numeral(kpiMetric?.value)
                    .format(format?.[kpiMetric?.format] || "0,0a")
                    .toUpperCase()}
                  subTitle={kpiMetric?.name}
                  highlightedElement="subTitle"
                />
              </div>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

export default OverviewKpiCards;
