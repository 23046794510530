import React, { useState } from 'react';
import { message } from 'antd';
import { Button, Select } from '@pai-ui/core';
import { Link } from 'react-router-dom';
// import ExpData from './TagLineTesting.json';
// import ExpDataNew from './TagLineTesting_new.json';
import FirstStepRevamp from './FirstStepRevamp.js';
import SecondStepRevamp from './SecondStepRevamp.js';
import ThirdStepRevamp from './ThirdStepRevamp.js';
import DefaultFormData from "./DefaultFormData.json";
import './style.css';

const TagLineExperiment = ({ templateSelected }) => {

    const ExperimentFormConfig = templateSelected?.template_form_config_table?.template_form_config;

    const [current, setCurrent] = useState(0);
    const [firstStepTagLinesList, setfirstStepTagLinesList] = useState([])
    const [responseList, setResponseList] = useState([])
    // const [formData, setFormData] = useState(DefaultFormData["Airline"]);
    const [formData, setFormData] = useState(DefaultFormData["Panera"]); // Requested by @pauline
    const [showList, setShowList] = useState(0)
    const [noList, setNoList] = useState(0)
    const [prevControl, setPrevControl] = useState()
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    }


    const sum = firstStepTagLinesList.filter(item => item.fav === true).reduce((accumulator, object) => {
        return accumulator + object.weight;
    }, 0);

    const steps = [
        {
            title: '',
            content: <FirstStepRevamp
                ExperimentFormConfig={ExperimentFormConfig.sections[0]}
                forthStepData={ExperimentFormConfig.sections[0]}
                formData={formData}
                setFormData={setFormData}
            />,
        },
        {
            title: 'Second',
            content: <SecondStepRevamp
                ExperimentFormConfig={ExperimentFormConfig.sections[0]}
                firstStepTagLinesList={firstStepTagLinesList}
                setfirstStepTagLinesList={setfirstStepTagLinesList}
                formData={formData}
                setFormData={setFormData}
                showList={showList}
                setShowList={setShowList}
                responseList={responseList}
                setResponseList={setResponseList}
                noList={noList}
                setNoList={setNoList}
            />,
        },
        {
            title: 'Last',
            content: <ThirdStepRevamp
                ExperimentFormConfig={ExperimentFormConfig.sections[0]}
                formData={formData}
                firstStepTagLinesList={firstStepTagLinesList}
                setfirstStepTagLinesList={setfirstStepTagLinesList}
                prevControl={prevControl}
                setPrevControl={setPrevControl}
                sum={sum}
            />,
        },
    ];

    const selectHandler = (v, property) => {
        switch (property) {
            case "industry": {
                const tempFormData = DefaultFormData[v];
                console.log('tempFormData', tempFormData, v);
                setFormData(tempFormData);
                setResponseList([]);
                setfirstStepTagLinesList([]);
                break;
            }
            default:
                break;
        }
        // locObj.count.generateCount = v
    }

    return (
        <div>
            <div style={{ position: "absolute", top: "15px", right: "20px" }}>
                <div>
                    <Select
                        style={{ width: "200px",
                         display:"none" // Added on request by @nandini, select dropdown not required in client solution.
                        }}
                        placeholder={ExperimentFormConfig.sections[0].elements.find(it => it.itemKey === "industry").placeholder}
                        options={ExperimentFormConfig.sections[0].elements.find(it => it.itemKey === "industry").options}
                        defaultValue={ExperimentFormConfig.sections[0].elements.find(it => it.itemKey === "industry").defaultOption}
                        value={formData.metaData.industry}
                        onChange={(e) => selectHandler(e, "industry")}
                    />
                </div>
            </div>
            <div className="steps-content" style={{ minHeight: "86vh" }}>{steps[current].content}</div>
            <div className="steps-action" style={{ display: "flex", justifyContent: "flex-end" }}>
                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                        disabled={(current === 1 && (firstStepTagLinesList.filter(it => it.fav === true).some(it => it.name.length === 0) || firstStepTagLinesList.filter(it => it.fav === true).some(it => it.edit === true)))}
                    >
                        Previous
                    </Button>
                )}
                {current < steps.length - 1 && (
                    <Button
                        type="primary"
                        onClick={() => next()}
                        disabled={
                            (current === 1 && firstStepTagLinesList.filter(it => it.fav).length < 2) ||
                            (current === 2 &&
                                (firstStepTagLinesList.filter(it => it.fav === true).some(it => it.name.length === 0) ||
                                    firstStepTagLinesList.filter(it => it.fav === true).some(it => it.edit === true))) ||
                            (current === 2 && firstStepTagLinesList.filter(it => it.control === true).length === 0)
                        }>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Link to={`../../tests`}>
                        <Button
                            type="primary"
                            onClick={() => message.success('Processing complete!')}
                            disabled={sum !== 100}
                        >
                            Done
                        </Button>
                    </Link>
                )}
            </div>
        </div >
    )
}

export default TagLineExperiment