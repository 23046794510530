export const SceneTemplateString = {
  format: "//ly.img.ubq/scene",
  version: "1.6.0",
  designElements: [
    {
      entity: 24117280,
      uuid: "5b37ec20-2b6d-4295-a02c-2c95145b7a94",
      id: "//ly.img.ubq/image",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: -0.00000762939453125,
        },
        top: {
          version: 1,
          unit: 1,
          value: -0.0000095367431640625,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 1,
          value: 147.98818969726563,
        },
        height: {
          version: 1,
          unit: 1,
          value: 82.3360366821289,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 3,
      has_block_fill_value: false,
      has_block_effects_value: false,
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: true,
      drop_shadow: {
        version: 1,
        enabled: false,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 0.25,
          },
        },
        xOffset: 1.7677669525146485,
        yOffset: 1.7677669525146485,
        xBlurRadius: 1.0,
        yBlurRadius: 1.0,
        clip: false,
      },
      hidden: false,
      clipped: false,
      disable_selection: false,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: true,
      block_duration: -1.0,
      has_block_time_offset: true,
      block_time_offset: 0.0,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: true,
      block_placeholder_controls: {
        version: 1,
        showOverlay: false,
        showButton: false,
      },
      version: 22,
      imageFileURI: "{{ image_url }}",
      externalReference: "",
      previewFileURI: "",
      opacity: 1.0,
      contentFillMode: 0,
      cropScaleX: 1.0,
      cropScaleY: 1.0,
      cropRotation: 0.0,
      cropTranslationX: 0.0,
      cropTranslationY: 0.0,
      filterEnabled: true,
      showsPlaceholderButton: false,
      showsPlaceholderOverlay: false,
      "duoToneFilter/enabled": false,
      "duoToneFilter/lightColor": {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.0,
          z: 0.0,
          w: 0.0,
        },
      },
      "duoToneFilter/darkColor": {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.0,
          z: 0.0,
          w: 0.0,
        },
      },
      "duoToneFilter/intensity": 0.0,
      "duoToneFilter/externalReference": "",
      "lutFilter/enabled": false,
      "lutFilter/lutFileURI": "",
      "lutFilter/externalReference": "",
      "lutFilter/intensity": 0.0,
      "lutFilter/horizontalTileCount": 5,
      "lutFilter/verticalTileCount": 5,
      "adjustments/enabled": true,
      "adjustments/brightness": 0.0,
      "adjustments/saturation": 0.0,
      "adjustments/exposure": 0.0,
      "adjustments/contrast": 0.0,
      "adjustments/gamma": 0.0,
      "adjustments/shadows": 0.0,
      "adjustments/highlights": 0.0,
      "adjustments/whites": 0.0,
      "adjustments/blacks": 0.0,
      "adjustments/clarity": 0.0,
      "adjustments/temperature": 0.0,
      "adjustments/sharpness": 0.0,
      "hspSelectiveAdjustments/enabled": true,
      "hspSelectiveAdjustments/hueRed": 0.0,
      "hspSelectiveAdjustments/hueOrange": 0.0,
      "hspSelectiveAdjustments/hueYellow": 0.0,
      "hspSelectiveAdjustments/hueGreen": 0.0,
      "hspSelectiveAdjustments/hueAqua": 0.0,
      "hspSelectiveAdjustments/hueBlue": 0.0,
      "hspSelectiveAdjustments/huePurple": 0.0,
      "hspSelectiveAdjustments/hueMagenta": 0.0,
      "hspSelectiveAdjustments/saturationRed": 0.0,
      "hspSelectiveAdjustments/saturationOrange": 0.0,
      "hspSelectiveAdjustments/saturationYellow": 0.0,
      "hspSelectiveAdjustments/saturationGreen": 0.0,
      "hspSelectiveAdjustments/saturationAqua": 0.0,
      "hspSelectiveAdjustments/saturationBlue": 0.0,
      "hspSelectiveAdjustments/saturationPurple": 0.0,
      "hspSelectiveAdjustments/saturationMagenta": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessRed": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessYellow": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessOrange": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessGreen": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessAqua": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessBlue": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessPurple": 0.0,
      "hspSelectiveAdjustments/perceivedBrightnessMagenta": 0.0,
      "blur/enabled": true,
      "blur/identifier": 0,
      "effect/enabled": true,
      "effect/identifier": 0,
      imageMattingEnabled: false,
      imageMattingStagingEnabled: false,
      stroke: {
        version: 1,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.6700000166893005,
            y: 0.6700000166893005,
            z: 0.6700000166893005,
            w: 1.0,
          },
        },
        width: 1.1571552753448487,
        style: 5,
        position: 0,
        cornerGeometry: 1,
        enabled: false,
      },
    },
    {
      entity: 2,
      uuid: "c7d0b9f4-b909-480a-a167-02d95a02bc22",
      id: "//ly.img.ubq/camera",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: 74.0,
        },
        top: {
          version: 1,
          unit: 1,
          value: 56.4375,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 1,
          value: 0.0846666693687439,
        },
        height: {
          version: 1,
          unit: 1,
          value: 0.0846666693687439,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 1,
      has_block_fill_value: false,
      has_block_effects_value: false,
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: false,
      hidden: false,
      clipped: false,
      disable_selection: true,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 2,
      resolution: {
        version: 1,
        width: 2901.0,
        height: 1312.0,
      },
      zoomLevel: 9.298781394958496,
      pixelRatio: 2.0,
      clearColor: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.0,
          z: 0.0,
          w: 0.0,
        },
      },
    },
    {
      entity: 46,
      uuid: "fad646ec-a4d1-4eaf-bea6-855882bf036a",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 960495622,
      uuid: "89f22bfe-fef8-4660-95ea-0fcf6447cacb",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 255852575,
      uuid: "5790c4da-eaef-4a20-8658-038bd4eee19d",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [254803998],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.06798747181892395,
          z: 0.32506510615348818,
          w: 1.0,
        },
      },
    },
    {
      entity: 254803998,
      uuid: "f723b472-6efb-4321-bfec-384728f0b399",
      id: "//ly.img.ubq/shapes/rect",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: -0.0000152587890625,
        },
        top: {
          version: 1,
          unit: 1,
          value: 82.33712768554688,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 1,
          value: 147.9882049560547,
        },
        height: {
          version: 1,
          unit: 1,
          value: 22.662885665893556,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 0,
      has_block_fill_value: true,
      block_fill: {
        version: 1,
        enabled: true,
        value: 255852575,
      },
      has_block_effects_value: true,
      block_effects: {
        version: 1,
        effects: [],
      },
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: true,
      drop_shadow: {
        version: 1,
        enabled: false,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 0.25,
          },
        },
        xOffset: 1.7677669525146485,
        yOffset: 1.7677669525146485,
        xBlurRadius: 1.0,
        yBlurRadius: 1.0,
        clip: false,
      },
      hidden: false,
      clipped: false,
      disable_selection: false,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: true,
      block_duration: -1.0,
      has_block_time_offset: true,
      block_time_offset: 0.0,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: true,
      block_placeholder_controls: {
        version: 1,
        showOverlay: false,
        showButton: false,
      },
      version: 8,
      opacity: 1.0,
      stroke: {
        version: 1,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.6700000166893005,
            y: 0.6700000166893005,
            z: 0.6700000166893005,
            w: 1.0,
          },
        },
        width: 0.800000011920929,
        style: 5,
        position: 0,
        cornerGeometry: 1,
        enabled: false,
      },
      fill: {
        version: 1,
        value: {
          version: 1,
          type: 0,
          data: {
            index: 0,
            data: {
              version: 4,
              colorSpace: 0,
              components: {
                version: 1,
                x: 0.0,
                y: 0.0,
                z: 0.0,
                w: 1.0,
              },
            },
          },
        },
        enabled: true,
      },
      outlineColor: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.6700000166893005,
          y: 0.6700000166893005,
          z: 0.6700000166893005,
          w: 1.0,
        },
      },
      outlineWidth: 0.800000011920929,
      outlineEnabled: false,
      color: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.0,
          z: 0.0,
          w: 1.0,
        },
      },
      colorEnabled: true,
    },
    {
      entity: 45,
      uuid: "19494163-cf7d-4a39-90e5-6829ee8d991b",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 1,
      uuid: "ba406c04-9ab5-4c92-bacf-e34755fef9c2",
      id: "//ly.img.ubq/scene",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: 0.0,
        },
        top: {
          version: 1,
          unit: 1,
          value: 0.0,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 3,
          value: 0.0,
        },
        height: {
          version: 1,
          unit: 3,
          value: 0.0,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: -1,
      has_block_fill_value: false,
      has_block_effects_value: false,
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: false,
      hidden: false,
      clipped: false,
      disable_selection: true,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: true,
      block_playback_time: 0.0,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 7,
      designUnit: 1,
      dpi: 300.0,
      pixelScaleFactor: 1.0,
      pageFormatId: "din-a6",
      pageDimensions: {
        version: 1,
        width: 148.0,
        height: 105.0,
      },
      aspectRatioLock: true,
      layout: 1,
      mode: 0,
      defaultPageDuration: 5.0,
    },
    {
      entity: 2097152,
      uuid: "0705063e-3537-44c9-ad14-4633298908e9",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [11534347],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 3,
      uuid: "16721d55-ff42-4417-96d8-bc31dbc47285",
      id: "//ly.img.ubq/stack",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: 0.0,
        },
        top: {
          version: 1,
          unit: 1,
          value: 0.0,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 3,
          value: 0.0,
        },
        height: {
          version: 1,
          unit: 3,
          value: 0.0,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 0,
      has_block_fill_value: false,
      has_block_effects_value: false,
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: false,
      hidden: false,
      clipped: false,
      disable_selection: true,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 2,
      axis: 1,
      spacing: 35.0,
      spacingInScreenspace: true,
    },
    {
      entity: 4,
      uuid: "9ef58f7d-f9f5-4b4b-9dbc-58e62d026df5",
      id: "//ly.img.ubq/page",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        top: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 1,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 1,
          value: 148.0,
        },
        height: {
          version: 1,
          unit: 1,
          value: 105.0,
        },
      },
      has_block_margin_value: true,
      block_margin: {
        version: 1,
        left: 0.0,
        top: 7.875,
        right: 0.0,
        bottom: 0.0,
      },
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 0,
      has_block_fill_value: true,
      block_fill: {
        version: 1,
        enabled: true,
        value: 5,
      },
      has_block_effects_value: true,
      block_effects: {
        version: 1,
        effects: [],
      },
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: true,
      drop_shadow: {
        version: 1,
        enabled: false,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 0.25,
          },
        },
        xOffset: 1.7677669525146485,
        yOffset: 1.7677669525146485,
        xBlurRadius: 1.0,
        yBlurRadius: 1.0,
        clip: false,
      },
      hidden: false,
      clipped: false,
      disable_selection: false,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: true,
      block_duration: 5.0,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: true,
      block_placeholder_controls: {
        version: 1,
        showOverlay: false,
        showButton: false,
      },
      version: 9,
      margin: {
        version: 1,
        x: 3.0,
        y: 3.0,
        z: 3.0,
        w: 3.0,
      },
      marginEnabled: false,
      stroke: {
        version: 1,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.6700000166893005,
            y: 0.6700000166893005,
            z: 0.6700000166893005,
            w: 1.0,
          },
        },
        width: 0.800000011920929,
        style: 5,
        position: 0,
        cornerGeometry: 1,
        enabled: false,
      },
      titleTemplate: "Option {{ubq.page_index}}",
      backgroundColor: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
      backgroundEnabled: true,
    },
    {
      entity: 5,
      uuid: "a6c42350-082e-4715-be6d-149054ff77b1",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [4],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.0,
          y: 0.04676432907581329,
          z: 0.2125651091337204,
          w: 1.0,
        },
      },
    },
    {
      entity: 11534346,
      uuid: "23180c09-0cc0-4e78-9bd8-7f7bf593b947",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [11534347],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 1892679694,
      uuid: "1cb0e14f-de64-4100-bad2-a7244af53a2c",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 1350565935,
      uuid: "ef648ba5-7cd2-4c3a-8fdb-99d4e51a04c4",
      id: "//ly.img.ubq/text",
      base_props_version: 28,
      has_block_z_index_value: false,
      block_position: {
        version: 2,
        left: {
          version: 1,
          unit: 1,
          value: 5.473886489868164,
        },
        top: {
          version: 1,
          unit: 1,
          value: 87.43734741210938,
        },
        right: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        bottom: {
          version: 1,
          unit: 0,
          value: 0.0,
        },
        type: 0,
      },
      block_size: {
        version: 1,
        width: {
          version: 1,
          unit: 1,
          value: 52.521141052246097,
        },
        height: {
          version: 1,
          unit: 1,
          value: 12.47671890258789,
        },
      },
      has_block_margin_value: false,
      has_block_depth_value: false,
      block_rotation: {
        version: 1,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      block_scale: {
        version: 1,
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      block_blend_mode: 1,
      block_sorting_order: 1,
      has_block_fill_value: true,
      block_fill: {
        version: 1,
        enabled: true,
        value: 2097188,
      },
      has_block_effects_value: false,
      has_block_blur_value: false,
      block_content_fill_mode: 1,
      has_block_crop: false,
      block_scopes: {
        version: 1,
        scopes: {
          value0: {
            version: 2,
            "design/style": true,
            "design/arrange": true,
            "design/arrange/move": true,
            "design/arrange/resize": true,
            "design/arrange/rotate": true,
            "design/arrange/flip": true,
            "content/replace": true,
            "lifecycle/destroy": true,
            "lifecycle/duplicate": true,
            "editor/inspect": true,
            "editor/present": true,
            "editor/managePages": true,
            "editor/select": true,
            "editor/zoom": true,
          },
          value1: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value2: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
          value3: {
            version: 2,
            "design/style": false,
            "design/arrange": false,
            "design/arrange/move": false,
            "design/arrange/resize": false,
            "design/arrange/rotate": false,
            "design/arrange/flip": false,
            "content/replace": false,
            "lifecycle/destroy": false,
            "lifecycle/duplicate": false,
            "editor/inspect": false,
            "editor/present": false,
            "editor/managePages": false,
            "editor/select": false,
            "editor/zoom": false,
          },
        },
      },
      has_drop_shadow: true,
      drop_shadow: {
        version: 1,
        enabled: false,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 0.25,
          },
        },
        xOffset: 1.7677669525146485,
        yOffset: 1.7677669525146485,
        xBlurRadius: 1.0,
        yBlurRadius: 1.0,
        clip: false,
      },
      hidden: false,
      clipped: false,
      disable_selection: false,
      is_placeholder: false,
      is_placeholder_content: false,
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: true,
      block_duration: -1.0,
      has_block_time_offset: true,
      block_time_offset: 0.0,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 19,
      text: "{{ tagline }}",
      fontFileUri:
        "/extensions/ly.img.cesdk.fonts/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf",
      externalReference: "//ly.img.cesdk.fonts/playfair_display_bold",
      opacity: 1.0,
      fontSize: 18.74050521850586,
      color: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
      colorEnabled: true,
      backgroundColor: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 0.666700005531311,
          y: 0.666700005531311,
          z: 0.666700005531311,
          w: 1.0,
        },
      },
      backgroundColorEnabled: false,
      alignment: 0,
      verticalAlignment: 0,
      lineHeight: 1.0,
      letterSpacing: 0.0,
      paragraphSpacing: 0.0,
      textRuns: [],
      hideLinesOutsideOfFrame: true,
      clipLinesOutsideOfFrame: true,
      automaticFontSizeEnabled: true,
      minAutomaticFontSize: 12.829999923706055,
      maxAutomaticFontSize: 25.650543212890626,
      hasLayoutReferenceFrame: true,
      layoutReferenceOrigin: {
        version: 1,
        x: 5.473886489868164,
        y: 87.43734741210938,
        z: 0.0,
      },
      layoutReferenceDimensions: {
        version: 1,
        x: 52.521141052246097,
        y: 12.47671890258789,
        z: 0.0,
      },
      stroke: {
        version: 1,
        color: {
          version: 4,
          colorSpace: 0,
          components: {
            version: 1,
            x: 0.6700000166893005,
            y: 0.6700000166893005,
            z: 0.6700000166893005,
            w: 1.0,
          },
        },
        width: 0.37481024861335757,
        style: 5,
        position: 0,
        cornerGeometry: 1,
        enabled: false,
      },
    },
    {
      entity: 1723858972,
      uuid: "f1add19e-303e-47fa-8f84-2c7bc6f6bc0e",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 398458887,
      uuid: "a8f6371c-e663-413a-9f69-cedf6a2dd8d0",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 2097188,
      uuid: "ccfb75d0-760e-4f9d-998d-154a39064302",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
    {
      entity: 44,
      uuid: "2fd74fdd-7ff0-4a88-82c2-44fb9abfb886",
      id: "//ly.img.ubq/fill/color",
      base_props_version: 28,
      block_render_connections: [1350565935],
      block_creator_role: 0,
      block_common: {
        version: 1,
        name: "",
        exportable: true,
        alwaysOnTop: false,
      },
      description: {
        version: 1,
        value: "",
      },
      metadata: [],
      has_block_playback_time: false,
      has_block_playback_control: false,
      has_block_duration: false,
      has_block_time_offset: false,
      has_block_trim: false,
      block_solo_playback: false,
      has_block_placeholder_controls: false,
      version: 1,
      value: {
        version: 4,
        colorSpace: 0,
        components: {
          version: 1,
          x: 1.0,
          y: 1.0,
          z: 1.0,
          w: 1.0,
        },
      },
    },
  ],
  hierarchy: {
    root: 1,
    children: [
      {
        key: 44,
        value: [],
      },
      {
        key: 398458887,
        value: [],
      },
      {
        key: 1892679694,
        value: [],
      },
      {
        key: 11534346,
        value: [],
      },
      {
        key: 4,
        value: [254803998, 1350565935, 24117280],
      },
      {
        key: 3,
        value: [4],
      },
      {
        key: 1350565935,
        value: [],
      },
      {
        key: 1,
        value: [3, 2],
      },
      {
        key: 45,
        value: [],
      },
      {
        key: 2097152,
        value: [],
      },
      {
        key: 2097188,
        value: [],
      },
      {
        key: 1723858972,
        value: [],
      },
      {
        key: 2,
        value: [],
      },
      {
        key: 255852575,
        value: [],
      },
      {
        key: 46,
        value: [],
      },
      {
        key: 254803998,
        value: [],
      },
      {
        key: 5,
        value: [],
      },
      {
        key: 960495622,
        value: [],
      },
      {
        key: 24117280,
        value: [],
      },
    ],
  },
  selected: [4],
  isPartOfArchive: false,
  externalImageAssets: [],
  externalAssetCategories: [],
};
