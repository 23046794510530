import React from "react";

import ChartWrapper from "../../../../assets/generic-components/visualization/components/wrapper";

export const Stage = (props) => {
  return (
    <ChartWrapper>
      {({ width, height }) => (
        <div
          style={{
            height,
            overflowY: "auto",
            width,
            overflowX: "hidden",
            paddingRight: 25,
          }}
        >
          {props.children}
        </div>
      )}
    </ChartWrapper>
  );
};
