import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import axios from "axios";

// axios.interceptors.request.use(
//   (config) => {
//     console.log("config", config);
//     if (process.env.REACT_APP_PANERA_ZS_DEV) {
//       config.headers["userid"] = "souvik.saha@zs.com";
//       //       config.headers.common["kubeflow-userid"] = "souvik.saha@zs.com";
//       //       config.headers.common["x-request-id"] =
//       //         "9c332de3-5bde-9778-871f-728719b9594c";
//     }
//     return config;
//   },
//   (error) => {
//     console.log(error);
//   }
// );
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
