import styled from 'styled-components'

export const HoverScaler = styled.div `
    cursor: pointer;
    transition: all ease 200ms;

    &:hover{
        transform: scale(${props => props.scale});
    }
`
export const HoverScalerAndEnable = styled.div `
    cursor: pointer;
    transition: all ease 200ms;
    &:hover{
        transform: scale(${props => props.scale});
        opacity:1;
    }
`