import React from "react";
import { RuleBuilder } from '@pai-ui/core/components/rule-builder';
import CustomFormulaEditor from "./../../kpi-builder/custom-formula-editor/index";
import PredefinedFormulaViewer from "./../../kpi-builder/predefined-formula-viewer/index";
import SelectWithSwitch from "./../../select-with-switch";

export const GetCustomFormElement = (item,formItem,getValue, formMetaData,) => {
  let element = null;
  // console.log('item inside GetCustomFormElement', item)

  switch (item.customComponent) {
    case "rulebuilder":
      element = (
        <RuleBuilder //TODO add default values
          config={item.values.ruleBuilderConfig}
          withAddRuleButton={item.values.ruleBuilderAddRuleButton}
          defaultValue={item.values.ruleBuilderValues}
          withRelationshipInput={false}
        />
      );
      break;
    case "formulaeditor":
      element = <CustomFormulaEditor item={item} formItem={formItem}formMetaData={formMetaData}/>;
      break;
    case "predefinedformulaviewer":
      { let metric = getValue()?.[item.componentProps.fieldToCheck]
      element = <PredefinedFormulaViewer item={item} metric={metric} allValues={getValue()} />; }
      break;
    case "selectWithSwitch":
      
      element = <SelectWithSwitch item={item} formItem={formItem} formMetaData={formMetaData} />;
      break;
  }
  return element;
};
