export const getResultSummaryConfig = (
  id,
  start_time,
  end_time,
  groupView,
  response_metric,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant , experiment_variant_group_kpi_mapping, experiment_variant_meta_data
   * Props : experiment_id, start_time_kpi , end_time_kpi, response_metric_kpi_name, group_view, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    group_view: groupView,
    response_metric_kpi_name: response_metric,
    version: version,
  };

  return input_json;
};

export const postExperimentVariantImageUploadConfig = (
  experiment_audience_group_variant_id,
  images_base64
) => {
  /**
   * Tables : experiment_audience_group_variant , experiment_variant_group_kpi_mapping, experiment_variant_meta_data
   * Props : experiment_id, start_time_kpi , end_time_kpi, response_metric_kpi_name, group_view
   */

  let input_json = {
    experiment_audience_group_variant_id: experiment_audience_group_variant_id,
    images_base64: images_base64,
  };

  return input_json;
};

export const getVariantComparisonConfig = (
  id,
  start_time,
  end_time,
  groupView,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config , experiment_variant_parameters_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, group_view, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    group_view: groupView,
    version: version,
  };

  return input_json;
};

export const getDetailedViewConfidenceIntervalConfig = (
  id,
  start_time,
  end_time,
  parameter_type,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config , experiment_variant_parameters_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, parameter_type, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    parameter_type: parameter_type,
    version: version,
  };

  return input_json;
};

export const getDetailedViewResponseMetricConfig = (
  id,
  start_time,
  end_time,
  parameter_type,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config , experiment_variant_parameters_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, parameter_type, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    parameter_type: parameter_type,
    version: version,
    "screen_in_scope": "results"
  };

  return input_json;
};

export const getDetailedViewParameterVariantComparisionTableConfig = (
  id,
  start_time,
  end_time,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config , experiment_variant_parameters_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    version: version,
  };

  return input_json;
};

export const getSegmentImpactExplorerTabParamsConfig = (
  id,
  start_time,
  end_time,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant, experiment_variant_segment_parameter_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    version: version,
  };

  return input_json;
};

export const getSegmentImpactExplorerTabDataConfig = (
  id,
  start_time,
  end_time,
  selectedSegment,
  selectedParameter,
  selectedTestGroup,
  selectedControlGroup,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant, experiment_variant_segment_parameter_results, experiment_variant_segment_parameter_meta_data
   * Props : experiment_id, start_time_kpi , end_time_kpi, version 
   */

  let input_json = {
    experiment_id: id, // experiment_id
    start_time_kpi: start_time, // start_time_kpi
    end_time_kpi: end_time, // end_time_kpi
    cohort_category: selectedSegment, // cohort_category
    parameter_type: selectedParameter, // parameter_type
    test_group_variant_id: selectedTestGroup, // experiment_audience_group_variant_id
    control_group_variant_id: selectedControlGroup, // experiment_audience_group_variant_id
    version: version,
  };

  return input_json;
};

export const getResultSummaryTwoByTwoChartConfig = (
  id,
  start_time,
  end_time,
  groupView,
  response_metric,
  version,
  summary_chart_metric
) => {
  /**
   * Tables : experiment_audience_group_variant , experiment_variant_group_kpi_mapping, experiment_variant_meta_data
   * Props : experiment_id, start_time_kpi , end_time_kpi, response_metric_kpi_name, group_view, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    group_view: groupView,
    response_metric_kpi_name: response_metric,
    version: version,
    summary_chart_metric: summary_chart_metric
  };

  return input_json;
};

export const getDetailedViewCorrelationChartConfig = (
  id,
  start_time,
  end_time,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config , experiment_variant_parameters_results
   * Props : experiment_id, start_time_kpi , end_time_kpi, version 
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    version: version,
  };

  return input_json;
};
