import React from "react";
import ChartWrapper from "@xai/assets/generic-components/visualization/components/wrapper";
import CreativeEditorSDK from "@cesdk/cesdk-js";
import { SceneTemplateString } from "./TEMPLATE";

export const TemplateComponentSummary = (props) => {
  return (
    <div style={{}}>
      {props.allData.variants.variants && (
        <>
          {props.allData.variants.variants.map((variant, index) => (
            <div
              key={index}
              style={{
                padding: "15px 20px",
                borderRadius: 15,
                cursor: "pointer",
                background:
                  index === props.data.selected
                    ? "rgba(255,255,255,0.05)"
                    : undefined,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    props.updateEntry("selected", index);
                  }}
                  style={{ flex: 1 }}
                >
                  <h4
                    style={{
                      marginBottom: 0,
                      color:
                        index === props.data.selected ? "#15afd0" : undefined,
                    }}
                  >
                    {variant.name}
                  </h4>
                  <p style={{ margin: 0 }}>{variant.description}</p>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const TemplateComponent = (props) => {
  const groupedTemplates = props.allData.variants.variants
    .map((v, i) => {
      if (props.allData.images.variants[i]) {
        var images = props.allData.images.variants[i].images.filter(
          (img) => img.selected
        );
        if (props.allData.content.variants[i].taglines) {
          var taglines = props.allData.content.variants[i].taglines.filter(
            (t) => t.selected
          );
          return images
            .map((img) => {
              return taglines.map((t) => ({
                image: img,
                tagline: t,
                template: "bottom-center",
              }));
            })
            .flat();
        }
        return null;
      }
      return null;
    })
    .filter((gt) => gt);

  return (
    <div
      style={{
        padding: "0px 0px",
        height: "100%",
        width: "100%",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <ChartWrapper>
        {({ width, height }) => (
          <div style={{ width, height }}>
            <Editor
              key={props.data.selected}
              current={props.data.selected}
              currentVariant={
                props.allData.images.variants[props.data.selected]
              }
              currentVariantTexts={
                props.allData.content.variants[props.data.selected]
              }
              pages={groupedTemplates[props.data.selected] || []}
              data={props.data}
              updateEntry={props.updateEntry}
              brandImages={[]}
            />
          </div>
        )}
      </ChartWrapper>
    </div>
  );
};

function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const Editor = (props) => {
  const cesdk_container = React.useRef(null);
  let editor = null;
  React.useEffect(() => {
    let unsubscribe = null;
    if (cesdk_container.current) {
      CreativeEditorSDK.create(cesdk_container.current, {
        baseURL: "https://cdn.img.ly/packages/imgly/cesdk-js/1.13.0/assets",
        theme: "dark",
        role: "Creator",
        i18n: {
          en: {
            "libraries.empty-custom-image-source.label": "Images",
            "libraries.empty-custom-text-source.label": "Text",
          },
        },
        callbacks: {
          onExport: (blobs) => {
            blobs.forEach((b) => {
              blobToBase64(b).then((b64) => {
                console.log(b64);
              });
            });
            return Promise.resolve();
          },
          onDownload: (s) => {
            console.log(atob(s).replace("P5", ""));
            return Promise.resolve();
          },
        },
        ui: {
          elements: {
            view: "default",
            panels: {
              inspector: {
                position: "left",
              },
              assetLibrary: {
                position: "right",
              },
            },
            navigation: {
              show: true, // 'false' to hide the navigation completely
              position: "bottom", // 'top' or 'bottom'
              action: {
                back: false, // true or false
                load: false, // true or false
                save: false, // true or false
                export: {
                  show: false,
                  format: ["application/pdf"],
                },
                download: false, // true  or false
              },
            },
            libraries: {
              replace: {
                entries: (defaultEntries, context) => {
                  if (context.selectedBlocks.length !== 1) {
                    return [];
                  }

                  const [selectedBlock] = context.selectedBlocks;

                  console.log(selectedBlock);

                  let list = [...defaultEntries];

                  if (selectedBlock.blockType === "ly.img.image") {
                    list.push({
                      id: "empty-custom-image-source-for-replace",
                      sourceIds: ["emptyImageSource"],
                      previewLength: 3,
                      gridColumns: 3,
                      gridItemHeight: "square",
                    });
                    return list;
                  }

                  if (selectedBlock.blockType === "ly.img.text") {
                    list.push({
                      id: "empty-custom-text-source-for-replace",
                      sourceIds: ["emptyTextSource"],
                      previewLength: 3,
                      gridColumns: 1,
                      gridItemHeight: "auto",
                    });
                    return list;
                  }

                  return [];
                },
              },
              insert: {
                entries: (defaultEntries) => {
                  console.log("defaultEntries", defaultEntries);

                  const imageEntry = defaultEntries.find((entry) => {
                    return entry.id === "ly.img.image";
                  });

                  const textEntry = defaultEntries.find((entry) => {
                    return entry.id === "ly.img.text";
                  });

                  if (imageEntry) {
                    imageEntry.gridColumns = 4;
                  }

                  if (textEntry) {
                    textEntry.gridColumns = 1;
                  }

                  return [
                    {
                      id: "empty-custom-image-source",
                      sourceIds: ["emptyImageSource"],
                      previewLength: 3,
                      gridColumns: 3,
                      gridItemHeight: "square",
                      previewBackgroundType: "cover",
                      gridBackgroundType: "cover",
                      icon: ({ theme, iconSize }) => {
                        if (theme === "dark") {
                          if (iconSize === "normal") {
                            return "https://img.ly/static/cesdk/guides/icon-normal-dark.svg";
                          } else {
                            return "https://img.ly/static/cesdk/guides/icon-large-dark.svg";
                          }
                        }

                        if (iconSize === "normal") {
                          return "https://img.ly/static/cesdk/guides/icon-normal-light.svg";
                        } else {
                          return "https://img.ly/static/cesdk/guides/icon-large-light.svg";
                        }
                      },
                    },
                    {
                      id: "empty-custom-text-source",
                      sourceIds: ["emptyTextSource"],
                      gridColumns: 1,
                      icon: ({ theme, iconSize }) => {
                        if (theme === "dark") {
                          if (iconSize === "normal") {
                            return "https://img.ly/static/cesdk/guides/icon-normal-dark.svg";
                          } else {
                            return "https://img.ly/static/cesdk/guides/icon-large-dark.svg";
                          }
                        }

                        if (iconSize === "normal") {
                          return "https://img.ly/static/cesdk/guides/icon-normal-light.svg";
                        } else {
                          return "https://img.ly/static/cesdk/guides/icon-large-light.svg";
                        }
                      },
                    },
                    ...defaultEntries.filter(
                      (e) =>
                        [
                          "ly.img.upload",
                          "ly.img.image",
                          "ly.img.text",
                        ].indexOf(e.id) === -1
                    ),
                  ];
                },
              },
            },
          },
        },
      }).then(async (instance) => {
        instance.addDefaultAssetSources();

        instance.addDemoAssetSources({
          sceneMode: "Design",
        });

        editor = instance;

        instance.engine.asset.addSource({
          id: "emptyImageSource",
          findAssets: () => {
            return Promise.resolve({
              assets: [
                ...props.currentVariant.images.map((image) => ({
                  id: image.id,
                  meta: {
                    blockType: "//ly.img.ubq/image",
                    uri: image.image_url,
                    thumbUri: image.image_url,
                    width:
                      image.aspect_ratio.split(":").map((a) => parseInt(a))[0] *
                      10,
                    height:
                      image.aspect_ratio.split(":").map((a) => parseInt(a))[1] *
                      10,
                  },
                  context: {
                    sourceId: "unsplash",
                  },
                })),
              ],
              total: props.currentVariant.images.length,
              currentPage: 1,
              nextPage: undefined,
            });
          },
        });

        instance.engine.asset.addSource({
          id: "emptyTextSource",
          findAssets: () => {
            return Promise.resolve({
              assets: [
                ...props.currentVariantTexts.taglines.map((tagline) => ({
                  id: tagline.text,
                  meta: {
                    blockType: "//ly.img.ubq/text",
                    fillType: "Solid",
                    cardLabel: tagline.text,
                    label: tagline.text,
                    text: tagline.text,
                    hasBackground: false,
                  },
                  label: tagline.text,
                  text: tagline.text,
                  value: tagline.text,
                  context: {
                    sourceId: "unsplash",
                  },
                  icon: tagline.text,
                })),
              ],
              total: props.currentVariantTexts.taglines.length,
              currentPage: 1,
              nextPage: undefined,
            });
          },
        });

        let engine = instance.engine;

        let template = generateTemplateWithPages([...props.pages]);

        await engine.scene.loadFromString(
          btoa(`P5${JSON.stringify(template)}`)
        );

        await (async () => {
          const allPages = instance.engine.scene.getPages();
          const urls = [];
          for (let i = 0; i < allPages.length; i++) {
            const mimeType = "image/png";
            const options = { pngCompressionLevel: 9 };
            const blob = await instance.engine.block.export(
              allPages[i],
              mimeType,
              options
            );
            //url =
            //const url = URL.createObjectURL(blob);
            let url = await blobToBase64(blob);
            urls.push(url);
          }

          let outputs = props.data.outputs;
          outputs[props.current] = urls;
          props.updateEntry("outputs", outputs);
        })();
      });
    }
    return () => {
      if (editor) {
        (async () => {
          const allPages = editor.engine.scene.getPages();
          const urls = [];
          for (let i = 0; i < allPages.length; i++) {
            const mimeType = "image/png";
            const options = { pngCompressionLevel: 9 };
            const blob = await editor.engine.block.export(
              allPages[i],
              mimeType,
              options
            );
            //url =
            //const url = URL.createObjectURL(blob);
            let url = await blobToBase64(blob);
            urls.push(url);
          }

          let outputs = props.data.outputs;
          outputs[props.current] = urls;
          props.updateEntry("outputs", outputs);
        })();
      }
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [cesdk_container]);

  console.log(props.data);

  return (
    <div
      ref={cesdk_container}
      style={{ width: "100%", height: "100%", zoom: 1.1111111111 }}
    ></div>
  );
};

const extractElementAtIndex = (array, predicate) => {
  let index = array.findIndex(predicate);
  return array.splice(index, 1)[0];
};

const extractAndFilter = (array, predicate, remove) => {
  let elements = array.filter(predicate);
  array = array.filter(remove);
  return {
    elements,
    array,
  };
};

const generateTemplateWithPages = (pages) => {
  let newSceneTemplateString = JSON.parse(JSON.stringify(SceneTemplateString));
  let designElements = JSON.parse(
    JSON.stringify(SceneTemplateString.designElements)
  );

  newSceneTemplateString.designElements = [];
  newSceneTemplateString.hierarchy.children = [];

  let scene = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/scene"
  );
  let camera = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/camera"
  );
  let stack = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/stack"
  );

  let pageElement = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/page"
  );

  let { elements: colorElements, array: updatedDesignElements } =
    extractAndFilter(
      designElements,
      (d) => d.id === "//ly.img.ubq/fill/color",
      (d) => d.id !== "//ly.img.ubq/fill/color"
    );

  designElements = updatedDesignElements;

  newSceneTemplateString.designElements.push(scene);
  newSceneTemplateString.designElements.push(camera);
  newSceneTemplateString.designElements.push(stack);

  newSceneTemplateString.hierarchy.children.push({
    key: 1,
    value: [3, 2],
  });

  newSceneTemplateString.hierarchy.children.push({
    key: 2,
    value: [],
  });

  let pageIds = [];

  pages.forEach((page, index) => {
    let newPage = { ...pageElement };
    newPage.entity = index === 0 ? 4 : generateEntityId();
    newPage.uuid = uuidv4();
    newSceneTemplateString.designElements.push(newPage);
    pageIds.push(newPage.entity);
    let pageHierarchy = [];
    designElements.forEach((element) => {
      let elementJsonString = JSON.stringify(element);
      elementJsonString = elementJsonString.replace(
        "{{ tagline }}",
        page.tagline.text
      );
      elementJsonString = elementJsonString.replace(
        "{{ image_url }}",
        page.image.image_url
      );
      let updatedElement = JSON.parse(elementJsonString);
      let newElement = { ...updatedElement };
      newElement.entity = index === 0 ? newElement.entity : generateEntityId();
      newElement.uuid = index === 0 ? newElement.uuid : uuidv4();
      newSceneTemplateString.designElements.push(newElement);
      pageHierarchy.push(newElement.entity);
      newSceneTemplateString.hierarchy.children.push({
        key: element.entity,
        value: [],
      });

      if (index > 0) {
        let [ce, replaced] = replaceAndAppendId(
          colorElements,
          element.entity,
          newElement.entity
        );

        colorElements = ce;

        if (replaced) {
          let newElementString = JSON.stringify(newElement);
          replaced.forEach((r) => {
            newElementString = newElementString.replace(
              r,
              colorElements[colorElements.length - 1].entity
            );
          });
        }
      }
    });

    if (index > 0) {
      let [ce, replaced] = replaceAndAppendId(
        colorElements,
        page.entity,
        newPage.entity
      );

      colorElements = ce;

      if (replaced) {
        let newPageString = JSON.stringify(newPage);
        replaced.forEach((r) => {
          newPageString = newPageString.replace(
            r,
            colorElements[colorElements.length - 1].entity
          );
        });
      }
    }

    newSceneTemplateString.hierarchy.children.push({
      key: newPage.entity,
      value: pageHierarchy,
    });
  });

  newSceneTemplateString.designElements = [
    ...newSceneTemplateString.designElements,
    ...colorElements,
  ];

  colorElements.forEach((color) => {
    newSceneTemplateString.hierarchy.children.push({
      key: color.entity,
      value: [],
    });
  });

  newSceneTemplateString.hierarchy.children.push({
    key: 3,
    value: pageIds,
  });

  return newSceneTemplateString;
};

const generateEntityId = () => {
  return Math.floor(Math.random() * (99999 - 10000) + 10000);
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const replaceAndAppendId = (colorElements, matchingId, appendId) => {
  let matchingColorElements = colorElements.filter(
    (c) => c.block_render_connections.indexOf(matchingId) !== -1
  );
  return [
    [
      ...colorElements,
      ...matchingColorElements.map((ce) => ({
        ...ce,
        entity: generateEntityId(),
        uuid: uuidv4(),
        block_render_connections: [appendId],
      })),
    ],
    matchingColorElements.length
      ? matchingColorElements.map((m) => m.entity)
      : undefined,
  ];
};
