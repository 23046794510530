import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Card, Input, Popconfirm, Row, Col, Checkbox, Button } from "antd";
import React from "react";
import * as HTMLIcons from "../icons";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig";

export const VariantsComponentSummary = (props) => {
  let selectedVariant = props.data.variants[props.data.selected];

  return (
    <div style={{}}>
      {props?.data?.variants && (
        <>
          {props?.data?.variants?.map((variant, index) => (
            <div
              key={index}
              style={{
                padding: "15px 20px",
                borderRadius: 15,
                cursor: "pointer",
                background:
                  index === props.data.selected
                    ? "rgba(255,255,255,0.05)"
                    : undefined,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    props.updateEntry("selected", index);
                  }}
                  style={{ flex: 1 }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      color:
                        index === props.data.selected ? "#15afd0" : undefined,
                    }}
                  >
                    {variant.name}
                  </p>
                </div>
                <div>
                  {index !== 0 && (
                    <CopyOutlined
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginRight: 10,
                      }}
                      onClick={() => {
                        let currentLength = props.data.variants.length;
                        props.updateEntries([
                          {
                            key: "variants",
                            value: [
                              ...props.data.variants,
                              {
                                ...variant,
                                name: `${variant.name} Copy`,
                              },
                            ],
                          },
                          {
                            key: "selected",
                            value: props.data.variants.length,
                          },
                        ]);
                        setTimeout(() => {
                          props.updateParentEntry(
                            "content",
                            "selected",
                            currentLength
                          );
                        }, 1000);
                      }}
                    />
                  )}
                  {/* disable delete for Global variant */}
                  {index !== 0 && props.data.variants.length > 1 && (
                    <Popconfirm
                      title="Are you sure you want to delete this variant?"
                      onConfirm={() => {
                        const newVariants = props.data.variants.filter(
                          (v, i) => i !== index
                        );
                        console.log(
                          "New Index",
                          index - 1,
                          props.data.selected > newVariants.length - 1
                            ? newVariants.length - 1
                            : props.data.selected
                        );
                        props.updateEntries([
                          {
                            key: "variants",
                            value: newVariants,
                          },
                          {
                            key: "selected",
                            value: index - 1 === 0 ? 1 : index - 1,
                            // props.data.selected > newVariants.length - 1
                            //   ? newVariants.length - 1
                            //   : props.data.selected,
                          },
                        ]);
                        // update selected index for content key as well
                        setTimeout(() => {
                          props.updateParentEntry(
                            "content",
                            "selected",
                            index - 1 === 0 ? 1 : index - 1
                          );

                          // delete prompts of index = index
                          let contentPrompts = props.allData.content.prompts;
                          contentPrompts.variants =
                            contentPrompts.variants.filter(
                              (variant, indx) => index !== indx
                            );
                          props.updateParentEntry(
                            "content",
                            "prompts",
                            contentPrompts
                          );

                          // delete variants of index = index
                          let contentVariants =
                            props.allData.content.variants.filter(
                              (variant, indx) => index !== indx
                            );
                          props.updateParentEntry(
                            "content",
                            "variants",
                            contentVariants
                          );
                        }, 1000);
                      }}
                      disabled={props.data.variants?.length === 1}
                    >
                      <DeleteOutlined style={{ fontSize: "22px" }} />
                    </Popconfirm>
                  )}
                </div>
              </div>
              {props.data.selected !== 0 &&
                props.data.selected === index &&
                !props.data.simple && (
                  <div>
                    {props.inputs
                      .filter(
                        (i) =>
                          !i.required &&
                          i.advanced &&
                          i.label.toLowerCase() !== "name" // name is a mandatory field |  Untitled/New Variant | Name field should be present in form view but hidden from sidebar selection
                      )
                      .map((element, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: 10,
                            paddingTop: 0,
                            display: "flex",
                          }}
                        >
                          <div style={{ marginTop: 1, marginRight: 20 }}>
                            <Checkbox
                              disabled={element.required}
                              checked={
                                (selectedVariant.selectedFields || []).indexOf(
                                  element.key
                                ) !== -1
                              }
                              onChange={
                                (/* e */) => {
                                  var currentFields =
                                    selectedVariant.selectedFields || [];
                                  var index = currentFields.indexOf(
                                    element.key
                                  );
                                  if (index !== -1) {
                                    currentFields.splice(index, 1);
                                  } else {
                                    currentFields.push(element.key);
                                  }
                                  let newVariants = [...props.data.variants];
                                  newVariants[
                                    props.data.selected
                                  ].selectedFields = currentFields;
                                  props.updateEntry("variants", newVariants);
                                }
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <p style={{ marginBottom: 0 }}>{element.label}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
            </div>
          ))}
        </>
      )}
      <div
        style={{
          padding: "20px",
          borderRadius: 15,
          // cursor: "pointer",
          // Allow addition of new context variants, only if Output Content is selected.
          cursor:
            props.data?.output_type?.length === 0 ? "not-allowed" : "pointer",
          marginBottom: 7,
          display: "flex",
          alignItems: "center",
          color: props.data?.output_type?.length === 0 ? "gray" : "",
        }}
        title={
          props.data?.output_type?.length === 0
            ? "Select Output Content to continue"
            : ""
        }
        onClick={
          props.data?.output_type?.length === 0
            ? null
            : () => {
                let currentLength = props.data.variants.length;
                console.log({
                  filteredInputs: props.inputs.filter(
                    // eslint-disable-next-line no-unused-vars
                    (i, idx) => !i.required || ["name", "role"].includes(i.key)
                  ),
                  inputs: props.inputs,
                });
                props.updateEntries([
                  {
                    key: "variants",
                    value: [
                      ...props.data.variants,
                      {
                        ...props.data.variants[0],
                        name: "Untitled Variant",
                        selectedFields: props.inputs
                          .filter(
                            (i, idx) =>
                              !i.required ||
                              idx === 0 ||
                              ["name", "role"].includes(i.key)
                          )
                          .map((i) => i.key),
                      },
                    ],
                  },
                  {
                    key: "selected",
                    value: props.data.variants.length,
                  },
                ]);
                setTimeout(() => {
                  props.updateParentEntry("content", "selected", currentLength);

                  // add prompts
                  let contentPrompts = props.allData.content.prompts;
                  const newPrompt = JSON.parse(
                    JSON.stringify(contentPrompts.variants[0])
                  );

                  contentPrompts.variants.push(newPrompt);
                  props.updateParentEntry("content", "prompts", contentPrompts);

                  // add variants
                  let contentVariants = props.allData.content.variants;
                  const newVariant = JSON.parse(
                    JSON.stringify(contentVariants[0])
                  );

                  contentVariants.push(newVariant);
                  props.updateParentEntry(
                    "content",
                    "variants",
                    contentVariants
                  );
                }, 1000);
              }
        }
      >
        <div>
          <PlusCircleOutlined style={{ fontSize: "24px" }} />
        </div>
        <div style={{ flex: 1, marginLeft: 15 }}>
          <h4
            style={{
              marginBottom: 0,
            }}
          >
            Add Variant
          </h4>
          <p style={{ margin: 0 }}>Target, tweak and generate</p>
        </div>
      </div>
    </div>
  );
};

export const VariantsComponent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [zoom, setZoom] = React.useState(
    localStorage.getItem("pai_collapsed") == "true" ? 0.95 : 0.55
  ); // It'll work as long as the whole thing wrapped under ChartWrapper - Potentially break in future
  // const [showTextArea, setShowTextArea] = React.useState(false);

  const { config, selectedExperiment } = useSubjectLineFormConfig();

  const [experimentTemplateLoader, setExperimentTemplateLoader] =
    React.useState(false);

  const selectedVariant = props.data.variants
    ? props.data.variants[props.data.selected]
    : undefined;

  console.log("Test ", { props, selectedVariant });

  const postChangeOutputContentType = async ({
    sectionKey,
    intialOutputContentType,
    newOutputContentType,
  }) => {
    console.log(
      "postChangeOutputContentType",
      sectionKey,
      intialOutputContentType,
      newOutputContentType
    );
    await props.updateEntry(
      sectionKey, //element.key,
      newOutputContentType //it.value
    );
    await props.updateParentEntry("variants", "selected", 0);
    let variantVariants = props.allData.variants.variants?.[0];
    await props.updateParentEntry("variants", "variants", [variantVariants]);

    await props.updateParentEntry("content", "selected", 0);
    // delete added prompts
    let contentPrompts = props.allData.content.prompts;
    contentPrompts.variants = [contentPrompts.variants?.[0]];

    await props.updateParentEntry("content", "prompts", contentPrompts);

    // delete added conext variants
    let contentVariants = props.allData.content.variants?.[0];
    await props.updateParentEntry("content", "variants", [contentVariants]);
    setExperimentTemplateLoader(true);

    await setTimeout(async () => {
      if (
        ["", null, undefined].indexOf(intialOutputContentType) > -1 ||
        intialOutputContentType === newOutputContentType
      ) {
        await props
          .updateExperiment({
            experimentTypeValue: newOutputContentType,
          })
          .finally(() => {
            setExperimentTemplateLoader(false);
          });
      } else {
        // delete experiment and create experiment
        // console.log("delete and add new experiment");
        await props.deleteExperiment().then(async () => {
          await props
            .addExperimentMethod({
              experimentTypeValue: newOutputContentType,
            })
            .finally(() => {
              setExperimentTemplateLoader(false);
            });
        });
      }
    }, 1000);
  };

  if (!selectedVariant) {
    return <></>;
  }

  console.log("VariantsComponent props", selectedExperiment, props, config);
  return (
    <>
      <Row gutter={[25, 25]}>
        <Col span={!props.data.simple ? 14 : 24}>
          {props.inputs
            .filter((input, index) => {
              return (
                (selectedVariant.selectedFields || []).indexOf(input.key) !==
                  -1 &&
                ((props.data.simple && input.simple) ||
                  (!props.data.simple && input.advanced)) &&
                (props.data.selected > 0
                  ? !input.required || index === 0 || ["name", "role"].includes(input.key)
                  : true) &&
                // Hide Output content for new context variants created. Show this field only for Global Context.
                (props.data.selected === 0 || index !== 0)
              );
            })
            .filter(
              (t) =>
                !(
                  props.data?.output_type !== "message-variant" &&
                  t.key === "role"
                )
            )
            .filter(
              (t) => !(selectedVariant.name !== "Global" && t.key === "role")
            )
            .map((element, index) => (
              <>
                {(props.data.selected !== 0 || element.key !== "name") && ( // Hide Name field for Global Context. Show this field only for New Variant Context.
                  <div style={{ marginTop: index > 0 ? 25 : 0 }}>
                    <Card className="">
                      <div>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          {element.label}
                        </p>
                        <p
                          style={{
                            fontSize: "0.8rem",
                            margin: "0px",
                            opacity: 0.75,
                            fontWeight: "300",
                          }}
                        >
                          {element.help}
                        </p>
                      </div>
                      {element.type === "tile-select" && (
                        <div style={{ marginTop: 20 }}>
                          <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
                            {element.props.tiles
                              ?.filter(
                                // exclude "poster", "push-notification"
                                (tile) =>
                                  ["poster", "push-notification"].indexOf(
                                    tile.value
                                  ) === -1
                              )
                              ?.map((it, index) => (
                                <Col
                                  key={index}
                                  span={
                                    24 /
                                    element.props.tiles?.filter(
                                      // exclude "poster", "push-notification"
                                      (tile) =>
                                        ["poster", "push-notification"].indexOf(
                                          tile.value
                                        ) === -1
                                    )?.length
                                  }
                                >
                                  <AspectRatio width={1} height={1}>
                                    <Popconfirm
                                      placement="bottom"
                                      title="Do you confirm to select the output content type?"
                                      okText={"Yes"}
                                      cancelText={"No"}
                                      onConfirm={async () => {
                                        // await props.updateEntry(
                                        //   element.key,
                                        //   it.value
                                        // );
                                        await postChangeOutputContentType({
                                          sectionKey: element.key,
                                          intialOutputContentType:
                                            props?.allData?.variants
                                              ?.output_type,
                                          newOutputContentType: it.value,
                                        });
                                      }}
                                      disabled={
                                        // it?.value !== "SUBJECT_LINE" ||
                                        it?.value ===
                                          "SUBJECT_LINE_EMAIL_BODY" ||
                                        props.data?.[element.key] ===
                                          it.value ||
                                        selectedExperiment?.status !== 4 // If experiment is not in Draft Status then only disable
                                      }
                                      okButtonProps={{
                                        loading: experimentTemplateLoader,
                                      }}
                                    >
                                      <Card
                                        className="card-section"
                                        style={{
                                          background:
                                            props.data?.[element.key] ===
                                            it.value
                                              ? `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))`
                                              : "",
                                          textAlign: "center",
                                          // cursor: "pointer",
                                          // #TODO : SUBJECT_LINE default selection for now, might need to change this later
                                          cursor:
                                            props.data?.[element.key] ===
                                            it.value
                                              ? ""
                                              : it?.value ===
                                                  "SUBJECT_LINE_EMAIL_BODY" ||
                                                selectedExperiment?.status !== 4 // If experiment is not in Draft Status then only disable
                                              ? "not-allowed"
                                              : "pointer",
                                          //   : it?.value === "SUBJECT_LINE"
                                          //   ? "pointer"
                                          //   : "not-allowed",
                                          border: "1px solid rgba(67, 67, 67)",
                                          height: "100%",
                                        }}
                                        // onClick={() => {
                                        //   props.updateEntry(
                                        //     element.key,
                                        //     it.value
                                        //   );
                                        // }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {!it.component && (
                                            <img
                                              src={`${process.env.REACT_APP_S3_BUCKET}/viz_configs/img/integrations/${it?.img}`} //#TODO Change the image path
                                              alt=""
                                            />
                                          )}
                                          {!!it.component && (
                                            <div
                                              className="html-icon-wrapper"
                                              style={{
                                                zoom,
                                              }}
                                            >
                                              {HTMLIcons[it.component]}
                                            </div>
                                          )}
                                          <p
                                            style={{
                                              marginTop: -8,
                                              marginBottom: -10,
                                            }}
                                          >
                                            {it.title}
                                          </p>
                                        </div>
                                      </Card>
                                    </Popconfirm>
                                  </AspectRatio>
                                </Col>
                              ))}
                          </Row>
                        </div>
                      )}
                      {element.type === "textarea" && (
                        <div style={{ marginTop: 20 }}>
                          <Input.TextArea
                            {...element.props}
                            style={element.simple ? element.props.style : {}}
                            value={selectedVariant[element.key]}
                            onChange={(e) => {
                              let newV = [...props.data.variants];
                              newV[props.data.selected][element.key] =
                                e.target.value;
                              props.updateEntry("variants", newV);
                              //   setFormData({
                              //     ...formData,
                              //     basicInfo: {
                              //       ...formData.basicInfo,
                              //       [element.key]: e.target.value,
                              //     },
                              //   });
                            }}
                          />
                        </div>
                      )}
                      {element.switchToAdvanced && props.data.simple && (
                        <div style={{ textAlign: "right" }}>
                          <Button
                            style={{ paddingRight: 0, marginTop: 10 }}
                            onClick={() => {
                              props.updateEntry("simple", false);
                            }}
                            type="link"
                          >
                            Advanced
                          </Button>
                        </div>
                      )}
                    </Card>
                  </div>
                )}
                {element.switchToSimple &&
                  !props.data.simple &&
                  props.data.selected === 0 && (
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button
                        style={{ paddingRight: 0 }}
                        onClick={() => {
                          props.updateEntry("simple", true);
                        }}
                        type="link"
                      >
                        Switch to simple editor
                      </Button>
                    </div>
                  )}
              </>
            ))}
        </Col>
        {!props.data.simple && (
          <Col span={10}>
            <Card
              style={{
                position: "sticky",
                top: 0,
                height: "calc(111vh - 65px - 125px)",
              }}
              bodyStyle={{ height: "100%" }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ marginBottom: 10 }}>
                  {selectedVariant.name} Prompt
                </h3>
                <div
                  style={{
                    flex: 1,
                    borderRadius: 10,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      inset: 0,
                      right: -22,
                      paddingRight: 22,
                      overflowY: "auto",
                    }}
                  >
                    <p
                      style={{
                        color: "rgba(255,255,255,0.55)",
                        margin: "2px",
                      }}
                    >
                      {selectedVariant.role}
                    </p>

                    <p
                      style={{ color: "rgba(255,255,255,0.55)" }}
                      dangerouslySetInnerHTML={{
                        // __html: `You are an experienced Copywriter with a passion for crafting compelling and persuasive messaging. You have to create copy for a marketing campaign for a company.<br />
                        // <br />
                        __html: `<br />
                        <br />${
                          selectedVariant.context
                            ? `<span style="color:white">Background</span>: ${selectedVariant.context}
                     <br /><br />`
                            : ""
                        }
                     ${
                       selectedVariant.product
                         ? `<span style="color:white">Product Description</span>: ${selectedVariant.product}
                     <br /><br />`
                         : ""
                     }
                     ${
                       selectedVariant.audience
                         ? `<span style="color:white">Overall Audience</span>: ${selectedVariant.audience}
                    <br /><br />`
                         : ""
                     }
                      ${
                        selectedVariant.variant_audience
                          ? `<span style="color:white">Variant Audience</span>: ${selectedVariant.variant_audience}
                      <br /><br />`
                          : ""
                      }
                      ${
                        selectedVariant.keywords
                          ? `<span style="color:white">Focus Areas and Keywords</span>: ${selectedVariant.keywords}
                      <br /><br />`
                          : ""
                      }
                      ${
                        selectedVariant.examples
                          ? `<span style="color:white">Examples</span>: ${selectedVariant.examples}
                      <br /><br />`
                          : ""
                      }
                      ${selectedVariant.exclude_keywords
                        ? `<span style="color:white">Exclude Keywords</span>: ${selectedVariant.exclude_keywords}
                  <br /><br />`
                        : ""
                      }
                      ${
                        selectedVariant.tone
                          ? `<span style="color:white">Tone</span>: ${selectedVariant.tone}
                      <br /><br />`
                          : ""
                      }
                      ${
                        selectedVariant.style
                          ? `<span style="color:white">Style</span>: ${selectedVariant.style}
                      `
                          : ""
                      }`,
                      }}
                    ></p>
                  </div>
                </div>
                <Row gutter={[10, 10]} style={{ display: "none" }}>
                  <Col span={12}>
                    <Button style={{ width: "100%" }} type="primary">
                      Summarize
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      style={{ width: "100%" }}
                      icon={<CopyOutlined />}
                      type="ghost"
                    >
                      Copy
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};
