import axios from "axios";

export const getIntegrations = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDataIntegrationsData.json`,
      {
        skipInterceptors: true
      })
      .then((res) => {
        const { data } = res;
        console.log(
          "GetDataIntegrationsData dataConfig",
          dataConfig,
          data,
          authToken
        );
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDataScreenData = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDataScreenData.json`,
      {
        skipInterceptors: true
      })
      .then((res) => {
        const { data } = res;
        console.log(
          "GetDataScreenData dataConfig",
          dataConfig,
          data,
          authToken
        );
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });