import React from "react";
import {
  MenuWrapper,
  MenuItem,
  MenuText,
  MenuIcon,
  MenuGroup,
  LinkedMenuItem,
} from "./styles";

const Menu = ({ groups, highlighted, onClick, currentKey, linked }) =>
  groups.map((group, index) => [
    <MenuGroup key={`0${index}`} bottom={group.last}>
      {group.name}
    </MenuGroup>,
    <MenuWrapper key={`1${index}`}>
      {group.items.map((item, index) => {
        const Icon = item.icon;
        const WrapperComponent = linked ? LinkedMenuItem : MenuItem;

        return (
          <WrapperComponent
            key={index}
            highlighted={highlighted}
            onClick={() => onClick && onClick(item.key, item)}
            to={item.linked}
            activeClassName="activeMenu"
            active={!item.linked && item.key === currentKey}
          >
            <MenuIcon>
              <Icon color="#FFF" />
            </MenuIcon>
            <MenuText>{item.text}</MenuText>
          </WrapperComponent>
        );
      })}
    </MenuWrapper>,
  ]);

export default Menu;
