import React, { useState, useEffect } from "react";
import { Button } from "@pai-ui/core/components/button";
import { EmptyData } from "@pai-ui/core/components/empty-data";
import { UserContext } from "@xai/providers/with-auth";
import withExperiment from "@xai/providers/with-experiment";
import { Grid, GridItem } from "@xai/library/containers/grid";
// import DateSelectionRangePicker from "@xai/assets/product-specific-common-components/date-selection-range-picker";
import ExperimentTopRowGenericComponent from "@xai/assets/product-specific-common-components/experiment-top-row-generic-component/index.js";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import WaveLoader from "@xai/assets/generic-components/wave-loader";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import MeasureResultsServiceHelper from "@xai/pages/measure-results/service-helper";
import * as Comps from "@xai/pages/measure-results/components/measure-results-tab/components/";
import { getResultSummaryConfig } from "@xai/pages/measure-results/components/measure-results-tab/transformer";

const currentScreen = "results"; // to show results screen top row and related content

const OverviewExperimentSummarySection = (/* {filters} */) => {
  const { token, experimentModule } = React.useContext(UserContext);
  const service = new MeasureResultsServiceHelper(token);
  console.log("OverviewExperimentSummarySection", experimentModule);

  const [selectedCohort, setSelectedCohort] = useState(null);
  const [selectedDateRangeVal, setSelectedDateRangeVal] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  // #TODO Need to refactor this Data Loading and Error States
  const [summaryData, setSummaryData] = useState({
    card: {
      data: null,
      loading: true,
      error: false
    }
  });
  const [summaryTabError, setSummaryTabError] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const loadSummaryTabData = async (selectedDateRange) => {
    const groupView = "results_summary";
    let dataconfig = getResultSummaryConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      groupView,
      splitByDelimiterAndToStartCase(
        experimentModule.selectedExperiment.response_metric
      ),
      experimentModule.selectedExperiment.default_version_config
    );
    console.log("loadSummaryTabData call", dataconfig);
    setSummaryTabError(false);
    await service
      .getExperimentResultSummary(dataconfig)
      .then(async (res) => {
        console.log("loadSummaryTabData res", res);
        setSummaryData({
          ...summaryData?.card,
          card: {
            data: res
          }
        });
        //# TODO need to remove the below code after variant filter is implemented 
        setSelectedVariants(res?.experiment_variant_summary_cards?.map((variantCard) => { return variantCard?.audience_group_variant_name }))
      })
      .catch((err) => {
        console.log("loadSummaryTabData Error", err);
        setSummaryTabError(true);
      });
  };

  const getSelectedDateRange = async () => {
    const experimentTaskManagementData =
      experimentModule?.selectedExperiment?.experiment_task_management;
    let selectedTaskId =
      selectedCohort?.length > 1 ? selectedCohort?.[1] : selectedCohort?.[0];
    console.log("getSelectedDateRange selectedCohort", selectedCohort);
    let selectedTaskItem = experimentTaskManagementData?.find(
      (item) => item.id === selectedTaskId
    );
    let datRange = [
      selectedTaskItem?.["start_time_kpi"],
      selectedTaskItem?.["end_time_kpi"],
    ];
    console.log("getSelectedDateRange", datRange, selectedCohort);
    setSelectedDateRangeVal(datRange);
    return datRange;
  };

  const load = () => {
    if (selectedCohort) {
      setDataLoading(true);
      getSelectedDateRange().then(async (selectedDateRange) => {
        await Promise.all([loadSummaryTabData(selectedDateRange)])
          .then(() => {
            setTimeout(() => {
              setDataLoading(false);
              setDataAvailable(true);
            }, 400);
          })
          .catch(() => {
            setDataLoading(false);
            setDataAvailable(false);
          });
      });
    } else {
      setTimeout(() => {
        setDataLoading(false);
        setDataAvailable(false);
      }, 400);
    }
  };

  useEffect(() => {
    console.log("change oon selectedCohort", selectedCohort);
    load();
  }, [selectedCohort]);

  return (
    <>
      <Grid
        template={[
          ["top-row"],
          // ["experiment-high-level-summary-date-range"],
          ["results-summary"],
        ]}
      >
        <GridItem area="top-row">
          <ExperimentTopRowGenericComponent
            currentScreen={currentScreen}
            selectedCohort={selectedCohort}
            setSelectedCohort={setSelectedCohort}
          />
        </GridItem>
        {/* <GridItem area="experiment-high-level-summary-date-range">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <DateSelectionRangePicker
              currentScreen={currentScreen}
              selectedCohort={selectedCohort}
              setSelectedCohort={setSelectedCohort}
            />
          </div>
        </GridItem> */}
        <GridItem key={"results-summary"}>
          {dataLoading ? (
            <div style={{ height: "45vh" }}>
              <LegacyPanel
                className={"hover-section"}
                round
                disableOverflow
                style={{ height: "inherit" }}
              >
                <LegacyPanel
                  flexible
                  background="rgba(255,255,255,0.03)"
                  paddingX
                  paddingY
                >
                  <LegacyPanel flexible paddingY center>
                    <WaveLoader />
                  </LegacyPanel>
                </LegacyPanel>
              </LegacyPanel>
            </div>
          ) : dataAvailable ? (
            <>
              {summaryTabError ? (
                <LegacyNoData height={"45vh"} error={true}>
                  <Button
                    onClick={() => loadSummaryTabData(selectedDateRangeVal)}
                  >
                    Try Again
                  </Button>
                </LegacyNoData>
              ) : (
                <Comps.MeasureResultsSummaryTab
                  summaryData={summaryData}
                  setSummaryData={setSummaryData}
                  selectedVariants={selectedVariants}
                />
              )}
            </>
          ) : (
            <>
              <div style={{ height: "45vh" }}>
                <LegacyPanel
                  className={"hover-section"}
                  round
                  disableOverflow
                  style={{ height: "inherit" }}
                >
                  <LegacyPanel
                    flexible
                    background="rgba(255,255,255,0.03)"
                    paddingX
                    paddingY
                  >
                    <LegacyPanel flexible paddingY center>
                      <EmptyData />
                    </LegacyPanel>
                  </LegacyPanel>
                </LegacyPanel>
              </div>
            </>
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default withExperiment(OverviewExperimentSummarySection);
