import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Card, Input, Popconfirm, Row, Col, Checkbox, Button } from "antd";
import React from "react";
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig.js";

export const VariantsComponentSummary = (props) => {
  const { config, updateConfig } = useSubjectLineFormConfig();
  let selectedVariant = config?.variants?.find((variObj) => variObj?.id === config?.selectedVariant);

  return (
    <div style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}>
      <>
        {config?.variants?.map((variant, index) => (
          <div
            key={index}
            style={{
              padding: "15px 20px",
              borderRadius: 15,
              cursor: "pointer",
              background:
                variant?.id === config?.selectedVariant
                  ? "rgba(255,255,255,0.05)"
                  : undefined,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  // props.updateEntry("selected", index);
                  updateConfig({ selectedVariant: variant?.id });
                }}
                style={{ flex: 1 }}
              >
                <p
                  style={{
                    marginBottom: 0,
                    color:
                      variant?.id === config?.selectedVariant ? "#15afd0" : undefined,
                  }}
                >
                  {variant?.name}
                </p>
              </div>
              <div>
                {index !== 0 && (
                  <CopyOutlined
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      // let currentLength = props.data.variants.length;
                      // props.updateEntries([
                      //   {
                      //     key: "variants",
                      //     value: [
                      //       ...props.data.variants,
                      //       {
                      //         ...variant,
                      //         name: `${variant.name} Copy`,
                      //       },
                      //     ],
                      //   },
                      //   {
                      //     key: "selected",
                      //     value: props.data.variants.length,
                      //   },
                      // ]);
                      // setTimeout(() => {
                      //   props.updateParentEntry(
                      //     "content",
                      //     "selected",
                      //     currentLength
                      //   );
                      // }, 1000);
                      updateConfig({
                        variants: [
                          ...config?.variants,
                          {
                            id: config?.variants?.length,
                            audience: selectedVariant?.audience,
                            context: selectedVariant?.context,
                            name: `${selectedVariant?.name} Copy`,
                            product: selectedVariant?.product,
                            selectedFields: selectedVariant?.selectedFields,
                            summary: selectedVariant?.summary,
                            tone: selectedVariant?.tone
                          }
                        ],
                        selectedVariant: config?.variants?.length
                      });
                    }}
                  />
                )}
                {config?.variants?.length > 1 && (
                  <Popconfirm
                    title="Are you sure you want to delete this variant?"
                    onConfirm={() => {
                      // const newVariants = props.data.variants.filter(
                      //   (v, i) => i !== index
                      // );
                      // console.log(
                      //   "New Index",
                      //   props.data.selected > newVariants.length - 1
                      //     ? newVariants.length - 1
                      //     : props.data.selected
                      // );
                      // props.updateEntries([
                      //   {
                      //     key: "variants",
                      //     value: newVariants,
                      //   },
                      //   {
                      //     key: "selected",
                      //     value:
                      //       props.data.selected > newVariants.length - 1
                      //         ? newVariants.length - 1
                      //         : props.data.selected,
                      //   },
                      // ]);
                      let tempVariants = config?.variants?.filter((variObj) => variObj?.id !== variant?.id);
                      updateConfig({ variants: tempVariants, selectedVariant: 0 });
                    }}
                    disabled={config?.variants?.length === 1}
                  >
                    <DeleteOutlined style={{ fontSize: "22px" }} />
                  </Popconfirm>
                )}
              </div>
            </div>
            {config?.selectedVariant !== 0 &&
              config?.selectedVariant === index &&
              !config?.simpleLayout && (
                <div>
                  {props?.inputs
                    ?.filter((i) => !i.required && i.advanced)
                    ?.map((element) => (
                      <div
                        key={index}
                        style={{
                          marginTop: 10,
                          paddingTop: 0,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginTop: 1, marginRight: 20 }}>
                          <Checkbox
                            disabled={element.required}
                            // checked={
                            //   (selectedVariant.selectedFields || []).indexOf(
                            //     element.key
                            //   ) !== -1
                            // }
                            checked={
                              selectedVariant?.selectedFields?.includes(element.key)
                            }
                            onChange={(e) => {
                              // var currentFields =
                              //   selectedVariant.selectedFields || [];
                              // var index = currentFields.indexOf(element.key);
                              // if (index !== -1) {
                              //   currentFields.splice(index, 1);
                              // } else {
                              //   currentFields.push(element.key);
                              // }
                              // let newVariants = [...props.data.variants];
                              // newVariants[
                              //   props.data.selected
                              // ].selectedFields = currentFields;
                              // props.updateEntry("variants", newVariants);
                              let tempSelectedFields = selectedVariant?.selectedFields;
                              if (e?.target?.checked) {
                                tempSelectedFields = [...tempSelectedFields, element?.key];
                              } else {
                                tempSelectedFields = tempSelectedFields?.filter((fields) => fields !== element?.key);
                              }
                              let tempVariants = config?.variants?.map((varObj) => {
                                if (varObj?.id === config?.selectedVariant) {
                                  return {
                                    ...varObj,
                                    selectedFields: [...new Set(tempSelectedFields)]
                                  }
                                } else {
                                  return varObj;
                                }
                              });
                              updateConfig({ variants: tempVariants });
                            }}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: 0 }}>{element.label}</p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
          </div>
        ))}
      </>
      <div
        style={{
          padding: "20px",
          borderRadius: 15,
          cursor: "pointer",
          marginBottom: 7,
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          // let currentLength = props.data.variants.length;
          // props.updateEntries([
          //   {
          //     key: "variants",
          //     value: [
          //       ...props.data.variants,
          //       {
          //         ...props.data.variants[0],
          //         name: "Untitled Variant",
          //         selectedFields: props.inputs
          //           .filter((i, idx) => !i.required || idx === 0)
          //           .map((i) => i.key),
          //       },
          //     ],
          //   },
          //   {
          //     key: "selected",
          //     value: props.data.variants.length,
          //   },
          // ]);
          // setTimeout(() => {
          //   props.updateParentEntry("content", "selected", currentLength);
          // }, 1000);
          updateConfig({
            variants: [
              ...config?.variants,
              {
                id: config?.variants?.length,
                audience: "",
                context: "",
                name: "Untitled Variant",
                product: "",
                selectedFields: ['variant_audience', 'keywords', 'examples', 'style', 'tone', 'name'],
                summary: "",
                tone: ""
              }
            ],
            selectedVariant: config?.variants?.length
          });
        }}
      >
        <div>
          <PlusCircleOutlined style={{ fontSize: "24px" }} />
        </div>
        <div style={{ flex: 1, marginLeft: 15 }}>
          <h4
            style={{
              marginBottom: 0,
            }}
          >
            Add Variant
          </h4>
          <p style={{ margin: 0 }}>Target, tweak and generate</p>
        </div>
      </div>
    </div>
  );
};

export const VariantsComponent = (props) => {

  const { config, updateConfig } = useSubjectLineFormConfig();

  let selectedVariant = config?.variants?.find((variObj) => variObj?.id === config?.selectedVariant);

  console.log('Context Values', config, selectedVariant);

  if (!selectedVariant) {
    return <></>;
  }

  return (
    <>
      <Row gutter={[25, 25]} style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}>
        <Col span={!config?.simpleLayout ? 14 : 24}>
          {props.inputs
            ?.filter((input, index) => {
              return (
                (selectedVariant?.selectedFields || [])?.indexOf(input.key) !==
                -1 &&
                ((config?.simpleLayout && input?.simple) ||
                  (!config?.simpleLayout && input?.advanced)) &&
                (config?.selected > 0
                  ? !input.required || index === 0
                  : true)
              );
            })
            ?.map((element, index) => (
              <>
                <div style={{ marginTop: index > 0 ? 25 : 0 }}>
                  <Card className="">
                    <div>
                      <p
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        {element.label}
                      </p>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          margin: "0px",
                          opacity: 0.75,
                          fontWeight: "300",
                        }}
                      >
                        {element.help}
                      </p>
                    </div>

                    {element.type === "textarea" && (
                      <div style={{ marginTop: 20 }}>
                        {console.log('Variants', config?.variants?.[config?.selectedVariant]?.[element.key])}
                        <Input.TextArea
                          {...element.props}
                          style={element?.simple ? element?.props?.style : {}}
                          value={config?.variants?.[config?.selectedVariant]?.[element.key]}
                          onChange={(e) => {
                            // let newV = [...props.data.variants];
                            // newV[props.data.selected][element.key] =
                            //   e.target.value;
                            // props.updateEntry("variants", newV);
                            let tempVariants = [...config?.variants];
                            tempVariants = tempVariants?.map((variObj) => {
                              if (variObj?.id === config?.selectedVariant) {
                                return {
                                  ...variObj,
                                  [element.key]: e?.target?.value
                                }
                              }
                              return variObj;
                            });
                            updateConfig({ variants: tempVariants });
                            //   setFormData({
                            //     ...formData,
                            //     basicInfo: {
                            //       ...formData.basicInfo,
                            //       [element.key]: e.target.value,
                            //     },
                            //   });
                          }}
                        />
                      </div>
                    )}
                    {element.switchToAdvanced && config?.simpleLayout && (
                      <div style={{ textAlign: "right" }}>
                        <Button
                          style={{ paddingRight: 0, marginTop: 10 }}
                          onClick={() => {
                            // props.updateEntry("simple", false);
                            updateConfig({ simpleLayout: false });
                          }}
                          type="link"
                        >
                          Advanced
                        </Button>
                      </div>
                    )}
                  </Card>
                </div>
                {element.switchToSimple &&
                  !config?.simpleLayout &&
                  config?.selectedVariant === 0 && (
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button
                        style={{ paddingRight: 0 }}
                        onClick={() => {
                          // props.updateEntry("simple", true);
                          updateConfig({ simpleLayout: true });
                        }}
                        type="link"
                      >
                        Switch to simple editor
                      </Button>
                    </div>
                  )}
              </>
            ))}
        </Col>
        {!config?.simpleLayout && (
          <Col span={10}>
            <Card
              style={{
                position: "sticky",
                top: 0,
                height: "calc(111vh - 65px - 125px)",
              }}
              bodyStyle={{ height: "100%" }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ marginBottom: 10 }}>
                  {selectedVariant.name} Prompt
                </h3>
                <div
                  style={{
                    flex: 1,
                    borderRadius: 10,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      inset: 0,
                      right: -22,
                      paddingRight: 22,
                      overflowY: "auto",
                    }}
                  >
                    <p
                      style={{ color: "rgba(255,255,255,0.55)" }}
                      dangerouslySetInnerHTML={{
                        __html: `You are an experienced Copywriter with a passion for crafting compelling and persuasive messaging. You have to create copy for a marketing campaign for a company.<br />
                        <br />
                      ${selectedVariant.context
                            ? `<span style="color:white">Background</span>: ${selectedVariant.context}
                     <br /><br />`
                            : ""
                          }
                     ${selectedVariant.product
                            ? `<span style="color:white">Product Description</span>: ${selectedVariant.product}
                     <br /><br />`
                            : ""
                          }
                     ${selectedVariant.audience
                            ? `<span style="color:white">Target Audience</span>: ${selectedVariant.audience}
                     <br /><br />`
                            : ""
                          }
                      ${selectedVariant.variant_audience
                            ? `<span style="color:white">Variant Audience</span>: ${selectedVariant.variant_audience}
                      <br /><br />`
                            : ""
                          }
                      ${selectedVariant.keywords
                            ? `<span style="color:white">Focus Areas and Keywords</span>: ${selectedVariant.keywords}
                      <br />`
                            : ""
                          }
                      ${selectedVariant.examples
                            ? `<span style="color:white">Examples</span>: ${selectedVariant.examples}
                      <br /><br />`
                            : ""
                          }
                      ${selectedVariant.tone
                            ? `<span style="color:white">Tone</span>: ${selectedVariant.tone}
                      <br /><br />`
                            : ""
                          }
                      ${selectedVariant.style
                            ? `<span style="color:white">Style</span>: ${selectedVariant.style}
                      `
                            : ""
                          }`,
                      }}
                    ></p>
                  </div>
                </div>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Button style={{ width: "100%" }} type="primary">
                      Summarize
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      style={{ width: "100%" }}
                      icon={<CopyOutlined />}
                      type="ghost"
                    >
                      Copy
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};
