import React, { useState /* , useEffect */ } from "react";
// import { Prompt, useHistory } from "react-router-dom"; // V5
import { useNavigate } from "react-router-dom"; // V6
import { Button } from "@pai-ui/core/components/button";
import { UserContext } from "@xai/providers/with-auth";
import withExperiment from "@xai/providers/with-experiment";
import { Grid, GridItem } from "@xai/library/containers/grid";
import ExperimentTopRowGenericComponent from "@xai/assets/product-specific-common-components/experiment-top-row-generic-component/index.js";
// import * as Comps from "./components";
import { DesignVariantBankApprovalTabs } from "./components/design-variant-bank-approval-tabs";
import { IsolatedActionComponent } from "./components/breadcrumbs";
import ConfirmNavigationPopup from "@xai/assets/generic-components/confirm-navigation-popup";
import { useCallbackPrompt } from "@xai/util/hooks/useCallbackPrompt.js";

const currentScreenTopRow = "variant-bank";

const DesignVariantBankApproval = () => {
  const { experimentModule, currentScreen, subRoute } =
    React.useContext(UserContext);
  //   const [selectedCohort, setSelectedCohort] = useState(null);

  // #region - Navigation Blocker
  /** Navigation Blocker */
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog,
    currentScreen,
    subRoute,
    setShowDialog
  );

  const [saveProceed, setSaveProceed] = useState(null);

  const additionalConfirmationButtons = () => {
    return (
      <>
        <Button onClick={confirmNavigation} ghost>{`Don't Save`}</Button>
        <Button onClick={() => setSaveProceed(!saveProceed)}>
          Save & Proceed
        </Button>
      </>
    );
  };
  // #endregion - Navigation Blocker

  // Parent State to hold segregated variant bank List data based on variant action status.
  const [segregatedVariantBankData, setSegregatedVariantBankData] =
    useState(null);

  // console.log("DesignVariantBank", userState.experimentModule);

  /* React Router v5 */
  // const history = useHistory();
  // const routeChange = async (routePath, state) => {
  //   await history.push({
  //     pathname: routePath,
  //     state: state,
  //   });
  // };

  /* React Router v6 */
  const navigate = useNavigate();
  const routeChange = async (routePath, state) => {
    await navigate(routePath, {
      state: state,
    });
  };

  const [breadcrumbItem, setBreadcrumbActiveItem] = React.useState(null);
  const createCustomComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {
          // Conditional to show Add more variants excpet for ua-dev and ua prd
          // #TODO : change the conditional based on client/dev instance.
          process.env.REACT_APP_MICROSERVICE_HOST.toLowerCase().indexOf(
            "dev" // X.AI : dev
          ) === -1 &&
            process.env.REACT_APP_MICROSERVICE_HOST.toLowerCase().indexOf(
              "prod" // X.AI : prod
            ) === -1 && (
              <Button
                // onClick={() => setBreadcrumbActiveItem("Past Deployments")}
                onClick={() => {
                  // routeChange("/testing/subject-line-generator", {
                  routeChange("/design/experiment", {
                    selectedExperiment: experimentModule?.selectedExperiment,
                  });
                }}
                type="link"
                ghost
                disabled={showDialog}
              >
                Add more variants
              </Button>
            )
        }
        {
          // Conditional to show deployment scheduler excpet for prod
          // #TODO : change the conditional "prod" value based on instance prod env value.
          process.env.REACT_APP_MICROSERVICE_HOST.toLowerCase().indexOf(
            "prod"
          ) === -1 && (
            <Button
              onClick={() => setBreadcrumbActiveItem("Deployment Schedular")}
              type="link"
              ghost
              disabled={
                !segregatedVariantBankData ||
                segregatedVariantBankData?.approvedDataList?.filter(
                  (variant) =>
                    variant?.action_status?.toLowerCase() === "approved"
                )?.length === 0 ||
                showDialog
              }
            >
              Deployment Schedular
            </Button>
          )
        }
        <Button
          onClick={() => setBreadcrumbActiveItem("Past Deployments")}
          type="link"
          ghost
          disabled={
            !segregatedVariantBankData ||
            (segregatedVariantBankData?.deactivatedDataList?.length === 0 &&
              segregatedVariantBankData?.deployedDataList?.length === 0) ||
            showDialog
          }
        >
          Past Deployments
        </Button>
      </div>
    );
  };

  // useEffect(() => {
  //   console.log("segregatedVariantBankData", segregatedVariantBankData);
  // }, [segregatedVariantBankData]);

  return (
    <>
      {breadcrumbItem ? (
        <IsolatedActionComponent
          setBreadcrumbActiveItem={setBreadcrumbActiveItem}
          breadcrumbItem={breadcrumbItem}
          segregatedVariantBankData={segregatedVariantBankData}
        />
      ) : (
        <>
          {
            // Below component is used in relation with react-router-dom v6.
          }
          <ConfirmNavigationPopup
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            additionalConfirmationButtons={additionalConfirmationButtons}
          />
          {
            // Below Prompt component is used in relation with react-router-dom v5.
          }
          {
            //   <Prompt
            //   when={showDialog}
            //   message={(location) =>
            //     `Are you sure you want to go to ${location.pathname}`
            //   }
            // />
          }
          <Grid
            template={[["top-row"], ["tab-pane"]]}
            style={{
              height: "100%",
              alignContent: "baseline",
              gridTemplateRows: "auto 1fr", // Ref : https://stackoverflow.com/a/47980925/4672396
            }}
          >
            <GridItem area="top-row">
              <ExperimentTopRowGenericComponent
                currentScreen={currentScreenTopRow}
                // selectedCohort={selectedCohort}
                // setSelectedCohort={setSelectedCohort}
                customContent={createCustomComponent}
              />
            </GridItem>
            <GridItem key={"tab-pane"}>
              <DesignVariantBankApprovalTabs
                setSegregatedVariantBankData={setSegregatedVariantBankData}
                setShowDialog={setShowDialog}
                confirmNavigation={confirmNavigation}
                saveProceed={saveProceed}
                // currentScreen={currentScreen}
                // selectedCohort={selectedCohort}
                // setSelectedCohort={setSelectedCohort}
              />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};

export default withExperiment(DesignVariantBankApproval);
