import React from "react";

export const InputWrapper = (props) => (
  <div style={{ marginBottom: 50 }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <h3
        style={{
          marginBottom: 10,
          lineHeight: "100%",
          fontWeight: 700,
          fontSize: "18px",
          color: "#FFF",
          flex: 1,
        }}
      >
        {props.label}
      </h3>
      {props.right}
    </div>
    <p
      style={{
        marginBottom: 20,
        fontSize: "14px",
        lineHeight: "125%",
      }}
    >
      {props.help}
    </p>
    {props.children}
  </div>
);
