import { UserGroup } from "@styled-icons/heroicons-outline/UserGroup";
import React, { useState } from 'react'
import { Visualization, DendogramChartConfig } from '@pai-ui/core/components/visualization';
import { WaveLoading } from "styled-spinkit";
import { Button } from "@pai-ui/core/components/button";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import "./index.css"
import DrawerComponent from './drawerContent'
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline'
import { Delete } from '@styled-icons/material-outlined/Delete'
import lodash from 'lodash'
import numeral from 'numeral'
import { Database } from '@styled-icons/feather/Database'
import { LunchDining } from '@styled-icons/material-outlined/LunchDining'
import { FoodEgg } from '@styled-icons/fluentui-system-regular/FoodEgg'
import { Location } from '@styled-icons/evil/Location'
import { Medium } from '@styled-icons/remix-line/Medium'
import { CaretDownCircle } from '@styled-icons/boxicons-regular/CaretDownCircle'
import { CaretUpCircle } from '@styled-icons/boxicons-regular/CaretUpCircle'
import { Desktop } from '@styled-icons/boxicons-regular/Desktop'
import { MobileAlt } from '@styled-icons/boxicons-regular/MobileAlt'
import { Apple } from '@styled-icons/boxicons-logos/Apple'
import { Android } from '@styled-icons/boxicons-logos/Android'

function filterID(id, data) {
  return data.reduce((arr, item) => {
    if (item.id != id) {
      if (item.children) item.children = filterID(id, item.children)
      arr.push(item)
    }
    return arr  // <<-- need to return the accumulator  
  }, [])
}
const getIcon = (type) => {
  switch (type) {

    case "User":
      return (<UserGroup
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></UserGroup>);
      
    case "Medium":
      return (<Medium
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Medium>);
      
    case "ChevronSmallDown":
      return (<CaretDownCircle
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></CaretDownCircle>);
      
    case "ChevronSmallUp":
      return (<CaretUpCircle
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></CaretUpCircle>);
      
    case "Desktop":
      return (<Desktop
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Desktop>);
      
    case "MobileAlt":
      return (<MobileAlt
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></MobileAlt>);
      
    case "Apple":
      return (<Apple
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Apple>);
      
    case "Android":
      return (<Android
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Android>);
      
      // return (<CalendarWeek
      //   style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></CalendarWeek>);
      
    case "Location":
      return (<Location
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Location>);
      
    case "FoodEgg":
      return (<FoodEgg
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></FoodEgg>);
      
    case "LunchDining":
      return (<LunchDining
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></LunchDining>);
      
    case "Database":
      return (<Database
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Database>);
      
    case "Management":
      return (<>
        <img 
          src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/results/management.svg`}
          alt=""
          style={{ height: "65px"}}
        />
      </>);
      
    case "default":
      (<Database
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Database>)
      
  }
}
function update(nodes) {
  return nodes.reduce((count, node) => {
    if (node.children) {
      var subcount = update(node.children);
      if (node.icon) {
        node["icon"] = getIcon(node.icon);
      }

      return count + subcount;
    } else {
      if (node.icon) {
        node["icon"] = getIcon(node.icon);
      }
      return count + 1;
    }
  }, 0);
}

const Dendogram = ({ data }) => {
  console.log('DendogramData', data)
  const [layout, setLayout] = useState("vertical")
  const [detailsContent, setDetailsContent] = React.useState(null);
  const [detailsOpened, setDetailsOpened] = React.useState(null);
  const [datad, setdatad] = React.useState()
  // const [nodeHeight, setnodeHeight] = useState()
  console.log('Dendo', data)
  const handeDelete = (data, datad) => {
    var k = lodash.cloneDeep(datad);
    var l = [k];
    filterID(data.id, l)
    console.log('parentpath2', l[0]);
    setdatad(l[0])


  }
  React.useEffect(() => {
    var k = lodash.cloneDeep(data)
    if (k) {
      update([k])
    }
    // console.log('dendodata', k)
    setdatad(k)
  }, [])

  const renderNodeContent = React.useCallback((data) => {
    console.log('dendData', data)
    if (data.isTouchpoint === true) {
      // setnodeHeight(380)
      return (
        <>
          <div style={{ width: '350px', height: '100%', padding: 5, border: '1px solid grey', borderRadius: 20, background: "rgba(255, 255, 255, 0.03)", marginTop: "10px" }}>
            <div
              style={{
                width: "100%",
                // height: "48px",

                borderRadius: "none",
                textAlign: "left",
                // paddingTop: "34px",
                // paddingBottom: "44px",
                marginLeft: 0,
                marginRight: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Row style={{ justifyContent: "space-between", }}>
                <Col style={{
                  marginTop: "5%", display: "flex"
                }} >


                  <div>
                    <h2 style={{ margin: "0px 0px 10px 10px", lineHeight: "100%" }}>{data.touchpoint.name}</h2>
                  </div>

                </Col>
                <Col span={2} style={{ margin: "3%" }} >
                  <div>
                    <Settings2Outline style={{ color: "rgba(255, 255, 255, 0.85)", cursor: "pointer" }} onClick={() => handleEdit(data)} ></Settings2Outline>
                  </div>
                </Col>
              </Row>
              <Row>
                <div style={{ width: "98%" }}>
                  {
                    data.touchpoint.data.map((item) => (
                      <div style={{ backgroundColor: item.color, display: "flex", marginBottom: "10px", padding: "10px" }} key={item.id}>
                        <span><img src={item.icon} alt="" style={{ height: "40px" }} /></span>
                        <h2 style={{ margin: "auto 20px" }}>{item.name}</h2>
                      </div>
                    ))
                  }
                </div>
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <div></div>
                <div>
                  {data.delete && (
                    <Delete style={{ padding: "5px", color: "rgba(255, 255, 255, 0.85)", height: "40px", cursor: "pointer" }} onClick={() => handeDelete(data, datad)} ></Delete>
                  )}
                </div>
              </Row>

            </div>

          </div>
        </>
      );
    } else {
      // setnodeHeight(100)
      return (
        <div style={{ width: '300px', height: '100%', padding: 5, border: '1px solid grey', borderRadius: 20, background: "rgba(255, 255, 255, 0.03)" }}>
          <div
            style={{
              width: "95%",
              height: "48px",

              borderRadius: "none",
              textAlign: "left",
              paddingTop: "34px",
              paddingBottom: "44px",
              marginLeft: 0,
              marginRight: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Row>
              {data.icon && <Col span={6} style={{
                display: "flex",
                justifyContent: "center",

                alignItems: "center"
              }}>
                {data.icon}
              </Col >}
              <Col span={data.icon ? 12 : 18} offset={4} style={{
                marginTop: "5%", display: "flex",
                justifyContent: "left",
                alignItems: "center"
              }} >


                <div>
                  <h2 style={{ margin: 0, lineHeight: "100%" }}>{data.name}</h2>
                  <h2 style={{ margin: 0 }}>{numeral(data.value1).format('').toUpperCase()}</h2>
                </div>

              </Col>
              <Col span={2} style={{ marginTop: "5%", alignItems: "center" }} >
                <div>
                  <Settings2Outline style={{ color: "rgba(255, 255, 255, 0.85)", height: "30px", cursor: "pointer", marginBottom: "5px" }} onClick={() => handleEdit(data)} ></Settings2Outline>
                </div>
                <div>
                  {data.delete && (
                    <Delete style={{ color: "rgba(255, 255, 255, 0.85)", height: "30px", cursor: "pointer" }} onClick={() => handeDelete(data, datad)} ></Delete>
                  )}
                </div>
              </Col>
            </Row>

          </div>
        </div>
      );
    }
  }, [handeDelete, datad]);

  const handleViewFirstMonthStatistic = React.useCallback((data) => {

    console.log('aayushnoode', data)
    setDetailsContent(
      <DrawerComponent item={data} type={"rule"} view={"new"}> </DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);

  const handleViewSecondMonthStatistic = React.useCallback((data) => {
    setDetailsContent(
      <DrawerComponent item={data} type={"variant"} view={"new"}></DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);
  const handleEdit = React.useCallback((data) => {
    setDetailsContent(
      <DrawerComponent item={data} type={data.type}></DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);
  const viewConfig = React.useMemo(() => {
    return new DendogramChartConfig({
      link: {
        lengthValueCorrelation: true,
        correlationScaleCoefficient: 200,
      },
      node: {   
        widthToComputeNodeCoordinates: 400,
        // heightToComputeNodeCoordinates: 380,
        dimensions: {
          // width: 200,
          // height: 400,
        },
        actions: {
          list: [
            {
              id: '1',
              name: 'Create Audience',
              onClick: handleViewFirstMonthStatistic,
            },
            {
              id: '2',
              name: 'Create Variant',
              onClick: handleViewSecondMonthStatistic,
            },
          ],
          source: 'icon-click',
        },
        renderContent: renderNodeContent,
      },
      layout: layout,
      features: {
        controls: true,
        defaultZoom: 1,
        linkWidthCorrelation: true,
        maxZoom: 2,
        minimap: true,
        minZoom: 0.5,
        paneMoveable: true,
        preventScrolling: false,
        zoomOnScroll: false,
      },
      details: {
        componentProps: { // Drawer component props
          title: (
            <div style={{ marginLeft: "41%" }}>
              Edit Configuration
            </div>
          ),
          width: "30%",

          footer: <Button>Save</Button>,
        },
        opened: detailsOpened,
        content: detailsContent,
        onOpenChange: setDetailsOpened,

      },
    });
  }, [detailsOpened, detailsContent, layout, renderNodeContent]);

  return (
    (layout && datad && Object.keys(datad).length > 0) ? (<Visualization

      height={1300}
      actions={[
        {
          id: "horizontal",
          name: "Horizontal",
          onClick: () => setLayout("horizontal"),
        },
        {
          id: "vertical",
          name: "Vertical",
          onClick: () => setLayout("vertical"),
        },
      ]}
      viewConfig={viewConfig}
      dataConfig={{
        structureType: 'processed'
      }}
      dataset={{
        data: datad
      }}
    />) : <WaveLoading color="#15afd0" />
  );
}
export default Dendogram;