import React, { useState, useEffect } from "react";
import { Modal } from '@pai-ui/core/components/modal';
import { Visualization, TableConfig } from '@pai-ui/core/components/visualization';

const BiasCorrectionReportModal = ({ isReportOpen, setIsReportOpen, reportData }) => {

    const close = React.useCallback(() => setIsReportOpen(false), []);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (reportData?.bias_correction_report) {
            const tempColumns = Object.keys(reportData?.bias_correction_report?.[0]).map((column) => { return { title: column, dataKey: column } });
            setColumns(tempColumns);
            setData(reportData?.bias_correction_report);
        }
    }, [reportData]);

    return (
        <>
            <Modal
                open={isReportOpen}
                withCloseButton={false}
                withoutFooterButtons={true}
                onOk={close}
                onCancel={close}
                bodyStyle={{ height: "70vh", /* overflowY: 'auto', */ background: "#181b20" }}
                width={"65vw"}
            >
                <div>
                    <Visualization
                        title="Bias Check & Correction Report"
                        subTitle=""
                        height={"60vh"}
                        viewConfig={new TableConfig({
                            columns: columns,
                            idProperty: 'anyUniquePropertyHere',
                        })}
                        dataConfig={{
                            structureType: "processed",
                        }}
                        dataset={{
                            data
                        }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default BiasCorrectionReportModal;