import React, { useState,  } from "react";

import { useForm } from "@pai-ui/core/utils/form";
import Sections from "./components/sections";
import { Button } from "@pai-ui/core/components/button";
// import { GenerateUpsertObject } from '@pai/assets/generic-components/form/transformer';

const PaiForm = ({
  formConfig,
  handleSubmit,
  isUpsertRunnig,
  formDynamicKPIFeilds,
  defaultValuesObj,
  saveLabelName="Save Changes",
  buttonProps

}) => {
  // console.log("pai form", formConfig, defaultValuesObj);
  const [formFieldsValues, setFormFieldsValues] = useState({});//defaultValuesObj

  React.useEffect(() => {
    console.log("form recieving default values",defaultValuesObj)

    if (defaultValuesObj && Object.keys(defaultValuesObj).length) {
      setFormFieldsValues(defaultValuesObj);
    } else {
      setFormFieldsValues({});
    }
  }, [defaultValuesObj]);

  // console.log("form main render",formFieldsValues)
  const {
    // fieldValidationProgressState,
    // formValidationProgressState,
    validateForm,
    getValue,
    getFieldMetadata,
     //getFieldsMetadata,
  } = useForm({
    fields: formConfig.allFieldsConfig,
    value: { ...formFieldsValues },
    onChange: (value) => {
      console.log("form onChange",value,formFieldsValues)
      // eslint-disable-next-line no-debugger
      // debugger;
      setFormFieldsValues(value) 
}
  });

  const onSubmit = React.useCallback(async () => {
    const { hasErrors } = await validateForm();
    if (!hasErrors) {
      handleSubmit(validateForm, getValue);
    }
  }, [handleSubmit, validateForm, getValue]);

 
  if (!formConfig.hasSteps) {
 return (
      <>
        <Sections
          formConfig={formConfig}
          getValue={getValue}
          getFieldMetadata={getFieldMetadata}
          formDynamicKPIFeilds={formDynamicKPIFeilds}
        />
      { <Button
         {...buttonProps}
          label={saveLabelName}
          htmlType="submit"
          style={{ float: "right", ...buttonProps?.style }}
          onClick={() => onSubmit()}
          loading={isUpsertRunnig ? true : false}
        />}
      </>
    ); 
}
};

export default PaiForm;
