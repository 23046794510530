import React, { useEffect }  from "react";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Button } from "@pai-ui/core/components/button";
import { TextArea } from "@pai-ui/core/components/text-area";
import { Select } from "@pai-ui/core/components/select";
import { Card } from "@pai-ui/core/components/card";
import { Form } from "antd";
import { UserContext } from "@xai/providers/with-auth";
import { debouncePromise } from "@xai/util/helper";
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig.js";
import DesignServiceHelper from "@xai/pages/design-gen-ai-marketing-copy/service-helper";
import "./BasicComponent-style.css";

export const BasicComponent = (props) => {
  console.log("BasicComponent", props);
  const { config, updateConfig, setBasicFormValidStatus } =
    useSubjectLineFormConfig();
  const [form] = Form.useForm();

  const { experimentModule } = React.useContext(UserContext);

  const getExperimentNames = async (dataconfig) => {
    const service = new DesignServiceHelper("token");
    return await service.getExperimentNames(dataconfig);
  };

  const BasicNameCheckRule = {
    required: true,
    validator: debouncePromise(async (rule, value) => {
      //ss - check if the experiment is being edited
      //console.log('inside validator', config, value, value.toLowerCase() === config.name.toLowerCase());
      if (
        config?.experimentId &&
        value.toLowerCase() ===
          experimentModule?.experimentList
            .find((x) => x.id === config?.experimentId)
            ?.name.toLowerCase()
      ) {
        return new Promise((resolve /* , reject */) => {
          resolve();
          setBasicFormValidStatus(true);
        });
      }
      let datametric = await getExperimentNames(value);
      console.log("experiment name", datametric);
      console.log({ rule, value });

      return new Promise((resolve, reject) => {
        if (datametric?.items?.length === 0) {
          setBasicFormValidStatus(true);
          resolve();
        } else {
          setBasicFormValidStatus(false);

          reject(
            new Error("An Experiment with entered name is already present")
          ).catch((/* e */) => {
            /* ignore for now */
          });
        }
      });
    }, 1000),
  };

  useEffect(() => {
    form.resetFields();
  }, [props?.allData?.basic?.basicName]);

  return (
    <>
      <Row span={24} style={{ paddingBottom: "10px" }}>
        <Col span={24}>
          <Form
            name="subject_line_generator"
            // initialValues={config}
            initialValues={props?.allData?.basic}
            form={form}
            style={{ height: "85vh", overflowY: "hidden", overflowX: "hidden" }}
          >
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <Card bodyStyle={{ padding: "0 20px" }}>
                  {props.inputs.map((element) => (
                    <>
                      <div style={{ padding: "0px", margin: "0px" }}>
                        <div style={{ padding: "0px", margin: "0px" }}>
                          <p
                            style={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            {element?.required && <span>*</span>}
                            {element?.label}
                          </p>
                          {element?.help && (
                            <p
                              style={{
                                fontSize: "0.8rem",
                                margin: "5px",
                                opacity: 0.75,
                                fontWeight: "300",
                              }}
                            >
                              {element.help}
                            </p>
                          )}
                        </div>

                        {element.type === "textarea" && (
                          <div>
                            <Form.Item
                              name={element.key}
                              rules={
                                element.key === "basicName"
                                  ? [].concat(BasicNameCheckRule, element.rules)
                                  : element.rules
                              }
                              {...element.props}
                            >
                              <TextArea
                                {...element.props}
                                style={
                                  element.simple ? element.props.style : {}
                                }
                                onChange={(e) => {
                                  updateConfig({
                                    ...config,
                                    [element.key]: e?.target?.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </Card>
              </Col>
              <Col
                span={12} /* style={{ height: "85vh", overflowY: "auto" }} */
              >
                <Card bodyStyle={{ padding: "0 20px" }}>
                  {props.select.map((element) => (
                    <>
                      <div style={{ padding: "0px", margin: "0px" }}>
                        <div style={{ padding: "0px", margin: "0px" }}>
                          <p
                            style={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              margin: "0px",
                            }}
                          >
                            {element?.required && <span>*</span>}
                            {element?.label}
                          </p>
                          {element.help && (
                            <p
                              style={{
                                fontSize: "0.8rem",
                                margin: "5px",
                                opacity: 0.75,
                                fontWeight: "300",
                              }}
                            >
                              {element.help}
                            </p>
                          )}
                        </div>

                        {element.type === "select" && (
                          <div>
                            <Form.Item
                              name={element.key}
                              rules={element.rules}
                              {...element.props}
                            >
                              <Select
                                {...element.props}
                                options={element.options}
                                style={
                                  element.simple ? element.props.style : {}
                                }
                                // value={config?.[element.key]}
                                onChange={(value) => {
                                  updateConfig({
                                    ...config,
                                    [element.key]: value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        )}
                        {element.type === "select-img" && (
                          <div>
                            <Form.Item
                              name={element.key}
                              rules={element.rules}
                              {...element.props}
                            >
                              <div
                                style={{
                                  gap: "20px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  margin: "0 5px",
                                }}
                              >
                                {element.options?.map(
                                  (optionitem, optionIndex) => {
                                    return (
                                      <div key={optionIndex}>
                                        {/* {optionitem?.name} */}
                                        <Card
                                          className="basicComponent-card-section"
                                          style={{
                                            background:
                                              config[element.key] ===
                                              optionitem.id
                                                ? `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))`
                                                : "",
                                            width: "100px",
                                            height: "100px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            border:
                                              "1px solid rgba(67, 67, 67)",
                                          }}
                                          // onClick={() => {
                                          //   changeHandler(
                                          //     optionitem.id,
                                          //     "response_metric"
                                          //   );
                                          // }}
                                          onClick={() => {
                                            updateConfig({
                                              ...config,
                                              [element.key]: optionitem.id,
                                            });
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/${optionitem.img}`}
                                              width={30}
                                              alt=""
                                            />
                                            <p style={{}}>{optionitem.name}</p>
                                          </div>
                                        </Card>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div
        style={{
          height: 70,
          flexShrink: 0,
          display: "block",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderTop: "1px solid rgba(255,255,255,0.25)",
          borderLeft: "1px solid rgb(24 27 32)",
          margin: "0 -25px",
          background: "#111419",
        }}
      >
        <div
          style={{
            padding: "17px 25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              console.log("config", config);
              form.validateFields().then(() => {
                props?.addDraftExperiment();
                props.setCurrentStep(props.currentStep + 1);
              });
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
