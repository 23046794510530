/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { Grid, GridItem } from "@xai/library/containers/grid";
import FormSection from "./formSection";
const Sections = (props) => {
  const { formConfig } = props;
  // console.log("form sections", props);
  return (
    <Grid template={formConfig.sectionsGrid}>
      {formConfig.sectionsGrid.map((g) => {
        return (
          <>
            {g.map((section) => {
              return (
                <GridItem key={section} area={section}>
                  {formConfig.sections[section] && (
                    <FormSection
                      {...props}
                      stepsConfig={formConfig.sections[section]}
                    />
                  )}
                </GridItem>
              );
            })}
          </>
        );
      })}
      <Grid />
    </Grid>
  );
};
export default Sections;


  /* <Grid
style={{ padding: "10px" }}
template={[
  ["variables", "rules", "overall", "overall"],
  ["users", "tags", "overall", "overall"],
]}
>
<GridItem area="variables"> */

