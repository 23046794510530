import React from "react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { Metric } from "@pai-ui/core/components/metric";
import { Tag } from "@pai-ui/core/components/tag";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Popover } from "@pai-ui/core/components/popover";
import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
import { Button } from "@pai-ui/core/components/button";
import { UserContext } from "@xai/providers/with-auth";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import {
  ZsStarFilled,
  ZsStarOutlined,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { statusEnum } from "@xai/assets/common/enums";
import { MetricClass } from "@xai/assets/common/cssClasses";
import { starStyle } from "@xai/assets/common/cssStyles";
import { loadSelectedExperimentData } from "@xai/providers/with-experiment";

export const ListItem = ({
  item,
  handleFavoriteToggle,
  onMarkFavFinishLoad,
}) => {
  const navigate = useNavigate();

  const userState = React.useContext(UserContext);

  const setSelectedExperiment = async (exp, path) => {
    let finalobj = {
      ...userState.experimentModule,
      analyze_screen_selected_cohort: null,
      analyze_screen_selected_task: null,
      result_screen_selected_cohort: null,
      loading: true,
    };
    await userState.dispatch({
      type: "experimentModule",
      payload: finalobj,
    });

    await loadSelectedExperimentData(exp, finalobj, userState.dispatch);
    await navigate(path);
  };

  return (
    <>
      <Row style={{ cursor: "pointer", marginBottom: 10 }}>
        <Col span={24} style={{ padding: "0px 0px 0px 0px" }}>
          <LegacyPanel className={"hover-section"} round disableOverflow>
            <LegacyPanel
              flexible
              background="rgba(255,255,255,0.03)"
              paddingX
              paddingY
            >
              <LegacyPanel flexible paddingY style={{ padding: "8px 0px" }}>
                <Row align="middle">
                  <Col span={1} align="middle">
                    <PopConfirm
                      key={item.id}
                      title={
                        item.is_favorite
                          ? "Remove from Favorites?"
                          : "Add as Favorite?"
                      }
                      // visible={visible}
                      okButtonProps={{ loading: onMarkFavFinishLoad }}
                      onConfirm={() =>
                        handleFavoriteToggle(item.id, !item.is_favorite)
                      }
                    >
                      <Popover
                        placement="topLeft"
                        title={""}
                        content={"Toggle Favorite"}
                        trigger="hover"
                      >
                        {item.is_favorite ? (
                          <ZsStarFilled style={starStyle} />
                        ) : (
                          <ZsStarOutlined style={starStyle} />
                        )}
                      </Popover>
                    </PopConfirm>
                  </Col>
                  <Col span={5} style={{ alignSelf: "flex-start" }}>
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Name"
                      subTitle={`${item.name}`}
                    />
                  </Col>
                  <Col
                    span={1}
                    /* offset={1} */
                    style={{ /* paddingTop: "14px", */ textAlign: "center" }}
                  >
                    <Tag
                      style={{ width: 75 }}
                      color={statusEnum[item.status].color}
                    >
                      {lodash.upperFirst(statusEnum[item.status].name)}
                    </Tag>
                  </Col>
                  <Col
                    span={4}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Go-live Date"
                      subTitle={`${moment(item.start_time).format(
                        "MM/DD/YYYY"
                      )}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Variants"
                      subTitle={`${item.variant_count}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={4}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Response Metric"
                      subTitle={`${item.response_metric}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={3}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Expected Lift"
                      subTitle={`${numeral(
                        item.expected_lift ? item.expected_lift : 0
                      ).format("0.00%")}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Observed Lift"
                      subTitle={`${numeral(item.lift ? item.lift : 0).format(
                        "0.00%"
                      )}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={0}
                    // offset={1}
                    style={{
                      // paddingTop: 5,
                      textAlign: "right",
                      // Below has to be reverted once the Ananlyze screen is ready
                      display: "none",
                    }}
                  >
                    <Button
                      size="small"
                      type="link"
                      disabled
                      style={{ pointerEvents: "none" }}
                      onClick={() => setSelectedExperiment(item, `#`)}
                    >
                      View Distribution
                    </Button>
                  </Col>
                  <Col
                    span={2}
                    style={{ /* paddingTop: 5, */ textAlign: "right" }}
                  >
                    <Button
                      size="small"
                      type="link"
                      primary
                      onClick={() =>
                        setSelectedExperiment(item, `../../measure`)
                      }
                      disabled={
                        [1, 3, 6].indexOf(item.status) === -1 // Experiment status : Completed, Active, Published
                      }
                    >
                      View Results
                    </Button>
                  </Col>
                </Row>
              </LegacyPanel>
            </LegacyPanel>
          </LegacyPanel>
        </Col>
      </Row>
    </>
  );
};
