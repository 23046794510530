import React, { useState } from "react";
import OutsideAlerter from "./outside-alerter";
import styled from "styled-components";

const paddingMap = (base) => ({
  small: `${base / 4}px ${base / 2}px`,
  large: `${base / 3}px ${base / 1.5}px`,
  default: `${base / 2}px ${base / 2}px`,
});

const fontSizeMap = (base) => ({
  default: base,
  small: base - 2,
  large: base + 2,
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border: ${(props) =>
    props.border
      ? `1px solid ${props.theme.colors[props.borderStyle || "default"]}57`
      : "0px"};
  border-radius: ${(props) => props.theme.borderRadius || "0"}px;
  cursor: pointer;
`;

const Display = styled.div`
  flex-grow: 1;
  padding: ${(props) =>
    paddingMap(props.theme.gutter.inner)[props.size || "default"]};
  font-size: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"]}px;
  line-height: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"] - 2}px;
  color: ${(props) => props.theme.colors[props.textStyle || "default"] + "d9"};
`;

const DropDown = styled.ul`
  padding: 0px;
  position: absolute;
  left: 0;
  right: 0;
  ${(props) => (props.dir === "up" ? "bottom" : "top")}: calc(100% + 1px);
  width: 100%;
  max-height: 170px;
  list-style-type: none;
  border-radius: ${(props) => props.theme.borderRadius || "0"}px;
  overflow: auto;
  z-index: 99;
  background-color: ${(props) => props.theme.background};
`;

const DropDownItem = styled.li`
  padding: ${(props) =>
    paddingMap(props.theme.gutter.inner)[props.size || "default"]};
  font-size: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"]}px;
  line-height: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"] - 2}px;
  color: ${(props) => props.theme.colors[props.textStyle || "default"]};
  border-bottom: 1px solid
    ${(props) =>
      props.border
        ? `${props.theme.colors[props.borderStyle || "default"]}57`
        : "transparent"};

  &:hover {
    background-color: ${(props) => props.theme.colors["primary"]};
  }
`;

const Arrow = styled.div`
  padding: ${(props) =>
    paddingMap(props.theme.gutter.inner)[props.size || "default"]};
  font-size: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"]}px;
  line-height: ${(props) =>
    fontSizeMap(props.theme.fontSizes.default)[props.size || "default"] - 1}px;
  color: ${(props) => props.theme.colors[props.textStyle || "default"] + "d9"};
`;

const Select = ({
  value,
  size,
  items,
  border,
  borderStyle,
  textStyle,
  onChange,
  labelKey,
  dir,
  style,
}) => {
  const [dropDown, setDropDown] = useState(false);

  return (
    <OutsideAlerter action={() => setDropDown(false)}>
      <Container
        border={border}
        borderStyle={borderStyle}
        style={style}
        onClick={() => setDropDown(!dropDown)}
      >
        <Display size={size} textStyle={textStyle}>
          {value}
        </Display>
        <Arrow size={size}>▼</Arrow>
        {dropDown && (
          <DropDown dir={dir}>
            {(items || []).map((item, i) => (
              <DropDownItem
                key={i}
                size={size}
                onClick={() => onChange && onChange(item)}
              >
                {labelKey ? item[labelKey] : item}
              </DropDownItem>
            ))}
          </DropDown>
        )}
      </Container>
    </OutsideAlerter>
  );
};

export default Select;
