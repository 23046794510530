import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "./styles";
// import { Menu as Hamburger } from "@styled-icons/boxicons-regular/Menu";
import { UserContext } from "@xai/providers/with-auth";
import { PageSkeleton } from "./pages";

import { getConfig } from "@xai/core/config";
// import { Avatar } from '@pai-ui/core/components/avatar';
// import { Select } from "@pai-ui/core/components/select";
// import { Result } from "@pai-ui/core/components/result";
// import { LoadingOutlined } from "@ant-design/icons";
import LoadingSkeleton from "@xai/assets/generic-components/loading-skeleton";
import { useLayoutContext } from "@xai/providers/layout";

const AuthLayout = ({ children }) => {
  const { menu } = useLayoutContext();
  const user = React.useContext(UserContext);
  const storageValue = window.localStorage.getItem("pai_collapsed");
  // eslint-disable-next-line no-unused-vars
  const [defaultUrl, setDefaultUrl] = useState("");
  const [useDefaultUrl, setUseDefaulturl] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [collapsed, setCollapsed] = useState(
    !storageValue || storageValue === "true"
  );
  console.log("authlayout", collapsed, user);

  const menuConfig = React.useMemo(() => {
    const menuGroups = menu.groups.map((g) => ({
      ...g,
      items: g.items,
    }));
    const result = menuGroups.filter((g) => g.items.length);

    return result;
  }, [menu]);

  useEffect(() => {
    let event = new window.Event("customResize");
    window.dispatchEvent(event);
    window.localStorage.setItem("pai_collapsed", collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (useDefaultUrl) {
      setDefaultUrl(window.localStorage.getItem("currUrl"));
      window.localStorage.setItem("currUrl", "");
      setUseDefaulturl(false);
    }
  }, []);

  if (!menuConfig.length) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {/* <Skeleton loading={true} active style={{ width: "18%" }}>
          <React.Fragment>
            <CardMeta title={""} description={""} />
          </React.Fragment>
        </Skeleton> */}
        <LoadingSkeleton />
      </div>
    );
  }

  const config = getConfig();
  let basename = config.REACT_APP_URL_PREFIX;

  if (basename && basename.length > 1) {
    basename = basename.slice(0, -1);
  }

  return (
    <Router basename={basename}>
      <Container>
        <Routes>
          <Route
            path="/*"
            element={
              <PageSkeleton
                user={user}
                pageLoader={children}
                menuConfig={menuConfig}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              />
            }
          />
        </Routes>
      </Container>
    </Router>
  );
};

export default AuthLayout;
