import axios from "axios";

let baseUrl = process.env.REACT_APP_IMAGE_GEN_AI_URL;
let username = process.env.REACT_APP_IMAGE_GEN_AI_USERNAME;
let password = process.env.REACT_APP_IMAGE_GEN_AI_PASSWORD;

/**
 * Load images from archive
 * @param {*} params pageNumber, pageSize, search
 * @returns data: array of images, total_count
 */
export const loadArchive = async (params) => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/archive`,
    params,
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Load gallery images
 * @returns
 */
export const loadGallery = async () => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/gallery`,
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Start an image generation
 * @param {*} config
 * @returns
 */
export const generate = async (config) => {
  try {
    const response = await axios({
      url: `${baseUrl}/imageGenerator/generate`,
      method: "POST",
      data: config,
      auth: {
        username,
        password,
      },
    });
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

/**
 * Get status of an image generation request
 * @param {*} id
 * @returns
 */
export const getStatus = async (id) => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/status`,
    params: {
      id,
    },
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Update image
 * @param {*} id
 * @param {*} item
 * @returns
 */
export const updateImage = async (id, item) => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/save`,
    method: "POST",
    data: {
      id,
      item,
    },
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Delete image
 * @param {*} id
 * @returns
 */
export const deleteItem = async (id) => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/item/delete`,
    method: "POST",
    data: {
      id,
    },
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Delete entire batch
 * @param {*} batch_id
 * @returns
 */
export const deleteBatch = async (batch_id) => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/batch/delete`,
    method: "POST",
    data: {
      batch_id,
    },
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Get all tags
 * @returns
 */
export const getTags = async () => {
  const response = await axios({
    url: `${baseUrl}/imageGenerator/tags`,
    auth: {
      username,
      password,
    },
  });
  return response.data;
};

/**
 * Start file upload and get a signedURL
 * @param {*} file
 */
export const upload = async (file) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: {
      username,
      password,
    },
  };
  let fd = new FormData();
  fd.append("file", file);
  const response = await axios.post(
    `${baseUrl}/imageGenerator/upload`,
    fd,
    config
  );
  return response.data.url;
};

/**
 * Fetch available canva accounts
 * @returns
 */
export const getCanvaAccounts = async () => {
  const response = await axios({
    url: `${baseUrl}/canvaIntegration/accounts`,
    auth: {
      username,
      password,
    },
  });
  return response.data;
};
