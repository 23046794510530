import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Gear as GearIcon } from "@styled-icons/evil/Gear";
import numeral from "numeral";
import { Card } from "@pai-ui/core/components/card";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Tag } from "@pai-ui/core/components/tag";
import { Space } from "@pai-ui/core/components/space";
import { List, ListItem, ListItemMeta } from "@pai-ui/core/components/list";
import { Button } from "@pai-ui/core/components/button";
import { Ellipsis } from "@pai-ui/core/components/ellipsis";
import { Popover } from "@pai-ui/core/components/popover";
import { Dropdown } from "@pai-ui/core/components/dropdown";
import { Menu } from "@pai-ui/core/components/menu";
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { HieghtAdjustorDiv } from "@xai/assets/generic-components/hieght-adjustor-div";

const VariantTableList = ({
  tableData,
  selectedVariants,
  error,
  loading,
  loadSummaryTabCardsData,
  selectedDateRangeVal,
  selectedAudience,
  endOfExportFileName,
}) => {
  const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"];
  const { format, experimentModule } = React.useContext(UserContext);
  const [filteredTableData, setFilteredTableData] = useState();
  const [sort, setSort] = useState("desc");

  const ExperimentResponseMetric =
    experimentModule?.selectedExperiment?.response_metric;
  const ExperimentSecondaryMetric =
    experimentModule?.selectedExperiment?.experiment_data
      ?.list_secondary_metric;
  const ExperimentTertiaryMetric =
    experimentModule?.selectedExperiment?.experiment_data?.list_tertiary_metric;
  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;
  const ExperimentAudienceGroupAvailable =
    experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]
      ?.audience_group_name;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;

  useEffect(() => {
    if (tableData) {
      // console.log("VariantTableList- useEffect",tableData,filteredTableData,sort);
      //#TODO Need to Refactor this below code and data structure
      let tempFilteredTableData = ExperimentAudienceGroupAvailable
        ? tableData?.experiment_variant_summary_cards
            ?.filter(
              (cardObj) =>
                cardObj?.parent_audience_group_variant_name === selectedAudience
            )
            ?.filter((cardObj) =>
              selectedVariants?.includes(cardObj?.audience_group_variant_name)
            )
        : tableData?.experiment_variant_summary_cards?.filter((cardObj) =>
            selectedVariants?.includes(cardObj?.audience_group_variant_name)
          );
      tempFilteredTableData =
        sort === "asc"
          ? tempFilteredTableData?.sort(
              (a, b) =>
                a?.kpi_list?.find(
                  (kpiObj) => kpiObj?.kpi_name === ExperimentResponseMetric
                )?.kpi_value -
                b?.kpi_list?.find(
                  (kpiObj) => kpiObj?.kpi_name === ExperimentResponseMetric
                )?.kpi_value
            )
          : tempFilteredTableData?.sort(
              (a, b) =>
                b?.kpi_list?.find(
                  (kpiObj) => kpiObj?.kpi_name === ExperimentResponseMetric
                )?.kpi_value -
                a?.kpi_list?.find(
                  (kpiObj) => kpiObj?.kpi_name === ExperimentResponseMetric
                )?.kpi_value
            );
      setFilteredTableData(tempFilteredTableData);
    }
  }, [selectedVariants, sort]);

  if (error) {
    return (
      <LegacyNoData height={"69vh"} error={true}>
        <Button onClick={() => loadSummaryTabCardsData(selectedDateRangeVal)}>
          Try Again
        </Button>
      </LegacyNoData>
    );
  }

  return (
    <>
      {!loading ? (
        <div className="recommendation-results-analysis-list">
          <HieghtAdjustorDiv height_vh={60} height_px={20}>
            <Card
              // style={{ height: "calc(88.2vh)" }}
              // style={{ height: `calc(${(38.5 * 1.1111) * 2}vh + 20px)` }}
              style={{ height: "inherit" }}
              bodyStyle={{ padding: "0px" }}
              bordered={false}
              title={
                <>
                  <div style={{ display: "flex" }}>
                    <Space
                      style={{
                        flex: "auto",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            margin: 0,
                            flexGrow: 1,
                            lineHeight: "130%",
                            fontWeight: 400,
                            color: colors?.[0],
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            List of Variants
                          </span>
                        </p>
                        <div style={{ marginTop: "10px" }}>
                          <Space>
                            {ExperimentTagGroups?.map((tagobj, i) => {
                              return (
                                <Tag key={i} color={tagobj?.tag_color}>
                                  {tagobj?.tag_group}
                                </Tag>
                              );
                            })}
                          </Space>
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <p
                            style={{
                              margin: 0,
                              flexShrink: 0,
                              textAlign: "right",
                              lineHeight: "100%",
                              fontWeight: 400,
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              sort === "asc" ? setSort("desc") : setSort("asc")
                            }
                          >
                            {ExperimentResponseMetric}
                            <br />
                            <small style={{ color: colors[0] }}>
                              {ExperimentSecondaryMetric}
                            </small>
                            <br />
                            {ExperimentTertiaryMetric && (
                              <small style={{ color: "rgb(193, 221, 234)" }}>
                                {`(${ExperimentTertiaryMetric})`}
                              </small>
                            )}
                          </p>
                        </Col>
                        <Col style={{ margin: "auto 0px auto 10px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CaretUpOutlined
                              style={{ color: sort === "asc" && "#15afd0" }}
                              onClick={() => setSort("asc")}
                            />
                            <CaretDownOutlined
                              style={{
                                color: sort === "desc" && "#15afd0",
                              }}
                              onClick={() => setSort("desc")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Space>
                    <Dropdown
                      placement={"bottomRight"}
                      overlay={
                        <Menu
                          items={[
                            {
                              id: 1,
                              name: (
                                <CSVLink
                                  data={filteredTableData?.map((variant) => {
                                    let returnObj = {
                                      "Audience Group":
                                        variant?.parent_audience_group_variant_name,
                                      Variant:
                                        variant?.audience_group_variant_name,
                                      // "Variant - label":
                                      //   variant?.audience_group_variant_label,
                                      // //   "Variant - is active": variant?.audience_group_variant_is_active
                                    };
                                    // handling Subject line & Body copy together
                                    if (
                                      experimentTextVariantsType?.toLowerCase() ===
                                      "subjectlineandbodycopy"
                                    ) {
                                      let textSplit =
                                        variant?.audience_group_variant_label?.split(
                                          "||||"
                                        );
                                      returnObj[
                                        "Variant - label - Subject line"
                                      ] = textSplit?.[0] || "";
                                      returnObj["Variant - label - Body copy"] =
                                        textSplit?.[1] || "";
                                    } else {
                                      returnObj["Variant - label"] =
                                        variant?.audience_group_variant_label;
                                    }
                                    let toneTags =
                                      typeof variant?.audience_group_variant_tags ===
                                      "string"
                                        ? JSON.parse(
                                            variant?.audience_group_variant_tags
                                          )
                                        : variant?.audience_group_variant_tags;
                                    // Concat Tone tags into comma seperated string
                                    toneTags = toneTags
                                      ?.filter(
                                        (item) =>
                                          item.tag_group?.toLowerCase() ===
                                          "tone"
                                      )
                                      ?.reduce((acc, tag, index, self) => {
                                        if (index === self.length - 1) {
                                          return acc.concat(tag?.name);
                                        }
                                        return acc.concat(tag?.name, ", ");
                                      }, "");

                                    returnObj["Tone"] = toneTags;

                                    variant?.kpi_list?.forEach((kpi) => {
                                      returnObj[kpi?.kpi_name] =
                                        ["", null, undefined].indexOf(
                                          kpi?.kpi_value
                                        ) > -1
                                          ? null
                                          : numeral(kpi?.kpi_value)
                                              .format(format?.[kpi?.format_id])
                                              .toUpperCase();

                                      returnObj[`${kpi?.kpi_name} - Lift`] =
                                        ["", null, undefined].indexOf(
                                          kpi?.kpi_lift
                                        ) > -1
                                          ? null
                                          : numeral(kpi?.kpi_lift)
                                              .format(
                                                format?.[kpi?.kpi_lift_format]
                                              )
                                              .toUpperCase();
                                    });
                                    return returnObj;
                                  })}
                                  //   filename={`Variants List - ${experimentModule?.selectedExperiment?.name}.csv`}
                                  filename={`Variants List - ${
                                    experimentModule?.selectedExperiment?.name
                                  } - ${selectedAudience} - ${endOfExportFileName()}.csv`}
                                >
                                  {"Export List (.csv)"}
                                </CSVLink>
                              ),
                            },
                          ]}
                        />
                      }
                      trigger={["click"]}
                    >
                      <GearIcon
                        width={22}
                        height={22}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        // color="#15afd0"
                      />
                    </Dropdown>
                  </div>
                </>
              }
            >
              <List
                itemLayout="horizontal"
                dataSource={filteredTableData}
                // style={{ overflowY: "auto", height: "calc(74.5vh)" }}
                style={{
                  // height: '100%',
                  overflowY: "auto",
                }}
                size="small"
                renderItem={(item, renderItemIndex) => (
                  <ListItem
                    style={{
                      opacity: !item.audience_group_variant_is_active
                        ? "0.7"
                        : "1",
                      cursor: "pointer",
                    }}
                    key={item.id}
                  >
                    <ListItemMeta
                      avatar={
                        <h4
                          style={{
                            fontSize: "14px",
                            color: "hsla(0,0%,100%,.65)",
                            marginRight: "4px",
                          }}
                        >{`#${renderItemIndex + 1}`}</h4>
                      }
                      title={
                        <div>
                          {item?.audience_group_variant_label?.includes(
                            "||||"
                          ) ? (
                            <Popover
                              placement="top"
                              title={`${item?.audience_group_variant_name} - ${
                                item?.audience_group_variant_label?.split(
                                  "||||"
                                )?.[0]
                              }`}
                              content={`Body: ${
                                item?.audience_group_variant_label?.split(
                                  "||||"
                                )?.[1]
                              }`}
                              trigger="hover"
                              overlayStyle={{
                                maxWidth: "30%",
                              }}
                            >
                              <span>{`${item?.audience_group_variant_name} - ${
                                item?.audience_group_variant_label?.split(
                                  "||||"
                                )?.[0]
                              }`}</span>
                            </Popover>
                          ) : (
                            <Ellipsis
                              maxWidth={500}
                              text={`${
                                item?.audience_group_variant_name
                              } - ${item?.audience_group_variant_label?.replace(
                                "||||",
                                ""
                              )}`}
                            />
                          )}
                          <br />
                          {typeof item?.audience_group_variant_tags === "string"
                            ? JSON.parse(
                                item?.audience_group_variant_tags
                              )?.map((tag, index) => {
                                return (
                                  <Tag
                                    key={index}
                                    color={
                                      ExperimentTagGroups?.find(
                                        (tagObj) =>
                                          tagObj?.tag_group === tag?.tag_group
                                      )?.tag_color /* tag_group tag?.color */
                                    }
                                    size="small"
                                  >
                                    {tag?.name}
                                  </Tag>
                                );
                              })
                            : item?.audience_group_variant_tags?.map(
                                (tag, index) => {
                                  return (
                                    <Tag
                                      key={index}
                                      color={
                                        ExperimentTagGroups?.find(
                                          (tagObj) =>
                                            tagObj?.tag_group === tag?.tag_group
                                        )?.tag_color /* tag_group tag?.color */
                                      }
                                      size="small"
                                    >
                                      {tag?.name}
                                    </Tag>
                                  );
                                }
                              )}
                        </div>
                      }
                    />
                    <div style={{ textAlign: "right" }}>
                      {item?.kpi_list?.find(
                        (kpiObj) =>
                          kpiObj?.kpi_name === ExperimentResponseMetric
                      )?.kpi_value ? (
                        <div>
                          <h4
                            style={{
                              textAlign: "right",
                              color: "#FFF",
                              lineHeight: "100%",
                              fontSize: "14px",
                              margin: 0,
                            }}
                          >
                            {numeral(
                              item?.kpi_list?.find(
                                (kpiObj) =>
                                  kpiObj?.kpi_name === ExperimentResponseMetric
                              )?.kpi_value
                            )
                              .format(
                                format?.[
                                  item?.kpi_list?.find(
                                    (kpiObj) =>
                                      kpiObj?.kpi_name ===
                                      ExperimentResponseMetric
                                  )?.format_id
                                ]
                              )
                              .toUpperCase()}
                          </h4>
                        </div>
                      ) : (
                        <></>
                      )}
                      {ExperimentSecondaryMetric &&
                      ExperimentSecondaryMetric?.toLowerCase() == "lift" ? (
                        item?.kpi_list?.find(
                          (kpiObj) =>
                            kpiObj?.kpi_name === ExperimentResponseMetric
                        )?.kpi_value ? (
                          <div>
                            <p
                              style={{
                                textAlign: "right",
                                lineHeight: "110%",
                                color: "#15afd0",
                                margin: "5px 0px",
                              }}
                            >
                              {numeral(
                                item?.kpi_list?.find(
                                  (kpiObj) =>
                                    kpiObj?.kpi_name ===
                                    ExperimentResponseMetric
                                )?.kpi_lift
                              )
                                .format(
                                  format?.[
                                    item?.kpi_list?.find(
                                      (kpiObj) =>
                                        kpiObj?.kpi_name ===
                                        ExperimentResponseMetric
                                    )?.kpi_lift_format
                                  ]
                                )
                                .toUpperCase()}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : item?.kpi_list?.find(
                          (kpiObj) =>
                            kpiObj?.kpi_name === ExperimentSecondaryMetric
                        )?.kpi_value ? (
                        <div>
                          <p
                            style={{
                              textAlign: "right",
                              lineHeight: "110%",
                              color: "#15afd0",
                              margin: "5px 0px",
                            }}
                          >
                            {numeral(
                              item?.kpi_list?.find(
                                (kpiObj) =>
                                  kpiObj?.kpi_name === ExperimentSecondaryMetric
                              )?.kpi_value
                            )
                              .format(
                                format?.[
                                  item?.kpi_list?.find(
                                    (kpiObj) =>
                                      kpiObj?.kpi_name ===
                                      ExperimentSecondaryMetric
                                  )?.format_id
                                ]
                              )
                              .toUpperCase()}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.kpi_list?.find(
                        (kpiObj) =>
                          kpiObj?.kpi_name === ExperimentTertiaryMetric
                      )?.kpi_value ? (
                        <div>
                          <p
                            style={{
                              textAlign: "right",
                              lineHeight: "110%",
                              color: "rgb(193, 221, 234)",
                              margin: "5px 0px",
                            }}
                          >
                            {`(${numeral(
                              item?.kpi_list?.find(
                                (kpiObj) =>
                                  kpiObj?.kpi_name === ExperimentTertiaryMetric
                              )?.kpi_value
                            )
                              .format(
                                format?.[
                                  item?.kpi_list?.find(
                                    (kpiObj) =>
                                      kpiObj?.kpi_name ===
                                      ExperimentTertiaryMetric
                                  )?.format_id
                                ]
                              )
                              .toUpperCase()})`}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </ListItem>
                )}
              />
            </Card>
          </HieghtAdjustorDiv>
        </div>
      ) : (
        <LegacyNoData height={"69vh"} loading={true} />
      )}
    </>
  );
};

export default VariantTableList;
