import axios from "axios";
import { dotNetApi } from "@xai/library/clients";

let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");
/**
 * Custom Transformer For getAllExperiments
 * 1. Transformation to parse JSON data for template_form_config value.
 */
//#region -start
const TransformExperimentListData = (experimentListData) => {
  experimentListData?.items?.map((data) => {
    data.experiment_data = JSON.parse(data?.experiment_data);
    return data;
  });
};

const TransformSelectedExperimentListData = (experimentListData) => {
  experimentListData?.items?.map((data) => {
    data.template_master.template_form_config_table.template_form_config =
      JSON.parse(
        data?.template_master?.template_form_config_table?.template_form_config
      );
    data.experiment_overall_kpi_lists = data?.experiment_overall_kpi_lists?.map((kpiObj) => {
      return {
        ...kpiObj,
        kpi_list: JSON.parse(kpiObj?.kpi_list),
        experiment_audience_group_variant_data: kpiObj?.experiment_audience_group_variant_data ? JSON.parse(kpiObj?.experiment_audience_group_variant_data) : []
      };
    });
    return data;
  });
};
//#endregion -end

// All the Mock JSON's are moved to CDN in pai-ui-assets-01 Bucket.
export const getAllExperiments = (authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/TestLibraryExperiment.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getAllExperiments data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getAllExperiments dataConfig", authToken);
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `{
              experimentMaster(pageSize: 10000, page: 1)  {
                items {
                  id
                  name: experimentName
                  is_favorite: isFavourite
                  is_active: isActive
                  status
                  start_time: startTime
                  end_time: endTime
                  variant_count: variantCount
                  expected_lift: expectedLift
                  lift
                  response_metric: responseMetric
                  response_metric_impact_format: responseMetricImpactFormat
                  response_metric_impact_value: responseMetricImpactValue
                  target_variable: targetVariable
                  target_variable_format: targetVariableFormat
                  target_variable_value: targetVariableValue
                  analyze_description: analyzeDescription
                  result_description: resultDescription
                  default_version_config: defaultVersionConfig
                  experiment_data: experimentData 	
                }
              }
            }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getAllExperiments data",
            // res,
            experimentMaster
          );
          await TransformExperimentListData(experimentMaster);
          resolve(experimentMaster);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getSelectedExperiment = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // #TODO: handle mock scenario properly.
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/TestLibraryExperiment.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("getSelectedExperiment data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getSelectedExperiment dataConfig", dataConfig, authToken);
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `{
              experimentMaster(
                  ids: ["${dataConfig}"]
                  pageSize: 10000, 
                  page: 1
                )  {
                items {
                  templateId
                  template_master: template {
                    id
                    template_name: templateName
                    template_form_config_table: templateFormConfig {
                      template_form_config: templateFormConfig
                    }
                  }
                  experiment_task_management: experimentTaskManagements {
                    id
                    experiment_id: experimentId
                    experiment_name: experimentName
                    prior_date_range: priorDateRange
                    start_time_kpi: startTimeKpi
                    end_time_kpi: endTimeKpi
                    request_from_screen: requestFromScreen
                    request_type: requestType
                    status
                    is_active: isActive
                    created
                    created_by: createdBy
                    bias_correction_required: biasCorrectionRequired
                    version
                    bias_correction_report: biasCorrectionReport
                    send_out_date:sendOutDate
                    send_out_week:sendOutWeek
                  }
                  experiment_overall_kpi_lists:experimentOverallKpiLists {
                    id
                    experiment_id:experimentId
                    start_time_kpi:startTimeKpi
                    end_time_kpi:endTimeKpi
                    version:version
                    kpi_list:experimentKpiList
                    experiment_audience_group_variant_data:experimentAudienceGroupVariantData
                  }	
                }
              }
            }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getSelectedExperiment data",
            // res,
            experimentMaster
          );
          await TransformSelectedExperimentListData(experimentMaster);
          resolve(experimentMaster);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const markFavorite = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    const { experiment_id, is_favorite } = dataConfig;
    if (mock) {
      // mock response for this api call
      let data = {
        id: experiment_id,
        is_favorite: is_favorite,
      };
      console.log("FavoriteExperiment dataConfig", authToken, dataConfig, data);
      resolve(data);
    } else {
      console.log("FavoriteExperiment dataConfig", dataConfig);
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
            experimentMasterUpdate(
              id: "${experiment_id}"
              experimentMaster: { isFavourite: ${is_favorite} }
            ) {
              id
              is_favorite: isFavourite
            }
          }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { experimentMasterUpdate } = res?.data?.data;
          // experimentTaskManagement.experimentsTaskList =
          //   experimentTaskManagement.items;
          // delete experimentTaskManagement.items;

          console.log(
            "FavoriteExperiment data",
            // res,
            experimentMasterUpdate
          );
          resolve(experimentMasterUpdate);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultSummary = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultSummary.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultSummary dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultSummary dataConfig",
        dataConfig,
        authToken
      );
      // let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/measure/GetExperimentResultSummary`;
      // axios({
      //   method: "POST",
      //   url: fetchUrl,
      //   data: dataConfig,
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + authToken,
      //   },
      // })
      //   .then((response) => {
      //     console.log(response.data, "GetExperimentResultSummary response");
      //     resolve(response.data);
      //   })
      //   .catch((err) => {
      //     console.log("GetExperimentResultSummary err", err);
      //     resolve({
      //       data: [],
      //     });
      //   });
      /** */
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultSummary`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          console.log("GetExperimentResultSummary data", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const postExperimentVariantImageUpload = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call

      console.log(
        "postExperimentVariantImageUpload dataConfig",
        dataConfig,
        authToken
      );
    } else {
      console.log("postExperimentVariantImageUpload dataConfig", dataConfig);

      dotNetApi
        .post(`/Products/UploadProductImages`, dataConfig, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultVariantComparison = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      // let data = axios({
      //   method: "GET",
      //   url: "./TempData/GetExperimentResultVariantComparison.json",
      // });
      // console.log("FavoriteExperiment dataConfig", dataConfig, data);
      // resolve(data);
      /** */
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultVariantComparison.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultVariantComparison dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultVariantComparison dataConfig",
        dataConfig,
        authToken
      );
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultVariantComparison`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultVariantComparison API Response",
            data
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultDetailedViewConfidenceInterval = (
  dataConfig,
  authToken
) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultsDetailedViewConfidenceIntervalChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultDetailedViewConfidenceInterval dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultDetailedViewConfidenceInterval dataConfig",
        dataConfig
      );
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultDetailedViewConfidenceInterval`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultDetailedViewConfidenceInterval API Response",
            data
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultDetailedViewResponseMetric = (
  dataConfig,
  authToken
) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultsDetailedViewResponseMetricChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetAnalyzeResponseMetricChart dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetAnalyzeResponseMetricChart dataConfig", dataConfig);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultSegmentImpactExplorerParams = (
  dataConfig,
  authToken
) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultSegmentImpactExplorerParams.json`,
        {
          skipInterceptors: true
        }
      )
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultSegmentImpactExplorerParams dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultSegmentImpactExplorerParams dataConfig",
        dataConfig
      );
      // let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/measure/GetExperimentResultSegmentImpactExplorerParams`;
      // axios({
      //   method: "POST",
      //   url: fetchUrl,
      //   data: dataConfig,
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + authToken,
      //   },
      // })
      //   .then((response) => {
      //     console.log(
      //       response.data,
      //       "GetExperimentResultSegmentImpactExplorerParams response"
      //     );
      //     resolve(response.data);
      //   })
      //   .catch((err) => {
      //     console.log(
      //       "GetExperimentResultSegmentImpactExplorerParams err",
      //       err
      //     );
      //     resolve({
      //       data: [],
      //     });
      //   });
      /** */
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultSegmentImpactExplorerParams`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultSegmentImpactExplorerParams data",
            data
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultSegmentImpactExplorerData = (
  dataConfig,
  authToken
) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultSegmentImpactExplorerData.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultSegmentImpactExplorerData dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultSegmentImpactExplorerData dataConfig",
        dataConfig
      );
      // let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/measure/GetExperimentResultSegmentImpactExplorerData`;
      // axios({
      //   method: "POST",
      //   url: fetchUrl,
      //   data: dataConfig,
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + authToken,
      //   },
      // })
      //   .then((response) => {
      //     console.log(
      //       response.data,
      //       "GetExperimentResultSegmentImpactExplorerData response"
      //     );
      //     resolve(response.data);
      //   })
      //   .catch((err) => {
      //     console.log("GetExperimentResultSegmentImpactExplorerData err", err);
      //     resolve({
      //       data: [],
      //     });
      //   });
      /** */
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultSegmentImpactExplorerData`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultDetailedViewParameterVariantComparisionTable = (
  dataConfig,
  authToken
) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultsDetailedViewParameterVariantComparisionTable.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentResultsDetailedViewParameterVariantComparisionTable dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "GetExperimentResultsDetailedViewParameterVariantComparisionTable dataConfig",
        dataConfig
      );
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultDetailedViewComparisionTable`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExportExperimentSegmentImpactReport = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call

      console.log(
        "GetExportExperimentSegmentImpactReport dataConfig",
        dataConfig,
        authToken
      );
    } else {
      console.log(
        "GetExportExperimentSegmentImpactReport dataConfig",
        dataConfig
      );

      dotNetApi
        .post(
          `/Experiments/GetExportExperimentSegmentImpactReport`,
          dataConfig,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getExperimentResultSummaryTwoByTwo = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentResultsSummaryTwoByTwoChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "getExperimentResultSummaryTwoByTwo dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "getExperimentResultSummaryTwoByTwo dataConfig",
        dataConfig,
        authToken
      );
      // let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/measure/GetExperimentResultSummary`;
      // axios({
      //   method: "POST",
      //   url: fetchUrl,
      //   data: dataConfig,
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + authToken,
      //   },
      // })
      //   .then((response) => {
      //     console.log(response.data, "GetExperimentResultSummary response");
      //     resolve(response.data);
      //   })
      //   .catch((err) => {
      //     console.log("GetExperimentResultSummary err", err);
      //     resolve({
      //       data: [],
      //     });
      //   });
      /** */
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultsSummaryTwoByTwoChart`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          console.log("getExperimentResultSummaryTwoByTwo data", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getExperimentResultDetailedViewCorrelationChart = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExecutiveResultDetailedViewCorrelationChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const data = res;
          console.log(
            "getExperimentResultDetailedViewCorrelationChart dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log(
        "getExperimentResultSummaryTwoByTwo dataConfig",
        dataConfig,
        authToken
      );
      // let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/measure/GetExperimentResultSummary`;
      // axios({
      //   method: "POST",
      //   url: fetchUrl,
      //   data: dataConfig,
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + authToken,
      //   },
      // })
      //   .then((response) => {
      //     console.log(response.data, "GetExperimentResultSummary response");
      //     resolve(response.data);
      //   })
      //   .catch((err) => {
      //     console.log("GetExperimentResultSummary err", err);
      //     resolve({
      //       data: [],
      //     });
      //   });
      /** */
      dotNetApi
        .post(
          `/Experiments/GetExperimentResultDetailedViewCorrelationChartInterval`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const data = res;
          console.log("getExperimentResultSummaryTwoByTwo data", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });
