import React, { useState, useEffect } from "react";
import moment from "moment";
import { Space } from "@pai-ui/core/components/space";
// import { DateRangePicker } from "@pai-ui/core/components/date-range-picker";
// import { Button } from "@pai-ui/core/components/button";
import { Cascader } from "@pai-ui/core/components/cascader";
// import { Select } from "@pai-ui/core/components/select";
import { UserContext } from "@xai/providers/with-auth";
import { FilterComponent } from "@xai/assets/generic-components/utils";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import WaveLoader from "@xai/assets/generic-components/wave-loader";

/**
 * #TODO :
 * 1. Custom range selection limit based on screen and data in scope
 */

/**
 * Custom Configs
 */
// const baseline_custom_range = "baseline_custom_range";
// const results_custom_range = "results_custom_range";

const DateSelectionRangePicker = ({
  currentScreen,
  selectedCohort,
  setSelectedCohort,
  // selectedOption,
  // setSelectedOption,
}) => {
  // const [selectedCohort, setSelectedCohort] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);
  const [cascaderOpened, setCascaderOpen] = useState(false);
  const [datePickerOpened, setDatePickerOpen] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [optionLists, setOptionLists] = useState([]);
  const { experimentModule /* , genericOptions */ } =
    React.useContext(UserContext);
  const experimentTaskManagementData =
    experimentModule?.selectedExperiment?.experiment_task_management;
  // const PresetOptions = genericOptions?.preset_range;

  const generateCascaderOptions = () => {
    /**
     * Remove custom_ranges feature for cascader for time being.
     * once DS modules are ready we could bring this feature back.
     * #TODO : This item could be brought in post request from @pauline.
     */
    console.log("experimentTaskManagementData", experimentTaskManagementData);
    const tempParentValue = ["send_out_dates" /* , "custom_ranges" */];
    const DateSelectionRangePickerCascaderOptions = [];
    const experimentAnalyzeSendOutDatesOptions = experimentTaskManagementData
      ?.filter(
        (taskItem) =>
          taskItem?.request_from_screen === "analyze" &&
          taskItem.prior_date_range?.length > 0 &&
          // defualt condition for preset range options
          taskItem.version === 1
      )
      ?.sort((a, b) =>
        a.start_time_kpi > b.start_time_kpi
          ? -1
          : b.start_time_kpi > a.start_time_kpi
          ? 1
          : 0
      )
      ?.map((reqItem) => {
        return {
          label:
            reqItem?.status === 1 ? (
              `${reqItem.send_out_week} - ${
                reqItem.send_out_date
              } - ${splitByDelimiterAndToStartCase(reqItem.prior_date_range)}`
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "auto",
                  marginRight: "5px",
                }}
              >
                <Space>
                  <span>{`${reqItem.send_out_week} - ${
                    reqItem.send_out_date
                  } - ${splitByDelimiterAndToStartCase(
                    reqItem.prior_date_range
                  )}`}</span>
                  {reqItem?.status !== 1 && (
                    <WaveLoader size="10" color="#15afd0" />
                  )}
                </Space>
              </div>
            ),
          value: reqItem?.id,
          disabled: reqItem?.status !== 1,
          item: reqItem,
        };
      });
    console.log(
      "experimentAnalyzeSendOutDatesOptions",
      experimentAnalyzeSendOutDatesOptions
    );

    /**
     * experimentTaskManagementCustomRangeOptions need to be checked if modification is required or not
     */
    // const experimentTaskManagementCustomRangeOptions =
    //   experimentTaskManagementData?.filter(
    //     (taskItem) =>
    //       [null, ""].indexOf(taskItem.prior_date_range) > -1 &&
    //       taskItem.request_type === baseline_custom_range
    //   );
    // const TaskManagementCustomRangeCascaderOptions = [
    //   ...experimentTaskManagementCustomRangeOptions?.map(
    //     (TaskManagementItem) => {
    //       return {
    //         value: TaskManagementItem.id,
    //         // [
    //         //   TaskManagementItem.start_time_kpi,
    //         //   TaskManagementItem.end_time_kpi,
    //         // ],
    //         label:
    //           TaskManagementItem.status === 1 ? (
    //             <span>{`${moment(TaskManagementItem.start_time_kpi).format(
    //               "MM/DD/YYYY"
    //             )} - ${moment(TaskManagementItem.end_time_kpi).format(
    //               "MM/DD/YYYY"
    //             )}`}</span>
    //           ) : (
    //             <div
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 margin: "auto",
    //                 marginRight: "5px",
    //               }}
    //             >
    //               <Space>
    //                 <span>{`${moment(TaskManagementItem.start_time_kpi).format(
    //                   "MM/DD/YYYY"
    //                 )} - ${moment(TaskManagementItem.end_time_kpi).format(
    //                   "MM/DD/YYYY"
    //                 )}`}</span>
    //                 {TaskManagementItem.status !== 1 && (
    //                   <WaveLoader size="10" color="#15afd0" />
    //                 )}
    //               </Space>
    //             </div>
    //           ),
    //         disabled: TaskManagementItem.status !== 1,
    //       };
    //     }
    //   ),
    //   {
    //     value: "New Custom Range",
    //     label: (
    //       <div
    //         onClick={(e) => {
    //           e.stopPropagation();
    //         }}
    //         style={{
    //           margin: "-6px -13px",
    //           padding: 5,
    //           display: "flex",
    //           flexDirection: "column",
    //         }}
    //       >
    //         <span style={{ margin: "10px 0px" }}>New Custom Range</span>
    //         <DateRangePicker
    //           allowClear={false}
    //           open={datePickerOpened}
    //           onOpenChange={(isOpened) => {
    //             setDatePickerOpen(isOpened);
    //           }}
    //           onChange={setDatePickerValue}
    //           value={datePickerValue}
    //         />
    //         {
    //           <div
    //             style={{
    //               display: "flex",
    //               justifyContent: "flex-end",
    //               margin: "5px 0",
    //             }}
    //           >
    //             <Button
    //               disabled={datePickerValue?.length != 2}
    //               label="Calculate"
    //               onClick={() => {
    //                 // setSelectedCohort([
    //                 //   "New custom range",
    //                 //   `${moment(datePickerValue[0]).format(
    //                 //     "MM/DD/YYYY"
    //                 //   )} - ${moment(datePickerValue[1]).format("MM/DD/YYYY")}`,
    //                 // ]);
    //                 /**
    //                  * #TODO
    //                  * trigger method to raise request for creating new Analyze data for selected time period
    //                  * show pop up message about request
    //                  */
    //                 setDatePickerOpen(false);
    //                 setCascaderOpen(false);
    //               }}
    //             />
    //           </div>
    //         }
    //       </div>
    //     ),
    //   },
    // ];
    // console.log(
    //   "TaskManagementCustomRangeCascaderOptions",
    //   TaskManagementCustomRangeCascaderOptions
    // );

    tempParentValue.forEach((item) =>
      DateSelectionRangePickerCascaderOptions.push({
        value: item,
        label: splitByDelimiterAndToStartCase(item),
        children:
          item === "send_out_dates"
            ? experimentAnalyzeSendOutDatesOptions
            : // eslint-disable-next-line no-undef
              TaskManagementCustomRangeCascaderOptions,
      })
    );
    setOptionLists(DateSelectionRangePickerCascaderOptions);
    /**
     * Below conditional is to handle default setting for cascader
     */
    if (!datePickerValue) {
      let enabledOption = experimentAnalyzeSendOutDatesOptions.find(
        (item) => !item.disabled
      );
      let tempSelectedCohort = experimentModule?.analyze_screen_selected_cohort;

      if (tempSelectedCohort) {
        // update selected cohort with version 1 task id, if passed task id is of different version
        let selectedExpTaskList =
          experimentModule?.selectedExperiment?.experiment_task_management;
        let selectedCohortTask = selectedExpTaskList?.find(
          (taskItem) => taskItem.id === tempSelectedCohort?.[1]
        );
        if (selectedCohortTask?.version > 1) {
          let selectedExpAnalyzeVersion_1 = selectedExpTaskList?.find(
            (taskItem) =>
              new Date(taskItem?.start_time_kpi).toISOString() ===
                new Date(selectedCohortTask?.start_time_kpi).toISOString() &&
              new Date(taskItem?.end_time_kpi).toISOString() ===
                new Date(selectedCohortTask?.end_time_kpi).toISOString() &&
              taskItem.version === 1
          );
          let updatedSelectedCohort = [
            tempSelectedCohort[0],
            selectedExpAnalyzeVersion_1?.id,
          ];
          setSelectedCohort(updatedSelectedCohort);
        } else {
          setSelectedCohort(tempSelectedCohort);
        }
      } else if (enabledOption) {
        setSelectedCohort(["send_out_dates", enabledOption?.["value"]]);
      }
    }
  };

  // const generateSelectOptions = () => {
  //   const experimentTaskManagementCustomRangeOptions =
  //     experimentTaskManagementData?.filter(
  //       (taskItem) =>
  //         // [null, ""].indexOf(taskItem.prior_date_range) > -1 &&
  //         taskItem.request_type === results_custom_range &&
  //         taskItem?.version === 1 // filter out version 1 date ranges, for unique dropdown options
  //     );

  //   const TaskManagementCustomRangeSelectOptions =
  //     experimentTaskManagementCustomRangeOptions
  //       ? [
  //           ...experimentTaskManagementCustomRangeOptions?.map(
  //             (TaskManagementItem) => {
  //               return {
  //                 id: TaskManagementItem.id,
  //                 name:
  //                   TaskManagementItem.status === 1 ? (
  //                     <span>{`${moment(
  //                       TaskManagementItem.start_time_kpi
  //                     ).format("MM/DD/YYYY")} - ${moment(
  //                       TaskManagementItem.end_time_kpi
  //                     ).format("MM/DD/YYYY")}`}</span>
  //                   ) : (
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         flexDirection: "row",
  //                         margin: "auto",
  //                         marginRight: "5px",
  //                       }}
  //                     >
  //                       <Space>
  //                         <span>{`${moment(
  //                           TaskManagementItem.start_time_kpi
  //                         ).format("MM/DD/YYYY")} - ${moment(
  //                           TaskManagementItem.end_time_kpi
  //                         ).format("MM/DD/YYYY")}`}</span>
  //                         {TaskManagementItem.status !== 1 && (
  //                           <WaveLoader size="10" color="#15afd0" />
  //                         )}
  //                       </Space>
  //                     </div>
  //                   ),
  //                 disabled: TaskManagementItem.status !== 1,
  //               };
  //             }
  //           ),
  //           // {
  //           //   id: "New Custom Range",
  //           //   name: (
  //           //     <div
  //           //       onClick={(e) => {
  //           //         e.stopPropagation();
  //           //       }}
  //           //       style={{
  //           //         // margin: "-6px -13px",
  //           //         padding: 5,
  //           //         display: "flex",
  //           //         flexDirection: "column",
  //           //       }}
  //           //     >
  //           //       <span style={{ margin: "10px 0px" }}>New Custom Range</span>
  //           //       <DateRangePicker
  //           //         allowClear={false}
  //           //         open={datePickerOpened}
  //           //         onOpenChange={(isOpened) => {
  //           //           setDatePickerOpen(isOpened);
  //           //         }}
  //           //         onChange={setDatePickerValue}
  //           //         value={datePickerValue}
  //           //       />
  //           //       {
  //           //         <div
  //           //           style={{
  //           //             display: "flex",
  //           //             justifyContent: "flex-end",
  //           //             margin: "5px 0",
  //           //           }}
  //           //         >
  //           //           <Button
  //           //             disabled={datePickerValue?.length != 2}
  //           //             label="Calculate"
  //           //             onClick={() => {
  //           //               // setSelectedOption(
  //           //               //   `New custom range / ${moment(datePickerValue[0]).format(
  //           //               //     "MM/DD/YYYY"
  //           //               //   )} - ${moment(datePickerValue[1]).format("MM/DD/YYYY")}`
  //           //               // );

  //           //               /**
  //           //                * #TODO
  //           //                * trigger method to raise request for creating new result data for selected time period
  //           //                * show pop up message about request
  //           //                */
  //           //               setDatePickerOpen(false);
  //           //               setCascaderOpen(false);
  //           //             }}
  //           //           />
  //           //         </div>
  //           //       }
  //           //     </div>
  //           //   ),
  //           //   disabled: true, // to handle default selection
  //           // },
  //         ]
  //       : [];

  //   setOptionLists(TaskManagementCustomRangeSelectOptions);

  //   /**
  //    * Below conditional is to handle default setting for Select
  //    */
  //   if (!datePickerValue) {
  //     let enabledOption = TaskManagementCustomRangeSelectOptions.find(
  //       (item) => !item.disabled
  //     );
  //     let tempSelectedCohort = experimentModule?.result_screen_selected_cohort;

  //     if (tempSelectedCohort) {
  //       // update selected option with version 1 task id, if passed task id is of different version
  //       let selectedExpTaskList =
  //         experimentModule?.selectedExperiment?.experiment_task_management;
  //       let selectedOptionTask = selectedExpTaskList?.find(
  //         (taskItem) => taskItem.id === tempSelectedCohort
  //       );
  //       if (selectedOptionTask?.version > 1) {
  //         let selectedExpAnalyzeVersion_1 = selectedExpTaskList?.find(
  //           (taskItem) =>
  //             new Date(taskItem?.start_time_kpi).toISOString() ===
  //               new Date(selectedOptionTask?.start_time_kpi).toISOString() &&
  //             new Date(taskItem?.end_time_kpi).toISOString() ===
  //               new Date(selectedOptionTask?.end_time_kpi).toISOString() &&
  //             taskItem.version === 1
  //         );
  //         let updatedSelectedCohort = selectedExpAnalyzeVersion_1?.id;
  //         setSelectedOption(updatedSelectedCohort);
  //       } else {
  //         setSelectedOption(tempSelectedCohort);
  //       }
  //     } else if (enabledOption) {
  //       setSelectedOption(enabledOption?.["id"]);
  //     }
  //   }
  // };

  const generateResultsCascaderOptions = () => {
    /**
     * Remove custom_ranges feature for cascader for time being.
     * once DS modules are ready we could bring this feature back.
     * #TODO : This item could be brought in post request from the stake holder
     */
    console.log("experimentTaskManagementData", experimentTaskManagementData);
    // const tempParentValue = [
    //   "send_out_dates",
    //   "first_4_weeks",
    //   "last_4_weeks",
    //   "All",
    //   /* , "custom_ranges" */
    // ];

    const DateSelectionRangePickerCascaderOptions = [];

    // Send Out Dates (week wise)
    const experimentResultSendOutDatesOptions = experimentTaskManagementData
      ?.filter(
        (taskItem) =>
          taskItem?.request_from_screen === "results" &&
          taskItem.prior_date_range?.length > 0 &&
          taskItem.prior_date_range === "last_7_days" &&
          // defualt condition for preset range options
          taskItem.version === 1
      )
      ?.sort((a, b) =>
        a.start_time_kpi > b.start_time_kpi
          ? -1
          : b.start_time_kpi > a.start_time_kpi
          ? 1
          : 0
      )
      ?.map((reqItem) => {
        return {
          label:
            reqItem?.status === 1 ? (
              `${reqItem.send_out_week} - ${reqItem.send_out_date}`
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "auto",
                  marginRight: "5px",
                }}
              >
                <Space>
                  <span>{`${reqItem.send_out_week} - ${reqItem.send_out_date}`}</span>
                  {reqItem?.status !== 1 && (
                    <WaveLoader size="10" color="#15afd0" />
                  )}
                </Space>
              </div>
            ),
          value: reqItem?.id,
          disabled: reqItem?.status !== 1,
          item: reqItem,
        };
      });

    console.log(
      "experimentResultSendOutDatesOptions",
      experimentResultSendOutDatesOptions
    );
    DateSelectionRangePickerCascaderOptions.push({
      value: "send_out_dates",
      label: splitByDelimiterAndToStartCase("send_out_dates"),
      children: experimentResultSendOutDatesOptions,
    });

    let weekWiseItems = experimentTaskManagementData?.filter(
      (taskItem) =>
        taskItem?.request_from_screen === "results" &&
        taskItem.prior_date_range?.length > 0 &&
        taskItem.prior_date_range === "last_7_days" &&
        // defualt condition for preset range options
        taskItem.version === 1
    );
    let weekIndexes = weekWiseItems?.map((reqItem) =>
      parseInt(reqItem.send_out_week.replace(/[^0-9\\.]+/g, ""))
    );
    let max_weekNumber = Math.max(...weekIndexes);
    console.log("weekIndexes", weekIndexes);
    let first_4week_all_taskItem = [];
    let latest_last_4_weeks_taskItem = [];
    let latest_all_taskItem = [];
    // First 4 weeks
    if (weekIndexes?.some((e) => e === 4)) {
      first_4week_all_taskItem = experimentTaskManagementData?.find(
        (e) =>
          parseInt(e?.send_out_week?.replace(/[^0-9\\.]+/g, "")) === 4 &&
          e?.prior_date_range?.toLowerCase() === "all"
      );

      DateSelectionRangePickerCascaderOptions.push({
        value: first_4week_all_taskItem?.id,
        label:
          first_4week_all_taskItem?.status === 1 ? (
            splitByDelimiterAndToStartCase("first_4_weeks")
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                marginRight: "5px",
              }}
            >
              <Space>
                <span>{splitByDelimiterAndToStartCase("first_4_weeks")}</span>
                {first_4week_all_taskItem?.status !== 1 && (
                  <WaveLoader size="10" color="#15afd0" />
                )}
              </Space>
            </div>
          ),
        disabled: first_4week_all_taskItem?.status !== 1,
        item: first_4week_all_taskItem,
      });
    }

    // Last 4 weeks
    if (weekIndexes?.some((e) => e >= 4)) {
      latest_last_4_weeks_taskItem = experimentTaskManagementData?.find(
        (e) =>
          parseInt(e?.send_out_week?.replace(/[^0-9\\.]+/g, "")) ===
            max_weekNumber &&
          e?.prior_date_range?.toLowerCase() === "last_4_weeks"
      );
      DateSelectionRangePickerCascaderOptions.push({
        value: latest_last_4_weeks_taskItem?.id,
        label:
          latest_last_4_weeks_taskItem?.status === 1 ? (
            splitByDelimiterAndToStartCase("last_4_weeks")
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                marginRight: "5px",
              }}
            >
              <Space>
                <span>{splitByDelimiterAndToStartCase("last_4_weeks")}</span>
                {latest_last_4_weeks_taskItem?.status !== 1 && (
                  <WaveLoader size="10" color="#15afd0" />
                )}
              </Space>
            </div>
          ),
        disabled: latest_last_4_weeks_taskItem?.status !== 1,
        item: latest_last_4_weeks_taskItem,
      });
    }

    //all
    if (weekIndexes?.some((e) => e >= 2)) {
      latest_all_taskItem = experimentTaskManagementData.find(
        (e) =>
          parseInt(e.send_out_week.replace(/[^0-9\\.]+/g, "")) ===
            max_weekNumber && e.prior_date_range.toLowerCase() === "all"
      );
      DateSelectionRangePickerCascaderOptions?.push({
        value: latest_all_taskItem?.id,
        label:
          latest_all_taskItem?.status === 1 ? (
            splitByDelimiterAndToStartCase("all")
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                marginRight: "5px",
              }}
            >
              <Space>
                <span>{splitByDelimiterAndToStartCase("all")}</span>
                {latest_all_taskItem?.status !== 1 && (
                  <WaveLoader size="10" color="#15afd0" />
                )}
              </Space>
            </div>
          ),
        disabled: latest_all_taskItem?.status !== 1,
        item: latest_all_taskItem,
      });
    }

    console.log(
      "DateSelectionRangePickerCascaderOptions",
      DateSelectionRangePickerCascaderOptions
    );
    /**
     * experimentTaskManagementCustomRangeOptions need to be checked if modification is required or not
     */

    setOptionLists(DateSelectionRangePickerCascaderOptions);
    /* default X.AI-behaviour */
    if (!datePickerValue) {
      let enabledOption = experimentResultSendOutDatesOptions?.find(
        (item) => !item.disabled
      );
      let tempSelectedCohort = experimentModule?.result_screen_selected_cohort;

      if (tempSelectedCohort) {
        // update selected cohort with version 1 task id
        setSelectedCohort(tempSelectedCohort);
      } else if (enabledOption) {
        setSelectedCohort(["send_out_dates", enabledOption?.["value"]]);
      }
    }

    /* default to All Date option */
    // if (!datePickerValue) {
    //   let tempSelectedCohort = experimentModule?.result_screen_selected_cohort;

    //   if (tempSelectedCohort) {
    //     // update selected cohort with version 1 task id
    //     setSelectedCohort(tempSelectedCohort);
    //   } else if (latest_all_taskItem) {
    //     setSelectedCohort([latest_all_taskItem?.["id"]]);
    //   }
    // }
  };

  const load = () => {
    /**
     * IF currentScreen === "analyze"
     */
    if (currentScreen === "analyze") {
      generateCascaderOptions();
    } else if (currentScreen === "results") {
      /**
       * IF currentScreen === "results"
       */
      // generateSelectOptions();
      generateResultsCascaderOptions();
    }
  };

  useEffect(() => {
    if (currentScreen === "analyze") {
      setSelectedCohort([]);
    } else if (currentScreen === "results") {
      // // setSelectedOption(null);
      // setSelectedOption("");
      setSelectedCohort([]);
    }
    setDatePickerOpen(false);
    setCascaderOpen(false);
    setDatePickerValue(null);
    setOptionLists([]);
    console.log(
      "changed on experimentModule.selectedExperiment",
      selectedCohort,
      // selectedOption,
      experimentModule.selectedExperiment
    );
    load();
  }, [experimentModule.selectedExperiment]);

  useEffect(() => {
    load();
    console.log("datePickerValue", datePickerValue, datePickerOpened);
    console.log("selectedCohort", selectedCohort, cascaderOpened);
  }, [/* cascaderOpened, */ datePickerOpened]);

  return (
    <FilterComponent title="">
      {currentScreen === "analyze" && (
        <Cascader
          allowClear={false}
          style={{ width: "560px" }}
          options={optionLists}
          value={selectedCohort?.length > 0 ? selectedCohort : []}
          expandTrigger="hover"
          onChange={(value) => {
            console.log("cascader value", value);
            setDatePickerValue(null);
            setSelectedCohort(value);
          }}
          open={cascaderOpened}
          onDropdownVisibleChange={(isOpened) => {
            if (datePickerOpened) {
              return;
            }
            setCascaderOpen(isOpened);
          }}
          displayRender={(labels, selectedOptions) =>
            labels.map((label, i) => {
              const option = selectedOptions[i];
              if (i === labels.length - 1) {
                return (
                  <span key={option.value}>
                    {label} (
                    {moment(option?.item?.start_time_kpi).format("DD MMM YYYY")}{" "}
                    to{" "}
                    {moment(option?.item?.end_time_kpi).format("DD MMM YYYY")})
                  </span>
                );
              }
              return <span key={option.value}>{label} / </span>;
            })
          }
        />
      )}
      {currentScreen === "results" && (
        // <Select
        //   style={{ width: "330px" }}
        //   options={optionLists}
        //   value={selectedOption}
        //   onChange={(value) => {
        //     console.log("Select value", value);
        //     setDatePickerValue(null);
        //     setSelectedOption(value);
        //   }}
        //   open={cascaderOpened}
        //   onDropdownVisibleChange={(isOpened) => {
        //     if (datePickerOpened) {
        //       return;
        //     }
        //     setCascaderOpen(isOpened);
        //   }}
        // />
        // <>WIP</>
        <Cascader
          allowClear={false}
          style={{ width: "460px" }}
          options={optionLists}
          value={selectedCohort?.length > 0 ? selectedCohort : []}
          expandTrigger="hover"
          onChange={(value) => {
            console.log("cascader value", value);
            setDatePickerValue(null);
            setSelectedCohort(value);
          }}
          open={cascaderOpened}
          onDropdownVisibleChange={(isOpened) => {
            if (datePickerOpened) {
              return;
            }
            setCascaderOpen(isOpened);
          }}
          displayRender={(labels, selectedOptions) =>
            labels.map((label, i) => {
              const option = selectedOptions[i];
              if (i === labels.length - 1) {
                return (
                  <span key={option?.value}>
                    {label} (
                    {moment(option?.item?.start_time_kpi).format("DD MMM YYYY")}{" "}
                    to{" "}
                    {moment(option?.item?.end_time_kpi).format("DD MMM YYYY")})
                  </span>
                );
              }
              return <span key={option?.value}>{label} / </span>;
            })
          }
        />
      )}
    </FilterComponent>
  );
};

export default DateSelectionRangePicker;
