import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { arrayMoveImmutable } from "array-move";
import { InformationCircle } from "@styled-icons/heroicons-outline/InformationCircle";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import {
  Visualization,
  TableConfig,
  VisualizationActionType,
} from "@pai-ui/core/components/visualization";
import { Space } from "@pai-ui/core/components/space";
import { Popover } from "@pai-ui/core/components/popover";
import { Button } from "@pai-ui/core/components/button";
import { Tooltip } from "@pai-ui/core/components/tooltip";
import { Avatar } from "@pai-ui/core/components/avatar";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Divider } from "@pai-ui/core/components/divider";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";
import {
  ConfidenceIntervalStartKeyword,
  ConfidenceIntervalEndKeyword,
  ImpactKeyword,
} from "./transformer";
import "../index.css";

const ParameterVariantComparisionTable2 = ({
  tableData,
  selectedVariants,
  error,
  loadDetailedViewTabData,
  selectedDateRangeVal,
  selectedAudience,
  endOfExportFileName,
}) => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState();
  const [loading, setLoading] = useState(true);

  const defaultFilteredTableRows = 13;

  const { format, experimentModule } = React.useContext(UserContext);
  const ExperimentAudienceGroupAvailable =
    experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]
      ?.audience_group_name;
  const ExperimentComparisonTableAlphaValue =
    experimentModule?.selectedExperiment?.experiment_data
      ?.comparison_table_alpha_value || 0.05;

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const SortableItem = SortableElement((props) => <tr {...props} />);

  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newData = { data: [] };
      newData.data = arrayMoveImmutable(
        data.data.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setData(newData);
    }
  };
  const DraggableContainer = (props) => {
    // console.log(props);
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };
  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index

    const index = data?.data?.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const ConfidenceIntervalCalculation = ({ dataKey, dataFormat, dataRow }) => {
    let tempStr = "";
    let tempObjKey = tableData?.columns
      ?.find(
        (item) => item.dataKey === dataKey.substring(0, dataKey.indexOf("||||"))
      )
      .children.find(
        (childrenItem) => childrenItem.kpi_name === ConfidenceIntervalEndKeyword
      )["key"];
    tempStr = `${numeral(dataRow[dataKey]).format(
      format?.[dataFormat]
    )} - ${numeral(dataRow[tempObjKey]).format(format?.[dataFormat])}`;
    return tempStr;
  };

  const loadTableColumns = (tableColumnData, data) => {
    if (tableColumnData && tableColumnData?.length > 0 && data) {
      let tempTableColumns = tableColumnData;
      console.log("tableColumnData", tableColumnData);
      let initialColumn = {
        dataKey: "parameter_type",
        title: <div style={{ textAlign: "center" }}>Parameters</div>,
        filters: tableData?.table_data?.data?.map((parameters) => {
          return {
            value: parameters?.parameter_type,
            text: parameters?.parameter_type,
          };
        }),
        onFilter: (value, record) => record?.parameter_type?.startsWith(value),
        filterSearch: true,
        defaultFilteredValue: tableData?.table_data?.data
          ?.slice(0, defaultFilteredTableRows)
          ?.map((parameters) => parameters.parameter_type),
        fixed: "left",
        width: 250,
        render: (text) => (
          <Space>
            <DragHandle />
            <span style={{ fontSize: "14px" }}>{text}</span>
          </Space>
        ),
      };
      let tempTableColumnsFiltered = ExperimentAudienceGroupAvailable
        ? tempTableColumns
          ?.filter(
            (columnObj) =>
              columnObj?.comparision_variant_1_parent_name ===
              selectedAudience ||
              columnObj?.comparision_variant_2_parent_name ===
              selectedAudience
          )
          ?.filter(
            (columnObj) =>
              selectedVariants?.includes(
                columnObj?.comparision_variant_1_name
              ) ||
              selectedVariants?.includes(
                columnObj?.comparision_variant_2_name
              )
          )
        : tempTableColumns?.filter(
          (columnObj) =>
            selectedVariants?.includes(
              columnObj?.comparision_variant_1_name
            ) ||
            selectedVariants?.includes(columnObj?.comparision_variant_2_name)
        );

      let baselineKeyData = tempTableColumnsFiltered?.[0]?.children?.filter(
        (x) => x?.kpi_name?.toLowerCase() === "baseline"
      )?.[0];
      let defaultColumnObj = tempTableColumnsFiltered?.[0];

      let baselineValObj = {
        key: defaultColumnObj?.dataKey,
        valueKey: baselineKeyData?.key,
        format_list: baselineKeyData?.format_list,
        name: baselineKeyData?.kpi_name,
      };

      let secondColumn = {
        dataKey: baselineKeyData?.key,
        key: baselineKeyData?.key,
        title: (
          <span>
            <Popover
              overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
              content={
                <div>
                  {defaultColumnObj?.comparision_variant_2_label &&
                    defaultColumnObj?.comparision_variant_2_label?.includes(
                      "||||"
                    ) ? (
                    <>
                      <h3>
                        {`${defaultColumnObj?.comparision_variant_2_name} - ${defaultColumnObj?.comparision_variant_2_label?.split(
                          "||||"
                        )?.[0]
                          }` || "Control"}
                        {/* {`${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_label}`} */}
                      </h3>
                      <Divider
                        orientation="left"
                        style={{ margin: "3px 0px" }}
                      />
                      <p>{`${defaultColumnObj?.comparision_variant_2_label?.split(
                        "||||"
                      )?.[1]
                        }`}</p>
                    </>
                  ) : (
                    <h3>
                      {`${defaultColumnObj?.comparision_variant_2_name} - ${defaultColumnObj?.comparision_variant_2_label}` ||
                        "Control"}
                      {/* {`${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_label}`} */}
                    </h3>
                  )}
                </div>
              }
            >
              <InformationCircle style={{ width: "23px" }}></InformationCircle>
            </Popover>{" "}
            &nbsp;&nbsp;&nbsp;
            {defaultColumnObj?.comparision_variant_2_name || "Control"}
          </span>
        ),
        align: "right",
        fixed: "left",
        width: 100,
        render: (_, r) => {
          const localFormatValue = baselineKeyData?.format_list?.find(
            (formatObj) => formatObj.parameter_type === r.parameter_type
          )?.format;
          return baselineKeyData?.kpi_name?.toLowerCase() ===
            ConfidenceIntervalStartKeyword?.toLowerCase() ? (
            <div>
              <ConfidenceIntervalCalculation
                dataKey={baselineKeyData?.key}
                dataFormat={localFormatValue}
                dataRow={r}
              />
            </div>
          ) : _ !== null ? (
            <PosNegValue
              value={_}
              valueFormat={format?.[localFormatValue]}
              textColor={"rgba(225,225,225,0.85)"}
              showCaret={
                baselineKeyData?.kpi_name?.toLowerCase() ===
                ImpactKeyword?.toLowerCase()
              }
              ZeroRange={{
                minNegZero:
                  r[
                    `${baselineKeyData?.key?.substring(
                      0,
                      baselineKeyData?.key?.indexOf("||||")
                    )}||||impact_magnitude`
                  ] == "negative"
                    ? _ > 0
                      ? _ * 1.01
                      : _ * 0.99
                    : _,
                minPosZero:
                  r[
                    `${baselineKeyData?.key?.substring(
                      0,
                      baselineKeyData?.key?.indexOf("||||")
                    )}||||impact_magnitude`
                  ] == "positive"
                    ? _ > 0
                      ? _ * 0.99
                      : _ * 1.01
                    : _,
              }}
              fontSize={"14px"}
            />
          ) : (
            "--"
          );
        },
        variant_parent_name:
          defaultColumnObj?.comparision_variant_2_parent_name,
        variant_id: defaultColumnObj?.comparision_variant_2_id,
        variant_name: defaultColumnObj?.comparision_variant_2_name,
        variant_label: defaultColumnObj?.comparision_variant_2_label,
        variant_order: defaultColumnObj?.comparision_variant_2_order,
        baselineValObj: baselineValObj,
      };
      console.log(
        "PVCT - tempTableColumnsFiltered",
        tempTableColumnsFiltered,
        baselineKeyData
      );
      tempTableColumnsFiltered = tempTableColumnsFiltered?.map((column) => {
        let testKeyObj,
          testValObj,
          pValueKeyObj,
          pValueValObj,
          incrementalKeyObj,
          incrementalValObj,
          liftKeyObj,
          liftValObj;

        // Set key - val objects for Test, P-Value, Incremental, Lift
        column?.children?.forEach((child) => {
          if (child.kpi_name.toLowerCase() === "test") {
            testKeyObj = child;
          } else if (child.kpi_name.toLowerCase() === "p-value") {
            pValueKeyObj = child;
          } else if (child.kpi_name.toLowerCase() === "incremental") {
            incrementalKeyObj = child;
          } else if (child.kpi_name.toLowerCase() === "lift") {
            liftKeyObj = child;
          }
        });
        // Test Key data
        // testKeyObj = column?.children?.find(
        //   (x) => x.kpi_name.toLowerCase() === "test"
        // );
        testValObj = {
          key: column?.dataKey,
          valueKey: testKeyObj?.key,
          format_list: testKeyObj?.format_list,
          name: testKeyObj?.kpi_name,
          // value: r?.[testKeyObj?.key],
          // format: testKeyObj?.format_list?.find(
          //   (formatObj) => formatObj.parameter_type === r.parameter_type
          // )?.format,
        };
        // "P-Value" key data
        // pValueKeyObj = column?.children?.find(
        //   (x) => x.kpi_name.toLowerCase() === "p-value"
        // );
        pValueValObj = {
          key: column?.dataKey,
          valueKey: pValueKeyObj?.key,
          format_list: pValueKeyObj?.format_list,
          name: pValueKeyObj?.kpi_name,
          // value: r?.[pValueKeyObj?.key],
          // format: pValueKeyObj?.format_list?.find(
          //   (formatObj) => formatObj.parameter_type === r.parameter_type
          // )?.format,
        };

        // "Incremental" key data
        // incrementalKeyObj = column?.children?.find(
        //   (x) => x.kpi_name.toLowerCase() === "incremental"
        // );
        incrementalValObj = {
          key: column?.dataKey,
          valueKey: incrementalKeyObj?.key,
          format_list: incrementalKeyObj?.format_list,
          name: incrementalKeyObj?.kpi_name,
          // value: r?.[incrementalKeyObj?.key],
          // format: incrementalKeyObj?.format_list?.find(
          //   (formatObj) => formatObj.parameter_type === r.parameter_type
          // )?.format,
        };

        // "Lift" key data
        // liftKeyObj = column?.children?.find(
        //   (x) => x.kpi_name.toLowerCase() === "lift"
        // );
        liftValObj = {
          key: column?.dataKey,
          valueKey: liftKeyObj?.key,
          format_list: liftKeyObj?.format_list,
          name: liftKeyObj?.kpi_name,
          // value: r?.[liftKeyObj?.key],
          // format: liftKeyObj?.format_list?.find(
          //   (formatObj) => formatObj.parameter_type === r.parameter_type
          // )?.format,
        };

        return {
          dataKey: column?.dataKey,
          // title: `${column.comparision_variant_1_name} vs ${column.comparision_variant_2_name}`,
          title: (
            <span className={!column?.comparision_variant_1_is_active && "opacity-0-55"}>
              <Popover
                overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
                content={
                  <div>
                    {column?.comparision_variant_1_label &&
                      column?.comparision_variant_1_label?.includes("||||") ? (
                      <>
                        <h3>
                          {`${column?.comparision_variant_1_name} - ${column?.comparision_variant_1_label?.split(
                            "||||"
                          )?.[0]
                            }`}
                          {/* {`${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_label}`} */}
                        </h3>
                        <Divider
                          orientation="left"
                          style={{ margin: "5px 0px" }}
                        />
                        <p>{`${column?.comparision_variant_1_label?.split(
                          "||||"
                        )?.[1]
                          }`}</p>
                      </>
                    ) : (
                      <h3>
                        {`${column?.comparision_variant_1_name} - ${column?.comparision_variant_1_label?.split(
                          "||||"
                        )?.[0]
                          }`}
                        {/* {`${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_label}`} */}
                      </h3>
                    )}
                  </div>
                }
              >
                <InformationCircle
                  style={{ width: "23px" }}
                ></InformationCircle>
              </Popover>{" "}
              &nbsp;&nbsp;&nbsp;
              {column?.comparision_variant_1_name}
            </span>
          ),
          width: 230,
          render: (_, r) => {
            const cellSubValue = (ValObj) => (
              <PosNegValue
                value={ValObj?.value}
                valueFormat={format?.[ValObj?.format]}
                textColor={
                  ValObj?.name?.toLowerCase() === "lift" ||
                    ValObj?.name?.toLowerCase() === "incremental"
                    ? ValObj?.value < 0
                      ? "#cf1322e6"
                      : "#6bd015"
                    : "rgba(225,225,225,0.85)"
                }
                showCaret={false}
                ZeroRange={{
                  minNegZero:
                    r[`${ValObj?.key}||||impact_magnitude`] == "negative"
                      ? ValObj?.value == 0
                        ? ValObj?.value + 1
                        : ValObj?.value > 0
                          ? ValObj?.value * 1.01
                          : ValObj?.value * 0.99
                      : ValObj?.value,
                  minPosZero:
                    r[`${ValObj?.key}||||impact_magnitude`] == "positive"
                      ? ValObj?.value == 0
                        ? ValObj?.value - 1
                        : ValObj?.value > 0
                          ? ValObj?.value * 0.99
                          : ValObj?.value * 1.01
                      : ValObj?.value,
                }}
                fontSize={ValObj?.name?.toLowerCase() === "test" && "14px"}
                showValue={
                  ValObj?.name?.toLowerCase() === "p-value" ? false : true
                }
                caratSize={
                  ValObj?.name?.toLowerCase() === "p-value" ? "20px" : "12px"
                }
              />
            );
            // Test Key data
            // let testKeyObj = column?.children?.find(
            //   (x) => x.kpi_name.toLowerCase() === "test"
            // );
            testValObj = {
              ...testValObj,
              // key: column?.dataKey,
              // name: testKeyObj?.kpi_name,
              value: r?.[testKeyObj?.key],
              format: testKeyObj?.format_list?.find(
                (formatObj) => formatObj.parameter_type === r.parameter_type
              )?.format,
            };
            // "P-Value" key data
            // let pValueKeyObj = column?.children?.find(
            //   (x) => x.kpi_name.toLowerCase() === "p-value"
            // );
            pValueValObj = {
              ...pValueValObj,
              // key: column?.dataKey,
              // name: pValueKeyObj?.kpi_name,
              value: r?.[pValueKeyObj?.key],
              format: pValueKeyObj?.format_list?.find(
                (formatObj) => formatObj.parameter_type === r.parameter_type
              )?.format,
            };

            // "Incremental" key data
            // let incrementalKeyObj = column?.children?.find(
            //   (x) => x.kpi_name.toLowerCase() === "incremental"
            // );
            incrementalValObj = {
              ...incrementalValObj,
              // key: column?.dataKey,
              // name: incrementalKeyObj?.kpi_name,
              value: r?.[incrementalKeyObj?.key],
              format: incrementalKeyObj?.format_list?.find(
                (formatObj) => formatObj.parameter_type === r.parameter_type
              )?.format,
            };

            // "Incremental" key data
            // let liftKeyObj = column?.children?.find(
            //   (x) => x.kpi_name.toLowerCase() === "lift"
            // );
            liftValObj = {
              ...liftValObj,
              // key: column?.dataKey,
              // name: liftKeyObj?.kpi_name,
              value: r?.[liftKeyObj?.key],
              format: liftKeyObj?.format_list?.find(
                (formatObj) => formatObj.parameter_type === r.parameter_type
              )?.format,
            };
            // // console and check
            // console.log(
            //   "tempTableColumnsFiltered",
            //   _,
            //   r,
            //   column?.children,
            //   `${column?.dataKey}||||impact_magnitude`,
            //   r[`${column?.dataKey}||||impact_magnitude`],
            //   testValObj,
            //   pValueValObj,
            //   incrementalValObj,
            //   liftValObj
            // );
            return (
              <Row
                span={24}
                align="middle"
                style={{
                  padding: "5px",
                }}
                className={!column?.comparision_variant_1_is_active && "opacity-0-55"}
              >
                <Col
                  span={8}
                  align="middle"
                  justify="start"
                  id={`test-${testValObj?.name}-${column?.dataKey}`}
                  style={{
                    textAlign: "initial",
                  }}
                >
                  {[undefined, null].indexOf(testValObj?.value) > -1 ? (
                    "--"
                  ) : (
                    <>{cellSubValue(testValObj)}</>
                  )}
                </Col>
                <Col
                  span={12}
                  align="middle"
                  style={{
                    textAlign: "right",
                  }}
                >
                  <div
                    id={`incremental-${incrementalValObj?.name}-${column?.dataKey}`}
                  >
                    {[undefined, null].indexOf(incrementalValObj?.value) >
                      -1 ? (
                      "--"
                    ) : (
                      <>
                        <span>{`Delta: `}</span>
                        {cellSubValue(incrementalValObj)}
                      </>
                    )}
                  </div>
                  <div id={`lift-${liftValObj?.name}-${column?.dataKey}`}>
                    {[undefined, null].indexOf(liftValObj?.value) > -1 ? (
                      "--"
                    ) : (
                      <>
                        <span>{`Lift: `}</span>
                        {cellSubValue(liftValObj)}
                      </>
                    )}
                  </div>
                </Col>
                <Col
                  span={2}
                  offset={2}
                  align="middle"
                  id={`pValue-${pValueValObj?.name}-${column?.dataKey}`}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {[undefined, null].indexOf(pValueValObj?.value) > -1 ? (
                    <></>
                  ) : (
                    <>
                      {
                        // (r?.[`${pValueValObj?.key}||||impact_magnitude`] !== null) ?
                        <Tooltip
                          overlayStyle={{ zoom: 1.1111111 }}
                          title={`P-Value: ${numeral(
                            pValueValObj?.value
                          ).format(format?.[pValueValObj?.format])}`}
                        >
                          <div>
                            {
                              <>
                                {
                                  pValueValObj?.value >
                                    ExperimentComparisonTableAlphaValue ? (
                                    <Avatar
                                      style={{ backgroundColor: "grey" }}
                                      size={16}
                                    />
                                  ) : incrementalValObj?.value > 0 ? (
                                    <Avatar
                                      style={{ backgroundColor: "#6bd015" }}
                                      size={16}
                                    /> //Pos
                                  ) : (
                                    <Avatar
                                      style={{ backgroundColor: "#cf1322e6" }}
                                      size={16}
                                    />
                                  ) // Neg
                                }
                              </>
                            }
                          </div>
                        </Tooltip>
                        // :<></>
                      }
                    </>
                  )}
                </Col>
              </Row>
            );
          },
          variant_parent_name: column?.comparision_variant_1_parent_name,
          variant_id: column?.comparision_variant_1_id,
          variant_name: column?.comparision_variant_1_name,
          variant_label: column?.comparision_variant_1_label,
          variant_order: column?.comparision_variant_1_order,
          variant_is_active: column?.comparision_variant_1_is_active,

          // children: column?.children,
          testValObj: testValObj,
          pValueValObj: pValueValObj,
          incrementalValObj: incrementalValObj,
          liftValObj: liftValObj,

          //   children: column?.children
          //     ?.filter(
          //       (filterColumnMetric) =>
          //         // filterColumnMetric.kpi_name !== ConfidenceIntervalEndKeyword
          //         [ConfidenceIntervalEndKeyword,'Baseline'].indexOf(filterColumnMetric.kpi_name) === -1,
          //     )
          //     ?.map((columnMetric) => {
          //       return {
          //         title:
          //           columnMetric?.kpi_name?.toLowerCase() ===
          //           ConfidenceIntervalStartKeyword?.toLowerCase()
          //             ? columnMetric?.kpi_name?.replace("Start", "")
          //             : columnMetric?.kpi_name,
          //         key: columnMetric?.key,
          //         dataIndex: columnMetric?.key,
          //         align: "right",
          //         width: "7.25vw",
          //         render: (_, r) => {
          //           const localFormatValue = columnMetric?.format_list?.find(
          //             (formatObj) =>
          //               formatObj.parameter_type === r.parameter_type
          //           )?.format;
          //           return columnMetric?.kpi_name?.toLowerCase() ===
          //             ConfidenceIntervalStartKeyword?.toLowerCase() ? (
          //             <div>
          //               <ConfidenceIntervalCalculation
          //                 dataKey={columnMetric?.key}
          //                 dataFormat={localFormatValue}
          //                 dataRow={r}
          //               />
          //             </div>
          //           ) : _ !== null ? (
          //             <PosNegValue
          //               value={_}
          //               valueFormat={userState?.format?.[localFormatValue]}
          //               textColor={"rgba(225,225,225,0.85)"}
          //               showCaret={
          //                 columnMetric?.kpi_name?.toLowerCase() ===
          //                 ImpactKeyword?.toLowerCase()
          //               }
          //               ZeroRange={{
          //                 minNegZero:
          //                   r[
          //                     `${columnMetric?.key?.substring(
          //                       0,
          //                       columnMetric?.key?.indexOf("||||")
          //                     )}||||impact_magnitude`
          //                   ] == "negative"
          //                     ? _ > 0
          //                       ? _ * 1.01
          //                       : _ * 0.99
          //                     : _,
          //                 minPosZero:
          //                   r[
          //                     `${columnMetric?.key?.substring(
          //                       0,
          //                       columnMetric?.key?.indexOf("||||")
          //                     )}||||impact_magnitude`
          //                   ] == "positive"
          //                     ? _ > 0
          //                       ? _ * 0.99
          //                       : _ * 1.01
          //                     : _,
          //               }}
          //             />
          //           ) : (
          //             "--"
          //           );
          //         },
          //       };
          //     }),
        };
      })
      ?.sort(
        (a, b) =>
          b.variant_is_active - a.variant_is_active
      );

      let columnConfig = [
        initialColumn,
        secondColumn,
        ...tempTableColumnsFiltered,
      ];
      console.log("PVCT - Table Config", columnConfig, selectedVariants);
      tempTableColumns?.length > 0 ? setColumns(columnConfig) : setColumns([]);
    }
  };

  const Load = () => {
    setLoading(true);
    let localTableData = tableData?.table_data;
    if (localTableData?.data) {
      localTableData.data = localTableData?.data?.map((row, index) => {
        return {
          ...row,
          index: index,
        };
      });
      setData(localTableData);
    }
    setLoading(false);
  };

  const LeftIcon = ({ onClick, className }) => {
    return (
      <button onClick={onClick} className={className}>
        LEFT
      </button>
    );
  };

  const RightIcon = ({ onClick, className }) => {
    return (
      <button onClick={onClick} className={className}>
        RIGHT
      </button>
    );
  };

  useEffect(() => {
    Load();
  }, []);

  useEffect(() => {
    loadTableColumns(tableData?.columns, data);
  }, [data, selectedVariants]);

  const getFileDownloadName = React.useCallback(
    () =>
      `Detailed Variant Comparison - ${experimentModule?.selectedExperiment?.name
      } - ${selectedAudience} - ${endOfExportFileName()}`,
    [selectedVariants]
  );

  const processDataForCSV = React.useCallback(
    (data) => {
      let variant2CombinedLabel = `"${columns[1]?.variant_name} - ${columns[1]?.variant_label}"`;
      let returnObjTemplate = {
        Parameter: "parameter_type",
        [variant2CombinedLabel]: columns[1].baselineValObj,
      };

      columns.slice(2).forEach((column) => {
        let variant1CombinedLabel = `"${column?.variant_name} - ${column?.variant_label}"`;
        let columnReturnObj = {
          [variant1CombinedLabel]: column.testValObj,
          [`${variant1CombinedLabel} - Incremental`]: column.incrementalValObj,
          [`${variant1CombinedLabel} - Lift`]: column.liftValObj,
          [`${variant1CombinedLabel} - P-Value`]: column.pValueValObj,
        };
        returnObjTemplate = {
          ...returnObjTemplate,
          ...columnReturnObj,
        };
      });

      /** */
      data = data.map((item) => {
        let dataItem = JSON.parse(JSON.stringify(returnObjTemplate));

        const retObj = Object.entries(dataItem).reduce((acc, [key, value]) => {
          let parameter_typeVal = item["parameter_type"];
          if (value === "parameter_type") {
            acc[key] = parameter_typeVal;
          } else {
            let val = item?.[value?.valueKey];
            let formatVal = value?.format_list.find(
              (formatItem) => formatItem.parameter_type === parameter_typeVal
            )?.format;

            acc[key] =
              [null, undefined, ""].indexOf(val) > -1
                ? ""
                : numeral(val).format(format?.[formatVal]).toUpperCase();
          }

          return acc;
        }, {});

        return retObj;
      });
      console.log("processDataForCSV", data, columns, returnObjTemplate);
      return data;
      // return [data[0]];
    },
    [columns]
  );

  if (error) {
    return (
      <LegacyNoData height={"69vh"} error={true}>
        <Button onClick={() => loadDetailedViewTabData(selectedDateRangeVal)}>
          Try Again
        </Button>
      </LegacyNoData>
    );
  }

  return (
    <>
      {loading ? (
        <LegacyNoData height={"71vh"} loading />
      ) : data && columns?.length > 0 ? (
        <Visualization
          className="comparision-table-section"
          height={"41vh"}
          withCard={false}
          dataConfig={{
            structureType: "processed",
          }}
          download={{
            getFilename: getFileDownloadName,
            processDataBeforeDownload: processDataForCSV,
          }}
          actions={[
            /* VisualizationActionType.exportJpeg, VisualizationActionType.exportPng, */ VisualizationActionType.exportCsv,
          ]}
          viewConfig={
            new TableConfig({
              pagination: false,
              sticky: true,
              // rowIndex
              idProperty: "index",
              components: {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              },
              tableLayout: "fixed",
              columns: columns,
              bordered: false,
              onRow: (record, index) => ({
                index,
              }),
              scroll: {
                // x: 'calc(700px  50%)',
                // y: 'calc(27vh)',
              },
              slider: {
                enabled: true,
                step: 250,
                leftGap: 0,
                rightGap: 0,
                LeftIcon,
                RightIcon,
              },
            })
          }
          dataset={data}
        />
      ) : (
        // </div>
        <LegacyNoData height={"71vh"} />
      )}
    </>
  );
};

export default ParameterVariantComparisionTable2;
