import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { Select } from "@pai-ui/core/components/select";
import { Visualization } from "@pai-ui/core/components/visualization";
import { Table } from "@pai-ui/core";
import { Metric } from "@pai-ui/core/components/metric";
import * as UI from "@pai-ui/core/components/visualization";
import {
  VisualizationFeature,
  VisualizationActionType,
} from "@pai-ui/core/components/visualization";
import { UserContext } from "@xai/providers/with-auth";
// import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import FindAvatarIcon from "@xai/assets/generic-components/findAvatarIcon";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";
import "./style.css";

const MeasureResultsVariantComparisonTab = ({ variantComparisonData }) => {
  let { comparisonTableData, variantList } = variantComparisonData;
  const { format, experimentModule } = React.useContext(UserContext);

  console.log(
    "variantList",
    // experimentModule?.selectedExperiment?.experiment_data?.variant_setup,
    variantList,
    "comparisonTableData",
    comparisonTableData
  );

  const [columns, setColumns] = useState([]);
  const [allControl, setAllControl] = useState([]);
  const [selectedControl1, setSelectedControl1] = useState();
  const [selectedControl2, setSelectedControl2] = useState();
  const [controlOptions, setControlOptions] = useState([]);
  const [tableData, setTableData] = useState([]);

  //GenericModalWithResultComponent State to be removed later
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

  useEffect(() => {
    if (variantList?.length) {
      setAllControl(variantList);

      setSelectedControl1(variantList[0]?.id);
      setSelectedControl2(variantList[1]?.id);

      const transformedVariantList = variantList?.map((variantObj) => {
        return {
          ...variantObj,
          name: `${variantObj?.name?.split('||||')?.[0]}`
        };
      });

      setControlOptions(transformedVariantList);
    }
  }, [experimentModule.selectedExperiment]);

  const compositionChartTransformation = (compositionChartAdditionalConfig, maxMinDomainrangeArray) => {

    let loccompositionChartAdditionalConfig = compositionChartAdditionalConfig;

    loccompositionChartAdditionalConfig.yAxisConfig = loccompositionChartAdditionalConfig?.yAxisConfig?.map((confObj) => {
      if (confObj?.["id"]) {
        return {
          ...confObj,
          domain: maxMinDomainrangeArray?.["id"] || ["auto", "auto"]
        };
      } else {
        return {
          ...confObj,
          domain: maxMinDomainrangeArray?.["withoutId"] || ["auto", "auto"]
        };
      }
    });
    return loccompositionChartAdditionalConfig;
  };

  const getCellData = (chartData, maxMinDomainrangeArray) => {

    let compositionChartAdditionalConfig = chartData?.chartAdditionalConfig ?
      typeof (chartData?.chartAdditionalConfig) === "string" ?
        JSON.parse(chartData?.chartAdditionalConfig) :
        JSON.parse(JSON.stringify(chartData?.chartAdditionalConfig)) : null;

    if (compositionChartAdditionalConfig) {
      let loccompositionChartAdditionalConfig = compositionChartAdditionalConfig;
      compositionChartAdditionalConfig = compositionChartTransformation(loccompositionChartAdditionalConfig, maxMinDomainrangeArray);
    }

    return <>
      {!chartData.is_chart ? (
        chartData.icon?.length > 0 ? (
          // <>Metric component with icon</>
          <Metric
            withBackground={false}
            prefix={
              <FindAvatarIcon avatar_icon={chartData.icon} avatar_Size={45} />
            }
            title={
              chartData?.kpi_value
                ? numeral(chartData?.kpi_value)
                  .format(format?.[chartData?.format_id])
                  .toUpperCase()
                : ""
            }
          />
        ) : (
          // <>Metric component without icon</>
          <>
            <h2 className="comparision-table-cell-text">
              {chartData?.kpi_value
                ? numeral(chartData?.kpi_value)
                  .format(format?.[chartData?.format_id])
                  .toUpperCase()
                : ""}
            </h2>
          </>
        )
      ) : (
        <>
          {
            // <>viz component</>
            <Visualization
              withCard={false}
              height={270}
              dataConfig={{
                structureType: "processed",
              }}
              features={[VisualizationFeature.fullScreen]}
              actions={[
                VisualizationActionType.exportJpeg,
                VisualizationActionType.exportPng,
                VisualizationActionType.exportCsv,
              ]}
              viewConfig={
                new UI[chartData?.chart_type || "BarChartConfig"]({
                  baseColors: chartData?.chart_type === "CompositionChartConfig" ? ["#acacac", "#15afd0"] : [],
                  layout: chartData?.chart_orientation || "vertical",
                  variant: chartData?.chart_variant,
                  series: {
                    excludeFields: ["format_id", "kpi_order"],
                    getConfig: (fieldName) => {
                      const config =
                        chartData?.chart_type === "CompositionChartConfig"
                          ? compositionChartAdditionalConfig?.seriesGetConfig
                          : {
                            [fieldName]: {
                              name:
                                chartData?.chart_type === "PieChartConfig" ||
                                  (chartData?.chart_type === "BarChartConfig" &&
                                    chartData?.chart_variant == "overlay")
                                  ? (fieldName)
                                  : chartData?.chart_type ===
                                    "BarChartConfig" ||
                                    chartData?.chart_type ===
                                    "LineChartConfig"
                                    ? (
                                      chartData?.chartdata?.[0]
                                        ?.kpi_series_name
                                    )
                                    : (fieldName),
                              label: {
                                position:
                                  chartData?.chart_type === "PieChartConfig"
                                    ? "outside"
                                    : chartData?.chart_orientation ===
                                      "vertical"
                                      ? "right"
                                      : "top",
                                format: {
                                  value:
                                    format?.[
                                    chartData?.chartdata?.[0]?.format_id
                                    ],
                                },
                              },
                            },
                          };
                      return config[fieldName];
                    },
                  },
                  legend: {
                    show: true,
                  },
                  axes: {
                    x: {
                      width: 100,
                      dataKey: "x-axis",
                    },
                    y:
                      chartData?.chart_type === "CompositionChartConfig"
                        ? compositionChartAdditionalConfig?.yAxisConfig?.map(
                          (item) => {
                            return {
                              ...item,
                              // domain: ["auto", "auto"],
                              show: false,
                            };
                          }
                        ) || {
                          // domain: ["auto", "auto"],
                          show: false,
                          format: {
                            value:
                              format?.[chartData?.chartdata?.[0]?.format_id],
                          },
                        }
                        : {
                          domain: maxMinDomainrangeArray?.length === 2 ? maxMinDomainrangeArray : ["auto", "auto"],
                          show: false,
                          format: {
                            value:
                              format?.[chartData?.chartdata?.[0]?.format_id],
                          },
                        },
                  },
                })
              }
              dataset={{
                data:
                  chartData?.chart_type === "PieChartConfig"
                    ? chartData?.chartdata || []
                    : (chartData?.chart_type === "BarChartConfig" &&
                      chartData?.chart_variant == "overlay") ||
                      chartData?.chart_type === "CompositionChartConfig"
                      ? chartData?.chartdata?.map(({ kpi_name, ...key }) => ({
                        "x-axis": kpi_name,
                        ...key,
                      })) || []
                      : chartData?.chart_type === "BarChartConfig" ||
                        chartData?.chart_type === "LineChartConfig"
                        ? chartData?.chartdata?.map((key) => ({
                          "x-axis": key["kpi_name"],
                          value: key["kpi_value"],
                        })) || []
                        : [],
              }}
            />
          }
        </>
      )}
    </>
  };

  const getMaxMinDomainRange = (columnData1, columnData2) => {
    if (columnData1?.is_chart && columnData2?.is_chart) {
      if (columnData1?.chart_type === "LineChartConfig" || columnData1?.chart_type === "BarChartConfig") {
        if (columnData1?.chart_variant === "overlay") {
          const valuesArray = [];
          const filterArray = ["format_id", "kpi_name", "kpi_order"];
          columnData1?.chartdata?.map((dataObj) => {
            Object.entries(dataObj)?.map((datapair) => {
              if (!filterArray.includes(datapair?.[0])) {
                valuesArray?.push(datapair?.[1]);
              }
            })
          });
          columnData2?.chartdata?.map((dataObj) => {
            Object.entries(dataObj)?.map((datapair) => {
              if (!filterArray.includes(datapair?.[0])) {
                valuesArray?.push(datapair?.[1]);
              }
            })
          })
          return [
            Math.min(...valuesArray) * 0.975,
            Math.max(...valuesArray) * 1.025
          ];
        } else {
          const minData = Math.min(
            ...columnData1?.chartdata?.map(dataObj => dataObj?.kpi_value),
            ...columnData2?.chartdata?.map(dataObj => dataObj?.kpi_value)
          );
          const maxData = Math.max(
            ...columnData1?.chartdata?.map(dataObj => dataObj?.kpi_value),
            ...columnData2?.chartdata?.map(dataObj => dataObj?.kpi_value)
          );
          return [
            minData * 0.975,
            maxData * 1.025
          ];
        }
      }
      if (columnData1?.chart_type === "CompositionChartConfig") {
        let keysArray = {
          id: [],
          withoutId: []
        };
        const filterArray = ["format_id", "kpi_name", "kpi_order"];
        const valuesArray = {
          id: [],
          withoutId: []
        };
        let locCompositionChartConfig = typeof (columnData1?.chartAdditionalConfig) === "string" ?
          JSON.parse(columnData1?.chartAdditionalConfig) :
          JSON.parse(JSON.stringify(columnData1?.chartAdditionalConfig));

        Object.entries(locCompositionChartConfig?.seriesGetConfig)?.map((confObj) => {
          if (confObj?.[1]?.["axisId"]) {
            keysArray?.id?.push(confObj?.[0]);
          } else {
            keysArray?.withoutId?.push(confObj?.[0]);
          }
        });

        columnData1?.chartdata?.map((dataObj) => {
          Object.entries(dataObj)?.map((datapair) => {
            if (!filterArray.includes(datapair?.[0])) {
              keysArray?.id?.includes(datapair?.[0]) ?
                valuesArray?.id?.push(datapair?.[1]) :
                valuesArray?.withoutId?.push(datapair?.[1]);
            }
          })
        });

        columnData2?.chartdata?.map((dataObj) => {
          Object.entries(dataObj)?.map((datapair) => {
            if (!filterArray.includes(datapair?.[0])) {
              keysArray?.id?.includes(datapair?.[0]) ?
                valuesArray?.id?.push(datapair?.[1]) :
                valuesArray?.withoutId?.push(datapair?.[1]);
            }
          })
        });

        return {
          id: [Math.min(...valuesArray?.id), Math.max(...valuesArray?.id)],
          withoutId: [Math.min(...valuesArray?.withoutId), Math.max(...valuesArray?.withoutId)]
        };
      }
    }
  };

  const getDynamicColumnData = (col1, col2, key) => {
    const columnData1 = key[col1] || {};
    const columnData2 = key[col2] || {};
    const maxMinDomainrangeArray = getMaxMinDomainRange(columnData1, columnData2);

    return {
      [col1]: getCellData(columnData1, maxMinDomainrangeArray),
      [col2]: getCellData(columnData2, maxMinDomainrangeArray),
    };
  };

  const handleControlChange1 = (selectedValue, previousValue) => {
    if (selectedValue !== previousValue && selectedValue !== selectedControl2) {
      setSelectedControl1(selectedValue);
    }
  };

  const handleControlChange2 = (selectedValue, previousValue) => {
    if (selectedValue !== previousValue && selectedValue !== selectedControl1) {
      setSelectedControl2(selectedValue);
    }
  };

  useEffect(() => {
    if (allControl?.length && variantComparisonData) {
      setColumns([
        {
          title: <h3>Parameters</h3>,
          dataKey: "parameters",
          fixed: "left",
          align: "center",
          width: "15%",
          render: (param) => <h3 style={{ fontFamily: "SF Text" }}>{param}</h3>,
        },
        {
          title: (
            <Select
              fullDdn
              value={selectedControl1}
              style={{ width: "80%" }}
              options={controlOptions}
              onChange={(val) => handleControlChange1(val, selectedControl1)}
            />
          ),
          dataKey: selectedControl1,
          key: selectedControl1,
          align: "center",
        },
        {
          title: (
            <Select
              fullDdn
              value={selectedControl2}
              style={{ width: "80%" }}
              options={controlOptions}
              onChange={(val) => handleControlChange2(val, selectedControl2)}
            />
          ),
          dataKey: selectedControl2,
          key: selectedControl2,
          align: "center",
        },
      ]);

      const formattedData =
        comparisonTableData &&
        comparisonTableData.map((paramObj) => ({
          parameters: Object.keys(paramObj)[0],
          ...getDynamicColumnData(
            selectedControl1,
            selectedControl2,
            Object.values(paramObj)[0]
          ),
        }));

      setTableData(formattedData);
    }
  }, [allControl, selectedControl1, selectedControl2]);

  return (
    <>
      {tableData?.length > 0 && columns?.length > 0 ? (
        <Table
          className="custom-border-comparison-table"
          id="comparision"
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          sticky={true}
        // scroll={{ y: "calc(61vh)" }}
        />
      ) : (
        <>
          <LegacyNoData height={"71vh"} />
          <GenericModalWithResultComponent
            isRequestModalOpen={isRequestModalOpen}
            setIsRequestModalOpen={setIsRequestModalOpen}
          />
        </>
      )}
    </>
  );
};

export default MeasureResultsVariantComparisonTab;
