import React from "react";
import {
  ZsShoppingCartOutlined,
  ZsTicket,
  ZsApartmentOutlined,
  ZsCalendarOutlined,
  ZsCoffeeOutlined,
  ZsContactsOutlined,
  ZsContainerOutlined,
  ZsMacCommandOutlined,
  ZsRestOutlined,
  ZsRocketOutlined,
  ZsShopOutlined,
  ZsStarOutlined,
  ZsTagOutlined,
  ZsWifiOutlined,
  ZsFlight,
  ZsLuggage,
  ZsAirlineSeatReclineExtra,
  ZsEntypoNew,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

const ZsAntdIcons = {
  ZsShoppingCartOutlined,
  ZsApartmentOutlined,
  ZsCalendarOutlined,
  ZsCoffeeOutlined,
  ZsContactsOutlined,
  ZsContainerOutlined,
  ZsMacCommandOutlined,
  ZsRestOutlined,
  ZsRocketOutlined,
  ZsShopOutlined,
  ZsStarOutlined,
  ZsTagOutlined,
  ZsWifiOutlined,
};

const ZSstyledIcons = {
  ZsTicket,
  ZsFlight,
  ZsLuggage,
  ZsAirlineSeatReclineExtra,
  ZsEntypoNew,
};

export const FindAvatarIcon = ({
  avatar_icon,
  avatar_Size = 35,
  avatar_marginTop = 10,
  color = "#15afd0CC",
  ...props
}) => {
  // console.log('props from FindAvatarIcon ZsAntdIcons', ZsAntdIcons);
  console.log("props from FindAvatarIcon", props, {
    avatar_icon,
    avatar_Size,
    avatar_marginTop,
    color,
  });
  // console.log('props from FindAvatarIcon ZsAntdIcons keys', Object.keys(ZSstyledIcons));

  // switch (avatar_icon) {
  switch (true) {
    case Object.keys(ZSstyledIcons).filter((icon) => icon === avatar_icon)
      .length > 0: {
        let Icon = ZSstyledIcons[avatar_icon]; // .filter(icon => icon.name === avatar_icon)[0];
        // console.log('FindAvatarIcon ZSstyledIcons Icon', Icon);
        return (
          <Icon
            width={avatar_Size}
            height={avatar_Size}
            style={{
              marginTop: `${avatar_marginTop}px`,
              color: `${color}` /* "#15afd0" + "CC" */,
            }}
          />
        );
      }
    case Object.keys(ZsAntdIcons).filter((icon) => icon === avatar_icon)
      .length > 0: {
        let Icon = ZsAntdIcons[avatar_icon]; // .filter(icon => icon.name === avatar_icon)[0];
        // console.log(' FindAvatarIcon ZsAntdIcons Icon', Icon);
        return (
          <Icon
            style={{
              fontSize: `${avatar_Size}px`,
              marginTop: `${avatar_marginTop}px`,
              color: `${color}` /* "#15afd0" + "CC"  */,
            }}
          />
        );
      }
    default:
      return <></>;
  }
};

export default FindAvatarIcon;
