import { PicLeftOutlined } from "@ant-design/icons";
import { Card, Col, /* Collapse,  */Row } from "antd";
import React from "react";
import ChartWrapper from "../../../assets/generic-components/visualization/components/wrapper";
import CreativeEditorSDK from "@cesdk/cesdk-js";
import { SceneTemplateString } from "./TEMPLATE";
import { AspectRatio } from "../../design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import Axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const uniqueImages = (images) => {
  return Array.from(new Set(images.map((i) => i.image_url))).map((url) =>
    images.find((img) => img.image_url === url)
  );
};

const uniqueTexts = (texts) => {
  return Array.from(new Set(texts.map((t) => t.text))).map((text) =>
    texts.find((t) => t.text === text)
  );
};

const SendDataToCanva = (props) => {
  React.useEffect(() => {
    if (props.templates) {
      const sections = [
        {
          title: "Images",
          layout: "grid",
          data: uniqueImages(props.templates.images),
        },
        {
          title: "Marketing Copy",
          layout: "row",
          data: uniqueTexts(props.templates.content),
        },
      ];
      Axios.post("https://paiweb.vercel.app/api/gen-ai/canva-load", {
        inputs: {
          sections,
        },
        session_id: "a6c2d4d0-c7be-43e3-a79e-235c257624ff",
      });
    }
  }, [props.templates]);
  return <></>;
};

export const TemplateComponentSummary = (props) => {
  const [showEditor, setShowEditor] = React.useState(false);

  const groupedTemplates = props.allData.variants.variants
    .map((v, i) => {
      if (props.allData.images.variants[i]) {
        var images = props.allData.images.variants[i].images.filter(
          (img) => img.selected
        );
        if (props.allData.content.variants[i].taglines) {
          var taglines = props.allData.content.variants[i].taglines.filter(
            (t) => t.selected
          );
          return images
            .map((img) => {
              return taglines.map((t) => ({
                image: img,
                tagline: t,
                template: "bottom-center",
              }));
            })
            .flat();
        }
        return null;
      }
      return null;
    })
    .filter((gt) => gt);

  const templates = {
    images: props.allData.images.variants
      .map((v) => v.images)
      .flat()
      .filter((t) => t.selected),
    content: props.allData.content.variants
      .map((v) => v.taglines)
      .flat()
      .filter((t) => t.selected),
  };

  if (!showEditor) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SendDataToCanva templates={templates} />
        <Row gutter={[20, 20]}>
          <Col span={6} offset={3}>
            <Card
              style={{
                backgroundColor: "rgba(255,255,255,0.04)",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowEditor(true);
              }}
            >
              <div>
                <AspectRatio width={8} height={9}>
                  <div
                    style={{
                      position: "absolute",
                      inset: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "50%",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <PicLeftOutlined style={{ fontSize: "64px" }} />
                    </div>
                    <h3 style={{ textAlign: "center", marginBottom: 0 }}>
                      Pre-built Templates
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        opacity: 0.5,
                        marginBottom: 0,
                      }}
                    >
                      Choose from a list of templates
                    </p>
                  </div>
                </AspectRatio>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <a
              href="https://www.canva.com/design/DAFrcJ1Kn9g/S6YSrARsxzExXalOPdu9KA/edit?ui=eyJFIjp7IkE_IjoiTiIsIkEiOiJBQUZyYjdIXzRjc18xWjVpQ0kifX0"
              target="_blank" rel="noreferrer"
            >
              <Card
                style={{
                  backgroundColor: "rgba(255,255,255,0.04)",
                }}
              >
                <div>
                  <AspectRatio width={8} height={9}>
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          height: "50%",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/canva-icon.png"
                          width={70}
                          height={70}
                        />
                      </div>
                      <h3 style={{ textAlign: "center", marginBottom: 0 }}>
                        Design with Canva
                      </h3>
                      <p
                        style={{
                          textAlign: "center",
                          opacity: 0.5,
                          marginBottom: 0,
                        }}
                      >
                        Use KIRA generated content in Canva
                      </p>
                    </div>
                  </AspectRatio>
                </div>
              </Card>
            </a>
          </Col>
          <Col span={6}>
            <Card
              style={{
                backgroundColor: "rgba(255,255,255,0.04)",
                cursor: "pointer",
              }}
              onClick={async () => {
                let zip = new JSZip();

                let singleVariant =
                  props.allData.variants.variants.length === 1;

                let allVariants = props.allData.variants.variants.filter(
                  (v, i) => (singleVariant ? true : i > 0)
                );

                let images = props.allData.images.variants.filter((v, i) =>
                  singleVariant ? true : i > 0
                );

                let texts = props.allData.content.variants.filter((v, i) =>
                  singleVariant ? true : i > 0
                );

                for (let i = 0; i < allVariants.length; i++) {
                  let variant = allVariants[i];
                  let folder = zip.folder(variant.name);

                  let variantImages = await Promise.all(
                    images[i].images.map(async (img) => {
                      let response = await fetch(img.image_url);
                      let data = await response.blob();
                      return {
                        name: img.image_url.split("/").pop(),
                        data,
                      };
                    })
                  );

                  for (let j = 0; j < variantImages.length; j++) {
                    folder.file(variantImages[j].name, variantImages[j].data);
                  }

                  folder.file(
                    "content.txt",
                    `${texts[i].taglines
                      .filter((t) => t.selected)
                      .map((t) => t.text)
                      .join("\n\n")}`
                  );
                }

                zip.generateAsync({ type: "blob" }).then(function (content) {
                  saveAs(content, "kira.zip");
                });
              }}
            >
              <div>
                <AspectRatio width={8} height={9}>
                  <div
                    style={{
                      position: "absolute",
                      inset: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "50%",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://theideaboutique.com/wp-content/uploads/2016/09/TIB-Blog-April03-AdobeSuites-01.png"
                        width={122}
                        height={100}
                      />
                    </div>
                    <h3 style={{ textAlign: "center", marginBottom: 0 }}>
                      Export Elements
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        opacity: 0.5,
                        marginBottom: 0,
                      }}
                    >
                      For use in other apps
                    </p>
                  </div>
                </AspectRatio>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "0px 0px",
        height: "100%",
        width: "100%",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <ChartWrapper>
        {({ width, height }) => (
          <div style={{ width, height }}>
            <Editor
              key={props.data.selected}
              current={props.data.selected}
              currentVariant={
                props.allData.images.variants[props.data.selected]
              }
              currentVariantTexts={
                props.allData.content.variants[props.data.selected]
              }
              pages={groupedTemplates[props.data.selected] || []}
              data={props.data}
              updateEntry={props.updateEntry}
              brandImages={[]}
            />
          </div>
        )}
      </ChartWrapper>
    </div>
  );
};

// const Viewer = (props) => {
//   React.useEffect(() => {
//     (async () => {
//       for (let i = 0; i < props.pages.length; i++) {
//         let engine = await CreativeEngine.init({
//           baseURL:
//             "https://cdn.img.ly/packages/imgly/cesdk-engine/1.13.0/assets",
//         });

//         document.getElementById(`page_${i}`).append(engine.element);

//         await engine.addDefaultAssetSources();
//         let template = generateTemplateWithPages([props.pages[i]]);

//         await engine.scene.loadFromString(
//           btoa(`P5${JSON.stringify(template)}`)
//         );
//       }
//     })();
//   }, []);
//   return (
//     <Row>
//       {props.pages.map((page, index) => (
//         <Col span={4}>
//           <AspectRatio width={16} height={10}>
//             <div
//               id={`page_${index}`}
//               style={{ inset: 0, position: "absolute" }}
//             ></div>
//           </AspectRatio>
//         </Col>
//       ))}
//     </Row>
//   );
// };

function blobToBase64(blob) {
  return new Promise((resolve/* , _ */) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const Editor = (props) => {
  const cesdk_container = React.useRef(null);
  let editor = null;
  React.useEffect(() => {
    let unsubscribe = null;
    if (cesdk_container.current) {
      CreativeEditorSDK.create(cesdk_container.current, {
        baseURL: "https://cdn.img.ly/packages/imgly/cesdk-js/1.14.0/assets",
        theme: "dark",
        role: "Creator",
        i18n: {
          en: {
            "libraries.empty-custom-image-source.label": "Images",
            "libraries.empty-custom-text-source.label": "Text",
          },
        },
        callbacks: {
          onExport: (blobs/* , options */) => {
            blobs.forEach((b) => {
              blobToBase64(b).then((b64) => {
                console.log(b64);
              });
            });
            return Promise.resolve();
          },
          onDownload: (s) => {
            console.log(atob(s).replace("P5", ""));
            return Promise.resolve();
          },
        },
        ui: {
          elements: {
            view: "default",
            panels: {
              inspector: {
                position: "left",
              },
              assetLibrary: {
                position: "right",
              },
            },
            navigation: {
              show: true, // 'false' to hide the navigation completely
              position: "bottom", // 'top' or 'bottom'
              action: {
                back: false, // true or false
                load: false, // true or false
                save: false, // true or false
                export: {
                  show: false,
                  format: ["application/pdf"],
                },
                download: false, // true  or false
              },
            },
            libraries: {
              replace: {
                entries: (defaultEntries, context) => {
                  if (context.selectedBlocks.length !== 1) {
                    return [];
                  }

                  const [selectedBlock] = context.selectedBlocks;

                  console.log(selectedBlock);

                  let list = [...defaultEntries];

                  if (selectedBlock.blockType === "ly.img.image") {
                    list.push({
                      id: "empty-custom-image-source-for-replace",
                      sourceIds: ["emptyImageSource"],
                      previewLength: 3,
                      gridColumns: 3,
                      gridItemHeight: "square",
                    });
                    return list;
                  }

                  if (selectedBlock.blockType === "ly.img.text") {
                    list.push({
                      id: "empty-custom-text-source-for-replace",
                      sourceIds: ["emptyTextSource"],
                      previewLength: 3,
                      gridColumns: 1,
                      gridItemHeight: "auto",
                    });
                    return list;
                  }

                  return [];
                },
              },
              insert: {
                entries: (defaultEntries) => {
                  console.log("defaultEntries", defaultEntries);

                  const imageEntry = defaultEntries.find((entry) => {
                    return entry.id === "ly.img.image";
                  });

                  const textEntry = defaultEntries.find((entry) => {
                    return entry.id === "ly.img.text";
                  });

                  if (imageEntry) {
                    imageEntry.gridColumns = 4;
                  }

                  if (textEntry) {
                    textEntry.gridColumns = 1;
                  }

                  return [
                    {
                      id: "empty-custom-image-source",
                      sourceIds: ["emptyImageSource"],
                      previewLength: 3,
                      gridColumns: 3,
                      gridItemHeight: "square",
                      previewBackgroundType: "cover",
                      gridBackgroundType: "cover",
                      icon: ({ theme, iconSize }) => {
                        if (theme === "dark") {
                          if (iconSize === "normal") {
                            return "https://img.ly/static/cesdk/guides/icon-normal-dark.svg";
                          } else {
                            return "https://img.ly/static/cesdk/guides/icon-large-dark.svg";
                          }
                        }

                        if (iconSize === "normal") {
                          return "https://img.ly/static/cesdk/guides/icon-normal-light.svg";
                        } else {
                          return "https://img.ly/static/cesdk/guides/icon-large-light.svg";
                        }
                      },
                    },
                    {
                      id: "empty-custom-text-source",
                      sourceIds: ["emptyTextSource"],
                      gridColumns: 1,
                      icon: ({ theme, iconSize }) => {
                        if (theme === "dark") {
                          if (iconSize === "normal") {
                            return "https://img.ly/static/cesdk/guides/icon-normal-dark.svg";
                          } else {
                            return "https://img.ly/static/cesdk/guides/icon-large-dark.svg";
                          }
                        }

                        if (iconSize === "normal") {
                          return "https://img.ly/static/cesdk/guides/icon-normal-light.svg";
                        } else {
                          return "https://img.ly/static/cesdk/guides/icon-large-light.svg";
                        }
                      },
                    },
                    ...defaultEntries.filter(
                      (e) =>
                        [
                          "ly.img.upload",
                          "ly.img.image",
                          "ly.img.text",
                        ].indexOf(e.id) === -1
                    ),
                  ];
                },
              },
            },
          },
        },
      }).then(async (instance) => {
        instance.addDefaultAssetSources();

        instance.addDemoAssetSources({
          sceneMode: "Design",
        });

        editor = instance;

        instance.engine.asset.addSource({
          id: "emptyImageSource",
          findAssets: () => {
            return Promise.resolve({
              assets: [
                ...props.currentVariant.images.map((image) => ({
                  id: image.id,
                  meta: {
                    blockType: "//ly.img.ubq/image",
                    uri: image.image_url,
                    thumbUri: image.image_url,
                    width:
                      image.aspect_ratio.split(":").map((a) => parseInt(a))[0] *
                      10,
                    height:
                      image.aspect_ratio.split(":").map((a) => parseInt(a))[1] *
                      10,
                  },
                  context: {
                    sourceId: "unsplash",
                  },
                })),
              ],
              total: props.currentVariant.images.length,
              currentPage: 1,
              nextPage: undefined,
            });
          },
        });

        instance.engine.asset.addSource({
          id: "emptyTextSource",
          findAssets: () => {
            return Promise.resolve({
              assets: [
                ...props.currentVariantTexts.taglines.map((tagline) => ({
                  id: tagline.text,
                  meta: {
                    blockType: "//ly.img.ubq/text",
                    fillType: "Solid",
                    cardLabel: tagline.text,
                    label: tagline.text,
                    text: tagline.text,
                    hasBackground: false,
                  },
                  label: tagline.text,
                  text: tagline.text,
                  value: tagline.text,
                  context: {
                    sourceId: "unsplash",
                  },
                  icon: tagline.text,
                })),
              ],
              total: props.currentVariantTexts.taglines.length,
              currentPage: 1,
              nextPage: undefined,
            });
          },
        });

        let engine = instance.engine;

        let template = generateTemplateWithPages([...props.pages]);

        await engine.scene.loadFromString(
          btoa(`P5${JSON.stringify(template)}`)
        );

        await (async () => {
          const allPages = instance.engine.scene.getPages();
          const urls = [];
          for (let i = 0; i < allPages.length; i++) {
            const mimeType = "image/png";
            const options = { pngCompressionLevel: 9 };
            const blob = await instance.engine.block.export(
              allPages[i],
              mimeType,
              options
            );
            //url =
            //const url = URL.createObjectURL(blob);
            let url = await blobToBase64(blob);
            urls.push(url);
          }

          let outputs = props.data.outputs;
          outputs[props.current] = urls;
          props.updateEntry("outputs", outputs);
        })();
      });
    }
    return () => {
      if (editor) {
        (async () => {
          const allPages = editor.engine.scene.getPages();
          const urls = [];
          for (let i = 0; i < allPages.length; i++) {
            const mimeType = "image/png";
            const options = { pngCompressionLevel: 9 };
            const blob = await editor.engine.block.export(
              allPages[i],
              mimeType,
              options
            );
            //url =
            //const url = URL.createObjectURL(blob);
            let url = await blobToBase64(blob);
            urls.push(url);
          }

          let outputs = props.data.outputs;
          outputs[props.current] = urls;
          props.updateEntry("outputs", outputs);
        })();
      }
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [cesdk_container]);

  console.log(props.data);

  return (
    <div
      ref={cesdk_container}
      style={{ width: "100%", height: "100%", zoom: 1.1111111111 }}
    ></div>
  );
};

const extractElementAtIndex = (array, predicate) => {
  let index = array.findIndex(predicate);
  return array.splice(index, 1)[0];
};

const extractAndFilter = (array, predicate, remove) => {
  let elements = array.filter(predicate);
  array = array.filter(remove);
  return {
    elements,
    array,
  };
};

const generateTemplateWithPages = (pages) => {
  let newSceneTemplateString = JSON.parse(JSON.stringify(SceneTemplateString));
  let designElements = JSON.parse(
    JSON.stringify(SceneTemplateString.designElements)
  );

  newSceneTemplateString.designElements = [];
  newSceneTemplateString.hierarchy.children = [];

  let scene = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/scene"
  );
  let camera = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/camera"
  );
  let stack = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/stack"
  );

  let pageElement = extractElementAtIndex(
    designElements,
    (d) => d.id === "//ly.img.ubq/page"
  );

  let { elements: colorElements, array: updatedDesignElements } =
    extractAndFilter(
      designElements,
      (d) => d.id === "//ly.img.ubq/fill/color",
      (d) => d.id !== "//ly.img.ubq/fill/color"
    );

  designElements = updatedDesignElements;

  newSceneTemplateString.designElements.push(scene);
  newSceneTemplateString.designElements.push(camera);
  newSceneTemplateString.designElements.push(stack);

  newSceneTemplateString.hierarchy.children.push({
    key: 1,
    value: [3, 2],
  });

  newSceneTemplateString.hierarchy.children.push({
    key: 2,
    value: [],
  });

  let pageIds = [];

  pages.forEach((page, index) => {
    let newPage = { ...pageElement };
    newPage.entity = index === 0 ? 4 : generateEntityId();
    newPage.uuid = uuidv4();
    newSceneTemplateString.designElements.push(newPage);
    pageIds.push(newPage.entity);
    let pageHierarchy = [];
    designElements.forEach((element) => {
      let elementJsonString = JSON.stringify(element);
      elementJsonString = elementJsonString.replace(
        "{{ tagline }}",
        page.tagline.text
      );
      elementJsonString = elementJsonString.replace(
        "{{ image_url }}",
        page.image.image_url
      );
      let updatedElement = JSON.parse(elementJsonString);
      let newElement = { ...updatedElement };
      newElement.entity = index === 0 ? newElement.entity : generateEntityId();
      newElement.uuid = index === 0 ? newElement.uuid : uuidv4();
      newSceneTemplateString.designElements.push(newElement);
      pageHierarchy.push(newElement.entity);
      newSceneTemplateString.hierarchy.children.push({
        key: element.entity,
        value: [],
      });

      if (index > 0) {
        let [ce, replaced] = replaceAndAppendId(
          colorElements,
          element.entity,
          newElement.entity
        );

        colorElements = ce;

        if (replaced) {
          let newElementString = JSON.stringify(newElement);
          replaced.forEach((r) => {
            newElementString = newElementString.replace(
              r,
              colorElements[colorElements.length - 1].entity
            );
          });
        }
      }
    });

    if (index > 0) {
      let [ce, replaced] = replaceAndAppendId(
        colorElements,
        page.entity,
        newPage.entity
      );

      colorElements = ce;

      if (replaced) {
        let newPageString = JSON.stringify(newPage);
        replaced.forEach((r) => {
          newPageString = newPageString.replace(
            r,
            colorElements[colorElements.length - 1].entity
          );
        });
      }
    }

    newSceneTemplateString.hierarchy.children.push({
      key: newPage.entity,
      value: pageHierarchy,
    });
  });

  newSceneTemplateString.designElements = [
    ...newSceneTemplateString.designElements,
    ...colorElements,
  ];

  colorElements.forEach((color) => {
    newSceneTemplateString.hierarchy.children.push({
      key: color.entity,
      value: [],
    });
  });

  newSceneTemplateString.hierarchy.children.push({
    key: 3,
    value: pageIds,
  });

  return newSceneTemplateString;
};

const generateEntityId = () => {
  return Math.floor(Math.random() * (99999 - 10000) + 10000);
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const replaceAndAppendId = (colorElements, matchingId, appendId) => {
  let matchingColorElements = colorElements.filter(
    (c) => c.block_render_connections.indexOf(matchingId) !== -1
  );
  return [
    [
      ...colorElements,
      ...matchingColorElements.map((ce) => ({
        ...ce,
        entity: generateEntityId(),
        uuid: uuidv4(),
        block_render_connections: [appendId],
      })),
    ],
    matchingColorElements.length
      ? matchingColorElements.map((m) => m.entity)
      : undefined,
  ];
};
