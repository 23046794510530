import styled from "styled-components";

const Separator = styled.div`
  width: 100%;
  font-family: SF Display;
  margin: ${(props) =>
    props.margin
      ? props.margin
      : props.theme.gutter.inner * 2 +
        "px 0 " +
        props.theme.gutter.inner * 1.75 +
        "px"};

  height: 1px;
  background-color: ${(props) => props.theme.colors.default}0d;
  position: relative;

  &:after {
    content: "${(props) => props.label}";
    position: absolute;
    top: 50%;
    left: ${(props) => props.theme.gutter.inner / 2}px;
    padding: ${(props) => props.theme.gutter.inner / 2}px;
    background-color: ${(props) => props.theme.colors.background};
    transform: translate(0px, -50%);
    font-size: ${(props) => props.theme.fontSizes.large}px;
    line-height: ${(props) => props.theme.fontSizes.large}px;
    font-weight: 200;
    color: hsla(0,0%,100%,0.75);
  }
`;

export default Separator;
