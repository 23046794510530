import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Select } from "antd";
import { FilterFilled } from '@ant-design/icons';
import {
    ZsDropdown,
    ZsMenu,
    ZsSignalFilled
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import * as Comps from "./components";
import DesignAISequencingServiceHelper from "@xai/pages/design-ai-sequencing/service-helper";
import { UserContext } from "@xai/providers/with-auth";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";
import { WaveLoading } from "styled-spinkit";

const DesignAISequencing = () => {

    const { token } = React.useContext(UserContext);
    const service = new DesignAISequencingServiceHelper(token);

    const [tests, settests] = useState();
    const [openForm, setopenForm] = useState(false);
    const [sortOptions] = useState({
        1: {
            label: "Favorite",
            name: "favorite",
        },
    });
    const [sortOption, setSortOption] = useState({
        label: "Favorite",
        name: "favorite",
    });
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

    const load = async () => {
        await service.getDesignAISequencingData()
            .then(async (res) => {
                console.log('getDesignAISequencingData Data', res);
                settests(res);
                // setLineChartData(null);
                // setload(false);
            })
            .catch((err) => {
                console.log('getDesignAISequencingData Error', err);
            });
    };

    useEffect(() => {
        load()
    }, []);

    return (
        <div className={'opacity-0-55'}>
            {tests && tests?.["tests"] ?
            openForm ?
                <>
                    <h3 style={{ marginLeft: "10px" }}>
                        <span
                            className="select-link"
                            onClick={() => {
                                setopenForm(false)
                            }}
                        >
                            AI Sequencing
                        </span>{" "}
                        &nbsp; /&nbsp; Auto generate sequence
                    </h3>
                    <Comps.TestExperiment /* templateSelected={templateSelected} experimentToLoad={experimentToLoad} */ />
                </> :
                <React.Fragment>
                    <GenericModalWithResultComponent
                        isRequestModalOpen={isRequestModalOpen}
                        setIsRequestModalOpen={setIsRequestModalOpen}
                    />
                    <div style={{ padding: "5px 20px" }}>
                        <Row gutter={[40, 40]} span={24} style={{ marginBottom: "20px" }}>
                            <Col style={{ margin: "auto 0px" }}>
                                <FilterFilled />
                                <span> Filter:</span>
                            </Col>
                            <Col span={4}>
                                <Input.Search placeholder="Search Text" />
                            </Col>
                            <Col span={4}>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    placeholder="Status"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option value="">All</Select.Option>
                                </Select>
                            </Col>
                            <Col span={4}>
                                <ZsDropdown
                                    overlay={
                                        <ZsMenu
                                            onClick={(x) => setSortOption(sortOptions[x?.key])}
                                            values={sortOptions}
                                        />
                                    }
                                    trigger={["click"]}
                                >
                                    <div style={{ marginTop: "2%" }}>
                                        <a
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            {sortOption?.name?.startsWith("desc") ? (
                                                <ZsSignalFilled
                                                    rotate={-90}
                                                    style={{ transform: "scaleX(-1)" }}
                                                />
                                            ) : (
                                                <ZsSignalFilled
                                                    rotate={90}
                                                    style={{ transform: "scaleX(-1)" }}
                                                />
                                            )}{" "}
                                            Sort By:
                                        </a>
                                        <span>&nbsp;{sortOption?.label}</span>
                                    </div>
                                </ZsDropdown>
                            </Col>
                            <Col align="end" span={10}>
                                <Button
                                    style={{ background: "#15afd0", color: "#ffffff" }}
                                    onClick={() => { setopenForm(true); }}
                                >Auto Generate Sequence</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {tests["tests"]
                                    .map((t) => ({
                                        ...t,
                                        name: t.name,
                                    }))
                                    .map((test, index) => (
                                        <Comps.TestSummary key={index} {...test} />
                                    ))}
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            : <WaveLoading color="#15afd0" />}
        </div>
    );
};

export default DesignAISequencing;