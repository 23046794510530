import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { CaretDown } from "@styled-icons/boxicons-regular/CaretDown";
import { UserContext } from "@xai/providers/with-auth";
import WaveLoader from "@xai/assets/generic-components/wave-loader";
import { ActiveMenuText } from "@xai/library/layouts/auth-layout/styles";
import {
  ObjectiveDropDownWrapper,
  ObjectiveSelected,
  ObjectiveIcon,
  ObjectiveItemWrapper,
  ObjectiveItem,
  SubMenuItem,
} from "./styles";

const ObjectiveMenu = () => {
  const { dispatch, objectiveModule } = React.useContext(UserContext);
  const ObjectiveMenuRef = useRef();
  let location = useLocation();

  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    objectiveModule.selectedObjective
  );
  // console.log("objmenu", selectedValue);
  const itemList = objectiveModule.objectiveMenuList;

  useEffect(() => {
    console.log(
      "objmenu",
      selectedValue?.id,
      objectiveModule.selectedObjective?.id
    );
    if (selectedValue?.id !== objectiveModule.selectedObjective?.id) {
      console.log("objdispatched");
      dispatch({
        type: "objectiveModule",
        payload: {
          selectedObjective: selectedValue,
          loading: true,
        },
      });
    }
  }, [selectedValue]);

  useEffect(() => {
    if ([null, undefined].indexOf(selectedValue) > -1) {
      setSelectedValue(objectiveModule.selectedObjective);
    }
  }, [objectiveModule.selectedObjective]);

  // Ref : https://stackoverflow.com/questions/70708271/how-to-close-dropdown-menu-using-own-useonoutsideclick-hook/70708380#70708380
  // useOnOutsideClick(ObjectiveMenuRef, () => {
  //   if (showDropDown) setShowDropDown(false);
  // });

  const ObjectiveLink = () => {
    return (
      <SubMenuItem key={1} style={{ color: "#15afd0" }}>
        <ActiveMenuText>Objectives</ActiveMenuText>
      </SubMenuItem>
    );
  };

  const ObjectiveDdn = () => {
    return (
      <SubMenuItem>
        <ObjectiveDropDownWrapper
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
          ref={ObjectiveMenuRef}
        >
          <ObjectiveSelected>
            {
              selectedValue ? selectedValue.name : <WaveLoader /> //"Select Objective"
            }
          </ObjectiveSelected>
          <ObjectiveIcon>
            <CaretDown color="#FFFFFF80" />
          </ObjectiveIcon>
          {showDropDown && (
            <ObjectiveItemWrapper>
              {itemList &&
                itemList.map((item, i) => (
                  <ObjectiveItem
                    key={i}
                    onClick={() => {
                      setSelectedValue(item);
                      console.log("selected obj", item);
                      setShowDropDown(false);
                    }}
                  >
                    {item.name}
                  </ObjectiveItem>
                ))}
              <Link to={"../objective"}>
                <ObjectiveItem>View All</ObjectiveItem>
              </Link>
            </ObjectiveItemWrapper>
          )}
        </ObjectiveDropDownWrapper>
      </SubMenuItem>
    );
  };

  return location.pathname == "/businessinputs/objective" ? (
    <ObjectiveLink />
  ) : (
    <ObjectiveDdn />
  );
};

export default ObjectiveMenu;
