import React, { useState, useEffect } from "react";
import { Tabs } from "@pai-ui/core/components/tabs";
import { Select } from "@pai-ui/core/components/select";
import { Space } from "@pai-ui/core/components/space";
import download from "downloadjs";
import { Button } from "@pai-ui/core/components/button";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";
import { Grid, GridItem } from "@xai/library/containers/grid";
// import DateSelectionRangePicker from "@xai/assets/product-specific-common-components/date-selection-range-picker";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import MeasureResultsServiceHelper from "@xai/pages/measure-results/service-helper";
import { FilterComponent } from "@xai/assets/generic-components/utils";
// import { Cascader } from '@pai-ui/core/components/cascader';
import {
  getResultSummaryConfig,
  getVariantComparisonConfig,
  // getDetailedViewConfidenceIntervalConfig,
  // getDetailedViewResponseMetricConfig,
  getDetailedViewParameterVariantComparisionTableConfig,
  getSegmentImpactExplorerTabParamsConfig,
  getSegmentImpactExplorerTabDataConfig,
  getResultSummaryTwoByTwoChartConfig,
  getDetailedViewCorrelationChartConfig
} from "./transformer";
import * as Comps from "./components";

const tabMenu = [
  {
    tab: "Summary",
    id: "summary",
  },
  {
    tab: "Detailed View",
    id: "detailedView",
  },
  {
    tab: "Variant Comparison",
    id: "variantComparison",
  },
  {
    tab: "Segment Impact Explorer",
    id: "segmentImpactExplorer",
  },
];

const initialSegmentFilters = {
  selectedSegment: null,
  selectedParameter: null,
  selectedTestGroup: null,
  selectedControlGroup: null,
};

const MeasureResultsTab = ({ currentScreen, selectedCohort, /* setSelectedCohort */ }) => {
  const { token, experimentModule } = React.useContext(UserContext);
  const service = new MeasureResultsServiceHelper(token);

  // console.log("MeasureResultsTab", userState.experimentModule);
  // const { experimentModule/* , genericOptions  */} = React.useContext(UserContext);
  const experimentTaskManagementData =
    experimentModule?.selectedExperiment?.experiment_task_management;
  const [selectedDateRangeVal, setSelectedDateRangeVal] = useState(null);
  // const [dataLoading, setDataLoading] = useState(true);
  // const [dataAvailable, setDataAvailable] = useState(false);

  const [summaryData, setSummaryData] = useState({
    card: {
      data: null,
      loading: true,
      error: false
    },
    twoByTwoChart: {
      data: null,
      loading: true,
      error: false
    }
  });
  const [variantComparisonData, setVariantComparisonData] = useState({
    data: null,
    loading: true,
    error: false
  });
  const [detailedViewData, setDetailedViewData] = useState({
    table: {
      data: null,
      loading: true,
      error: false
    },
    correlationChart: {
      data: null,
      loading: true,
      error: false
    }
  });
  // const [
  //   detailedViewConfidenceIntervalData,
  //   setDetailedViewConfidenceIntervalData,
  // ] = useState(null);
  // const [detailedViewResponseMetricData, setDetailedViewResponseMetricData] =
  //   useState(null);
  // const [detailedViewParameter, setdetailedViewParamter] = useState();
  const [segmentImpactExplorerTabParams, setSegmentImpactExplorerTabParams] = useState({
    data: null,
    loading: true,
    error: false
  });

  const [activeTab, setActiveTab] = useState(tabMenu[0].id);
  const onTabChange = (key) => {
    // console.log("onTabChange : Results, Selected tab : ", key);
    setActiveTab(key);
  };

  // States for segmentImpactExplorerTab
  const [segmentimpactTabFilters, setSegmentimpactTabFilters] = useState(
    initialSegmentFilters
  );
  const {
    selectedSegment,
    selectedParameter,
    selectedTestGroup,
    selectedControlGroup,
  } = segmentimpactTabFilters;

  const [segmentimpactTabData, setSegmentimpactTabData] = useState({
    data: null,
    loading: true,
    error: false
  });

  //States for Error Handling

  // const [summaryTabError, setSummaryTabError] = useState(false);
  // const [variantComparisonTabError, setVariantComparisonTabError] =
  //   useState(false);
  // const [detailedViewTabError, setDetailedViewTabError] = useState(false);
  // const [
  //   detailedViewTabConfidenceIntervalError,
  //   setDetailedViewTabConfidenceIntervalError,
  // ] = useState(false);
  // const [
  //   detailedViewTabResponseMetricError,
  //   setDetailedViewTabResponseMetricError,
  // ] = useState(false);
  // const [segmentImpactTabError, setSegmentImpactTabError] = useState(false);
  // const [segmentImpactTabTableError, setSegmentImpactTabTableError] =
  //   useState(false);

  //State for Segment Impact Loading

  // const [segmentImpactDataLoading, setSegmentImpactDataLoading] =
  //   useState(false);

  //States for Variant Filter

  const [selectedVariants, setSelectedVariants] = useState();
  const [variantsOptions, setVariantsOptions] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState();
  const [AudienceOptions, setAudienceOptions] = useState([]);

  // const [selectedCascaderVariants, setSelectedCascaderVariants] = useState();

  // const cascaderChangeHandler = (values) => {
  //   setSelectedCascaderVariants(values);
  //   let localSelectedVariants = [];
  //   values?.map?.((variantsArray) => {
  //     if (variantsArray?.[1]) {
  //       localSelectedVariants?.push(variantsArray?.[1]);
  //     } else if (variantsArray?.[0]) {
  //       let filteredVariantChildren = variantsOptions?.find?.((variantObj) => variantObj?.value === variantsArray?.[0])?.children;
  //       filteredVariantChildren?.map((childrenVariantObj) => {
  //         localSelectedVariants?.push(childrenVariantObj?.value);
  //       });
  //     }
  //   })
  //   setSelectedVariants(localSelectedVariants);
  // };

  useEffect(() => {
    if (selectedAudience) {
      let tempVariantOptions;
      let tempVariantList = experimentModule?.selectedExperiment?.experiment_data?.variant_setup;
      let filteredAudienceGroups = experimentModule?.selectedExperiment?.experiment_overall_kpi_lists?.find((kpiListObj) => {
        const filteredTaskManagementObj =
          experimentModule?.selectedExperiment?.experiment_task_management
            ?.find((taskManObj) => taskManObj?.id === selectedCohort?.[selectedCohort?.length - 1]);
        if (filteredTaskManagementObj?.start_time_kpi === kpiListObj?.start_time_kpi &&
          filteredTaskManagementObj?.end_time_kpi === kpiListObj?.end_time_kpi) {
          return kpiListObj;
        }
      });
      let filteredVariantGroup = filteredAudienceGroups?.experiment_audience_group_variant_data?.
        groups?.find((grpObj) => grpObj?.group_name === selectedAudience);
      if (filteredVariantGroup) {
        tempVariantOptions = filteredVariantGroup?.variant_list?.
          map?.((filteredVarObj) => {
            return {
              id: filteredVarObj?.variant_name,
              name: `${filteredVarObj?.variant_name} - ${filteredVarObj?.variant_label?.split('||||')?.[0]}`,
            }
          });
      } else {
        tempVariantOptions = tempVariantList?.
          filter((varObj) => varObj?.audience_group_name === selectedAudience).
          map?.((filteredVarObj) => {
            return {
              id: filteredVarObj?.variant_name,
              name: `${filteredVarObj?.variant_name} - ${filteredVarObj?.variant_label?.split('||||')?.[0]}`,
            }
          });
      }
      setVariantsOptions(tempVariantOptions);
      let tempSelectedVariants = tempVariantOptions?.map((variantArray) => variantArray?.id);
      setSelectedVariants(tempSelectedVariants);
      setSelectedAudience(selectedAudience);
    }
  }, [selectedAudience]);

  const FilterDiv = (
    <div style={{ paddingBottom: "5px", display: "flex", flexDirection: "row" }}>
      <Space>
        {
          (activeTab === "summary" || activeTab === "detailedView") &&
          (experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]?.audience_group_name ?
            <>
              <FilterComponent title="Audience">
                <Select
                  placeholder="Select item"
                  width={250}
                  maxTagCount="responsive"
                  onChange={(values) => setSelectedAudience(values)}
                  value={selectedAudience}
                  options={AudienceOptions}
                />
              </FilterComponent>
              <FilterComponent title="Variants">
                <Select
                  placeholder="Select item"
                  mode="multiple"
                  width={250}
                  maxTagCount="responsive"
                  onChange={(values) => setSelectedVariants(values)}
                  onSelectAll={(values) => setSelectedVariants(values)}
                  value={selectedVariants}
                  options={variantsOptions}
                />
              </FilterComponent>
              {/* <FilterComponent title="Variants">
              <Cascader
                options={variantsOptions}
                style={{ width: "250px" }}
                value={selectedCascaderVariants}
                onChange={(e) => cascaderChangeHandler(e)}
                placeholder="Please select"
                multiple
                showSearch
                maxTagCount="responsive"
              />
            </FilterComponent> */}
            </> :
            <FilterComponent title="Variants">
              <Select
                placeholder="Select item"
                mode="multiple"
                width={250}
                maxTagCount="responsive"
                onChange={(values) => setSelectedVariants(values)}
                onSelectAll={(values) => setSelectedVariants(values)}
                value={selectedVariants}
                options={variantsOptions}
              />
            </FilterComponent>)
        }
        {/* <DateSelectionRangePicker
          currentScreen={currentScreen}
          selectedCohort={selectedCohort}
          setSelectedCohort={setSelectedCohort}
        /> */}
      </Space>
    </div>
  );

  const reloadResultsPageData = (tab) => {
    if (selectedCohort) {
      // setDataLoading(true);
      switch (tab) {
        case "Summary":
          loadSummaryTabData(selectedDateRangeVal);
          break;
        case "DetailView":
          loadDetailedViewTabData(selectedDateRangeVal);
          break;
        case "VariantComparison":
          loadVariantComparisonTabData(selectedDateRangeVal);
          break;
        case "SegmentImpactExplorer":
          loadSegmentImpactExplorerTabParams(selectedDateRangeVal);
          break;
      }
      // setTimeout(() => {
      //   setDataLoading(false);
      //   setDataAvailable(true);
      // }, 400);
    }
    // else {
    //   setTimeout(() => {
    //     // setDataLoading(false);
    //     setDataAvailable(false);
    //   }, 400);
    // }
  };

  const loadSummaryTabCardsData = async (selectedDateRange) => {
    const groupView = "results_summary";
    let dataconfig = getResultSummaryConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      groupView,
      splitByDelimiterAndToStartCase(
        experimentModule.selectedExperiment.response_metric
      ),
      experimentModule.selectedExperiment.default_version_config,
    );
    console.log("loadSummaryTabCardsData call", dataconfig);
    let tempSummaryData = { ...summaryData };
    tempSummaryData.card.loading = true;
    tempSummaryData.card.error = false;
    setSummaryData(tempSummaryData);
    const result = await service
      .getExperimentResultSummary(dataconfig)
      .then(async (res) => {
        console.log("loadSummaryTabCardsData res", res);
        return res;
      })
      .catch((err) => {
        console.log("loadSummaryTabCardsData Error", err);
        let tempSummaryData = { ...summaryData };
        tempSummaryData.card.error = true;
        setSummaryData(tempSummaryData);
      });
    return result;
  };

  const loadSummaryTabTwoByTwoChartData = async (selectedDateRange) => {
    const groupView = "results_summary";
    let dataconfig = getResultSummaryTwoByTwoChartConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      groupView,
      splitByDelimiterAndToStartCase(
        experimentModule.selectedExperiment.response_metric
      ),
      experimentModule?.selectedExperiment?.default_version_config,
      experimentModule?.selectedExperiment?.experiment_data?.summary_chart_metric
    );
    console.log("loadSummaryTabTwoByTwoChartData call", dataconfig);
    let tempSummaryData = { ...summaryData };
    tempSummaryData.twoByTwoChart.loading = true;
    tempSummaryData.twoByTwoChart.error = false;
    setSummaryData(tempSummaryData);
    const result = await service
      .getExperimentResultSummaryTwoByTwo(dataconfig)
      .then(async (res) => {
        console.log("loadSummaryTabTwoByTwoChartData res", res);
        return res;
      })
      .catch((err) => {
        console.log("loadSummaryTabTwoByTwoChartData Error", err);
        let tempSummaryData = { ...summaryData };
        tempSummaryData.twoByTwoChart.error = true;
        setSummaryData(tempSummaryData);
      });
    return result;
  };

  const loadSummaryTabData = async (selectedDateRange) => {
    if (selectedDateRange) {
      await Promise.allSettled([
        loadSummaryTabCardsData(selectedDateRange),
        loadSummaryTabTwoByTwoChartData(selectedDateRange)
      ])
        .then((responses) => {
          let tempSummaryData = { ...summaryData };
          tempSummaryData.card.data = responses?.[0]?.value;
          tempSummaryData.card.loading = false;
          tempSummaryData.twoByTwoChart.data = responses?.[1]?.value;
          tempSummaryData.twoByTwoChart.loading = false;
          setSummaryData(tempSummaryData);
        });
    }
  };

  const loadDetailedViewTabComparisionTableData = async (selectedDateRange) => {
    console.log("loadDetailedViewTabData call");
    let dataconfigParameterVariantComparisionTable =
      getDetailedViewParameterVariantComparisionTableConfig(
        experimentModule.selectedExperiment.id,
        ...selectedDateRange,
        experimentModule.selectedExperiment.default_version_config,
      );
    let tempdetailedViewTableData = { ...detailedViewData };
    tempdetailedViewTableData.table.loading = true;
    tempdetailedViewTableData.table.error = false;
    setDetailedViewData(tempdetailedViewTableData);
    const result = await service
      .getExperimentResultDetailedViewParameterVariantComparisionTable(
        dataconfigParameterVariantComparisionTable
      )
      .then(async (res) => {
        console.log("loadDetailedViewTabData res", res);
        return res;
      })
      .catch((err) => {
        console.log("loadDetailedViewTabData Error", err);
        let tempdetailedViewTableData = { ...detailedViewData };
        tempdetailedViewTableData.table.error = true;
        setDetailedViewData(tempdetailedViewTableData);
      });
    return result;
  }

  const loadDetailedViewTabCorrelationChartData = async (selectedDateRange) => {
    console.log("loadDetailedViewTabCorrelationChartData call");
    let dataconfig = getDetailedViewCorrelationChartConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      experimentModule.selectedExperiment.default_version_config,
    );
    let tempdetailedViewTableData = { ...detailedViewData };
    tempdetailedViewTableData.correlationChart.loading = true;
    tempdetailedViewTableData.correlationChart.error = false;
    setDetailedViewData(tempdetailedViewTableData);
    const result = await service
      .getExperimentResultDetailedViewCorrelationChart(
        dataconfig
      )
      .then(async (res) => {
        console.log("loadDetailedViewTabCorrelationChartData res", res);
        return res;
      })
      .catch((err) => {
        console.log("loadDetailedViewTabCorrelationChartData Error", err);
        let tempdetailedViewTableData = { ...detailedViewData };
        tempdetailedViewTableData.correlationChart.error = true;
        setDetailedViewData(tempdetailedViewTableData);
      });
    return result;
  }

  const loadDetailedViewTabData = async (selectedDateRange) => {
    if (selectedDateRange) {
      await Promise.allSettled([
        loadDetailedViewTabComparisionTableData(selectedDateRange),
        loadDetailedViewTabCorrelationChartData(selectedDateRange)
      ])
        .then((responses) => {
          let tempdetailedViewTableData = { ...detailedViewData };
          tempdetailedViewTableData.table.data = responses?.[0]?.value;
          tempdetailedViewTableData.table.loading = false;
          tempdetailedViewTableData.correlationChart.data = responses?.[1]?.value;
          tempdetailedViewTableData.correlationChart.loading = false;
          setDetailedViewData(tempdetailedViewTableData);
        });
    }
  };

  const loadVariantComparisonTabData = async (selectedDateRange) => {
    console.log("loadVariantComparisonTabData call");
    const groupView = "results_variant_comparison";
    let dataconfig = getVariantComparisonConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      groupView,
      experimentModule.selectedExperiment.default_version_config,
    );
    setVariantComparisonData({
      ...variantComparisonData,
      error: false,
      loading: true
    });
    await service
      .getExperimentResultVariantComparison(dataconfig)
      .then(async (res) => {
        console.log("loadVariantComparisonTabData res", res);
        setVariantComparisonData({
          ...variantComparisonData,
          data: res,
          loading: false
        });
        // setVariantComparisonData(null)
      })
      .catch((err) => {
        console.log("loadVariantComparisonTabData Error", err);
        setVariantComparisonData({
          ...variantComparisonData,
          error: true
        });
      });
  };

  const loadSegmentImpactExplorerTabParams = async (selectedDateRange) => {
    let dataconfig = getSegmentImpactExplorerTabParamsConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      experimentModule.selectedExperiment.default_version_config,
    );
    console.log("loadSegmentImpactExplorerTabParams call", dataconfig);
    setSegmentImpactExplorerTabParams({
      ...segmentImpactExplorerTabParams,
      loading: true,
      error: false
    });
    await service
      .getExperimentResultSegmentImpactExplorerParams(dataconfig)
      .then(async (res) => {
        console.log("loadSegmentImpactExplorerTabParams res", res);
        setSegmentImpactExplorerTabParams({
          ...segmentImpactExplorerTabParams,
          loading: false,
          data: res
        });
        if (res?.parameter_list?.length === 0 &&
          res?.segment_list?.length === 0 &&
          res?.variant_groups_control_test_list?.length === 0) {
          setSegmentimpactTabData({
            ...segmentimpactTabData,
            data: null,
            loading: false
          });
        }
      })
      .catch((err) => {
        console.log("loadVariantComparisonTabData Error", err);
        setSegmentImpactExplorerTabParams({
          ...segmentImpactExplorerTabParams,
          error: true
        });
      });
  };

  const getSelectedDateRange = async () => {
    const experimentTaskManagementData =
      experimentModule.selectedExperiment.experiment_task_management;
    let selectedTaskId = selectedCohort?.length > 1 ? selectedCohort?.[1] : selectedCohort?.[0]
    console.log("getSelectedDateRange selectedCohort", selectedCohort);
    let selectedTaskItem = experimentTaskManagementData.find(
      (item) => item.id === selectedTaskId
    );
    let datRange = [
      selectedTaskItem?.["start_time_kpi"],
      selectedTaskItem?.["end_time_kpi"],
    ];
    console.log("getSelectedDateRange", datRange, selectedCohort);
    setSelectedDateRangeVal(datRange);
    return datRange;
  };

  const loadVariantFilter = () => {
    if (experimentModule?.selectedExperiment) {
      let tempVariantList = experimentModule?.selectedExperiment?.experiment_data?.variant_setup;
      let tempAudienceGroupSort = experimentModule?.selectedExperiment?.experiment_data?.audience_group_setup;
      let tempAudienceOptions = [];
      let tempVariantOptions = [];
      let tempSelectedVariants = [];
      // Resetting all current States
      setSelectedVariants();
      setVariantsOptions([]);
      setSelectedAudience();
      setAudienceOptions([]);
      if (tempVariantList?.[0]?.audience_group_name) {
        let tempGroupOptionsUnique = [...new Set(tempVariantList?.map?.(variant => variant?.audience_group_name))];
        let filteredAudienceGroups = experimentModule?.selectedExperiment?.experiment_overall_kpi_lists?.find((kpiListObj) => {
          const filteredTaskManagementObj =
            experimentModule?.selectedExperiment?.experiment_task_management
              ?.find((taskManObj) => taskManObj?.id === selectedCohort?.[selectedCohort?.length - 1]);
          if (filteredTaskManagementObj?.start_time_kpi === kpiListObj?.start_time_kpi &&
            filteredTaskManagementObj?.end_time_kpi === kpiListObj?.end_time_kpi) {
            return kpiListObj;
          }
        });
        // Filtering the Audience options from experiment_audience_group_variant_data else tempGroupOptionsUnique is used
        const defaultSelectedGroup = filteredAudienceGroups?.experiment_audience_group_variant_data?.groups?.[0];
        if (defaultSelectedGroup?.group_name) {
          tempAudienceOptions =
            filteredAudienceGroups?.experiment_audience_group_variant_data?.
              groups?.map((grpObj) => {
                return {
                  id: grpObj?.group_name,
                  name: grpObj?.group_name,
                  sort: grpObj?.order,
                  variant_list:
                    grpObj?.variant_list?.map(variantObj => {
                      return {
                        ...variantObj,
                        id: variantObj?.variant_name,
                        name: `${variantObj?.variant_name} - ${variantObj?.variant_label}`,
                      };
                    }),
                }
              });
        } else {
          tempAudienceOptions = tempGroupOptionsUnique?.map?.((unique_audience_group) => {
            return {
              id: unique_audience_group,
              name: unique_audience_group,
            }
          });
        }
        // Here the based on filtered Audience order sorting is done else by GroupSort
        if (defaultSelectedGroup?.order) {
          tempAudienceOptions = tempAudienceOptions?.sort((a, b) => a?.sort - b?.sort);
        } else if (tempAudienceGroupSort) {
          tempAudienceOptions = tempAudienceOptions?.sort(function (a, b) {
            return tempAudienceGroupSort?.indexOf(a?.name) - tempAudienceGroupSort?.indexOf(b?.name);
          });
        }
        let tempSelectedAudienceOption = tempAudienceOptions?.[0]?.id;
        let tempFilteredVariantList = filteredAudienceGroups?.experiment_audience_group_variant_data?.
          groups?.find(grpObj => grpObj?.group_name === tempAudienceOptions?.[0]?.id)?.variant_list;
        if (tempFilteredVariantList) {
          tempVariantOptions = tempAudienceOptions?.[0]?.variant_list;
        } else {
          tempVariantOptions = tempVariantList?.
            filter((varObj) => varObj?.audience_group_name === tempSelectedAudienceOption).
            map?.((filteredVarObj) => {
              return {
                id: filteredVarObj?.variant_name,
                name: `${filteredVarObj?.variant_name} - ${filteredVarObj?.variant_label?.split('||||')?.[0]}`,
              };
            });
        }
        tempSelectedVariants = tempVariantOptions?.map((variantArray) => variantArray?.id);
        setAudienceOptions(tempAudienceOptions);
        setSelectedAudience(tempSelectedAudienceOption);
      } else {
        tempVariantOptions = tempVariantList?.map((variantObj) => {
          return {
            id: variantObj?.variant_name,
            name: `${variantObj?.variant_name} - ${variantObj?.variant_label?.split('||||')?.[0]}`
          }
        });
      }
      setVariantsOptions(tempVariantOptions);
      tempSelectedVariants = tempVariantOptions?.map((variantObj) => variantObj?.id);
      setSelectedVariants(tempSelectedVariants);
    }
  };

  const load = () => {
    if (selectedCohort) {
      // setDataLoading(true);
      setSegmentimpactTabFilters(initialSegmentFilters);
      loadVariantFilter();
      getSelectedDateRange().then(async (selectedDateRange) => {
        /**
         * Methods to trigger data load for each tabs
         */
        await Promise.all([
          loadSummaryTabData(selectedDateRange),
          loadDetailedViewTabData(selectedDateRange),
          loadVariantComparisonTabData(selectedDateRange),
          loadSegmentImpactExplorerTabParams(selectedDateRange),
        ])
        // .then(() => {
        //   setTimeout(() => {
        //     // setDataLoading(false);
        //     setDataAvailable(true);
        //   }, 400);
        // });
      });
    }
    // else {
    //   setTimeout(() => {
    //     // setDataLoading(false);
    //     setDataAvailable(false);
    //   }, 400);
    // }
  };

  const endOfExportFileName = React.useCallback(() => {
    let experimenttaskId = selectedCohort[selectedCohort.length - 1];
    let experimenttask = experimentTaskManagementData.find(
      (taskItem) => taskItem.id === experimenttaskId
    );
    console.log("endOfExportFileName - experimenttask", experimenttask);
    let fileNameEnd = ``;
    // if (selectedCohort.length > 1) {
    //     fileNameEnd = `${fileNameEnd} - ${splitByDelimiterAndToStartCase('send_out_date')} - ${experimenttask.send_out_week}`
    // }
    // else{
    //     fileNameEnd = `${fileNameEnd} - ${splitByDelimiterAndToStartCase(experimenttask.prior_date_range)} - ${experimenttask.send_out_week}`
    // }
    fileNameEnd = `${splitByDelimiterAndToStartCase(experimenttask.prior_date_range)} - ${experimenttask.send_out_week}`

    return fileNameEnd;
  }, [selectedCohort]);

  useEffect(() => {
    console.log("change oon selectedCohort", selectedCohort);
    load();
  }, [selectedCohort]);

  useEffect(() => {
    if (experimentModule?.selectedExperiment?.id) {
      //Reseting all States when experiment is changing
      setSummaryData({
        card: {
          ...summaryData?.card,
          data: null,
          error: false
        },
        twoByTwoChart: {
          ...summaryData?.twoByTwoChart,
          data: null,
          error: true
        },
      });
      setDetailedViewData({
        table: {
          ...detailedViewData?.table,
          data: null,
          error: false
        },
        correlationChart: {
          ...detailedViewData?.correlationChart,
          data: null,
          error: false
        }
      });
      setVariantComparisonData({
        ...variantComparisonData,
        data: null,
        error: false
      });
      setSegmentImpactExplorerTabParams({
        ...segmentImpactExplorerTabParams,
        data: null,
        error: false
      });
      setSegmentimpactTabData({
        ...segmentimpactTabData,
        data: null,
        error: false,
      });
      setSelectedVariants();
      setVariantsOptions([]);
      setSelectedAudience();
      setAudienceOptions([]);
    }
  }, [experimentModule?.selectedExperiment?.id]);

  const loadSegmentImpactExplorerTabData = async () => {
    // setSegmentimpactTabData(null);
    // setSegmentImpactDataLoading(true);
    console.log(
      "loadSegmentImpactExplorerTabData",
      selectedDateRangeVal,
      segmentimpactTabFilters
      // selectedSegment,
      // selectedParameter,
      // selectedTestGroup,
      // selectedControlGroup
    );
    let dataconfig = getSegmentImpactExplorerTabDataConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRangeVal,
      selectedSegment,
      selectedParameter,
      selectedTestGroup,
      selectedControlGroup,
      experimentModule.selectedExperiment.default_version_config,
    );
    console.log("loadSegmentImpactExplorerTabData call", dataconfig);
    setSegmentimpactTabData({
      ...segmentimpactTabData,
      error: false,
      loading: true
    });
    await service
      .getExperimentResultSegmentImpactExplorerData(dataconfig)
      .then(async (res) => {
        console.log("loadSegmentImpactExplorerTabData res", res);
        setSegmentimpactTabData({
          ...segmentimpactTabData,
          data: res,
          loading: false
        });
      })
      .catch((err) => {
        console.log("loadSegmentImpactExplorerTabData Error", err);
        setSegmentimpactTabData({
          ...segmentimpactTabData,
          error: true
        });
      })
    // .finally(() => {
    //   setSegmentimpactTabData({
    //     ...segmentimpactTabData,
    //   });
    // });
  };

  const exportSegmentImpactExplorerTabData = async () => {
    console.log(
      "exportSegmentImpactExplorerTabData",
      selectedDateRangeVal,
      segmentimpactTabFilters
      // selectedSegment,
      // selectedParameter,
      // selectedTestGroup,
      // selectedControlGroup
    );
    let dataconfig = getSegmentImpactExplorerTabDataConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRangeVal,
      selectedSegment,
      selectedParameter,
      selectedTestGroup,
      selectedControlGroup,
      experimentModule.selectedExperiment.default_version_config,
    );
    console.log("exportSegmentImpactExplorerTabData call", dataconfig);
    await service
      .exportExperimentSegmentImpactReport(dataconfig)
      .then(async (res) => {
        console.log("exportSegmentImpactExplorerTabData res", res);
        const blob = res;
        download(
          blob,
          "Report.xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        // require("downloadjs")(res, "Report.xlsx");
      })
      .catch((err) => {
        console.log("exportSegmentImpactExplorerTabData Error", err);
      });
    // .finally(() => {
    // });
  };

  useEffect(() => {
    if (
      selectedSegment &&
      selectedParameter &&
      selectedTestGroup &&
      selectedControlGroup
    ) {
      console.log(
        "change oon segmentimpactTabFilters",
        selectedSegment,
        selectedParameter,
        selectedTestGroup,
        selectedControlGroup
      );
      setSegmentimpactTabData({
        ...segmentimpactTabData,
        data: null,
        loading: true
      });
      loadSegmentImpactExplorerTabData();
    } else {
      setSegmentimpactTabData({
        ...segmentimpactTabData,
        data: null,
        loading: false
      });
    }
  }, [selectedSegment, selectedParameter, selectedTestGroup]);

  // const loadDetailedViewTabDataConfidenceInterval = async (
  //   detailedViewParameter
  // ) => {
  //   console.log("loadDetailedViewTabData call");
  //   let dataconfigConfidenceInterval = getDetailedViewConfidenceIntervalConfig(
  //     experimentModule.selectedExperiment.id,
  //     ...selectedDateRangeVal,
  //     detailedViewParameter,
  //     experimentModule.selectedExperiment.default_version_config,
  //   );
  //   setDetailedViewTabConfidenceIntervalError(false);
  //   await service
  //     .getExperimentResultDetailedViewConfidenceInterval(
  //       dataconfigConfidenceInterval
  //     )
  //     .then(async (res) => {
  //       console.log(
  //         "loadDetailedViewTabDataParameterChange Confidence Interval",
  //         res
  //       );
  //       setDetailedViewConfidenceIntervalData(res);
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "loadVariantComparisonTabData Confidence Interval Error",
  //         err
  //       );
  //       setDetailedViewTabConfidenceIntervalError(true);
  //     });
  // };


  // const loadDetailedViewTabDataResponseMetric = async (
  //   detailedViewParameter
  // ) => {
  //   console.log("loadDetailedViewTabData call");
  //   let dataconfigResponseMetric = getDetailedViewResponseMetricConfig(
  //     experimentModule.selectedExperiment.id,
  //     ...selectedDateRangeVal,
  //     detailedViewParameter,
  //     experimentModule.selectedExperiment.default_version_config,
  //   );
  //   setDetailedViewTabResponseMetricError(false);
  //   await service
  //     .getExperimentResultDetailedViewResponseMetric(dataconfigResponseMetric)
  //     .then(async (res) => {
  //       console.log(
  //         "loadDetailedViewTabDataParameterChange Response Metric",
  //         res
  //       );
  //       setDetailedViewResponseMetricData(res);
  //     })
  //     .catch((err) => {
  //       console.log("loadVariantComparisonTabData Response Metric Error", err);
  //       setDetailedViewTabResponseMetricError(true);
  //     });
  // };

  // useEffect(async () => {
  //   if (detailedViewParameter) {
  //     await Promise.all([
  //       // loadDetailedViewTabDataConfidenceInterval(detailedViewParameter),
  //       loadDetailedViewTabDataResponseMetric(detailedViewParameter),
  //     ]);
  //   }
  //   else {
  //     // Set with empty array
  //     // setDetailedViewConfidenceIntervalData([]);
  //     setDetailedViewResponseMetricData([])
  //   }
  // }, [detailedViewParameter]);

  return (
    <>
      {
        experimentModule?.loading ?
          <LegacyNoData height={"71vh"} loading={true} /> :
          <Grid template={[["tabPane", "tabPane", "tabPane", "tabPane"]]}>
            <GridItem area="tabPane">
              <div className={"custom-tabs"}>
                <Tabs
                  items={tabMenu}
                  // style={{ marginTop: "-25px", marginLeft: "3px" }}
                  defaultActiveKey={activeTab}
                  onChange={onTabChange}
                  tabBarExtraContent={FilterDiv}
                />
                <>
                  {activeTab === "summary" && (
                    <>
                      {summaryData?.card?.error && summaryData?.twoByTwoChart?.error ? (
                        <LegacyNoData height={"71vh"} error={true}>
                          <Button
                            onClick={() => loadSummaryTabData(selectedDateRangeVal)}
                          >
                            Try Again
                          </Button>
                        </LegacyNoData>
                      )
                        :
                        summaryData?.card?.loading && summaryData?.twoByTwoChart?.loading ? (
                          <LegacyNoData height={"71vh"} loading={true} />
                        )
                          :
                          summaryData?.card?.data || summaryData?.twoByTwoChart?.data ?
                            (
                              <Comps.MeasureResultsSummaryTab
                                summaryData={summaryData}
                                setSummaryData={setSummaryData}
                                selectedVariants={selectedVariants}
                                currentScreen={currentScreen}
                                loadSummaryTabCardsData={loadSummaryTabCardsData}
                                loadSummaryTabTwoByTwoChartData={loadSummaryTabTwoByTwoChartData}
                                selectedDateRangeVal={selectedDateRangeVal}
                                selectedAudience={selectedAudience}
                                endOfExportFileName={endOfExportFileName}
                              />
                            )
                            :
                            (
                              <LegacyNoData height={"71vh"} />
                            )
                      }
                    </>
                  )}
                  {activeTab === "detailedView" && (
                    <>
                      {detailedViewData?.table?.error && detailedViewData?.correlationChart?.error ? (
                        <LegacyNoData height={"71vh"} error={true}>
                          <Button onClick={() => loadDetailedViewTabData(selectedDateRangeVal)}>
                            Try Again
                          </Button>
                        </LegacyNoData>
                      )
                        :
                        detailedViewData?.table?.loading && detailedViewData?.correlationChart?.loading ? (
                          <LegacyNoData height={"71vh"} loading={true} />
                        )
                          :
                          detailedViewData?.table?.data || detailedViewData?.correlationChart?.data ?
                            (
                              <Comps.MeasureResultsDetailedViewTab
                                detailedViewData={detailedViewData}
                                selectedVariants={selectedVariants}
                                selectedDateRangeVal={selectedDateRangeVal}
                                loadDetailedViewTabData={loadDetailedViewTabData}
                                loadDetailedViewTabCorrelationChartData={loadDetailedViewTabCorrelationChartData}
                                selectedAudience={selectedAudience}
                                endOfExportFileName={endOfExportFileName}
                              />
                            ) :
                            (
                              <LegacyNoData height={"71vh"} />
                            )
                      }
                    </>
                  )}
                  {activeTab === "variantComparison" && (
                    <>
                      {
                        variantComparisonData?.error ? (
                          <LegacyNoData height={"71vh"} error={true}>
                            <Button
                              onClick={() =>
                                reloadResultsPageData("VariantComparison")
                              }
                            >
                              Try Again
                            </Button>
                          </LegacyNoData>
                        )
                          :
                          variantComparisonData?.loading ?
                            (
                              <LegacyNoData height={"71vh"} loading={true} />
                            )
                            :
                            variantComparisonData?.data ?
                              (
                                <Comps.MeasureResultsVariantComparisonTab
                                  variantComparisonData={
                                    variantComparisonData?.data !== null &&
                                    variantComparisonData?.data
                                  }
                                />
                              ) :
                              (
                                <LegacyNoData height={"71vh"} />
                              )
                      }
                    </>
                  )}
                  {activeTab === "segmentImpactExplorer" && (
                    <>
                      {
                        segmentImpactExplorerTabParams?.error ? (
                          <LegacyNoData height={"71vh"} error={true}>
                            <Button
                              onClick={() =>
                                reloadResultsPageData("SegmentImpactExplorer")
                              }
                            >
                              Try Again
                            </Button>
                          </LegacyNoData>
                        )
                          :
                          segmentImpactExplorerTabParams?.loading ?
                            (
                              <LegacyNoData height={"71vh"} loading={true} />
                            )
                            :
                            segmentImpactExplorerTabParams?.data ?
                              (
                                <Comps.MeasureResultsSegmentImpactExplorerTab
                                  segmentImpactExplorerTabParams={
                                    segmentImpactExplorerTabParams
                                  }
                                  segmentimpactTabFilters={segmentimpactTabFilters}
                                  setSegmentimpactTabFilters={
                                    setSegmentimpactTabFilters
                                  }
                                  segmentimpactTabData={segmentimpactTabData}
                                  // segmentImpactTabTableError={
                                  //   segmentImpactTabTableError
                                  // }
                                  loadSegmentImpactExplorerTabData={
                                    loadSegmentImpactExplorerTabData
                                  }
                                  // segmentImpactDataLoading={segmentImpactDataLoading}
                                  exportSegmentImpactExplorerTabData={
                                    exportSegmentImpactExplorerTabData
                                  }
                                />
                              )
                              :
                              (
                                <LegacyNoData height={"71vh"} />
                              )
                      }
                    </>
                  )}
                </>
              </div>
            </GridItem>
          </Grid>
      }
    </>
  );
};

export default MeasureResultsTab;
