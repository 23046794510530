import React, { useState } from "react";
import * as Comps from "./components";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";

const DesignOptimization = () => {
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

    return (
        <div className={'opacity-0-55'}>
            <GenericModalWithResultComponent
                isRequestModalOpen={isRequestModalOpen}
                setIsRequestModalOpen={setIsRequestModalOpen}
            />
            <Comps.CardLayout />
        </div>
    );
};

export default DesignOptimization;