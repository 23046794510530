import { Card, Checkbox, /* Col,  */ Input /* , Row */ } from "antd";
import React from "react";
import { ImageUploader } from "../../design-gen-ai-dynamic-images/components/image-uploader/ImageUploader";

export const BrandComponentSummary = (props) => {
  return (
    <div style={{}}>
      {props.inputs.map((element, index) => (
        <div
          key={index}
          style={{
            padding: 10,
            paddingBottom: 20,
            paddingTop: 0,
            display: "flex",
          }}
        >
          <div style={{ marginTop: 1, marginRight: 20 }}>
            <Checkbox
              disabled={element.required}
              checked={
                (props.data.selectedFields || []).indexOf(element.key) !== -1
              }
              onChange={
                (/* e */) => {
                  var currentFields = props.data.selectedFields || [];
                  var index = currentFields.indexOf(element.key);
                  if (index !== -1) {
                    currentFields.splice(index, 1);
                  } else {
                    currentFields.push(element.key);
                  }
                  props.updateEntry("selectedFields", currentFields);
                }
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ marginBottom: 0 }}>{element.label}</h3>
            <p style={{ marginBottom: 0, opacity: 0.75 }}>{element.help}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const BrandComponent = (props) => {
  return (
    <>
      {props.inputs
        .filter((input) => {
          return (props.data.selectedFields || []).indexOf(input.key) !== -1;
        })
        .map((element, index) => (
          <div style={{ marginBottom: 25 }} key={index}>
            <Card className="">
              <div>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    margin: "0px",
                  }}
                >
                  {element.label}
                </p>
                <p
                  style={{
                    fontSize: "0.8rem",
                    margin: "0px",
                    opacity: 0.75,
                    fontWeight: "300",
                  }}
                >
                  {element.help}
                </p>
              </div>
              {element.type === "file-upload" && (
                <div style={{ marginTop: 20 }}>
                  <ImageUploader image={props.data[element.key]} multiple />
                </div>
              )}
              {element.type === "textarea" && (
                <div style={{ marginTop: 20 }}>
                  <Input.TextArea
                    {...element.props}
                    onChange={(e) => {
                      props.updateEntry(element.key, e.target.value);
                      //   setFormData({
                      //     ...formData,
                      //     basicInfo: {
                      //       ...formData.basicInfo,
                      //       [element.key]: e.target.value,
                      //     },
                      //   });
                    }}
                  />
                </div>
              )}
            </Card>
          </div>
        ))}
    </>
  );
};
