import * as EnterpriseLedgerService from "@xai/services/executive";

class EnterpriseLedgerServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getEnterpriseLedgerDendogram(dataConfig) {
    const data = await EnterpriseLedgerService.getEnterpriseLedgerDendogram(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart(dataConfig) {
    const data = await EnterpriseLedgerService.getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExecutiveEnterpriseLedgerRevenueOverTimeChart(dataConfig) {
    const data = await EnterpriseLedgerService.getExecutiveEnterpriseLedgerRevenueOverTimeChart(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExecutiveEnterLedgerExperimentRevenueCorrelationChart(dataConfig) {
    const data = await EnterpriseLedgerService.getExecutiveEnterLedgerExperimentRevenueCorrelationChart(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }


}

export default EnterpriseLedgerServiceHelper;
