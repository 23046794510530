import { postTableDataTransformer } from "@xai/pages/design-variant-bank-approval/components/design-variant-bank-approval-table/transformer";

const BasicStepFieldsKeyList = [
  // Array of basic step field keys to check value is empty
  "basicName",
  "objective",
  "targetAudience",
  "campaignPerformance",
  "currentState",
  "frequency",
  "responseMetric",
  "message",
  "variantAllocation",
];

export const basicFieldsValueCheck = (config) => {
  return BasicStepFieldsKeyList.find(
    (x) => ["", null, undefined].indexOf(config[x]) > -1
  );
};

export const postVariantbanktransformer = ({
  experimentId,
  variantList,
  experimentTypeValue,
}) => {
  let updertData = variantList?.map((variant) => {
    return {
      experiment_id: experimentId,
      variantbankId: variant?.id,
      subjectLine:
        [ "SUBJECT_LINE","SUBJECT_LINE_EMAIL_BODY"].indexOf(
          experimentTypeValue
        ) > -1
          ? variant?.text
          : variant?.body,
      bodyCopy:
        ["EMAIL_BODY"].indexOf(experimentTypeValue) > -1
          ? variant?.text
          : variant?.body,
      version: 1,
    };
  });
  updertData = postTableDataTransformer(updertData);
  console.log("postVariantbanktransformer", updertData);
  return updertData;
};

export const postExperimentDataTransformer = ({
  basicData,
  experimentTypeValue,
  selectedExperiment,
}) => {
  let experimentTextVariantsType =
    experimentTypeValue === "SUBJECT_LINE"
      ? "subjectLineOnly"
      : experimentTypeValue === "EMAIL_BODY"
      ? "bodyCopyOnly"
      : experimentTypeValue === "SUBJECT_LINE_EMAIL_BODY"
      ? "subjectLineAndBodyCopy"
      : experimentTypeValue === "TAGLINE"
      ? "tagline"
      :"";

  let templateId = 1; // as default, in future will have to change based on output content type selection.

  // double JSON.stringify, to get json string literals
  let expDataObj = JSON.stringify(
    JSON.stringify({
      experiment_text_variants_type: experimentTextVariantsType,
      basicStepInfo: basicData,
      // tag_groups //#TODO : To be added later, post discussing with team.
      //
    })
  );
  console.log("postExperimentDataTransformer", expDataObj, basicData);

  let dataConfig = {
    id: basicData?.experimentId,
    Name: basicData?.basicName,
    templateId: selectedExperiment?.templateId
      ? selectedExperiment?.templateId
      : templateId,
    isActive: selectedExperiment?.isActive
      ? selectedExperiment?.isActive
      : true, // default for now, to be changed to what we get from selected experiment on navigation
    status: selectedExperiment?.status ? selectedExperiment?.status : 4, // default for now, to be changed to what we get from selected experiment on navigation
    defaultVersionConfig: selectedExperiment?.defaultVersionConfig
      ? selectedExperiment?.defaultVersionConfig
      : 1, // default for now, to be changed to what we get from selected experiment on navigation
    experimentData: expDataObj,
  };
  return dataConfig;
};
