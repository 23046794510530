import React, { useState, useEffect } from "react";
// import { startCase } from "lodash";
// import numeral from "numeral";
import { Card } from "@pai-ui/core/components/card";
import { Select } from "@pai-ui/core/components/select";
import { Space } from "@pai-ui/core/components/space";
import { Tag } from "@pai-ui/core/components/tag";
import { Metric } from "@pai-ui/core/components/metric";
import { Popover } from "@pai-ui/core/components/popover";
import { DotsVertical } from "@styled-icons/boxicons-regular/DotsVertical";
import { UserContext } from "@xai/providers/with-auth";
import { statusEnum } from "@xai/assets/common/enums";
import ExperimentViewConfigModal from "@xai/assets/product-specific-common-components/experiment-view-config-modal/index.js";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import DateSelectionRangePicker from "@xai/assets/product-specific-common-components/date-selection-range-picker";
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import { loadSelectedExperimentData } from "@xai/providers/with-experiment";
import "./index.css";

const ExperimentTopRowGenericComponent = ({
  currentScreen,
  selectedCohort,
  setSelectedCohort,
  customContent,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedExperimentKPIList, setSelectedExperimentKPIList] = useState();

  const { experimentModule, dispatch, format } = React.useContext(UserContext);
  useEffect(() => {
    if (
      experimentModule?.selectedExperiment?.experiment_overall_kpi_lists &&
      selectedCohort
    ) {
      const selectedExperimentData =
        experimentModule?.selectedExperiment?.experiment_overall_kpi_lists?.find(
          (kpiListObj) => {
            const filteredTaskManagementObj =
              experimentModule?.selectedExperiment?.experiment_task_management?.find(
                (taskManObj) =>
                  taskManObj?.id ===
                  selectedCohort?.[selectedCohort?.length - 1]
              );
            if (
              filteredTaskManagementObj?.start_time_kpi ===
                kpiListObj?.start_time_kpi &&
              filteredTaskManagementObj?.end_time_kpi ===
                kpiListObj?.end_time_kpi
            ) {
              return kpiListObj;
            }
          }
        );
      setSelectedExperimentKPIList(selectedExperimentData);
    }
  }, [selectedCohort]);

  const setSelectedExperiment = async (id) => {
    let finalobj = {
      ...experimentModule,
      analyze_screen_selected_cohort: null,
      analyze_screen_selected_task: null,
      result_screen_selected_cohort: null,
      loading: true,
    };
    await dispatch({
      type: "experimentModule",
      payload: finalobj,
    });

    let selExp = experimentModule?.experimentList?.find(
      (expObj) => expObj?.id === id
    );
    await loadSelectedExperimentData(selExp, finalobj, dispatch);
  };

  return (
    <>
      {["variant-bank"].indexOf(currentScreen) == -1 && (
        <ExperimentViewConfigModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedExperiment={experimentModule?.selectedExperiment}
        />
      )}
      <div className="toprow-container-wrapper">
        {experimentModule?.selectedExperiment ? (
          <Card bordered={false}>
            <div style={{ display: "flex" }}>
              <Space>
                <Metric
                  title={
                    <div id="manageRes">
                      <Select
                        style={{
                          marginBottom: "0px",
                          padding: 0,
                          marginLeft: -10,
                          fontSize: "21px !important",
                          width: "auto",
                          maxWidth: "40vw",
                          minWidth: "30vw",
                        }}
                        size="large"
                        defaultValue={experimentModule?.selectedExperiment?.id}
                        showSearch
                        optionFilterProp="children"
                        onChange={setSelectedExperiment}
                        options={
                          experimentModule?.experimentList
                            ?.filter((experiment) => {
                              if (currentScreen === "results") {
                                return (
                                  [1, 3, 6].indexOf(experiment.status) > -1
                                ); // Experiment status : Completed, Active, Published
                              }
                              return experiment;
                            })
                            ?.map((a) => ({
                              ...a,
                              name: a?.name,
                              id: a?.id,
                            }))
                            ?.sort((a, b) => a.name.localeCompare(b.name)) //
                        }
                        bordered={false}
                      />
                      <Tag
                        color={
                          statusEnum[
                            experimentModule?.selectedExperiment?.status
                          ]?.color
                        }
                      >
                        {
                          statusEnum[
                            experimentModule?.selectedExperiment?.status
                          ]?.name
                        }
                      </Tag>
                    </div>
                  }
                  subTitle={
                    <>
                      {["variant-bank"].indexOf(currentScreen) == -1 && (
                        <Space>
                          {/* <Tag
                      color={
                        statusEnum[
                          experimentModule?.selectedExperiment
                            ?.status
                        ]?.color
                      }
                    >
                      {
                        statusEnum[
                          experimentModule?.selectedExperiment
                            ?.status
                        ]?.name
                      }
                    </Tag> */}
                          {/* <span>
                      {currentScreen === "analyze" &&
                        experimentModule?.selectedExperiment
                          ?.analyze_description}
                      {currentScreen === "results" &&
                        experimentModule?.selectedExperiment
                          ?.result_description}
                    </span> */}
                          <>
                            <span>Date:</span>
                            <DateSelectionRangePicker
                              currentScreen={currentScreen}
                              selectedCohort={selectedCohort}
                              setSelectedCohort={setSelectedCohort}
                            />
                          </>
                        </Space>
                      )}
                    </>
                  }
                  highlightedElement="subTitle"
                  withBackground={false}
                />
              </Space>
              {["variant-bank"].indexOf(currentScreen) == -1 && (
                <Space
                  size={"large"}
                  style={{
                    flex: "auto",
                    justifyContent: "flex-end",
                  }}
                >
                  {currentScreen === "analyze" &&
                    selectedExperimentKPIList?.kpi_list &&
                    Object.values(selectedExperimentKPIList?.kpi_list)
                      ?.filter(
                        (filteredKpiObj) =>
                          filteredKpiObj?.analyze_screen_visible
                      )
                      ?.sort(
                        (a, b) =>
                          parseInt(a?.analyze_screen_order) -
                          parseInt(b?.analyze_screen_order)
                      )
                      ?.map((kpiObject) => {
                        return (
                          <>
                            <Metric
                              textAlign="right"
                              // title={numeral(kpiObject?.value)?.format(format?.[kpiObject?.format])}
                              title={
                                <PosNegValue
                                  value={kpiObject?.value}
                                  valueFormat={format?.[kpiObject?.format]}
                                  textColor={"rgba(225,225,225,0.85)"}
                                  showCaret={kpiObject?.posneg}
                                  fontSize="28px"
                                  ZeroRange={{
                                    minNegZero:
                                      kpiObject?.posneg == "negative"
                                        ? kpiObject?.value > 0
                                          ? kpiObject?.value * 1.01
                                          : kpiObject?.value * 0.99
                                        : kpiObject?.value,
                                    minPosZero:
                                      kpiObject?.posneg == "positive"
                                        ? kpiObject?.value > 0
                                          ? kpiObject?.value * 0.99
                                          : kpiObject?.value * 1.01
                                        : kpiObject?.value,
                                  }}
                                  caratSize="20px"
                                />
                              }
                              subTitle={kpiObject?.label}
                              highlightedElement="subTitle"
                              withBackground={false}
                            />
                          </>
                        );
                      })}
                  {currentScreen === "results" &&
                    selectedExperimentKPIList?.kpi_list &&
                    Object.values(selectedExperimentKPIList?.kpi_list)
                      ?.filter(
                        (filteredKpiObj) =>
                          filteredKpiObj?.results_screen_visible
                      )
                      ?.sort(
                        (a, b) =>
                          parseInt(a?.results_screen_order) -
                          parseInt(b?.results_screen_order)
                      )
                      ?.map((kpiObject) => {
                        return (
                          <>
                            <Metric
                              textAlign="right"
                              // title={numeral(kpiObject?.value)?.format(format?.[kpiObject?.format])}
                              title={
                                <PosNegValue
                                  value={kpiObject?.value}
                                  valueFormat={format?.[kpiObject?.format]}
                                  textColor={"rgba(225,225,225,0.85)"}
                                  showCaret={kpiObject?.posneg}
                                  fontSize="28px"
                                  ZeroRange={{
                                    minNegZero:
                                      kpiObject?.posneg == "negative"
                                        ? kpiObject?.value > 0
                                          ? kpiObject?.value * 1.01
                                          : kpiObject?.value * 0.99
                                        : kpiObject?.value,
                                    minPosZero:
                                      kpiObject?.posneg == "positive"
                                        ? kpiObject?.value > 0
                                          ? kpiObject?.value * 0.99
                                          : kpiObject?.value * 1.01
                                        : kpiObject?.value,
                                  }}
                                  caratSize="20px"
                                />
                              }
                              subTitle={kpiObject?.label}
                              highlightedElement="subTitle"
                              withBackground={false}
                            />
                          </>
                        );
                      })}
                  <Popover
                    placement="bottomRight"
                    content={
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowModal(true)}
                      >
                        View Config
                      </div>
                    }
                    trigger="click"
                  >
                    <DotsVertical
                      style={{ height: "25px", cursor: "pointer" }}
                    />
                  </Popover>
                </Space>
              )}
              {customContent && customContent()}
            </div>
            {["variant-bank"].indexOf(currentScreen) == -1 &&
              experimentModule?.selectedExperiment?.experiment_data
                ?.data_under_review_label && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    color: "#15afd0",
                    marginRight: "20px",
                  }}
                >
                  <i>
                    {
                      experimentModule?.selectedExperiment?.experiment_data
                        ?.data_under_review_label
                    }
                  </i>
                </div>
              )}
          </Card>
        ) : (
          <LegacyNoData height={"12vh"} />
        )}
      </div>
    </>
  );
};

export default ExperimentTopRowGenericComponent;
