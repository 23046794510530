import { DI_TOKENS } from "@xai/core/constants/di";
import { inject } from "@xai/core/di/utils";
import axios from "axios";


export const AddConfig = (authToken, dataConfig) =>
  new Promise((resolve,) => {
    let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/createNewSupersetRoute`; //TODO call create/edit api conditionally
    axios({
      method: "POST",
      url: fetchUrl,
      data: dataConfig,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + authToken,
      },
    })
      .then((response) => {
        resolve({ message: response.data.message, status: response.status, data: response.data.data });
      })
      .catch((err) => {
        console.log(err);
        resolve({ message: "Something went wrong!", status: err.response.status }); //err.response.data.message
      });
  });

export const FetchSupersetRoutesService = (authToken) =>
  new Promise((resolve, reject) => {
    let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/getAllSupersetRoutes`;

    axios({
      method: "GET",
      url: fetchUrl,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + authToken,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });


export const getSupersetAccessToken = async () => {
  const configService = inject(DI_TOKENS.configService);
  const url = configService.get().REACT_APP_SUPERSET_HOST;

  console.log("getSupersetAccessToken called");
  let [username, password] = `${process.env.REACT_APP_SUPERSET_CRED}`.split("|||||");

  let body = {
    username,
    password,
    provider: "db",
  };

  const options = {
    method: "POST",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(body),
  };

  const fetchRes = await fetch(
    `${url}/api/v1/security/login`,
    options
  );

  return fetchRes.json().then((x) => {
    console.log("value after getSupersetAccessToken call", x);
    return x.access_token;
  });
};

export const getSupersetDashboards = async (aToken) => {
  const configService = inject(DI_TOKENS.configService);
  const url = configService.get().REACT_APP_SUPERSET_HOST;

  const options = {
    method: "GET",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${aToken}`,
    },
  };

  const res = await fetch(
    `${url}/api/v1/dashboard`,
    options
  );

  return res.json().then((x) => {
    console.log("value after getDashboards api call", x);
    let res = x;
    if (x?.result?.length) {
      res = {
        ...res,
        result: res?.result?.filter((key) => key?.published)
      }
    }
    return res;
  });
};

export const getReportsConfigured = async (authToken) => {
  const routes = await FetchSupersetRoutesService(authToken);
  const supersetAccessToken = await getSupersetAccessToken();
  const dashboards = await getSupersetDashboards(supersetAccessToken);
  const reports = routes.filter((x) => x.route.toLowerCase().startsWith('add as report'));
  const updatedRoutes = reports.map((x) => {
    const dashboard = dashboards?.result?.find((dashbrd) => dashbrd.id === x.dashboard_id);

    if (dashboard) {
      x.dashboardName = dashboard.dashboard_title;
    }

    return x;
  });

  return updatedRoutes;
};

export const updateRoute = async (route, authToken) => {
  const copy = { ...route };

  delete copy.dashboardName;

  const configService = inject(DI_TOKENS.configService);
  const url = configService.get().REACT_APP_MICROSERVICE_HOST;

  await axios({
    method: "PUT",
    url: `${url}/updateSupersetRoute`,
    headers: {
      "x-api-key": configService.get().REACT_APP_API_KEY,
      Authorization: "Bearer " + authToken,
    },
    data: {
      input_json: copy
    }
  });
};


export const deleteRoute = async (id, authToken) => {
  const configService = inject(DI_TOKENS.configService);
  const url = configService.get().REACT_APP_MICROSERVICE_HOST;

  await axios({
    method: "DELETE",
    url: `${url}/deleteSupersetRoute/${id}`,
    headers: {
      "x-api-key": configService.get().REACT_APP_API_KEY,
      Authorization: "Bearer " + authToken,
    },
  });
};

export const getScreensConfigured = async (authToken) => {
  const routes = await FetchSupersetRoutesService(authToken);
  const supersetAccessToken = await getSupersetAccessToken();
  const dashboards = await getSupersetDashboards(supersetAccessToken);
  const reports = routes.filter((x) => x.route.toLowerCase().startsWith('add as subscreen'));
  const updatedRoutes = reports.map((x) => {
    const dashboard = dashboards?.result?.find((dashbrd) => dashbrd.id === x.dashboard_id);

    if (dashboard) {
      x.dashboardName = dashboard.dashboard_title;
    }

    return x;
  });

  return updatedRoutes;
};