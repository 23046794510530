import React, { /* useState,  */ /*  useEffect, */ useCallback } from "react";
import { Modal } from "@pai-ui/core/components/modal";
import { Button } from "@pai-ui/core/components/button";
// import { Result } from "@pai-ui/core";
import { Result } from "antd";
import WaveLoader from "@xai/assets/generic-components/wave-loader";

const BiasCorrectionRequestModal = ({
  isRequestModalOpen,
  setIsRequestModalOpen,
  dagCreationTaskAPIResponseRecieved,
  dagCreationStatus,
  setDagCreationTaskAPIResponseRecieved,
}) => {
  const close = useCallback(() => {
    setIsRequestModalOpen(false);
    // reset dagCreationTaskAPIResponseRecieved
    setDagCreationTaskAPIResponseRecieved(false);
  }, []);
  //   const [data, setData] = useState([]);

  return (
    <>
      <Modal
        open={isRequestModalOpen}
        withCloseButton={false}
        withoutFooterButtons={true}
        onOk={close}
        // onCancel={close}
        bodyStyle={{
          // height: "55vh",
          /* overflowY: 'auto', */
          background: "#181b20",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        width={"65vw"}
      >
        {dagCreationTaskAPIResponseRecieved ? (
          <Result
            status={dagCreationStatus ? "success" : "error"}
            title={
              dagCreationStatus
                ? "Successfully Created Bias Correction Request"
                : "Failed To Create Bias Correction Request"
            }
            // subTitle={`DAG Request task has been created successfully`}
            extra={
              <Button type="ghost" key="close" onClick={close}>
                Close to continue
              </Button>
            }
          />
        ) : (
          <Result
            icon={<WaveLoader size={40} color="#15afd0" />}
            title={"Bias correction task request creation is in progress."}
            subTitle={`Might take some time to complete the request creation. \n
            Please wait for task creation confirmation.`}
          />
        )}
      </Modal>
    </>
  );
};

export default BiasCorrectionRequestModal;
