import React from "react";
import EditOutlined from '@ant-design/icons/EditOutlined'
import { Row } from '@pai-ui/core/components/row';
import { Input } from '@pai-ui/core/components/input';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import SaveIcon from '@ant-design/icons/SaveOutlined';
import { Table } from "../table";
import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
import { inject } from "@xai/core/di/utils";
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Spin from 'antd/lib/spin';
import { DI_TOKENS } from "@xai/core/constants/di";
import { showNotificationMessage } from "@xai/assets/generic-components/toaster-message";

import './style.css';

export const ScreensConfigured = (props) => {
  const loggerService = inject(DI_TOKENS.loggerService);
  const { getScreens, deleteScreen, updateScreen } = props;
  const [screens, setScreens] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [activeEditingRow, setActiveEditingRow] = React.useState(undefined);
  const [itemIdLoading, setItemIdLoading] = React.useState(undefined);

  const getScreensLocal = React.useCallback(async () => {
    try {
      setLoading(true);
      const reps = await getScreens();
      setScreens(reps);
    } catch (err) {
      loggerService.error('failed to get screens configured', err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to get screens',
      );
    } finally {
      setLoading(false);
    }
  }, [getScreens]);

  const handleScreenNameChange = React.useCallback((e) => {
    setActiveEditingRow((x) => ({
      ...x,
      subroute: e.target.value
    }));
  }, []);

  const renderEditableCell = React.useCallback((item) => {
    if (activeEditingRow?.id === item.id) {
      return (
        <Input
          style={{ height: '20px' }}
          size="small"
          onChange={handleScreenNameChange}
          value={activeEditingRow.subroute}
        />
      );
    } 

    return item.subroute;
  }, [activeEditingRow, handleScreenNameChange]);

  const handleScreenDelete = React.useCallback(async (item) => {
    try {
      setItemIdLoading(item.id);
      await deleteScreen(item.id);
      const reps = await getScreens();
      setScreens(reps);
      setActiveEditingRow(false);
      showNotificationMessage(
        "top",
        "success",
        'Screen has been successfully deleted',
      );
    } catch (err) {
      loggerService.error('failed to delete screen', err);
      console.log(err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to delete screen',
      );
    } finally {
      setItemIdLoading(undefined);
    }
  }, [getScreens, deleteScreen]);

  const handleScreenUpdate = React.useCallback(async () => {
    try {
      setItemIdLoading(activeEditingRow.id);
      await updateScreen(activeEditingRow);
      const reps = await getScreens();
      setScreens(reps);
      setActiveEditingRow(false);
      showNotificationMessage(
        "top",
        "success",
        'Screen has been successfully updated',
      );
    } catch (err) {
      loggerService.error('failed to update screen', err);
      showNotificationMessage(
        "top",
        "error",
        err?.response?.data?.message || 'Failed to update screen',
      );
    } finally {
      setItemIdLoading(undefined);
    }
  }, [getScreens, updateScreen, activeEditingRow]);

  const renderActions = React.useCallback((item) => {
    if (itemIdLoading === item.id) {
      return (
        <Spin size="small" indicator={<LoadingOutlined />} />
      );
    }

    return (
      <Row className="configurable-ui-management__screens__actions">
        {activeEditingRow?.id === item.id 
          ? <SaveIcon onClick={() => handleScreenUpdate(item)} />
          : <EditOutlined onClick={() => setActiveEditingRow(item)} />}
        <PopConfirm
          title="Delete Screen?"
          onConfirm={() => handleScreenDelete(item)}
        >
          <DeleteOutlined className="configurable-ui-management__screens__delete-icon" />
        </PopConfirm>
      </Row>
    );
  }, [handleScreenDelete, handleScreenUpdate, activeEditingRow?.id, itemIdLoading]);

  const columns = [
    {
      width: '45%',
      id: 'screen-name',
      render: renderEditableCell,
      fieldName: 'subroute'
    },
    {
      width: '45%',
      id: 'dashboard-name',
      fieldName: 'dashboardName',
    },
    {
      width: '10%',
      id: 'actions',
      render: renderActions
    }
  ];

  return (
    <Table 
      loading={loading}
      getData={getScreensLocal}
      data={screens}
      columns={columns}
    />
  );
};