import { Col, Row, Spin } from "antd";
import React from "react";
import { AspectRatio } from "./components/aspect-ratio/AspectRatio";

import { DisplayGrid } from "./molecules/display-grid/DisplayGrid";
import {
  GenerateContextProvider,
  useGenerateContext,
} from "./contexts/generate-context/GenerateContext";
import { GenerateImagesSidebar } from "./molecules/generate-images-sidebar/GenerateImagesSidebar";
import { ImageEditor } from "./molecules/image-editor/ImageEditor";
// import { GenerateImagesWidgets } from "./molecules/generate-images-widgets/GenerateImagesWidgets";
import { Stage } from "./molecules/stage/Stage";

export function Timer() {
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 0.1);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return <div>{seconds.toFixed(1)}</div>;
}

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <GenerateContextProvider>
      <DynamicImages />
    </GenerateContextProvider>
  );
};

export const DynamicImages = () => {
  const generateContext = useGenerateContext();

  const height = `calc(111vh - 71px)`;

  return (
    <div
      style={{
        maxWidth: "99.5%",
        marginLeft: "auto",
        height,
        marginTop: -22,
        marginBottom: -23,
      }}
    >
      <Row gutter={[0, 0]}>
        <Col span={6}>
          <div style={{ height }}>
            <GenerateImagesSidebar />
          </div>
        </Col>
        <Col span={18}>
          <Stage>
            <div style={{ paddingLeft: 20 }}>
              {generateContext.loading && (
                <Row gutter={[15, 15]}>
                  {new Array(4).fill(0).map((a, i) => (
                    <Col key={i}
                      span={
                        24 /
                        (generateContext.aspectRatio.value === "16:10" ? 2 : 4)
                      }
                    >
                      <AspectRatio
                        width={parseInt(
                          generateContext.aspectRatio.value.split(":")[0]
                        )}
                        height={parseInt(
                          generateContext.aspectRatio.value.split(":")[1]
                        )}
                      >
                        <div
                          style={{
                            height: "100%",
                            backgroundColor: "rgba(255,255,255,0.1)",
                            borderRadius: 20,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spin />
                          <Timer />
                        </div>
                      </AspectRatio>
                    </Col>
                  ))}
                </Row>
              )}
              <DisplayGrid />
            </div>
          </Stage>
        </Col>
      </Row>
      <ImageEditor />
    </div>
  );
};
