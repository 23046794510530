import * as ExecutiveService from "@xai/services/executive";

class ExecutiveServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getExperimnetsRadarChartData(data) {
    const result = await ExecutiveService.getExperimnetsRadarChartData(
      data,
      this.authToken
    );
    // console.log("getExperimnetsRadarChartData", result);
    if (!result) return null;
    return result;
  }

  async getExecutiveAtAGlanceKPIMetrics(data) {
    const result = await ExecutiveService.getExecutiveAtAGlanceKPIMetrics(
      data,
      this.authToken
    );
    if (!result) return null;
    return result;
  }

  async getExecutiveAtAGlanceExperimentListTable(data) {
    const result = await ExecutiveService.getExecutiveAtAGlanceExperimentListTable(
      data,
      this.authToken
    );
    if (!result) return null;
    return result;
  }

  async getExecutiveAtAGlanceTwobyTwoChart(data) {
    const result = await ExecutiveService.getExecutiveAtAGlanceTwobyTwoChart(
      data,
      this.authToken
    );
    if (!result) return null;
    return result;
  }

}

export default ExecutiveServiceHelper;
