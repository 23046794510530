import React, { useState, useEffect } from "react";
import { notification } from "@pai-ui/core/components/notification";
import { Tabs } from "@pai-ui/core/components/tabs";
import { Button } from "@pai-ui/core/components/button";
import { Space } from "@pai-ui/core/components/space";
import { InputSearch } from "@pai-ui/core/components/input";
import { Cascader } from "@pai-ui/core/components/cascader";
import { UserContext } from "@xai/providers/with-auth";
// import { Grid, GridItem } from "@xai/library/containers/grid";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";
import { isArrayOfObjectsEqual } from "@xai/pages/transformer";
// import * as Comps from "./components";
import { DesignVariantBankApprovalTable } from "../design-variant-bank-approval-table";
import { getVariantBankListConfig } from "./transformer";
import {
  getTableDataTransformer,
  postTableDataTransformer,
} from "@xai/pages/design-variant-bank-approval/components/design-variant-bank-approval-table/transformer";

const tabMenu = [
  {
    tab: "Draft Variants",
    id: "draftVariants",
  },
  {
    tab: "Approved Variants",
    id: "approvedVariants",
  },
];

notification.config({
  duration: 3,
  maxCount: 1,
});

// event listener to block browser tab refresh or tab closing.
const unloadCallback = (event) => {
  event.preventDefault();
  event.returnValue = "";
  return "";
};

export const DesignVariantBankApprovalTabs = ({
  setSegregatedVariantBankData,
  setShowDialog,
  confirmNavigation,
  saveProceed,
}) => {
  const { token, experimentModule, user } = React.useContext(UserContext);
  const service = new DesignVariantBankApprovalServiceHelper(token);

  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;

  const [tagFilters, setTagFilters] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const setTagFilterOptions = async (dataSet) => {
    let cascaderOptions = ExperimentTagGroups?.map((tagGroup) => {
      return {
        value: tagGroup?.tag_group,
        label: tagGroup?.tag_group,
        children: [],
      };
    });

    dataSet?.forEach((dataSetItem) => {
      // variantTitleTags ,
      dataSetItem?.variantTitleTags?.forEach((tagItem) => {
        let tagItemVal = tagItem?.name;
        let cascaderOptionsItem = cascaderOptions?.find(
          (item) => item.value.toLowerCase() === tagItem.tag_group.toLowerCase()
        );
        // Check and push
        cascaderOptionsItem?.children?.indexOf(tagItemVal) === -1 &&
          cascaderOptionsItem?.children?.push(tagItemVal);
      });

      // variantBodyContentTags
      dataSetItem?.variantBodyContentTags?.forEach((tagItem) => {
        let tagItemVal = tagItem?.name;
        let cascaderOptionsItem = cascaderOptions?.find(
          (item) => item.value.toLowerCase() === tagItem.tag_group.toLowerCase()
        );
        // Check and push
        cascaderOptionsItem?.children?.indexOf(tagItemVal) === -1 &&
          cascaderOptionsItem?.children?.push(tagItemVal);
      });
    });

    cascaderOptions = cascaderOptions?.map((tagItem) => {
      tagItem.children = tagItem?.children?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      return tagItem;
    });

    // console.log(
    //   "setTagFilterOptions",
    //   dataSet,
    //   ExperimentTagGroups,
    //   cascaderOptions
    // );
    setTagFilters(cascaderOptions);
  };

  const [tableData, setTableData] = useState({
    approvedDataList: [],
    modifiedApprovedDataList: [],
    cancelledDataList: [],
    modifiedCancelledDataList: [],
    draftDataList: [],
    modifiedDraftDataList: [],
    deployedDataList: [],
    deactivatedDataList: [],
  });

  const [variantBankApprovalListData, setVariantBankApprovalListData] =
    useState({
      data: null,
      loading: true,
      error: false,
    });

  const [activeTab, setActiveTab] = useState(tabMenu[0].id);
  const onTabChange = (key) => {
    // console.log("onTabChange : Results, Selected tab : ", key);
    setActiveTab(key);
  };

  const seperateDataSetMethod = async (dataSet) => {
    let seperateDataset = {
      approvedDataList: [],
      cancelledDataList: [],
      draftDataList: [],
      deployedDataList: [],
      deactivatedDataList: [],
    };
    await dataSet?.forEach((dataSetData) => {
      if (
        ["liked", "approved"].indexOf(
          dataSetData?.action_status?.toLowerCase()
        ) > -1
      ) {
        seperateDataset.approvedDataList.push(dataSetData);
      } else if (dataSetData?.action_status?.toLowerCase() === "cancelled") {
        seperateDataset.cancelledDataList.push(dataSetData);
      } else if (dataSetData?.action_status?.toLowerCase() === "deployed") {
        seperateDataset.deployedDataList.push(dataSetData);
      } else if (dataSetData?.action_status?.toLowerCase() === "deactivated") {
        seperateDataset.deactivatedDataList.push(dataSetData);
      } else {
        seperateDataset.draftDataList.push(dataSetData);
      }
    });

    console.log("seperateDataset", seperateDataset, tableData);

    let {
      approvedDataList,
      // cancelledDataList,
      draftDataList,
      // deployedDataList,
      // deactivatedDataList,
    } = seperateDataset;

    approvedDataList = approvedDataList?.sort((a, b) =>
      // a.action_status.localeCompare(b.action_status)
      {
        if (a?.action_status === null) {
          return -1;
        } else if (b?.action_status === null) {
          return 1;
        } else {
          return b?.action_status.localeCompare(a?.action_status); // descending order for approved tab
        }
      }
    );
    draftDataList = draftDataList?.sort((a, b) =>
      // a.action_status.localeCompare(b.action_status)
      {
        if (a?.action_status === null) {
          return -1;
        } else if (b?.action_status === null) {
          return 1;
        } else {
          return a?.action_status.localeCompare(b?.action_status); // // ascending order for approved tab
        }
      }
    );

    let finalTableData = await {
      // approvedDataList: seperateDataset.approvedDataList,
      // modifiedApprovedDataList: seperateDataset.approvedDataList,
      approvedDataList: approvedDataList,
      modifiedApprovedDataList: approvedDataList,
      cancelledDataList: seperateDataset.cancelledDataList,
      modifiedCancelledDataList: seperateDataset.cancelledDataList,
      // draftDataList: seperateDataset.draftDataList,
      // modifiedDraftDataList: seperateDataset.draftDataList,
      draftDataList: draftDataList,
      modifiedDraftDataList: draftDataList,
      deployedDataList: seperateDataset.deployedDataList,
      deactivatedDataList: seperateDataset.deactivatedDataList,
    };
    setTableData(() => {
      return finalTableData;
    });

    return await finalTableData;
  };

  const getModifiedDataItems = async (array1, array2) => {
    return await array1?.filter(
      (object1) =>
        !array2.some((object2) => {
          return (
            // Add custom filtering conditions here
            object1.subjectLine === object2.subjectLine &&
            object1.bodyCopy === object2.bodyCopy &&
            object1.action_status === object2.action_status &&
            object1.comments === object2.comments
          );
        })
    );
  };

  // get Final modifiedDataset and differenceInDataset
  const getModifiedAndDifferenceInDataset = async (tableDataProp) => {
    const modifiedDataset = await [].concat(
      tableDataProp?.draftDataList,
      tableDataProp?.approvedDataList,
      tableDataProp?.cancelledDataList,
      tableDataProp?.deployedDataList,
      tableDataProp?.deactivatedDataList
    );

    let differenceInDataset = await getModifiedDataItems(
      modifiedDataset,
      variantBankApprovalListData?.data
    );
    return {
      modifiedDataset,
      differenceInDataset,
    };
  };

  /** Finalize changes - Button State */
  const [approveButtonStatus, setApproveButtonStatus] = useState(false);

  const [tabButtonStatus, setTabButtonStatus] = useState({
    reset: false,
    save: false,
  });

  const resetDatasetChanges = async (/* updatedData */) => {
    // reset Data

    let tableData_onReset = {
      ...tableData,
      approvedDataList: tableData.approvedDataList,
      modifiedApprovedDataList: tableData.approvedDataList,
      cancelledDataList: tableData.cancelledDataList,
      modifiedCancelledDataList: tableData.cancelledDataList,
      draftDataList: tableData.draftDataList,
      modifiedDraftDataList: tableData.draftDataList,
    };

    let { differenceInDataset } = await getModifiedAndDifferenceInDataset(
      tableData_onReset
    );

    // console.log("modifiedDataset, differenceInDataset", differenceInDataset, differenceInDataset?.length);

    setTableData(() => {
      return tableData_onReset;
    });

    // disable reset, save buttons and enable approve button based on differenceInDataset array length
    setTabButtonStatus(() => {
      return {
        reset: false,
        save: differenceInDataset?.length,
      };
    });

    // if there is no difference, then removeEventListener on reset.
    if (differenceInDataset?.length === 0) {
      // // Below code will Remove an event listener to prevent the browser tab to be closed
      window.removeEventListener("beforeunload", unloadCallback);
      setShowDialog(false);
    }
  };

  const approveFinalChanges = async (finalTableData) => {
    setApproveButtonStatus(true);
    /**
     * 1. Find updated items with original data set
     * 2. on successfull approve response
     *     1. update original dataset as well
     *     2. update button status
     */

    let { modifiedDataset, differenceInDataset } =
      await getModifiedAndDifferenceInDataset(finalTableData);

    if (differenceInDataset?.length > 0) {
      // add/update approval date to approved items
      let approvedDateVal = new Date().toISOString();
      differenceInDataset = differenceInDataset.map((dataItem) => {
        let actualDataItem = variantBankApprovalListData?.data?.find(
          (x) => x.variantbankId === dataItem.variantbankId
        );
        // Check if action_status has changed
        if (
          actualDataItem?.action_status?.toLowerCase() !==
          dataItem?.action_status?.toLowerCase()
        ) {
          // for action_status change track action changes
          if (dataItem?.action_status?.toLowerCase() === "approved") {
            dataItem.approvedDate = approvedDateVal;
          } else if (
            dataItem?.action_status?.toLowerCase() === "refused" ||
            dataItem?.action_status?.toLowerCase() === "cancelled"
          ) {
            dataItem.approvedDate = null;
          }
          dataItem?.experiment_variant_bank_user_logs?.push({
            userName: user?.name || "",
            email: user?.username || "",
            step: activeTab,
            action: dataItem?.action_status,
            time: approvedDateVal,
          });
        }

        return dataItem;
      });

      const finalDataSet = await postTableDataTransformer(differenceInDataset);

      console.log(
        "approveFinalChanges",
        modifiedDataset,
        differenceInDataset,
        finalDataSet
      );

      // #TODO : API Call to sync approved data changes with DB Table
      await service
        .bulkUpdateExperimentVarianBankList(finalDataSet)
        .then((res) => {
          console.log("bulkUpdateExperimentVarianBankList res", res);
          // on successfull approval, update original dataset with modifiedDataset
          setSegregatedVariantBankData(finalTableData);
          setVariantBankApprovalListData((prevState) => {
            return {
              ...prevState,
              loading: false,
              data: modifiedDataset,
            };
          });
          notification.success({
            message: "Variant Changes are Saved",
            placement: "topRight",
          });
        })
        .catch((err) => {
          console.log("bulkUpdateExperimentVarianBankList Error", err);

          // On error show toaster that notifies user about failure on approve click.
          notification.error({
            message: "Failed to save Variant changes",
            placement: "topRight",
          });
        })
        .finally(() => {
          setApproveButtonStatus(false);
          // disable save and approve buttons
          setTabButtonStatus(() => {
            return {
              reset: false,
              save: false,
            };
          });
        });
    }

    // // Below code will Remove an event listener to prevent the browser tab to be closed
    window.removeEventListener("beforeunload", unloadCallback);
    setShowDialog(false);
  };

  const saveDatasetChanges = async (/* updatedData */) => {
    // On save, update the original TableData with modified TableData

    const modifiedDataset = await [].concat(
      tableData.modifiedDraftDataList,
      tableData.modifiedApprovedDataList,
      tableData.modifiedCancelledDataList,
      tableData.deployedDataList,
      tableData.deactivatedDataList
    );

    let finalTableData = await seperateDataSetMethod(modifiedDataset);

    setTimeout(async () => {
      await approveFinalChanges(finalTableData);
    }, 300);
  };

  /**  */
  const [globalSearchText, setGlobalSearchText] = useState("");
  const onGlobalSearch = async (value) => {
    console.log("onGlobalSearch", value);
    setGlobalSearchText(value);
  };

  const tabBarExtraContent = (
    <div
      style={{ paddingBottom: "7px", display: "flex", flexDirection: "row" }}
    >
      <Space>
        <InputSearch
          placeholder="Input Search text and press Enter"
          allowClear
          style={{ width: "295px" }}
          onSearch={onGlobalSearch}
        />
        <Cascader
          withSearch
          multiple
          value={selectedTags}
          onChange={setSelectedTags}
          options={tagFilters}
          placeholder="Select Tags"
          style={{ width: "270px" }}
          maxTagCount={"responsive"}
        />
        <Button
          ghost
          onClick={() => resetDatasetChanges()}
          disabled={!tabButtonStatus?.reset || approveButtonStatus}
        >
          Reset
        </Button>
        <Button
          // ghost
          onClick={() => saveDatasetChanges()}
          disabled={!tabButtonStatus?.save}
          loading={approveButtonStatus}
        >
          Save
        </Button>
      </Space>
    </div>
  );

  /** */

  // Method to update data on Table save feature
  const updateTableData = (data) => {
    console.log(
      `data change on  tab ${activeTab}`,
      activeTab === "draftVariants"
        ? tableData?.modifiedDraftDataList
        : activeTab === "approvedVariants"
        ? tableData?.modifiedApprovedDataList
        : activeTab === "deployedVariants"
        ? tableData?.deployedDataList
        : activeTab === "deactivatedVariants"
        ? tableData?.deactivatedDataList
        : tableData,
      data
    );

    if (activeTab === "draftVariants") {
      setTableData((prevState) => {
        return {
          ...prevState,
          modifiedDraftDataList: data,
        };
      });
    } else if (activeTab === "approvedVariants") {
      setTableData((prevState) => {
        console.log(
          "approvedVariants",
          prevState.modifiedApprovedDataList,
          data,
          isArrayOfObjectsEqual(prevState.modifiedApprovedDataList, data)
        );
        return {
          ...prevState,
          modifiedApprovedDataList: data,
        };
      });
    }
  };

  const loadVariantBankList = async () => {
    // Reset State segregatedVariantBankData
    setSegregatedVariantBankData(null);

    // Reset State variantBankApprovalListData
    setVariantBankApprovalListData((prevState) => {
      return {
        ...prevState,
        data: null,
        loading: true,
        error: false,
      };
    });

    // api data config
    let dataconfig = getVariantBankListConfig(
      experimentModule?.selectedExperiment?.id
    );
    console.log("loadVariantBankList call", dataconfig);

    // api call
    await service
      .getExperimentVarianBankList(dataconfig)
      .then(async (res) => {
        console.log("loadVariantBankList res", res);
        // transform to table data structure
        let dataResponse = res?.items;
        dataResponse = await getTableDataTransformer(dataResponse);

        // Temp
        // setTimeout(async () => {
        let segregatedtableData = await seperateDataSetMethod(dataResponse);
        setSegregatedVariantBankData(segregatedtableData);
        setVariantBankApprovalListData((prevState) => {
          return {
            ...prevState,
            loading: false,
            data: dataResponse,
          };
        });
        setTagFilterOptions(dataResponse);
        // }, 600);
      })
      .catch((err) => {
        console.log("loadVariantBankList Error", err);
        setVariantBankApprovalListData((prevState) => {
          return {
            ...prevState,
            error: true,
          };
        });
      })
      .finally(() => {
        // Reset TabButtonStatus
        setTabButtonStatus(() => {
          return {
            reset: false,
            save: false,
          };
        });
      });
  };

  // load method to fetch data from backend for selected experiment
  const Load = async () => {
    loadVariantBankList();
  };

  useEffect(() => {
    console.log("DesignVariantBank", experimentModule);
    Load();
  }, [experimentModule?.selectedExperiment]);

  useEffect(() => {
    // data difference with base and updated data
    if (
      !isArrayOfObjectsEqual(
        tableData?.draftDataList,
        tableData?.modifiedDraftDataList
      ) ||
      !isArrayOfObjectsEqual(
        tableData?.approvedDataList,
        tableData?.modifiedApprovedDataList
      ) ||
      !isArrayOfObjectsEqual(
        tableData?.cancelledDataList,
        tableData?.modifiedCancelledDataList
      )
    ) {
      console.log(
        "tableData change",
        tableData,
        !isArrayOfObjectsEqual(
          tableData?.draftDataList,
          tableData?.modifiedDraftDataList
        ),
        !isArrayOfObjectsEqual(
          tableData?.approvedDataList,
          tableData?.modifiedApprovedDataList
        ),
        !isArrayOfObjectsEqual(
          tableData?.cancelledDataList,
          tableData?.modifiedCancelledDataList
        )
      );
      // enable reset, save and disable approave button.
      setTabButtonStatus(() => {
        return {
          reset: true,
          save: true,
        };
      });
      notification.success({
        message: "Click on Save Button to Save Changes",
        placement: "topRight",
      });
      setShowDialog(true);

      // Below code will add an event listener to prevent the browser tab to be closed
      // Ref - https://stackoverflow.com/questions/36355093/reactjs-browser-tab-close-event#:~:text=You%20can%20show%20a%20message,preventDefault()%3B%20return%20ev.

      window.addEventListener("beforeunload", unloadCallback);
      // Ref : https://stackoverflow.com/a/10364316/4672396
    }
  }, [tableData]);

  // below effect is suppose to take place when user tries to leave the page.
  useEffect(() => {
    if (saveProceed !== null) {
      saveDatasetChanges();
      setTimeout(() => {
        confirmNavigation();
      }, 2500);
    }
  }, [saveProceed]);

  return (
    <>
      <div className={"custom-tabs"} style={{ height: "100%" }}>
        {variantBankApprovalListData?.error ? (
          <LegacyNoData height={"85vh"} error={true}>
            <Button onClick={() => loadVariantBankList()}>Try Again</Button>
          </LegacyNoData>
        ) : variantBankApprovalListData?.loading ? (
          <LegacyNoData height={"85vh"} loading={true} />
        ) : variantBankApprovalListData?.data ? (
          <>
            <Tabs
              items={tabMenu}
              // style={{ marginTop: "-25px", marginLeft: "3px" }}
              defaultActiveKey={activeTab}
              onChange={onTabChange}
              tabBarExtraContent={tabBarExtraContent}
            />
            <>
              {["draftVariants", "approvedVariants"].indexOf(activeTab) >
                -1 && (
                <>
                  <DesignVariantBankApprovalTable
                    selectedTab={activeTab}
                    tableData={tableData}
                    updateTableData={updateTableData}
                    tabButtonStatus={tabButtonStatus}
                    globalSearchText={globalSearchText}
                    selectedTags={selectedTags}
                    tagFilters={tagFilters}
                    loadVariantBankList={loadVariantBankList}
                  />
                </>
              )}
            </>
          </>
        ) : (
          <LegacyNoData height={"85vh"} />
        )}
      </div>
    </>
  );
};

export default DesignVariantBankApprovalTabs;
