import * as DesignService from "@xai/services/experiment/Design";

class DesignVariantBankApprovalServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getExperimentVarianBankList(dataconfig) {
    const data = await DesignService.getExperimentVarianBankList(
      dataconfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async bulkUpdateExperimentVarianBankList(dataConfig) {
    const data = await DesignService.bulkUpdateExperimentVarianBankList(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async bulkUpsertExperimentVarianBankList(dataConfig) {
    const data = await DesignService.bulkUpsertExperimentVarianBankList(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async csvExportFeatureApi(dataconfig) {
    const data = await DesignService.csvExportFeatureApi(
      dataconfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

}

export default DesignVariantBankApprovalServiceHelper;
