import React from "react";
import { ConfigProvider } from "antd";
// import withAuth from "@xai/providers/with-auth";
import { withAuth } from "./providers/with-auth";
import LoadingCard from "@xai/pages/loading-card";
import "@xai/assets/generic-ant-d-components/style.less";
import "@xai/assets/generic-ant-d-components/product_antd_style.less";
import { SubjectLineFormConfigProvider } from "./context/SubjectLineFormConfig";
import { LayoutProvider } from "./providers/layout";

// const LoadingCard = React.lazy(() => import("./pages/loading-card"));

function App() {
  // console.log('user logged in --App: ', userState)

  const [criticalFontsLoaded, setCriticalFontsLoaded] = React.useState(false);

  React.useEffect(() => {
    const fonts = {
      critical: [
        {
          family: 'SF Display',
          weight: 200,
          src: 'SF-UI-Display-Light.otf'
        },
        {
          family: 'SF Display',
          weight: 400,
          src: 'SF-UI-Display-Regular.otf'
        },
        {
          family: 'SF Text',
          weight: 400,
          src: 'SF-UI-Text-Regular.otf',
        },
        {
          family: 'SF Text',
          weight: 200,
          src: 'SF-UI-Text-Light.otf',
        },
      ],
      notCritical: [
        {
          family: 'SF Display',
          weight: 700,
          src: 'SF-UI-Display-Bold.otf'
        },
        {
          family: 'SF Display',
          weight: 600,
          src: 'SF-UI-Display-Semibold.otf',
        },
        {
          family: 'SF Text',
          weight: 700,
          src: 'SF-UI-Text-Bold.otf',
        },
        {
          family: 'SF Text',
          weight: 700,
          style: 'italic',
          src: 'SF-UI-Text-BoldItalic.otf',
        },
        {
          family: 'SF Text',
          weight: 200,
          style: 'italic',
          src: 'SF-UI-Text-LightItalic.otf',
        },
        {
          family: 'SF Text',
          weight: 600,
          src: 'SF-UI-Text-Semibold.otf',
        },
        {
          family: 'SF Text',
          weight: 600,
          style: 'italic',
          src: 'SF-UI-Text-SemiboldItalic.otf',
        }
      ],
    };

    const loadAndAddFont = async (fontConfig) => {
      try {
        const font = new FontFace(
          fontConfig.family,
          `url(${process.env.REACT_APP_CLOUDFRONT_CDN}/fonts/${fontConfig.src})`,
          {
            weight: fontConfig.weight,
            style: fontConfig.style
          }
        );

        await font.load();
        document.fonts.add(font);
      } catch (err) {
        console.error('ERROR_LOADING_FONTS', err, fontConfig);
      }
    };

    Promise.all(
      fonts.critical.map(loadAndAddFont)
    ).then(() => {
      setCriticalFontsLoaded(true);

      const timeToWaitUntilAllCriticalAssetsAreLoaded = 500;

      setTimeout(() => {
        fonts.notCritical.forEach(loadAndAddFont);
      }, timeToWaitUntilAllCriticalAssetsAreLoaded);
    });
  }, []);

  return (
    <ConfigProvider prefixCls="xai">
      {criticalFontsLoaded
        ? (
          <React.Suspense fallback={null}>
            <SubjectLineFormConfigProvider>
              <LayoutProvider>
                <LoadingCard />
              </LayoutProvider>
            </SubjectLineFormConfigProvider>
          </React.Suspense>
        )
        : (
          <div
            style={{
              zoom: 1.1,
              backgroundColor: '#0d0f13',
              height: '100vh',
              width: '100vw',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#181b20',
                borderRadius: 10,
                width: 200,
                height: 150,
                color: 'grey',
                fontFamily: 'sans-serif',
                padding: 12,
              }}
            >
              Loading App
            </div>
          </div>
        )
      }
    </ConfigProvider>
  );
}

export default withAuth(App);
