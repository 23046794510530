import React from "react";
import withExperiment from "@xai/providers/with-experiment";
import TestLibraryListing from "./components/test-library-listing";

const MeasureTestLibrary = (props) => {
  const {
    handleSetFavorite,
    onMarkFavFinishLoad,
  } = props;
  return (
    <div>
      <TestLibraryListing
        handleSetFavorite={handleSetFavorite}
        onMarkFavFinishLoad={onMarkFavFinishLoad}
      />
      {/* <span>MeasureTestLibrary</span> */}
    </div>
  );
};

export default withExperiment(MeasureTestLibrary);
