import React from "react";
// import { UserContext } from "@xai/providers/with-auth";
import { Grid, GridItem } from "@xai/library/containers/grid";
import SummaryCardList from "./components/summary-card-list";
import * as Comps from "./components";

const MeasureResultsSummaryTab = ({
  summaryData,
  setSummaryData,
  selectedVariants,
  currentScreen,
  loadSummaryTabTwoByTwoChartData,
  loadSummaryTabCardsData,
  selectedDateRangeVal,
  selectedAudience,
  endOfExportFileName,
}) => {
  // const userState = React.useContext(UserContext);
  // console.log("MeasureResultsTab", userState.experimentModule);

  return (
    <>
      <Grid
        template={[
          [
            ...Array(12)
              .fill()
              .map(() => "two-by-two-chart"),
            ...Array(12)
              .fill()
              .map(() => "variant-table-list"),
          ],
          [
            ...Array(24)
              .fill()
              .map(() => "summary-card-list"),
          ],
        ]}
      >
        <GridItem area="summary-card-list">
          <SummaryCardList
            summaryData={summaryData?.card?.data}
            error={summaryData?.card?.error}
            loading={summaryData?.card?.loading}
            setSummaryData={setSummaryData}
            selectedVariants={selectedVariants}
            loadSummaryTabCardsData={loadSummaryTabCardsData}
            selectedDateRangeVal={selectedDateRangeVal}
            selectedAudience={selectedAudience}
          />
        </GridItem>
        <GridItem area="two-by-two-chart">
          {currentScreen === "results" ? (
            <Comps.TwoByTwoChart
              chartData={summaryData?.twoByTwoChart?.data}
              error={summaryData?.twoByTwoChart?.error}
              loading={summaryData?.twoByTwoChart?.loading}
              loadSummaryTabTwoByTwoChartData={loadSummaryTabTwoByTwoChartData}
              selectedDateRangeVal={selectedDateRangeVal}
              selectedVariants={selectedVariants}
              selectedAudience={selectedAudience}
              endOfExportFileName={endOfExportFileName}
            />
          ) : (
            <></>
          )}
        </GridItem>
        <GridItem area="variant-table-list">
          {currentScreen === "results" ? (
            <Comps.VariantTableList
              tableData={summaryData?.card?.data}
              error={summaryData?.card?.error}
              loading={summaryData?.card?.loading}
              selectedVariants={selectedVariants}
              loadSummaryTabCardsData={loadSummaryTabCardsData}
              selectedDateRangeVal={selectedDateRangeVal}
              selectedAudience={selectedAudience}
              endOfExportFileName={endOfExportFileName}
            />
          ) : (
            <></>
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default MeasureResultsSummaryTab;
