/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  Link,
  Outlet,
  NavLink
} from "react-router-dom";
import axios from "axios";
import {
  Container,
  Body,
  Logo,
  LogoName,
  SubMenuItem,
  ScrollContainer,
  Sidebar,
  PageContainer,
  MenuText,
  LogoStatic,
  LogoDynamic,
} from "./styles";
import { FeedbackProps } from "./config";
import ReactDOM from "react-dom";
import Menu from "../../../assets/generic-components/menu";
import { Typography } from "@xai/library/base";
import { useCommonServiceHelper } from "@xai/library/utils/common-service-helper";
import LandingPage from "../../../pages/landing-page";
import * as Components from "./../../../assets/generic-components/index";
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import { UserOutlined } from "@ant-design/icons";
import { Menu as Hamburger } from "@styled-icons/boxicons-regular/Menu";
import { Wrapper } from "../../../assets/generic-components/visualization/components";
import { UserContext } from "@xai/providers/with-auth";
import LoadingSkeleton from "../../../assets/generic-components/loading-skeleton/index";
import { Cached } from "@styled-icons/material/Cached";
import { showNotificationMessage } from "../../../assets/generic-components/toaster-message/index";

import { Avatar } from "@pai-ui/core/components/avatar";
import { Space } from "@pai-ui/core/components/space";
import { Popover } from "@pai-ui/core/components/popover";
import { Select } from "@pai-ui/core/components/select";
import { SignOutButton } from "@xai/providers/with-auth";
import { Result } from "@pai-ui/core/components/result";

const feedbackHRef = `mailto:${FeedbackProps.mailId}?subject=${FeedbackProps.defaultSubj}
&body=Page: ${window.location.pathname}%0DFeedback:%0D%0D%0DScreenShot:`;

const clearCache = () => {
  axios
    .post(
      `${process.env.REACT_APP_MICROSERVICE_HOST}/clear-cache`,
      {
        endpoints: [],
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          Authorization: "Bearer",
        },
      }
    )
    .then((response) => {
      showNotificationMessage(
        "topRight",
        "success",
        "Cache Cleared Successfuly"
      );
      console.log("cache cleared", response);
    })
    .catch((err) => {
      showNotificationMessage("topRight", "error", "Failed to clear cache");
      console.log("error in clearing cache", err);
    });
};

export function PageComponent({
  i,
  s,
  setCollapsed,
  collapsed,
  clearCache,
  user,
  pageLoader,
  navigationDiv,
}) {
  return (
    <Body>
      {navigationDiv &&
        ReactDOM.createPortal(
          i.subMenu &&
          i.subMenu.map((s, subMenuIndex) => {
            const ActionComponent = Components[s.componentKey];
            if (s.type === "Component" && ActionComponent) {
              return <ActionComponent key={subMenuIndex} />;
            }

            return (
              s.name !== "" && (
                <NavLink
                  className="submenuitem"
                  to={s.linked ? "../" + s.linked : undefined}
                  style={({ isActive }) => ({
                    color: isActive ? "#15afd0" : "rgba(255, 255, 255, 0.5)",
                    padding: "0.8rem 1rem",
                    margin: "0px 0.188rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderTop: "2px solid transparent",
                    transition: "background ease 500ms",
                    cursor: "pointer",
                    overflow: "hidden",
                    textDecoration: "none",
                    marginBottom: "2px",
                  })}
                >
                  <p
                    style={{
                      fontFamily: "SF Text",
                      fontSize: "14px",
                      fontWeight: 200,
                      flex: 1,
                      margin: 0,
                    }}
                  >
                    {s.name}
                  </p>
                </NavLink>
              )
            );
          }),
          navigationDiv
        )}
      {s.key && (
        <PageContainer id="page-container">
          <React.Suspense
            fallback={
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingSkeleton />
              </div>
            }
          >
            {pageLoader && pageLoader({ pageName: s.key, isSuperSet: s.isSupersetRoute, dashboardId: s.dashboardId })}
          </React.Suspense>
        </PageContainer>
      )}

      {s.url && (
        <Wrapper>
          {({ width, height }) => (
            <React.Fragment>
              <iframe
                title="abc"
                style={{ border: 0, zIndex: -10000 }}
                src={s.url}
                width={width}
                height={height}
              ></iframe>
              <div
                style={{
                  border: "solid 1px #14afd0",
                  background: "#111419",
                  fontSize: 20,
                  borderRadius: "5px",
                  color: "#14afd0",
                  padding: "10px 15px",
                  textAlign: "middle",
                  position: "absolute",
                  top: "90%",
                  right: "20px",
                }}
              >
                WIP Screen. Coming soon...
              </div>
            </React.Fragment>
          )}
        </Wrapper>
      )}
    </Body>
  );
}

export function DefaultPage({
  setCollapsed,
  collapsed,
  user,
  divRef,
  navigationDiv,
  setNavigationDiv,
}) {
  useEffect(function () {
    setNavigationDiv(divRef.current);
  }, []);
  return (
    <Body>
      <Logo
        faded
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
        }}
      >
        <div
          onClick={() => setCollapsed(!collapsed)}
          style={{
            padding: "0.8rem 0rem",
            margin: "0px 0.188rem",
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            opacity: 0.75,
          }}
        >
          <Hamburger color="#FFF" style={{ width: 20, height: 20 }} />
        </div>
        <div id="portal" ref={divRef} style={{ display: "flex" }}></div>
        <div style={{ marginLeft: "auto" }}></div>
        <Space direction="horizontal" align="center">
          <a href={feedbackHRef}>
            <img
              src={
                process.env.REACT_APP_CLOUDFRONT_CDN +
                "/images/landing/feedback.png"
              }
              width={25}
              alt={"icon"}
            />
          </a>
        </Space>
        <div style={{ marginLeft: 20 }}>
          <Popover
          // trigger={"click"}
          // title="Switch Role"
          // content={
          //     <Select
          //         value={user && user?.role && user?.role?.role?.id}
          //         onChange={(e) => {
          //             user?.dispatch({
          //                 type: "role",
          //                 payload: e,
          //             });
          //         }}
          //     >
          //         {user?.user?.accesses &&
          //             user?.user?.accesses.map((r, rIndex) => (
          //                 <Select.Option key={rIndex} value={r.role.id}>
          //                     {r.role.name}
          //                 </Select.Option>
          //             ))}
          //     </Select>
          // }
          // placement="bottomRight"
          >
            <MenuText>
              <Space
                direction="horizontal"
                align="center"
              >
                <Avatar>
                  {user?.user?.name
                    ?.split(" ")
                    ?.map((s) => s[0]) || (
                      <UserOutlined />
                    )}
                </Avatar>
                <div>
                  <Typography.Paragraph>
                    {user?.user?.name}
                  </Typography.Paragraph>
                  <Typography.Paragraph colorStyle="primary">
                    {user?.role?.role?.name}
                  </Typography.Paragraph>
                </div>
                {/* <CaretDownOutlined /> */}
              </Space>
            </MenuText>
          </Popover>
        </div>
        {process.env.REACT_APP_IS_AD_REQUIRED?.toLowerCase() ===
          "true" && (
            <div>
              <SignOutButton />
            </div>
          )}
      </Logo>
      <Outlet />
    </Body>
  );
}

export function PageSkeleton({
  menuConfig,
  user,
  pageLoader,
  collapsed,
  setCollapsed,
}) {
  const divRef = useRef(null);

  const [navigationDiv, setNavigationDiv] = useState(null);
  return (
    <>
      <Sidebar collapsed={collapsed}>
        <Link to=".">
          <Logo>
            <LogoName>
              <LogoStatic>P</LogoStatic>
              <LogoDynamic>ersonalize</LogoDynamic>
              <LogoStatic>
                <small>.</small>
                <span>AI</span>
              </LogoStatic>
            </LogoName>
          </Logo>
        </Link>
        <ScrollContainer>
          <Menu groups={menuConfig} linked />
        </ScrollContainer>
      </Sidebar>

      <Routes>
        <Route
          path="/"
          element={
            <DefaultPage
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              clearCache={clearCache}
              user={user}
              divRef={divRef}
              navigationDiv={navigationDiv}
              setNavigationDiv={setNavigationDiv}
            />
          }
        >
          {menuConfig
            .map((g) =>
              g.items.map((i, ind) => [
                <Route key={ind} path={i.linked}>
                  <Route index element={<Navigate to={i.defaultRoute} />} />
                  {i.subMenu.map((s, sIndex) => (
                    <Route
                      key={sIndex}
                      path={s.linked}
                      element={
                        <PageComponent
                          i={i}
                          s={s}
                          setCollapsed={setCollapsed}
                          collapsed={collapsed}
                          clearCache={clearCache}
                          user={user}
                          navigationDiv={navigationDiv}
                          pageLoader={pageLoader}
                        />
                      }
                    />
                  ))}
                </Route>,
              ])
            )
            .concat([<Route key={100} index element={<LandingPage />} />])}
        </Route>
      </Routes>
    </>
  );
}
