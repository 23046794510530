import React, { useState, useEffect } from "react";

import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import { Input } from '@pai-ui/core/components/input';
import { RuleBuilder, RuleFieldType } from '@pai-ui/core/components/rule-builder';
import { Select } from '@pai-ui/core/components/select';
import { message, Upload } from 'antd';
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons';
import {
  ZsSpace,
  ZsInput,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

const config = [
  {
    key: 'id',
    placeholder: 'Select value',
    type: RuleFieldType.select,
    options: [
      {
        id: 'annualspend',
        name: 'Annual Spend',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equals to',
              },
              {
                id: 'greater',
                name: 'is greater than',
              }


            ]
          },
          {
            type: RuleFieldType.numberField,
            placeholder: 'Enter Annual Spend',
            key: 'value',
            width: "200px"
          }
        ]
      },
      {
        id: 'lifecycle',
        name: 'Life Cycle Segments',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                width: "200px",
                name: 'is equals to',
                children: [{
                  type: RuleFieldType.select,
                  placeholder: 'Select Life Cycle Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'early',
                      name: 'Early Tenure',
                    },
                    {
                      id: 'stable',
                      name: 'Stable',
                    },
                    {
                      id: 'incliner',
                      name: 'Incliners',
                    },
                  ]

                }]
              },
              {
                id: 'any',
                name: 'is of any',
                width: "300px",
                children: [{
                  type: RuleFieldType.multiselect,
                  placeholder: 'Select Life Cycle Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'early',
                      name: 'Early Tenure',
                    },
                    {
                      id: 'stable',
                      name: 'Stable',
                    },
                    {
                      id: 'incliner',
                      name: 'Incliners',
                    },
                  ]

                }]
              },
            ]
          },

        ]
      },
      {
        id: 'businesssegment',
        name: 'Businness Segments',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equals to',
                children: [{
                  type: RuleFieldType.select,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'lunch',
                      name: 'Lunchtime Loyalists',
                    },
                    {
                      id: 'health',
                      name: 'Health Enthusiasts',
                    },
                  ]

                }]
              },
              {
                id: 'any',
                name: 'is of any',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "300px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'lunch',
                      name: 'Lunchtime Loyalists',
                    },
                    {
                      id: 'weekend',
                      name: 'Family Weekenders',
                    },
                  ]

                }]
              },
            ]
          },

        ]
      },
      {
        id: 'state',
        name: 'State',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equals to',
                children: [{
                  type: RuleFieldType.select,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'cal',
                      name: 'California',
                    },
                    {
                      id: 'ny',
                      name: 'New York',
                    },
                  ]

                }]
              },
              {
                id: 'any',
                name: 'is of any',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "300px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'cal',
                      name: 'California',
                    },
                    {
                      id: 'ny',
                      name: 'New York',
                    },
                  ]

                }]
              },
            ]
          },

        ]
      },
      {
        id: 'product',
        name: 'Product Preference',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is not',
                children: [{
                  type: RuleFieldType.select,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'sal',
                      name: 'Salad',
                    }

                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'store',
        name: 'Store Type',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is not',
                children: [{
                  type: RuleFieldType.select,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'fran',
                      name: 'Franchise',
                    }

                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'location',
        name: 'Store Location',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is not',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'time',
                      name: 'Times Square',
                    },
                    {
                      id: 'vegas',
                      name: 'Las Vegas Strip',
                    }

                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'revenue',
        name: 'Annual Revenue',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'great',
                name: 'is greater than',

              },
              {
                id: 'less',
                name: 'is less than',

              },

            ]
          },
          {
            type: RuleFieldType.numberField,
            placeholder: 'Enter Annual Revenue',
            key: 'value',
            width: "200px"
          }

        ]
      },
      {
        id: 'hours',
        name: 'Operating Hours',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equal to',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'ap',
                      name: '10a to 10p',
                    }
                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'msa',
        name: 'MSA',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equal to',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'angeles',
                      name: 'Los Angeles-Long Beach-Anaheim',
                    }
                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'device',
        name: 'Device',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equal to',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'desktop',
                      name: 'Desktop',
                    },
                    {
                      id: 'mobile',
                      name: 'Mobile',
                    }
                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'os',
        name: 'Operating System',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equal to',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [
                    {
                      id: 'ios',
                      name: 'iOS',
                    },
                    {
                      id: 'andriod',
                      name: 'Android',
                    }
                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'weeklyfeq',
        name: 'Weekly Frequency',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is equal to',
                children: [{
                  type: RuleFieldType.multiselect,
                  width: "200px",
                  placeholder: 'Select Business Segments',
                  key: 'value',
                  options: [

                    {
                      id: 'Weekday',
                      name: 'Weekday',
                    }
                  ]

                }]
              },

            ]
          },

        ]
      },
      {
        id: 'dayof',
        name: 'Day of Week Pref',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'equals',
                name: 'is less than',
              },
              {
                id: 'greater',
                name: 'is greater than',
              }


            ]
          },
          {
            type: RuleFieldType.numberField,
            placeholder: 'Enter Annual Spend',
            key: 'value',
            width: "200px"
          }
        ]
      },

    ]
  }
]
const config2 = [
  {
    key: 'id',
    id: 1,
    placeholder: 'Enter Variant Name',
    type: RuleFieldType.textField,
    width: "150px",

  },
  {
    key: 'testcontol',
    id: 3,

    type: RuleFieldType.select,
    options: [
      {
        id: 'test',
        name: 'Test',
      },
      {
        id: 'control',
        name: 'Control',
      }
    ]


  },
  {
    key: 'id1',
    id: 2,
    placeholder: 'Variant Percent',
    width: "150px",
    type: RuleFieldType.numberField,

  }
]
const config3 = [
  {
    key: 'id',
    placeholder: 'Select value',
    type: RuleFieldType.select,
    options: [
      {
        id: 'Weekly Frequency',
        name: 'Weekly Frequency',
        children: [
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'Weekly Frequency',
                name: 'Weekly Frequency',
              }
            ]
          },
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'Weekly Frequency',
                name: 'Weekly Frequency',
              }
            ]
          },
          {
            placeholder: 'Select value',
            type: RuleFieldType.select,
            key: 'operator',
            options: [
              {
                id: 'Weekly Frequency',
                name: 'Weekly Frequency',
              }
            ]
          }
        ]
      },
    ]
  }
]

const DrawerComponent = (item, type) => {
  console.log('aayushnoode', item, type)
  const [value, setValue] = useState(1)
  const [technique, selectedTechnique] = useState()
  const [variable, setvariable] = useState()


  const [loading, setLoading] = useState(false);
  const [imageUrl, /* setImageUrl */] = useState();

  useEffect(() => {


    console.log('ietemmm', item)
    if (item.type === "variant") {

      setValue(2)

    }
    if (item.type === "touchpoint")
      setValue(3)

    if (item.view !== "new") {
      selectedTechnique(item.item.technique ? item.item.technique : 1)

      setvariable(item.item.variable ? item.item.variable : 1)
    }


  }, [])

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <CloudUploadOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Document
      </div>
    </div>
  );

  return (
    <>
      {value == 1 ?
        (
          <>
            <Row style={{ paddingTop: "15px" }}>
              <Col span={8} >
                Audience Name
              </Col>
              <Col span={12} offset={2}>
                <Input value={item.view == "new" ? "" : item.item.name} />
              </Col>

            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Col span={8}>
                Rules
              </Col>

            </Row>
            <Row>
              <Row style={{ paddingTop: "15px" }}>
                <RuleBuilder
                  // ruleInputPlaceholder="Custom placeholder"
                 
                  config={config}

                />
              </Row>
            </Row>
          </>
        ) :
        value === 3 ? (
          <>
            {console.log("aayushnoode", item.item.touchpoint.name)}
            <Row style={{ marginTop: "15px" }}>
              <Col span={8}>
                Touchpoint Name
              </Col>
              <Col span={8}>
                <ZsInput value={item.item.touchpoint.name} />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col span={8}>
                Rules
              </Col>

            </Row>
            <Row>
              <Row style={{ paddingTop: "15px" }}>
                <RuleBuilder
                  ruleInputPlaceholder="Add Rule Relationship Ex : {(R1) AND (R2) OR (R3)}"
                 
                  config={config3}

                />
              </Row>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col span={8} style={{ marginTop: "5px" }}>
                Time Interval
              </Col>
              <Col span={2}>
                <ZsInput defaultValue={"3"} />
              </Col>
              <Col span={6} style={{ marginLeft: "15px" }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select item"
                  defaultValue="Days"
                  options={[
                    {
                      id: 1,
                      name: 'Days'
                    },
                    {
                      id: 2,
                      name: 'Weeks'
                    },
                    {
                      id: 3,
                      name: 'Months'
                    },
                    {
                      id: 4,
                      name: 'Years'
                    }
                  ]}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col span={8} style={{ marginTop: "5px" }}>
                Channel
              </Col>
              <Col span={8}>
                <Select
                  style={{ width: "110%" }}
                  placeholder="Select item"
                  defaultValue="App Push"
                  options={[
                    {
                      id: 1,
                      name: 'App Push'
                    }
                  ]}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col span={8} style={{ marginTop: "5px" }}>
                Content
              </Col>
              <Col span={8}>
                <Input value="Welcome Message" style={{ width: "110%", height: "25px" }} />
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      // src={imageUrl}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Col>
            </Row>
          </>
        )
          : (
            <>

              <Row style={{ paddingTop: "15px" }}>
                <Col span={8}>
                  Test Groups
                </Col>

              </Row>
              <Row>
                <Row style={{ paddingTop: "15px" }}>
                  <RuleBuilder
                    // ruleInputPlaceholder="Custom placeholder"
                    config={config2}
                    

                  />
                </Row>
              </Row>
              <Row style={{ paddingTop: "15px" }}>
                <Col span={8} >
                  Randomization​ Technique
                </Col>
                <Col span={14} offset={2}>
                  <Select
                    placeholder="Select Randomization​ Technique"
                    value={technique}
                    options={[
                      {
                        id: 1,
                        name: 'Stratified Sampling'
                      },
                      {
                        id: 2,
                        name: 'Weighted Sampling'
                      },
                      {
                        id: 3,
                        name: 'Simple Random Sampling'
                      },

                    ]}
                    onChange={selectedTechnique}
                    style={{ width: "250px" }}
                  />
                </Col>

              </Row>
              {(technique == 1 || technique == 2) &&
                <Row style={{ paddingTop: "15px" }}>
                  <Col span={8} >
                    Sampling Variables
                  </Col>
                  <Col span={14} offset={2}>
                    <Select
                      placeholder="Select  Sampling Variables"
                      value={variable}

                      maxTagCount={"responsive"}
                      options={[
                        {
                          id: 1,
                          name: 'Age'
                        },
                        {
                          id: 2,
                          name: 'Gender'
                        },
                        {
                          id: 3,
                          name: 'City'
                        },
                        {
                          id: 4,
                          name: 'State'
                        },
                        {
                          id: 5,
                          name: "New vs Returning User"
                        }

                      ]}
                      style={{ width: "250px" }}
                      onChange={setvariable}
                    />
                  </Col>

                </Row>}
              {technique == 2 &&
                (<Row>
                  {variable == 1 &&
                    <Col span={24}>
                      <ZsSpace>
                        {[
                          {
                            id: 1,
                            label: "01-10",
                            type: "Text",
                          },
                          {
                            id: 2,
                            label: "10-20",
                            type: "Text",
                          },
                          {
                            id: 3,
                            label: "20-30",
                            type: "Text",
                          },
                          {
                            id: 4,
                            label: "30-40",
                            type: "Text",
                          },
                        ].map((b, i) => (
                          <div key={i} style={{ display: "grid" }}>
                            {!b.hideLabel && (
                              <span style={{ color: "#0988ab" }}>{b.label}</span>
                            )}
                            <ZsInput
                              style={{
                                width: "90%",
                              }}
                              placeholder={"Enter %"}
                              defaultValue={item.item.age ? item.item.age[i] : ""}
                            />
                          </div>
                        ))}
                      </ZsSpace>
                    </Col>

                  }
                </Row>
                )}
              {technique == 2 &&
                (<Row>
                  {variable == 2 &&
                    <Col span={24}>
                      <ZsSpace>
                        {[
                          {
                            id: 1,
                            label: "Female",
                            type: "Text",
                          },
                          {
                            id: 2,
                            label: "Male",
                            type: "Text",
                          },
                          {
                            id: 3,
                            label: "Unknown",
                            type: "Text",
                          },

                        ].map((b,i) => (
                          <div key={i} style={{ display: "grid" }}>
                            {!b.hideLabel && (
                              <span style={{ color: "#0988ab" }}>{b.label}</span>
                            )}
                            <ZsInput
                              style={{
                                width: "90%",
                              }}
                              placeholder={"Enter %"}

                            />
                          </div>
                        ))}
                      </ZsSpace>
                    </Col>

                  }
                </Row>
                )}

            </>
          )}



    </>
  );
}
export default DrawerComponent;