import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Input } from "@pai-ui/core/components/input";
import { Select } from "@pai-ui/core/components/select";
import { Button } from "@pai-ui/core/components/button";
import { Modal } from "@pai-ui/core/components/modal";
import { Row as ZsRow } from "@pai-ui/core/components/row";
import { Col as ZsCol } from "@pai-ui/core/components/col";
import { notification } from "@pai-ui/core/components/notification";
import AccessControlServiceHelper from "../../services-helper";
import { isEmailValid } from "./transformer";

export const NewUserCard = ({
  allRoles,
  messages,
  setLoadingFlag,
  authtoken,
  hideNewUserCard,
  addNewUser,
  //loading,
}) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [roles, setRoles] = useState([]);
  const [addUserButton, setAddUserButton] = useState(true);
  const accessControlServiceHelper = new AccessControlServiceHelper(authtoken);

  useEffect(() => {
    if (username && roles.length && isEmailValid(email)) {
      setAddUserButton(false);
    } else {
      setAddUserButton(true);
    }
  }, [email, username, roles.length]);

  const showToasterMessage = (type, text) => {
    var placement = "top";
    notification[type]({
      message: text,
      placement,
    });
  };

  const handleAddUser = async () => {
    setLoadingFlag(true);
    hideNewUserCard();
    let response;
    let inputJson = {
      id: null,
      name: username,
      email,
      userRoles: allRoles.filter((role) => roles.includes(role.id)),
      is_Active: true,
    };

    try {
      response = await accessControlServiceHelper.upsertUser(inputJson);
      console.log("result from add user", response);
    } catch (error) {
      console.log(error);
    }
    if (response.data.error) {
      showToasterMessage("error", response.data.error);
    } else if (response.data.data.id) {
      addNewUser(response["data"]["data"]);
      showToasterMessage("success", "New user created successfully.");
    } else {
      showToasterMessage("error", "An error occurred.");
    }
    setLoadingFlag(false);
  };

  return (
    <div>
      <Modal
        title={"Add User"}
        visible={true}
        onCancel={() => {
          hideNewUserCard();
        }}
        footer={null}
        width={500}
        style={{ padding: "0px" }}
        bodyStyle={{
          background: "#111419",
          overflowY: "hidden",
          maxHeight: "90vh",
          padding: "15px 0",
        }}
      >
        <Form
          id="userForm"
          labelCol={{
            span: 5,
          }}
          size={"default"}
          layout={"horizontal"}
          validateMessages={messages}
          onReset={() => {
            setEmail("");
            setUsername("");
            setRoles([]);
          }}
        >
          <ZsRow>
            <ZsCol span={18} offset={3}>
              <Form.Item
                labelAlign="left"
                label="Name"
                style={{ color: "#15afd0" }}
                name="firstname"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  value={username}
                  autoComplete="off"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  // prefix={
                  //   <UserOutlined className="site-form-item-icon" />
                  // }
                  placeholder="Full Name"
                />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  style={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                name="role"
                label="Roles"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    marginBottom: "0px",
                    padding: 0,
                    fontSize: "21px !important",
                    width: "100%",
                  }}
                  size="middle"
                  options={allRoles.map((role) => ({
                    id: role.id,
                    name: role.role,
                  }))}
                  placeholder="Select Roles"
                  onChange={(e) => {
                    setRoles(e);
                  }}
                  allowClear
                ></Select>
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "7px",
                }}
              >
                <Button htmlType="reset" type="default">
                  Reset
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={handleAddUser}
                  disabled={addUserButton}
                  // loading={loading}
                >
                  Add User
                </Button>
              </div>
            </ZsCol>
          </ZsRow>
        </Form>
        <div
          style={{
            height: 12,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        />
      </Modal>
    </div>
  );
};

export default NewUserCard;
