import axios from "axios";
import { dotNetApi, javaDagApi } from "@xai/library/clients";

let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");

export const getAnalyzeResponseMetric = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetAnalyzeResponseMetricChart.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetAnalyzeResponseMetricChart dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetAnalyzeResponseMetricChart dataConfig", dataConfig);
      dotNetApi
        .post(
          `/Experiments/GetExperimentResponseMetric`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const getAnalyzeComparisionTable = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/AnalyzeComparisonTableCustom.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log(
            "GetExperimentAnalyzeReport dataConfig",
            dataConfig,
            data,
            authToken
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetExperimentAnalyzeReport dataConfig", dataConfig);
      dotNetApi
        .post(
          `/Experiments/GetExperimentAnalyzeReport`,
          dataConfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const markExperimentDefaultVersionConfig = (dataConfig, authToken) =>
  new Promise((resolve, reject) => {
    const { experiment_id, default_version_config } = dataConfig;
    if (mock) {
      // mock response for this api call
      let data = {
        id: experiment_id,
        default_version_config: default_version_config,
      };
      console.log(
        "ExperimentMasters default_version_config dataConfig",
        authToken,
        dataConfig,
        data
      );
      resolve(data);
    } else {
      console.log(
        "ExperimentMasters default_version_config dataConfig",
        dataConfig
      );
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
            experimentMasterUpdate(
              id: "${experiment_id}"
              experimentMaster: { defaultVersionConfig: ${default_version_config} }
            ) {
              id
              default_version_config: defaultVersionConfig
            }
          }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { experimentMasterUpdate } = res?.data?.data;

          console.log(
            "ExperimentMasters default_version_config data",
            // res,
            experimentMasterUpdate
          );
          resolve(experimentMasterUpdate);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const biasCorrectionDagTrigger = (dataConfig /* , authToken */) =>
  new Promise((resolve, reject) => {
    // const { experiment_id, start_time_kpi, end_time_kpi, request_type } =
    //   dataConfig;
    if (mock) {
      // sample mock response for this api call
      let data = {
        dag_created: true,
      };
      // let data = true;
      console.log("biasCorrectionDagTriggerdataConfig", dataConfig, data);
      setTimeout(() => {
        resolve(data);
      }, 5000);
    } else {
      console.log("biasCorrectionDagTriggerdataConfig", dataConfig);
      javaDagApi
        .post(`/triggerDAG`, dataConfig)
        .then((res) => {
          const { data } = res;
          console.log("javaDagApi", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);

          // // for testing
          // // resolve(true);
          // let data = {
          //   dag_created: true,
          // };
          // resolve(data)
        });
    }
  });

export const addExperimentTaskItem = (dataConfig /* , authToken */) =>
  new Promise((resolve, reject) => {
    const {
      experiment_id,
      experiment_name,
      end_time_kpi,
      created,
      created_by,
      prior_date_range,
      request_type,
      request_from_screen,
      start_time_kpi,
      version,
    } = dataConfig;

    if (mock) {
      // mock response for this api call
      let data = {
        id: "4eed636b-c87b-40c0-bded-7e4b5f690101", // Sample Id
        experiment_id,
        experiment_name,
        prior_date_range,
        start_time_kpi,
        end_time_kpi,
        request_from_screen,
        request_type,
        status: 4,
        is_active: true,
        created,
        created_by,
        bias_correction_required: false,
        version,
        bias_correction_report: null,
      };
      console.log(
        "addExperimentTaskItem dataConfig",
        /*  authToken, */ dataConfig,
        data
      );
      resolve(data);
    } else {
      console.log("addExperimentTaskItem dataConfig", dataConfig);
      dotNetApi
        .post(
          `/ExperimentTaskManagements`,
          {
            query: `mutation {
              experimentTaskManagementAdd(
                experimentTaskManagement: {
                  experimentId: "${experiment_id}"
                  experimentName: "${experiment_name ? experiment_name : "testing from UI"
              }"
                  priorDateRange: "${prior_date_range}"
                  startTimeKpi: "${start_time_kpi}"
                  endTimeKpi: "${end_time_kpi}"
                  requestFromScreen: "${request_from_screen}"
                  requestType: "${request_type}"
                  status: 4 ${
              // # corresponds to draft status, from UI always create task status as draft
              ""
              }
                  isActive: true  ${
              // # default flag status is true
              ""
              }
                  created: "${created}"
                  createdBy: "${created_by}"
                  biasCorrectionRequired: false  ${
              // # default flag status is false
              ""
              }
                  version: ${version}
                }
              ) {
                id
                experiment_id: experimentId
                experiment_name: experimentName
                prior_date_range: priorDateRange
                start_time_kpi: startTimeKpi
                end_time_kpi: endTimeKpi
                request_from_screen: requestFromScreen
                request_type: requestType
                status
                is_active: isActive
                created
                created_by: createdBy
                bias_correction_required: biasCorrectionRequired
                version
                bias_correction_report: biasCorrectionReport
              }
            }
            `,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { experimentTaskManagementAdd } = res?.data?.data;

          console.log(
            "experimentTaskManagementAdd data",
            experimentTaskManagementAdd
          );
          resolve(experimentTaskManagementAdd);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
