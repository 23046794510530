import * as AnalyzeService from "@xai/services/Analyze";
import { TransformComparisiondata } from "./transformer";

class AnalyzeServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getAnalyzeResponseMetricChart(dataConfig) {
    const data = await AnalyzeService.getAnalyzeResponseMetric(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getAnalyzeResponseComparisionTable(dataConfig) {
    const data = await AnalyzeService.getAnalyzeComparisionTable(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    let transformedData = TransformComparisiondata(data);
    return transformedData;
  }

  async markExperimentDefaultVersionConfig(dataConfig) {
    const data = await AnalyzeService.markExperimentDefaultVersionConfig(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async biasCorrectionDagTrigger(dataConfig) {
    const data = await AnalyzeService.biasCorrectionDagTrigger(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async addExperimentTaskItem(dataConfig) {
    const data = await AnalyzeService.addExperimentTaskItem(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }
}

export default AnalyzeServiceHelper;
