import MockPage from "./mockpage";
import AccessControl from "./access-control";
import ErrorFallback from "./ErrorFallback";
import MeasureTestLibrary from "./measure-test-library";
import MeasureAnalyze from "./measure-analyze";
import MeasureResults from "./measure-results";
import ExperimentTasksManagementDashboard from "./experiment-tasks-management-dashboard";
import DesignExperiment from "./design-experiment";
import ExecutiveAtAGlanceOverview from "./executive-at-a-glance-overview";
import ExecutiveEnterpriseLedger from "./executive-enterprise-ledger";
import DesignOptimization from "./design-optimization";
import DesignMaxAIApplications from "./design-max-ai-applications";
import DesignAISequencing from "./design-ai-sequencing";
import DataIntegrations from "./data-integrations";
import DataData from "./data-data";
import DesignGenAIGallery from "./design-gen-ai-gallery/demo";
import DesignGenAIDynamicImages from "./design-gen-ai-dynamic-images/demo";
import DesignGenAIArchive from "./design-gen-ai-archive/demo";
import DesignVariantBankApproval from "./design-variant-bank-approval";
import SupersetIntegration from "./superset-integration";
import CustomReports from "./custom-reports/"

export {
  AccessControl,
  MockPage,
  ErrorFallback,
  MeasureTestLibrary,
  MeasureAnalyze,
  MeasureResults,
  ExperimentTasksManagementDashboard,
  DesignExperiment,
  ExecutiveAtAGlanceOverview,
  ExecutiveEnterpriseLedger,
  DesignOptimization,
  DesignAISequencing,
  DataIntegrations,
  DataData,
  DesignMaxAIApplications,
  DesignGenAIGallery,
  DesignGenAIDynamicImages,
  DesignGenAIArchive,
  DesignVariantBankApproval,
  SupersetIntegration,
  CustomReports,
};
