import React from "react";
import { Space } from "@pai-ui/core";

export const FilterComponent = ({ title, children, type, style }) => {
    return type === "inline" ? (
        <Space style={style}>
            <div style={{ color: "#15afd0" }}>{title}</div>
            {children}
        </Space>
    ) : (
        <div>
            <div style={{ color: "#15afd0" }}>{title}</div>
            {children}
        </div>
    );
};
