import React, { useState, useEffect } from "react";
import { startCase } from "lodash";
import numeral from "numeral";
import moment from "moment";
import { FileEarmarkText } from "@styled-icons/bootstrap/FileEarmarkText";
import { Modal } from "@pai-ui/core/components/modal";
import { Collapse, CollapsePanel } from "@pai-ui/core/components/collapse";
import { RuleBuilder } from '@pai-ui/core/components/rule-builder';
import { Space } from '@pai-ui/core/components/space';
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Divider } from "@pai-ui/core/components/divider";
import { Ellipsis } from '@pai-ui/core/components/ellipsis';
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import { Grid, GridItem } from "@xai/library/containers/grid";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";
import { BiasCorrectionReportModal } from "@xai/pages/measure-analyze/components";
import { variantOrderArray, variantWidth } from "./transformer.js";
import "./index.css";

const ExperimentViewConfigModal = ({ showModal, setShowModal, selectedExperiment }) => {

    const close = React.useCallback(() => setShowModal(false), []);
    const [configDetails, setConfigDetails] = useState([]);
    const [triggerBiasCorrectionReport, setTriggerBiasCorrectionReport] = useState(false);
    const [baselineCorrectionPeriodValue, setBaselineCorrectionPeriodValue] = useState(false);
    const [biasCorrectionFlag, setBiasCorrectionFlag] = useState(false);


    const templateFormConfig = selectedExperiment?.template_master?.template_form_config_table?.template_form_config;
    const formatObject = templateFormConfig?.format_object;

    const userState = React.useContext(UserContext);

    const audience_setup_label = "audience_setup";
    const baseline_configuration_label = "Baseline Configuration";
    const baseline_period_label = "Baseline Period";
    const bias_correction_label = "Bias Correction";

    const Load = () => {
        if ((selectedExperiment !== null || selectedExperiment !== undefined) && selectedExperiment?.template_master?.template_form_config_table?.template_form_config) {
            let tempArray = [];

            //Below function is to order the key value pairs of variant object based on order array, customized request. 
            const OrderVariantData = (data) => {
                let variantTransformedArray = data;
                variantTransformedArray = variantTransformedArray?.map((variantObj) => {
                    if (variantOrderArray) {
                        let orderedVariantObject = {};
                        variantOrderArray?.map((item) => {
                            if (variantObj[item]) {
                                orderedVariantObject[item] = variantObj[item];
                            }
                        });
                        return orderedVariantObject;
                    }
                    return variantObj;
                });
                return variantTransformedArray;
            }

            templateFormConfig?.collapse_headers?.map((item, index) => (
                item.variant_setup ?
                    tempArray.push({
                        header: item.header,
                        variant_setup: item.variant_setup,
                        parameters: templateFormConfig?.sections[0].elements // TODO Checking only first section of Form Config Rather than all Sections
                            .filter(elem => elem.section === index)
                            .map(element => OrderVariantData(selectedExperiment.experiment_data[element.itemKey]))
                    }) :
                    tempArray.push({
                        header: item.header,
                        variant_setup: item.variant_setup,
                        parameters: templateFormConfig?.sections[0].elements // TODO Checking only first section of Form Config Rather than all Sections
                            .filter(elem => elem.section === index)
                            .map(element => {
                                if (element.audience) {
                                    return {
                                        [audience_setup_label]: element.audience,
                                        [element.label]: selectedExperiment.experiment_data[element.itemKey]
                                    }
                                }
                                return {
                                    [element.label]:
                                        element.questionType === "DateRange" ?
                                            selectedExperiment?.experiment_data[element.itemKey]
                                            && selectedExperiment?.experiment_data[element.itemKey][0]
                                            && selectedExperiment?.experiment_data[element.itemKey][1]
                                            && `${moment(selectedExperiment?.experiment_data[element.itemKey][0]).format('DD-MMM-YYYY')} to ${moment(selectedExperiment.experiment_data[element.itemKey][1]).format('DD-MMM-YYYY')}`
                                            :
                                            Array.isArray(selectedExperiment.experiment_data[element.itemKey]) ?
                                                selectedExperiment.experiment_data[element.itemKey].join(" , ")
                                                :
                                                selectedExperiment.experiment_data[element.itemKey]
                                }
                            })
                    })
            ));
            console.log('View Config Modal Data', tempArray);
            setConfigDetails(tempArray);
        }
    }

    const RuleBuilderComponent = ({ paramData, label }) => {

        const config = React.useMemo(
            () => [templateFormConfig?.sections[0]?.elements?.find((elementObj) => elementObj.audience)?.audience_rulebuilder_config],
            [templateFormConfig?.sections[0]?.elements?.find((elementObj) => elementObj.audience)?.audience_rulebuilder_config],
        );

        let RuleBuilderData = Object.keys(paramData).
            filter((key) => !key.includes(audience_setup_label)).
            reduce((cur, key) => { return Object.assign(cur, { [key]: paramData[key] }) }, {});

        return (
            <>
                {
                    RuleBuilderData[label]?.map((parameterObj) => (
                        <>
                            <div>
                                <div style={{ margin: "10px 0px" }}>
                                    {parameterObj?.audience_group_name}
                                </div>
                                <div>
                                    <RuleBuilder
                                        withRelationshipInput={false}
                                        defaultValue={{
                                            rules: parameterObj?.audience_group_rules
                                        }}
                                        config={config}
                                        readonly
                                    />
                                </div>
                            </div>
                        </>
                    ))
                }
            </>
        )
    }

    useEffect(() => {
        Load();
        BaseLinePeriodData();
    }, [selectedExperiment]);

    const BaseLinePeriodData = () => {
        if (selectedExperiment?.default_version_config === 1) {
            setBaselineCorrectionPeriodValue("NA");
        } else {
            let filteredTaskObject = selectedExperiment?.experiment_task_management?.find(task => task.version === selectedExperiment?.default_version_config)
            if (
                filteredTaskObject &&
                filteredTaskObject?.status === 1 &&
                filteredTaskObject?.request_type === "bias_correction" &&
                filteredTaskObject?.request_from_screen === "analyze"
            ) {
                if (filteredTaskObject.prior_date_range) {
                    setBaselineCorrectionPeriodValue(splitByDelimiterAndToStartCase(filteredTaskObject?.prior_date_range));
                } else {
                    setBaselineCorrectionPeriodValue(`${moment(filteredTaskObject.start_time_kpi).format("MM/DD/YYYY")} - ${moment(filteredTaskObject.end_time_kpi).format("MM/DD/YYYY")}`);
                }
            }
            if (
                filteredTaskObject?.bias_correction_report &&
                filteredTaskObject?.status === 1 &&
                filteredTaskObject?.request_type === "bias_correction" &&
                filteredTaskObject?.request_from_screen === "analyze"
            ) {
                setBiasCorrectionFlag(true);
            } else {
                setBiasCorrectionFlag(false);
            }
        }
    }

    if (triggerBiasCorrectionReport) {
        let tempReportData = selectedExperiment?.experiment_task_management?.find(task => task.version === selectedExperiment?.default_version_config)?.bias_correction_report;
        return <>
            <BiasCorrectionReportModal
                isReportOpen={true}
                reportData={tempReportData}
                setIsReportOpen={setTriggerBiasCorrectionReport}
            />
        </>
    }

    return (
        <div>
            <Modal
                open={showModal}
                withCloseButton={false}
                withoutFooterButtons={true}
                onOk={close}
                onCancel={close}
                bodyStyle={{ height: "90vh", /* overflowY: 'auto', */ background: "#181b20" }}
                width={"55vw"}
                style={{
                    padding: 15,
                    borderRadius: 10,
                }}
            >
                {configDetails && configDetails?.length > 0 ?
                    <Grid template={[["top-row"], ["config-details"]]}>
                        <GridItem area="top-row">
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Test Configuration</div>
                            {/* TODO Should this be Experimnt Config? */}
                        </GridItem>
                        <GridItem area="config-details">
                            <div className="experiment-config-section">
                                {
                                    configDetails?.map((item, index) => (

                                        <Collapse
                                            accordion={true}
                                            key={index}
                                            defaultActiveKey={['0']}
                                            bordered={false}
                                            style={{ background: "hsla(0, 0%, 100%, .04)" }}
                                        >
                                            <CollapsePanel
                                                header={
                                                    <span style={{ fontSize: "16px" }}>{item.header}</span>
                                                }
                                                key={index}
                                            >

                                                {item.variant_setup ?
                                                    <>
                                                        {
                                                            item.parameters?.map((param) => (
                                                                param?.map((par, ind) => (
                                                                    <div key={ind} className="row-section">
                                                                        <Row>
                                                                            {
                                                                                Object.entries(par).map((p, i) => (
                                                                                    <Col key={i} span={variantWidth?.[p?.[0]] || 4} style={{ margin: "10px 0px", /* display: "flex", flexDirection: "row" */ }}>
                                                                                        <Row>
                                                                                            <span style={{ fontSize: "14px" }}>
                                                                                                {`${p[0] === "variant_label" ? "Variant" : startCase(p[0])}:`}&nbsp;
                                                                                            </span>
                                                                                            <span style={{ opacity: 0.65, fontSize: "14px", /* marginTop: "10px" */ }}>
                                                                                                {
                                                                                                    formatObject && formatObject?.[p[0]] ?
                                                                                                        <Ellipsis
                                                                                                            maxWidth={p[0] === "variant_label" ? "16vw" : "9vw"}
                                                                                                            text={numeral(p[1]).format(userState.format?.[formatObject?.[p[0]]]).toUpperCase()}
                                                                                                        />
                                                                                                        :
                                                                                                        <Ellipsis
                                                                                                            maxWidth={p[0] === "variant_label" ? "16vw" : "9vw"}
                                                                                                            text={p[1]}
                                                                                                        />
                                                                                                }
                                                                                            </span>
                                                                                        </Row>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                            {
                                                                                (param.length - 1 !== ind) && <Divider orientation="left" />
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                ))
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    <Col>
                                                        {
                                                            item.parameters?.map((param, id) => (
                                                                param[audience_setup_label] ?
                                                                    <Row key={id} className="row-section">
                                                                        <Col span={6} style={{ margin: "10px 0px" }}>
                                                                            <Row style={{ fontSize: "14px" }}>
                                                                                {Object.keys(param).filter(it => it !== audience_setup_label)} :
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={18} style={{ margin: "auto 0px", opacity: 0.65, padding: "10px 0px" }}>
                                                                            <RuleBuilderComponent
                                                                                paramData={param}
                                                                                label={Object.keys(param).find(it => it !== audience_setup_label)}
                                                                            />
                                                                        </Col>
                                                                        {
                                                                            (item.parameters.length - 1 !== id) && <Divider orientation="left" />
                                                                        }
                                                                    </Row>
                                                                    :
                                                                    <>
                                                                        <Row key={id} className="row-section" >
                                                                            <Col span={6} style={{ margin: "10px 0px" }}>
                                                                                <Row style={{ fontSize: "14px" }}>
                                                                                    {Object.keys(param)} :
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={18} style={{ margin: "10px 0px", opacity: 0.65 }}>
                                                                                <Row style={{ fontSize: "14px" }}>
                                                                                    {
                                                                                        formatObject && formatObject?.[Object.keys(param)] ?
                                                                                            numeral(Object.values(param)).format(userState.format?.[formatObject?.[Object.keys(param)]]).toUpperCase()
                                                                                            : Object.values(param)
                                                                                    }
                                                                                </Row>
                                                                            </Col>
                                                                            {
                                                                                (item.parameters.length - 1 !== id) && <Divider orientation="left" />
                                                                            }
                                                                        </Row>

                                                                    </>
                                                            ))
                                                        }
                                                    </Col>
                                                }
                                            </CollapsePanel>
                                        </Collapse>
                                    ))
                                }
                                <Collapse
                                    accordion={true}
                                    bordered={false}
                                    style={{ background: "hsla(0, 0%, 100%, .04)" }}
                                >
                                    <CollapsePanel
                                        header={<span style={{ fontSize: "16px" }}>{baseline_configuration_label}</span>}
                                    >
                                        <Row className="row-section">
                                            <Col span={6} style={{ margin: "10px 0px" }}>
                                                <Row style={{ fontSize: "14px" }}>
                                                    {baseline_period_label} :
                                                </Row>
                                            </Col>
                                            <Col span={18} style={{ margin: "auto 0px", opacity: 0.65, padding: "10px 0px" }}>
                                                {
                                                    baselineCorrectionPeriodValue
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="row-section">
                                            <Col span={6} style={{ margin: "10px 0px" }}>
                                                <Row style={{ fontSize: "14px" }}>
                                                    {bias_correction_label} :
                                                </Row>
                                            </Col>
                                            <Col span={18} style={{ margin: "auto 0px", opacity: 0.65, padding: "10px 0px" }}>
                                                <Space>
                                                    <span>{biasCorrectionFlag ? "Yes" : "No"}</span>
                                                    <FileEarmarkText
                                                        style={{
                                                            height: "22px",
                                                            color: biasCorrectionFlag
                                                                ? "#15afd0"
                                                                : "gray",
                                                            cursor: biasCorrectionFlag
                                                                ? "pointer"
                                                                : "",
                                                        }}
                                                        onClick={
                                                            () => biasCorrectionFlag ?
                                                                setTriggerBiasCorrectionReport(true) : setTriggerBiasCorrectionReport(false)
                                                        }
                                                    />
                                                </Space>
                                            </Col>
                                            <Divider orientation="left" />
                                        </Row>
                                    </CollapsePanel>
                                </Collapse>
                            </div>
                        </GridItem>
                    </Grid>
                    :
                    <>
                        <LegacyNoData height="85vh" />
                    </>
                }
            </Modal>
        </div >
    )
}



export default ExperimentViewConfigModal;