import React from "react";

import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => {
    return props.templateColumns
      ? props.templateColumns
      : props?.template?.[0]
          ?.map((x, i, arr) => {
            const itemsCount = props.template[0].length;
            if (props.unit === "%") {
              const gap = props.colGap ?? props.gap ?? 20;
              const gapsCount = itemsCount - 1;
              const marginPerItem = (gap / itemsCount) * gapsCount;
              const isLastItem = i === arr.length - 1;
              const boundaryPadding = 1;
              return `calc(${100 / itemsCount}% - ${marginPerItem + (isLastItem ? boundaryPadding : 0)}px)`;
            }
            return "1fr";
          })
          ?.join(" ") || "";
  }};
  grid-template-areas: ${(props) => props?.template?.map((t) => "'" + t.join(" ") + "'").join(" ")};
  column-gap: ${({ gap, colGap }) => colGap ?? gap ?? 20}px;
  row-gap: ${({ gap, rowGap }) => rowGap ?? gap ?? 20}px;
`;

export const GridItem = styled.div`
  grid-area: ${(props) => props.area};
`;

const GridWrapper = ({ template, items }) => (
  <Grid template={template}>
    {items &&
      items.map((item, i) => {
        const Component = item.component;
        return (
          <GridItem key={i} area={item.area}>
            <Component />
          </GridItem>
        );
      })}
  </Grid>
);

export default GridWrapper;
