import React from "react"; //, { useEffect, useState }
import { Modal } from "@pai-ui/core/components/modal";
import RouteConfigForm from "../route-config-form";
import { GenerateSuperSetFormUpsertObject } from "../../transformer";
import SupersetHelper from "../../service-helper";
import { UserContext } from "@xai/providers/with-auth";
import { showNotificationMessage } from "@xai/assets/generic-components/toaster-message/index";
import "./style.css";
import { useLayoutContextToUpdateMenu } from "@xai/providers/layout";

const AddDashboardPopupContent = ({
  showPopup,
  setShowPopup,
  selectedDashboard,
  ddnMaster,
}) => {
  const userState = React.useContext(UserContext);
  const serviceHelper = new SupersetHelper(userState.token);
  const { updateMenu } = useLayoutContextToUpdateMenu();

  const close = React.useCallback(() => setShowPopup(false), []);

  const handleSubmit = async (validateForm, getValue) => {
    console.log("values after submit", validateForm, getValue);
    let input = GenerateSuperSetFormUpsertObject(getValue(), ddnMaster);
    let res = await serviceHelper.addConfig(input);
    showNotificationMessage(
      "top",
      res.status === 200 ? "success" : "error",
      res.status === 200
        ? res.message || "Saved Successfully!"
        : res.message || "Error in saving!"
    );

    if (res.status === 200) {
      updateMenu(userState.token);
    }

    if (res.status) close();
  };
  return (
    <>
      {showPopup ? (
        <Modal
          className="add-dashboard-popup-modal"
          open={showPopup}
          withCloseButton={false}
          withoutFooterButtons={false}
          okButtonProps={{
            style: {
              display: "none",
            },
          }}
          cancelButtonProps={{
            style: {
              marginRight: 72,
            },
          }}
          // onOk={handleSubmit}
          onCancel={close}
          onBackgroundClick={close}
          onEscapeKeydown={close}
        >
          <RouteConfigForm
            selectedDashboard={selectedDashboard}
            ddnMaster={ddnMaster}
            handleSubmit={handleSubmit}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
export default AddDashboardPopupContent;
