import React from "react";
import { Upload as Upload_Icon } from "@styled-icons/boxicons-regular/Upload";
// import { message, Upload } from "antd";
import { message } from "@pai-ui/core/components/message";
import { UploaderDragger } from "@pai-ui/core/components/uploader";
import { Modal } from "@pai-ui/core/components/modal";
import { Space } from "@pai-ui/core/components/space";
// import { Button } from "@pai-ui/core/components/button";

const Upload_Icon_ = (
  <Upload_Icon
    width={50}
    height={50}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);

// const { Dragger } = Upload;

export const FileUploadPopUp = ({
  ModalWidth = "50vw",
  showDialog,
  closePopUp,
  topExtraContent = () => <></>,
  bottomExtraContent = () => <></>,
  fileUploadURL,
  fileNameAttribute = "file",
  multipleFiles = false,
  uploadMainText = "Click or drag file to this area to upload",
  uploadSubText = "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
  onUploadCompletion = () => {
    console.log("onUploadCompletion default method");
  },
}) => {
  const props = {
    name: fileNameAttribute,
    multiple: multipleFiles,
    action: fileUploadURL,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        onUploadCompletion();
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <Modal
        open={showDialog}
        onCancel={closePopUp}
        withCloseButton={true}
        withoutFooterButtons={true}
        bodyStyle={{
          // height: "55vh",
          /* overflowY: 'auto', */
          background: "#181b20",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        width={ModalWidth}
      >
        <Space
          direction="vertical"
          gap={"middle"}
          style={{ padding: "30px", width: "100%" }}
        >
          {topExtraContent()}
          <UploaderDragger {...props}>
            <div style={{ height: "20vh", padding: "25px" }}>
              <p>{Upload_Icon_}</p>
              <p className="ant-upload-text">{uploadMainText}</p>
              <p className="ant-upload-hint">{uploadSubText}</p>
            </div>
          </UploaderDragger>
          {bottomExtraContent()}
        </Space>
      </Modal>
    </>
  );
};

export default FileUploadPopUp;
