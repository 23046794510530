import React from "react";
import { Space } from '@pai-ui/core/components/space';
import {
    tagline_experiment_unique_identifier_label,
    default_experiment_unique_identifier_label,
    subjectline_experiment_unique_identifier_label,
    genAiExperiment_experiment_unique_identifier_label
} from "../transformer.js";
import TagLineExperiment from "../tag-line-template";
import DefaultFormTemplate from "../default-form-template";
import SubjectLineExperiment from "../subject-line-form-template";
import DesignGenAIMarketingCopy from "@xai/pages/design-gen-ai-marketing-copy/demo2.js";
import './index.css';

const FormLayout = ({ templateSelected, setTemplateSelected, experimentToLoad }) => {

    return (
        <div
            style={{
            display: "flex",
            flexDirection: "column",
            height: "96vh",
            }}
        >
            <div>
                <h3 style={{ marginLeft: "10px", marginBottom: "15px" }}>
                    <Space>
                        <span
                            className="select-link"
                            onClick={() => { setTemplateSelected(null) }}>
                            Choose a template
                        </span>
                        <span>
                            /
                        </span>
                        <span>
                            {/* Create an Experiment */}
                            {templateSelected?.template_name ? templateSelected?.template_name : 'Create an Experiment'}
                        </span>
                    </Space>
                </h3>
            </div>
            {
                templateSelected &&
                templateSelected?.template_identifier?.toLowerCase() === tagline_experiment_unique_identifier_label?.toLowerCase() &&
                <>
                    <TagLineExperiment
                        templateSelected={templateSelected}
                    />
                </>
            }
            {
                templateSelected &&
                templateSelected?.template_identifier?.toLowerCase() === default_experiment_unique_identifier_label?.toLowerCase() &&
                <>
                    <DefaultFormTemplate
                        experimentToLoad={experimentToLoad}
                        templateSelected={templateSelected}
                    />
                </>
            }
            {
                templateSelected &&
                templateSelected?.template_identifier?.toLowerCase() === subjectline_experiment_unique_identifier_label?.toLowerCase() &&
                <>
                    <SubjectLineExperiment
                        experimentToLoad={experimentToLoad}
                        templateSelected={templateSelected}
                    />
                </>
            }
            {
                templateSelected &&
                templateSelected?.template_identifier?.toLowerCase() === genAiExperiment_experiment_unique_identifier_label?.toLowerCase() &&
                <>
                    <DesignGenAIMarketingCopy />
                </>
            }
        </div>
    );
};

export default FormLayout;