import React /* , { useEffect, useState }  */ from "react";
// import { Metric } from "@pai-ui/core/components/metric";
// import { Carousel } from '@pai-ui/core/components/carousel';
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";

const OverviewUsecaseResultsFromExperiments = (/* { filters } */) => {
  return (
    <>
      {/* <div>OverviewUsecaseResultsFromExperiments placeholder</div> */}
      <LegacyNoData
        height={"52vh"}
        error={true}
        description={"Results From Experiments placeholder"}
      />
    </>
  );
};

export default OverviewUsecaseResultsFromExperiments;
