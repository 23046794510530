import React, { useEffect, useState } from "react";
// import moment from "moment";
import numeral from "numeral";
import { Card } from "@pai-ui/core/components/card";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Tag } from "@pai-ui/core/components/tag";
import { List, ListItem, ListItemMeta } from "@pai-ui/core/components/list";
import { Button } from "@pai-ui/core/components/button";
import { HieghtAdjustorDiv } from "@xai/assets/generic-components/hieght-adjustor-div";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getExecutiveAtAGlanceExperimentListTableConfig } from "@xai/pages/executive-at-a-glance-overview/transformer";
import ExecutiveServiceHelper from "@xai/pages/executive-at-a-glance-overview/service-helper";
import { UserContext } from "@xai/providers/with-auth";
// import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import "./style.css";

const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"];

const OverviewListOfExperiments = ({
  filters,
  // setAagOverviewSelectedExperiment,
  aagOverviewSelectedExperiment
}) => {
  // const { handleSetFavorite, onMarkFavFinishLoad } = props;

  const { token, format } = React.useContext(UserContext);
  const service = new ExecutiveServiceHelper(token);

  const [loading, setLoading] = useState(true);
  const [table, settabledata] = useState([]);
  //States for Error Handling
  const [tableDataError, setTableDataError] = useState(false);

  const loadExperimentListTableData = async () => {
    let dataconfig = getExecutiveAtAGlanceExperimentListTableConfig(
      filters?.dateRange?.[0],
      filters?.dateRange?.[1]
    );
    console.log("loadExperimentListTableData call", dataconfig);
    setTableDataError(false);
    await service
      .getExecutiveAtAGlanceExperimentListTable(dataconfig)
      .then(async (res) => {
        console.log("loadExperimentListTableData res", res);
        settabledata(res?.table_data);

        // Below setting experiment data is removed for Demo purpose, need to be removed later
        
        // if (res?.table_data?.length > 0) {
        //   setAagOverviewSelectedExperiment(res?.table_data?.[0]?.id);
        // }
      })
      .catch((err) => {
        console.log("loadExperimentListTableData Error", err);
        setTableDataError(true);
      })
      .finally(() => {
        // setLoading(false);
        // #TODO : Below code to be commented later during actual api integration and use the above.
        setTimeout(() => {
          console.log("table", table);
          setLoading(false);
        }, 1500);
      });
  };

  useEffect(() => {
    if (filters?.dateRange?.length !== 0) {
      loadExperimentListTableData();
    }
  }, [filters]);

  if (tableDataError) {
    return (
      <LegacyNoData height={"inherit"} error={true}>
        <Button onClick={() => loadExperimentListTableData()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return loading ? (
    <LegacyNoData height={"100%"} loading={true} />
  ) : (
    <div className="recommendation-results-analysis-list">
      {/* <span>OverviewListOfExperiments</span> */}
      <HieghtAdjustorDiv height_vh={37.5 * 2} height_px={20}>
        <Card
          // style={{ height: "calc(88.2vh)" }}
          // style={{ height: `calc(${(38.5 * 1.1111) * 2}vh + 20px)` }}
          style={{ height: "inherit" }}
          bodyStyle={{ padding: "0px" }}
          bordered={false}
          title={
            <Row justify="space-between" align="middle">
              <Col>
                <p
                  style={{
                    margin: 0,
                    flexGrow: 1,
                    lineHeight: "130%",
                    fontWeight: 400,
                    color: colors[0],
                  }}
                >
                  Historical <br />
                  <span style={{ color: "#ccc", fontSize: "18px" }}>
                    List of Experiments
                  </span>
                </p>
              </Col>
              <Col>
                <p
                  style={{
                    margin: 0,
                    flexShrink: 0,
                    textAlign: "right",
                    lineHeight: "100%",
                    fontWeight: 400,
                  }}
                >
                  Incremental Revenue
                  <br />
                  <small style={{ color: colors[0] }}>
                    Lift in Response Metric vs. Control
                  </small>
                </p>
              </Col>
            </Row>
          }
        >
          <List
            itemLayout="horizontal"
            dataSource={table}
            // style={{ overflowY: "auto", height: "calc(74.5vh)" }}
            style={{
              // height: '100%',
              overflowY: "auto",
            }}
            size="small"
            renderItem={(item, renderItemIndex) => (
              <ListItem
                style={{
                  // opacity: item?.shouldHide ? "0.7" : "1",
                  cursor: "pointer",
                }}
                className={
                  item.id == aagOverviewSelectedExperiment
                    ? "highlighted-clickable"
                    : "experiments-list-item"
                }
                key={item.id}
                // onClick={() => {
                //   setAagOverviewSelectedExperiment(item?.id);
                // }}
              >
                <ListItemMeta
                  avatar={
                    <h4
                      style={{
                        fontSize: "14px",
                        color: "hsla(0,0%,100%,.65)",
                        marginRight: "4px",
                      }}
                    >{`#${renderItemIndex + 1}`}</h4>
                  }
                  title={
                    <>
                      <div style={{ color: "#FFFFFF", marginBottom: "1px" }}>
                        {item?.name}
                      </div>
                      {item?.tags?.map((tag, index) => {
                        return (
                          <Tag
                            key={index}
                            color="#15afd0"
                            size="small"
                            style={{
                              background: "#000",
                              color: "#15afd0",
                              border: "1px solid #15afd0",
                            }}
                          >
                            {tag}
                          </Tag>
                        );
                      })}
                    </>
                  }
                />
                <div style={{ textAlign: "right" }}>
                  <div>
                    <h4
                      style={{
                        textAlign: "right",
                        color: "#FFF",
                        lineHeight: "100%",
                        fontSize: "14px"
                      }}
                    >
                      {numeral(item?.primary_value)
                        .format(format?.[item?.primary_value_format])
                        .toUpperCase()}
                    </h4>
                  </div>
                  <div>
                    <p
                      style={{
                        textAlign: "right",
                        lineHeight: "110%",
                        color: "#15afd0",
                      }}
                    >
                      {numeral(item?.secondary_value)
                        .format(format?.[item?.secondary_value_format])
                        .toUpperCase()}
                    </p>
                  </div>
                </div>
              </ListItem>
            )}
          />
        </Card>
      </HieghtAdjustorDiv>
    </div>
  );
};

export default OverviewListOfExperiments;
