import React from "react";
import numeral from "numeral";
import {
  Visualization,
  BoxChartConfig,
} from "@pai-ui/core/components/visualization";
import { UserContext } from "@xai/providers/with-auth";

export const CustomVizBoxPlot = ({
  box_plot_data,
  is_control,
  selectedTestGroup,
  selectedControlGroup,
  selectedParameter,
  min_limit,
  max_limit,
}) => {
  const { format } = React.useContext(UserContext);

  const {
    min_value,
    min_format,
    first_quartile_value,
    // first_quartile_format,
    median_value,
    // median_format,
    third_quartile_value,
    // third_quartile_format,
    max_value,
    // max_format,
  } = box_plot_data;

  return (
    <>
      <Visualization
        withCard={false}
        height={30}
        width={"100%"}
        viewConfig={
          new BoxChartConfig({
            layout: "vertical",
            dataConfig: {
              type: "calculated",
            },
            margin: {
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            },
            referenceLines: [
              {
                id: "12",
                color: "grey",
                strokeDasharray: "5 5",
                x: "Variant",
              },
            ],
            axes: {
              y: {
                show: false,
                // domain: [0, 1],
                domain: [min_limit, max_limit],
                format: {
                  value: format?.[min_format], //"0[.]00%",
                },
              },
              x: { show: false },
            },
            tooltip: {
              overlayStyle: { zoom: 1.1111111, display: "flex" },
              renderContent: (props) => {
                console.log("BoxChart. tooltip.renderContent", props);
                return (
                  <div
                    style={{
                      padding: "10px",
                      minHeight: "150px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3>
                      {is_control ? selectedControlGroup : selectedTestGroup}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        minWidth: "300px",
                        paddingTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "35%",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "700",
                            textAlign: "center",
                            color: "#15AFD0",
                          }}
                        >
                          {numeral(median_value).format(format?.[min_format])}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <b>{selectedParameter}</b>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "65%",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: "0px 2px",
                          borderLeft: "1px solid rgb(48, 48, 48)",
                        }}
                      >
                        {[
                          { name: "Maximum", value: max_value },
                          {
                            name: "Third Quartile",
                            value: third_quartile_value,
                          },
                          {
                            name: "First Quartile",
                            value: first_quartile_value,
                          },
                          { name: "Minimum", value: min_value },
                        ].map((x, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                paddingRight: "10px",
                                display: "flex",
                              }}
                            >
                              <b>{x.name}:</b>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-end",
                              }}
                            >
                              {numeral(x.value).format(format?.[min_format])}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              },
            },
          })
        }
        dataset={{
          fields: ["name", "calculation"],
          data: [
            [
              "Variant",
              {
                min: min_value,
                firstQuartile: first_quartile_value,
                median: median_value,
                thirdQuartile: third_quartile_value,
                max: max_value,
              },
            ],
          ],
        }}
      />
    </>
  );
};
