import React from "react";
import { Space } from "@pai-ui/core/components/space";
import { Card } from "@pai-ui/core/components/card";
import { Select } from "@pai-ui/core/components/select";
import { Button } from "@pai-ui/core/components/button";
import { FilterComponent } from "@xai/assets/generic-components/utils";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";

export const SegmentImpactExplorerTopFilterSection = ({
  segmentImpactExplorerTabParams,
  segmentimpactTabFilters,
  setSegmentimpactTabFilters,
  exportSegmentImpactExplorerTabData,
  segmentimpactTabData,
}) => {
  const {
    selectedSegment,
    selectedParameter,
    selectedTestGroup,
    selectedControlGroup,
  } = segmentimpactTabFilters;

  const { segment_list, parameter_list, variant_groups_control_test_list } =
    segmentImpactExplorerTabParams;

  const FilterField = ({
    label,
    fieldOptions = [],
    valueKey,
    value,
    setValue,
  }) => {
    return (
      <FilterComponent title={label}>
        <Select
          style={{ width: "17vw" }}
          options={fieldOptions}
          value={value}
          onChange={(option) => {
            setValue({ ...segmentimpactTabFilters, [valueKey]: option });
          }}
        />
      </FilterComponent>
    );
  };

  return (
    <Card bodyStyle={{ display: "flex", width: "100%" }}>
      <Space size={"large"}>
        <FilterField
          label={"Segment Selection"}
          valueKey={"selectedSegment"}
          value={selectedSegment}
          setValue={setSegmentimpactTabFilters}
          fieldOptions={segment_list?.map((option) => {
            return {
              id: option,
              name: splitByDelimiterAndToStartCase(option),
            };
          })}
        />
        <FilterField
          label={"Parameter Selection"}
          valueKey={"selectedParameter"}
          value={selectedParameter}
          setValue={setSegmentimpactTabFilters}
          fieldOptions={parameter_list?.map((option) => {
            return {
              id: option,
              name: option,
              // name: splitByDelimiterAndToStartCase(option),
            };
          })}
        />
        <FilterField
          label={"Test Groups"}
          valueKey={"selectedTestGroup"}
          value={selectedTestGroup}
          setValue={setSegmentimpactTabFilters}
          fieldOptions={
            variant_groups_control_test_list?.find(
              (option) => option.id === selectedControlGroup
            )?.["test_groups"]?.map((testGroupObj) => {
              return {
                ...testGroupObj,
                name: testGroupObj?.name?.split('||||')?.[0]
              }
            }) || []
          }
        />
        <FilterField
          label={"Control Groups"}
          valueKey={"selectedControlGroup"}
          value={selectedControlGroup}
          setValue={setSegmentimpactTabFilters}
          fieldOptions={variant_groups_control_test_list?.map((testGroupObj) => {
            return {
              ...testGroupObj,
              name: testGroupObj?.name?.split('||||')?.[0]
            }
          })}
        />
      </Space>
      <Space
        style={{
          flex: "auto",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="Create Report"
          onClick={exportSegmentImpactExplorerTabData}
          disabled={
            // if filters are not selected
            !selectedSegment ||
            !selectedParameter ||
            !selectedTestGroup ||
            !selectedControlGroup ||
            // If Table data is not available
            segmentimpactTabData?.length == 0
          }
        />
      </Space>
    </Card>
  );
};

export default SegmentImpactExplorerTopFilterSection;
