import React /* , { useState, useEffect } */ from "react";
import { Row, Col } from "antd";
import {
  /* Input, */ TextArea /* , Button, Select, Card  */,
} from "@pai-ui/core";
// import { Modal } from "paiuilatest";
// import { Checkbox } from "@pai-ui/core/components/checkbox";
// import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
// import { PlusSquareOutline } from "@styled-icons/evaicons-outline/PlusSquareOutline";
// import { getTagLines } from '../../../../../../services/experiment';
import "./style.css";
// import TabGrid from "./TabGrid";
// import { Form } from "antd";
// import { notification } from "antd";
// import MaxAIServiceHelper from "../../service-helper";
// import { UserContext } from "../../../../providers/with-auth";
// import { getS3Data } from "../../../transformer";

export const AdvancedModalContent = ({
  inputChangeHandler,
  formData,
  modalValues,
}) => {
  console.log({ modalValues, formData });
  return (
    <div style={{ padding: "50px" }}>
      <Row gutter={[20, 20]}>
        {formData?.advanced?.map((b, i) => (
          <Col
            key={i}
            style={{
              margin: "10px 20px",
              paddingBottom: "35px",
              borderBottom:
                formData?.advanced?.length - 1 !== i && "1px solid #2f2f2f",
            }}
            span={24}
          >
            <Row>
              <Col span={6} style={{ fontSize: "16px" }}>
                <div>{b.title}</div>
                <div style={{ fontSize: "12px", opacity: 0.5 }}>{b.desc}</div>
              </Col>
              <Col span={18}>
                <TextArea
                  rows={4}
                  // defaultValue={ExpData.elements.find(it => it.itemKey === "keywords").defaultOption}
                  value={modalValues[b.title]}
                  onChange={(e) => inputChangeHandler(e, "advanced", b.title)}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
};
