import React from "react";
import numeral from "numeral";
import {
  ZsCaretUpFilled,
  ZsCaretDownFilled,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

export const PosNegValue = ({
  value,
  valueFormat,
  fontSize = "12px",
  fontWeight = "inherit",
  lineHeight = "100%",
  PosColor = "#6bd015",
  NegColor = "#cf1322e6" /* '#920912' */,
  ZeroColor = "yellow",
  textColor = "",
  showCaret = true, // boolean to show or hide caret
  ZeroRange = { minNegZero: 0, minPosZero: 0 }, // [minNeg , minPos]
  showValue = true, // boolean to show or hide value
  caratSize = "12px",
}) => {
  const formattedValue = numeral(Math.abs(value))
    .format(valueFormat)
    .toUpperCase();
  console.log("PosNegValue ", { value, valueFormat, ZeroRange });
  return (
    <>
      <span
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          lineHeight: lineHeight,
          color:
            value > ZeroRange.minPosZero
              ? PosColor
              : value < ZeroRange.minNegZero
                ? NegColor
                : ZeroColor,
        }}
      >
        {showCaret &&
          (value > ZeroRange.minPosZero ? (
            <ZsCaretUpFilled
              style={{ verticalAlign: "center", marginBottom: 4, fontSize: caratSize }}
            />
          ) : value < ZeroRange.minNegZero ? (
            <ZsCaretDownFilled
              style={{ verticalAlign: "center", marginBottom: 4, fontSize: caratSize }}
            />
          ) : (
            <ZsCaretDownFilled
              style={{
                visibility: "hidden",
                verticalAlign: "center",
                marginBottom: 4,
              }}
            />
          ))}
        <span style={{ color: textColor }}>
          {showValue ? (value < 0 ? `-${formattedValue}` : formattedValue) : ""}
        </span>
      </span>
    </>
  );
};

export default PosNegValue;
