import {
  CheckOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Slider,
  Tabs,
} from "antd";
import React from "react";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import MaxAIServiceHelper from "@xai/pages/design-max-ai-applications/service-helper";
import { UserContext } from "@xai/providers/with-auth";

export const buildPrompt = (baseVariant, variant, settings, generator) => {
  return `You are an experienced Copywriter with a passion for crafting compelling and persuasive messaging. You have to create copy for a marketing campaign for a company.
  \n\n
  Background: ${baseVariant.context}
  \n\n
  Product Description: ${baseVariant.product}
  \n\n
  Target Audience: ${baseVariant.audience}
  \n\n
  ${variant.variant_audience
      ? `Variant Audience: ${variant.variant_audience}`
      : ""
    }
  \n\n
  ${variant.variant_audience
      ? `Focus Areas and Keywords: ${variant.keywords}`
      : ""
    }
  \n\n
  ${variant.examples ? `Examples: ${variant.examples}` : ""}
  \n\n
  ${variant.tone ? `Tone: ${variant.tone}` : ""}
  \n\n
  ${variant.style ? `Tone: ${variant.style}` : ""}
  \n\n
  Generate ${settings.count} ${generator.name} between ${settings.limit.min
    } and ${settings.limit.max} characters each.
  \n\n
  ${settings.text ? `Additional instructions: ${settings.text}` : ""}
  `;
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const ContentComponentSummary = (props) => {
  const [loading, setLoading] = React.useState(false);
  const token = React.useContext(UserContext);
  const service = new MaxAIServiceHelper(token);

  const request = (generator) => {
    let respInput = {
      taglines: [
        buildPrompt(
          props.allData.variants.variants[0],
          props.allData.variants.variants[props.data.selected],
          props.data.prompts.variants[props.data.selected][generator.key],
          generator
        ),
      ],
      tagvariantcount:
        props.data.prompts.variants[props.data.selected][generator.key].count ||
        1, //formData.count.generateCount,
      min: parseInt(
        props.data.prompts.variants[props.data.selected][generator.key].limit
          .min
      ),
      max: parseInt(
        props.data.prompts.variants[props.data.selected][generator.key].limit
          .max
      ),
    };
    setLoading(true);
    service
      .getMaxAITagLines(respInput, true)
      .then(async (res) => {
        setLoading(false);
        console.log(Object.values(res.finalResult));
        Object.values(res.finalResult).forEach(function (value) {
          if (value.length === 0) {
            //setNoList(true);
          } else {
            let originalData = { ...props.data };
            let filteredData =
              originalData.variants[props.data.selected][generator.key];
            originalData.variants[props.data.selected][generator.key] = [
              ...value.map((v) => ({
                id: uuidv4(),
                text: v.split('"').join(" ").trim(),
                selected: false,
              })),
              ...filteredData,
            ];

            props.updateEntry("variants", originalData.variants);
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("getTagLines Error", err);
        //setAPIErrorFlag(true);
      });
  };

  return (
    <div style={{  display: "flex", flexDirection: "column", height: "85vh", overflowY: "auto", overflowX: "hidden" }}>
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          margin: -25,
          marginTop: -15,
          padding: 25,
        }}
      >
        {props.generators.map((generator, index) => (
          <div style={{}} key={index}>
            {props.allProps
              .find((p) => p.key === "variants")
              .props.inputs.filter(
                (input) =>
                  !input.required &&
                  input.advanced &&
                  props.allData.variants.variants[
                    props.data.selected
                  ].selectedFields.indexOf(input.key) !== -1
              )
              .map((input) => (
                <>
                  <p style={{ margin: 0, marginBottom: 10, color: "#15afd0" }}>
                    {input.label}
                  </p>
                  <Input.TextArea
                    rows={input.props.rows}
                    value={
                      props.allData.variants.variants[props.data.selected][
                      input.key
                      ]
                    }
                    onChange={(e) => {
                      let updatedVariants = [
                        ...props.allData.variants.variants,
                      ];
                      updatedVariants[props.data.selected][input.key] =
                        e.target.value;
                      props.updateParentEntry(
                        "variants",
                        "variants",
                        updatedVariants
                      );
                    }}
                    style={{ marginBottom: 20 }}
                  ></Input.TextArea>
                </>
              ))}
            <p style={{ margin: 0, marginBottom: 10, color: "#15afd0" }}>
              Character limit
            </p>
            <Row gutter={[5, 5]} style={{ marginBottom: 20 }}>
              <Col span={5}>
                <Input
                  type="number"
                  min={0}
                  max={500}
                  value={
                    props.data.prompts.variants[props.data.selected][
                      generator.key
                    ].limit.min
                  }
                  onChange={(e) => {
                    let updatedPrompts = { ...props.data.prompts };
                    updatedPrompts.variants[props.data.selected][
                      generator.key
                    ].limit.min = parseInt(e.target.value);
                    props.updateEntry("prompts", updatedPrompts);
                  }}
                ></Input>
              </Col>
              <Col flex={1}>
                <Slider
                  range
                  max={500}
                  min={0}
                  step={5}
                  value={[
                    props.data.prompts.variants[props.data.selected][
                      generator.key
                    ].limit.min,
                    props.data.prompts.variants[props.data.selected][
                      generator.key
                    ].limit.max,
                  ]}
                  onChange={(e) => {
                    let updatedPrompts = { ...props.data.prompts };
                    updatedPrompts.variants[props.data.selected][
                      generator.key
                    ].limit.min = e[0];
                    updatedPrompts.variants[props.data.selected][
                      generator.key
                    ].limit.max = e[1];
                    props.updateEntry("prompts", updatedPrompts);
                  }}
                />
              </Col>
              <Col span={5}>
                <Input
                  type="number"
                  min={0}
                  max={500}
                  value={
                    props.data.prompts.variants[props.data.selected][
                      generator.key
                    ].limit.max
                  }
                  onChange={(e) => {
                    let updatedPrompts = { ...props.data.prompts };
                    updatedPrompts.variants[props.data.selected][
                      generator.key
                    ].limit.max = parseInt(e.target.value);
                    props.updateEntry("prompts", updatedPrompts);
                  }}
                ></Input>
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ marginBottom: 0 }}>
              <Col flex={1}>
                <p style={{ margin: 0, color: "#15afd0", marginTop: 2 }}>
                  Number of variations
                </p>
              </Col>
              <Col span={4}>
                <Input
                  type="number"
                  min={1}
                  max={16}
                  value={
                    props.data.prompts.variants[props.data.selected][
                      generator.key
                    ].count || 1
                  }
                  onChange={(e) => {
                    let updatedPrompts = { ...props.data.prompts };
                    updatedPrompts.variants[props.data.selected][
                      generator.key
                    ].count = e.target.value;
                    props.updateEntry("prompts", updatedPrompts);
                  }}
                ></Input>
              </Col>
            </Row>
          </div>
        ))}
      </div>

      {props.generators.map((generator, index) => (
        <div key={index}>
          <p style={{ margin: 0, marginBottom: 10, color: "#15afd0" }}>
            Additional instructions
          </p>
          <Input.TextArea
            rows={5}
            value={
              props.data.prompts.variants[props.data.selected][generator.key]
                .text
            }
            onChange={(e) => {
              let updatedPrompts = { ...props.data.prompts };
              updatedPrompts.variants[props.data.selected][generator.key].text =
                e.target.value;
              props.updateEntry("prompts", updatedPrompts);
            }}
            style={{ marginBottom: 20 }}
          ></Input.TextArea>
          <Button
            type="primary"
            style={{ width: "100%" }}
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              request(generator);
            }}
          >
            Generate
          </Button>
        </div>
      ))}
    </div>
  );
};

export const ContentComponent = (props) => {
  const generatorData = props.data.variants
    ? props.data.variants[props.data.selected]
    : undefined;

  if (!generatorData) {
    return <></>;
  }

  return (
    <div style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}>
      <Tabs
        activeKey={`tab_${props.data.selected}`}
        tabBarStyle={{
          position: "sticky",
          top: -30,
          zIndex: 99,
          backgroundColor: "#121419",
          paddingTop: 15,
        }}
        onChange={(key) => {
          props.updateEntry("selected", parseInt(key.split("_")[1]));
        }}
      >
        {props.allData.variants.variants
          .filter((v, i) =>
            props.allData.variants.variants.length > 1 ? i > 0 : true
          )
          .map((variant, selected) => {
            const currentIndex =
              props.allData.variants.variants.length > 1
                ? selected + 1
                : selected;
            return (
              <Tabs.TabPane tab={variant.name} key={`tab_${currentIndex}`}>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  {props.generators.map((generator, index) => (
                    <Row gutter={[20, 20]} key={index}>
                      {generatorData[generator.key].map((tagline, tIndex) => (
                        <Col span={12} key={tIndex}>
                          <AspectRatio
                            width={16}
                            height={
                              props.data.prompts.variants[currentIndex][
                                generator.key
                              ].limit.max < 50
                                ? 5
                                : props.data.prompts.variants[currentIndex][
                                  generator.key
                                ].limit.max < 150
                                  ? 6
                                  : 8
                            }
                          >
                            <Card
                              style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                paddingLeft: 25,
                              }}
                            >
                              <Button
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  minWidth: 0,
                                  borderRadius: "50%",
                                }}
                                type={tagline.selected ? "primary" : "outline"}
                                onClick={() => {
                                  var variants = props.data.variants;
                                  if (!variants[selected]) {
                                    variants[selected] = {};
                                  }

                                  variants[currentIndex][generator.key] =
                                    generatorData[generator.key].map((t, i) => {
                                      if (i === tIndex) {
                                        return { ...t, selected: !t.selected };
                                      }
                                      return t;
                                    });
                                  props.updateEntry(
                                    "variants",
                                    JSON.parse(JSON.stringify(variants))
                                  );
                                }}
                                icon={
                                  <CheckOutlined
                                    style={{
                                      opacity: tagline.selected ? 1 : 0,
                                    }}
                                  />
                                }
                              />
                              <Input.TextArea
                                value={tagline.text}
                                bordered={0}
                                autoSize={{ minRows: 1, maxRows: 10 }}
                                maxLength={500}
                                style={{
                                  padding: 0,
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  maxWidth: "90%",
                                  outline: 0,
                                  border: 0,
                                }}
                                onChange={(e) => {
                                  let newVariants = [...props.data.variants];
                                  newVariants[currentIndex][
                                    generator.key
                                  ].text = e.target.value;
                                  props.updateEntry("variants", newVariants);
                                }}
                              ></Input.TextArea>
                            </Card>
                          </AspectRatio>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </div>
              </Tabs.TabPane>
            );
          })}
      </Tabs>
    </div>
  );
};
