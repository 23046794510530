import React, { useState, useEffect } from "react";
import numeral from "numeral";
// import moment from "moment";
import { Visualization, DendogramChartConfig } from '@pai-ui/core/components/visualization';
import EnterpriseLedgerServiceHelper from "@xai/pages/executive-enterprise-ledger/service-helper";
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getEnterpriseLedgerConfig } from "../../transformer";

const DendogramCollapse = ({ filters }) => {

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { token, format } = React.useContext(UserContext);
    const service = new EnterpriseLedgerServiceHelper(token);

    const Load = async () => {
        setLoading(true);
        let dataconfig = getEnterpriseLedgerConfig(
            filters?.dateRange?.[0],
            filters?.dateRange?.[1]
        );
        console.log('dataconfig', dataconfig);
        await service.getEnterpriseLedgerDendogram(dataconfig)
            .then(async (res) => {
                console.log('responseData', res);
                setChartData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getAnalyzeResponseMetricChartData Error', err);
                setError(true);
            });
    }

    useEffect(() => {
        if (filters?.dateRange?.length !== 0) {
            Load();
        }
    }, [filters]);

    const renderNodeContent = React.useCallback((data) => {
        return (
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: 20 }}>
                <div
                    style={{
                        borderRadius: '50%',
                        width: '45px',
                        height: '45px',
                        border: '1px solid #15AFD0',
                        marginRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: data?.children?.length > 0 ? "#15AFD0" : "#fff",
                        fontSize: "28px",
                        backgroundColor: !data?.children?.length > 0 && "#15AFD0"
                    }}
                >
                    {numeral(data?.value).format(format?.[chartData?.format]).toUpperCase()}
                </div>
                <div style={{ fontSize: "24px" }}>{data?.label}</div>
            </div>
        );
    }, [chartData]);

    const viewConfig = React.useMemo(() => {
        return new DendogramChartConfig({
            features: {
                controls: true,
                defaultZoom: 1,
                linkWidthCorrelation: true,
                maxZoom: 2,
                minimap: false,
                minZoom: 0.5,
                paneMoveable: true,
                preventScrolling: false,
                zoomOnScroll: false,
            },
            collapseConfig: {
                enabled: true,
                disappearType: 'hide',
            },
            node: {
                renderContent: renderNodeContent,
                dimensions: {
                    height: 10,
                    width: 300,
                },
            },
            layout: "horizontal",
            style: {
                link: {
                    stroke: "#15afd0",
                    strokeWidth: 5,
                    strokeOpacity: 1,
                },
                linkValueBackground: {
                    fill: "none",
                },
                linkValueText: {
                    fill: "none",
                },
            },
        });
    }, [renderNodeContent]);

    if (error) {
        return (
            <LegacyNoData height={"80vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <>
            {
                loading ?
                    <LegacyNoData height={"80vh"} loading={true} /> :
                    <>
                        {
                            chartData &&
                            <Visualization
                                height={"80vh"}
                                viewConfig={viewConfig}
                                dataConfig={{
                                    structureType: 'processed'
                                }}
                                dataset={{
                                    data: chartData
                                }}
                            />
                        }
                    </>
            }
        </>
    );
};

export default DendogramCollapse;