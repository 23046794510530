import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd';
import { Input, TextArea, Button, Select, Card } from '@pai-ui/core';
// import { Checkbox } from '@pai-ui/core/components/checkbox';
import { Delete } from '@styled-icons/fluentui-system-filled/Delete';
import { PlusSquareOutline } from '@styled-icons/evaicons-outline/PlusSquareOutline';
import DesignServiceHelper from "@xai/pages/design-experiment/service-helper";
import { UserContext } from "@xai/providers/with-auth";
import './style.css';
import TabGrid from './TabGrid';
import { Form } from 'antd';
import { notification } from "antd";


const SecondStepRevamp = ({ ExperimentFormConfig, firstStepTagLinesList, setfirstStepTagLinesList, setSelectedControl, formData, setFormData, showList, setShowList, responseList, setResponseList, noList, setNoList }) => {

    const [errorFlag, seterrorFlag] = useState(false);
    const [deleteErrormessage, setdeleteErrormessage] = useState(false);
    const [APIErrorFlag, setAPIErrorFlag] = useState(false);

    const { token } = React.useContext(UserContext);
    const service = new DesignServiceHelper(token);

    const firstStepDeleteHandler = (index) => {
        let locObj = { ...formData }
        locObj.taglines = locObj.taglines.filter((item, i) => i !== index)
        if (locObj.taglines[locObj.taglines.length - 1].split(' ').length < 3) { setdeleteErrormessage(true) } else { setdeleteErrormessage(false) }
        console.log('condition', locObj.taglines[locObj.taglines.length - 1].split(' ').length < 3, locObj.taglines[locObj.taglines.length - 1])
        setFormData(locObj)
        seterrorFlag(false)
    }
    const [form] = Form.useForm();

    useEffect(() => {
        if (formData) {
            form.setFieldsValue({
                keywords: formData.metaData.keywords,
                min: formData.count.minCount,
                max: formData.count.maxCount,
                tone: formData.metaData.tone
            });
        }
    }, [form, formData]);

    const firstStepAddHandler = () => {
        let locObj = { ...formData }
        locObj.taglines = [...locObj.taglines, ""]
        setFormData(locObj)
    }

    const firstStepInputChange = (value, index) => {
        let locObj = { ...formData }
        let locVar = value.replaceAll(',', ' ')
        locVar = locVar.split(' ')
        locVar = locVar.filter(i => i !== '')
        if (locVar.length < 3) {
            seterrorFlag(true)
            setdeleteErrormessage(true)
        } else {
            seterrorFlag(false)
            setdeleteErrormessage(false)
        }
        locObj.taglines[index] = value
        setFormData(locObj)
    }

    const inputChangeHandler = (Obj, type) => {
        let locObj = { ...formData }
        type === "ary" ?
            Obj.target.value === "" ?
                locObj.count[Obj.target.name] = [] :
                locObj.count[Obj.target.name] = [Obj.target.value] :
            type === "keywords" ?
                locObj.metaData[Obj.target.name] = Obj.target.value :
                type === "split" ?
                    locObj.metaData[Obj.target.name] = Obj.target.value :
                    locObj.count[Obj.target.name] = Obj.target.value
        setFormData(locObj)
    }

    const selectHandler = (value) => {
        let locObj = { ...formData };
        locObj.count.generateCount = value;
        setFormData(locObj);
    }

    // const generateOpenAIResponseCheckBoxHandler = (value) => {
    //     let locObj = { ...formData };
    //     locObj.basicInfo.generate_open_ai_response = value;
    //     setFormData(locObj);
    // }

    const generateHandler = async () => {
        let locFlag = false
        console.log('getFieldsValue', formData, form.getFieldsValue());
        form.validateFields()
            .then((values) => {
                console.log('success values => ', JSON.stringify(values));
            })
            .catch((errorInfo) => {
                console.log('failureCallback values => ', JSON.stringify(errorInfo));
            });
        if (formData.count.minCount === "" || formData.count.maxCount === "" || formData.metaData.tone === "" || formData.metaData.keywords === "" || (formData.metaData.keywords.length === 1 && formData.metaData.keywords[0] === "")) {
            locFlag = false
            var placement = "topRight";
            notification["error"]({
                message: 'Please Fill all the Mandatory Fields.',
                placement,
            });
        } else {
            locFlag = true
        }
        if (locFlag) {
            setAPIErrorFlag(false);
            setShowList(true)
            setNoList(false)
            console.log('formData API Trig', formData);
            let locTagline = formData.taglines
            let locMetaData = formData.metaData.keywords.split(",");
            locMetaData = locMetaData.map((item, i) => {
                if (item.startsWith(i)) {
                    return item
                } else {
                    return `${i + 1})${item}`
                }
            })
            locTagline = locTagline.map((item, i) => {
                if (item.startsWith(i)) {
                    return item
                } else {
                    return `${i + 1})${item}`
                }
            })
            let respInput = {
                "taglines": [
                    `You are an agent trying to create multiple different customer taglines for a ${formData.metaData.industry} company. Each tagline should include one of the following concepts/keywords: ${String(locMetaData.map(i => { return i })).replaceAll(',', '')}.Example taglines are: ${String(locTagline.map(i => { return i }))}. Please create ${formData.count.generateCount} tagline variations, with at least ${formData.count.minCount} and a maximum of ${formData.count.maxCount}, containing a ${formData.metaData.tone} tone`
                ],
                "tagvariantcount": formData.count.generateCount,
                "min": Number(formData.count.minCount) ? Number(formData.count.minCount) : formData.count.minCount,
                "max": Number(formData.count.maxCount) ? Number(formData.count.maxCount) : formData.count.maxCount
            }
            let generateOpenAIResponse = formData?.basicInfo?.generate_open_ai_response;
            console.log('getTagLines Called', respInput, generateOpenAIResponse);
            await service.getDesignExperimentTagLines(respInput, generateOpenAIResponse)
                .then(async (res) => {
                    console.log('getTagLines Response', res);
                    Object.values(res).forEach(function (value) {
                        if (value.length === 0) {
                            setNoList(true)
                        } else {
                            value.map((ittt) => {
                                if (ittt !== "") {
                                    responseList.push(ittt.replaceAll(".", "").replaceAll('"', "").replaceAll(":", " "))
                                }
                            })
                            setNoList(false)
                        }
                    })
                    let unique = [...new Set(responseList)];
                    setResponseList(unique);

                    let locAry = unique.map((it, index) => {
                        return {
                            id: index,
                            name: it,
                            fav: (index < 4) ? true : false,
                            liked: false,
                            disliked: false,
                            new: false,
                            edit: false,
                            control: false,
                            weight: 0
                        }
                    })
                    setfirstStepTagLinesList(locAry)
                    setShowList(false)
                })
                .catch((err) => {
                    console.log('getTagLines Error', err);
                    setAPIErrorFlag(true);
                });

        }
    }

    return (
        <>
            <Row>
                <Col span={12}>
                    <Form form={form} name="tagline" autoComplete="off" style={{ marginRight: "20px" }}>
                        <div style={{ marginBottom: "20px", /* height: "40vh"  */}} >
                            <Card className="custom-ant-card-no-padding">
                                <Row style={{ justifyContent: "space-between", margin: "20px" }}>
                                    <Col span={5}>
                                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "taglines").primary_label}
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "taglines").mandatory && "*"}
                                        </p>
                                        <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "taglines").secondary_label}
                                        </p>
                                    </Col>
                                    <Col span={17} offset={1}>
                                        <div style={{ overflowY: "auto", height: "30vh" }}>
                                            {
                                                formData.taglines.map((i, index) => (
                                                    <>
                                                        <Row style={{ margin: "10px 0px" }}>
                                                            <Col span={23}>
                                                                <Input
                                                                    placeholder={ExperimentFormConfig.elements.find(it => it.itemKey === "taglines").placeholder}
                                                                    style={ExperimentFormConfig.elements.find(it => it.itemKey === "taglines").style}
                                                                    defaultValue={i}
                                                                    value={i}
                                                                    onChange={(e) => firstStepInputChange(e.target.value, index)}
                                                                />
                                                            </Col>
                                                            <Col span={1}>
                                                                <Delete
                                                                    color={((formData.taglines.length === 1 && index === 0)) ? '' : '#15afd0'}
                                                                    style={{ margin: "5px 0px", cursor: ((formData.taglines.length === 1 && index === 0)) ? "" : "pointer" }}
                                                                    onClick={() => ((formData.taglines.length === 1 && index === 0)) ? "" : firstStepDeleteHandler(index)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))
                                            }
                                            <Row>
                                                <span style={{ color: "red" }}>
                                                    {(deleteErrormessage && !formData.taglines.includes('')) && 'Minimum 3 Words required'}
                                                </span>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col span={24} align="right">
                                                <PlusSquareOutline
                                                    color={formData.taglines.includes('') ? '' : errorFlag ? "" : '#15afd0'}
                                                    width={20}
                                                    style={{ cursor: formData.taglines.includes('') ? '' : errorFlag ? '' : "pointer" }}
                                                    onClick={() => formData.taglines.includes('') ? '' : errorFlag ? '' : firstStepAddHandler()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Card>
                        </div>
                        <div style={{ marginBottom: "20px" }} >
                            <Card className="custom-ant-card-no-padding">
                                <Row style={{ justifyContent: "space-between", margin: "20px" }}>
                                    <Col span={5}>
                                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").primary_label}
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").mandatory && "*"}
                                        </p>
                                        <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                            {ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").secondary_label}
                                        </p>
                                    </Col>
                                    <Col span={17} offset={1}>

                                        <Form.Item
                                            name='keywords'
                                            initialValue={formData.metaData.keywords}
                                            rules={[ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").rules]}
                                        >
                                            {/* {console.log('keywords', formData.metaData.keywords)} */}
                                            <TextArea
                                                rows={3}
                                                name="keywords"
                                                placeholder={ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").placeholder}
                                                // defaultValue={ExperimentFormConfig.elements.find(it => it.itemKey === "keywords").defaultOption}
                                                value={formData.metaData.keywords}
                                                onChange={(e) => inputChangeHandler(e, "split")}
                                            />
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <div className={""} style={{ marginBottom: "20px" }} >
                            <Row>
                                <Col span={12}>
                                    <Card className="custom-ant-card-no-padding" style={{ marginRight: "20px" }}>
                                        <Row style={{ justifyContent: "space-between", padding: "20px" }}>
                                            <Col span={12}>
                                                <p style={{ fontSize: "1rem", margin: "0px" }}>
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").primary_label}
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").mandatory && "*"}
                                                </p>
                                                <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").secondary_label}
                                                </p>
                                            </Col>
                                            <Col span={12} style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                <Row style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                    <Col span={10} style={{ display: "flex", flexDirection: "row" }}>
                                                        <Form.Item
                                                            name="min"
                                                            rules={[ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").rules]}
                                                            initialValue={formData.count.minCount}

                                                        >
                                                            {/* {console.log('minCount', formData.count.minCount)} */}
                                                            <Input
                                                                name='minCount'
                                                                placeholder={ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").placeholder}
                                                                style={ExperimentFormConfig.elements.find(it => it.itemKey === "minCount").style}
                                                                // defaultValue={formData.count.minCount}
                                                                value={formData.count.minCount}
                                                                onChange={(e) => inputChangeHandler(e)}
                                                            />
                                                        </Form.Item>

                                                    </Col>
                                                    <span style={{ margin: "5px 10px" }}>-</span>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            name="max"
                                                            rules={[ExperimentFormConfig.elements.find(it => it.itemKey === "maxCount").rules]}
                                                            initialValue={formData.count.maxCount}
                                                        >
                                                            {/* {console.log('maxCount', formData.count.maxCount)} */}
                                                            <Input
                                                                name='maxCount'
                                                                placeholder={ExperimentFormConfig.elements.find(it => it.itemKey === "maxCount").placeholder}
                                                                style={ExperimentFormConfig.elements.find(it => it.itemKey === "maxCount").style}
                                                                // defaultValue={formData.count.maxCount}
                                                                value={formData.count.maxCount}
                                                                onChange={(e) => inputChangeHandler(e)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card className="custom-ant-card-no-padding">
                                        <Row style={{ borderRadius: "10px", padding: "20px" }}>
                                            <Col span={12}>
                                                <p style={{ fontSize: "1rem", margin: "0px" }}>
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "tone").primary_label}
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "tone").mandatory && "*"}
                                                </p>
                                                <p style={{ fontSize: "0.75rem", margin: "0px", opacity: 0.5, fontWeight: "300" }}>
                                                    {ExperimentFormConfig.elements.find(it => it.itemKey === "tone").secondary_label}
                                                </p>
                                            </Col>
                                            <Col span={11} offset={1} style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                <Form.Item
                                                    name="tone"
                                                    rules={[ExperimentFormConfig.elements.find(it => it.itemKey === "tone").rules]}
                                                    initialValue={formData.metaData.tone}
                                                >
                                                    {/* {console.log('tone', formData.metaData.tone)} */}
                                                    <Input
                                                        name="tone"
                                                        placeholder={ExperimentFormConfig.elements.find(it => it.itemKey === "tone").placeholder}
                                                        style={ExperimentFormConfig.elements.find(it => it.itemKey === "tone").style}
                                                        value={formData.metaData.tone}
                                                        onChange={e => inputChangeHandler(e, "keywords")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", alignItems: "baseline" }}>
                            {/* <div>
                                <Checkbox
                                    label="Generate Taglines Using OpenAI"
                                    checked={formData?.basicInfo?.generate_open_ai_response}
                                    onChange={(e) => { generateOpenAIResponseCheckBoxHandler(e?.target?.checked) }}
                                />
                            </div> */}
                            <div>
                                <Select
                                    style={{ width: "50px" }}
                                    value={formData.count.generateCount}
                                    placeholder="Select item"
                                    options={[
                                        {
                                            id: 5,
                                            name: 5
                                        },
                                        {
                                            id: 10,
                                            name: 10
                                        }
                                    ]}
                                    onChange={selectHandler}
                                />
                            </div>
                            <div>
                                <Form.Item name='submit'>
                                    <Button
                                        htmlType="submit"
                                        disabled={
                                            formData.taglines.includes('') ||
                                                formData.taglines.length === 0 ?
                                                true :
                                                (errorFlag) ?
                                                    true : false}
                                        onClick={() => generateHandler()}
                                    >
                                        Generate
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Col>
                <Col span={12}>
                    <Card className="custom-ant-card-no-padding">
                        <div style={{ marginBottom: "10px", padding: "20px", marginLeft: "20px", height: "76vh", overflowY: "auto" }}>
                            <TabGrid
                                firstStepTagLinesList={firstStepTagLinesList}
                                setfirstStepTagLinesList={setfirstStepTagLinesList}
                                setSelectedControl={setSelectedControl}
                                showList={showList}
                                noList={noList}
                                setNoList={setNoList}
                                APIErrorFlag={APIErrorFlag}
                                generateHandler={generateHandler}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SecondStepRevamp