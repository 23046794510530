import moment from "moment";

export const tagline_experiment_unique_identifier_label = "taglines";

export const default_experiment_unique_identifier_label = "default";

export const convertRawExpData = (rawdata) => {
    let b = {};
    if (rawdata.weighted_variable && rawdata.weighted_variable > 0) {
        for (var i in rawdata.buckets) {
            var temp = Object.keys(rawdata.buckets[i])[0];

            b["bucket_" + rawdata.weighted_variable + "_" + temp] = Object.values(
                rawdata.buckets[i]
            )[0];

            console.log("bucket[i]-->", b);
        }
    }
    rawdata = {
        ...rawdata,
        percent_audience: rawdata.percent_audience * 100,


        ...b,
    };
    if (rawdata.start_date != "") {
        rawdata["test_duration"] = [moment(rawdata.start_date), moment(rawdata.end_date)]
    }

    var allKeys = Object.keys(rawdata);

    // rawdata.array.forEach((element) => {
    //   console.log("element-->", element.key);
    // });

    console.log("rawdata-->", rawdata);
    let d = allKeys.map((k) => {
        return { name: k, value: rawdata[k], id: rawdata[k] };
    });

    return d;
};

export const default_add_new_rulebuilder_config = {
    "rules": [
        {
            "name": "R1",
            "value": {
                "id": "transactions12M",
                "operator": "equals",
                "value": 0,
            }
        },
        {
            "name": "R2",
            "value": {
                "id": "subscriptionflag",
                "operator": "equals",
                "value": "No"
            }
        }
    ]
}
