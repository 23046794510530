import styled from 'styled-components'

const Panel = styled.div`
    padding-left: ${props => props.padding || props.paddingLeft || props.paddingHorizontal ? props.theme.gutter.inner : 0}px;
    padding-right: ${props => props.padding || props.paddingRight || props.paddingHorizontal ? props.theme.gutter.inner : 0}px;
    padding-top: ${props => props.padding || props.paddingTop || props.paddingVertical ? props.theme.gutter.inner : 0}px;
    padding-bottom: ${props => props.padding || props.paddingBottom || props.paddingVertical ? props.theme.gutter.inner : 0}px;
    flex-grow: ${props => props.grow ? 1: 0};
    background: ${props => props.background ? "rgba(255,255,255,0.03)" :"none"};
    border-radius: ${props => props.background ? props.theme.borderRadius : 0}px;
    border-bottom: ${props => props.borderVertical || props.borderBottom ? "1px solid rgba(255,255,255,0.05)" : "0px"};
    border-top: ${props => props.borderVertical || props.borderTop ? "1px solid rgba(255,255,255,0.05)" : "0px"};
    border-left: ${props => props.borderHorizontal || props.borderLeft ? "1px solid rgba(255,255,255,0.05)" : "0px"};
    border-right: ${props => props.borderHorizontal || props.borderRight ? "1px solid rgba(255,255,255,0.05)" : "0px"};
    ${props => props.stripe ? `border-left: 7px solid ${props.stripe}` : ""}
    ${props => props.scrollableY ? "overflow-y:auto;":""}
    ${props => props.scrollableX ? "overflow-x:auto;":""}

    ${props => {
        if(props.hover){
            return `
                transition: opacity ease 200ms;
                
                &:hover{
                    opacity: 1!important;
                }
            `
        }
    }}

    ${props => {
        if(props.hideScroll){
            return `
            ::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
            `
        }
    }}
`

export default Panel