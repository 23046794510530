import React, { useState, useEffect } from 'react'
import { Panel } from '@xai/library/legacy/containers/layout'
import { Row, Col, Input, Affix, } from 'antd';
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import { PieChart, BarChart } from '@xai/library/legacy/components/chart'
import { ZsButton, ZsSpace } from '@xai/assets/generic-ant-d-components/quick-access-ant-d'
import numeral from 'numeral'
import { Visualization, LineChartConfig } from '@pai-ui/core/components/visualization';
import {
    // ZsSelect,

} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { Warning } from "@styled-icons/entypo/Warning"
import { ShoppingCartOutlined } from '@ant-design/icons';
import { InformationCircle } from '@styled-icons/heroicons-outline/InformationCircle'
import { Popover } from 'antd';
import { Carousel } from 'antd';
import "./index.css";
import ChartWrapper from "@xai/assets/generic-components/visualization/components/wrapper";
import { WaveLoading } from 'styled-spinkit';
const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"]


export const Metric = ({ title, value, align }) => (
    <Row>
        <div style={{ textAlign: align || "left", marginLeft: (align || "left") !== "left" ? 30 : 0, marginRight: (align || "left") !== "right" ? 30 : 0 }}>

            <h2
                style={{
                    margin: 0,
                    marginBottom: 0,
                    fontSize: "16px",
                    fontWeight: "normal",
                    lineHeight: "100%",

                }}
            >{value}</h2>
            <small style={{ display: "block" }}>{title}</small>
        </div>

    </Row>

)

const MetricIcons = {
    "cart": (<ShoppingCartOutlined style={{ fontSize: '38px', color: colors[0] + "CC" }} />),

}

export const MetricSummary = ({ name, value, icon, noBg, subValue }) => (

    <Panel flexible background={noBg ? "" : "rgba(255,255,255,0.03)"} paddingX round>

        <Panel flexible stack="horizontal" center paddingY>
            <Panel width="50px">
                {MetricIcons[icon]}
            </Panel>
            <Panel width="10px"></Panel>
            <Panel flexible>
                <h2 style={{ margin: 0, color: colors[0], fontSize: '28px', fontWeight: 400, lineHeight: "100%", textTransform: "uppercase", marginBottom: subValue ? -10 : 0 }}>
                    {value}
                    {subValue &&
                        <small style={{ color: colors[1], opacity: 0.75, /* fontSize: "50%", */ fontSize: '13px' }}> / {subValue}</small>
                    }
                </h2>
                <p style={{ margin: 0, width: "auto" }}>{name}</p>
            </Panel>

        </Panel>
    </Panel>
)

const CompareSection = ({ height, name, child1, child2, child3, last, legend, child4 }) => (
    <Row style={{ background: "rgba(255,255,255,0.03)", borderBottom: "3px solid #111419", borderBottomLeftRadius: last ? 20 : 0, borderBottomRightRadius: last ? 20 : 0 }}>
        <Col span={4} style={{ paddingLeft: 10, borderRight: "3px solid #111419" }}>
            <Panel height={height + "px"} center paddingX style={{ textAlign: "center" }}>
                <h2
                    style={{
                        margin: 0,
                        marginBottom: 0,
                        fontSize: "16px",
                        fontWeight: "normal",
                        lineHeight: "100%",

                    }}
                >
                    {name}
                </h2>
                {legend && [
                    <Panel height="10px" key={0} />,
                    <img key={0} style={{ transform: "translateX(-5px)" }} src={legend.image} width={legend.width} />
                ]}
            </Panel>
        </Col>
        <Col span={(child3 && child4) ? 5 : 10} style={{ borderRight: "3px solid #111419" }}>
            <Panel flexible paddingY paddingX height={height + "px"}>
                {child1}
            </Panel>
        </Col>
        <Col span={(child3 && child4) ? 5 : 10} style={{ borderRight: "3px solid #111419" }}>
            <Panel flexible paddingY paddingX height={height + "px"}>
                {child2}
            </Panel>
        </Col>
        {child3 && <Col span={5} style={{ borderRight: "3px solid #111419" }}>
            <Panel flexible paddingY paddingX height={height + "px"}>
                {child3}
            </Panel>

        </Col>}
        {child4 && <Col span={5}>
            <Panel flexible paddingY paddingX height={height + "px"}>
                {child4}
            </Panel>

        </Col>}
    </Row>
)

export const InlineInput = ({ label, placeholder }) => {
    <Panel stack="horizontal">
        <p style={{ margin: "0px 8px 0px", flexShrink: 0, color: colors[0] }}>{label[0]}</p>
        <Input placeholder={placeholder} style={{ minWidth: "none", maxWidth: 45, marginTop: -5 }} />
        <p style={{ margin: "0px 0px 0px 8px", color: colors[0], flexShrink: 0, }}>{label[1]}</p>
    </Panel>,
        <Panel height="7px" />
}

const TableCol = ({ value, lift, isTest, format, align = 'center', warningtext, number, createAudienceFlag }) => (


    <Row>
        <Col span={24}>
            <Row>
                <Col span={isTest && lift ? 8 : isTest ? 24 : 24} style={{ textAlignLast: isTest && lift ? "center" : align }}>
                    <ZsSpace style={{ marginLeft: isTest ? 0 : 0 }}>
                        <h2
                            style={{
                                margin: 0,
                                marginBottom: 0,
                                fontSize: "16px",
                                fontWeight: "normal",
                                lineHeight: "100%",

                            }}
                        >
                            {console.log('aayush', value, format)}
                            {format ? numeral(value).format(format).toUpperCase() : value}
                            {isTest && createAudienceFlag && <Popover
                                placement="top"

                                content={warningtext}
                                trigger="hover"
                            >
                                <Warning value={lift} style={{ width: number && number == 2 ? "5%" : "10%", position: "absolute", marginLeft: number == 2 ? "2%" : "3%", marginTop: number == 2 ? "-1%" : "-2%" }}
                                    color={"#FFFF00"}
                                />
                            </Popover>}
                        </h2>

                    </ZsSpace>

                </Col>
                {isTest && lift && (
                    <>
                        <Col span={8} style={{ textAlignLast: "start", paddingLeft: '7px' }}>
                            <PosNegValue value={lift} valueFormat={'0.00%'}
                                fontSize={"16px"}

                                ZeroColor={"rgb(225 108 0 / 98%)"}
                                NegColor={"rgb(232 21 38)"}
                                PosColor={"rgb(11 192 66)"}

                                ZeroRange={{ minNegZero: -0.00049, minPosZero: 0.00049 }}
                            />
                        </Col>
                        {isTest && <Col span={8} style={{ textAlignLast: "start", paddingLeft: '7px' }}>
                            <Popover
                                placement="top"

                                content="Warning: Test groups not homogenous. 
                                Suggest alternative sampling method.​"
                                trigger="hover"
                            >
                                <Warning value={lift} style={{ width: "35%" }}
                                    color={"#FFFF00"}
                                />
                            </Popover>

                        </Col>}
                    </>

                )}

            </Row>
        </Col>
    </Row>
);

const ABTestAnalyse = ({ value, createAudienceFlag }) => {
    const [selection, setSelection] = useState()
    const [data, setData] = useState();
    const [datacopy, setdatacopy] = useState();
    const [fulldata, setfulldata] = useState()
    const [config, setConfig] = useState()
    const [names, setnames] = useState()

    useEffect(() => {
        if (!value && value == undefined) {
            value = [
                {
                    "name": [
                        "Variant A",
                        "Control A",
                        "Variant B",
                        "Control B",
                        "Variant C",
                        "Control C",
                    ],
                    "warning": "\"Store Type is not Franchise% Drive Thru Sales is less than 20%\r\n        Region is equal to Midwest\r\n        Stratified Sampling by State: 20%\"",
                    "conversion": {
                        "config": {
                            "first": {
                                "name": "Variant A"
                            },
                            "second": {
                                "name": "Control A"
                            },
                            "third": {
                                "name": "Variant B"
                            },
                            "fourth": {
                                "name": "Control B"
                            },
                            "fifth": {
                                "name": "Variant C"
                            },
                            "six": {
                                "name": " Control C"
                            }
                        },
                        "data": {
                            "fields": [
                                "year",
                                "Variant A",
                                "Control A",
                                "Variant B",
                                "Control B",
                                "Variant C",
                                "Control C",
                            ],
                            "data": [
                                ["1-Oct,2020", 21, 22, 22, 18, 16, 19],
                                ["8-Oct,2020", 24, 26, 26, 17, 14, 16],
                                ["15-Oct,2020", 23, 23, 21, 20, 19, 20],
                                ["22-Oct,2020", 21, 27, 21, 19, 17, 18],
                                ["29-Oct,2020", 21, 22, 20, 16, 13, 13],
                                ["5-Nov,2020", 23, 29, 21, 19, 17, 18],
                                ["12-Nov,2020", 20, 32, 18, 16, 18, 16],
                                ["19-Nov,2020", 25, 35, 22, 20, 18, 20],
                                ["26-Nov,2020", 22, 29, 22, 20, 17, 19],
                                ["3-Dec,2020", 23, 23, 21, 20, 17, 17],
                                ["10-Dec,2020", 23, 20, 21, 17, 18, 17],
                                ["17-Dec,2020", 24, 22, 25, 16, 13, 15],
                                ["24-Dec,2020", 24, 26, 24, 15, 13, 14]
                            ]
                        }
                    },
                    "conversion_audience": {
                        "config": {
                            "first": {
                                "name": "Multi-Entrée only"
                            },
                            "second": {
                                "name": "Multi-Entree + Kids Meal"
                            },
                            "third": {
                                "name": "No Multi-Entrée + Kids Meal"
                            },
                            "fourth": {
                                "name": "No Multi-Entrée + Family Feast"
                            }
                        },
                        "data": {
                            "fields": [
                                "year",
                                "Multi-Entrée only",
                                "Multi-Entree + Kids Meal",
                                "No Multi-Entrée + Kids Meal",
                                "No Multi-Entrée + Family Feast"
                            ],
                            "data": [
                                [
                                    "1-Jan",
                                    173250,
                                    173250,
                                    66825,
                                    66825,
                                    81675,
                                    81675,
                                    173250,
                                    173250
                                ],
                                [
                                    "8-Jan",
                                    182368.42105263157,
                                    200380.11695906433,
                                    66825,
                                    80043.13186813188,
                                    89842.5,
                                    90808.54838709679,
                                    182368.42105263157,
                                    200380.11695906433
                                ],
                                [
                                    "15-Jan",
                                    136776.31578947368,
                                    160416.66666666666,
                                    66825,
                                    77105.76923076925,
                                    106177.5,
                                    111885.9677419355,
                                    136776.31578947368,
                                    160416.66666666666
                                ],
                                [
                                    "22-Jan",
                                    164131.5789473684,
                                    210736.84210526315,
                                    77962.5,
                                    90813.46153846155,
                                    114345,
                                    129099.19354838715,
                                    164131.5789473684,
                                    210736.84210526315
                                ],
                                [
                                    "29-Jan",
                                    136776.31578947368,
                                    157039.47368421053,
                                    77962.5,
                                    77105.76923076925,
                                    122512.5,
                                    129099.19354838712,
                                    136776.31578947368,
                                    157039.47368421053
                                ],
                                [
                                    "5-Feb",
                                    164131.5789473684,
                                    186421.05263157896,
                                    74250,
                                    74250,
                                    89842.5,
                                    91774.59677419356,
                                    164131.5789473684,
                                    186421.05263157896
                                ],
                                [
                                    "12-Feb",
                                    136776.31578947368,
                                    141842.1052631579,
                                    70537.5,
                                    73638.04945054946,
                                    81675,
                                    82553.2258064516,
                                    136776.31578947368,
                                    141842.1052631579
                                ],
                                [
                                    "19-Feb",
                                    182368.42105263157,
                                    184619.8830409357,
                                    66825,
                                    74902.74725274726,
                                    98010,
                                    109602.58064516132,
                                    182368.42105263157,
                                    184619.8830409357
                                ],
                                [
                                    "26-Feb",
                                    296348.6842105263,
                                    200942.98245614037,
                                    66825,
                                    69028.02197802196,
                                    89842.5,
                                    89842.5,
                                    296348.6842105263,
                                    200942.98245614037
                                ],
                                [
                                    "4-Mar",
                                    164131.5789473684,
                                    190473.68421052626,
                                    81675,
                                    91547.80219780222,
                                    106177.5,
                                    103894.11290322582,
                                    164131.5789473684,
                                    190473.68421052626
                                ],
                                [
                                    "11-Mar",
                                    136776.31578947368,
                                    168859.64912280702,
                                    74250,
                                    78329.67032967033,
                                    98010,
                                    98010.00000000001,
                                    136776.31578947368,
                                    168859.64912280702
                                ]
                            ]
                        }
                    },
                    "infoicon": [
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                        "MSA is equal to Los Angeles-Long Beach-Anaheim Device is equal to DesktopStratified Sampling by New vs Returning User: 50%",
                    ],
                    "kpi": [
                        {
                            "name": "Population Size",
                            "value": [
                                {
                                    "value": 75000,
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": 750000,
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": 1050000,
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": 1050000,
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": 812500,
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": 812500,
                                    "warning": false,
                                    "warningtext": ""
                                }
                            ]
                        },
                        {
                            "name": "Conversion Rate",
                            "value": [
                                {
                                    "value": "3.0%",
                                    "warning": false,
                                    "warningtext": "",
                                    "format": "0.0%"
                                },
                                {
                                    "value": "3.0%",
                                    "warning": false,
                                    "warningtext": "Warning: Variant 1B is +18% vs Control 1"
                                },
                                {
                                    "value": "1.3%",
                                    "warning": true,
                                    "warningtext": "Warning: Variant B is +7% vs Control B"
                                },
                                {
                                    "value": "1.2%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "1.8%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "1.8%",
                                    "warning": false,
                                    "warningtext": ""
                                }
                            ]
                        },
                        {
                            "name": "Daily Transactions",
                            "value": [
                                {
                                    "value": "25K/day",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "22K/day",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "36K/day",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "32K/day",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "27K/day",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "27K/day",
                                    "warning": false,
                                    "warningtext": ""
                                }
                            ]
                        },
                        {
                            "name": "% Logged in Users",
                            "value": [
                                {
                                    "value": "8.4%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "9.0%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "8.2%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "18.9%",
                                    "warning": true,
                                    "warningtext": "Warning: Variant 2A is +35% vs Control 2"
                                },
                                {
                                    "value": "15.0%",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "14.6%",
                                    "warning": false,
                                    "warningtext": ""
                                }
                            ]
                        },
                        {
                            "name": "Average Order Value",
                            "value": [
                                {
                                    "value": "$22",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "$23",
                                    "warning": false,
                                    "warningtext": "Warning: Variant 1B is +18% vs Control 1"
                                },
                                {
                                    "value": "$12",
                                    "warning": false,
                                    "warningtext": "Warning: Variant B is +7% vs Control B"
                                },
                                {
                                    "value": "$11",
                                    "warning": false,
                                    "warningtext": "Warning: Variant C is -12% vs Control C"
                                },
                                {
                                    "value": "$15",
                                    "warning": false,
                                    "warningtext": ""
                                },
                                {
                                    "value": "$17",
                                    "warning": true,
                                    "warningtext": "Warning: Variant C is -12% vs Control C"
                                }
                            ]
                        },
                        {
                            "name": "Visitors per Hour",
                            "value": [
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [2,
                                        8,
                                        22,
                                        35,
                                        21,
                                        12,
                                        100]
                                },
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [3.00,
                                        1,
                                        8,
                                        23,
                                        34,
                                        22,
                                        12,
                                        100]
                                },
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [
                                        1,
                                        2,
                                        10,
                                        32,
                                        35,
                                        13,
                                        8,
                                        100]
                                },
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [
                                        3,
                                        12,
                                        30,
                                        38,
                                        10,
                                        7,
                                        100]
                                },
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [15,
                                        35,
                                        22,
                                        12,
                                        15,
                                        100]
                                },
                                {
                                    "labels": [
                                        "12a-4a",
                                        "4a-8a",
                                        "8a-12p",
                                        "12p-4p",
                                        "4p-8p",
                                        "8p-12a"
                                    ],
                                    "data": [1,
                                        13,
                                        33,
                                        25,
                                        10,
                                        18,
                                        100]
                                }
                            ]
                        },
                        {
                            "name": "New vs Returning Users",
                            "value": [
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",

                                    ],
                                    "data": [45, 55]
                                },
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",

                                    ],
                                    "data": [44, 56]
                                },
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",
                                    ],
                                    "data": [63, 37]
                                },
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",

                                    ],
                                    "data": [65, 37]
                                },
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",

                                    ],
                                    "data": [65, 35]
                                },
                                {
                                    "labels": [
                                        "New Users",
                                        "Returning Users",

                                    ],
                                    "data": [54, 46]
                                }
                            ]
                        },
                        {
                            "name": "Sales by Category",
                            "value": [
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [10,
                                        25,
                                        36,
                                        11,
                                        11,
                                        7,
                                        100]
                                },
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [8,
                                        26,
                                        38,
                                        10,
                                        10,
                                        8,
                                        100]
                                },
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [20,
                                        11,
                                        15,
                                        10,
                                        9,
                                        35,
                                        100]
                                },
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [15,
                                        14,
                                        32,
                                        17,
                                        13,
                                        9,
                                        100]
                                },
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [14,
                                        13,
                                        30,
                                        14,
                                        15,
                                        14,
                                        100]
                                },
                                {
                                    "labels": [
                                        "Pizza",
                                        "Salads",
                                        "Sandwiches",
                                        "Sides",
                                        "Soups",
                                        "Warm Bowls"
                                    ],
                                    "data": [18,
                                        20,
                                        28,
                                        9,
                                        18,
                                        7,
                                        100]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
        console.log('analyzedata22', value)
        setSelection(value[0].conversion.data.fields.filter(x => x != 'year'))

        setdatacopy(value[0].conversion.data)
        setConfig(value[0].conversion.config)
        setfulldata(value[0])
        setnames(value[0].name)
        if (createAudienceFlag) {
            setData(value[0].conversion.data)
        } else {
            setData(value[0].conversion_audience.data)
        }
    }, [])

    return (selection && selection.length > 0 &&
        datacopy && Object.keys(datacopy).length > 0 &&
        config && Object.keys(config).length > 0 &&
        fulldata && Object.keys(fulldata).length > 0
        // && value && value.length > 0
        && names && names.length > 0
        ?
        <>

            <div>
                <Visualization
                    title="Profit per Customer"
                    // subTitle="Revenue per Customer"
                    height={450}

                    viewConfig={new LineChartConfig({
                        baseColors: ['aqua', 'yellow', 'green', 'purple', 'orange'],
                        series: {
                            getConfig: (fieldName) => {
                                const config = {};

                                return {
                                    ...config[fieldName], label: {
                                        show: false
                                    },
                                    dot: {
                                        show: false,
                                    },
                                };
                            }
                        },
                        axes: {
                            x: {
                                dataKey: 'year',
                            },
                        },
                    })}
                    dataset={data}
                />
            </div>

            <ChartWrapper style={{ paddingTop: "20px !important" }} id={'aayush1'}>
                {({ width }) => (
                    <div style={{ width, paddingTop: "20px !important", marginTop: "-15%" }} id={'aayush2'}>
                        <Carousel arrows={true} dots={false} infinite={false} id={'aayush3'}>
                            <div style={{ padding: "5px", width: { width } }}>

                                <Row gutter={[25, 25]} id="page-container">

                                    <Col span={24}>
                                        <Affix offsetTop={0} target={() => document.getElementById("page-container")}>
                                            <Row style={{ background: "rgba(255,255,255,0.03)", borderBottom: "3px solid #111419" }}>
                                                <Col span={4} style={{ paddingLeft: 10, borderRight: "3px solid #111419" }}>
                                                    <Panel height="60px" center paddingX>
                                                        <ZsSpace>

                                                            <h2
                                                                style={{
                                                                    margin: 0,
                                                                    marginBottom: 0,
                                                                    fontSize: "16px",
                                                                    fontWeight: "normal",
                                                                    lineHeight: "100%",

                                                                }}
                                                            >

                                                                {"Parameters"}
                                                            </h2>


                                                        </ZsSpace>
                                                    </Panel>
                                                </Col>
                                                <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                    <Panel height="60px" center paddingX>
                                                        <ZsSpace>
                                                            <Popover content={fulldata.infoicon[0]} >
                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                            </Popover>
                                                            <h2
                                                                style={{
                                                                    margin: 0,
                                                                    marginBottom: 0,
                                                                    fontSize: "16px",
                                                                    fontWeight: "normal",
                                                                    lineHeight: "100%",

                                                                }}
                                                            >

                                                                {createAudienceFlag ? fulldata.name[0] : fulldata.audience_name[0]}
                                                            </h2>


                                                        </ZsSpace>                                        </Panel>
                                                </Col>
                                                <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                    <Panel height="60px" center paddingX>
                                                        <ZsSpace>
                                                            <Popover content={fulldata.infoicon[1]} >                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                            </Popover>
                                                            <h2
                                                                style={{
                                                                    margin: 0,
                                                                    marginBottom: 0,
                                                                    fontSize: "16px",
                                                                    fontWeight: "normal",
                                                                    lineHeight: "100%",

                                                                }}
                                                            >

                                                                {createAudienceFlag ? fulldata.name[1] : fulldata.audience_name[1]}
                                                            </h2>


                                                        </ZsSpace>                                        </Panel>
                                                </Col>
                                                <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                    <Panel height="60px" center paddingX>
                                                        <ZsSpace>
                                                            <Popover content={fulldata.infoicon[2]} >                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                            </Popover>
                                                            <h2
                                                                style={{
                                                                    margin: 0,
                                                                    marginBottom: 0,
                                                                    fontSize: "16px",
                                                                    fontWeight: "normal",
                                                                    lineHeight: "100%",

                                                                }}
                                                            >

                                                                {createAudienceFlag ? fulldata.name[2] : fulldata.audience_name[2]}
                                                            </h2>


                                                        </ZsSpace>                                        </Panel>
                                                </Col>
                                                <Col span={5}>
                                                    <Panel height="60px" center paddingX>
                                                        <ZsSpace>
                                                            <Popover content={fulldata.infoicon[3]} >                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                            </Popover>
                                                            <h2
                                                                style={{
                                                                    margin: 0,
                                                                    marginBottom: 0,
                                                                    fontSize: "16px",
                                                                    fontWeight: "normal",
                                                                    lineHeight: "100%",

                                                                }}
                                                            >

                                                                {createAudienceFlag ? fulldata.name[3] : fulldata.audience_name[3]}
                                                            </h2>


                                                        </ZsSpace>                                        </Panel>
                                                </Col>
                                            </Row>
                                        </Affix>

                                        <CompareSection
                                            name={fulldata.kpi[0].name}
                                            height={50}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[0].value[0].value} format={"#0a"} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[0].value[0].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[0].value[1].value} format={"#0a"} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[0].value[1].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[0].value[2].value} format={"#0a"} isTest={fulldata.kpi[0].value[2].warning} warningtext={fulldata.kpi[0].value[2].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[0].value[3].value} format={"#0a"} isTest={fulldata.kpi[0].value[3].warning} warningtext={fulldata.kpi[0].value[3].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                        />


                                        <CompareSection
                                            name={fulldata.kpi[1].name}
                                            height={50}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[1].value[0].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[0].warning} warningtext={fulldata.kpi[1].value[0].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[1].value[1].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[1].warning} warningtext={fulldata.kpi[1].value[1].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[1].value[2].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[2].warning} warningtext={fulldata.kpi[1].value[2].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[1].value[3].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[3].warning} warningtext={fulldata.kpi[1].value[3].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                        />
                                        <CompareSection
                                            name={fulldata.kpi[2].name}
                                            height={50}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[2].value[0].value} format={""} isTest={fulldata.kpi[2].value[0].warning} warningtext={fulldata.kpi[2].value[0].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[2].value[1].value} format={""} isTest={fulldata.kpi[2].value[1].warning} warningtext={fulldata.kpi[2].value[1].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[2].value[2].value} format={""} isTest={fulldata.kpi[2].value[2].warning} warningtext={fulldata.kpi[2].value[2].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[2].value[3].value} format={""} isTest={fulldata.kpi[2].value[3].warning} warningtext={fulldata.kpi[2].value[3].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                        />
                                        <CompareSection
                                            name={fulldata.kpi[3].name}
                                            height={50}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[3].value[0].value} format={""} isTest={fulldata.kpi[3].value[0].warning} warningtext={fulldata.kpi[3].value[0].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[3].value[1].value} format={""} isTest={fulldata.kpi[3].value[1].warning} warningtext={fulldata.kpi[3].value[1].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[3].value[2].value} format={""} isTest={fulldata.kpi[3].value[2].warning} warningtext={fulldata.kpi[3].value[2].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[3].value[3].value} format={""} isTest={fulldata.kpi[3].value[3].warning} warningtext={fulldata.kpi[3].value[3].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                        />
                                        <CompareSection
                                            name={fulldata.kpi[4].name}
                                            height={50}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[4].value[0].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[0].warning} warningtext={fulldata.kpi[4].value[0].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[4].value[1].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[1].warning} warningtext={fulldata.kpi[4].value[1].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[4].value[2].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[2].warning} warningtext={fulldata.kpi[4].value[2].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <TableCol value={fulldata.kpi[4].value[3].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[3].warning} warningtext={fulldata.kpi[4].value[3].warningtext} createAudienceFlag={createAudienceFlag}></TableCol>
                                                </Panel>
                                            )}
                                        />


                                        <CompareSection
                                            name={fulldata.kpi[5].name}
                                            height={235}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <BarChart

                                                        labels={fulldata.kpi[5].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[5].value[0].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />

                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    {console.log('currentData', fulldata.kpi[5].value[1].data)}
                                                    <BarChart
                                                        labels={fulldata.kpi[5].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[5].value[1].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />


                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <BarChart
                                                        labels={fulldata.kpi[5].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[5].value[2].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <BarChart
                                                        labels={fulldata.kpi[5].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[5].value[3].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />
                                                </Panel>
                                            )}
                                        />
                                        <CompareSection
                                            name={fulldata.kpi[6].name}
                                            height={235}

                                            child1={(
                                                <Panel flexible paddingY>
                                                    <PieChart
                                                        data={fulldata.kpi[6].value[0].data}
                                                        labels={fulldata.kpi[6].value[0].labels}
                                                        hideToolTips
                                                    />
                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    <PieChart
                                                        data={fulldata.kpi[6].value[1].data}
                                                        labels={fulldata.kpi[6].value[1].labels}
                                                        hideToolTips
                                                    />
                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <PieChart
                                                        data={fulldata.kpi[6].value[2].data}
                                                        labels={fulldata.kpi[6].value[2].labels}
                                                        hideToolTips
                                                    />
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <PieChart
                                                        data={fulldata.kpi[6].value[3].data}
                                                        labels={fulldata.kpi[6].value[3].labels}
                                                        hideToolTips
                                                    />
                                                </Panel>
                                            )}
                                        />



                                        <CompareSection
                                            last
                                            name={fulldata.kpi[7].name}
                                            height={235}
                                            child1={(
                                                <Panel flexible paddingY>
                                                    <BarChart
                                                        labels={fulldata.kpi[7].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[7].value[0].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />

                                                </Panel>
                                            )}
                                            child2={(
                                                <Panel flexible paddingY>
                                                    {console.log('currentData', fulldata.kpi[7].value[1].data)}
                                                    <BarChart
                                                        labels={fulldata.kpi[7].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[7].value[1].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />


                                                </Panel>
                                            )}
                                            child3={(
                                                <Panel flexible paddingY>
                                                    <BarChart
                                                        labels={fulldata.kpi[7].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[7].value[2].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />
                                                </Panel>
                                            )}
                                            child4={(
                                                <Panel flexible paddingY>
                                                    <BarChart
                                                        labels={fulldata.kpi[7].value[0].labels
                                                        }
                                                        highlightMax
                                                        data={[
                                                            {
                                                                data: fulldata.kpi[7].value[3].data
                                                            }
                                                        ]}
                                                        scaleX={{
                                                            min: 0,
                                                            max: 50
                                                        }}


                                                    />
                                                </Panel>
                                            )}
                                        />
                                        <ZsButton type="primary" style={{ marginTop: "20px" }}>Resolve Warnings</ZsButton>
                                    </Col>


                                </Row>

                            </div>
                            {createAudienceFlag &&
                                <div style={{ padding: "5px", width: { width } }}>

                                    <Row gutter={[25, 25]} id="page-container">

                                        <Col span={24}>
                                            <Affix offsetTop={0} target={() => document.getElementById("page-container")}>
                                                <Row style={{ background: "rgba(255,255,255,0.03)", borderBottom: "3px solid #111419" }}>
                                                    <Col span={4} style={{ paddingLeft: 10, borderRight: "3px solid #111419" }}>
                                                        <Panel height="60px" center paddingX>
                                                            <ZsSpace>

                                                                <h2
                                                                    style={{
                                                                        margin: 0,
                                                                        marginBottom: 0,
                                                                        fontSize: "16px",
                                                                        fontWeight: "normal",
                                                                        lineHeight: "100%",

                                                                    }}
                                                                >

                                                                    {"Parameters"}
                                                                </h2>


                                                            </ZsSpace>
                                                        </Panel>
                                                    </Col>
                                                    <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                        <Panel height="60px" center paddingX>
                                                            <ZsSpace>
                                                                <Popover content={fulldata.infoicon[0]} >
                                                                    <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                                </Popover>
                                                                <h2
                                                                    style={{
                                                                        margin: 0,
                                                                        marginBottom: 0,
                                                                        fontSize: "16px",
                                                                        fontWeight: "normal",
                                                                        lineHeight: "100%",

                                                                    }}
                                                                >

                                                                    {fulldata.name[4]}
                                                                </h2>


                                                            </ZsSpace>                                        </Panel>
                                                    </Col>
                                                    <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                        <Panel height="60px" center paddingX>
                                                            <ZsSpace>
                                                                <Popover content={fulldata.infoicon[1]} >                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                                </Popover>
                                                                <h2
                                                                    style={{
                                                                        margin: 0,
                                                                        marginBottom: 0,
                                                                        fontSize: "16px",
                                                                        fontWeight: "normal",
                                                                        lineHeight: "100%",

                                                                    }}
                                                                >

                                                                    {fulldata.name[5]}
                                                                </h2>


                                                            </ZsSpace>                                        </Panel>
                                                    </Col>
                                                    <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                        <Panel height="60px" center paddingX>
                                                            <ZsSpace>
                                                                <Popover content={fulldata.infoicon[0]} >
                                                                    <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                                </Popover>
                                                                <h2
                                                                    style={{
                                                                        margin: 0,
                                                                        marginBottom: 0,
                                                                        fontSize: "16px",
                                                                        fontWeight: "normal",
                                                                        lineHeight: "100%",

                                                                    }}
                                                                >

                                                                    {fulldata.name[6]}
                                                                </h2>


                                                            </ZsSpace>                                        </Panel>
                                                    </Col>
                                                    <Col span={5} style={{ paddingLeft: 20, borderRight: "3px solid #111419" }}>
                                                        <Panel height="60px" center paddingX>
                                                            <ZsSpace>
                                                                <Popover content={fulldata.infoicon[1]} >                                                                <InformationCircle style={{ width: "23px" }}></InformationCircle>


                                                                </Popover>
                                                                <h2
                                                                    style={{
                                                                        margin: 0,
                                                                        marginBottom: 0,
                                                                        fontSize: "16px",
                                                                        fontWeight: "normal",
                                                                        lineHeight: "100%",

                                                                    }}
                                                                >

                                                                    {fulldata.name[7]}
                                                                </h2>


                                                            </ZsSpace>                                        </Panel>
                                                    </Col>
                                                </Row>
                                            </Affix>

                                            <CompareSection
                                                name={fulldata.kpi[0].name}
                                                height={50}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[0].value[4].value} format={"#0a"} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[0].value[4].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[0].value[5].value} format={"#0a"} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[0].value[5].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[0].value[6].value} format={"#0a"} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[0].value[6].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[0].value[7].value} format={"#0a"} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[0].value[7].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}

                                            />


                                            <CompareSection
                                                name={fulldata.kpi[1].name}
                                                height={50}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[1].value[4].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[0].warning} warningtext={fulldata.kpi[1].value[0].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[1].value[5].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[1].value[1].warning} warningtext={fulldata.kpi[1].value[1].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[1].value[6].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[1].value[6].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[1].value[7].value} format={fulldata.kpi[1].value[0].format ? fulldata.kpi[1].value[0].format : "$0.0a"} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[1].value[7].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                            />
                                            <CompareSection
                                                name={fulldata.kpi[2].name}
                                                height={50}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[2].value[4].value} format={""} isTest={fulldata.kpi[2].value[0].warning} warningtext={fulldata.kpi[2].value[0].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[2].value[5].value} format={""} isTest={fulldata.kpi[2].value[1].warning} warningtext={fulldata.kpi[2].value[1].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[2].value[6].value} format={""} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[2].value[6].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[2].value[7].value} format={""} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[2].value[7].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                            />
                                            <CompareSection
                                                name={fulldata.kpi[3].name}
                                                height={50}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[3].value[4].value} format={""} isTest={fulldata.kpi[3].value[0].warning} warningtext={fulldata.kpi[3].value[0].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[3].value[5].value} format={""} isTest={fulldata.kpi[3].value[1].warning} warningtext={fulldata.kpi[3].value[1].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[3].value[6].value} format={""} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[3].value[6].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[3].value[7].value} format={""} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[3].value[7].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                            />
                                            <CompareSection
                                                name={fulldata.kpi[4].name}
                                                height={50}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[4].value[4].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[0].warning} warningtext={fulldata.kpi[4].value[0].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[4].value[5].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[4].value[1].warning} warningtext={fulldata.kpi[4].value[1].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[4].value[6].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[0].value[0].warning} warningtext={fulldata.kpi[4].value[6].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <TableCol value={fulldata.kpi[4].value[7].value} format={fulldata.kpi[4].value[0].format ? fulldata.kpi[4].value[0].format : ""} isTest={fulldata.kpi[0].value[1].warning} warningtext={fulldata.kpi[4].value[7].warningtext} number={2} createAudienceFlag={createAudienceFlag}></TableCol>
                                                    </Panel>
                                                )}
                                            />


                                            <CompareSection
                                                name={fulldata.kpi[5].name}
                                                height={235}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[5].value[4].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[5].value[4].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}

                                                        />

                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        {console.log('currentData', fulldata.kpi[5].value[5].data)}
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[5].value[5].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[5].value[5].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}

                                                        />


                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[5].value[6].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[5].value[6].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}

                                                        />

                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        {console.log('currentData', fulldata.kpi[5].value[7].data)}
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[5].value[7].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[5].value[5].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}

                                                        />


                                                    </Panel>
                                                )}
                                            />
                                            <CompareSection
                                                name={fulldata.kpi[6].name}
                                                height={235}

                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <PieChart
                                                            data={fulldata.kpi[6].value[4].data}
                                                            labels={fulldata.kpi[6].value[4].labels}
                                                            hideToolTips
                                                        />
                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        <PieChart
                                                            data={fulldata.kpi[6].value[5].data}
                                                            labels={fulldata.kpi[6].value[5].labels}
                                                            hideToolTips
                                                        />
                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <PieChart
                                                            data={fulldata.kpi[6].value[6].data}
                                                            labels={fulldata.kpi[6].value[6].labels}
                                                            hideToolTips
                                                        />
                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <PieChart
                                                            data={fulldata.kpi[6].value[6].data}
                                                            labels={fulldata.kpi[6].value[6].labels}
                                                            hideToolTips
                                                        />
                                                    </Panel>
                                                )}
                                            />



                                            <CompareSection
                                                last
                                                name={fulldata.kpi[7].name}
                                                height={235}
                                                child1={(
                                                    <Panel flexible paddingY>
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[7].value[4].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[7].value[4].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}


                                                        />

                                                    </Panel>
                                                )}
                                                child2={(
                                                    <Panel flexible paddingY>
                                                        {console.log('currentData', fulldata.kpi[7].value[1].data)}
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[7].value[5].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[7].value[5].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}


                                                        />


                                                    </Panel>
                                                )}
                                                child3={(
                                                    <Panel flexible paddingY>
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[7].value[6].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[7].value[6].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}


                                                        />

                                                    </Panel>
                                                )}
                                                child4={(
                                                    <Panel flexible paddingY>
                                                        <BarChart
                                                            style={{ width: "25vw", marginLeft: "auto" }}
                                                            labels={fulldata.kpi[7].value[7].labels
                                                            }
                                                            highlightMax
                                                            data={[
                                                                {
                                                                    data: fulldata.kpi[7].value[7].data
                                                                }
                                                            ]}
                                                            scaleX={{
                                                                min: 0,
                                                                max: 50
                                                            }}


                                                        />


                                                    </Panel>
                                                )}
                                            />
                                            <ZsButton type="primary" style={{ marginTop: "20px" }}>Resolve Warnings</ZsButton>
                                        </Col>


                                    </Row>

                                </div>
                            }

                        </Carousel>
                    </div>
                )}
            </ChartWrapper>




        </> : <WaveLoading color="#15afd0" />
    );
}

export default ABTestAnalyse