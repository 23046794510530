import React, { useCallback } from "react";
import { Modal } from "@pai-ui/core/components/modal";
import { Button } from "@pai-ui/core/components/button";
import { Result } from "antd";

const GenericModalWithResultComponent = ({
    isRequestModalOpen,
    setIsRequestModalOpen,
    // title = "Created For Illustration Only With Mock Data",
    // subTitle = "For more information, please contact Admin",
    title = "Coming Soon",
    subTitle = "",
    resultStatus = "info"
}) => {

    const close = useCallback(() => {
        setIsRequestModalOpen(false);
    }, []);

    return (
        <>
            <Modal
                open={isRequestModalOpen}
                withCloseButton={false}
                withoutFooterButtons={true}
                onOk={close}
                onCancel={close}
                bodyStyle={{
                    background: "#181b20",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                width={"65vw"}
            >
                <Result
                    status={resultStatus}
                    title={title}
                    subTitle={subTitle}
                    extra={
                        <Button type="ghost" key="close" onClick={close}>
                            Close to continue
                        </Button>
                    }
                />
            </Modal>
        </>
    );
};

export default GenericModalWithResultComponent;