const messages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email address!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const validateMessages = () => {
  return messages;
};

export default validateMessages;
