import React, { useState } from "react";
import moment from "moment";
// import Form from 'antd/lib/form';
// import Popconfirm from "antd/lib/popconfirm";
import { Radio } from "antd";
import Typography from "antd/lib/typography";
// import { Form } from "@pai-ui/core/components/form";
// import { Input } from "@pai-ui/core/components/input";
// import { TextArea } from "@pai-ui/core/components/text-area";
import { Space } from "@pai-ui/core/components/space";
import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
// import { Typography } from "@pai-ui/core/components/typography";
// import { InputNumber } from "@pai-ui/core/components/input-number";
// import { Radio } from "@pai-ui/core/components/radio";
// import { RadioGroup } from "@pai-ui/core/components/radio-group";
import { TextArea } from "@pai-ui/core/components/text-area";
import { Tag } from "@pai-ui/core/components/tag";
import { ThumbLike } from "@styled-icons/fluentui-system-filled/ThumbLike";
import { Like } from "@styled-icons/boxicons-regular/Like";
import { Dislike as DislikeFill } from "@styled-icons/boxicons-solid/Dislike";
import { Dislike } from "@styled-icons/boxicons-regular/Dislike";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Delete as DeleteFill } from "@styled-icons/fluentui-system-filled/Delete";
// import { Undo } from "@styled-icons/boxicons-regular/Undo";

import { Edit as EditIcon } from "@styled-icons/fluentui-system-filled/Edit";
import { Save as SaveIcon } from "@styled-icons/boxicons-solid/Save";
import { CancelCircle as CancelIcon } from "@styled-icons/icomoon/CancelCircle";

const EditIcon_ = (
  <EditIcon
    width={20}
    height={20}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);

const SaveIcon_ = (
  <SaveIcon
    width={20}
    height={20}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);

const CancelIcon_ = (
  <CancelIcon
    width={20}
    height={20}
    style={{ cursor: "pointer" }}
    color="#15afd0"
  />
);
/**
 *
 */
const subjectLineConfig = ({
  ExperimentTagGroups,
  getTitleTagColumnSearchProps,
}) => [
  {
    title: "Subject Line",
    dataKey: "subjectLine",
    // width: "20vw",
    editable: true,
    render: (_, record) => {
      return (
        <div>
          <span>{_}</span>
          <div>
            {record?.variantTitleTags?.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  color={
                    ExperimentTagGroups?.find(
                      (tagObj) =>
                        tagObj?.tag_group?.toLowerCase() ===
                        tag?.tag_group?.toLowerCase()
                    )?.tag_color /* tag_group tag?.color */
                  }
                  size="small"
                >
                  {tag?.name}
                </Tag>
              );
            })}
          </div>
        </div>
      );
    },
    ...getTitleTagColumnSearchProps("subjectLine"),
  },
];

const bodyCopyConfig = ({
  ExperimentTagGroups,
  getTitleTagColumnSearchProps,
}) => [
  {
    title: "Body Copy",
    dataKey: "bodyCopy",
    // width: "27vw",
    editable: true,
    render: (_, record) => {
      return (
        <div>
          <span>{_}</span>
          <div>
            {record?.variantBodyContentTags?.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  color={
                    ExperimentTagGroups?.find(
                      (tagObj) =>
                        tagObj?.tag_group?.toLowerCase() ===
                        tag?.tag_group?.toLowerCase()
                    )?.tag_color /* tag_group tag?.color */
                  }
                  size="small"
                >
                  {tag?.name}
                </Tag>
              );
            })}
          </div>
        </div>
      );
    },
    ...getTitleTagColumnSearchProps("bodyCopy"),
  },
];

const taglineConfig = ({
  ExperimentTagGroups,
  getTitleTagColumnSearchProps,
}) => [
  {
    title: "Tagline",
    dataKey: "subjectLine",
    // width: "20vw",
    editable: true,
    render: (_, record) => {
      return (
        <div>
          <span>{_}</span>
          {/* <div>{record?.bodyCopy}</div> */}
          <div>
            {record?.variantTitleTags?.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  color={
                    ExperimentTagGroups?.find(
                      (tagObj) =>
                        tagObj?.tag_group?.toLowerCase() ===
                        tag?.tag_group?.toLowerCase()
                    )?.tag_color /* tag_group tag?.color */
                  }
                  size="small"
                >
                  {tag?.name}
                </Tag>
              );
            })}
          </div>
        </div>
      );
    },
    ...getTitleTagColumnSearchProps("subjectLine"),
  },
];

const imageOnlyConfig = () => [
  {
    title: "Image",
    dataKey: "imageUrl",
    width: "15vw",
    editable: false,
    render: (_) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <span>{_}</span> */}
          <img src={_} height={"150px"} />
        </div>
      );
    },
  },
];

const Like_ = ({ record, handleAction, selectedTab }) => (
  <Like
    title={
      selectedTab === "draftVariants"
        ? "Like"
        : selectedTab === "approvedVariants"
        ? "Approve"
        : "Like"
    }
    width={20}
    height={20}
    style={{
      cursor:
        // ["", null, undefined].indexOf(record?.approvedDate) === -1
        //   ? ""
        //   :
        "pointer",
    }}
    color={
      // ["", null, undefined].indexOf(record?.approvedDate) === -1
      //   ? "#1890ff"
      //   :
      "#15afd0"
    }
    onClick={(e) => {
      e.preventDefault();
      let statusChange =
        selectedTab === "draftVariants"
          ? "liked"
          : selectedTab === "approvedVariants"
          ? "approved"
          : "liked";
      handleAction(record, statusChange);
    }}
  />
);

const ThumbLike_ = ({ /* record, */ selectedTab }) => (
  <ThumbLike
    title={
      selectedTab === "draftVariants"
        ? "Liked"
        : selectedTab === "approvedVariants"
        ? "Approved"
        : "Liked"
    }
    width={20}
    height={20}
    style={{
      cursor:
        // ["", null, undefined].indexOf(record?.approvedDate) === -1
        //   ? ""
        //   :
        "pointer",
    }}
    color={
      // ["", null, undefined].indexOf(record?.approvedDate) === -1
      //   ? "#1890ff"
      //   :
      "#15afd0"
    }
  />
);

const Dislike_ = ({ record, handleAction, experimentTextVariantsType }) => {
  const [value, setValue] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(null);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <PopConfirm
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            Please add comments and confirm reason for disliking this variant
          </div>
          <Space size={"middle"} direction="vertical">
            <Radio.Group onChange={(e) => onChange(e)} value={value}>
              <Space direction="vertical">
                {experimentTextVariantsType?.toLowerCase() === "imageonly" ? (
                  <>
                    <Radio
                      value={
                        "The image is irrelevant to the campaign / project"
                      }
                    >
                      The image is irrelevant to the campaign / project
                    </Radio>
                    <Radio
                      value={"The image is inconsistent with brand guidelines"}
                    >
                      The image is inconsistent with brand guidelines
                    </Radio>
                    <Radio value={"The quality of the image is poor"}>
                      The quality of the image is poor
                    </Radio>
                  </>
                ) : (
                  <>
                    <Radio
                      value={
                        "The message includes inaccurate or unsupported claim"
                      }
                    >
                      The message includes inaccurate or unsupported claim
                    </Radio>
                    <Radio
                      value={
                        "The message is inconsistent with brand guidelines"
                      }
                    >
                      The message is inconsistent with brand guidelines
                    </Radio>
                    <Radio value={"The quality of the message is poor"}>
                      The quality of the message is poor
                    </Radio>
                  </>
                )}
                <Radio value={"Other"}>Other</Radio>
              </Space>
            </Radio.Group>
            <TextArea
              onChange={(e) => {
                setTextAreaValue(e.target.value);
              }}
              rows={3}
              disabled={value !== "Other"}
            />
          </Space>
        </div>
      }
      // title="Please add comments and confirm reason for disliking this variant"
      onConfirm={(e) => {
        e.preventDefault();
        console.log(
          "on cofirm - radio",
          value,
          textAreaValue,
          record,
          value !== "Other" ? value : textAreaValue
        );
        handleAction(
          record,
          "refused",
          ` Refuse : ${value !== "Other" ? value : textAreaValue} \n\n`
        );
      }}
      placement="topRight"
      overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
    >
      <Dislike
        title="Dislike"
        width={20}
        height={20}
        style={{
          cursor:
            // ["", null, undefined].indexOf(record?.approvedDate) === -1
            //   ? "#1890ff"
            //   :
            "pointer",
        }}
        color={
          // ["", null, undefined].indexOf(record?.approvedDate) === -1
          //   ? "#1890ff"
          //   :
          "#15afd0"
        }
        // onClick={(e) => {
        //   e.preventDefault();
        //   handleAction(record, "refused");
        // }}
      />
    </PopConfirm>
  );
};

// // Same as Dislike_
// const Undo_ = ({ record, handleAction }) => (
//   <Undo
//     title="Revert / Dislike"
//     width={25}
//     height={25}
//     style={{
//       cursor:
//         // ["", null, undefined].indexOf(record?.approvedDate) === -1
//         //   ? "#1890ff"
//         //   :
//         "pointer",
//     }}
//     color={
//       // ["", null, undefined].indexOf(record?.approvedDate) === -1
//       //   ? "#1890ff"
//       //   :
//       "#15afd0"
//     }
//     onClick={(e) => {
//       e.preventDefault();
//       handleAction(record, "refused");
//     }}
//   />
// );

const DislikeFill_ = (/* { record } */) => (
  <DislikeFill
    title="Disliked"
    width={20}
    height={20}
    style={{
      cursor:
        // ["", null, undefined].indexOf(record?.approvedDate) === -1
        //   ? ""
        //   :
        "pointer",
    }}
    color={
      // ["", null, undefined].indexOf(record?.approvedDate) === -1
      //   ? "#1890ff"
      //   :
      "#15afd0"
    }
  />
);

const Delete_ = ({ record, handleAction, experimentTextVariantsType }) => {
  const [value, setValue] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(null);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <PopConfirm
      // title="Sure to delete?"
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            Please add comments and confirm reason for deleting this variant
          </div>
          <Space size={"middle"} direction="vertical">
            <Radio.Group onChange={(e) => onChange(e)} value={value}>
              <Space direction="vertical">
                {experimentTextVariantsType?.toLowerCase() === "imageonly" ? (
                  <>
                    <Radio
                      value={
                        "The image is irrelevant to the campaign / project"
                      }
                    >
                      The image is irrelevant to the campaign / project
                    </Radio>
                    <Radio
                      value={"The image is inconsistent with brand guidelines"}
                    >
                      The image is inconsistent with brand guidelines
                    </Radio>
                    <Radio value={"The quality of the image is poor"}>
                      The quality of the image is poor
                    </Radio>
                  </>
                ) : (
                  <>
                    <Radio
                      value={
                        "The message includes inaccurate or unsupported claim"
                      }
                    >
                      The message includes inaccurate or unsupported claim
                    </Radio>
                    <Radio
                      value={
                        "The message is inconsistent with brand guidelines"
                      }
                    >
                      The message is inconsistent with brand guidelines
                    </Radio>
                    <Radio value={"The quality of the message is poor"}>
                      The quality of the message is poor
                    </Radio>
                  </>
                )}
                <Radio value={"Other"}>Other</Radio>
              </Space>
            </Radio.Group>
            <TextArea
              onChange={(e) => {
                setTextAreaValue(e.target.value);
              }}
              rows={3}
              disabled={value !== "Other"}
            />
          </Space>
        </div>
      }
      // disabled={["", null, undefined].indexOf(record?.approvedDate) === -1}
      onConfirm={(e) => {
        e.preventDefault();
        handleAction(
          record,
          "cancelled",
          ` Delete : ${value !== "Other" ? value : textAreaValue} \n\n`
        );
      }}
      placement="topRight"
      overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
    >
      <Delete
        title="Delete"
        width={25}
        height={25}
        style={{
          cursor:
            // ["", null, undefined].indexOf(record?.approvedDate) === -1
            //   ? ""
            //   :
            "pointer",
        }}
        color={
          // ["", null, undefined].indexOf(record?.approvedDate) === -1
          //   ? "#1890ff"
          //   :
          "#15afd0"
        }
        // onClick={(e) => {
        //   e.preventDefault();
        //   handleAction(record, "cancelled");
        // }}
      />
    </PopConfirm>
  );
};
const DeleteFill_ = (/* { record } */) => (
  <DeleteFill
    title="Deleted"
    width={25}
    height={25}
    style={{
      cursor:
        // ["", null, undefined].indexOf(record?.approvedDate) === -1
        //   ? ""
        //   :
        "pointer",
    }}
    color={
      // ["", null, undefined].indexOf(record?.approvedDate) === -1
      //   ? "#1890ff"
      //   :
      "#15afd0"
    }
  />
);

const actionStatusConfig = ({
  selectedTab,
  isEditing,
  editingKey,
  edit,
  cancel,
  save,
  handleAction,
  experimentTextVariantsType,
}) => [
  {
    title: "Actions",
    dataKey: "action_status",
    render: (_a, record) => {
      const editable = isEditing(record);
      const _ = typeof _a === "string" ? _a : "";
      return (
        <Space size={"small"} style={{ justifyContent: "space-around" }}>
          <>
            {/* Action Icons based on status */}
            {_?.toLowerCase() === "liked" ? (
              <>
                {selectedTab === "draftVariants" && (
                  <>
                    {ThumbLike_({ record, selectedTab })}
                    <Dislike_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                    <Delete_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                  </>
                )}
                {selectedTab === "approvedVariants" && (
                  <>
                    {/* 
                    {Undo_({ record, handleAction })}
                    {Delete_({ record, handleAction, experimentTextVariantsType })}
                    */}
                    {Like_({ record, handleAction, selectedTab })}
                    <Dislike_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                    <Delete_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                  </>
                )}
              </>
            ) : _?.toLowerCase() === "approved" ? (
              <>
                {/* {selectedTab === "draftVariants" && (
                  <>
                    {ThumbLike_({ record, selectedTab })}
                    {Dislike_({ record, handleAction, experimentTextVariantsType })}
                    {Delete_({ record, handleAction, experimentTextVariantsType })}
                  </>
                )} */}
                {selectedTab === "approvedVariants" && (
                  <>
                    {/* 
                    {Undo_({ record, handleAction })}
                    {Delete_({ record, handleAction, experimentTextVariantsType })}
                    */}
                    {ThumbLike_({ record, selectedTab })}
                    <Dislike_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                    <Delete_
                      record={record}
                      handleAction={handleAction}
                      experimentTextVariantsType={experimentTextVariantsType}
                    />
                  </>
                )}
              </>
            ) : _?.toLowerCase() === "refused" ? (
              <>
                {Like_({ record, handleAction, selectedTab })}
                {DislikeFill_({ record })}
                <Delete_
                  record={record}
                  handleAction={handleAction}
                  experimentTextVariantsType={experimentTextVariantsType}
                />
              </>
            ) : _?.toLowerCase() === "cancelled" ? (
              <>
                {/* {selectedTab === "draftVariants" && (
                  <>
                    {Like_({ record, handleAction })}
                    {Dislike_({ record, handleAction, experimentTextVariantsType })}
                    {DeleteFill_({ record })}
                  </>
                )}
                {selectedTab === "approvedVariants" && (
                  <>
                    {Undo_({ record, handleAction })}
                    {DeleteFill_({ record })}
                  </>
                )} */}
                {Like_({ record, handleAction, selectedTab })}

                <Dislike_
                  record={record}
                  handleAction={handleAction}
                  experimentTextVariantsType={experimentTextVariantsType}
                />
                {DeleteFill_({ record })}
              </>
            ) : (
              <>
                {Like_({ record, handleAction, selectedTab })}
                <Dislike_
                  record={record}
                  handleAction={handleAction}
                  experimentTextVariantsType={experimentTextVariantsType}
                />
                <Delete_
                  record={record}
                  handleAction={handleAction}
                  experimentTextVariantsType={experimentTextVariantsType}
                />
              </>
            )}
          </>
          <>
            {/* Edit , Save , Cancel icons for row editing */}
            {editable ? (
              <>
                <Typography.Link onClick={() => save(record.variantbankId)}>
                  {SaveIcon_}
                </Typography.Link>
                <PopConfirm
                  title="Sure to cancel?"
                  onConfirm={cancel}
                  placement="topRight"
                  overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
                >
                  {CancelIcon_}
                </PopConfirm>
              </>
            ) : (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                {EditIcon_}
              </Typography.Link>
            )}
          </>
        </Space>
      );
    },
    // Sort feature not required.
    // // sorter: (a, b) => a?.action_status?.localeCompare(b?.action_status),
    // Ref : https://stackoverflow.com/a/65388386/4672396
    // sorter: (a, b) => {
    //   if (
    //     a &&
    //     a.action_status &&
    //     a.action_status.length &&
    //     b &&
    //     b.action_status &&
    //     b.action_status.length
    //   ) {
    //     return a.action_status.length - b.action_status.length;
    //   } else if (a && a.action_status && a.action_status.length) {
    //     // That means be has null action_status, so a will come first.
    //     return 1;
    //   } else if (b && b.action_status && b.action_status.length) {
    //     // That means a has null action_status so b will come first.
    //     return -1;
    //   }

    //   // Both action_status has null value so there will be no order change.
    //   return 0;
    // },
    // // defaultSortOrder: 'ascend',
    align: "center",
    width: "12vw",
  },
];

const commentsColumnConfig = [
  {
    title: "Comments",
    dataKey: "comments",
    editable: true,
    width: "20vw",
  },
];

const approvedDateConfig = [
  {
    title: "Approved Date",
    dataKey: "approvedDate",
    width: "9vw",
    align: "center",
    render: (_) => {
      return [null, undefined, ""].indexOf(_) > -1
        ? ""
        : moment(_).format("Do MMMM YYYY");
      // return moment(_).format("Do MMMM YYYY");
    },
  },
];

const approvedUserNameConfig = [
  {
    title: "Approved By",
    dataKey: "experiment_variant_bank_user_logs",
    width: "9vw",
    align: "center",
    render: (_, record) => {
      return (
        <>
          {
            // #TODO :
            /**
             *  ?.toString() added as a temporary fix to handle P.AI UI library Table change, (empty string endered as 0)
             * once library provides a fix, we can remove this temporary fix.
             */
            record?.action_status?.toString()?.toLowerCase() === "approved"
              ? _?.sort(
                  (a, b) => Date.parse(b.time) - Date.parse(a.time)
                )?.find(
                  (statusObj) => statusObj?.action?.toLowerCase() === "approved"
                )?.userName || ""
              : ""
          }
        </>
      );
    },
  },
];

const deploymentDateConfig = [
  {
    title: "Deployment Date",
    dataKey: "deploymentDate",
    width: "9vw",
    align: "center",
    render: (_) => {
      return [null, undefined, ""].indexOf(_) > -1
        ? ""
        : moment(_).format("Do MMMM YYYY");
    },
  },
];

const deactivatedDateConfig = [
  {
    title: "Deactivated Date",
    dataKey: "deactivatedDate",
    width: "9vw",
    align: "center",
    render: (_) => {
      return [null, undefined, ""].indexOf(_) > -1
        ? ""
        : moment(_).format("Do MMMM YYYY");
    },
  },
];

const commonTableConfig = ({
  ExperimentTagGroups,
  experimentTextVariantsType,
  getTitleTagColumnSearchProps,
}) => {
  let finalColumns = [];
  if (experimentTextVariantsType?.toLowerCase() === "subjectlineonly") {
    finalColumns = [
      ...subjectLineConfig({
        ExperimentTagGroups,
        getTitleTagColumnSearchProps,
      }),
    ];
  } else if (experimentTextVariantsType?.toLowerCase() === "tagline") {
    finalColumns = [
      ...taglineConfig({
        ExperimentTagGroups,
        getTitleTagColumnSearchProps,
      }),
    ];
  } else if (experimentTextVariantsType?.toLowerCase() === "imageonly") {
    finalColumns = [
      ...imageOnlyConfig({
        ExperimentTagGroups,
        getTitleTagColumnSearchProps,
      }),
    ];
  } else if (experimentTextVariantsType?.toLowerCase() === "bodycopyonly") {
    finalColumns = [
      ...bodyCopyConfig({ ExperimentTagGroups, getTitleTagColumnSearchProps }),
    ];
  } else if (
    experimentTextVariantsType?.toLowerCase() === "subjectlineandbodycopy"
  ) {
    finalColumns = [
      ...subjectLineConfig({
        ExperimentTagGroups,
        getTitleTagColumnSearchProps,
      }),
      ...bodyCopyConfig({ ExperimentTagGroups, getTitleTagColumnSearchProps }),
    ];
  }
  return finalColumns;
};

/** */
export const getColumnConfig = ({
  ExperimentTagGroups,
  experimentTextVariantsType,
  getTitleTagColumnSearchProps,
  selectedTab,
  isEditing,
  editingKey,
  edit,
  cancel,
  save,
  handleAction,
}) => {
  let columns = [
    ...commonTableConfig({
      ExperimentTagGroups,
      experimentTextVariantsType,
      getTitleTagColumnSearchProps,
    }),
  ];

  if (selectedTab === "draftVariants") {
    columns = columns.concat([
      ...commentsColumnConfig,
      ...actionStatusConfig({
        selectedTab,
        isEditing,
        editingKey,
        edit,
        cancel,
        save,
        handleAction,
        experimentTextVariantsType,
      }),
    ]);
  } else if (selectedTab === "approvedVariants") {
    columns = columns.concat([
      ...commentsColumnConfig,
      ...actionStatusConfig({
        selectedTab,
        isEditing,
        editingKey,
        edit,
        cancel,
        save,
        handleAction,
        experimentTextVariantsType,
      }),
      ...approvedDateConfig,
      ...approvedUserNameConfig,
    ]);
  } else if (selectedTab === "deployedVariants") {
    columns = columns.concat([...approvedDateConfig, ...deploymentDateConfig]);
  } else if (selectedTab === "deactivatedVariants") {
    columns = columns.concat([
      ...approvedDateConfig,
      ...deploymentDateConfig,
      ...deactivatedDateConfig,
    ]);
  }

  const mergedColumns = columns?.map((col) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataKey === "age"
            ? "number"
            : ["subjectLine", "bodyCopy", "comments"].indexOf(col.dataKey) > -1
            ? "textarea"
            : "text",
        dataKey: col.dataKey,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return mergedColumns;
};

/**
 * getTableDataTransformer
 */
// #region
export const getTableDataTransformer = (dataSet) => {
  let modifiedDataSet = dataSet?.map((dataItem) => {
    let { variant_details, ...remainingObj } = dataItem;
    // variant_details = JSON.parse(JSON.stringify(variant_details));
    variant_details =
      typeof variant_details === "object"
        ? JSON.parse(JSON.stringify(variant_details))
        : JSON.parse(JSON.stringify(JSON.parse(variant_details)));

    return {
      ...remainingObj,
      subjectLine: variant_details?.variant_title,
      bodyCopy: variant_details?.variant_body_content,
      variantTitleTags: variant_details?.variant_title_tags,
      variantBodyContentTags: variant_details?.variant_body_content_tags,
      imageUrl: variant_details?.variant_image,
    };
  });

  return modifiedDataSet;
};
// #endregion

/**
 * postTableDataTransformer
 */
// #region
export const postTableDataTransformer = (modifiedDataSet) => {
  let finalDataSet = modifiedDataSet?.map((dataItem) => {
    const {
      experiment_id,
      variantbankId,
      subjectLine,
      bodyCopy,
      variantTitleTags,
      variantBodyContentTags,
      imageUrl,
      action_status,
      comments,
      approvedDate,
      deploymentDate,
      deactivatedDate,
      version,
      experiment_variant_bank_user_logs,
      // ...remainingObj
    } = dataItem;
    return {
      // ...remainingObj,
      id: variantbankId,
      experimentId: experiment_id,
      variantDetails: {
        variant_title: subjectLine,
        variant_body_content: bodyCopy,
        variant_title_tags: variantTitleTags || [],
        variant_body_content_tags: variantBodyContentTags || [],
        variant_image: imageUrl,
      },
      actionStatus: action_status || null,
      deploymentDate: deploymentDate || null,
      approvedDate: approvedDate || null,
      deactivatedDate: deactivatedDate || null,
      comments: comments || null,
      version: version || null,
      experiment_variant_bank_user_logs:
        experiment_variant_bank_user_logs || [],
    };
  });

  return finalDataSet;
};
// #endregion
