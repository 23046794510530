import * as DesignService from "@xai/services/experiment/Design-MaxAI";
// import axios from "axios";

class MaxAIServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getMaxAITagLines(data, generateOpenAIResponse) {
    const result = await DesignService.newGetMaxAITagLines(data, generateOpenAIResponse);
    if (!result) return null;
    return result;
  }

  async getDesignExperimentTagLines(data, generateOpenAIResponse) {
    const result = await DesignService.getDesignExperimentTagLines(data, generateOpenAIResponse);
    if (!result) return null;
    return result;
  }

  async getMaxAIApplicationsData(data) {
    const result = await DesignService.getMaxAIApplicationsData(data);
    if (!result) return null;
    return result;
  }

  async getMaxAIGetTemplateTaglineResponse(data) {
    const result = await DesignService.getMaxAIGetTemplateTaglineResponse(data);
    if (!result) return null;
    return result;
  }

  async getMaxAITemplateFormConfig(data) {
    const result = await DesignService.getMaxAITemplateFormConfig(data);
    if (!result) return null;
    return result;
  }

  async getMaxAITemplateDefaultFormData(data) {
    const result = await DesignService.getMaxAITemplateDefaultFormData(data);
    if (!result) return null;
    return result;
  }

  async getMaxAITaglineConfig(data) {
    const result = await DesignService.getMaxAITaglineConfig(data);
    if (!result) return null;
    return result;
  }
}

export default MaxAIServiceHelper;
