import axios from "axios";
import { dotNetApi } from "@xai/library/clients";

let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");

const TransformTemplateListData = (experimentListData) => {
  experimentListData?.items?.map((data) => {
    data.template_form_config_table.template_form_config = JSON.parse(
      data?.template_form_config_table?.template_form_config
    );
    data.template_data_table.template_data = JSON.parse(
      data?.template_data_table?.template_data
    );
    data.template_dendogram_rules_table.template_dendogram_rules = JSON.parse(
      data?.template_dendogram_rules_table?.template_dendogram_rules
    );
    return data;
  });
};

// All the Mock JSON's are moved to CDN in pai-ui-assets-01 Bucket.
export const getAllDesignExperiments = (authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignExperimentCardLayout.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          const { data } = res;
          console.log("getAllDesignExperiments data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getAllDesignExperiments dataConfig", authToken);
      dotNetApi
        .post(
          `/TemplateMasters`,
          {
            query: `{
                            templateMaster(pageSize: 10000, page: 1, sort: { field: id, type: ASC }) {
                              items {
                                id
                                template_name: templateName
                                description: description
                                target_subject: targetSubject
                                template_identifier: templateIdentifier
                                template_form_config_table: templateFormConfig {
                                  template_form_config: templateFormConfig
                                }
                                template_data_table: templateData {
                                  template_data: templateData
                                }
                                template_dendogram_rules_table: templateDendogramRules {
                                  template_dendogram_rules: templateDendogramRules
                                }
                                icon:icon
                              }
                            }
                          }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { templateMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log("getAllDesignExperiments data", res, templateMaster);
          await TransformTemplateListData(templateMaster);
          resolve(templateMaster);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

// export const getDesignExperimentTagLines = (config, generateOpenAIResponse) =>
//   new Promise((resolve, reject) => {
//     if (!generateOpenAIResponse) {
//       axios
//         .get(
//           `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignExperimentTagLineExperiment.json`,
//           {
//             skipInterceptors: true,
//           }
//         )
//         .then((res) => {
//           console.log("getDesignExperimentTagLines data", res);
//           resolve(res.data.content);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     } else {
//       // axios.post(`${process.env.REACT_APP_DOTNET_MICROSERVICE_HOST_CONTENT_GENERATOR}/ContentGenerator`,
//       axios
//         .post(
//           `${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetOpenApiContent`,
//           // {
//           //     "taglines": [
//           //         "Book your flight today!",
//           //         "Don’t wait, book now!",
//           //         "Hurry before it’s too late, book your flight now!"
//           //     ],
//           //     "count": {
//           //         "minCount": 0,
//           //         "maxCount": 20,
//           //         "canContain": [
//           //             "flight"
//           //         ],
//           //         "cantContain": [
//           //             "hello"
//           //         ]
//           //     }
//           // }
//           config
//         )
//         .then((response) => {
//           if (response?.data?.error) {
//             reject({});
//           }
//           resolve(response.data.content);
//         })
//         .catch((err) => {
//           console.log(err);
//           reject({});
//         });
//     }
//   });

export const getAllDesignOptimizations = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignOptimizationCardLayout.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("getAllDesignExperiments data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDesignAISequencingData = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignAISequencingListing.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("getAllDesignExperiments data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDesignAISequencingFormElements = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignAISequencingFormElements.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetDesignAISequencingFormElements data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// export const getSpecificSubjectLineConfig = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios
//       .get(
//         `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetSpecificSubjectLineConfig.json`,
//         {
//           skipInterceptors: true,
//         }
//       )
//       // axios({
//       //     method: "GET",
//       //     url: "./TempData/GetSpecificSubjectLineConfig.json"
//       // })
//       .then((res) => {
//         const { data } = res;
//         console.log("getSpecificSubjectLineConfig data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getMaxAIApplicationsData = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios
//       .get(
//         `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignMaxAIApplication.json`,
//         {
//           skipInterceptors: true,
//         }
//       )
//       .then((res) => {
//         const { data } = res;
//         console.log("GetDesignAISequencingFormElements data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

export const getMaxAIGetTemplateTaglineResponse = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetTemplateTaglineResponse.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetTemplateTaglineResponse data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// export const getMaxAITemplateFormConfig = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios
//       .get(
//         `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITemplateFormConfig.json`,
//         {
//           skipInterceptors: true,
//         }
//       )
//       .then((res) => {
//         const { data } = res;
//         console.log("GetTemplateTaglineResponse data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getMaxAITemplateDefaultFormData = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios
//       .get(
//         `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITemplateDefaultFormData.json`,
//         {
//           skipInterceptors: true,
//         }
//       )
//       .then((res) => {
//         const { data } = res;
//         console.log("GetTemplateTaglineResponse data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getMaxAITaglineConfig = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios
//       .get(
//         `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITaglineConfig.json`,
//         {
//           skipInterceptors: true,
//         }
//       )
//       .then((res) => {
//         const { data } = res;
//         console.log("GetTemplateTaglineResponse data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

export const getGenAIDynamicImages = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetGenAIPreFilters.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetGenAIPreFilters data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * getExperimentVarianBankList
 */

export const getExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    const { experiment_id } = dataconfig;
    if (mock) {
      // mock response for this api call
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentVarianBankList.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          const { data } = res;
          console.log("getExperimentVarianBankList data", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExperimentVarianBankList dataConfig");
      dotNetApi
        .post(
          `/ExperimentVariantBanks`,
          {
            query: `{
                        experimentVariantBank(
                            page: 1
                            pageSize: 1000
                            experimentId: { value: "${experiment_id}", type: eq }
                        ) {
                            items {
                            variantbankId: id
                            experiment_id: experimentId
                            variant_details: variantDetails
                            action_status: actionStatus
                            comments
                            approvedDate
                            deploymentDate
                            deactivatedDate
                            version
                            experiment_variant_bank_user_logs: experimentVariantBankUserLogs {
                                id
                                userName
                                email
                                step
                                action
                                time
                              }
                            }
                        }
                    }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentVariantBank } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getExperimentVarianBankList data",
            res,
            experimentVariantBank
          );
          // await TransformTemplateListData(templateMaster);
          resolve(experimentVariantBank);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const bulkUpdateExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    // const { experiment_id } = dataconfig;
    console.log("bulkUpdateExperimentVarianBankList - ", dataconfig);
    if (mock) {
      // mock response for this api call
      setTimeout(() => {
        resolve(dataconfig);
      }, 500);
    } else {
      dotNetApi
        .put(
          `/Experiments/ExperimentVariantBanks/BulkUpdate`,
          dataconfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const getDeployedAndDeactivatedVariants = (experiment_id) =>
  dotNetApi.post(`/ExperimentVariantBanks`, {
    query: `{
        experimentVariantBank(
          page: 1
          pageSize: 10000
          actionStatuss: ["deployed", "deactivated"]
          experimentId: { value: "${experiment_id}", type: eq }
        ) {
          items {
            actionStatus
            experimentId
            variantDetails
            deploymentDate
            deactivatedDate
          }
        }
      }
      `,
  });

export const getApprovedVariants = (experiment_id) =>
  dotNetApi.post(`/ExperimentVariantBanks`, {
    query: `{
        experimentVariantBank(
          page: 1
          pageSize: 10000
          actionStatuss: ["approved"]
          experimentId: { value: "${experiment_id}", type: eq }
        ) {
          items {
            actionStatus
            experimentId
            variantDetails
            deploymentDate
            deactivatedDate
            id
          }
        }
      }
      `,
  });

export const getExperimentNames = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("getExperimentNames data", dataconfig, authToken);
    if (mock) {
      resolve([]);
      // // mock response for this api call
      // axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/TestLibraryExperiment.json`,
      //     {
      //         skipInterceptors: true
      //     })
      //     .then((res) => {
      //         const { data } = res;
      //         console.log("getAllExperiments data", data, authToken);
      //         resolve(data);
      //     })
      //     .catch((error) => {
      //         reject(error);
      //     });
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `{
                experimentMaster(
                  page: 1
                  pageSize: 1000
                  experimentName: { value: "${dataconfig}", type: eq }
                ) {
                  items {
                    experimentName
                  }
                }
              }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getAllExperiments data",
            // res,
            experimentMaster
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMaster);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const addExperiment = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("addExperiment data", dataconfig, authToken);
    if (mock) {
      resolve([]);
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
                experimentMasterUpsert(
                  experimentMaster: {
                    experimentName: "${dataconfig?.Name}"
                    isActive: ${dataconfig?.isActive}
                    status: ${dataconfig?.status}
                    defaultVersionConfig: ${dataconfig?.defaultVersionConfig}
                    templateId: ${dataconfig?.templateId}
                    experimentData: ${dataconfig?.experimentData}
                  }
                ) {
                  id
                  experimentName
                  experiment_data: experimentData
                  status
                  templateId
                  isActive
                }
              }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMasterUpsert } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "addExperiment data",
            // res,
            experimentMasterUpsert
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMasterUpsert);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const bulkUpsertExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    // const { experiment_id } = dataconfig;
    console.log("bulkUpsertExperimentVarianBankList - ", dataconfig);
    if (mock) {
      // mock response for this api call
      setTimeout(() => {
        resolve(dataconfig);
      }, 500);
    } else {
      dotNetApi
        .put(
          `/Experiments/ExperimentVariantBanks/BulkUpsert`,
          dataconfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });


