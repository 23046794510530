import { Card, Checkbox, Input } from "antd";
import React from "react";

export const ContextComponentSummary = (props) => {
  return (
    <div style={{}}>
      {props.inputs.map((element, index) => (
        <div
          key={index}
          style={{
            padding: 10,
            paddingBottom: 20,
            paddingTop: 0,
            display: "flex",
            marginBottom: 10,
          }}
        >
          <div style={{ marginTop: 1, marginRight: 20 }}>
            <Checkbox
              disabled={element.required}
              checked={
                (props.data.selectedFields || []).indexOf(element.key) !== -1
              }
              onChange={() => {
                var currentFields = props.data.selectedFields || [];
                var index = currentFields.indexOf(element.key);
                if (index !== -1) {
                  currentFields.splice(index, 1);
                } else {
                  currentFields.push(element.key);
                }
                props.updateEntry("selectedFields", currentFields);
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ marginBottom: 0 }}>{element.label}</h3>
            <p style={{ marginBottom: 0, opacity: 0.75 }}>{element.help}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ContextComponent = (props) => {
  return (
    <>
      {props.inputs
        .filter((input) => {
          return (props.data.selectedFields || []).indexOf(input.key) !== -1;
        })
        .map((element, index) => (
          <div style={{ marginBottom: 25 }} key={index}>
            <Card className="">
              <div>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    margin: "0px",
                  }}
                >
                  {element.label}
                </p>
                <p
                  style={{
                    fontSize: "0.8rem",
                    margin: "0px",
                    opacity: 0.75,
                    fontWeight: "300",
                  }}
                >
                  {element.help}
                </p>
              </div>
              {/* <>
            {element.type === "tile_select" && (
              <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
                {element.tiles?.map((it) => (
                  <Col span={24 / element.tiles.length}>
                    <AspectRatio width={1} height={1}>
                      <Card
                        className="card-section"
                        style={{
                          background:
                            formData?.basicInfo?.[element.key] === it.value
                              ? `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))`
                              : "",
                          textAlign: "center",
                          cursor: "pointer",
                          border: "1px solid rgba(67, 67, 67)",
                          height: "100%",
                        }}
                        onClick={() => {
                          changeHandler(it.value, element.key);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!it.component && (
                            <img
                              src={`${process.env.REACT_APP_S3_BUCKET}/${subRoute}/viz_configs/img/integrations/${it?.img}`}

                              alt=""
                            />
                          )}
                          {!!it.component && <>{HTMLIcons[it.component]}</>}
                          <p
                            style={{
                              marginTop: 10,
                              marginBottom: -10,
                            }}
                          >
                            {it.title}
                          </p>
                        </div>
                      </Card>
                    </AspectRatio>
                  </Col>
                ))}
              </Row>
            )}
          </> */}
              {element.type === "textarea" && (
                <div style={{ marginTop: 20 }}>
                  <Input.TextArea
                    {...element.props}
                    defaultValue={props.data[element.key]}
                    onChange={(e) => {
                      props.updateEntry(element.key, e.target.value);
                      //   setFormData({
                      //     ...formData,
                      //     basicInfo: {
                      //       ...formData.basicInfo,
                      //       [element.key]: e.target.value,
                      //     },
                      //   });
                    }}
                  />
                </div>
              )}
            </Card>
          </div>
        ))}
    </>
  );
};
