import React from "react";
// import {
//   Card,
//   Input,
//   Row,
//   Col,
//   Select,
//   Switch,
//   RadioGroup,
//   Button,
// } from "@pai-ui/core";
import "./../style.css";

import { FormItem } from "@pai-ui/core/components/form-item";
import { GetCustomFormElement } from "./customComponents";
import { Popover } from "@pai-ui/core/components/popover";
import ZsInfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

const SectionItem = (item, formItem, metaData) => {
  // console.log('SectionItem', item, formItem, metaData)
  let options =
    item.component === "radiogroup"
      ? { items: formItem.options }
      : { options: formItem.options };
  // console.log("section item", item.label, options, item, metaData);
  let returnObj = (
    <FormItem
      className="pai-form-lib-item"
      classes={{
        label: "pai-form-lib-item-label",
      }}
      key={item.label}
      labelWidth={150}
      component={item.component}
      field={metaData}
      // label={item.label}
      label={
        <>
          <span style={{ paddingRight: '5px' }}> {item.label}</span>
         { item.info && <Popover
            placement="top"
            title={""}
            content={item.info}
            trigger="hover"
            
          >
            <ZsInfoCircleOutlined style={{ fontSize: 13 }} />
          </Popover>}
        </>
      }
      componentProps={{
        ...item.componentProps,
        ...options,
      }}
    />
  );
  return returnObj;
};

const SectionFields = ({
  formConfig,
  fields,
  getFieldMetadata,
  getValue,
  formDynamicKPIFeilds,
}) => {
  let FormItems = [];
  // eslint-disable-next-line no-debugger

  fields.map((item) => {
    let tempItems = [];
    let formItem = formConfig.allFieldsConfig[item.fieldName];
    //Check if form component or custom component
    if (!item.customComponent) {
      if (item.customDynamicFields) {
        formDynamicKPIFeilds[item.customDynamicFields].map((x) => {
          formItem = formConfig.allFieldsConfig[x.fieldName];
          tempItems.push(
            SectionItem(x, formItem, getFieldMetadata(x.fieldName))
          );
        });
      } else {
        tempItems = [
          SectionItem(item, formItem, getFieldMetadata(item.fieldName)),
        ];
      }
      FormItems.push(...tempItems);
    } else {
      let formMetaData = getFieldMetadata(item.fieldName);
      if (!formMetaData.hidden) {
        let tempElement = GetCustomFormElement(
          item,
          formItem,
          getValue,
          formMetaData
        );
        FormItems.push(tempElement);
      }
    }
  });
  // console.log("form sectionFields", FormItems);

  return <>{FormItems}</>;
};
export default SectionFields;
