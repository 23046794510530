import styled, { css } from 'styled-components'

const globalFontStyles = css`
    margin: 0;
    color: ${props => props.theme.colors[props.colorStyle || "default"]};
    text-align: ${props => props.align || "left"};
    font-weight: 200;
    font-family: 'SF Text';
    margin-bottom: ${props => props.marginBottom ? 7: 0}px!important;
    opacity: ${props => props.muted ? 0.66 : 1};
`

export const Heading1 = styled.h1`
    ${globalFontStyles}
    margin: 0;
    font-family: 'SF Display';
    font-size: ${props => props.theme.fontSizes.xlarge}px;
    line-height: ${props => props.theme.fontSizes.xlarge}px;
    font-family: SF Display;
    font-weight: 200;
`

export const Heading2 = styled.h2`
    ${globalFontStyles}
    margin: 0;
    font-family: 'SF Display';
    font-size: ${props => props.theme.fontSizes.large}px;
    line-height: ${props => props.theme.fontSizes.large}px;
    font-family: SF Display;
    font-weight: bold;
`

export const Heading3 = styled.h3`
    ${globalFontStyles}
    margin: 0;
    font-family: 'SF Display';
    font-size:  ${props => props.theme.fontSizes.medium}px;
    line-height: ${props => props.theme.fontSizes.medium}px;
    font-weight: 600;
`

export const Paragraph = styled.p`
    ${globalFontStyles}
    margin: 0;
    font-size: ${props => props.theme.fontSizes.default}px;
    line-height: ${props => props.theme.fontSizes.default}px;
`

export const Small = styled.small`
    ${globalFontStyles}
    ${props => props.block ? "display: block;" : ""}
    margin: 0;
    font-size: ${props => props.theme.fontSizes.small}px;
    line-height: ${props => props.theme.fontSizes.default}px;
    opacity: 1;
`

export const Span = styled.span`
    ${globalFontStyles}
    ${props => props.block ? "display: block;" : ""}
    margin: 0;
    opacity: 1;
`