import React, {  useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col, Input, Select, Upload } from 'antd';
import Wizard from '../containers/wizard';
import { Panel, LargePanel } from '../containers/layout';
const { Dragger } = Upload

const CSVImporter = ({ onComplete }) => {
    const [/* fileName, */ setFileName] = useState(null)
    return (
        <Wizard
            onFinish={()=>{
                onComplete()
            }}
            steps={[
                {
                    title: "Upload Text File",
                    component: ({ moveNext }) => (
                        <Dragger
                            name="file"
                            multiple={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            onChange={info => {
                                const { status } = info.file
                                if (status === 'done') {
                                    setFileName(info.file.name)
                                    moveNext()
                                }
                            }}
                            accept=".txt,.csv"
                        >
                            <LargePanel height={240}>
                                <UploadOutlined style={{ fontSize: '64px' }} />
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </LargePanel>
                        </Dragger>
                    ),
                    buttons: false
                },
                {
                    title: "Parse",
                    component: () => (
                        <Panel paddingY>
                            <Panel paddingY>
                                <Row style={{marginTop:0}}>
                                    <Col span={10}><span style={{marginTop: 4, display:"block"}}>Record Limit</span></Col>
                                    <Col span={14}><Input value="100" /></Col>
                                </Row>
                                <Row style={{marginTop: 2}}>
                                    <Col span={10}><span style={{marginTop: 4,display:"block"}}>File Format</span></Col>
                                    <Col span={14} >
                                        <Select style={{width: "100%"}} defaultValue="jack">
                                            <Select.Option value="jack">Comma Delimited Text Files (*.csv)</Select.Option>
                                            <Select.Option value="lucy">Semi-colon Delimited Text Files (*.csv)</Select.Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 2}}>
                                    <Col span={10}><span style={{marginTop: 4, display:"block"}}>Output File Name as Field</span></Col>
                                    <Col span={14}><Input value="No" /></Col>
                                </Row>
                                <Row style={{marginTop: 2}}>
                                    <Col span={10}><span style={{marginTop: 4, display:"block"}}>Field Length</span></Col>
                                    <Col span={14}><Input value="2000" /></Col>
                                </Row>
                                <Row style={{marginTop: 2}}>
                                    <Col span={10}><span style={{marginTop: 4, display:"block"}}>Start Data Import on Line</span></Col>
                                    <Col span={14}><Input value="101" /></Col>
                                </Row>
                                <Row style={{marginTop: 2}}>
                                    <Col span={10}><span style={{marginTop: 4, display:"block"}}>Ignore Delimiters in</span></Col>
                                    <Col span={14}>
                                        <Select style={{width: "100%", color:"#FFF"}} defaultValue="jack">
                                            <Select.Option value="jack">Quotes</Select.Option>
                                            <Select.Option value="lucy">Single Quotes</Select.Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Panel>
                        </Panel>
                    )
                }
            ]}
        />
    );
}

export default CSVImporter