import React, { useState, useEffect } from "react";
import moment from "moment";
import {
    Visualization,
    LineChartConfig,
    VisualizationFeature,
    VisualizationActionType
} from '@pai-ui/core/components/visualization';
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import AnalyzeServiceHelper from "@xai/pages/measure-analyze/service-helper";
import { getAnalyzeResponseMetricChartConfig } from './../../transformer';

const ResponseMetricChart = ({ selectedCohort, selectedVersion, selectedVariants }) => {

    const { format } = React.useContext(UserContext);
    const [lineChartData, setLineChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [excludeFieldsArray, setExcludeFieldsArray] = useState([]);

    const { token, experimentModule } = React.useContext(UserContext);
    const service = new AnalyzeServiceHelper(token);

    const loadChartData = async (selectedDateRange) => {
        const groupView = "analyze";
        let dataconfig = getAnalyzeResponseMetricChartConfig(
            experimentModule.selectedExperiment.id,
            ...selectedDateRange,
            groupView,
            experimentModule.selectedExperiment.response_metric,
            selectedVersion
        );
        await service.getAnalyzeResponseMetricChart(dataconfig)
            .then(async (res) => {
                let tempChartData = [...res?.data]
                tempChartData = tempChartData?.map(item => {
                    return {
                        ...item,
                        time_metric: moment(item.time_metric).format('DD-MMM, YYYY'),
                    }
                });

                // Ref - https://stackoverflow.com/questions/68175713/javascript-add-missing-keys-in-an-object-inside-array
                const keys = tempChartData?.reduce(
                    (acc, curr) => (Object.keys(curr).forEach((key) => acc.add(key)), acc),
                    new Set()
                );

                const transformedLineChartData = tempChartData?.map((item) =>
                    [...keys].reduce((acc, key) => ((acc[key] = item[key] ?? null), acc), {})
                );
                console.log('transformedLineChartData Line Chart Data After Tranformation', transformedLineChartData);
                setLineChartData(transformedLineChartData);
                // setLineChartData(null);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getAnalyzeResponseMetricChartData Error', err);
                setError(true);
            });
    }

    const getSelectedDateRange = async () => {
        const experimentTaskManagementData =
            experimentModule.selectedExperiment.experiment_task_management;
        let selectedTaskItem = experimentTaskManagementData.find(
            (item) => item.id === selectedCohort[1]
        );
        console.log('selectedTaskItem', selectedTaskItem)
        if (selectedTaskItem) {
            let datRange = [
                selectedTaskItem["start_time_kpi"],
                selectedTaskItem["end_time_kpi"],
            ];
            console.log("getSelectedDateRange", datRange);
            return datRange;
        } else {
            return;
        }
    };

    const Load = async () => {
        if (selectedCohort && selectedVersion) {
            setError(false);
            setLoading(true);
            getSelectedDateRange().then((selectedDateRange) => {
                /**
                 * Methods to trigger data load for each tabs
                 */
                if (selectedDateRange) {
                    loadChartData(selectedDateRange);
                } else {
                    setLineChartData({ data: [] });
                    setLoading(false);
                }
            });
        } else {
            setLineChartData({ data: [] })
            setLoading(false);
        }
    };

    useEffect(() => {
        Load();
    }, [selectedCohort, selectedVersion]);

    const updateExcludeFields = (chartData) => {
        if (chartData) {
            let localFormatOrderRemovalArray = Object.keys(chartData?.[0])?.filter(item =>
                item.includes("||||variant_order") ||
                item.includes("||||format_id")
            );
            let localSelectedVariantsRemovalArray = Object.keys(chartData?.[0])?.filter(
                item => !selectedVariants?.includes(item.substring(0, item.indexOf('||||')))
            );
            setExcludeFieldsArray([...localFormatOrderRemovalArray, ...localSelectedVariantsRemovalArray]);
        }
    };

    useEffect(() => {
        if (selectedVariants && lineChartData && lineChartData?.[0]) {
            updateExcludeFields(lineChartData);
        }
    }, [selectedVariants, lineChartData]);

    if (error) {
        return (
            <LegacyNoData height={"61vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <>
            {loading ? <LegacyNoData height={"61vh"} loading={true} /> :
                lineChartData &&
                <Visualization
                    title={(experimentModule?.selectedExperiment?.response_metric)}
                    // subTitle={`${splitByDelimiterAndToStartCase(experimentModule?.selectedExperiment?.response_metric)} per ${splitByDelimiterAndToStartCase(experimentModule?.selectedExperiment?.target_variable)}`}
                    height="55vh"
                    actions={[
                        VisualizationActionType.exportJpeg,
                        VisualizationActionType.exportPng,
                        VisualizationActionType.exportCsv,
                    ]}
                    features={[VisualizationFeature.fullScreen]}
                    dataConfig={{
                        structureType: "processed",
                    }}
                    viewConfig={new LineChartConfig({
                        series: {
                            excludeFields: excludeFieldsArray || [],
                            getConfig: (fieldName) => {
                                const config = {
                                    [fieldName]: {
                                        name: splitByDelimiterAndToStartCase(fieldName.replace('||||kpi_value', '')),
                                    },
                                };
                                return {
                                    ...config[fieldName],
                                    label: {
                                        show: false
                                    },
                                    dot: {
                                        show: false,
                                    },
                                };
                            }
                        },
                        axes: {
                            x: {
                                dataKey: 'time_metric',
                            },
                            y: {
                                format: {
                                    value: lineChartData && lineChartData[0] ? format?.[Object.entries(lineChartData[0])?.find(item => item[0]?.includes("||||format_id"))[1]] : null
                                },
                                domain: ["auto", "auto"]
                            }
                        },
                    })}
                    dataset={{ data: lineChartData }}
                />
            }
        </>
    )
};

export default ResponseMetricChart;