import axios from "axios";
import { dotNetApi } from "@xai/library/clients";

// let mock = Boolean(process.env.REACT_APP_MOCK.toLowerCase() === "true");
let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");

const TransformTemplateListData = (experimentListData) => {
  experimentListData?.items?.map((data) => {
    data.template_form_config_table.template_form_config = JSON.parse(
      data?.template_form_config_table?.template_form_config
    );
    data.template_data_table.template_data = JSON.parse(
      data?.template_data_table?.template_data
    );
    data.template_dendogram_rules_table.template_dendogram_rules = JSON.parse(
      data?.template_dendogram_rules_table?.template_dendogram_rules
    );
    return data;
  });
};

const TransformExperimentListData = (experimentListData) => {
  experimentListData?.items?.map((data) => {
    if (
      data.template_master?.template_form_config_table?.template_form_config
    ) {
      data.template_master.template_form_config_table.template_form_config =
        JSON.parse(
          data?.template_master?.template_form_config_table
            ?.template_form_config
        );
    }
    data.experiment_data = JSON.parse(data?.experiment_data);
    data.experiment_overall_kpi_lists = data?.experiment_overall_kpi_lists?.map(
      (kpiObj) => {
        return {
          ...kpiObj,
          kpi_list: JSON.parse(kpiObj?.kpi_list),
          experiment_audience_group_variant_data:
            kpiObj?.experiment_audience_group_variant_data
              ? JSON.parse(kpiObj?.experiment_audience_group_variant_data)
              : [],
        };
      }
    );
    return data;
  });
};

// All the Mock JSON's are moved to CDN in pai-ui-assets-01 Bucket.
export const getAllDesignExperiments = (authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignExperimentCardLayout.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          const { data } = res;
          console.log("getAllDesignExperiments data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getAllDesignExperiments dataConfig", authToken);
      dotNetApi
        .post(
          `/TemplateMasters`,
          {
            query: `{
                            templateMaster(pageSize: 10000, page: 1, sort: { field: id, type: ASC }) {
                              items {
                                id
                                template_name: templateName
                                description: description
                                target_subject: targetSubject
                                template_identifier: templateIdentifier
                                template_form_config_table: templateFormConfig {
                                  template_form_config: templateFormConfig
                                }
                                template_data_table: templateData {
                                  template_data: templateData
                                }
                                template_dendogram_rules_table: templateDendogramRules {
                                  template_dendogram_rules: templateDendogramRules
                                }
                                icon:icon
                              }
                            }
                          }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { templateMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log("getAllDesignExperiments data", res, templateMaster);
          await TransformTemplateListData(templateMaster);
          resolve(templateMaster);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

// export const getDesignExperimentTagLines = (config, generateOpenAIResponse) =>
//   new Promise((resolve, reject) => {
//     if (!generateOpenAIResponse) {
//       axios
//         .get(
//           `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignExperimentTagLineExperiment.json`,
//           {
//             skipInterceptors: true,
//           }
//         )
//         .then((res) => {
//           console.log("getDesignExperimentTagLines data", res);
//           resolve(res.data.content);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     } else {
//       // axios.post(`${process.env.REACT_APP_DOTNET_MICROSERVICE_HOST_CONTENT_GENERATOR}/ContentGenerator`,
//       axios
//         .post(
//           `${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetOpenApiContent`,
//           // {
//           //     "taglines": [
//           //         "Book your flight today!",
//           //         "Don’t wait, book now!",
//           //         "Hurry before it’s too late, book your flight now!"
//           //     ],
//           //     "count": {
//           //         "minCount": 0,
//           //         "maxCount": 20,
//           //         "canContain": [
//           //             "flight"
//           //         ],
//           //         "cantContain": [
//           //             "hello"
//           //         ]
//           //     }
//           // }
//           config
//         )
//         .then((response) => {
//           if (response?.data?.error) {
//             reject({});
//           }
//           resolve(response.data.content);
//         })
//         .catch((err) => {
//           console.log(err);
//           reject({});
//         });
//     }
//   });

export const getAllDesignOptimizations = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignOptimizationCardLayout.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("getAllDesignExperiments data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDesignAISequencingData = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignAISequencingListing.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("getAllDesignExperiments data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDesignAISequencingFormElements = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignAISequencingFormElements.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetDesignAISequencingFormElements data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// export const getSpecificSubjectLineConfig = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios({
//       method: "GET",
//       url: "./sample_data/experiment/GetSpecificSubjectLineConfig.json",
//     })
//       .then((res) => {
//         const { data } = res;
//         console.log("getSpecificSubjectLineConfig data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

export const getAllExperiments = (authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/TestLibraryExperiment.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          const { data } = res;
          console.log("getAllExperiments data", data, authToken);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getAllExperiments dataConfig", authToken);
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `{
              experimentMaster(pageSize: 10000, page: 1)  {
                items {
                  id
                  name: experimentName
                  is_favorite: isFavourite
                  is_active: isActive
                  status
                  start_time: startTime
                  end_time: endTime
                  variant_count: variantCount
                  expected_lift: expectedLift
                  lift
                  response_metric: responseMetric
                  response_metric_impact_format: responseMetricImpactFormat
                  response_metric_impact_value: responseMetricImpactValue
                  target_variable: targetVariable
                  target_variable_format: targetVariableFormat
                  target_variable_value: targetVariableValue
                  analyze_description: analyzeDescription
                  result_description: resultDescription
                  default_version_config: defaultVersionConfig
                  experiment_data: experimentData 	
                  templateId
                  template_master: template {
                    id
                    template_name: templateName
                    template_form_config_table: templateFormConfig {
                      template_form_config: templateFormConfig
                    }
                  }
                  experiment_task_management: experimentTaskManagements {
                    id
                    experiment_id: experimentId
                    experiment_name: experimentName
                    prior_date_range: priorDateRange
                    start_time_kpi: startTimeKpi
                    end_time_kpi: endTimeKpi
                    request_from_screen: requestFromScreen
                    request_type: requestType
                    status
                    is_active: isActive
                    created
                    created_by: createdBy
                    bias_correction_required: biasCorrectionRequired
                    version
                    bias_correction_report: biasCorrectionReport
                    send_out_date:sendOutDate
                    send_out_week:sendOutWeek
                  }
                  experiment_overall_kpi_lists:experimentOverallKpiLists {
                    id
                    experiment_id:experimentId
                    start_time_kpi:startTimeKpi
                    end_time_kpi:endTimeKpi
                    version:version
                    kpi_list:experimentKpiList
                    experiment_audience_group_variant_data:experimentAudienceGroupVariantData
                  }
                }
              }
            }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getAllExperiments data",
            // res,
            experimentMaster
          );
          await TransformExperimentListData(experimentMaster);
          resolve(experimentMaster);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

/**
 * getExperimentVarianBankList
 */

export const getExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    const { experiment_id } = dataconfig;
    if (mock) {
      // mock response for this api call
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperimentVarianBankList.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          const { data } = res;
          console.log("getExperimentVarianBankList data", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("getExperimentVarianBankList dataConfig");
      dotNetApi
        .post(
          `/ExperimentVariantBanks`,
          {
            query: `{
                        experimentVariantBank(
                            page: 1
                            pageSize: 1000
                            experimentId: { value: "${experiment_id}", type: eq }
                        ) {
                            items {
                            variantbankId: id
                            experiment_id: experimentId
                            variant_details: variantDetails
                            action_status: actionStatus
                            comments
                            approvedDate
                            deploymentDate
                            deactivatedDate
                            version
                            experiment_variant_bank_user_logs: experimentVariantBankUserLogs {
                                id
                                userName
                                email
                                step
                                action
                                time
                              }
                            }
                        }
                    }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentVariantBank } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getExperimentVarianBankList data",
            res,
            experimentVariantBank
          );
          // await TransformTemplateListData(templateMaster);
          resolve(experimentVariantBank);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const bulkUpdateExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    // const { experiment_id } = dataconfig;
    console.log("bulkUpdateExperimentVarianBankList - ", dataconfig);
    if (mock) {
      // mock response for this api call
      setTimeout(() => {
        resolve(dataconfig);
      }, 500);
    } else {
      dotNetApi
        .put(
          `/Experiments/ExperimentVariantBanks/BulkUpdate`,
          dataconfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

// export const GetGenAIPreFilters = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios({
//       method: "GET",
//       url: "./sample_data/experiment/GetGenAIPreFilters.json",
//     })
//       .then((res) => {
//         const { data } = res;
//         console.log("GetGenAIPreFilters data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getDeployedAndDeactivatedVariants = (experiment_id) =>
//   dotNetApi.post(`/ExperimentVariantBanks`, {
//     query: `{
//         experimentVariantBank(
//           page: 1
//           pageSize: 10000
//           actionStatuss: ["deployed", "deactivated"]
//           experimentId: { value: "${experiment_id}", type: eq }
//         ) {
//           items {
//             actionStatus
//             experimentId
//             variantDetails
//             deploymentDate
//             deactivatedDate
//           }
//         }
//       }
//       `,
//   });

// export const getApprovedVariants = (experiment_id) =>
//   dotNetApi.post(`/ExperimentVariantBanks`, {
//     query: `{
//         experimentVariantBank(
//           page: 1
//           pageSize: 10000
//           actionStatuss: ["approved"]
//           experimentId: { value: "${experiment_id}", type: eq }
//         ) {
//           items {
//             actionStatus
//             experimentId
//             variantDetails
//             deploymentDate
//             deactivatedDate
//             id
//           }
//         }
//       }
//       `,
//   });

export const getExperimentNames = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("getExperimentNames data", dataconfig, authToken);
    if (mock) {
      resolve({
        items: [],
      });
      // // mock response for this api call
      // axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/TestLibraryExperiment.json`,
      //     {
      //         skipInterceptors: true
      //     })
      //     .then((res) => {
      //         const { data } = res;
      //         console.log("getAllExperiments data", data, authToken);
      //         resolve(data);
      //     })
      //     .catch((error) => {
      //         reject(error);
      //     });
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `{
                experimentMaster(
                  page: 1
                  pageSize: 1000
                  experimentName: { value: "${dataconfig}", type: eq }
                ) {
                  items {
                    experimentName
                  }
                }
              }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMaster } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "getAllExperiments data",
            // res,
            experimentMaster
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMaster);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const addExperiment = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("addExperiment data", dataconfig, authToken);
    if (mock) {
      resolve({
        id: "test_experiment_id",
        experimentName: dataconfig?.Name,
        status: dataconfig?.status,
        templateId: dataconfig?.templateId,
        isActive: dataconfig?.isActive,
        experiment_data: dataconfig?.experimentData,
      });
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
                experimentMasterUpsert(
                  experimentMaster: {
                    experimentName: "${dataconfig?.Name}"
                    isActive: ${dataconfig?.isActive}
                    status: ${dataconfig?.status}
                    defaultVersionConfig: ${dataconfig?.defaultVersionConfig}
                    templateId: ${dataconfig?.templateId}
                    experimentData: ${dataconfig?.experimentData}
                  }
                ) {
                  id
                  experimentName
                  experiment_data: experimentData
                  status
                  templateId
                  isActive
                }
              }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMasterUpsert } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "addExperiment data",
            // res,
            experimentMasterUpsert
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMasterUpsert);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const bulkUpsertExperimentVarianBankList = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    // const { experiment_id } = dataconfig;
    console.log("bulkUpsertExperimentVarianBankList - ", dataconfig);
    if (mock) {
      // mock response for this api call
      setTimeout(() => {
        resolve(dataconfig);
      }, 500);
    } else {
      dotNetApi
        .put(
          `/Experiments/ExperimentVariantBanks/BulkUpsert`,
          dataconfig
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const updateExperiment = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("updateExperiment data", dataconfig, authToken);
    if (mock) {
      dataconfig.experiment_data = JSON.stringify(
        JSON.parse(dataconfig.experimentData)
      );
      resolve(dataconfig);
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
                experimentMasterUpsert(
                  experimentMaster: {
                    id: "${dataconfig?.id}"
                    experimentName: "${dataconfig?.Name}"
                    isActive: ${dataconfig?.isActive}
                    status: ${dataconfig?.status}
                    defaultVersionConfig: ${dataconfig?.defaultVersionConfig}
                    templateId: ${dataconfig?.templateId}
                    experimentData: ${dataconfig?.experimentData}
                  }
                ) {
                  id
                  experimentName
                  experiment_data: experimentData
                  status
                  templateId
                  isActive
                }
              }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMasterUpsert } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "updateExperiment data",
            // res,
            experimentMasterUpsert
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMasterUpsert);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const deleteExperiment = (dataconfig, authToken) =>
  new Promise((resolve, reject) => {
    console.log("deleteExperiment data", dataconfig, authToken);
    if (mock) {
      resolve([]);
    } else {
      dotNetApi
        .post(
          `/ExperimentMasters`,
          {
            query: `mutation {
              experimentMasterDelete(id: "${dataconfig?.id}") {
                id
                experimentName
              }
            }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then(async (res) => {
          let { experimentMasterDelete } = res?.data?.data;
          // experimentMaster.experiments = experimentMaster.items;
          // delete experimentMaster.items;

          console.log(
            "deleteExperiment data",
            // res,
            experimentMasterDelete
          );
          // await TransformExperimentListData(experimentMaster);
          resolve(experimentMasterDelete);
          // resolve({items:[]});
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });

export const csvExportFeatureApi = (dataconfig) =>
  new Promise((resolve, reject) => {
    // mock = false; // temporary
    // const { experiment_id, action_status_list, templete } = dataconfig;
    if (mock) {
      // mock response for this api call
      console.log(
        "csvExportFeatureApi",
        "experiment_id, action_status_list, templete"
      );
      resolve();
    } else {
      console.log("getExperimentVarianBankList dataConfig");
      dotNetApi
        .post(
          `/Experiments/ExperimentVariantBanks/CsvExport`,
          dataconfig,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Accept: "text/csv",
            },
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });
