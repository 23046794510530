import React from "react";
import { Collapse, Button, Row, Col } from "antd";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";

export const PublishComponent = (props) => {
  const allTemplates = props.allData.templates.outputs;
  const allVariants = props.allData.variants.variants;

  return (
    <div style={{ padding: "20px 0px" }}>
      <Collapse bordered={false} defaultActiveKey={"0"}>
        {allVariants.map((variant, vIndex) => (
          <Collapse.Panel
            key={vIndex.toString()}
            header={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <h4 style={{ margin: 0 }}>{variant.name}</h4>
                </div>
                <Button
                  type="ghost"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Edit Outputs
                </Button>
              </div>
            }
          >
            <div style={{ padding: 0 }}>
              <Row gutter={[20, 20]}>
                {(allTemplates[vIndex] || []).map((item, index) => (
                  <Col span={props.orientation === "portrait" ? 8 : 8} key={index}>
                    <AspectRatio
                      width={props.orientation === "portrait" ? 10 : 16}
                      height={props.orientation === "portrait" ? 16 : 10}
                    >
                      <img
                        src={item}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 10,
                        }}
                      ></img>
                    </AspectRatio>
                  </Col>
                ))}
              </Row>
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
