import * as DesignService from "@xai/services/Design";

class DesignAISequencingServiceHelper { //getDataScreenData
  constructor(token) {
    this.authToken = token;
  }

  async getDesignAISequencingData() {
    const data = await DesignService.getDesignAISequencingData(
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getDesignAISequencingFormElements() {
    const data = await DesignService.getDesignAISequencingFormElements(
      this.authToken
    );
    if (!data) return null;
    return data;
  }

}

export default DesignAISequencingServiceHelper;
