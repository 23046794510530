import * as MeasureService from "@xai/services/Measure";

class MeasureResultsServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getExperimentResultSummary(dataConfig) {
    const data = await MeasureService.getExperimentResultSummary(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async postExperimentVariantImageUpload(dataConfig) {
    const data = await MeasureService.postExperimentVariantImageUpload(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExperimentResultVariantComparison(dataConfig) {
    const data = await MeasureService.getExperimentResultVariantComparison(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExperimentResultDetailedViewConfidenceInterval(dataConfig) {
    const data = await MeasureService.getExperimentResultDetailedViewConfidenceInterval(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data.data;
  }

  async getExperimentResultDetailedViewResponseMetric(dataConfig) {
    const data = await MeasureService.getExperimentResultDetailedViewResponseMetric(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data.data;
  }

  async getExperimentResultDetailedViewParameterVariantComparisionTable(dataConfig) {
    const data = await MeasureService.getExperimentResultDetailedViewParameterVariantComparisionTable(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExperimentResultSegmentImpactExplorerData(dataConfig) {
    const data =
      await MeasureService.getExperimentResultSegmentImpactExplorerData(
        dataConfig,
        this.authToken
      );
    if (!data) return null;
    return data;
  }

  async getExperimentResultSegmentImpactExplorerParams(dataConfig) {
    const data =
      await MeasureService.getExperimentResultSegmentImpactExplorerParams(
        dataConfig,
        this.authToken
      );
    if (!data) return null;
    return data;

  }

  async exportExperimentSegmentImpactReport(dataConfig) {
    const data =
      await MeasureService.getExportExperimentSegmentImpactReport(
        dataConfig,
        this.authToken
      );
    if (!data) return null;
    return data;
  }

  async getExperimentResultSummaryTwoByTwo(dataConfig) {
    const data = await MeasureService.getExperimentResultSummaryTwoByTwo(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getExperimentResultDetailedViewCorrelationChart(dataConfig) {
    const data = await MeasureService.getExperimentResultDetailedViewCorrelationChart(
      dataConfig,
      this.authToken
    );
    if (!data) return null;
    return data.data;
  }

}

export default MeasureResultsServiceHelper;
