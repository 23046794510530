import React, { useState, useEffect } from "react";
import { Space } from "@pai-ui/core/components/space";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Input } from "@pai-ui/core/components/input";
import { Card } from "@pai-ui/core/components/card";
import { Button, Select } from "@pai-ui/core";
import { TextArea } from "@pai-ui/core/components/text-area";
import { Checkbox } from "@pai-ui/core/components/checkbox";
import { Form } from "antd";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { PlusSquareOutline } from "@styled-icons/evaicons-outline/PlusSquareOutline";
import "./style.css";
// import { getS3DataNew } from "../../../transformer";
import TabGrid from "./TabGrid";
import { WaveLoading } from "styled-spinkit";
import MaxAIServiceHelper from "../../service-helper";
import { UserContext } from "@xai/providers/with-auth";

const TemplateForm = ({ selectedTemplate, setSelectedTemplate }) => {
  
  const [form] = Form.useForm();

  const [TemplateFormConfig, setTemplateFormConfig] = useState();
  const [DefaultFormData, setDefaultFormData] = useState();
  const [errorFlag, seterrorFlag] = useState(false);
  const [deleteErrormessage, setdeleteErrormessage] = useState(false);
  const [APIErrorFlag, setAPIErrorFlag] = useState(false);
  const [formData, setFormData] = useState();
  const [firstStepTagLinesList, setfirstStepTagLinesList] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [noList, setNoList] = useState(0);
  const [showList, setShowList] = useState(0);

  // const token = {
  //   token: "need-some-token-to-work-with-adding-dummy-text-for-now",
  // };
  const token = React.useContext(UserContext);
  const service = new MaxAIServiceHelper(token);

  const Load = async () => {
    await service
      .getMaxAITemplateFormConfig()
      .then(async (res) => {
        console.log("Data from S3 TemplateFormConfig.json", res);
        setTemplateFormConfig(res);
      })
      .catch((err) => {
        console.log("Error from S3 TemplateFormConfig.json", err);
      });

    await service
      .getMaxAITemplateDefaultFormData()
      .then(async (res) => {
        console.log(
          "Data from S3 TemplateDefaultFormData.json",
          res,
          res?.["Airline"]
        );
        setDefaultFormData(res);
        setFormData(res?.["Airline"]);
      })
      .catch((err) => {
        console.log("Error from S3 TemplateDefaultFormData.json", err);
      });
  };

  useEffect(() => {
    Load();
  }, []);

  const selectHandler = (v) => {
    const tempFormData = DefaultFormData[v];
    setFormData(tempFormData);
    form.setFieldsValue({
      keywords: tempFormData.metaData.keywords,
      min: tempFormData.count.minCount,
      max: tempFormData.count.maxCount,
      tone: tempFormData.metaData.tone,
    });
    setResponseList([]);
    setfirstStepTagLinesList([]);
  };

  const taglineInputChange = (value, index) => {
    let locObj = { ...formData };
    let locVar = value?.replaceAll(",", " ");
    locVar = locVar.split(" ");
    locVar = locVar.filter((i) => i !== "");
    if (locVar.length < 3) {
      seterrorFlag(true);
      setdeleteErrormessage(true);
    } else {
      seterrorFlag(false);
      setdeleteErrormessage(false);
    }
    locObj.taglines[index] = value;
    setFormData(locObj);
  };

  const taglineDeleteHandler = (index) => {
    let locObj = { ...formData };
    locObj.taglines = locObj?.taglines?.filter((item, i) => i !== index);
    if (
      locObj?.taglines?.[locObj?.taglines?.length - 1]?.split(" ")?.length < 3
    ) {
      setdeleteErrormessage(true);
    } else {
      setdeleteErrormessage(false);
    }
    setFormData(locObj);
    seterrorFlag(false);
  };

  const addNewTaglineHandler = () => {
    let locObj = { ...formData };
    locObj.taglines = [...locObj.taglines, ""];
    setFormData(locObj);
  };

  const generateHandler = async () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("success values => ", values);
        setAPIErrorFlag(false);
        setShowList(true);
        setNoList(false);
        let locTagline = formData?.taglines;
        let locMetaData = form.getFieldValue("keywords").split(",");
        locMetaData = locMetaData?.map((item, i) => {
          if (item.startsWith(i)) {
            return item;
          } else {
            return `${i + 1})${item}`;
          }
        });
        locTagline = locTagline?.map((item, i) => {
          if (item.startsWith(i)) {
            return item;
          } else {
            return `${i + 1})${item}`;
          }
        });
        let respInput = {
          taglines: [
            `You are an agent trying to create multiple different customer taglines for a ${
              formData.metaData.industry
            } company. Each tagline should include one of the following concepts/keywords: ${String(
              locMetaData.map((i) => {
                return i;
              })
            ).replaceAll(",", "")}.Example taglines are: ${String(
              locTagline.map((i) => {
                return i;
              })
            )}. Please create ${form.getFieldValue(
              "generateCount"
            )} tagline variations, with at least ${form.getFieldValue(
              "min"
            )} and a maximum of ${form.getFieldValue(
              "max"
            )}, containing a ${form.getFieldValue("tone")} tone`,
          ],
          tagvariantcount: form.getFieldValue("generateCount"), //formData.count.generateCount,
          min: Number(form.getFieldValue("min"))
            ? Number(form.getFieldValue("min"))
            : form.getFieldValue("min"), //Number(formData.count.minCount) ? Number(formData.count.minCount) : formData.count.minCount,
          max: Number(form.getFieldValue("max"))
            ? Number(form.getFieldValue("max"))
            : form.getFieldValue("max"), //Number(formData.count.maxCount) ? Number(formData.count.maxCount) : formData.count.maxCount
        };
        let generateOpenAIResponse = form.getFieldValue("open_ai");
        console.log("getTagLines Called", respInput, generateOpenAIResponse);
        if (generateOpenAIResponse) {
          await service
            .getMaxAITagLines(respInput, generateOpenAIResponse)
            .then(async (res) => {
              console.log("getTagLines Response", res);
              Object.values(res).forEach(function (value) {
                if (value.length === 0) {
                  setNoList(true);
                } else {
                  value.map((ittt) => {
                    if (ittt !== "") {
                      responseList.push(
                        ittt
                          .replaceAll(".", "")
                          .replaceAll('"', "")
                          .replaceAll(":", " ")
                      );
                    }
                  });
                  setNoList(false);
                }
              });
              let unique = [...new Set(responseList)];
              setResponseList(unique);

              let locAry = unique.map((it, index) => {
                return {
                  id: index,
                  name: it,
                  fav: index < 4 ? true : false,
                  liked: false,
                  disliked: false,
                  new: false,
                  edit: false,
                  control: false,
                  weight: 0,
                };
              });
              setfirstStepTagLinesList(locAry);
              setShowList(false);
            })
            .catch((err) => {
              console.log("getTagLines Error", err);
              setAPIErrorFlag(true);
            });
        } else {
          await service
            .getMaxAIGetTemplateTaglineResponse()
            .then(async (res) => {
              console.log("getTagLines Response", res);
              Object.values(
                form.getFieldValue("open_ai")
                  ? res
                  : res?.content?.[formData?.metaData?.industry]
              ).forEach(function (value) {
                if (value.length === 0) {
                  setNoList(true);
                } else {
                  value.map((ittt) => {
                    if (ittt !== "") {
                      responseList.push(
                        ittt
                          .replaceAll(".", "")
                          .replaceAll('"', "")
                          .replaceAll(":", " ")
                      );
                    }
                  });
                  setNoList(false);
                }
              });
              let unique = [...new Set(responseList)];
              setResponseList(unique);

              let locAry = unique?.map((it, index) => {
                return {
                  id: index,
                  name: it,
                  fav: index < 4 ? true : false,
                  liked: false,
                  disliked: false,
                };
              });
              setfirstStepTagLinesList(locAry);
              setShowList(false);
            })
            .catch((err) => {
              console.log("getTagLines Error", err);
              setAPIErrorFlag(true);
            });
        }
      })
      .catch((errorInfo) => {
        console.log("failureCallback values => ", JSON.stringify(errorInfo));
      });
  };

  return (
    <>
      {TemplateFormConfig && DefaultFormData && formData ? (
        <>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ marginLeft: "10px", marginBottom: "15px" }}>
              <Space>
                <span
                  className="select-link"
                  onClick={() => {
                    setSelectedTemplate(null);
                  }}
                >
                  Max AI Apps
                </span>
                <span>/</span>
                <span>{selectedTemplate?.title}</span>
              </Space>
            </h3>
            <Select
              style={{
                width: "200px",
              }}
              placeholder={
                TemplateFormConfig?.config?.find(
                  (it) => it.itemKey === "industry"
                )?.placeholder
              }
              options={
                TemplateFormConfig?.config?.find(
                  (it) => it.itemKey === "industry"
                )?.options
              }
              defaultValue={
                TemplateFormConfig?.config?.find(
                  (it) => it.itemKey === "industry"
                )?.defaultOption
              }
              value={formData?.metaData?.industry}
              onChange={(e) => selectHandler(e)}
            />
          </Row>
          <Row>
            <Col span={12}>
              <Form
                form={form}
                name="tagline"
                autoComplete="off"
                style={{ marginRight: "20px" }}
              >
                <div
                  className="outerSection"
                  style={{ marginBottom: "20px" /* height: "40vh"  */ }}
                >
                  <Card className="custom-ant-card-no-padding">
                    <Row
                      style={{
                        justifyContent: "space-between",
                        margin: "20px",
                      }}
                    >
                      <Col span={5}>
                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                          {
                            TemplateFormConfig?.config?.find(
                              (it) => it.itemKey === "taglines"
                            )?.primary_label
                          }
                          {TemplateFormConfig?.config?.find(
                            (it) => it.itemKey === "taglines"
                          )?.mandatory && "*"}
                        </p>
                        <p
                          style={{
                            fontSize: "0.75rem",
                            margin: "0px",
                            opacity: 0.5,
                            fontWeight: "300",
                          }}
                        >
                          {
                            TemplateFormConfig?.config?.find(
                              (it) => it.itemKey === "taglines"
                            )?.secondary_label
                          }
                        </p>
                      </Col>
                      <Col span={17} offset={1}>
                        <div style={{ overflowY: "auto", height: "30vh" }}>
                          {formData?.taglines?.map((i, index) => (
                            <>
                              <Row style={{ margin: "10px 0px" }}>
                                <Col span={23}>
                                  <Input
                                    placeholder={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "taglines"
                                      )?.placeholder
                                    }
                                    style={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "taglines"
                                      )?.style
                                    }
                                    defaultValue={i}
                                    value={i}
                                    onChange={(e) =>
                                      taglineInputChange(
                                        e?.target?.value,
                                        index
                                      )
                                    }
                                  />
                                </Col>
                                <Col span={1}>
                                  <Delete
                                    color={
                                      formData?.taglines?.length === 1 &&
                                      index === 0
                                        ? ""
                                        : "#15afd0"
                                    }
                                    style={{
                                      margin: "5px 0px",
                                      cursor:
                                        formData?.taglines?.length === 1 &&
                                        index === 0
                                          ? ""
                                          : "pointer",
                                    }}
                                    onClick={() =>
                                      formData.taglines.length === 1 &&
                                      index === 0
                                        ? ""
                                        : taglineDeleteHandler(index)
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          ))}
                          <Row>
                            <span style={{ color: "red" }}>
                              {deleteErrormessage &&
                                !formData?.taglines?.includes("") &&
                                "Minimum 3 Words required"}
                            </span>
                          </Row>
                        </div>
                        <Row>
                          <Col span={24} align="right">
                            <PlusSquareOutline
                              color={
                                formData?.taglines?.includes("")
                                  ? ""
                                  : errorFlag
                                  ? ""
                                  : "#15afd0"
                              }
                              width={20}
                              style={{
                                cursor: formData?.taglines?.includes("")
                                  ? ""
                                  : errorFlag
                                  ? ""
                                  : "pointer",
                              }}
                              onClick={() =>
                                formData?.taglines?.includes("")
                                  ? ""
                                  : errorFlag
                                  ? ""
                                  : addNewTaglineHandler()
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </div>
                <div className="outerSection" style={{ marginBottom: "20px" }}>
                  <Card className="custom-ant-card-no-padding">
                    <Row
                      style={{
                        justifyContent: "space-between",
                        margin: "20px",
                      }}
                    >
                      <Col span={5}>
                        <p style={{ fontSize: "1rem", margin: "0px" }}>
                          {
                            TemplateFormConfig?.config?.find(
                              (it) => it.itemKey === "keywords"
                            )?.primary_label
                          }
                          {TemplateFormConfig?.config?.find(
                            (it) => it.itemKey === "keywords"
                          )?.mandatory && "*"}
                        </p>
                        <p
                          style={{
                            fontSize: "0.75rem",
                            margin: "0px",
                            opacity: 0.5,
                            fontWeight: "300",
                          }}
                        >
                          {
                            TemplateFormConfig?.config?.find(
                              (it) => it.itemKey === "keywords"
                            )?.secondary_label
                          }
                        </p>
                      </Col>
                      {console.log("formData", formData?.metaData?.keywords)}
                      <Col span={17} offset={1}>
                        <Form.Item
                          name="keywords"
                          initialValue={formData?.metaData?.keywords}
                          rules={[
                            TemplateFormConfig?.config?.find(
                              (it) => it.itemKey === "keywords"
                            )?.rules,
                          ]}
                        >
                          <TextArea
                            rows={3}
                            name="keywords"
                            placeholder={
                              TemplateFormConfig?.config?.find(
                                (it) => it.itemKey === "keywords"
                              )?.placeholder
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </div>
                <div className="outerSection" style={{ marginBottom: "20px" }}>
                  <Row>
                    <Col span={12}>
                      <Card
                        className="custom-ant-card-no-padding"
                        style={{ marginRight: "20px" }}
                      >
                        <Row
                          style={{
                            justifyContent: "space-between",
                            padding: "20px",
                          }}
                        >
                          <Col span={12}>
                            <p style={{ fontSize: "1rem", margin: "0px" }}>
                              {
                                TemplateFormConfig?.config?.find(
                                  (it) => it.itemKey === "minCount"
                                )?.primary_label
                              }
                              {TemplateFormConfig?.config?.find(
                                (it) => it.itemKey === "minCount"
                              )?.mandatory && "*"}
                            </p>
                            <p
                              style={{
                                fontSize: "0.75rem",
                                margin: "0px",
                                opacity: 0.5,
                                fontWeight: "300",
                              }}
                            >
                              {
                                TemplateFormConfig?.config?.find(
                                  (it) => it.itemKey === "minCount"
                                )?.secondary_label
                              }
                            </p>
                          </Col>
                          <Col
                            span={12}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <Row
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <Col
                                span={10}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Form.Item
                                  name="min"
                                  rules={[
                                    TemplateFormConfig?.config?.find(
                                      (it) => it.itemKey === "minCount"
                                    ).rules,
                                  ]}
                                  initialValue={formData?.count?.minCount}
                                >
                                  <Input
                                    name="minCount"
                                    placeholder={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "minCount"
                                      )?.placeholder
                                    }
                                    style={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "minCount"
                                      )?.style
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <span style={{ margin: "5px 10px" }}>-</span>
                              <Col span={10}>
                                <Form.Item
                                  name="max"
                                  rules={[
                                    TemplateFormConfig?.config?.find(
                                      (it) => it.itemKey === "maxCount"
                                    )?.rules,
                                  ]}
                                  initialValue={formData?.count?.maxCount}
                                >
                                  <Input
                                    name="maxCount"
                                    placeholder={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "maxCount"
                                      )?.placeholder
                                    }
                                    style={
                                      TemplateFormConfig?.config?.find(
                                        (it) => it.itemKey === "maxCount"
                                      )?.style
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card className="custom-ant-card-no-padding">
                        <Row style={{ borderRadius: "10px", padding: "20px" }}>
                          <Col span={12}>
                            <p style={{ fontSize: "1rem", margin: "0px" }}>
                              {
                                TemplateFormConfig?.config?.find(
                                  (it) => it.itemKey === "tone"
                                )?.primary_label
                              }
                              {TemplateFormConfig?.config?.find(
                                (it) => it.itemKey === "tone"
                              )?.mandatory && "*"}
                            </p>
                            <p
                              style={{
                                fontSize: "0.75rem",
                                margin: "0px",
                                opacity: 0.5,
                                fontWeight: "300",
                              }}
                            >
                              {
                                TemplateFormConfig?.config?.find(
                                  (it) => it.itemKey === "tone"
                                )?.secondary_label
                              }
                            </p>
                          </Col>
                          <Col
                            span={11}
                            offset={1}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <Form.Item
                              name="tone"
                              rules={[
                                TemplateFormConfig?.config?.find(
                                  (it) => it.itemKey === "tone"
                                )?.rules,
                              ]}
                              initialValue={formData?.metaData?.tone}
                            >
                              <Input
                                name="tone"
                                placeholder={
                                  TemplateFormConfig?.config?.find(
                                    (it) => it.itemKey === "tone"
                                  )?.placeholder
                                }
                                style={
                                  TemplateFormConfig?.config?.find(
                                    (it) => it.itemKey === "tone"
                                  )?.style
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "8px",
                    alignItems: "baseline",
                  }}
                >
                  <div>
                    <Form.Item
                      name="open_ai"
                      initialValue={
                        formData?.basicInfo?.generate_open_ai_response
                      }
                      valuePropName="checked"
                    >
                      <Checkbox label="Generate Taglines Using OpenAI" />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      name="generateCount"
                      initialValue={formData?.count?.generateCount}
                    >
                      <Select
                        style={{ width: "50px" }}
                        placeholder="Select item"
                        options={[
                          {
                            id: 5,
                            name: 5,
                          },
                          {
                            id: 10,
                            name: 10,
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Button
                      htmlType="submit"
                      ghost
                      disabled={
                        formData?.taglines?.includes("") ||
                        formData?.taglines?.length === 0
                          ? true
                          : errorFlag
                          ? true
                          : false
                      }
                      onClick={() => generateHandler()}
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
            <Col span={12}>
              <Card className="custom-ant-card-no-padding">
                <div
                  style={{
                    marginBottom: "10px",
                    padding: "20px",
                    marginLeft: "20px",
                    height: "74.5vh",
                    overflowY: "auto",
                  }}
                >
                  <TabGrid
                    firstStepTagLinesList={firstStepTagLinesList}
                    setfirstStepTagLinesList={setfirstStepTagLinesList}
                    showList={showList}
                    noList={noList}
                    setNoList={setNoList}
                    APIErrorFlag={APIErrorFlag}
                    generateHandler={generateHandler}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Button
            label="Close"
            ghost
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setSelectedTemplate(null);
            }}
          />
        </>
      ) : (
        <WaveLoading color="#15afd0" />
      )}
    </>
  );
};

export default TemplateForm;
