import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { arrayMoveImmutable } from 'array-move';
import { InformationCircle } from '@styled-icons/heroicons-outline/InformationCircle';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import { Visualization, TableConfig } from '@pai-ui/core/components/visualization';
import { Space } from '@pai-ui/core/components/space';
import { Popover } from '@pai-ui/core/components/popover';
import { Button } from '@pai-ui/core/components/button';
import { Divider } from '@pai-ui/core/components/divider';
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";
import { ConfidenceIntervalStartKeyword, ConfidenceIntervalEndKeyword, ImpactKeyword } from "./transformer";
import '../index.css';

const ParameterVariantComparisionTable = ({ tableData, selectedVariants, error, loadDetailedViewTabData, selectedDateRangeVal, selectedAudience }) => {

    const [data, setData] = useState();
    const [columns, setColumns] = useState();
    const [loading, setLoading] = useState(true);

    const defaultFilteredTableRows = 13;

    const { format, experimentModule } = React.useContext(UserContext);
    const ExperimentAudienceGroupAvailable = experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]?.audience_group_name;

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const SortableItem = SortableElement((props) => (
        <tr {...props} />));

    const SortableBody = SortableContainer((props) => (
        <tbody {...props} />));


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let newData = { data: [] };
            newData.data = arrayMoveImmutable(data.data.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            console.log('Sorted items: ', newData);
            setData(newData);
        }
    }
    const DraggableContainer = (props) => {
        console.log(props);
        return (
            <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />);
    };
    const DraggableBodyRow = ({ ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index

        const index = data?.data?.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const ConfidenceIntervalCalculation = ({ dataKey, dataFormat, dataRow }) => {
        let tempStr = "";
        let tempObjKey = tableData?.columns?.find((item) => item.dataKey === dataKey.substring(0, dataKey.indexOf('||||'))).children.find((childrenItem) => childrenItem.kpi_name === ConfidenceIntervalEndKeyword)["key"];
        tempStr = `${numeral(dataRow[dataKey]).format(format?.[dataFormat])} - ${numeral(dataRow[tempObjKey]).format(format?.[dataFormat])}`;
        return tempStr;
    }

    const loadTableColumns = (tableColumnData, data) => {
        if (tableColumnData && tableColumnData?.length > 0 && data) {
            let tempTableColumns = tableColumnData;
            console.log('tableColumnData', tableColumnData);
            let initialColumn = {
                dataKey: "parameter_type",
                title: <div style={{ textAlign: "center" }}>Parameters</div>,
                filters: tableData?.table_data?.data?.map((parameters) => {
                    return {
                        value: parameters?.parameter_type,
                        text: parameters?.parameter_type,
                    }
                }),
                onFilter: (value, record) => record?.parameter_type?.startsWith(value),
                filterSearch: true,
                defaultFilteredValue: tableData?.table_data?.data?.slice(0, defaultFilteredTableRows)?.map((parameters) => parameters.parameter_type),
                fixed: 'left',
                width: 250,
                render: (text) => <Space><DragHandle />{(text)}</Space>
            };
            tempTableColumns =
                ExperimentAudienceGroupAvailable ?
                    tempTableColumns
                        ?.filter((columnObj) =>
                            columnObj?.comparision_variant_1_parent_name === selectedAudience ||
                            columnObj?.comparision_variant_2_parent_name === selectedAudience)
                        ?.filter((columnObj) =>
                            selectedVariants?.includes(columnObj?.comparision_variant_1_name) ||
                            selectedVariants?.includes(columnObj?.comparision_variant_2_name)) :
                    tempTableColumns
                        ?.filter((columnObj) =>
                            selectedVariants?.includes(columnObj?.comparision_variant_1_name) ||
                            selectedVariants?.includes(columnObj?.comparision_variant_2_name));
            tempTableColumns = tempTableColumns
                ?.map((column) => {
                    return {
                        dataKey: column?.dataKey,
                        // title: `${column.comparision_variant_1_name} vs ${column.comparision_variant_2_name}`,
                        title: (
                            <span>
                                <Popover
                                    overlayStyle={{ zoom: 1.1111111 }}  // Popover tooltip positioning.
                                    content={
                                        <div>
                                            {column?.comparision_variant_1_label &&
                                                column?.comparision_variant_2_label &&
                                                (column?.comparision_variant_1_label?.includes('||||') || column?.comparision_variant_2_label?.includes('||||')) ?
                                                <>
                                                    <h3>
                                                        {`${column?.comparision_variant_1_name} - ${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_name} - ${column?.comparision_variant_2_label?.split('||||')?.[0]}`}
                                                    </h3>
                                                    {
                                                        column?.comparision_variant_1_label?.split('||||')?.[1] ?
                                                            <>
                                                                <Divider orientation="left" style={{ margin: "5px 0x" }} />
                                                                <p>{`Variant 1 Body: ${column?.comparision_variant_1_label?.split('||||')?.[1]}`}</p>
                                                            </> : <></>
                                                    }
                                                    {
                                                        column?.comparision_variant_2_label?.split('||||')?.[1] ?
                                                            <>
                                                                <Divider orientation="left" style={{ margin: "5px 0x" }} />
                                                                <p>{`Variant 2 Body: ${column?.comparision_variant_2_label?.split('||||')?.[1]}`}</p>
                                                            </> : <></>
                                                    }
                                                </> :
                                                <h3>
                                                    {`${column?.comparision_variant_1_label} vs ${column?.comparision_variant_2_label}`}
                                                </h3>
                                            }
                                        </div>
                                    }
                                >
                                    <InformationCircle style={{ width: "23px" }}></InformationCircle>
                                </Popover> &nbsp;&nbsp;&nbsp;
                                {`${column?.comparision_variant_1_name} vs ${column?.comparision_variant_2_name}`}
                            </span>
                        ),
                        children: column?.children?.filter((filterColumnMetric) => filterColumnMetric.kpi_name !== ConfidenceIntervalEndKeyword)?.map((columnMetric) => {
                            return {
                                title: columnMetric?.kpi_name?.toLowerCase() === ConfidenceIntervalStartKeyword?.toLowerCase() ? columnMetric?.kpi_name?.replace("Start", "") : columnMetric?.kpi_name,
                                key: columnMetric?.key,
                                dataIndex: columnMetric?.key,
                                align: "right",
                                width: "7.25vw",
                                render: (_, r) => {
                                    const localFormatValue = columnMetric?.format_list?.find((formatObj) => formatObj.parameter_type === r.parameter_type)?.format;
                                    return (
                                        columnMetric?.kpi_name?.toLowerCase() === ConfidenceIntervalStartKeyword?.toLowerCase() ?
                                            <div>
                                                <ConfidenceIntervalCalculation
                                                    dataKey={columnMetric?.key}
                                                    dataFormat={localFormatValue}
                                                    dataRow={r}
                                                />
                                            </div> :
                                            (_ !== null) ?
                                                <PosNegValue
                                                    value={_}
                                                    valueFormat={format?.[localFormatValue]}
                                                    textColor={"rgba(225,225,225,0.85)"}
                                                    showCaret={columnMetric?.kpi_name?.toLowerCase() === ImpactKeyword?.toLowerCase()}
                                                    ZeroRange={{
                                                        minNegZero:
                                                            r[`${columnMetric?.key?.substring(0, columnMetric?.key?.indexOf('||||'))}||||impact_magnitude`] == "negative"
                                                                ?
                                                                _ == 0 ?
                                                                    _ + 1
                                                                    :
                                                                    _ > 0
                                                                        ? _ * 1.01
                                                                        : _ * 0.99
                                                                : _,
                                                        minPosZero:
                                                            r[`${columnMetric?.key?.substring(0, columnMetric?.key?.indexOf('||||'))}||||impact_magnitude`] == "positive"
                                                                ?
                                                                _ == 0 ?
                                                                    _ - 1
                                                                    :
                                                                    _ > 0
                                                                        ? _ * 0.99
                                                                        : _ * 1.01
                                                                : _,
                                                    }}
                                                />
                                                : "--"
                                    )
                                }
                            }
                        })
                    };
                });
            tempTableColumns?.length > 0 ?
                setColumns([initialColumn, ...tempTableColumns])
                : setColumns([]);
        }
    }

    const Load = () => {
        setLoading(true);
        let localTableData = tableData?.table_data;
        if (localTableData?.data) {
            localTableData.data = localTableData?.data?.map((row, index) => {
                return {
                    ...row,
                    index: index
                }
            });
            setData(localTableData);
        }
        setLoading(false);
    }

    const LeftIcon = ({ onClick }) => {
        return <button onClick={onClick} className={"className"} style={{ zIndex: "100 !important" }}>LEFT</button>;
    };

    const RightIcon = ({ onClick, className }) => {
        return <button onClick={onClick} className={className}>RIGHT</button>;
    };

    useEffect(() => {
        Load();
    }, []);

    useEffect(() => {
        loadTableColumns(tableData?.columns, data);
    }, [data, selectedVariants, selectedAudience]);

    if (error) {
        return (
            <LegacyNoData height={"69vh"} error={true}>
                <Button onClick={() => loadDetailedViewTabData(selectedDateRangeVal)}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <>
            {
                loading ?
                    <LegacyNoData height={"71vh"} loading /> :
                    data && columns?.length > 0 ?
                        <Visualization
                            className="comparision-table-section"
                            height={"41vh"}
                            withCard={false}
                            dataConfig={{
                                structureType: "processed",
                            }}
                            viewConfig={new TableConfig({
                                pagination: false,
                                sticky: true,
                                // rowIndex 
                                idProperty: "index",
                                components: {
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                    },
                                },
                                tableLayout: 'fixed',
                                columns: columns,
                                bordered: false,
                                onRow: (record, index) => ({
                                    index,
                                }),
                                scroll: {
                                    // x: 'calc(700px  50%)',
                                    // y: 'calc(27vh)',
                                },
                                slider: {
                                    enabled: true,
                                    step: 150,
                                    leftGap: 0,
                                    rightGap: 0,
                                    LeftIcon,
                                    RightIcon,
                                },
                            })}
                            dataset={
                                data
                            }
                        />
                        // </div>
                        :
                        <LegacyNoData height={"71vh"} />
            }
        </>
    );
};

export default ParameterVariantComparisionTable;