import React, { useState, useEffect } from "react";
import { Visualization, CorrelationMatrixConfig } from '@pai-ui/core/components/visualization';
import EnterpriseLedgerServiceHelper from "@xai/pages/executive-enterprise-ledger/service-helper";
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getEnterpriseLedgerConfig } from "../../transformer";

const ExperimentRevenueBusinessUnitCorrelation = ({ filters }) => {

    const [filtersOptions, setFiltersOptions] = useState([]);
    const [currentFilter, setCurrentFilter] = useState();

    const [rawData, setRawData] = useState();
    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { token, format } = React.useContext(UserContext);
    const service = new EnterpriseLedgerServiceHelper(token);

    const Load = async () => {
        setLoading(true);
        let dataconfig = getEnterpriseLedgerConfig(
            filters?.dateRange?.[0],
            filters?.dateRange?.[1]
        );
        console.log('dataconfig', dataconfig);
        await service.getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart(dataconfig)
            .then(async (res) => {
                console.log('getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart', res);
                setFiltersOptions(res?.viz_filter_1);
                setCurrentFilter(res?.viz_filter_1[0]?.id)
                setChartData(res?.viz_data[res?.viz_filter_1[0]?.id]);
                setRawData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getExecutiveEnterpriseLedgerExperimentRevenueBusinessCorrelationChart Error', err);
                setError(true);
            });
    }

    useEffect(() => {
        if (filters?.dateRange?.length !== 0) {
            Load();
        }
    }, [filters]);

    useEffect(() => {
        if (currentFilter) {
            if (rawData?.viz_data[currentFilter]) {
                setChartData(rawData?.viz_data[currentFilter]);
            }
        }
    }, [currentFilter]);

    if (error) {
        return (
            <LegacyNoData height={"58vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <>
            {
                loading ?
                    <LegacyNoData height={"58vh"} loading={true} /> :
                    <>
                        <Visualization
                            title="Revenue Experiments By Business Unit"
                            height={"58vh"}
                            width={"100%"}
                            filters={[
                                {
                                    value: currentFilter,
                                    optionsBasedFilterProps: {
                                        options: filtersOptions,
                                    },
                                    type: 'select',
                                    style: {
                                        width: "150px"
                                    },
                                    onChange: setCurrentFilter,
                                }
                            ]}
                            viewConfig={
                                new CorrelationMatrixConfig({
                                    excludeFields: chartData?.data && chartData?.data[0] ? Object.keys(chartData?.data[0]).filter(item => item.includes("format")) : [],
                                    rowNameField: 'Feature',
                                    columnWidth: {
                                        default: 'fill',
                                        label: 80,
                                    },
                                    format: {
                                        value: chartData?.data && chartData?.data[0] ? format?.[Object.entries(chartData?.data[0]).find(item => item[0]?.includes("format"))[1]] : null
                                    },
                                })
                            }
                            dataConfig={{
                                structureType: "processed",
                            }}
                            dataset={chartData}
                        />
                    </>
            }
        </>
    );
};

export default ExperimentRevenueBusinessUnitCorrelation;