import React from "react";
import { AspectRatio } from "../aspect-ratio/AspectRatio";

export const FullImageDisplay = (props) => {
  return (
    <div>
      <div
        onClick={() => {
          props.onClick && props.onClick(props.item);
        }}
      >
        <AspectRatio width={props.width} height={props.height}>
          <img
            src={props.item.image_url}
            id="downloadableDiv"
            className={props.className}
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover" }}
          />
        </AspectRatio>
      </div>
    </div>
  );
};
