import React from "react";
import { Row, Col, Tag } from "antd";
import {
    Panel as LegacyPanel,
} from "@xai/library/legacy/containers/layout";
import { Color } from "@xai/assets/generic-components/color-select";
import {
    ZsUserAddOutlined,
    ZsEditOutlined,
    ZsDeleteOutlined,
    ZsCopyOutlined,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";


const TestSummary = ({
    name,
    target,
    status,
    description,
    response,
    variants
}) => {

    const ModelMetric = ({ label, value, align }) => (
        <LegacyPanel>
            <p
                style={{
                    margin: "0px 0px 3px",
                    color: Color[2],
                    lineHeight: "101%",
                    display: "block",
                    textAlign: align || "left",
                    fontSize: "14px",
                }}
            >
                {label}
            </p>
            <p
                style={{
                    margin: 0,
                    fontSize: "14px",
                    color: "#FFF",
                    lineHeight: "125%",
                    textAlign: align || "left",
                    marginTop: 6,
                }}
                className="line-clamp-2"
            >
                {value}
            </p>
        </LegacyPanel>
    );

    const getIcon = (status) => {
        if (status === "Completed") {
            return <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/results/checked.svg`} alt="" style={{ height: "20px", margin: "0px 10px 5px 0px" }} />
        } else if (status === "Inprogress") {
            return <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/results/work-in-progress.svg`} alt="" style={{ height: "20px", margin: "0px 10px 5px 0px" }} />
        } else {
            return <img src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/results/rec.svg`} alt="" style={{ height: "20px", margin: "0px 10px 5px 0px" }} />
        }
    }

    const getColor = (status) => {
        if (status === "Completed") {
            return <Tag style={{ background: "#15afd0", color: "#ffffff", width: "80px", textAlign: "center" }}>Published</Tag>
        } else {
            return <Tag style={{ border: "2px solid #15afd0", color: "#15afd0", width: "80px", textAlign: "center" }}>Draft</Tag>
        }
    }

    return (
        <Row span={24} style={{ cursor: "pointer", marginBottom: 10 }}>
            <Col span={24} style={{ padding: "0px 0px 0px 0px" }} align="middle">
                <LegacyPanel className={"hover-section"} round disableOverflow>
                    <LegacyPanel
                        flexible
                        background="rgba(255,255,255,0.03)"
                        paddingX
                        paddingY
                    >
                        <LegacyPanel flexible paddingY>
                            <Row align="middle">
                                <Col span={6}>
                                    <Row>
                                        <ModelMetric label="" value={getIcon(status)} />
                                        <ModelMetric label="" value={`${name}`} />
                                    </Row>
                                    <Row>
                                        <div style={{ fontStyle: "italic", color: "gray", fontSize: "12px" }} >
                                            {description}
                                        </div>
                                    </Row>
                                </Col>
                                <Col span={3}>
                                    <ModelMetric label="" value={getColor(status)} />
                                </Col>
                                <Col span={3}>
                                    <ModelMetric label="Target Subject" value={target} />
                                </Col>
                                <Col span={4}>
                                    <ModelMetric label="Variant" value={variants} />
                                </Col>
                                <Col span={4}>
                                    <ModelMetric label="Response Metric" value={response} />
                                </Col>
                                <Col span={4} >
                                    <ModelMetric label="" value={
                                        <div style={{ marginLeft: "50%" }}>
                                            <ZsUserAddOutlined style={{ fontSize: 20, color: "#15afd0", marginRight: "8px" }} />
                                            <ZsEditOutlined style={{ fontSize: 20, color: "#15afd0", marginRight: "8px" }} />
                                            <ZsCopyOutlined style={{ fontSize: 20, color: "#15afd0", marginRight: "8px" }} />
                                            <ZsDeleteOutlined style={{ fontSize: 20, color: "#15afd0", marginRight: "8px" }} />
                                        </div>
                                    } />
                                </Col>
                            </Row>
                        </LegacyPanel>
                    </LegacyPanel>
                </LegacyPanel>
            </Col>
        </Row>
    );
};

export default TestSummary;