import React, { useEffect, useState } from "react";
import moment from "moment";
import { groupBy } from "lodash";
import { Loading } from "@pai-ui/core/components/loading";
// import { getDeployedAndDeactivatedVariants } from "@xai/services/Design";
import { UserContext } from "@xai/providers/with-auth";
import { VariantCard } from "./variantCard.js";
import { Filters } from "./filters.js";

const dateFormat = "DD-MMM-YYYY";

export const PastDeployments = ({ segregatedVariantBankData }) => {
  const [loading, setLoading] = useState(true);
  const [allVariants, setAllVariants] = useState([]);
  const [variants, setVariants] = useState([]);
  const { experimentModule } = React.useContext(UserContext);
  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;

  const [filtersData, setFiltersData] = useState({
    title_bodycopy: "",
    status: [],
    deployment_date: [],
    grouped_by_deployment_date: false,
  });

  const loadData = async () => {
    setLoading(true);
    // const data = await getDeployedAndDeactivatedVariants(
    //   "e47fed16-1b14-11ee-99dd-6ac934cd6e11"
    // );
    // const mappedVariants = data?.data?.data?.experimentVariantBank?.items?.map(
    //   (x) => ({ ...x, variantDetails: JSON.parse(x.variantDetails) })
    // );

    const mappedVariants = [].concat(
      segregatedVariantBankData?.deployedDataList,
      segregatedVariantBankData?.deactivatedDataList
    );

    setAllVariants(mappedVariants || []);
    setVariants(mappedVariants || []);
    setLoading(false);

    console.log("PastDeployments", {
      mappedVariants,
    });
  };

  console.log({
    allVariants,
    variants,
    mapped: groupBy(variants, (v) => v.deploymentDate),
    experimentModule,
  });

  useEffect(() => {
    loadData();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        paddingTop: "20px",
      }}
    >
      <Filters
        allVariants={allVariants}
        setVariants={setVariants}
        filtersData={filtersData}
        setFiltersData={setFiltersData}
      />
      <div
        style={{
          maxHeight: "83vh",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          overflowY: "auto",
          padding: "10px",
        }}
      >
        {filtersData?.grouped_by_deployment_date ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            {Object.values(
              // Object with descending sort order for deploymentDate
              Object.keys(groupBy(variants, (v) => v.deploymentDate))
                .sort((a, b) => moment(b).diff(moment(a)))
                .reduce((temp_obj, key) => {
                  temp_obj[key] = groupBy(variants, (v) => v.deploymentDate)[
                    key
                  ];
                  return temp_obj;
                }, {})
              // groupBy(variants, (v) => v.deploymentDate)
            )?.map((groupedVariants, index) => {
              return (
                <>
                  <div
                    key={index}
                    style={{
                      padding: "15px",
                      borderRadius: "5px",
                      // boxShadow: "0px 0px 4px 0px #686868",
                      border: "1px solid #3f3f3f",
                      // background: "rgb(39 133 205 / 12%)",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        paddingTop: "0px",
                        fontSize: "16px",
                        opacity: "0.75",
                      }} //
                    >{`Deployment Date: ${moment(
                      groupedVariants?.[0]?.deploymentDate
                    ).format(dateFormat)}`}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      {groupedVariants?.map((x) => (
                        <>
                          <VariantCard
                            variant={x}
                            key={x.experimentId}
                            ExperimentTagGroups={ExperimentTagGroups}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                  {/* <Divider orientation="left" style={{ margin: "0px" }} /> */}
                </>
              );
            })}
          </div>
        ) : (
          variants?.map((x) => (
            <VariantCard
              variant={x}
              key={x.experimentId}
              ExperimentTagGroups={ExperimentTagGroups}
            />
          ))
        )}
      </div>
    </div>
  );
};
