import React from "react";
import { Collapse, Button, Row, Col } from "antd";
import { AspectRatio } from "../../design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import Axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const downloadImages = async (name, images) => {
  let zip = new JSZip();

  let variantImages = await Promise.all(
    images.map(async (img) => {
      let response = await fetch(img);
      let data = await response.blob();
      return {
        name:
          img.split("/").pop().indexOf(".") === -1
            ? `${img.split("/").pop()}.png`
            : img.split("/").pop(),
        data,
      };
    })
  );

  for (let j = 0; j < variantImages.length; j++) {
    zip.file(variantImages[j].name, variantImages[j].data);
  }

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${name}.zip`);
  });
};

export const PublishComponent = (props) => {
  let singleVariant = props.allData.variants.variants.length === 1;

  const allTemplates = props.allData.templates.outputs;
  const allVariants = props.allData.variants.variants.filter((v, i) =>
    singleVariant ? true : i > 0
  );

  const [published, setPublished] = React.useState([]);

  React.useEffect(() => {
    Axios.post("https://paiweb.vercel.app/api/gen-ai/canva-list", {
      session_id: "a6c2d4d0-c7be-43e3-a79e-235c257624ff",
    }).then((response) => {
      console.log(response.data);
      setPublished(response.data.outputs);
    });
  }, []);

  return (
    <div style={{ padding: "20px 0px" }}>
      <Collapse bordered={false} defaultActiveKey={["canva", "0"]}>
        {!!published?.length && (
          <Collapse.Panel
            key={"canva"}
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 0px 10px",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1 }}>
                  <h4 style={{ margin: 0 }}>Canva</h4>
                </div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    downloadImages("Canva", published);
                  }}
                >
                  Download All
                </Button>
              </div>
            }
          >
            <div style={{ padding: 20, marginBottom: 20 }}>
              <Row gutter={[20, 20]}>
                {published.map((p, index) => (
                  <Col span={8} key={index}>
                    <img
                      src={p}
                      style={{
                        width: "100%",
                        borderRadius: 10,
                      }}
                    ></img>
                  </Col>
                ))}
              </Row>
            </div>
          </Collapse.Panel>
        )}
        {allVariants.map((variant, vIndex) => (
          <Collapse.Panel
            key={vIndex.toString()}
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 0px 10px",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1 }}>
                  <h4 style={{ margin: 0 }}>{variant.name}</h4>
                </div>
                {!!(allTemplates[singleVariant ? vIndex : vIndex + 1] || [])
                  .length && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      downloadImages(
                        variant.name,
                        allTemplates[singleVariant ? vIndex : vIndex + 1] || []
                      );
                    }}
                  >
                    Download All
                  </Button>
                )}
              </div>
            }
          >
            <div style={{ padding: 20, marginBottom: 20 }}>
              <Row gutter={[20, 20]}>
                {(allTemplates[singleVariant ? vIndex : vIndex + 1] || []).map(
                  (item, index) => (
                    <Col
                      span={props.orientation === "portrait" ? 8 : 8}
                      key={index}
                    >
                      <AspectRatio
                        width={props.orientation === "portrait" ? 10 : 16}
                        height={props.orientation === "portrait" ? 16 : 10}
                      >
                        <img
                          src={item}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: 10,
                          }}
                        ></img>
                      </AspectRatio>
                    </Col>
                  )
                )}
              </Row>
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
