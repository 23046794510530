import React, { useState, useEffect } from "react";
import { Visualization, CorrelationMatrixConfig } from '@pai-ui/core/components/visualization';
import EnterpriseLedgerServiceHelper from "@xai/pages/executive-enterprise-ledger/service-helper";
import { Button } from '@pai-ui/core/components/button';
import { Select } from '@pai-ui/core/components/select';
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { FilterComponent } from "@xai/assets/generic-components/utils";
import { getEnterpriseLedgerConfig } from "../../transformer";

const ExperimentRevenueCorrelation = ({ filters }) => {

    const [rawData, setRawData] = useState();
    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [metricsOptions, setMetricsoption] = useState([]);
    const [currentMetric, setCurrentMetric] = useState();

    const { token, format } = React.useContext(UserContext);
    const service = new EnterpriseLedgerServiceHelper(token);

    const Load = async () => {
        setLoading(true);
        let dataconfig = getEnterpriseLedgerConfig(
            filters?.dateRange?.[0],
            filters?.dateRange?.[1]
        );
        console.log('dataconfig', dataconfig);
        await service.getExecutiveEnterLedgerExperimentRevenueCorrelationChart(dataconfig)
            .then(async (res) => {
                console.log('getExecutiveEnterLedgerExperimentRevenueCorrelationChart', res);
                setMetricsoption(res?.viz_filter_1);
                setCurrentMetric(res?.viz_filter_1[0]?.id)
                setChartData(res?.viz_data[res?.viz_filter_1[0]?.id]);
                setRawData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getExecutiveEnterLedgerExperimentRevenueCorrelationChart Error', err);
                setError(true);
            });
    }

    useEffect(() => {
        if (filters?.dateRange?.length !== 0) {
            Load();
        }
    }, [filters]);

    useEffect(() => {
        if (currentMetric) {
            if (rawData?.viz_data[currentMetric]) {
                setChartData(rawData?.viz_data[currentMetric]);
            }
        }
    }, [currentMetric]);

    if (error) {
        return (
            <LegacyNoData height={"70vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <>
            {
                loading ?
                    <LegacyNoData height={"70vh"} loading={true} /> :
                    <>
                        {
                            <Visualization
                                title="Experiment Revenue"
                                height={"70vh"}
                                width={"100%"}
                                headerHeight={70}
                                headerContent={{
                                    right: <>
                                        <FilterComponent title="Select segmentation variable">
                                            <Select
                                                options={metricsOptions}
                                                value={currentMetric}
                                                style={{
                                                    width: "150px"
                                                }}
                                                onChange={(value) => setCurrentMetric(value)}
                                            />
                                        </FilterComponent>
                                    </>,
                                }}
                                viewConfig={
                                    new CorrelationMatrixConfig({
                                        excludeFields: chartData?.data && chartData?.data[0] ? Object.keys(chartData?.data[0]).filter(item => item.includes("format")) : [],
                                        rowNameField: 'Feature',
                                        columnWidth: {
                                            default: 'fill',
                                            label: 80,
                                        },
                                        format: {
                                            value: chartData?.data && chartData?.data[0]["format"] ? format?.[Object.entries(chartData?.data[0]).find(item => item[0]?.includes("format"))[1]] : null
                                        },
                                    })
                                }
                                dataConfig={{
                                    structureType: "processed",
                                }}
                                dataset={chartData}
                            />
                        }
                    </>
            }
        </>
    );
};

export default ExperimentRevenueCorrelation;