import React from 'react'
import { Input, } from 'antd';
import './index.css'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ZsTable from "@xai/assets/generic-ant-d-components/zs-table.js";


import {
    ZsAvatar,
    ZsEditFilled,
    ZsTag
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

const { Panel } = Collapse;

const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"]

export const categories = [
    {
        name: "Designer",
        icon: "luxury",
        splitup: {
            'Group A': 27,
            'Control': 23
        }
    },
    {
        name: "Beauty Brands",
        icon: "beauty",
        splitup: {
            'Group A': 21,
            'Control': 17
        }
    },
    {
        name: "Apparel",
        icon: "apparel",
        splitup: {
            'Group A': 16,
            'Control': 14
        }
    },
    {
        name: "Shoes",
        icon: "footwear",
        splitup: {
            'Group A': 8,
            'Control': 12
        }
    },
    {
        name: "Bags",
        icon: "handbag",
        splitup: {
            'Group A': 10,
            'Control': 9
        }
    },
    {
        name: "Accessories",
        icon: "accessories",
        splitup: {
            'Group A': 5,
            'Control': 7
        }
    }
]

export const Metric = ({ title, value, align }) => (
    <div style={{ textAlign: align || "left", marginLeft: (align || "left") !== "left" ? 30 : 0, marginRight: (align || "left") !== "right" ? 30 : 0 }}>
        <h1 style={{ margin: 0, lineHeight: "100%", fontWeight: 400 }}>{value}</h1>
        <p style={{ margin: 0, color: colors[0], fontSize: '13px' }}>{title}</p>
    </div>
)
export const MetricSummary = ({ name, value, percentage, noBg, subValue }) => (

    <Panel flexible background={noBg ? "" : "rgba(255,255,255,0.03)"} paddingX round>

        <Panel flexible stack="horizontal" center paddingY>
            <Panel width="50px">

            </Panel>
            <Panel width="10px"></Panel>
            <Panel flexible>
                <h2 style={{ margin: 0, color: colors[0], fontSize: '28px', fontWeight: 400, lineHeight: "100%", textTransform: "uppercase", marginBottom: subValue ? -10 : 0 }}>
                    {value}
                    {subValue &&
                        <small style={{ color: colors[1], opacity: 0.75, /* fontSize: "50%", */ fontSize: '13px' }}> / {subValue}</small>
                    }
                </h2>
                <p style={{ margin: 0, width: "auto" }}>{name}</p>
            </Panel>
            {percentage &&
                <Panel width="10px">

                </Panel>
            }
        </Panel>
    </Panel>
)

export const InlineInput = ({ label, placeholder }) => {
    <Panel stack="horizontal">
        <p style={{ margin: "0px 8px 0px", flexShrink: 0, color: colors[0] }}>{label[0]}</p>
        <Input placeholder={placeholder} style={{ minWidth: "none", maxWidth: 45, marginTop: -5 }} />
        <p style={{ margin: "0px 0px 0px 8px", color: colors[0], flexShrink: 0, }}>{label[1]}</p>
    </Panel>,
        <Panel height="7px" />
}

export const FetchSummaryData = (
    elements, formConfig
) => {
    console.log('aayushelements', elements, formConfig)
    if (!elements) return [];

    const data_basic_details = [
        {
            key: "2",
            control: "Experiment Name",
            response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "experiment_name")[0].value,

            control1: "Frequency",
            response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "frequency")[0].value
        },
        {
            key: "3",
            control: "Hypothesis",
            response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "underlying_hypothesis")[0].value,

            control1: "Target Variable",
            response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "variable")[0].value
        },

        {
            key: "8",
            control: "Test Duration",
            response:
                // "4/1/2021 – 9/30/2021",
                Array.isArray(elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value)
                    ?
                    `${elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[0].format("MM/DD/YYYY")} - ${elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "test_duration")[0].value[1].format("MM/DD/YYYY")}`

                    : "",

            control1: "Response Metric",
            response1: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "metric")[0].value
        },


        {
            key: "12",
            control: "Expected Lift",
            response:
                elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "baseline")[0].value
                    ?
                    elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "baseline")[0].value
                    : "",
            control1: "",
            response1: ""
        },
    ]
    return data_basic_details;
}
export const getAdvancedData = (
    elements, formConfig
) => {
    console.log('aayushelements', elements, formConfig)
    if (!elements) return [];

    const data_basic_details = [
        {
            key: "2",
            control: "Statistical Power",
            response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "Statistical")[0].value,
            control1: "",
            response1: ""
        },
        {
            key: "3",
            control: "Significance​",
            control1: "",
            response1: "",
            response: elements && elements.formConfig.sections[0].elements.filter(x => x.backendName == "Significance​")[0].value,
        },


    ]
    return data_basic_details;
}
export const getDesignDetails = (
    elements, formConfig
) => {
    console.log('aayushelements', elements, formConfig)
    if (!elements) return [];

    const data_basic_details = [
        {
            key: "2",
            control: "Number of Target Groups",
            response: '6',
            control1: "",
            response1: ""
        },
        {
            key: "3",
            control: "Total Population",
            response: "1,500,000",
            response1: "",
            control1: ""
        },
        {
            key: "4",
            control: "Warnings",
            response: (["Revenue per Customer", "Offers per Cutomer", "Population Size"].map(x => {
                return (<ZsTag value={x} key={0}>{x}</ZsTag>)
            })),
            response1: "",
            control1: ""
        },


    ]
    return data_basic_details;
}

export const Columns = () => {
    return [
        {
            title: "",
            dataIndex: "control",
            key: "control",
            width: "17%",
            render: (data) => (
                <span style={{ color: "#FFF", float: "left" }}>{data != "" ? data + " :" : ""}</span>
            ),
        },
        {
            width: "30%",
            title: (
                <React.Fragment>
                    <span style={{ cursor: "pointer" }}></span>
                </React.Fragment>
            ),
            dataIndex: "response",
            key: "response",
            render: (data) => <span style={{ opacity: "0.65" }}>{data}</span>,
        },
        {
            width: "20%",
            title: "",
            dataIndex: "control1",
            key: "control1",
            render: (data) => (
                <span style={{ color: "#FFF", float: "left" }}>{data != "" ? data + " :" : ""}</span>
            ),
        },
        {
            width: "30%",
            title: (
                <React.Fragment>
                    <span style={{ cursor: "pointer" }}></span>
                </React.Fragment>
            ),
            dataIndex: "response1",
            key: "response1",
            render: (data) => <span style={{ opacity: "0.65" }}>{data}</span>,
        },
    ];
};

const ABTestAnalyse = (elements, formConfig) => {


    const columns = Columns();


    return (


        <div className="summary-container" style={{ marginBottom: "20px" }}>
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
            >
                <Panel
                    header={<div>
                        Global Details{" "}
                        <ZsAvatar
                            size={20}
                            style={{
                                backgroundColor: "#15afd0",
                                cursor: "pointer",
                            }}

                            value={<ZsEditFilled />}
                        >
                            <ZsEditFilled />
                        </ZsAvatar>
                    </div>}
                    key="1" className="site-collapse-custom-panel"
                    bordered={true}
                >
                    <ZsTable
                        columns={columns}
                        dataSource={FetchSummaryData(elements, formConfig)}
                        size="small"
                        pagination={false}
                        showHeader={false}
                        bodered={true}
                    />
                </Panel>
                <Panel
                    header={<div>
                        Advanced Configuration{" "}
                        <ZsAvatar
                            size={20}
                            style={{
                                backgroundColor: "#15afd0",
                                cursor: "pointer",
                            }}

                            value={<ZsEditFilled />}
                        >
                            <ZsEditFilled />
                        </ZsAvatar>
                    </div>}
                    key="2" className="site-collapse-custom-panel"
                    bordered={true}
                >
                    <ZsTable
                        columns={columns}
                        dataSource={getAdvancedData(elements, formConfig)}
                        size="small"
                        pagination={false}
                        showHeader={false}
                        bodered={true}
                    />

                </Panel>
                <Panel
                    header={<div>
                        Design Details{" "}
                        <ZsAvatar
                            size={20}
                            style={{
                                backgroundColor: "#15afd0",
                                cursor: "pointer",
                            }}

                            value={<ZsEditFilled />}
                        >
                            <ZsEditFilled />
                        </ZsAvatar>
                    </div>}
                    key="3" className="site-collapse-custom-panel"
                    bordered={true}
                >
                    <ZsTable
                        columns={columns}
                        dataSource={getDesignDetails(elements, formConfig)}
                        size="small"
                        pagination={false}
                        showHeader={false}
                        bodered={true}
                    />

                </Panel>


            </Collapse>
        </div>
    );




}

export default ABTestAnalyse