/* eslint-disable */
import axios from "axios";

export const getAllUsers = (authToken) =>
  new Promise((resolve) => {
    //to be changed
    //const mock = process.env.REACT_APP_AUTH_MOCK;
    // const mock = false;
    let fetchUrl =
      // mock
      //   ? "./sample_data/accessControl/GetAllUsers.json"
      //   :
      `${process.env.REACT_APP_MICROSERVICE_HOST}/access-control/GetAllUsers`;

    axios({
      // method: mock ? "POST" : "GET",
      method: "GET",
      url: fetchUrl,
      data: {},
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + authToken,
      },
    })
      .then((response) => {
        console.log(response.data, "response");
        let processedUsersData = [];

        //convert raw data into table format data
        response.data.users.map((user) => {
          processedUsersData.push({
            id: user.id,
            key: user.id,
            checked: false,
            firstname: user.name,
            email: user.email,
            status: user.is_active,
            role: user.userRoles,
            edit: null,
          });
        });

        resolve(processedUsersData);
      })
      .catch((err) => {
        console.log(err, "error");
        resolve({
          data: [],
        });
      });
  });

export const getAllRoles = (authToken) =>
  new Promise((resolve) => {
    //console.log("get all users called");
    // const mock = false;

    let fetchUrl =
      // mock
      //   ? "./sample_data/accessControl/GetAllRoles.json"
      //   :
      `${process.env.REACT_APP_MICROSERVICE_HOST}/access-control/GetAllRoles`;

    console.log("mock and url", /* mock, */ fetchUrl);
    axios({
      // method: mock ? "POST" : "GET",
      method: "GET",
      url: fetchUrl,
      data: {},
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + authToken,
      },
    })
      .then((response) => {
        let processedRoleData = [];
        response.data.roles.map((r) => {
          processedRoleData.push({
            id: r.id,
            key: r.id,
            role: r.name,
          });
        });
        resolve(processedRoleData);
      })
      .catch(() => {
        resolve({
          role: [],
        });
      });
  });

export const addUser = (data, authToken) =>
  new Promise((resolve) => {
    let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/access-control/UpsertUser`;

    axios({
      method: "POST",
      url: fetchUrl,
      data: { inputjson: data },
      headers: {
        Authorization: "Bearer " + authToken,
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log(err, "error");
        resolve({
          data: [],
        });
      });
    // }
  });

export const editUser = (data, authToken) =>
  new Promise((resolve) => {
    let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/access-control/UpsertUser`;

    axios({
      method: "POST",
      url: fetchUrl,
      data: { inputjson: data },
      headers: {
        Authorization: "Bearer " + authToken,
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        resolve({
          data: [],
        });
      });
    // }
  });

export const deleteUser = (data, authToken) =>
  new Promise((resolve) => {
    let fetchUrl = `${process.env.REACT_APP_MICROSERVICE_HOST}/access-control/DeleteUser`;
    console.log("data", JSON.stringify(data));

    axios({
      method: "POST",
      url: fetchUrl,
      data: { inputjson: data },
      headers: {
        Authorization: "Bearer " + authToken,
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        resolve({
          data: [],
        });
      });
    // }
  });
