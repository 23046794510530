import React, { useState, useEffect } from "react";
import numeral from "numeral";
// import { Row } from '@pai-ui/core/components/row';
import { Col } from "@pai-ui/core/components/col";
import { Warning } from "@styled-icons/entypo/Warning";
import { InformationCircle } from "@styled-icons/heroicons-outline/InformationCircle";
import { Carousel } from "@pai-ui/core/components/carousel";
import { Space } from "@pai-ui/core/components/space";
import { Button } from "@pai-ui/core/components/button";
import { Popover } from "@pai-ui/core/components/popover";
import { Ellipsis } from '@pai-ui/core/components/ellipsis';
import { Panel } from "@xai/library/legacy/containers/layout";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";
import ComparisonTableGenericChartComponent from "@xai/assets/product-specific-common-components/comparison-table-generic-chart-component";
import ChartWrapper from "@xai/assets/generic-components/visualization/components/wrapper";
import {
  ComponentGenericConfig,
  ExcludeFieldsComparisionChart,
  getAnalyzeComparisonTableConfig,
} from "./../../transformer";
import AnalyzeServiceHelper from "@xai/pages/measure-analyze/service-helper";
import FindAvatarIcon from "@xai/assets/generic-components/findAvatarIcon";
import "./index.css";

const ComparisionTableCustomComponent = ({
  selectedCohort,
  selectedVersion,
  selectedVariants
}) => {
  // const [templateGridArray, setTemplateGridArray] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [error, setError] = useState(false);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    hoverEffect: false,
    infinite: false,
  };

  const { token, experimentModule, format } = React.useContext(UserContext);
  const service = new AnalyzeServiceHelper(token);

  // const CarouselTemplateGrid = (split = 4) => {
  //     if (data) {
  //         let tempgrid = [...Array(data.header.length).fill().map(() => "name")]
  //         let tempArray = []
  //         let tempArray2 = []
  //         for (let i = 1; i <= Math.ceil(tempgrid.length / split); i++) {
  //             if (i == 1) {
  //                 if (tempgrid.length > split) {
  //                     tempArray2.push({ startIndex: 0, endIndex: split })
  //                 } else {
  //                     tempArray2.push({ startIndex: 0, endIndex: tempgrid.length })
  //                 }
  //             } else if (Math.ceil(tempgrid.length / split) != i) {
  //                 tempArray2.push({ startIndex: (i * split) - split, endIndex: i * split })

  //             } else {
  //                 tempArray2.push({ startIndex: (i * split) - split, endIndex: tempgrid.length })
  //             }
  //             if (tempArray2.length > 0) {
  //                 tempArray.push(tempArray2)
  //             }
  //             tempArray2 = []
  //         }
  //         setTemplateGridArray(tempArray)
  //     }
  // };

  const getSelectedDateRange = async () => {
    const experimentTaskManagementData =
      experimentModule.selectedExperiment.experiment_task_management;
    let selectedTaskItem = experimentTaskManagementData.find(
      (item) => item.id === selectedCohort[1]
    );
    console.log("selectedTaskItem", selectedTaskItem);
    if (selectedTaskItem) {
      let datRange = [
        selectedTaskItem["start_time_kpi"],
        selectedTaskItem["end_time_kpi"],
      ];
      console.log("getSelectedDateRange", datRange);
      return datRange;
    } else {
      return;
    }
  };

  const loadComparisionData = async (selectedDateRange) => {
    const groupView = "analyze";
    let dataconfig = getAnalyzeComparisonTableConfig(
      experimentModule.selectedExperiment.id,
      ...selectedDateRange,
      groupView,
      selectedVersion
    );
    await service
      .getAnalyzeResponseComparisionTable(dataconfig)
      .then(async (res) => {
        console.log("loadVariantComparisionTableData res", res);
        setData(res);
        // setData(null);
        setTimeout(() => {
          setLoading(false);
          setDataAvailable(true);
        }, 400);
      })
      .catch((err) => {
        console.log("getAnalyzeResponseMetricChartData Error", err);
        setError(true);
      });
  };

  const Load = async () => {
    if (selectedCohort && selectedVersion) {
      setError(false);
      setLoading(true);
      getSelectedDateRange().then((selectedDateRange) => {
        /**
         * Methods to trigger data load for each tabs
         */
        if (selectedDateRange) {
          loadComparisionData(selectedDateRange);
        } else {
          setDataAvailable(false);
          setLoading(false);
        }
      });
    } else {
      setTimeout(() => {
        setLoading(false);
        setDataAvailable(false);
      }, 400);
    }
  };

  useEffect(() => {
    Load();
  }, [selectedCohort, selectedVersion]);

  // useEffect(() => {
  //     CarouselTemplateGrid();
  // }, [data])

  if (error) {
    return (
      <LegacyNoData height={"55vh"} error={true}>
        <Button onClick={() => Load()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return (
    <>
      {loading ? (
        <LegacyNoData height={"55vh"} loading />
      ) : dataAvailable && data ? (
        <div style={{ display: "flex" }}>
          <div style={{ width: "15%" }}>
            {data?.parameters
              ?.sort(
                (a, b) =>
                  parseInt(a.parameter_order) - parseInt(b.parameter_order)
              )
              ?.map((column, index) => (
                <div key={index} className="comparision-table-cell">
                  <Panel
                    height={
                      column?.is_chart
                        ? ComponentGenericConfig["height"]["chart"]
                        : ComponentGenericConfig["height"]["kpi"]
                    }
                    center
                  >
                    <Space>
                      <h2 className="comparision-table-cell-text">
                        {column?.parameter_type}
                      </h2>
                      {
                        column?.parameter_info_text &&
                        <Popover
                          content={
                            <div>
                              <span>
                                {column?.parameter_info_text}
                              </span>
                            </div>
                          }
                        >
                          <InformationCircle
                            style={{ width: "23px" }}
                          ></InformationCircle>
                        </Popover>
                      }
                    </Space>
                  </Panel>
                </div>
              ))}
          </div>
          <div style={{ width: "85%" }} className="comparision-table-carousel">
            <ChartWrapper style={{ justifyContent: "flex-start" }}>
              {({ width }) => (
                <div style={{ width: width }}>
                  <Carousel {...settings}>
                    {data?.header?.filter((headerObj) => selectedVariants?.includes(headerObj?.audience_group_variant_name))?.map((variantName, index) => (
                      <Col key={index} >
                        <div className="comparision-table-cell">
                          <Panel
                            height={ComponentGenericConfig["height"]["kpi"]}
                            center
                            style={{ width: "100%" }}
                          >
                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "inherit",
                              padding: "0px 40px 0px 20px"
                            }}>
                              {/* <Popover content={variantName?.info_text} > */}
                              <h2 className="comparision-table-cell-text" style={{ width: "90%" }}>
                                {
                                  variantName?.audience_group_variant_label ?
                                    <Ellipsis
                                      maxWidth={"90%"}
                                    >
                                      {`${variantName?.audience_group_variant_name}: ${variantName?.audience_group_variant_label}`}
                                    </Ellipsis>
                                    :
                                    <Ellipsis
                                      maxWidth={"90%"}
                                    >
                                      {variantName?.audience_group_variant_name}
                                    </Ellipsis>
                                }
                              </h2>
                              <Popover
                                content={
                                  <div>
                                    {variantName?.info_text && (
                                      <span>
                                        {variantName?.info_text}
                                      </span>
                                    )}
                                  </div>
                                }
                              >
                                <InformationCircle
                                  style={{ width: "23px" }}
                                ></InformationCircle>
                              </Popover>
                            </div>
                          </Panel>
                        </div>
                        {
                          data?.parameters
                            ?.sort(
                              (a, b) =>
                                parseInt(a.parameter_order) -
                                parseInt(b.parameter_order)
                            )
                            ?.map((parameterObject) =>
                              parameterObject?.data
                                ?.filter(
                                  // filter condition to map corresponding parameter data for a variant (column in scope)
                                  (variantParamData) =>
                                    variantParamData.audience_group_variant_name ===
                                    variantName.audience_group_variant_name
                                )
                                ?.map((parameter, index) => (
                                  <div
                                    key={index}
                                    className="comparision-table-cell"
                                  >
                                    <Panel
                                      height={
                                        parameterObject.is_chart
                                          ? ComponentGenericConfig["height"][
                                          "chart"
                                          ]
                                          : ComponentGenericConfig["height"][
                                          "kpi"
                                          ]
                                      }
                                      center
                                    >
                                      {parameterObject?.is_chart ? (
                                        <>
                                          <ComparisonTableGenericChartComponent
                                            height={
                                              ComponentGenericConfig["height"][
                                              "chart"
                                              ]
                                            }
                                            chart_config={parameterObject}
                                            chart_data={parameter.chartdata}
                                            excludeFields={
                                              ExcludeFieldsComparisionChart[
                                              parameterObject.chart_type
                                              ]
                                            }
                                          />
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          {
                                            <h2 className="comparision-table-cell-text">
                                              <Space>
                                                {parameterObject?.icon && (
                                                  <FindAvatarIcon
                                                    avatar_icon={
                                                      parameterObject.icon
                                                    }
                                                    avatar_Size={25}
                                                    avatar_marginTop={0}
                                                  />
                                                )}
                                                {numeral(parameter.kpi_value)
                                                  .format(
                                                    format?.[parameter.format_id]
                                                  )
                                                  .toUpperCase()}
                                                {parameter?.warning_text && (
                                                  <div>
                                                    <Popover
                                                      placement="top"
                                                      content={
                                                        parameter.warning_text
                                                      }
                                                      trigger="hover"
                                                    >
                                                      <Warning
                                                        style={{
                                                          width: "20px",
                                                        }}
                                                        color={"#FFFF00"}
                                                      />
                                                    </Popover>
                                                  </div>
                                                )}
                                              </Space>
                                            </h2>
                                          }
                                        </div>
                                      )}
                                    </Panel>
                                  </div>
                                ))
                            )
                        }
                      </Col>
                    ))}
                  </Carousel>
                </div>
              )}
            </ChartWrapper>
          </div>
        </div>
      ) : (
        <LegacyNoData height={"55vh"} />
      )}
    </>
  );
};

export default ComparisionTableCustomComponent;
