import { UserGroup } from "@styled-icons/heroicons-outline/UserGroup";
import React, { useState } from 'react'
import { Visualization, DendogramChartConfig, VisualizationFeature, VisualizationActionType } from '@pai-ui/core/components/visualization';
import { WaveLoading } from "styled-spinkit";
import { Button } from "@pai-ui/core/components/button";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import "./index.css"
import DrawerComponent from './drawerContent'
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline'
import { Delete } from '@styled-icons/material-outlined/Delete'
import lodash from 'lodash'
import numeral from 'numeral'
import { Database } from '@styled-icons/feather/Database'
import { LunchDining } from '@styled-icons/material-outlined/LunchDining'
import { FoodEgg } from '@styled-icons/fluentui-system-regular/FoodEgg'
import { Location } from '@styled-icons/evil/Location'
import { Medium } from '@styled-icons/remix-line/Medium'
import { CaretDownCircle } from '@styled-icons/boxicons-regular/CaretDownCircle'
import { CaretUpCircle } from '@styled-icons/boxicons-regular/CaretUpCircle'
import { Desktop } from '@styled-icons/boxicons-regular/Desktop'
import { MobileAlt } from '@styled-icons/boxicons-regular/MobileAlt'
import { Apple } from '@styled-icons/boxicons-logos/Apple'
import { Android } from '@styled-icons/boxicons-logos/Android'

function filterID(id, data) {
  return data.reduce((arr, item) => {
    if (item.id != id) {
      if (item.children) item.children = filterID(id, item.children)
      arr.push(item)
    }
    return arr  // <<-- need to return the accumulator  
  }, [])
}
const getIcon = (type) => {
  switch (type) {

    case "User":
      return (<UserGroup
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></UserGroup>);

    case "Medium":
      return (<Medium
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Medium>);

    case "ChevronSmallDown":
      return (<CaretDownCircle
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></CaretDownCircle>);

    case "ChevronSmallUp":
      return (<CaretUpCircle
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></CaretUpCircle>);

    case "Desktop":
      return (<Desktop
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Desktop>);

    case "MobileAlt":
      return (<MobileAlt
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></MobileAlt>);

    case "Apple":
      return (<Apple
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Apple>);

    case "Android":
      return (<Android
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Android>);

    case "Location":
      return (<Location
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Location>);

    case "FoodEgg":
      return (<FoodEgg
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></FoodEgg>);

    case "LunchDining":
      return (<LunchDining
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></LunchDining>);

    case "Database":
      return (<Database
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Database>);

    case "default":
      (<Database
        style={{ height: "48px", marginLeft: "22%", color: "rgba(255, 255, 255, 0.85)" }}></Database>)
      break;
  }
}
function update(nodes) {
  return nodes.reduce((count, node) => {
    if (node.children) {
      var subcount = update(node.children);
      if (node.icon) {
        node["icon"] = getIcon(node.icon);
      }

      return count + subcount;
    } else {
      if (node.icon) {
        node["icon"] = getIcon(node.icon);
      }
      return count + 1;
    }
  }, 0);
}


const Dendogram = ({ data, templateSelected, formConfig, setFormConfig }) => {
  const [layout, /* setLayout */] = useState("vertical")
  const [detailsContent, setDetailsContent] = React.useState(null);
  const [detailsOpened, setDetailsOpened] = React.useState(null);
  const [datad, setdatad] = React.useState()
  const filtersOptions = formConfig?.sections[1]?.elements?.find(elemObj => elemObj.backendName === "dendo")?.filter_options.map((item) => { return { id: item.id, name: item.label } });
  const [currentFilter, setCurrentFilter] = React.useState(formConfig?.sections[1]?.elements?.find(elemObj => elemObj.backendName === "dendo")?.filter_value);

  React.useEffect(() => {
    if (currentFilter) {
      let tempFormConfig = { ...formConfig };
      tempFormConfig.sections[1].elements.find(elemObj => elemObj.backendName === "dendo").filter_value = currentFilter;
      setFormConfig(tempFormConfig);
    }
  }, [currentFilter]);

  const handeDelete = (data, datad) => {
    var k = lodash.cloneDeep(datad);
    var l = [k];
    filterID(data.id, l)
    console.log('parentpath2', l[0]);
    setdatad(l[0])


  }
  console.log('templateSelected', templateSelected);
  React.useEffect(() => {
    var k = lodash.cloneDeep(data)
    if (k) {
      update([k])
    }
    console.log('dendodata', k)
    setdatad(k)
  }, [])

  const renderNodeContent = React.useCallback((data) => {
    return (
      <div style={{ width: data.icon ? '260px' : '248px', height: '100%', padding: 5, border: '1px solid grey', borderRadius: 20, background: "rgba(255, 255, 255, 0.03)" }}>
        <div
          style={{
            width: "95%",
            height: "48px",

            borderRadius: "none",
            textAlign: "left",
            paddingTop: "34px",
            paddingBottom: "44px",
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Row>
            {data.icon && <Col span={6} style={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center"
            }}>
              {data.icon}
            </Col >}
            <Col span={data.icon ? 12 : 18} offset={4} style={{
              marginTop: "5%", display: "flex",
              justifyContent: "left",
              alignItems: "center"
            }} >


              <div>
                <h2 style={{ margin: 0, lineHeight: "100%" }}>{data.name}</h2>
                {data.value1 && (<h2 style={{ margin: 0 }}>{numeral(data.value1).format('0.0a').toUpperCase()}</h2>)}
              </div>

            </Col>
            <Col span={2} style={{ marginTop: "5%", alignItems: "center" }} >
              <div>
                <Settings2Outline style={{ color: "rgba(255, 255, 255, 0.85)", height: "30px", cursor: "pointer", marginBottom: "5px" }} onClick={() => handleEdit(data, datad)} ></Settings2Outline>
              </div>
              <div>
                {data.delete && (
                  <Delete style={{ color: "rgba(255, 255, 255, 0.85)", height: "30px", cursor: "pointer" }} onClick={() => handeDelete(data, datad)} ></Delete>
                )}
              </div>
            </Col>
          </Row>

        </div>
      </div>
    );
  }, [handeDelete, datad]);

  const handleViewFirstMonthStatistic = React.useCallback((data) => {

    console.log('aayushnoode', data)
    setDetailsContent(
      <DrawerComponent item={data} type={"rule"} view={"new"} templateSelected={templateSelected}> </DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);

  const handleViewSecondMonthStatistic = React.useCallback((data) => {
    setDetailsContent(
      <DrawerComponent item={data} type={"variant"} view={"new"} templateSelected={templateSelected}></DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);
  const handleEdit = React.useCallback((data, datad) => {
    setDetailsContent(
      <DrawerComponent item={data} type={data.type} datad={datad} templateSelected={templateSelected}></DrawerComponent>
    );
    setDetailsOpened(true);
  }, []);

  // #TODO Remove this Data Harding Coding
  const localDendoData = {
    "id": "1",
    "value1": 21800000,
    "name": "Panera Customers",
    "type": "rule",
    "icon": "Database",
    "value": {
      "relationship": "{(R1) }",
      "rules": [
        {
          "id": "activeCustomers",
          "operator": "greaterThan",
          "value": "fran"
        }
      ]
    },
    "delete": false,
    "children": [
      {
        "value1": 1400000,
        "id": "2",
        "withActions": true,
        "name": "Multi-Entree",
        "value": {
          "relationship": "{(R1) AND (R2) }",
          "rules": [
            {
              "id": "bev",
              "operator": "is",
              "value": "no"
            }
          ]
        },
        "type": "rule",
        "icon": "Location",
        "delete": true,
        "children": [
          {
            "id": "3",
            "withActions": false,
            "name": "Multi-Entrée only",
            "value1": 1100000,
            "technique": 1,
            "variable": 4,
            "value": {
              "relationship": "{(R1)}",
              "rules": [
                {
                  "id": "13wk",
                  "operator": "lessThan",
                  "value": "16"
                }
              ]
            },
            "type": "",
            "delete": true,
            "children": [

            ]
          },
          {
            "value1": 216000,
            "id": "6",
            "withActions": false,
            "name": "Multi-Entree + Kids Meal",
            "technique": 1,
            "variable": 4,
            "value": {
              "relationship": "{(R1)}",
              "rules": [
                {
                  "id": "13wk",
                  "operator": "greaterThan",
                  "value": "16"
                }
              ]
            },
            "type": "",
            "delete": true,
            "children": [

            ]
          }
        ]
      },
      {
        "value1": 1100000,
        "id": "9",
        "withActions": true,
        "name": "No Multi-Entree",
        "value": {
          "relationship": "{(R1) AND (R2) }",
          "rules": [
            {
              "id": "bev",
              "operator": "is",
              "value": "yes"
            }
          ]
        },
        "type": "rule",
        "icon": "Location",
        "delete": true,
        "children": [
          {
            "id": "10",
            "withActions": false,
            "name": "No Multi-Entrée + Kids Meal",
            "value1": 968000,
            "technique": 1,
            "variable": 4,
            "value": {
              "relationship": "{(R1)}",
              "rules": [
                {
                  "id": "13wk",
                  "operator": "lessThan",
                  "value": "16"
                }
              ]
            },
            "type": "",
            "delete": true,
            "children": [

            ]
          },
          {
            "value1": 143000,
            "id": "13",
            "withActions": false,
            "name": "No Multi-Entrée + Family Feast",
            "technique": 1,
            "variable": 4,
            "value": {
              "relationship": "{(R1)}",
              "rules": [
                {
                  "id": "13wk",
                  "operator": "greaterThan",
                  "value": "16"
                }
              ]
            },
            "type": "",
            "delete": true,
            "children": [

            ]
          }
        ]
      }
    ]
  };

  const actionListViewConfig = () => {
    if (currentFilter === "test-control") {
      return [
        {
          id: '1',
          name: 'Create Audience',
          onClick: handleViewFirstMonthStatistic,
        },
        {
          id: '2',
          name: 'Create Variant',
          onClick: handleViewSecondMonthStatistic,
        },
      ]
    } else {
      return [
        {
          id: '1',
          name: 'Create Audience',
          onClick: handleViewFirstMonthStatistic,
        }
      ]
    }
  }

  const viewConfig = React.useMemo(() => {
    return new DendogramChartConfig({
      node: {
        widthToComputeNodeCoordinates: currentFilter === "test-control" ? 230 : 600,
        heightToComputeNodeCoordinates: 100,
        actions: {
          list: actionListViewConfig(),
          source: 'icon-click',
        },
        renderContent: renderNodeContent,
      },
      layout: layout,
      features: {
        controls: false,
        defaultZoom: 1,
        linkWidthCorrelation: true,
        maxZoom: 2,
        minimap: false,
        minZoom: 0.5,
        paneMoveable: true,
        preventScrolling: false,
        zoomOnScroll: false,
      },
      details: {
        componentProps: { // Drawer component props
          title: (
            <div style={{ marginLeft: "16px"/* , marginLeft: "41%" */ }}>
              Edit Configuration
            </div>
          ),
          width: "30%",

          footer: <Button>Save</Button>,
        },
        opened: detailsOpened,
        content: detailsContent,
        onOpenChange: setDetailsOpened,

      },
    });
  }, [detailsOpened, detailsContent, layout, renderNodeContent]);

  return (
    (layout && datad && Object.keys(datad).length > 0) ? (
      <Visualization

        features={[VisualizationFeature.fullScreen]}
        actions={[VisualizationActionType.exportJpeg, VisualizationActionType.exportPng, VisualizationActionType.exportCsv]}
        filters={[
          {
            value: currentFilter,
            optionsBasedFilterProps: {
              options: filtersOptions,
              renderOption: (x) => `${x.name}`
            },
            type: 'select',
            onChange: setCurrentFilter,
            style:{
              width:"150px"
            }
          }
        ]}
        height={"65vh"}
        width={"100%"}
        // actions={[
        //   {
        //     id: "horizontal",
        //     name: "Horizontal",
        //     onClick: () => setLayout("horizontal"),
        //   },
        //   {
        //     id: "vertical",
        //     name: "Vertical",
        //     onClick: () => setLayout("vertical"),
        //   },
        // ]}
        viewConfig={viewConfig}
        dataConfig={{
          structureType: 'processed'
        }}
        dataset={{
          data: currentFilter === "test-control" ? datad : localDendoData
        }}
      />) : <WaveLoading color="#15afd0" />
  );
}
export default Dendogram;