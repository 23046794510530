import React from "react";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { Paragraph } from "@xai/library/base/typography";
import { Card, CardMeta } from '@pai-ui/core/components/card';
import { Skeleton } from '@pai-ui/core/components/skeleton';
import { Avatar } from '@pai-ui/core/components/avatar';

const LoadingSkeleton = () => {
    return (
        <Card
        size="large"
        style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
        bordered={false}
        bodyStyle={{ backgroundColor: "transparent" }}
       //  loading={!userState.identity}
        actions={
         <Paragraph
         key={1}
         align="center"
         colorStyle="primary"
       >
         Identifying
       </Paragraph>
        }
      >
        <Skeleton>
            <React.Fragment>
              <Avatar
                size={65}
                style={{ marginBottom: 20, backgroundColor: "#15afd0" }}
                icon={<LoadingOutlined />}
              ></Avatar>
              <CardMeta
                title={'loading'}
              />
            </React.Fragment>
        </Skeleton>
      </Card>
    )
}




export default LoadingSkeleton;
