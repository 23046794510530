import React, { useState, useEffect } from "react";
import moment from "moment";
import { Visualization, BarChartConfig } from '@pai-ui/core/components/visualization';
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import EnterpriseLedgerServiceHelper from "@xai/pages/executive-enterprise-ledger/service-helper";
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getEnterpriseLedgerConfig } from "../../transformer";

const RevenueOverTime = ({ filters }) => {

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { token, format } = React.useContext(UserContext);
    const service = new EnterpriseLedgerServiceHelper(token);

    const Load = async () => {
        setLoading(true);
        let dataconfig = getEnterpriseLedgerConfig(
            filters?.dateRange?.[0],
            filters?.dateRange?.[1]
        );
        console.log('dataconfig', dataconfig);
        await service.getExecutiveEnterpriseLedgerRevenueOverTimeChart(dataconfig)
            .then(async (res) => {
                let responseData = [];
                console.log('getExecutiveEnterpriseLedgerRevenueOverTimeChart', res);
                responseData = res?.data?.map(item => {
                    return {
                        ...item,
                        time_metric: moment(item.time_metric).format('DD-MMM, YYYY'),
                    }
                });
                setChartData(responseData);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getExecutiveEnterpriseLedgerRevenueOverTimeChart Error', err);
                setError(true);
            });
    }

    useEffect(() => {
        if (filters?.dateRange?.length !== 0) {
            Load();
        }
    }, [filters]);

    if (error) {
        return (
            <LegacyNoData height={"58vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    // console.log('chartData', Object.keys(chartData[0]).filter(item => item.includes("format")));

    return (
        <>
            {
                loading ?
                    <LegacyNoData height={"58vh"} loading={true} /> :
                    <Visualization
                        title="Revenue Over Time"
                        height={"58vh"}
                        viewConfig={new BarChartConfig({
                            series: {
                                excludeFields: chartData && chartData[0] ? Object.keys(chartData[0]).filter(item => item.includes("format")) : [],
                                getConfig: (fieldName) => {
                                    const config = {
                                        [fieldName]: {
                                            name: splitByDelimiterAndToStartCase(fieldName),
                                        },
                                    };

                                    return config[fieldName];
                                }
                            },
                            axes: {
                                x: {
                                    dataKey: 'time_metric',
                                },
                                y: {
                                    format: {
                                        value: chartData && chartData[0] ? format?.[Object.entries(chartData[0]).find(item => item[0]?.includes("format"))[1]] : null
                                    }
                                }
                            },
                        })}
                        dataConfig={{
                            structureType: "processed",
                        }}
                        dataset={{ data: chartData }}
                    />
            }
        </>
    );
};

export default RevenueOverTime;