import { Button, Col, Modal, Popconfirm, Row, message } from "antd";
import React from "react";
import { useGenerateContext } from "../../contexts/generate-context/GenerateContext";
import {
  DeleteOutlined,
  DownloadOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import { AspectRatio } from "../../components/aspect-ratio/AspectRatio";
import { FullImageDisplay } from "../../components/full-image-display/FullImageDisplay";
import Axios from "axios";
import "./filters.css";

export const ImageEditor = () => {
  const generateContext = useGenerateContext();

  const aspectRatio = generateContext.editing.value
    ? generateContext.editing.value.aspect_ratio
        .split(":")
        .map((i) => parseInt(i))
    : [];

  const [currentFilter, setCurrentFilter] = React.useState();

  const isLandscape = aspectRatio[0] > aspectRatio[1];

  return (
    <Modal
      width={1080}
      visible={!!generateContext.editing.value}
      bodyStyle={{
        maxHeight: "none",
        borderRadius: 25,
        overflow: "hidden",
      }}
      onCancel={() => {
        setCurrentFilter("Normal");
        generateContext.editing.closeEdit(undefined);
      }}
      footer={null}
    >
      {!!aspectRatio.length && (
        <Row>
          <Col span={isLandscape ? 24 : 12}>
            <div style={{ padding: 30 }}>
              <div
                style={{
                  borderRadius: 15,
                  overflow: "hidden",
                }}
              >
                {!!generateContext.editing.value && (
                  <FullImageDisplay
                    width={aspectRatio[0]}
                    height={aspectRatio[1]}
                    item={generateContext.editing.value}
                    className={
                      currentFilter
                        ? `filter-${currentFilter.toLowerCase()}`
                        : ""
                    }
                  />
                )}
              </div>
            </div>
          </Col>
          <Col span={isLandscape ? 24 : 12}>
            <div
              style={{
                padding: 30,
                paddingLeft: isLandscape ? 30 : 0,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <h2 style={{ fontSize: "24px", marginBottom: 10 }}>
                Edit Render
              </h2>
              <p style={{ marginBottom: 25 }}>{prompt}</p>
              <h3 style={{ fontWeight: "bold", marginBottom: 20 }}>Filters</h3>
              <div
                style={{
                  overflow: "auto",
                  marginLeft: -30,
                  marginRight: -30,
                  paddingBottom: 20,
                }}
              >
                <div
                  style={{
                    width:
                      (isLandscape ? 150 : 100) *
                        generateContext.filters.length +
                      40,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 20px",
                    }}
                  >
                    {generateContext.filters.map((f,indx) => (
                      <div key={indx}
                        onClick={() => {
                          setCurrentFilter(f);
                        }}
                        style={{
                          width: isLandscape ? 150 : 100,
                          margin: "0px 10px",
                          cursor: "pointer",
                          borderRadius: 15,
                          overflow: "hidden",
                        }}
                      >
                        <AspectRatio
                          width={aspectRatio[0]}
                          height={aspectRatio[1]}
                        >
                          <div
                            style={{
                              height: "100%",
                              borderRadius: 15,
                              border:
                                f === currentFilter
                                  ? "3px solid #15afd0"
                                  : "1px solid rgba(255,255,255,0.3)",
                              overflow: "hidden",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                backgroundImage: `url(${generateContext.editing.value.image_url})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                borderRadius: 15,
                              }}
                              className={`filter-${f.toLowerCase()}`}
                            ></div>
                          </div>
                        </AspectRatio>
                        <p style={{ textAlign: "center" }}>{f}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ display: isLandscape ? "flex" : "block" }}>
                <Button
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: isLandscape ? undefined : "auto",
                  }}
                  onClick={() => {
                    generateContext.editing.downloadImage();
                  }}
                  size="large"
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  size="large"
                  icon={<FullscreenExitOutlined />}
                >
                  Crop
                </Button>
                <Popconfirm
                  title="Delete Render"
                  description="Are you sure to delete this render?"
                  onConfirm={() => {
                    Axios.post(
                      `https://paiweb.vercel.app/api/gen-ai/item/delete_by_url`,
                      {
                        image_url: generateContext.editing.value.image_url,
                      }
                    ).then(() => {
                      message.success("Render deleted successfully!");
                    });
                    generateContext.editing.closeEdit();
                    //   setImages(
                    //     images.filter(
                    //       (i) =>
                    //         i.image_url !== generateContext.editing.value.image_url
                    //     )
                    //   );
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ width: "100%", marginBottom: 25 }}
                    size="large"
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
