import styled from "styled-components";

export const ColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorBox = styled.div`
  width: 18px;
  height: 18px;
  background: ${(props) => props.background};
  margin-left: 5px;
  box-sizing: border-box;
  border: ${(props) => (props.active ? 1 : 0)}px solid #fff;
  border-radius: 5px;
`;

export const Color_segment = [
  "#15afd0",
  "#acacac",
  "#01872a",
  "#f4f4f5",
  "#b4e4ed",
  "#427580",
  "#e8f3f6",
  "#85bae0",
  "#348ccb",
  "#6BD013",
  "#920912",
];
export const Blue = ["#15afd0"];

export const Color = [
  "#6F0B13", //red
  "#acacac", //grey
  "#15afd0", //primary blue
  "#01872a", //Green
  "#e16c00", //Orange
  "#348ccb",
  "#85bae0", //greyish blue
  "#87c8d5",
  "#b4e4ed",
  "#DAF1FF",
  "#e8f3f6",
  "#01952f",
  "#15afd01a",
  "#6BD013",
  "#181b20",
  "#15afd033",
];

// export const Color_Stack2 = [
//   "#920912",
//   "#dd2d03",
//   "#e16c00",
//   "#01872a",
//   "#15afd0",
//   "#87c8d5",
//   "#DAF1FF",
//   "#acacac",
//   "#b4e4ed",
//   "#85bae0",//greyish blue
//   "#01952f",
//   "#e8f3f6",
// ]

export const Color_Stack = [
  "#e16c00",

  "#999999",

  "#c2c2c2",
  "#11677d",
  "#15afd0",

  "#f4f4f5",
  "#01872a",
  "#15afd0",
  "#87c8d5",
  "#DAF1FF",
  "#acacac",
  "#b4e4ed",
  "#85bae0", //greyish blue
  "#01952f",
  "#e8f3f6",
  "#920912",
  "#dd2d03",
  "#15afd033",
  "#15afd01a",
];
export const Color_Stack_4 = [
  "#01872a",

  "#c2c2c2",
  "#11677d",
  "#15afd0",

  "#f4f4f5",
  "#999999",
  "#15afd0",
  "#87c8d5",
  "#DAF1FF",
  "#acacac",
  "#b4e4ed",
  "#85bae0", //greyish blue
  "#01952f",
  "#e8f3f6",
];

export const Color_Stack_Bundles = [
  "#e16c00",
  "#01872a",
  "#c2c2c2",
  "#11677d",
  "#15afd0",
  "#f4f4f5",
]

export const Color_stack_ABTest = [
  "#6F0B13",//red
  "#acacac",//grey
  "#15afd0",//primary blue
  "#01872a", //Green
  "#348ccb",
  "#11677d",
  "#85bae0",//greyish blue
  "#87c8d5",
  "#999999",
  "#b4e4ed",
  "#DAF1FF",
  "#e8f3f6",
  
];



