import React /* , { useEffect, useState }  */ from "react";
// import { Metric } from "@pai-ui/core/components/metric";
// import { Carousel } from '@pai-ui/core/components/carousel';
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";

// const classes = {
//   root: "cs-root",
//   title: "cs-title",
//   subTitle: "cs-subTitle",
// };

const OverviewUsecaseKpiCards = (/* { filters } */) => {
  return (
    <>
      {/* <div>OverviewUsecaseKpiCards placeholder</div> */}
      <LegacyNoData
        height={"25vh"}
        error={true}
        description={"Usecase Kpi Cards placeholder"}
      />
    </>
  );
};

export default OverviewUsecaseKpiCards;
