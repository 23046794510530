import React, { Component, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Checkbox } from 'antd';
import Wizard from '../containers/wizard';
import { Panel, LargePanel } from '../containers/layout';
import { rand } from '../utils/rand';

const { Dragger } = Upload

class SelectableSheet extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div onClick={() => this.props.onSelect()} style={{ cursor: "pointer", display: "flex", padding: "0px", justifyContent: "start", alignItems: "center", background: this.props.selected ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0.1)", margin: "0 3px 3px 0" }}>
                <Checkbox checked={this.props.selected} style={{ marginLeft: 10 }} />
                <p style={{ margin: "5px 10px", color: "white", fontSize: "12px" }}>{this.props.name}</p>
                <p style={{ margin: "5px 10px 5px auto", color: "rgba(255,255,255,0.5)", fontSize: "10px" }}>{this.props.size.toLocaleString("en-US")} records</p>
            </div>
        )
    }
}

const ExcelImporter = ({ onComplete }) => {
    const [/* fileName, */ setFileName] = useState(null)
    const [files, setFiles] = useState([])
    const [sizes, /* setSizes */] = useState({
        "Customer": rand(10000, 1000000),
        "Product": rand(10000, 1000000),
        "Orders": rand(10000, 1000000),
        "Returns": rand(10000, 1000000)
    })
    return (
        <Wizard
            onFinish={() => {
                onComplete()
            }}
            steps={[
                {
                    title: "Upload Excel File",
                    component: ({ moveNext }) => (
                        <Dragger
                            name="file"
                            multiple={false}
                            action="https://mlforge.free.beeceptor.com"
                            onChange={info => {
                                const { status } = info.file
                                if (status === 'done') {
                                    setFileName(info.file.name)
                                    moveNext()
                                }
                            }}
                            accept=".xls,.xlsx"
                        >
                            <LargePanel height={240}>
                                <UploadOutlined style={{ fontSize: '64px' }} />
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </LargePanel>
                        </Dragger>
                    ),
                    buttons: false
                },
                {
                    title: "Select Sheets",
                    component: () => (
                        <Panel paddingY>
                            <Panel paddingY>
                                <div style={{ width: 280, marginLeft: "auto", marginRight: "auto" }}>
                                    {["Customer", "Product", "Orders", "Returns"].map(sheet => (
                                        <SelectableSheet size={sizes[sheet]} key={sheet} name={sheet} selected={files.indexOf(sheet) !== -1} onSelect={() => {
                                            const index = files.indexOf(sheet)
                                            if (index == -1) {
                                                setFiles([...files, sheet])
                                            } else {
                                                const newFiles = [...files].splice(index, 1)
                                                setFiles(newFiles)
                                            }
                                        }} />
                                    ))}
                                </div>
                            </Panel>
                        </Panel>
                    )
                }
            ]}
        />
    );
}


export default ExcelImporter