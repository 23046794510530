import React, { useState, useEffect, useContext } from "react";
import { Select } from "@pai-ui/core/components/select";
import { Space } from "@pai-ui/core/components/space";
import { Button } from "@pai-ui/core/components/button";
import { UserContext } from "@xai/providers/with-auth";
import withExperiment from "@xai/providers/with-experiment";
import { Grid, GridItem } from "@xai/library/containers/grid";
import ExperimentTopRowGenericComponent from "@xai/assets/product-specific-common-components/experiment-top-row-generic-component/";
import DateSelectionRangePicker from "@xai/assets/product-specific-common-components/date-selection-range-picker";
import * as Comps from "./components";
import { FilterComponent } from "@xai/assets/generic-components/utils";
import WaveLoader from "@xai/assets/generic-components/wave-loader";
import AnalyzeServiceHelper from "@xai/pages/measure-analyze/service-helper";
import { getAddExperimentTaskItemDataconfig } from "./transformer";
import "./index.css";

const currentScreen = "analyze";

const bias_correction_request = "bias_correction";

const MeasureAnalyze = () => {
  const {
    token,
    identity,
    experimentModule,
    dispatch: dispatchMethod,
  } = useContext(UserContext);
  console.log("MeasureAnalyze", experimentModule);

  const service = new AnalyzeServiceHelper(token);

  const [selectedCohort, setSelectedCohort] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [
    selectedExperimentDistinctTaskList,
    setSelectedExperimentDistinctTaskList,
  ] = useState(null);
  const [selectedExperimentTask, setSelectedExperimentTask] = useState(null);

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [
    dagCreationTaskAPIResponseRecieved,
    setDagCreationTaskAPIResponseRecieved,
  ] = useState(false);
  const [dagCreationStatus, setDagCreationStatus] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState(null);
  const [variantsOptions, setVariantsOptions] = useState([]);

  const load = (selectedExperiment) => {
    console.log(
      "experimentModule change - load method",
      experimentModule?.selectedExperiment
    );
    let tempReferenceTaskItem = experimentModule?.analyze_screen_selected_task
      ? experimentModule?.analyze_screen_selected_task
      : selectedExperiment?.experiment_task_management?.find(
        (task) => task?.id === selectedCohort?.[1]
      );
    let tempVariantList = selectedExperiment?.experiment_data?.variant_setup;
    if (tempReferenceTaskItem) {
      let tempExperimentDistinctTaskList =
        selectedExperiment?.experiment_task_management?.filter(
          (task) =>
            new Date(task?.start_time_kpi).toISOString() ===
            new Date(tempReferenceTaskItem?.start_time_kpi).toISOString() &&
            new Date(task?.end_time_kpi).toISOString() ===
            new Date(tempReferenceTaskItem?.end_time_kpi).toISOString()
        );
      setSelectedExperimentTask(tempReferenceTaskItem);
      setSelectedExperimentDistinctTaskList(tempExperimentDistinctTaskList);
      setSelectedVersion(tempReferenceTaskItem?.version);
      let tempVariantOptions = tempVariantList?.map((variantObj) => {
        return {
          id: variantObj?.variant_name,
          name: `${variantObj?.variant_name} - ${variantObj?.variant_label}`
        }
      });
      setVariantsOptions(tempVariantOptions);
      let tempSelectedVariants = tempVariantList?.map((variantObj) => variantObj?.variant_name);
      setSelectedVariants(tempSelectedVariants);
      console.log('tempVariantOptions', tempVariantOptions, tempSelectedVariants);
    }
  };
  useEffect(() => {
    // Reset state variables
    setSelectedExperimentTask(null);
    setSelectedVariants(null);
    setVariantsOptions(null);
    setSelectedVersion(null);
    setSelectedExperimentDistinctTaskList(null);
    if (experimentModule?.selectedExperiment && selectedCohort.length > 0) {
      console.log(
        "experimentModule change",
        experimentModule?.selectedExperiment
      );
      let tempselectedExperiment = experimentModule?.selectedExperiment;
      load(tempselectedExperiment);
    }
  }, [selectedCohort]);

  const versionChangehandler = (value) => {
    setSelectedVersion(value);
    let tempselectedExperiment = experimentModule?.selectedExperiment;
    let tempReferenceTaskItem =
      tempselectedExperiment?.experiment_task_management?.find(
        (task) => task?.version === value
      );
    setSelectedExperimentTask(tempReferenceTaskItem);
  };

  const checkVersionBiasDisable = () => {
    // Method to decide whether Bias correction button should be disabled or enabled.
    /**
     * Case 1 - selected version is version 1
     *
     * Version  Status bias_correction_required
     * 1          1     true
     * 2          5     false
     * 3          1     false
     *
     * Case 2 - If DS fails to populate date for version 1
     * Version  Status bias_correction_required
     * 1          5     true
     *
     * User shouldn't be able to create bias correction request
     *
     * Case 3 - selected version is version 3
     *
     * Version  Status bias_correction_required
     * 1          1     true
     * 2          5     false
     * 3          1     true
     *
     * User should be able to crete a new version request from UI for version 3
     *
     */
    if (!selectedExperimentTask?.bias_correction_required) {
      return true;
    } else {
      let taskListItemWithMaxVersion = Math.max(
        ...selectedExperimentDistinctTaskList?.map((task) => task?.version)
      );
      if (
        selectedExperimentDistinctTaskList?.find(
          (task) => task?.version === taskListItemWithMaxVersion
        )?.status === 5
      ) {
        if (taskListItemWithMaxVersion == selectedExperimentTask?.version) {
          return true;
        }
        return false;
      } else {
        if (
          taskListItemWithMaxVersion == selectedExperimentTask?.version &&
          selectedExperimentTask?.status === 1
        ) {
          if (selectedExperimentTask?.bias_correction_required) {
            return false;
          }
        }
        return true;
      }
    }
  };

  const createBiasCorrectionTask = async () => {
    // const selectedExperiment = experimentModule?.selectedExperiment;
    let biasCorrectionDagTriggerDataconfig = {
      experiment_id: selectedExperimentTask?.experiment_id,
      start_time_kpi: new Date(
        selectedExperimentTask?.start_time_kpi
      ).toISOString(),
      end_time_kpi: new Date(
        selectedExperimentTask?.end_time_kpi
      ).toISOString(),
      request_type: bias_correction_request,
    };
    await service
      .biasCorrectionDagTrigger(biasCorrectionDagTriggerDataconfig)
      .then(async (res) => {
        console.log("biasCorrectionDagTrigger res", res);
        // #TODO : if dag creation successfull insert task item.
        const { dag_created } = res;
        // const dag_created = res;
        if (dag_created) {
          setDagCreationStatus(true);
          let userId = identity.userName;
          let selectedExperimentTaskList =
            experimentModule?.selectedExperiment?.experiment_task_management;
          let addExperimentTaskItemDataconfig =
            getAddExperimentTaskItemDataconfig(
              selectedExperimentTaskList,
              selectedExperimentTask,
              currentScreen,
              userId
            );
          // insert task item to task manaegement table
          await service
            .addExperimentTaskItem(addExperimentTaskItemDataconfig)
            .then((taskItem) => {
              // Add task item to selected experiment task list and update experiment list in React context
              console.log("addExperimentTaskItem taskItem", taskItem);
              let updatedExpList = [...experimentModule?.experimentList];
              let updatedSelectedExperiment = updatedExpList.find(
                (exp) => exp.id === experimentModule?.selectedExperiment.id
              );
              updatedSelectedExperiment?.experiment_task_management.push(
                taskItem
              );
              let finalobj = {
                ...experimentModule,
                experimentList: updatedExpList,
                selectedExperiment: updatedSelectedExperiment,
              };
              console.log("addExperimentTaskItem finalobj", finalobj);
              dispatchMethod({
                type: "experimentModule",
                payload: finalobj,
              });
              // triggering Load method to update the Version dropdown list
              load(updatedSelectedExperiment);
            })
            .catch((err) => {
              console.log("getAnalyzeResponseMetricChartData Error", err);
              // Task creation failed, after successfull dag creation
              // In this scenario, ideally, we should retrigger insert api again or cancel registered api.
              // To be discussed

              // This API approach should be changed in future release.

              // there should be a single API that triggers bias correction request - to register dag and then create associated task in backend db.

              setDagCreationStatus(false);
            });
        } else {
          // DAG creation failed show error message
          setDagCreationStatus(false);
        }
      })
      .catch((err) => {
        console.log("getAnalyzeResponseMetricChartData Error", err);
        // On Error, show failed notification view.
        setDagCreationStatus(false);
      })
      .finally(() => {
        // irrespective of succes or failure need to set true for below state variable to show required message.
        setDagCreationTaskAPIResponseRecieved(true);
      });
  };

  return (
    <>
      <Grid
        template={[
          ["top-row"],
          ["filter-section"],
          ["response-metric-chart"],
          ["comparision-table"],
          ["correct-biases"],
        ]}
      >
        <GridItem area="top-row">
          <ExperimentTopRowGenericComponent currentScreen={currentScreen} />
        </GridItem>
        <GridItem key={"filter-section"}>
          <div
            className="analyze-screen-filter-section"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Space className="analyze-screen-filter-section">
              <DateSelectionRangePicker
                currentScreen={currentScreen}
                selectedCohort={selectedCohort}
                setSelectedCohort={setSelectedCohort}
              />
              <FilterComponent title="Version">
                <Select
                  placeholder="Select item"
                  options={selectedExperimentDistinctTaskList?.map((task) => {
                    return {
                      id: task?.version,
                      name: (
                        <Space>
                          {`V${task?.version}`}
                          {task?.status !== 1 && task?.status !== 5 && (
                            <WaveLoader size="10" color="#15afd0" />
                          )}
                        </Space>
                      ),
                      disabled: task?.status !== 1 && task?.status !== 5,
                    };
                  })}
                  value={selectedVersion}
                  style={{ width: "100px" }}
                  onChange={versionChangehandler}
                />
              </FilterComponent>
              <FilterComponent title="Variants">
                <Select
                  placeholder="Select item"
                  mode="multiple"
                  width={200}
                  maxTagCount="responsive"
                  onChange={(values) => setSelectedVariants(values)}
                  onSelectAll={(values) => setSelectedVariants(values)}
                  value={selectedVariants}
                  options={variantsOptions}
                />
              </FilterComponent>
            </Space>
            <div>
              <Comps.ExperimentAnalyzeFeatureIcons
                selectedExperimentTask={selectedExperimentTask}
              />
            </div>
          </div>
        </GridItem>
        <GridItem key="response-metric-chart">
          <Comps.ResponseMetricChart
            selectedCohort={selectedCohort}
            selectedVersion={selectedVersion}
            selectedVariants={selectedVariants}
          />
        </GridItem>
        <GridItem key="comparision-table">
          <Comps.ComparisionTable
            selectedCohort={selectedCohort}
            selectedVersion={selectedVersion}
            selectedVariants={selectedVariants}
          />
        </GridItem>
        <GridItem key="correct-biases">
          <Comps.BiasCorrectionRequestModal
            isRequestModalOpen={isRequestModalOpen}
            setIsRequestModalOpen={setIsRequestModalOpen}
            dagCreationTaskAPIResponseRecieved={
              dagCreationTaskAPIResponseRecieved
            }
            dagCreationStatus={dagCreationStatus}
            setDagCreationTaskAPIResponseRecieved={
              setDagCreationTaskAPIResponseRecieved
            }
          />
          <Button
            label="Correct Biases"
            disabled={checkVersionBiasDisable()}
            onClick={() => {
              setIsRequestModalOpen(!isRequestModalOpen);
              createBiasCorrectionTask();
            }}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default withExperiment(MeasureAnalyze);
