import React from "react"
import { Col } from '@pai-ui/core/components/col';
import { Row } from '@pai-ui/core/components/row';
import {ExpressionVariable}  from "./common-components"
import "./../style.css"

const LiftFormula = (props) => {
  var selectedKpi=props?.allValues?.["kpi 1"]|| null
  return (
    <Col span={24} className="kpi-formula-wrapper">
      <div style={{ position: "relative" }}>
        <div className="numerator">
          <Row>
          <ExpressionVariable selected={selectedKpi}  label={selectedKpi??"KPI 1"} variantName={"Test"}/>

          <span className="operator">-</span>
          <ExpressionVariable selected={selectedKpi}  label={selectedKpi??"KPI 1"} variantName={"Control"}/>
         
          </Row>
        </div>
        <div className="denominator">
        <ExpressionVariable selected={selectedKpi}  label={selectedKpi??"KPI 1"} variantName={"Control"}/>
        </div>
      </div>
    </Col>
  );
};

export default LiftFormula ;