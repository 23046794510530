import styled from "styled-components";
import * as React from "react";
// import { NavLink } from "react-router-dom";
import { NavLink as BaseNavLink } from "react-router-dom";

// eslint-disable-next-line react/display-name
const NavLink = React.forwardRef(
  ({ activeClassName, activeStyle, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
          [props.className, isActive ? activeClassName : null]

            .filter(Boolean)

            .join(" ")
        }
        style={({ isActive }) => ({
          ...props.style,

          ...(isActive ? activeStyle : null),
        })}
      />
    );
  }
);

export const LinkedMenuItem = styled(NavLink)`
  padding: 0.55rem 1.188rem;
  padding-left: 1.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) =>
    props.active ? (props.highlighted ? "#15afd0" : "#181b20") : "transparent"};
  transition: all ease 500ms;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none !important;
  border-right: ${(props) => (props.highlighted ? 0 : props.active ? 2 : 0)}px
    solid #15afd0;

  &:hover {
    background: ${(props) => (props.highlighted ? "#15afd0" : "#181b20")};
    border-right: ${(props) => (props.highlighted ? 0 : 2)}px solid #15afd0;

    > p {
      color: #fff;
    }
  }

  > p {
    color: ${(props) => (props.active ? "#FFF" : "rgba(255,255,255,0.5)")};
  }
`;

export const MenuItem = styled.li`
  padding: 0.4rem 1.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) =>
    props.active ? (props.highlighted ? "#15afd0" : "#181b20") : "transparent"};
  transition: background ease 500ms;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background: ${(props) => (props.highlighted ? "#15afd0" : "#181b20")};
    border-right: ${(props) => (props.highlighted ? 0 : 2)}px solid #15afd0;

    > p {
      color: #fff;
    }
  }

  ${(props) => {
    if (props.active) {
      return `
                background: ${(props) =>
                  props.highlighted ? "#15afd0" : "#181b20"};
                border-right: ${(props) =>
                  props.highlighted ? 0 : 2}px solid #15afd0;

                > p{
                    color: #FFF;
                }
            `;
    }
  }}
`;

export const MenuText = styled.p`
  font-family: "SF Text";
  font-weight: 200;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  flex: 1;
  margin: 0;
  white-space: nowrap;
  transition: opacity ease 500ms;
`;

export const MenuIcon = styled.div`
  width: 20px;
  flex-shrink: 0;
  margin-right: 15px;
`;

export const MenuGroup = styled.p`
  color: #15afd0;
  font-family: "SF Text";
  font-size: 14px;
  font-weight: 200;
  padding: 0.025rem 1.188rem;
  margin-top: ${(props) => (props.bottom ? "auto" : "0px")};
  margin-bottom: 0.33rem;
  min-width: 270px;
`;

export const MenuWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 15px;
`;
