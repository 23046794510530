import React, { useState } from "react";
import { UserContext } from "@xai/providers/with-auth";
import withExperiment from "@xai/providers/with-experiment";
import { Grid, GridItem } from "@xai/library/containers/grid";
import ExperimentTopRowGenericComponent from "@xai/assets/product-specific-common-components/experiment-top-row-generic-component/index.js";
import * as Comps from "./components";

const currentScreen = "results";

const MeasureResults = () => {
  const userState = React.useContext(UserContext);
  const [selectedCohort, setSelectedCohort] = useState(null);

  console.log("MeasureResults", userState.experimentModule);

  return (
    <>
      <Grid template={[["top-row"], ["results-tab"]]}>
        <GridItem area="top-row">
          <ExperimentTopRowGenericComponent
            currentScreen={currentScreen}
            selectedCohort={selectedCohort}
            setSelectedCohort={setSelectedCohort}
          />
        </GridItem>
        <GridItem key={"results-tab"}>
          <Comps.MeasureResultsTab
            currentScreen={currentScreen}
            selectedCohort={selectedCohort}
            setSelectedCohort={setSelectedCohort}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default withExperiment(MeasureResults);
