import * as MeasureService from "@xai/services/Measure";

class MeasureServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getAllExperiments() {
    const data = await MeasureService.getAllExperiments(this.authToken);
    if (!data) return null;
    return data;
  }

  async getSelectedExperiment(data) {
    const result = await MeasureService.getSelectedExperiment(data, this.authToken);
    if (!result) return null;
    return result;
  }

  async setFavorite(data) {
    const result = await MeasureService.markFavorite(data, this.athToken);
    // console.log("setFavorite", result);
    if (!result) return null;
    return result;
  }
}

export default MeasureServiceHelper;
