import React from "react";

export const Poster = (
  <div
    style={{
      borderRadius: 10,
      background: "rgba(255,255,255,0.05)",
      border: "1px solid rgba(255,255,255,0.6)",
      width: 120,
      height: 90,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 10,
      marginTop: 12,
      marginBottom: 12,
    }}
  >
    <div
      style={{
        height: 5,
        background: "rgba(255,255,255,0.2)",
        marginBottom: 3,
        width: 40,
      }}
    ></div>
    <div
      style={{
        height: 5,
        background: "rgba(255,255,255,0.2)",
        marginBottom: 3,
        width: 50,
      }}
    ></div>
    <div
      style={{
        height: 5,
        background: "rgba(255,255,255,0.2)",
        width: 30,
      }}
    ></div>
  </div>
);

export const Notification = (
  <div
    style={{
      height: 90,
      marginTop: 12,
      marginBottom: 12,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <div
      style={{
        borderRadius: 10,
        background: "rgba(255,255,255,0.05)",
        border: "1px solid rgba(255,255,255,0.6)",
        width: 120,
        height: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <div
        style={{
          height: 7,
          background: "rgba(255,255,255,0.4)",
          marginBottom: 3,
          width: 100,
        }}
      ></div>
      <div
        style={{
          height: 5,
          background: "rgba(255,255,255,0.2)",
          marginBottom: 3,
          width: 90,
        }}
      ></div>
      <div
        style={{
          height: 5,
          background: "rgba(255,255,255,0.2)",
          width: 75,
        }}
      ></div>
    </div>
  </div>
);

export const Email = (
  <div style={{ margin: "12px 0px" }}>
    <div
      style={{
        height: 6,
        background: "rgba(255,255,255,0.4)",
        marginBottom: 5,
        width: 80,
      }}
    ></div>
    <div
      style={{
        borderRadius: 5,
        background: "rgba(255,255,255,0.05)",
        border: "1px solid rgba(255,255,255,0.6)",
        width: 90,
        height: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        marginBottom: 8,
      }}
    ></div>
    <div
      style={{
        height: 6,
        background: "rgba(255,255,255,0.2)",
        marginBottom: 5,
        width: 40,
      }}
    ></div>
    <div
      style={{
        height: 4,
        background: "rgba(255,255,255,0.2)",
        width: 80,
        marginBottom: 3,
      }}
    ></div>
    <div
      style={{
        height: 4,
        background: "rgba(255,255,255,0.2)",
        width: 80,
        marginBottom: 3,
      }}
    ></div>
    <div
      style={{
        height: 4,
        background: "rgba(255,255,255,0.2)",
        width: 80,
      }}
    ></div>
  </div>
);
