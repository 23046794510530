import React from "react";
import LiftFormula from "./components/lift";
import IncrementalFormula from "./components/incremental";
import IncrementalFormula2 from "./components/incremental2";

const PredefinedFormulaViewer = (props) => {

  switch (props.metric) {
    case 2:
      return <LiftFormula {...props}/>;
      case 37:
      return <IncrementalFormula {...props} />;
      case 92:
      return <IncrementalFormula2 {...props} />;
  }
  return <></>
};

export default PredefinedFormulaViewer;
