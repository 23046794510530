export const getVariantBankListConfig = (id) => {
  /**
   * Tables : experiment_variant_bank
   * Props : experiment_id,
   */

  let input_json = {
    experiment_id: id,
  };

  return input_json;
};
