import * as DesignService from "@xai/services/experiment/Design";

class DesignServiceHelper {
  constructor(token) {
    this.athToken = token;
  }

  async getExperimentNames(data) {
    const result = await DesignService.getExperimentNames(data);
    if (!result) return null;
    return result;
  }

  async addExperiment(data) {
    const result = await DesignService.addExperiment(data);
    if (!result) return null;
    return result;
  }

  async updateExperiment(data) {
    const result = await DesignService.updateExperiment(data);
    if (!result) return null;
    return result;
  }

  async deleteExperiment(data) {
    const result = await DesignService.deleteExperiment(data);
    if (!result) return null;
    return result;
  }
}

export default DesignServiceHelper;
