export const getRadarVizChartConfig = (
  start_time,
  end_time,
) => {
  /**
   * Tables : 
   * Props : start_time_kpi , end_time_kpi
   */

  let input_json = {
    start_time_kpi: start_time,
    end_time_kpi: end_time,
  };

  return input_json;
};

export const getExecutiveAtAGlanceKPIMetricsConfig = (
  start_time,
  end_time,
) => {
  /**
   * Tables : 
   * Props : start_time_kpi , end_time_kpi
   */

  let input_json = {
    start_time_kpi: start_time,
    end_time_kpi: end_time,
  };

  return input_json;
};

export const getExecutiveAtAGlanceExperimentListTableConfig = (
  start_time,
  end_time,
) => {
  /**
   * Tables : 
   * Props : start_time_kpi , end_time_kpi
   */

  let input_json = {
    start_time_kpi: start_time,
    end_time_kpi: end_time,
  };

  return input_json;
};

export const getExecutiveAtAGlanceExperimentTwoByTwoChartConfig = (
  start_time,
  end_time,
) => {
  /**
   * Tables : 
   * Props : start_time_kpi , end_time_kpi
   */

  let input_json = {
    start_time_kpi: start_time,
    end_time_kpi: end_time,
  };

  return input_json;
};