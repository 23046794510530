import * as DesignService from "@xai/services/experiment/Design";
import * as DesignMaxAIService from "@xai/services/experiment/Design-MaxAI";

class DesignExperimentServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getAllDesignExperiments() {
    const data = await DesignService.getAllDesignExperiments(
      this.authToken
    );
    if (!data) return null;
    return data;
  }

  async getDesignExperimentTagLines(data, generateOpenAIResponse) {
    const result = await DesignMaxAIService.getDesignExperimentTagLines(
      data,
      generateOpenAIResponse
    );
    if (!result) return null;
    return result;
  }

  async getSpecificSubjectLineConfig() {
    const data = await DesignMaxAIService.getSpecificSubjectLineConfig(
      this.authToken
    );
    if (!data) return null;
    return data;
  }

}

export default DesignExperimentServiceHelper;
