import * as React from 'react';
import { Modal } from '@pai-ui/core/components/modal';
import { Tabs } from '@pai-ui/core/components/tabs';
import { ReportsConfigured } from './components/reports-configured';
import { ScreensConfigured } from './components/screens-configured';

import './style.css';

export const ConfigurableUiManagement = (props) => {
  const { getReports, deleteReport, updateReport, getScreens, deleteScreen, updateScreen, open, close } = props;
  const TAB_TYPE = {
    screens: 'screens',
    reports: 'reports',
  };
  const [activeTab, setActiveTab] = React.useState(TAB_TYPE.screens);

  const tabUiConfig = {
    [TAB_TYPE.reports]: (
      <ReportsConfigured 
        getReports={getReports}
        deleteReport={deleteReport}
        updateReport={updateReport}
      />
    ),
    [TAB_TYPE.screens]: (
      <ScreensConfigured 
        getScreens={getScreens}
        deleteScreen={deleteScreen}
        updateScreen={updateScreen}
      />
    ),
  };

  return (
    <Modal
      visible={open}
      onCancel={close}
      className="ConfigurableUiManagement"
    >
      <Tabs
        items={[
          {
            tab: "Screens Configured",
            id: TAB_TYPE.screens
          },
          {
            tab: "Reports Configured",
            id: TAB_TYPE.reports
          },
        ]}
        activeKey={activeTab}
        onChange={setActiveTab}
      />
      {tabUiConfig[activeTab]}
    </Modal>
  );
};