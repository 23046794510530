import * as React from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

const useBlocker = (blocker, when = true) => {
    const navigator = React.useContext(UNSAFE_NavigationContext)?.navigator;

    React.useEffect(() => {
        if (!when) return;

        const push = navigator.push;

        navigator.push = (...args) => {
            const result = blocker();
            if (result !== false) {
                push(...args)
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, blocker, when]);
}

export default useBlocker;