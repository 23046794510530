import React from "react";
import { Row, Col } from 'antd';
import { Card } from '@pai-ui/core/components/card';
import { StarFill } from '@styled-icons/bootstrap/StarFill';
import { Star } from '@styled-icons/bootstrap/Star';
import { target_subject_label, response_metric_label } from './transformer.js';
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";


const ExperimentCard = ({ cardData/* , setTemplateSelected, handleTemplateSelection */ }) => {

    const template_data = cardData?.template_data_table?.template_data;

    const [isRequestModalOpen, setIsRequestModalOpen] = React.useState(false);

    const cardSelectionHandler = (cardData) => {
      console.log("cardSelectionHandler", cardData);
      // setTemplateSelected(cardData);
      // handleTemplateSelection(cardData?.id);
      setIsRequestModalOpen(true);
      /** */
    //   if (cardData.template_identifier === "genAiExperiment") {
    //     setTemplateSelected(cardData);
    //     handleTemplateSelection(cardData?.id);
    //   } else {
    //     setIsRequestModalOpen(true);
    //   }
    };

    return (
        <>
            {
                isRequestModalOpen &&
                <GenericModalWithResultComponent
                    isRequestModalOpen={isRequestModalOpen}
                    setIsRequestModalOpen={setIsRequestModalOpen}
                />
            }
            <div onClick={() => { cardSelectionHandler(cardData) }} style={{ cursor: "pointer" }}>
                <Card>
                    <Row align="space-between">
                        <Col>
                            <div style={{
                                backgroundColor: "rgba(21, 175, 208, 0.1)",
                                padding: "10px",
                                borderRadius: "50%"
                            }}>
                                <img
                                    src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/${cardData.icon}.png`}
                                    alt=""
                                    style={{ height: "5vh", margin: "auto", color: "#15afd0" }}
                                />
                            </div>
                        </Col>
                        <Col>
                            {
                                template_data?.experiment_name?.length > 0 ?
                                    <StarFill style={{ opacity: "0.4" }} height={15} />
                                    :
                                    <Star style={{ opacity: "0.4" }} height={15} />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginTop: "20px"
                            }}>
                                {cardData?.template_name}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{
                                opacity: "0.7",
                                height: "10vh"
                            }}>
                                {cardData?.description}
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "center", marginBottom: "10px" }}>
                        <Col>
                            <img
                                src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/group.png`}
                                width={30}
                                alt=""
                                style={{ height: "4vh", opacity: "0.7", color: "#15afd0" }}
                            />
                        </Col>
                        <Col offset={1}>
                            <p style={{
                                margin: "0px",
                                opacity: "0.7"
                            }}>
                                {target_subject_label}: {cardData?.target_subject}
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "center" }}>
                        <Col>
                            <img
                                src={`${process.env.REACT_APP_CLOUDFRONT_CDN}/images/xai-design-experiment/target-blue.png`}
                                alt=""
                                style={{ height: "4vh", opacity: "0.7", color: "#15afd0" }}
                            />
                        </Col>
                        <Col offset={1}>
                            <p style={{
                                margin: "0px",
                                opacity: "0.7"
                            }}>
                                {response_metric_label}: {template_data?.response_metric}
                            </p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
};

export default ExperimentCard;