/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Switch,
  Radio,
  Avatar,
  Divider,
  Badge,
  Skeleton,
  Tag,
  Button,
  Row,
  Col,
  Space,
  Tooltip,
  Steps,
  message,
  Popover,
  Dropdown,
  Menu,
  Pagination,
  Collapse,
  List,
  Popconfirm,
  Drawer,
  Progress,
  Checkbox,
  Typography,
  Cascader,
  DatePicker,
  Modal,
  InputNumber,
  Tabs,
  Slider,
  Empty,
} from "antd";
import { SortDescending } from "@styled-icons/heroicons-outline/SortDescending";
import {
  ExclamationOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditFilled,
  FilterFilled,
  SignalFilled,
  UserAddOutlined,
  CopyFilled,
  DeleteFilled,
  PieChartOutlined,
  CheckCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  StarFilled,
  StarOutlined,
  UserOutlined,
  BranchesOutlined,
  LineChartOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  CloseOutlined,
  CaretUpFilled,
  CaretDownFilled,
  PieChartFilled,
  EditOutlined,
  CopyOutlined,
  PlayCircleFilled,
  FundViewOutlined,
  VerticalAlignTopOutlined,
  PlayCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
  BackwardOutlined,
  CaretUpOutlined,
  ShoppingCartOutlined,
  EllipsisOutlined,
  SearchOutlined,
  CheckOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  CoffeeOutlined,
  ContactsOutlined,
  ContainerOutlined,
  MacCommandOutlined,
  RestOutlined,
  RocketOutlined,
  ShopOutlined,
  TagOutlined,
  WifiOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Photograph } from "@styled-icons/heroicons-solid/Photograph";
import "antd/dist/antd.css";
import { Discount } from "@styled-icons/boxicons-solid";
import { LabFlask } from "@styled-icons/entypo";
import { Ticket } from "@styled-icons/entypo/Ticket";
import { AirplaneTakeOff } from "@styled-icons/fluentui-system-regular/AirplaneTakeOff";
import { LaptopWindows } from "@styled-icons/material-sharp/LaptopWindows";
import { LocalDrink } from "@styled-icons/material-twotone/LocalDrink";
import { Users } from "@styled-icons/entypo/Users";
import { FileText } from "@styled-icons/feather/FileText";
import { Flight } from "@styled-icons/material/Flight";
import { Luggage } from "@styled-icons/fluentui-system-regular/Luggage";
import { AirlineSeatReclineExtra } from "@styled-icons/material/AirlineSeatReclineExtra";
import { New } from "@styled-icons/entypo/New";
import { Export } from "@styled-icons/typicons/Export";
import { Save as SaveIcon } from "@styled-icons/boxicons-regular/Save";
import { Cross as CrossIcon } from "@styled-icons/entypo/Cross";
import { notification } from '@pai-ui/core/components/notification';

const { Search } = Input;
const { Panel } = Collapse;
const Accordion = Panel;
const { Text } = Typography;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const { CheckableTag } = Tag;
export const ZsModal = (props) => {
  return <Modal {...props} />;
};

const showToasterMessage = (type, text) => {
  var placement = "topRight";
  notification[type]({
    message: text,
    placement,
  });
};
// function hasDuplicates(array) {
//   return (new Set(array)).size !== array.length;
// }
//ant-d components

export const ZsSteps = ({
  steps,
  onFinish,
  onNext,
  onPrev,
  vertical,
  finishText,
  onChange,
  validationArray,
  setValidationArray,
  isSubmitDisabled,
  enabledSteps,
  finishMessage
}) => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    if (steps[current].form) {
      steps[current].form
        .validateFields()
        .then((values) => {
          // steps[current].form.submit();

          setValidationArray([
            ...validationArray.filter((a) => a.stepId != current),
            { stepId: current, isValid: true },
          ]);
          onNext && onNext(values); //(Object.assign(hypothesis, values));
          setCurrent(current + 1);
        })
        .catch((errorInfo) => {
          setValidationArray([
            ...validationArray.filter((a) => a.stepId != current),
            { stepId: current, isValid: false },
          ]);
          console.log("Error Data", errorInfo);
          setCurrent(current + 1);
        });
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (steps[current].form) {
      steps[current].form
        .validateFields()
        .then((values) => {
          // steps[current].form.submit();

          setValidationArray([
            ...validationArray.filter((a) => a.stepId != current),
            { stepId: current, isValid: true },
          ]);
          onPrev && onPrev(values); //(Object.assign(hypothesis, values));
          setCurrent(current - 1);
        })
        .catch((errorInfo) => {
          setValidationArray([
            ...validationArray.filter((a) => a.stepId != current),
            { stepId: current, isValid: false },
          ]);
          console.log("Error Data", errorInfo);
          setCurrent(current - 1);
        });
    } else {
      setCurrent(current - 1);
    }
  };

  const GoBackFromSummary = (index2MoveBackward) => {
    setCurrent(index2MoveBackward);
  };

  const { Step } = Steps;
  console.log('steps', enabledSteps);
  return (
    <React.Fragment>
      <Row>
        <Col span={vertical ? 3 : 24}>
          <div style={{ marginTop: vertical ? 0 : 0 }}>
            <Steps
              current={current}
              direction={vertical ? "vertical" : "horizontal"}
              onChange={(xx) => {
                setCurrent(xx);
              }}
            >
              {steps.map((item, i) => (
                <Step
                  className={`${validationArray.filter((a) => a.stepId == i)[0]?.isValid ==
                    false && "invalid-step-custom-wrapper"
                    } ${enabledSteps[i]?.isActive
                      ? "traversed-step"
                      : "untraversed-step"
                    }`}
                  status={
                    current == i
                      ? "process"
                      : validationArray.filter((a) => a.stepId == i)[0]
                        ?.isValid == false && "error"
                  }
                  disabled={!enabledSteps[i]?.isActive}
                  key={item.title}
                  title={item.title}
                  icon={
                    (validationArray.filter((a) => a.stepId == i)[0]?.isValid ==
                      false &&
                      i != current && (
                        <div
                          style={{
                            border: "1px solid #faad14",
                            borderRadius: "50%",
                            height: "32px",
                            width: "32px",
                          }}
                        >
                          <ExclamationOutlined
                            style={{
                              color: "#faad14",
                              fontSize: "18px",
                              transform: "translateY(-2px)",
                            }}
                          />
                        </div>
                      )) ||
                    (enabledSteps[i]?.isActive && (
                      <div
                        style={{
                          border: "1px solid rgb(21, 175, 208)",
                          borderRadius: "50%",
                          height: "32px",
                          width: "32px",
                        }}
                      >
                        <CheckOutlined
                          style={{
                            color: "rgb(21, 175, 208)",
                            fontSize: "18px",
                            transform: "translateY(-2px)",
                          }}
                        />
                      </div>
                    ))
                  }
                />
              ))}
            </Steps>
          </div>
        </Col>
        <Col span={vertical ? 21 : 24}>
          <div
            className="steps-content"
            style={{
              marginTop: vertical ? 0 : "1%",

              minHeight: vertical ? "620px" : "620px",
              overflowY: "auto",
            }}
          >
            {steps[current].content(GoBackFromSummary)}
          </div>
          <div
            className="steps-action"
            style={{ textAlign: vertical ? "right" : "center" }}
          >
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && current != 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && current != 1 && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  message.success(finishMessage);
                  onFinish && onFinish();
                }}
                disabled={isSubmitDisabled}
              >
                {steps.length === 1 ? (
                  <span>Submit</span>
                ) : (
                  <span>{finishText || "Done"}</span>
                )}
              </Button>
            )}
            {current == 1 && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  message.success("Dags Calculation sucessfully triggered");
                  next();
                }}

              >

                <span>Calculate</span>

              </Button>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};


export const ZsPhotograph = (props) => {
  return <Photograph {...props} />;
};

export const ZsUseForm = (props) => {
  return Form.useForm()[0];
};

export const ZsTag = (props) => {
  return <Tag {...props}> {props.value ? props.value : props.children}</Tag>;
};

export const ZsEllipsis = (props) => {
  return <Paragraph ellipsis>{props.children}</Paragraph>;
};

export const ZsAvatar = (props) => {
  return (
    <Avatar {...props} onClick={props.onClick}>
      {props.children}
    </Avatar>
  );
};

export const ZsSkeleton = (props) => {
  return <Skeleton {...props}>{props.children}</Skeleton>;
};

export const ZsButton = (props) => {
  return (
    <Button style={props.style} {...props}>
      {props.value ? props.value : props.children}
    </Button>
  );
};

export const ZsRow = ({ className, justify, gutter, children, style, disabled }) => {
  return (
    <Row
      style={style}
      justify={justify ? justify : "start"}
      gutter={gutter ? gutter : 0}
      disabled={disabled}
      className={className}>
      {children}
    </Row>
  );
};
export const ZsCheckableTag = (props) => {
  return <CheckableTag {...props}>{props.children}</CheckableTag>;
};
export const ZsRangePicker = (props) => {
  const { RangePicker } = DatePicker;
  return <RangePicker picker={props.picker || "month"} {...props} />;
};

export const ZsCol = (props) => {
  return <Col {...props}>{props.children}</Col>;
};

export const ZsSpace = (props) => {
  return <Space {...props}> {props.value ? props.value : props.children}</Space>;
};

export const ZsSpaceC = (props) => {
  return <Space {...props}>{props.children}</Space>;
};
export const ZsSlider = (props) => {
  return <Slider {...props}>{props.children}</Slider>;
};

export const ZsEmpty = (props) => {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props}>
      {props.children}
    </Empty>
  );
};

export const ZsTooltip = (props) => {
  return (
    <Tooltip title={props.title} {...props}>
      {props.children}
    </Tooltip>
  );
};

export const ZsTextArea = (props) => {
  return <Input.TextArea {...props} />;
};

export const ZsInput = (props) => {
  return <Input {...props} />;
};

export const ZsInputNumber = (props) => {
  return <InputNumber {...props} />;
};

export const ZsInputPassword = (props) => {
  return <Input.Password {...props} />;
};

export const ZsSelect = (props) => {
  if (props.fullDdn) {
    return (
      <div style={{ color: "hsla(0,0%,100%,.65)" }}>
        {/* {console.log("select 1", props)} */}

        <Select {...props}>
          {props.values
            ? props.values.map((val) => (
              <Select.Option style={{ color: "hsla(0,0%,100%,.65)" }} {...val} key={val.id} value={val.id}>
                {val.name}
              </Select.Option>
            ))
            : props.children}
        </Select>
      </div>
    );
  } else {
    return (
      <div>
        {/* {console.log("select2", props)} */}

        <Select {...props} getPopupContainer={(triggerNode) => triggerNode.parentElement}>
          {props.values
            ? props.values.map((val) => (
              <Select.Option key={val.id} value={val.id}>
                {val.name}
              </Select.Option>
            ))
            : props.children}
        </Select>
      </div>
    );
  }
};

export const ZsDatePicker = (props) => {
  return <DatePicker {...props} />;
};

export const ZsBadge = (props) => {
  return <Badge {...props}>{props.children}</Badge>;
};
export const ZsSelect1 = (props) => {
  return (
    <Select {...props} style={{ color: "hsla(0,0%,100%,.65)" }}>
      {props.values &&
        props.values.map((val) => (
          <Select.Option
            style={{ color: "hsla(0,0%,100%,.65)" }}
            key={val.id}
            disabled={val.disabled || false}
            value={val.id}>
            {val.label ? val.label : val.name}
          </Select.Option>
        ))}
    </Select>
  );
};
export const ZsMultiSelect = (props) => {
  return (
    <Select mode="multiple" {...props}>
      {!props.isEmpty &&
        props.values.map((val) => (
          // TODO
          <ZsOption key={val.id} value={val.id} {...val}>
            {val.label ? val.label : val.name}
          </ZsOption>
        ))}
    </Select>
  );
};

export const ZsRadioButton = (props) => {
  return <Radio.Group {...props} />;
};

export const ZsOption = (props) => {
  return <Select.Option {...props}>{props.children}</Select.Option>;
};

export const ZsSwitch = (props) => {
  return <Switch {...props} checked={props.value} />;
};

export const ZsPopover = (props) => {
  return <Popover {...props}>{props.children}</Popover>;
};
export const ZsCheckOutlined = (props) => {
  return <CheckOutlined {...props} />;
};
export const ZsDivider = (props) => {
  return <Divider {...props}>{props.value}</Divider>;
};

export const ZsSearch = ({ placeholder, onChange, style, className }) => {
  return (
    <Search
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value.trim())}
      style={style}
      className={className}
    />
  );
};

export const ZsCheckbox = (props) => {
  return <Checkbox {...props}>{props.children}</Checkbox>;
};

export const ZsDropdown = (props) => {
  return <Dropdown {...props}>{props.children}</Dropdown>;
};

export const ZsMenu = (props) => {
  const { values } = props;

  return (
    <Menu {...props}>
      {Object.keys(values).map((x) => (
        <Menu.Item key={x}>{values[x].label}</Menu.Item>
      ))}
    </Menu>
  );
};

export const ZsPagination = (props) => {
  return <Pagination {...props} />;
};

export const ZsText = (props) => {
  return <Text {...props}>{props.children}</Text>;
};

export const ZsCollapse = (props) => {
  return <Collapse {...props}>{props.children}</Collapse>;
};

export const ZsAccordion = (props) => {
  return <Accordion {...props}>{props.children}</Accordion>;
};

export const ZsListItem = ({ listItem, listItemMeta }) => {
  // console.log(listItem, listItemMeta);
  return (
    <List.Item key={listItem.key} actions={listItem.actions} style={listItem.style}>
      <List.Item.Meta
        avatar={listItemMeta.avatar}
        description={listItemMeta.description}
        title={listItemMeta.title}
        style={listItemMeta.style}
      />
    </List.Item>
  );
};
export const ZsSuccessMessage = (msg) => {
  return message.success(msg);
};
export const ZsErrorMessage = (msg) => {
  return message.error(msg);
};

export const ZsInfoMessage = (msg) => {
  return message.info(msg);
};

export const ZsList = (props) => {
  return <List {...props} />;
};

export const ZsCascader = (props) => {
  return <Cascader {...props} />;
};

export const ZsPopconfirm = (props) => {
  return <Popconfirm {...props}>{props.children}</Popconfirm>;
};

export const ZsProgress = (props) => {
  return <Progress {...props} />;
};

export const ZsDrawer = (props) => {
  return <Drawer {...props}>{props.children}</Drawer>;
};
export const ZsTabs = (props) => {
  return <Tabs {...props}>{props.children}</Tabs>;
};
export const ZsTabPane = (props) => {
  return <TabPane {...props}>{props.children}</TabPane>;
};
export const ZsListItemMeta = (props) => {
  return <List.Item.Meta {...props} />;
};

export const ZsListItemModified = (props) => {
  return <List.Item {...props}>{props.children}</List.Item>;
};

//icons
export const ZsInfoCircleOutlined = (props) => {
  return <InfoCircleOutlined {...props} />;
};

export const ZsLoadingOutlined = () => {
  return <LoadingOutlined />;
};

export const ZsDeleteOutlined = (props) => {
  return <DeleteOutlined {...props} />;
};

export const ZsPlusOutlined = (props) => {
  return <PlusOutlined {...props} />;
};

export const ZsEditFilled = (props) => {
  return <EditFilled {...props} />;
};

export const ZsFilterFilled = (props) => {
  return <FilterFilled {...props} />;
};

export const ZsSignalFilled = (props) => {
  return <SignalFilled {...props} />;
};

export const ZsUserAddOutlined = (props) => {
  return <UserAddOutlined {...props} />;
};

export const ZsCopyFilled = (props) => {
  return <CopyFilled {...props} />;
};

export const ZsDeleteFilled = (props) => {
  return <DeleteFilled {...props} />;
};

export const ZsPieChartOutlined = (props) => {
  return <PieChartOutlined {...props} />;
};

export const ZsCheckCircleOutlined = (props) => {
  return <CheckCircleOutlined {...props} />;
};

export const ZsRightCircleOutlined = (props) => {
  return <RightCircleOutlined {...props} />;
};
export const ZsLeftCircleOutlined = (props) => {
  return <LeftCircleOutlined {...props} />;
};

export const ZsCloseCircleFilled = (props) => {
  return <CloseCircleFilled {...props} />;
};

export const ZsCheckCircleFilled = (props) => {
  return <CheckCircleFilled {...props} />;
};

export const ZsStarFilled = (props) => {
  return <StarFilled {...props} />;
};

export const ZsStarOutlined = (props) => {
  return <StarOutlined {...props} />;
};

export const ZsUserOutlined = (props) => {
  return <UserOutlined {...props} />;
};

export const ZsBranchesOutlined = (props) => {
  return <BranchesOutlined {...props} />;
};

export const ZsLineChartOutlined = (props) => {
  return <LineChartOutlined {...props} />;
};

export const ZsExclamationCircleOutlined = (props) => {
  return <ExclamationCircleOutlined {...props} />;
};

export const ZsExclamationCircleFilled = (props) => {
  return <ExclamationCircleFilled {...props} />;
};

export const ZsCloseOutlined = (props) => {
  return <CloseOutlined {...props} />;
};

export const ZsCaretUpFilled = (props) => {
  return <CaretUpFilled {...props} />;
};
export const ZsCaretUpOutlined = (props) => {
  return <CaretUpOutlined {...props} />;
};

export const ZsCaretDownFilled = (props) => {
  return <CaretDownFilled {...props} />;
};

export const ZsPieChartFilled = (props) => {
  return <PieChartFilled {...props} />;
};

export const ZsEditOutlined = (props) => {
  return <EditOutlined {...props} />;
};

export const ZsCopyOutlined = (props) => {
  return <CopyOutlined {...props} />;
};

export const ZsPlayCircleFilled = (props) => {
  return <PlayCircleFilled {...props} />;
};

export const ZsFundViewOutlined = (props) => {
  return <FundViewOutlined {...props} />;
};

export const ZsPublishOutlined = (props) => {
  return <VerticalAlignTopOutlined {...props} />;
};

export const ZsPlayCircleOutlined = (props) => {
  return <PlayCircleOutlined {...props} />;
};

export const ZsSyncOutlined = (props) => {
  return <SyncOutlined {...props} />;
};

export const ZsSortDescending = (props) => {
  return <SortDescending {...props} />;
};
export const ZsCloseCircleOutlined = (props) => {
  return <CloseCircleOutlined {...props} />;
};

export const ZsRollbackOutlined = (props) => {
  return <RollbackOutlined {...props} />;
};

export const ZsBackwardOutlined = (props) => {
  return <BackwardOutlined {...props} />;
};

export const ZsEllipsisOutlined = (props) => {
  return <EllipsisOutlined {...props} />;
};

// export const ZsCaretUpOutlined = (props) => {
//   return <CaretUpOutlined {...props} />;
// };

export const ZsLabFlask = (props) => {
  return <LabFlask {...props} />;
};

export const ZsShoppingCartOutlined = (props) => {
  return <ShoppingCartOutlined {...props} />;
};

export const ZsDiscount = (props) => {
  return <Discount {...props} />;
};
export const ZsSearchOutlined = (props) => {
  return <SearchOutlined {...props} />;
};

export const ZsTicket = (props) => {
  return <Ticket {...props} />;
};

export const ZsApartmentOutlined = (props) => {
  return <ApartmentOutlined {...props} />;
};

export const ZsCalendarOutlined = (props) => {
  return <CalendarOutlined {...props} />;
};

export const ZsCoffeeOutlined = (props) => {
  return <CoffeeOutlined {...props} />;
};

export const ZsContactsOutlined = (props) => {
  return <ContactsOutlined {...props} />;
};

export const ZsContainerOutlined = (props) => {
  return <ContainerOutlined {...props} />;
};

export const ZsMacCommandOutlined = (props) => {
  return <MacCommandOutlined {...props} />;
};

export const ZsRestOutlined = (props) => {
  return <RestOutlined {...props} />;
};

export const ZsRocketOutlined = (props) => {
  return <RocketOutlined {...props} />;
};

export const ZsShopOutlined = (props) => {
  return <ShopOutlined {...props} />;
};

export const ZsTagOutlined = (props) => {
  return <TagOutlined {...props} />;
};

export const ZsWifiOutlined = (props) => {
  return <WifiOutlined {...props} />;
};

export const ZsAirplaneTakeOff = (props) => {
  return <AirplaneTakeOff {...props} />;
};

export const ZsLaptopWindows = (props) => {
  return <LaptopWindows {...props} />;
};

export const ZsLocalDrink = (props) => {
  return <LocalDrink {...props} />;
};

export const ZsUsers = (props) => {
  return <Users {...props} />;
};

export const ZsDownOutlined = (props) => {
  return <DownOutlined {...props} />;
};

export const ZsFileText = (props) => {
  return <FileText {...props} />;
};

export const ZsFlight = (props) => {
  return <Flight {...props} />;
};

export const ZsLuggage = (props) => {
  return <Luggage {...props} />;
};

export const ZsAirlineSeatReclineExtra = (props) => {
  return <AirlineSeatReclineExtra {...props} />;
};

export const ZsEntypoNew = (props) => {
  return <New {...props} />;
};
export const ZsExport = (props) => {
  return <Export {...props} />;
};

export const ZsSaveIcon = (props) => {
  return <SaveIcon {...props} />;
};

export const ZsCrossIcon = (props) => {
  return <CrossIcon {...props} />;
};

export const ZsEyeOutlined = (props) => {
  return <EyeOutlined {...props} />;
};
