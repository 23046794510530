import React, { Component } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-style'

Chart.defaults.scale.ticks.fontColor = "rgba(255,255,255,0.75)";
Chart.defaults.global.defaultFontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
Chart.defaults.global.legend.labels.fontColor = "rgba(255,255,255,0.75)";

Chart.plugins.register({
    beforeRender: function (chart) {
        if (chart.config.options.showAllTooltips) {
            // create an array of tooltips
            // we can't use the chart tooltip because there is only one tooltip per chart
            chart.pluginTooltips = [];
            chart.config.data.datasets.forEach(function (dataset, i) {
                chart.getDatasetMeta(i).data.forEach(function (sector) {
                    chart.pluginTooltips.push(new Chart.Tooltip({
                        _chart: chart.chart,
                        _chartInstance: chart,
                        _data: chart.data,
                        _options: chart.options.tooltips,
                        _active: [sector]
                    }, chart));
                });
            });

            // turn off normal tooltips
            chart.options.tooltips.enabled = false;
        }
    },
    afterDraw: function (chart, easing) {
        if (chart.config.options.showAllTooltips) {
            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
            if (!chart.allTooltipsOnce) {
                if (easing !== 1)
                    return;
                chart.allTooltipsOnce = true;
            }

            // turn on tooltips
            chart.options.tooltips.enabled = true;
            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
                tooltip.initialize();
                tooltip.update();
                // we don't actually need this since we are not animating tooltips
                tooltip.pivot();
                tooltip.transition(easing).draw();
            });
            chart.options.tooltips.enabled = false;
        }
    }
})

export const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#15c4ec", "#FFFFFF", "#e8f3f6"]

export const SmallScatterPlot = ({ data, scaleX, scaleY, color }) => (
    <BasicChart
        type="scatter"
        data={{
            datasets: [
                {
                    backgroundColor: color || colors[0] + "CC",
                    borderColor: color || colors[0] + "CC",
                    data
                }
            ]
        }}
        options={{
            elements: {
                point: {
                    radius: 1
                }
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: false,
                    ticks: {
                        fontColor: "transparent",
                        min: scaleX ? scaleX.min : -0.15,
                        max: scaleX ? scaleX.max : 0.15
                    },
                    gridLines: {
                        display: false,
                        lineWidth: 1
                    }
                }],
                yAxes: [{
                    display: false,
                    ticks: {
                        fontColor: "transparent",
                        min: scaleY ? scaleY.min : 0,
                        max: scaleY ? scaleY.max : 50
                    },
                    gridLines: {
                        display: false,
                        lineWidth: 0
                    }
                }]
            },
            tooltips: {
                enabled: false
            }
        }} />
)

export const ScatterPlot = ({ data, scaleX, scaleY }) => (
    <div style={{ marginLeft: 15, marginTop: 15, flexGrow: 1, display: "flex", position: "relative", flexDirection: "column", border: `1px solid rgba(255,255,255,0.2)` }}>
        <div style={{ position: "absolute", fontSize: '14px', left: -25, top: -1, bottom: -1, width: 15, lineHeight: '15px', writingMode: "vertical-lr", textAlign: "center", transform: "rotateZ(180deg)" }}>
            {scaleY && scaleY.title ? scaleY.title : "Absolute Revenue ($)"}
        </div>
        <div style={{ position: "absolute", fontSize: '14px', left: -1, top: -25, right: -1, height: 15, lineHeight: '15px', textAlign: "center" }}>
            {scaleX && scaleX.title ? scaleX.title : "YoY Growth (%)"}
        </div>
        <BasicChart
            border={true}
            type="scatter"
            data={{
                datasets: [
                    {
                        backgroundColor: colors[0] + "CC",
                        borderColor: colors[0] + "CC",
                        data
                    }
                ]
            }}
            options={{
                elements: {
                    point: {
                        radius: 3
                    }
                },
                showAllTooltips: true,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        display: false,
                        ticks: {
                            fontColor: "transparent",
                            min: scaleX ? scaleX.min : -0.15,
                            max: scaleX ? scaleX.max : 0.15
                        },
                        gridLines: {
                            display: false,
                            lineWidth: 1
                        }
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            fontColor: "transparent",
                            min: scaleY ? scaleY.min : 0,
                            max: scaleY ? scaleY.max : 50
                        },
                        gridLines: {
                            display: false,
                            lineWidth: 0
                        }
                    }]
                },
                tooltips: {
                    yAlign: 'center',
                    xAlign: 'left',
                    bodyFontSize: 10,
                    bodyFontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                    backgroundColor: 'rgba(0,0,0,0)',
                    bodyFontColor: "#939497",
                    bodyAlign: "left",
                    position: "average",
                    displayColors: false,
                    tooltipCaretSize: 0,
                    bodySpacing: 0,
                    yPadding: 0,
                    xPadding: 0,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].label || '';
                            return label;
                        }
                    }
                }
            }} />
        <div style={{ display: "flex", flexDirection: "column", position: "absolute", borderLeft: `1px solid rgba(255,255,255,0.2)`, top: 0, left: '50%', right: '-50%', bottom: 0, pointerEvents: 'none' }}>
            <BasicChart
                type="scatter"
                data={{
                    datasets: [
                        {
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            data
                        }
                    ]
                }}
                options={{
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: function (value) {
                                    return '$' + (scaleY ? parseInt(value / 1000000) + "M" : value);
                                },
                                fontSize: 10,
                                min: scaleY ? scaleY.min : 0,
                                max: scaleY ? scaleY.max : 50,
                                fontColor: "rgba(255,255,255,0.25)",
                            },
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            display: false,
                            ticks: {
                                min: scaleX ? scaleX.min : -0.15,
                                max: scaleX ? scaleX.max : 0.15
                            }
                        }]
                    },
                    legend: {
                        display: false
                    }
                }} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", position: "absolute", borderTop: `1px solid rgba(255,255,255,0.2)`, top: '50%', left: 0, right: 0, height: "50%", pointerEvents: 'none' }}>
            <BasicChart
                type="scatter"
                data={{
                    datasets: [
                        {
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            data
                        }
                    ]
                }}
                options={{
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: scaleY ? scaleY.min : 0,
                                max: scaleY ? scaleY.max : 50
                            },
                            display: false,
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            position: "top",
                            ticks: {
                                callback: function (value) {
                                    return scaleX ? (value + "%") : (value * 100 + "%");
                                },
                                fontSize: 10,
                                fontColor: "rgba(255,255,255,0.25)",
                                min: scaleX ? scaleX.min : -0.15,
                                max: scaleX ? scaleX.max : 0.15
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: false
                    }
                }} />
        </div>
    </div>
)

const generateBandColors = (band, data) => {
    return data.map(d => {
        return d < band[0] ? "#941100" : d > band[1] ? colors[0] + "CC" : "grey"
    })
}

export const ColumnChart = ({ hideTooltips, style, labels, data, border, legend, hideY, small, scaleY, scaleX, bands, dataLabels, labelFormat, hideX }) => (
    <BasicChart
        style={style}
        type="bar"
        data={{
            labels,
            datasets: data.map((d, i) => ({ label: d.label, data: d.data, borderWidth: border ? 1 : 0, backgroundColor: bands ? generateBandColors(bands, d.data) : d.highlightMax ? d.data.map(v => v === Math.max(...d.data) ? colors[0] + "CC" : colors[2] + "CC") : (d.backgroundColor || `${colors[i]}CC`) }))
        }}
        options={{
            legend: {
                display: legend
            },
            tooltips: {
                enabled: !hideTooltips
            },
            scales: {
                yAxes: [{
                    display: true,
                    position: scaleY && scaleY.position ? scaleY.position : "left",
                    ticks: {
                        min: scaleY && scaleY.min ? scaleY.min : null,
                        max: scaleY && scaleY.max ? scaleY.max : null,
                        display: !hideY,
                        maxTicksLimit: scaleY && scaleY.maxTicksLimit ? scaleY.maxTicksLimit : 11,
                        callback: function (value) {
                            var million = labelFormat === "M" ? parseInt(value / 1000000) + "MM" : dataLabels == "$" ? value.toLocaleString("en-US") : value
                            return dataLabels == "$" ? "$" + million : value.toFixed(1) + "%"
                        }
                    },
                    gridLines: {
                        color: "transparent",
                        display: true,
                        drawBorder: false,
                        zeroLineColor: hideX ? "transparent" : "rgba(255,255,255,0.5)",
                        zeroLineWidth: 1
                    }
                }],
                xAxes: [{
                    ticks: {
                        display: !hideX,
                        maxTicksLimit: scaleX && scaleX.maxTicksLimit ? scaleX.maxTicksLimit : 11,
                        maxRotation: 0
                    },
                    gridLines: {
                        color: "transparent"
                    },
                    thickness: small ? 9 : scaleX && scaleX.thickness ? scaleX.thickness : 'flex',
                }]
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    if (!dataLabels) {
                        return
                    }

                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                            ctx.fillStyle = 'rgba(255,255,255,0.5)';
                            var y_pos = dataset.data[i] < 0 ? model.y + 20 : model.y - 8;
                            var x_pos = model.x
                            var million = labelFormat === "M" ? (dataset.data[i] / 1000000.0).toFixed(2) + "MM" : dataLabels == "$" ? dataset.data[i].toLocaleString("en-US") : dataset.data[i]
                            var value = dataLabels == "$" ? "$" + million : dataset.data[i].toFixed(1) + "%"
                            ctx.fillText(value, x_pos, y_pos);
                        }
                    });
                }
            }
        }}
    />
)

export const CapillaryChart = ({ hideTooltips, style, labels, data, border, legend, hideY, small, scaleY, bands, dataLabels, labelFormat, hideX }) => (
    <BasicChart
        style={style}
        type="bar"
        data={{
            labels,
            datasets: data.map((d, i) => ({ xAxisID: i == 1 ? 'x-axis-1' : 'x-axis-2', label: d.label, data: d.data, borderWidth: border ? 1 : 0, backgroundColor: bands ? generateBandColors(bands, d.data) : d.highlightMax ? d.data.map(v => v === Math.max(...d.data) ? colors[0] + "CC" : colors[2] + "CC") : (d.backgroundColor || `${colors[i]}CC`) }))
        }}
        options={{
            legend: {
                display: legend
            },
            tooltips: {
                enabled: !hideTooltips
            },
            scales: {
                yAxes: [{
                    display: true,
                    position: scaleY && scaleY.position ? scaleY.position : "left",
                    ticks: {
                        min: scaleY && scaleY.min ? scaleY.min : null,
                        max: scaleY && scaleY.max ? scaleY.max : null,
                        display: !hideY,
                        maxTicksLimit: scaleY && scaleY.maxTicksLimit ? scaleY.maxTicksLimit : 11,
                        callback: function (value) {
                            var million = labelFormat === "M" ? parseInt(value / 1000000) + "MM" : dataLabels == "$" ? value.toLocaleString("en-US") : value
                            return dataLabels == "$" ? "$" + million : value.toFixed(1) + "%"
                        }
                    },
                    gridLines: {
                        color: "transparent",
                        display: true,
                        drawBorder: false,
                        zeroLineColor: hideX ? "transparent" : "rgba(255,255,255,0.5)",
                        zeroLineWidth: 1
                    },
                    stacked: false
                }],
                xAxes: [{
                    id: 'x-axis-1',
                    ticks: {
                        display: !hideX,
                        fontSize: small ? 9 : 12
                    },
                    gridLines: {
                        color: "transparent"
                    },
                    barThickness: 50,
                    stacked: true,
                }, {
                    weight: 1,
                    id: 'x-axis-2',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        color: "transparent"
                    },
                    stacked: true,
                    barThickness: 30,
                    offset: true,
                }]
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    if (!dataLabels) {
                        return
                    }

                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    const allDatasets = this.data.datasets
                    this.data.datasets.forEach(function (dataset, index) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                            ctx.fillStyle = 'rgba(255,255,255,0.5)';
                            var y_pos = dataset.data[i] < 0 ? model.y + 20 : model.y - 8;
                            var x_pos = model.x
                            var million = labelFormat === "M" ? (dataset.data[i] / 1000000.0).toFixed(2) + "MM" : dataLabels == "$" ? dataset.data[i].toLocaleString("en-US") : dataset.data[i]
                            var value = dataLabels == "N" ? index === 0 ? parseInt(dataset.data[i] * 100 / allDatasets[1].data[i]) + "%" : dataset.data[i].toLocaleString("en-US") : dataLabels == "$" ? "$" + million : dataset.data[i].toFixed(1) + "%"
                            ctx.fillText(value, x_pos, y_pos);
                        }
                    });
                }
            }
        }}
    />
)

export const HorizontalCapillaryChart = ({ hideTooltips, style, labels, data, border, legend, hideY, small, scaleY, bands, dataLabels, labelFormat, hideX }) => (
    <BasicChart
        style={style}
        type="horizontalBar"
        data={{
            labels,
            datasets: data.map((d, i) => ({ yAxisID: i == 1 ? 'y-axis-1' : 'y-axis-2', label: d.label, data: d.data, borderWidth: border ? 1 : 0, backgroundColor: bands ? generateBandColors(bands, d.data) : d.highlightMax ? d.data.map(v => v === Math.max(...d.data) ? colors[0] + "CC" : colors[2] + "CC") : (d.backgroundColor || `${colors[i]}CC`) }))
        }}
        options={{
            legend: {
                display: legend
            },
            tooltips: {
                enabled: !hideTooltips
            },
            scales: {
                xAxes: [{
                    display: true,
                    position: scaleY && scaleY.position ? scaleY.position : "left",
                    ticks: {
                        min: scaleY && scaleY.min ? scaleY.min : null,
                        max: scaleY && scaleY.max ? scaleY.max : null,
                        display: !hideY,
                        maxTicksLimit: scaleY && scaleY.maxTicksLimit ? scaleY.maxTicksLimit : 11,
                        callback: function (value) {
                            var million = labelFormat === "M" ? parseInt(value / 1000000) + "MM" : dataLabels == "$" ? value.toLocaleString("en-US") : value
                            return dataLabels == "$" ? "$" + million : value.toFixed(1) + "%"
                        }
                    },
                    gridLines: {
                        color: "transparent",
                        display: true,
                        drawBorder: false,
                        zeroLineColor: hideX ? "transparent" : "rgba(255,255,255,0.5)",
                        zeroLineWidth: 1
                    },
                    stacked: false
                }],
                yAxes: [{
                    id: 'y-axis-1',
                    ticks: {
                        display: !hideX,
                        fontSize: small ? 10 : 12
                    },
                    gridLines: {
                        color: "transparent"
                    },
                    barThickness: 20,
                    stacked: true,
                }, {
                    weight: 1,
                    id: 'y-axis-2',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        color: "transparent"
                    },
                    stacked: true,
                    barThickness: 10,
                    offset: true,
                }]
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    if (!dataLabels) {
                        return
                    }

                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                            ctx.fillStyle = 'rgba(255,255,255,0.5)';
                            var x_pos = dataset.data[i] < 0 ? model.x + 20 : model.x - 8;
                            var y_pos = model.y
                            var million = labelFormat === "M" ? (dataset.data[i] / 1000000.0).toFixed(2) + "MM" : dataLabels == "$" ? dataset.data[i].toLocaleString("en-US") : dataset.data[i]
                            var value = dataLabels == "N" ? dataset.data[i] : dataLabels == "$" ? "$" + million : dataset.data[i].toFixed(1) + "%"
                            ctx.fillText(value, x_pos, y_pos);
                        }
                    });
                }
            }
        }}
    />
)

export const LineChart = ({ labels, data, options, legend, smooth, style }) => (
    <BasicChart
        style={style}
        type={smooth ? "line" : "bar"}
        data={{
            labels,
            datasets: data.map(d => ({
                type: d.type || "line",
                fill: d.histogram || d.type === "bar" || false,
                borderDash: d.dashed ? [5, 5] : [5, 0],
                data: d.data,
                borderColor: d.color,
                label: d.label,
                lineTension: smooth ? 0.4 : 0,
                pointStyle: d.histogram ? "point" : "line",
                pointRadius: d.pointRadius,
                pointBorderColor: d.pointBorderColor,
                backgroundColor: !d.histogram ? d.type === "bar" ? colors[0] + "CC" : d.type == "line" ? d.color + "CC" : "transparent" : d.color + "4a"
            }))
        }}
        options={{
            ...options,
            legend: {
                display: !!legend,
                position: legend && legend.position,
                labels: {
                    boxWidth: 50,
                    padding: 20,
                    usePointStyle: true
                }
            }
        }}
    />
)

const generateBases = (length, start, end) => {
    const array = new Array(length)
    array.fill(0)
    array[0] = start
    array[array.length - 1] = end
    return array
}

export const WaterfallChart = ({ labels, data, options, legend, scaleY, hideLabels, hideScales, hideTooltips, style }) => (
    <BasicChart
        style={style}
        type="horizontalBar"
        data={{
            labels,
            datasets: [
                { label: "Base", backgroundColor: "grey", data: generateBases(labels.length, data[0].value, data[labels.length - 1].value) },
                {
                    label: "Offset", backgroundColor: "transparent", data: generateBases(labels.length, 0, 0).map((e, i) => {
                        if (i == 0 || i == labels.length - 1)
                            return 0

                        return data[i].offset
                    })
                },
                {
                    label: "Gains", backgroundColor: colors[0] + "CC", data: generateBases(labels.length, 0, 0).map((e, i) => {
                        if (i == 0 || i == labels.length - 1)
                            return 0

                        if (data[i].value < 0)
                            return 0

                        return data[i].value
                    })
                },
                {
                    label: "Losses", backgroundColor: "#941100", data: generateBases(labels.length, 0, 0).map((e, i) => {
                        if (i == 0 || i == labels.length - 1)
                            return 0

                        if (data[i].value >= 0)
                            return 0

                        return -data[i].value
                    })
                }
            ]
        }}
        options={{
            ...options,
            tooltips: {
                enabled: !hideTooltips
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        max: scaleY ? scaleY.max : "flex",
                        display: false,
                    },
                    gridLines: {
                        color: "transparent",
                        display: true,
                        drawBorder: false,
                        zeroLineColor: hideScales ? "transparent" : "rgba(255,255,255,0.3)",
                        zeroLineWidth: 1
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        display: !hideScales
                    },
                    gridLines: {
                        color: "transparent"
                    }
                }]
            },
            legend: {
                display: !!legend,
                position: legend && legend.position,
                labels: {
                    filter: (legendItem) => {
                        if (legendItem.text === "Base" || legendItem.text === "Offset")
                            return false

                        return true
                        // return tr  ue or false based on legendItem's datasetIndex (legendItem.datasetIndex)
                    }
                }
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    if (hideLabels) {
                        return
                    }
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'center';

                    this.data.datasets.forEach(function (dataset) {
                        if (dataset.label == "Gains") {
                            for (var i = 0; i < dataset.data.length; i++) {
                                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                                ctx.fillStyle = 'rgba(255,255,255,0.5)';
                                var y_pos = model.y + 5;
                                var x_pos = dataset.data[i] < 0 ? model.x - 10 : model.x + 10
                                ctx.fillText(data[i].label, x_pos, y_pos);
                            }
                        }
                    });
                }
            }
        }}
    />
)

export const BarChart = ({ labels, data, scaleX, scaleY, disableBands, legend, stacked, labelDisplay }) => (
    <BasicChart
        type="horizontalBar"
        data={{
            labels,
            datasets: data.map((d, i) => ({ label: d.label, data: d.data, borderWidth: 0, backgroundColor: disableBands ? d.backgroundColor || colors[i] : d.data.map(d => (d < -2.5 ? '#941100' : d > 2.5 ? colors[0] + "CC" : 'grey')) }))
        }}
        options={{
            legend: {
                display: !!legend
            },
            scales: {
                yAxes: [{
                    position: scaleY && scaleY.position ? scaleY.position : "left",
                    barThickness: scaleY && scaleY.thickness ? scaleY.thickness : 12,
                    ticks: {
                        fontSize: 12,
                        fontColor: "rgba(255,255,255,1)",
                        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
                    },
                    stacked: stacked
                }],
                xAxes: [{
                    ticks: {
                        min: scaleX && scaleX.min ? scaleX.min : null,
                        max: scaleX && scaleX.max ? scaleX.max : null,
                        display: false
                    },
                    gridLines: {
                        color: "transparent",
                        display: true,
                        drawBorder: false,
                        zeroLineColor: "rgba(255,255,255,0.5)",
                        zeroLineWidth: 1
                    },
                    stacked: stacked
                }]
            },
            tooltips: {
                enabled: false
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    if (labelDisplay === false) {
                        return
                    }
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                            ctx.fillStyle = 'rgba(255,255,255,0.5)';
                            var y_pos = model.y + 7;
                            var x_pos = dataset.data[i] < 0 ? model.x - 20 : model.x + 15
                            ctx.fillText(parseInt(dataset.data[i]) + "%", x_pos, y_pos);
                        }
                    });
                }
            }
        }}
    />
)

export const PieChart = ({ labels, data, legend, hideTooltips }) => (
    <BasicChart
        type="pie"
        data={{
            labels: labels,
            datasets: [
                { data: data, backgroundColor: data.map((d, i) => colors[i] + "CC") }
            ]
        }}
        options={{
            tooltips: {
                enabled: !hideTooltips
            },
            legend: {
                display: !!legend,
                position: (legend && legend.position) || "top"
            }
        }}
    />
)

class BasicChart extends Component {
    constructor(props) {
        super(props);
        this.chartElement = React.createRef();
    }
    componentDidMount() {
        const ctx = this.chartElement.current.getContext('2d')
        let data = this.props.data
        data.datasets && data.datasets.forEach(d => {
            if (d.backgroundColor && d.backgroundColor !== "transparent" && this.props.type !== "scatter") {
                if (Array.isArray(d.backgroundColor)) {
                    d.backgroundColor = d.backgroundColor.map(g => {
                        let gradientFill = ctx.createLinearGradient(0, 0, this.props.type === "horizontalBar" ? 1200 : 0, this.props.type === "horizontalBar" ? 0 : 600);
                        gradientFill.addColorStop(0, g);
                        gradientFill.addColorStop(1, "#000");
                        return gradientFill
                    })
                } else {
                    let gradientFill = ctx.createLinearGradient(0, 0, this.props.type === "horizontalBar" ? 1200 : 0, this.props.type === "horizontalBar" ? 0 : 600);
                    gradientFill.addColorStop(0, d.backgroundColor);
                    gradientFill.addColorStop(1, "#000");
                    d.backgroundColor = gradientFill
                }
                d.borderColor = 'rgba(255,255,255,0.1)'
                d.hoverBorderColor = 'rgba(255,255,255,0.2)'

                if (this.props.type.toLowerCase().indexOf("bar") !== -1) {
                    d.borderWidth = 1
                    d.hoverBorderWidth = 2
                }
            }
        })
        const config = {
            type: this.props.type,
            data: this.props.data,
            options: {
                ...this.props.options,
                responsive: true,
                maintainAspectRatio: false
            }
        }

        this.chart = new Chart(ctx, config);
    }

    render() {
        const style = this.props.style ? { ...this.props.style } : {}
        return (
            <div className={this.props.untouchable ? "untouchable" : "chart-container"} style={{ position: "relative", flexGrow: 1, maxHeight: "100%", flexShrink: 1, margin: "0px 0px 0", ...style }}>
                <canvas ref={this.chartElement}></canvas>
            </div>
        )
    }
}

export default BasicChart