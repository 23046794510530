import { FormFieldValidationRequired } from "@pai-ui/core/utils/form/validation/field/required";
import { FormFieldValidationCustom } from '@pai-ui/core/utils/form/validation/field/custom';

export const CreateValidators = (arr, handleValidateOnDebounce) => {
  let validations = [];
  Object.keys(arr).forEach((v) => {
    switch (v) {
      case "required":
        arr[v] && validations.push(new FormFieldValidationRequired());
        break;
      // case "validateOnDebounce":
      //   validations.push(
      //     new FormFieldValidationCustom(
      //       async (value = '', values) => {
      //         // eslint-disable-next-line no-debugger
      //         debugger;
      //         console.log('validateOnDebounce triggered', value, values);

      //         let config={
      //           "input_json": {
      //             "test_id": values.test_id,
      //             "kpi_name": value,
      //           }
      //         };
      //         if(values.kpi_details?.id){ //edit kpi
      //           config.input_json.id = values.kpi_details.id;
      //           // {
      //           //   "input_json": {
      //           //     "test_id": values.test_id,
      //           //     "kpi_name": value,
      //           //     "id": values.kpi_details.id
      //           //   }
      //           // }
      //         }
      //          let res = await CheckIfKpiNameAvailable(token, config);
      //          console.log('CheckIfKpiNameAvailable', res);
      //          if(res==false)
      //              return [
      //             {
      //               message: 'A KPI with entered name is already present!',
      //             }
      //           ];
      //       }
      //     )
      //   )
      //   break;
      case "validateOnDebounce":
        validations.push(
          new FormFieldValidationCustom(
            async (value = '', values) => {
              let ret = handleValidateOnDebounce(value, values);
              return ret
            }
          )
        )
        break;
    }
  });
  return validations;
};

// eslint-disable-next-line no-unused-vars
export const CheckRenderCondition = (values, conditions, fieldName) => {
  let res = true;
  let i = 0;
  while (res && i < conditions?.length) {
    let currCondition = conditions[i];

    switch (currCondition.operator) {
      case "includes":
        res = values?.[currCondition.dependentField]?.includes(
          currCondition.valueToCheck
        );
        break;
      case "includesWithinObject":
        res = values?.[currCondition.dependentField]?.map(x=>x[currCondition.dependentValue]).includes(
          currCondition.valueToCheck
        );
        break;
      case "===":
        res = Array.isArray(currCondition.valueToCheck) ? values?.[currCondition.dependentField]?.join()=== currCondition.valueToCheck?.join()
        : values?.[currCondition.dependentField] === currCondition.valueToCheck;
        break;
      case "!==":
        res = Array.isArray(currCondition.valueToCheck) ? values?.[currCondition.dependentField]?.join() !== currCondition.valueToCheck?.join()
        : values?.[currCondition.dependentField] !== currCondition.valueToCheck;
        break;
      case "exists":
        res = values?.[currCondition.dependentField];
        break;
      case "or": //TODO naming to be checked
        res = false;
        if (currCondition.fieldValueType === "array") {
          currCondition.valueToCheck.forEach((v) => {
            if (values?.[currCondition.dependentField]?.includes(v)) {
              res = true;
            }
          });
        } else {
          res = currCondition.valueToCheck.includes(
            values?.[currCondition.dependentField]
          );
        }
    }
    // console.log(
    //   "CheckRenderCondition",
    //   fieldName,
    //   values?.[currCondition.dependentField],
    //   currCondition,
    //   res
    // );
    i += 1;
  }

  return res;
};

// isAvailable: (values) => {
//     console.log("validating for lift", values);
//     return values?.section?.includes(3);
//   },

const CreateConfigObj = (
  f,
  ddnMaster,
  defaultValuesObj,
  optionsKey,
  multipleOptionsKeys,
  
  handleValidateOnDebounce
) => {
  // console.log('CreateConfigObj', f)
  let tempObj = {
    [f.fieldName]: {
      ...f,
      options: optionsKey ? ddnMaster[optionsKey] : ddnMaster[f.optionsKey],
      validators: f.validations ? CreateValidators(f.validations, handleValidateOnDebounce) : [],
      validationDelayMs: f.validations?.validateOnDebounce ? 500 : 0
    },

  };

  // some custom components might require multiple values for their children components
  //ex rule builder, editable formula needs aggregations and column names
  if (multipleOptionsKeys) {
    let allOptions = {};
    multipleOptionsKeys.map((k) => {
      allOptions[k] = ddnMaster[k];
    });
    tempObj[f.fieldName]["ddnOptions"] = { ...allOptions };
  }

  //conditional render on some value
  if (f.conditionalRender) {
    tempObj[f.fieldName]["isAvailable"] = (values) => {
      return CheckRenderCondition(values, f.conditionalRender, f.fieldName);
    };
  }
  //add defaultValue here
  if (defaultValuesObj?.[f.fieldName]) {
    tempObj[f.fieldName]["defaultValue"] = defaultValuesObj[f.fieldName];
  }

  return tempObj;
};

export const CreateGenericFormFieldsConfig = ({
  config,
  ddnMaster,
  defaultValuesObj,
  dynamicFieldsConfig,
  
  handleValidateOnDebounce
}) => {
  // console.log(
  //   "CreateGenericFormFieldsConfig",
  //   config,
  //   ddnMaster,
  //   defaultValuesObj,
  //   dynamicFieldsConfig
  // );
  const componentDefaultValuesObj = {}

  Object.keys(config.sections).map((section) => {
    let reqd_section = config.sections[section];
    reqd_section.sectionFields.map((f) => {
      let tempConfig = {};
      if (f.customDynamicFields) {
        // eslint-disable-next-line no-debugger
        // debugger;
        dynamicFieldsConfig[f.customDynamicFields]?.map((x) => {
          tempConfig = {
            ...tempConfig,
            ...CreateConfigObj(
              x,
              ddnMaster,
              defaultValuesObj,
              f.optionsKey,
              f.multipleOptionsKeys,
              
              handleValidateOnDebounce
            ),
          };
        });
      } else {
        // eslint-disable-next-line no-debugger
        // debugger;
        if (f.default_value !== undefined && f.default_value !== null) {
          componentDefaultValuesObj[f.fieldName] = f.default_value
        }
        
        tempConfig = CreateConfigObj(f, ddnMaster, defaultValuesObj, f.optionsKey,f.multipleOptionsKeys,  handleValidateOnDebounce);
      }

      config["allFieldsConfig"] = {
        ...config["allFieldsConfig"],
        ...tempConfig,
        
      };
    });
  });
config["default_state_values"] = { ...componentDefaultValuesObj }
  //submit button
  // config.controlButtons = [{name:'Save Changes', position:'right', onChange:""}]
  // console.log("processed config", config);
  return config;
};

// This function will help in updating default values of the form when the config is already avaialble
export const UpdateGenericFormFieldsConfig = ({
  config,
  defaultValuesObj,
  clearValues,
}) => {
  // eslint-disable-next-line no-debugger
  // debugger;
  if (clearValues) {
    Object.keys(config.allFieldsConfig).map((key) => {
      delete config.allFieldsConfig[key]["defaultValue"];
    });
  } else if (config && Object.keys(config.allFieldsConfig)) {
    Object.keys(config.allFieldsConfig).map((key) => {
      config.allFieldsConfig[key]["defaultValue"] = defaultValuesObj[key];
    });
  }
  // console.log(
  //   "selectedKPI UpdateGenericFormFieldsConfig",
  //   config,
  //   defaultValuesObj
  // );
  return config;
};

// export const GenerateUpsertObject = (updatedValues, originalKPI) => {
//   console.log('GenerateUpsertObject', updatedValues);
//   let returner ={
//     base_variant: originalKPI.base_variant,
//     is_visible: originalKPI.is_visible || updatedValues.is_visible

//   }
// return returner;
// }
