import * as AccessControlService from "@xai/services/accessControl";

class AccessControlServiceHelper {
  constructor(token) {
    this.athToken = token;
  }

  async getAllUsers() {
    const data = await AccessControlService.getAllUsers(this.athToken);

    if (!data) return null;
    return data;
  }

  async upsertUser(inputJson) {
    const data = await AccessControlService.editUser(inputJson, this.athToken);
    // console.log("api", data);
    if (!data) return null;
    return data;
  }

  async getAllRoles() {
    const result = await AccessControlService.getAllRoles(this.athToken);

    if (!result) return null;
    return result;
  }

  async deleteUser(inputJson) {
    const result = await AccessControlService.deleteUser(
      inputJson,
      this.athToken
    );
    console.log("deleteCJ api result", result);

    if (!result) return null;
    return result;
  }
}

export default AccessControlServiceHelper;
