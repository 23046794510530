import React from "react";
import { Visualization, VisualizationFeature, VisualizationActionType } from "@pai-ui/core/components/visualization";
import * as UI from "@pai-ui/core/components/visualization";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { UserContext } from "@xai/providers/with-auth";

const ComparisonTableGenericChartComponent = ({ height, chart_config, chart_data, excludeFields }) => {

    const { format } = React.useContext(UserContext);

    const defaultChartConfig = {
        legendShow: true,
        yAxisShow: false,
        xAxisWidth: 75
    }

    let compositionChartAdditionalConfig = chart_config?.chartAdditionalConfig ? JSON.parse(JSON.stringify(chart_config?.chartAdditionalConfig)) : null;
    
    return (
        <>
            {
                (chart_data !== null || chart_data !== []) ?
                    <Visualization
                        height={height}
                        dataConfig={{
                            structureType: "processed",
                        }}
                        features={[VisualizationFeature.fullScreen]}
                        actions={[VisualizationActionType.exportJpeg, VisualizationActionType.exportPng, VisualizationActionType.exportCsv]}
                        viewConfig={
                            new UI[chart_config?.chart_type]({
                                layout: chart_config?.chart_orientation || "horizontal",
                                variant: chart_config?.chart_variant || null,
                                series: {
                                    excludeFields: excludeFields,
                                    getConfig: (fieldName) => {
                                        const config =
                                            chart_config?.chart_type === "CompositionChartConfig" ?
                                            compositionChartAdditionalConfig?.seriesGetConfig
                                                :
                                                {
                                                    [fieldName]: {
                                                        name:
                                                            chart_config?.chart_type === "PieChartConfig" ||
                                                                (chart_config?.chart_type === "BarChartConfig" &&
                                                                    chart_config?.chart_variant)
                                                                ? splitByDelimiterAndToStartCase(fieldName)
                                                                : chart_config?.chart_type === "BarChartConfig" ||
                                                                    chart_config?.chart_type === "LineChartConfig"
                                                                    ? chart_data[0]?.kpi_series_name
                                                                    : splitByDelimiterAndToStartCase(fieldName),
                                                        label: {
                                                            position: chart_config?.chart_orientation === 'vertical' ? 'right' : 'top',
                                                            format: {
                                                                value: format?.[chart_data[0]?.format_id],
                                                            }
                                                        },
                                                    },
                                                };
                                        return config[fieldName];
                                    }
                                },
                                legend: {
                                    show: defaultChartConfig.legendShow
                                },
                                axes: {
                                    x: {
                                        width: chart_config?.chart_orientation === 'vertical' && defaultChartConfig.xAxisWidth,
                                        dataKey: "kpi_name",
                                    },
                                    y: chart_config?.chart_type === "CompositionChartConfig"
                                        ? compositionChartAdditionalConfig?.yAxisConfig?.map(
                                            (item) => {
                                                return {
                                                    ...item,
                                                    domain: ["auto", "auto"],
                                                    show: false,
                                                };
                                            }
                                        ) || {
                                            domain: ["auto", "auto"],
                                            show: false,
                                            format: {
                                                value:
                                                    format?.[chart_data[0]?.format_id],
                                            },
                                        }
                                        :
                                        {
                                            domain: ["auto", "auto"],
                                            show: defaultChartConfig.yAxisShow,
                                            format: {
                                                value: format?.[chart_data[0]?.format_id]
                                            }
                                        }
                                },
                            })
                        }
                        dataset={{
                            data: chart_data
                        }}
                    />
                    :
                    <LegacyNoData height={height} />
            }
        </>
    );
};

export default ComparisonTableGenericChartComponent;