import * as SupersetServiceHelper from "@xai/services/superset-integration/index";

class SupersetHelper {
    constructor(token) {
      this.authToken = token;
      this.addConfig = this.addConfig.bind(this);
    }
    
//   async getAllExperiments(screenId) {
//       const data = await ABMeasureServiceHelper.GetExperiments(this.authToken,screenId);
//       return data;
//     }

    async addConfig(config) {
      const res = await SupersetServiceHelper.AddConfig(this.authToken, config);
      console.log('after addConfig', res)
      return res;
    }
}

export default SupersetHelper;