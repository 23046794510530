import * as ExperimentTasksManagementService from "@xai/services/experimentTasksManagement";

class ExperimentTasksManagementServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async getAllExperimntTaskRequestlist() {
    const data =
      await ExperimentTasksManagementService.getAllExperimntTaskRequestlist(
        this.authToken
      );
    if (!data) return null;
    return data;
  }
}

export default ExperimentTasksManagementServiceHelper;
