import { Button, Col, Row /* , Select, TreeSelect */ } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
// import { isEqual } from "lodash";
import { AspectRatio } from "../design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import * as AntIcons from "@ant-design/icons";
import * as Components from "./components";
import ChartWrapper from "../../assets/generic-components/visualization/components/wrapper";
import {
  GenerateContextProvider,
  // useGenerateContext,
} from "../design-gen-ai-dynamic-images/contexts/generate-context/GenerateContext";
import DataServiceHelper from "@xai/pages/design-gen-ai-dynamic-images/service-helper";
import DesignServiceHelper from "@xai/pages/design-gen-ai-marketing-copy/service-helper";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";
import {
  SubjectLineFormConfigProvider,
  useSubjectLineFormConfig,
} from "@xai/context/SubjectLineFormConfig";
import {
  basicFieldsValueCheck,
  postVariantbanktransformer,
  postExperimentDataTransformer,
} from "./transformer.js";

const SubjectLineGenerator = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = React.useState(0);

  const [definition, setdefinition] = React.useState();

  const [basePrompts, setBasePrompts] = React.useState({
    Global:
      "You are an experienced Copywriter with a passion for crafting compelling and persuasive messaging. You have to create copy for a marketing campaign for a company.",
    fallback:
      "You are an experienced Copywriter with a passion for crafting compelling and persuasive messaging. You have to create copy for a marketing campaign for a company.",
  });

  const [data, setData] = React.useState({});

  const {
    config,
    updateConfig,
    basicFormValidStatus,
    selectedExperiment,
    setSelectedExperiment,
  } = useSubjectLineFormConfig();

  // const [canvaAccounts, setCanvaAccounts] = React.useState([]);

  // const [selectedCanvaAccount, setSelectedCanvaAccount] = React.useState("");

  // const [dropDownData, setDropDownData] = React.useState({
  //   marketingCopy: [],
  //   subjectLine: [],
  // });

  // eslint-disable-next-line no-unused-vars
  const [currentProduct, setCurrentProduct] = React.useState(
    "marketingCopy-Generic"
  );

  // eslint-disable-next-line no-unused-vars
  const [defaultImages, setDefaultImages] = React.useState([]);

  const [addToVariantbankButtonDisable, setAddToVariantbankButtonDisable] =
    React.useState(true);

  const [addToVariantbankButtonloader, setAddToVariantbankButtonloader] =
    React.useState(false);

  // const getDropDownData = () => {
  //   const service = new DataServiceHelper("token");
  //   return service.getGenAIMarketingCopyOptions();
  // };

  const getSpecificMarketingCopyConfig = (product) => {
    const service = new DataServiceHelper("token");
    return service.getGenAIMarketingCopyDefinition(
      "token",
      product.toLowerCase()
    );
  };

  // const getCanvaAccounts = () => {
  //   const service = new DataServiceHelper("token");
  //   return service.getCanvaAccounts();
  // };

  // React.useEffect(() => {
  //   // getDropDownData().then((d) => setDropDownData(d));
  //   // getCanvaAccounts().then((d) => {
  //   //   setCanvaAccounts(d.accounts);
  //   //   setSelectedCanvaAccount(d.accounts[0].id);
  //   // });
  // }, []);

  React.useEffect(() => {
    getSpecificMarketingCopyConfig(currentProduct).then((d) =>
      setdefinition(d)
    );
  }, [currentProduct]);

  const getDefaultData = (definition) => {
    let defaultData = {};

    definition.steps.forEach((step) => {
      defaultData[step.key] = step.defaultData;
    });

    return defaultData;
  };

  React.useEffect(() => {
    if (definition) {
      let tempData = getDefaultData(definition);
      if (location?.state?.selectedExperiment?.experiment_data?.basicStepInfo) {
        let locationSelectedExperiment = {
          ...location?.state?.selectedExperiment,
        };
        locationSelectedExperiment.experiment_data.basicStepInfo = JSON.parse(
          JSON.stringify(
            locationSelectedExperiment?.experiment_data?.basicStepInfo
          )
        );
        setSelectedExperiment(locationSelectedExperiment);
        let modifiedConfig = JSON.parse(
          JSON.stringify({
            ...config,
            ...location?.state?.selectedExperiment?.experiment_data
              ?.basicStepInfo,
          })
        );
        updateConfig(modifiedConfig);
        // setData(
        //   location?.state?.selectedExperiment?.experiment_data?.basicStepInfo
        //     ?.metaData
        // );
        tempData.basic =
          location?.state?.selectedExperiment?.experiment_data?.basicStepInfo;
      } else {
        updateConfig(tempData.basic);
        // setData(getDefaultData(definition));
        // setData(tempData);
      }
      setData(tempData);

      setDefaultImages([
        definition.steps.find((s) => s.key === "images")?.defaultData
          .variants[0]?.images,
      ]);
    }
  }, [definition]);

  React.useEffect(() => {
    console.log("data useeffect on data", data, config);
    let modifiedConfig = JSON.parse(
      JSON.stringify({ ...config, metaData: data })
    );
    updateConfig(() => {
      return modifiedConfig;
    });
    // updateConfig({ ...config, metaData: data });
  }, [
    data,
    data?.content?.prompts,
    data?.content?.variants,
    data?.variants?.variants,
  ]);

  const enableAddToVariantBankButton = async () => {
    // Note : generic changes made to this method needs to be suynced with getSelectedVariants
    // #TODO : Check for already added variants to be excluded post upsert implementation
    let selectedVariantsList = [];
    // eslint-disable-next-line no-unused-vars
    await data?.content?.variants?.forEach((variantList, variantListIndex) => {
      let selectedVariants = variantList?.taglines?.filter(
        // variant - selected and not added to varian Bank ==> selected && !variantAdded
        (variant) => variant?.selected && !variant?.variantAdded
      );
      if (selectedVariants?.length > 0) {
        selectedVariantsList = [].concat(
          selectedVariantsList,
          selectedVariants
        );
      }
    });
    setAddToVariantbankButtonDisable(() => {
      return selectedVariantsList?.length === 0;
    });
  };

  React.useEffect(() => {
    console.log("data useeffect", definition, data, data?.content?.variants);
    enableAddToVariantBankButton();
  }, [data?.content?.variants]);

  // React.useEffect(() => {
  //   console.log('data useeffect', definition,data,data?.content?.variants)
  // }, [data]);

  React.useEffect(() => {
    console.log("location", location);
  }, []);

  if (!definition) {
    return <></>;
  }

  let filteredDefinition = definition.steps.filter((d) => {
    if (!data || !data.variants) {
      return true;
    }
    if (d.key === "images" || d.key === "templates" || d.key === "publish") {
      return (
        // data.variants.output_type === "poster" || !data.variants.output_type
        ["poster"].includes(data.variants.output_type) || false // Hide images, templates, publish -  steps by default
      );
    }

    if (d.key === "review") {
      if (!data.variants.output_type) {
        return false;
      }
      return !["poster"].includes(data.variants.output_type);
    }

    return true;
  });

  console.log({ filteredDefinition, definition });

  const step = filteredDefinition[currentStep];

  const MainComponent = Components[step.component];
  const SummaryComponent = Components[step.summaryComponent];

  console.log("steooppppp ", { step });

  const updateEntry = (section, key, value) => {
    setData((d) => {
      let newD = { ...d };
      if (!newD[section]) {
        newD[section] = {};
      }
      newD[section][key] = value;
      return newD;
    });
  };

  const updateEntries = (section, keyValues) => {
    setData((d) => {
      let newD = JSON.parse(JSON.stringify({ ...d }));
      if (!newD[section]) {
        newD[section] = {};
      }
      keyValues.forEach((kv) => {
        console.log(kv.key, kv.value);
        newD[section][kv.key] = kv.value;
      });
      return newD;
    });
  };

  const sectionData = data[step.key] || {};

  const addExperimentMethod = async ({ experimentTypeValue }) => {
    let expTypevalue = experimentTypeValue || data?.variants?.output_type;
    let modifiedConfig = config;
    modifiedConfig.metaData = {
      ...config.metaData,
      ...data,
    };

    let dataConfig = await postExperimentDataTransformer({
      // basicData: config,
      basicData: modifiedConfig,
      experimentTypeValue: expTypevalue,
      selectedExperiment: selectedExperiment,
    });

    const service = new DesignServiceHelper("token");
    let res = await service.addExperiment(dataConfig);

    // // Temp Response
    // let tempId = "b120e293-f412-4854-b296-a996ae74c11c"; // exp ID of "Test Experiment"
    // let res = {
    //   id: tempId,
    //   experiment_data: null,
    //   // experiment_data: {
    //   //   basicStepInfo: {
    //   //     ...config,
    //   //     experimentId: tempId,
    //   //   },
    //   // },
    // };

    // res.experiment_data = JSON.parse(JSON.stringify(res.experiment_data));

    res.experiment_data = JSON.parse(res?.experiment_data);

    setSelectedExperiment({ ...res });
    await console.log("addExperimentMethod res", res);

    let modifiedResConfig = res?.experiment_data?.basicStepInfo;
    await updateConfig({
      ...config,
      ...modifiedResConfig,
      experimentId: res?.id,
    });
  };

  // update Experiment when Step Tile changes or next or previous button is clicked
  const updateExperiment = async ({ experimentTypeValue }) => {
    let expTypevalue = experimentTypeValue || data?.variants?.output_type;
    let modifiedConfig = config;
    modifiedConfig.metaData = {
      ...config.metaData,
      ...data,
    };

    let dataConfig = await postExperimentDataTransformer({
      // basicData: config,
      basicData: modifiedConfig,
      experimentTypeValue: expTypevalue,
      selectedExperiment: selectedExperiment,
    });

    // console.log(
    //   "updateExperiment",
    //   expTypevalue,
    //   selectedExperiment,
    //   config,
    //   dataConfig
    // );

    const service = new DesignServiceHelper("token");
    return await service
      .updateExperiment(dataConfig)
      .then((res) => {
        res.experiment_data = JSON.parse(res.experiment_data);
        // console.log("res", res, res.experiment_data, expData);
        let modifiedConfig = res.experiment_data?.basicStepInfo;
        updateConfig({
          ...config,
          ...modifiedConfig,
        });
        setSelectedExperiment({ ...res });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const addDraftExperiment = async () => {
    let experimentTypeValue = data?.variants?.output_type;
    // Check if experiment needs to be created - primary
    if (config?.experimentId) {
      console.log(
        "addExperimentMethod - edit",
        experimentTypeValue,
        config,
        selectedExperiment?.experiment_data?.basicStepInfo
      );
      // check if any changes are there in basic details - 2ndry
      // #TODO : addExperimentMethod to be called post comparing with previous and current basic step input changes.

      // // updateExperiment method to be added here with conditionals.
      // if (
      //   !isEqual(
      //     { ...config },
      //     { ...selectedExperiment?.experiment_data?.basicStepInfo }
      //   )
      //   // #TODO: Proper Nested object comparison needs to be added here
      // ) {
      console.log(
        "updateExperiment - to be called",
        config,
        selectedExperiment?.experiment_data?.basicStepInfo,
        selectedExperiment,
        experimentTypeValue
      );

      await updateExperiment({ experimentTypeValue: experimentTypeValue });
      // }
    } else {
      // let dataConfig = {
      //   Name: config?.basicName,
      //   templateId: 1, // SubjectLine Experiment template
      // };
      // console.log("addExperimentMethod", dataConfig, config, data, props);

      // console.log("addExperimentMethod - add");
      // // Comment below line for testing, if needed.
      // await addExperimentMethod(dataConfig);

      console.log("addExperimentMethod - add");
      await addExperimentMethod({ experimentTypeValue: experimentTypeValue });
    }
  };

  const deleteExperiment = async () => {
    let dataConfig = {
      id: selectedExperiment?.id,
    };
    const service = new DesignServiceHelper("token");
    return await service
      .deleteExperiment(dataConfig)
      .then(async (/* res */) => {
        let modifiedConfig = config;
        modifiedConfig.experimentId = null;
        await updateConfig({
          ...config,
          ...modifiedConfig,
        });
        return;
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const props = {
    ...filteredDefinition[currentStep].props,
    data: sectionData,
    allData: data,
    allProps: definition.steps.map((s) => ({ key: s.key, props: s.props })),
    updateEntry: (key, value) => {
      updateEntry(step.key, key, value);
    },
    updateParentEntry: updateEntry,
    basePrompts,
    setBasePrompts,
    updateEntries: (keyValues) => {
      console.log("KeyValues", keyValues);
      updateEntries(step.key, keyValues);
    },
    currentStep: currentStep,
    setCurrentStep: setCurrentStep,
    addExperimentMethod: addExperimentMethod,
    addDraftExperiment: addDraftExperiment,
    updateExperiment: updateExperiment,
    deleteExperiment: deleteExperiment,
  };

  const updateAddedVariantsInfo = ({ selectedVariantsList_WithIndex }) => {
    let contentVariants = props?.allData?.content?.variants;

    selectedVariantsList_WithIndex?.forEach((variantList) => {
      let listInScope =
        contentVariants?.[variantList?.variantListIndex]?.taglines;
      let selectedvariants = variantList?.selectedvariants;
      // Iterate over the first array
      for (const object1 of listInScope) {
        // Find the matching object in the second array
        const matchingObject2 = selectedvariants.find(
          (object2) => object1.id === object2.id
        );

        // If a matching object is found
        if (matchingObject2) {
          // Update the matching object in the first array
          object1["variantAdded"] = true;
        }
      }
    });

    // update data
    props.updateEntry("variants", JSON.parse(JSON.stringify(contentVariants)));

    console.log(
      "updateAddedVariantsInfo",
      data,
      props,
      props.allData.content.variants
    );
  };

  const addVariantsMethod = async (
    dataconfig,
    selectedVariantsList_WithIndex
  ) => {
    setAddToVariantbankButtonloader(true);
    const service = new DesignVariantBankApprovalServiceHelper("token");
    await service
      .bulkUpsertExperimentVarianBankList(dataconfig)
      .then(async (res) => {
        await console.log("addExperimentMethod res", res);
        /**
         * once variants are added,
         * update variants local status with additional info.
         */
        await updateAddedVariantsInfo({ selectedVariantsList_WithIndex });
      })
      .catch((e) => {
        console.log("addVariantsMethod error", e);
      })
      .finally(() => {
        setAddToVariantbankButtonloader(false);
      });
  };

  const getSelectedVariants = async () => {
    // Note : generic changes made to this method needs to be suynced with enableAddToVariantBankButton
    // #TODO : Check for already added variants to be excluded post upsert implementation
    let selectedVariantsList_WithIndex = [];
    let selectedVariantsList = [];
    await props?.data?.variants?.forEach((variantList, variantListIndex) => {
      let selectedVariants = variantList?.taglines?.filter(
        // variant - selected and not added to varian Bank ==> selected && !variantAdded
        (variant) => variant?.selected && !variant?.variantAdded
      );
      if (selectedVariants?.length > 0) {
        selectedVariantsList_WithIndex.push({
          variantListIndex: variantListIndex,
          selectedvariants: selectedVariants,
        });
        selectedVariantsList = [].concat(
          selectedVariantsList,
          selectedVariants
        );
      }
    });

    return {
      selectedVariantsList_WithIndex,
      selectedVariantsList,
    };
  };

  // Add to variant bank
  const addToVariantbank = async () => {
    // let selectedVariantsList_WithIndex = [];
    // let selectedVariantsList = [];

    const { selectedVariantsList_WithIndex, selectedVariantsList } =
      await getSelectedVariants();

    console.log(
      "variants",
      config?.experimentId,
      props.data.variants,
      selectedVariantsList_WithIndex,
      selectedVariantsList
    );

    let finalVariantList = await postVariantbanktransformer({
      experimentId: config?.experimentId,
      variantList: selectedVariantsList,
      experimentTypeValue: data?.variants?.output_type,
    });

    await addVariantsMethod(finalVariantList, selectedVariantsList_WithIndex);
    // await updateAddedVariantsInfo({ selectedVariantsList_WithIndex }); // added for testing
  };

  return (
    <>
      <GenerateContextProvider value={{}}>
        {/* // Removing the dropdown select */}
        {/* 
        <Select
          style={{
            position: "fixed",
            top: 13,
            right: "13%",
            zIndex: 9999999,
            width: 150,
          }}
          value={currentProduct}
          size="large"
          onChange={setCurrentProduct}
        >
          <Select.OptGroup label="Marketing Copy">
            {dropDownData.marketingCopy.map((d, keyindx) => (
              <Select.Option value={`marketingCopy-${d}`} key={keyindx}>
                {d}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select> 
        */}
        <div
          style={{
            // position: "absolute",
            inset: 0,
            // padding: "0px 1.1888rem",
            overflow: "hidden",
            maxWidth: "100%",
            maxHeight: "100%",
            flex: "1 1 auto",
          }}
        >
          <Row style={{ height: "100%" }}>
            <Col flex={"100px"} style={{ paddingTop: 25 }}>
              {filteredDefinition.map((step, index) => {
                const Icon = AntIcons[step.icon];
                const basicFieldsValueStatus =
                  (!basicFieldsValueCheck(config) && basicFormValidStatus) ||
                  location?.state?.selectedExperiment?.experiment_data
                    ?.basicStepInfo;
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: 5,
                      cursor: basicFieldsValueStatus
                        ? "pointer"
                        : step?.title === "Basic"
                        ? ""
                        : "not-allowed",
                    }}
                    onClick={() => {
                      if (basicFieldsValueStatus) {
                        addDraftExperiment();
                        setCurrentStep(index);
                      }
                    }}
                  >
                    <AspectRatio width={1} height={0.9}>
                      <div
                        style={{
                          background:
                            index === currentStep
                              ? "rgba(255,255,255,0.03)"
                              : "rgba(255,255,255,0)",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          position: "absolute",
                          inset: 0,
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                          color:
                            index === currentStep
                              ? "#15afd0"
                              : basicFieldsValueStatus
                              ? undefined
                              : "gray",
                        }}
                      >
                        <Icon style={{ fontSize: "24px", marginTop: 5 }} />
                        <p
                          style={{
                            marginBottom: 0,
                            textAlign: "center",
                            marginTop: 5,
                          }}
                        >
                          {step.title}
                        </p>
                      </div>
                    </AspectRatio>
                  </div>
                );
              })}
            </Col>
            <Col
              span={!MainComponent ? undefined : 6}
              flex={!MainComponent ? 1 : undefined}
              style={{
                flexShrink: 1,
                backgroundColor: "rgba(255,255,255,0.03)",
              }}
            >
              {!!MainComponent && (
                <ChartWrapper>
                  {({ height /* , width */ }) => (
                    <div
                      style={{
                        height,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ flex: 1, overflow: "auto", padding: 25 }}>
                        {SummaryComponent && <SummaryComponent {...props} />}
                      </div>
                    </div>
                  )}
                </ChartWrapper>
              )}
              {!MainComponent && (
                <ChartWrapper>
                  {({ height, width }) => (
                    <div
                      style={{
                        height,
                        width: width - 5,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          overflow: "auto",
                          padding: "10px 25px 0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {SummaryComponent && <SummaryComponent {...props} />}
                      </div>
                    </div>
                  )}
                </ChartWrapper>
              )}
            </Col>
            {!!MainComponent && (
              <Col
                flex={1}
                style={{
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: "-1.188rem",
                  }}
                >
                  <ChartWrapper>
                    {({ height }) => (
                      <div
                        style={{
                          overflow: "auto",
                          height,
                          padding: 25,
                          paddingTop: 30,
                        }}
                      >
                        {MainComponent && props && <MainComponent {...props} />}
                      </div>
                    )}
                  </ChartWrapper>
                </div>
                <div
                  style={{
                    height: 70,
                    flexShrink: 0,
                    display: "block",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTop: "1px solid rgba(255,255,255,0.25)",
                  }}
                >
                  {!!MainComponent && currentStep !== 0 && (
                    <div
                      style={{
                        padding: "17px 25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={() => {
                          addDraftExperiment();
                          setCurrentStep(currentStep - 1);
                        }}
                        type="ghost"
                        style={{ marginRight: 20 }}
                      >
                        Previous
                      </Button>
                      <div
                        style={{
                          // padding: "17px 25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "15px",
                        }}
                      >
                        {currentStep === 2 && (
                          <Button
                            onClick={() => {
                              addToVariantbank();
                            }}
                            type="primary"
                            ghost
                            style={{}}
                            disabled={
                              addToVariantbankButtonDisable
                              // getSelectedVariants()?.selectedVariantsList?.length !== 0
                              // [].length===0
                            }
                            loading={addToVariantbankButtonloader}
                          >
                            Add to variant bank
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            addDraftExperiment();
                            setCurrentStep(currentStep + 1);
                          }}
                          type="primary"
                          style={{}}
                          disabled={
                            props.allData?.variants?.output_type?.length ===
                              0 && currentStep === 2
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </GenerateContextProvider>
    </>
  );
};

const SubjectLineGeneratorIndex = () => {
  return (
    <SubjectLineFormConfigProvider>
      <SubjectLineGenerator />
    </SubjectLineFormConfigProvider>
  );
};

export default SubjectLineGeneratorIndex;
