import React from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Auth, dotNetApi } from "@xai/library/clients";
// import * as Config from "@xai/library/config";

let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");
// let mock = false; //process.env.REACT_APP_API_MOCK;

export const getUserFromOpenIDToken = async (props) => {
  const { requestor, email, identity } = props;
  console.log("auth getUserFromOpenIDToken", requestor, email, identity);
  try {
    // #TODO : wherever created Axios instance is used, we will require custom userHeaders.
    const userHeaders = {
      userid: identity.userName,
      username: identity.name,
    };
    let apiResponse;
    if (mock) {
      apiResponse = await Promise.allSettled([
        // axios.get(`https://panera-backend-node-sbox.xai.zssandbox.com/auth/GetAPILogin`),
        Auth.get(`/GetAPILogin`, { headers: userHeaders }),
        axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMetaData.json`,
          {
            skipInterceptors: true
          }),
        axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetExperiment_generic_options.json`,
          {
            skipInterceptors: true
          }),
        axios.get(`${process.env.REACT_APP_SUPERSET_HOST}/login/`, {
          // headers: { "Kubeflow-Userid": "souvik.saha@zs.com" },
        }).catch((err) => {
          console.error('error while login to superset', err);
        })
      ]);
      console.log("mock auth getUserFromOpenIDToken", apiResponse);
      // apiResponse[0] = { data: apiResponse[0] };
      // apiResponse[1] = { data: apiResponse[0].status_format };
    } else {
      // TODO - actual api integration to be implemented and tested.
      apiResponse = await Promise.allSettled([
        Auth.get(`/GetAPILogin`, { headers: userHeaders }),
        dotNetApi.get(`/Metadatas/GetMetaData`),
        dotNetApi.get(`/Metadatas/GetExperimentGenericOptions`),
      ]);
    }
    console.log("auth getUserFromOpenIDToken", apiResponse);
    const token = apiResponse?.[0]?.value;
    const metadata = apiResponse?.[1]?.value;
    const genericOptions = apiResponse?.[2]?.value;
    let response = {
      user: jwt_decode(token.data.token),
      token: token?.data?.token,
      format: metadata?.data?.status_format?.format,
      status: metadata?.data?.status_format?.status,
      genericOptions: genericOptions?.data?.experiment_generic_options,
    };
    console.log("auth getUserFromOpenIDToken", apiResponse, response);
    return response;
  } catch (ex) {
    console.log(ex, window.location.href);
    // return { error : ex.response.data };
    // return { error : <React.Fragment>Your session has expired! <br/> <a href="http://localhost:3000/" target="new"> Click here to login </a> </React.Fragment>  }; //FIXME remove hard code
    return {
      error: (
        <React.Fragment>
          Your session has expired! <br />
          <a
            href={window.location.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to login
          </a>
        </React.Fragment>
      ),
    };
  }
};

export const getMetaData = async (token, /* additionalHeaders */) => {
  //requestor, email
  console.log("auth getMetaData");
  try {
    // console.log("api", requestor);
    const apiResponse = await Promise.all([
      // axios.get(`${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetMetaData`, {
      //   headers: {
      //     "x-api-key": process.env.REACT_APP_API_KEY,
      //     Authorization: "Bearer " + token,
      //     ...additionalHeaders
      //   },
      // }),
      dotNetApi.get(`/Metadatas/GetMetaData`, {
        Authorization: "Bearer " + token,
      }),
      dotNetApi.get(`/Metadatas/GetExperimentGenericOptions`, {
        Authorization: "Bearer " + token,
      }),
    ]);
    const metadata = apiResponse[0];
    const genericOptions = apiResponse[1];
    console.log("auth getMetaData", metadata);
    return {
      format: metadata.data?.status_format?.format,
      status: metadata.data?.status_format?.status,
      genericOptions: genericOptions.data?.experiment_generic_options,
      // country: metadata.data?.country,
      // currency: metadata.data?.currency,
    };
  } catch (ex) {
    console.log(ex, window.location.href);
    return {
      error: (
        <React.Fragment>
          Your session has expired! <br />
          <a
            href={window.location.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            Click here to login
          </a>
        </React.Fragment>
      ),
    };
  }
};

export const getAccount = () => {
  return {
    accountIdentifier: "d13137ec-a8a2-4d03-b600-06975ff843b8",
    homeAccountIdentifier:
      "ZDEzMTM3ZWMtYThhMi00ZDAzLWI2MDAtMDY5NzVmZjg0M2I4.ZWMzYzdkZWUtZDU1Mi00OTRiLWEzOTMtN2Y5NDFhOTBiOTg1",
    userName: "john.doe@zs.com",
    name: "John Doe",
    idToken: {
      aud: "8ec1a8f5-2c92-4c20-be43-1675094b6da4",
      iss: "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
      iat: 1608214919,
      nbf: 1608214919,
      exp: 1608218819,
      aio: "ATQAy/8SAAAASWWAhdEHvWCFZop10VSRONoy/Z/IBi5tFPR0Bd58YJZ00VFVJ5PYAduNz8Id6x+N",
      email: "john.doe@zs.com",
      name: "John Doe",
      nonce: "77663aa4-6b68-409f-b71d-d9316a15f141",
      oid: "d13137ec-a8a2-4d03-b600-06975ff843b8",
      preferred_username: "john.doe@zs.com",
      rh: "0.AAAA7n087FLVS0mjk3-UGpC5hfWowY6SLCBMvkMWdQlLbaQOAAk.",
      sub: "WV1zIi1XpdKean8CrHACwdkoyxE75Eq0DELBzOuuIGQ",
      tid: "ec3c7dee-d552-494b-a393-7f941a90b985",
      uti: "TX6vwvCZnESg7_WMqvEsAA",
      ver: "2.0",
    },
    idTokenClaims: {
      aud: "8ec1a8f5-2c92-4c20-be43-1675094b6da4",
      iss: "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
      iat: 1608214919,
      nbf: 1608214919,
      exp: 1608218819,
      aio: "ATQAy/8SAAAASWWAhdEHvWCFZop10VSRONoy/Z/IBi5tFPR0Bd58YJZ00VFVJ5PYAduNz8Id6x+N",
      email: "john.doe@zs.com",
      name: "John Doe",
      nonce: "77663aa4-6b68-409f-b71d-d9316a15f141",
      oid: "d13137ec-a8a2-4d03-b600-06975ff843b8",
      preferred_username: "john.doe@zs.com",
      rh: "0.AAAA7n087FLVS0mjk3-UGpC5hfWowY6SLCBMvkMWdQlLbaQOAAk.",
      sub: "WV1zIi1XpdKean8CrHACwdkoyxE75Eq0DELBzOuuIGQ",
      tid: "ec3c7dee-d552-494b-a393-7f941a90b985",
      uti: "TX6vwvCZnESg7_WMqvEsAA",
      ver: "2.0",
    },
    environment:
      "https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985/v2.0",
  };
};
