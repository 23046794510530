import React from "react";
import { Breadcrumb, BreadcrumbItem } from "@pai-ui/core/components/breadcrumb";
import { UserContext } from "@xai/providers/with-auth";
import { PastDeployments } from "./components/past-deployments";
import { DeploymentSchedular } from "./components/deployment-schedular";

export const IsolatedActionComponent = ({
  breadcrumbItem,
  setBreadcrumbActiveItem,
  segregatedVariantBankData,
}) => {
  const { experimentModule, /*  dispatch, */ format } =
    React.useContext(UserContext);

  return (
    experimentModule?.selectedExperiment?.id && (
      <>
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => setBreadcrumbActiveItem(null)}
            style={{ cursor: "pointer" }}
          >
            {experimentModule?.selectedExperiment?.name}
          </BreadcrumbItem>
          <BreadcrumbItem>{breadcrumbItem}</BreadcrumbItem>
        </Breadcrumb>
        {breadcrumbItem === "Past Deployments" && (
          <PastDeployments
            selectedExperiment={experimentModule?.selectedExperiment}
            format={format}
            segregatedVariantBankData={segregatedVariantBankData}
          />
        )}
        {breadcrumbItem === "Deployment Schedular" && (
          <DeploymentSchedular
            segregatedVariantBankData={segregatedVariantBankData}
          />
        )}
      </>
    )
  );
};
