import React from "react";

export const Panel = ({
  style,
  ref,
  id,
  round,
  center,
  flexible,
  border,
  children,
  scrollX,
  scrollY,
  stack,
  width,
  height,
  paddingX,
  paddingY,
  background,
  reliefX,
  wrap,
  disableOverflow,
  className,
  onClick,
}) => (
  <div
    onClick={onClick}
    ref={ref}
    id={id}
    className={`${className}${scrollX || scrollY ? " custom-scroll" : ""}${
      scrollX ? " custom-scroll-x" : ""
    }${scrollY ? " custom-scroll-y" : ""}`}
    style={{
      borderRadius: round ? 10 : 0,
      flexGrow: flexible ? 1 : 0,
      borderLeft:
        border == "all" || border === "left"
          ? "1px solid rgba(255,255,255,0.1)"
          : "0px",
      borderRight:
        border == "all" || border === "right"
          ? "1px solid rgba(255,255,255,0.1)"
          : "0px",
      borderTop:
        border == "all" || border === "top"
          ? "1px solid rgba(255,255,255,0.1)"
          : "0px",
      borderBottom:
        border == "all" || border === "bottom"
          ? "1px solid rgba(255,255,255,0.1)"
          : "0px",
      display: "flex",
      flexDirection: stack === "horizontal" ? "row" : "column",
      flexShrink: width || height ? 0 : 1,
      width,
      height,
      padding: `${paddingY ? "10px" : "0px"} ${paddingX ? "20px" : "0px"}`,
      background,
      justifyContent: center ? "center" : "start",
      alignItems: center ? "center" : "stretch",
      marginLeft: reliefX ? -20 : 0,
      marginRight: reliefX ? -20 : 0,
      position: "relative",
      flexWrap: wrap ? "wrap" : "no-wrap",
      maxHeight: disableOverflow ? height : "none",
      overflow: disableOverflow ? "hidden" : "",
      ...(style || {}),
    }}
  >
    {children}
  </div>
);

export const LargePanel = ({ children, height }) => (
  <div
    style={{
      height,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {children}
  </div>
);
