import { Col, Row } from "antd";
import React from "react";
import { ImageCardDisplay } from "../../components/image-card-display/ImageCardDisplay";
import { useGenerateContext } from "../../contexts/generate-context/GenerateContext";

export const DisplayGrid = (/* props */) => {
  const generateContext = useGenerateContext();
  return (
    <>
      <div>
        {(generateContext.images || []).map((batch, index) => (
          <div style={{ overflow: "hidden", margin: "40px 0px" }} key={index}>
            <h3 style={{ padding: "0px 15px 10px", margin: 0 }}>
              #{generateContext.images.length - index} - {batch[0].prompt}
            </h3>
            <Row gutter={[15, 15]}>
              {batch.map((item, index) => (
                <Col span={item.aspect_ratio === "16:10" ? 12 : 6} key={index}>
                  <div style={{}}>
                    <div style={{}}>
                      <ImageCardDisplay
                        onClick={() => {
                          generateContext.editing.startEdit(item);
                        }}
                        onEdit={() => {
                          generateContext.editing.startEdit(item);
                        }}
                        onSelect={() => {
                          generateContext.toggleItemSelect(item);
                        }}
                        onDelete={() => {}}
                        onExpand={() => {
                          generateContext.editing.startEdit(item);
                        }}
                        {...item}
                        selected={
                          generateContext.selected &&
                          generateContext.selected.findIndex(
                            (s) => s.id === item.id
                          ) != -1
                        }
                        radius={generateContext.config.radius}
                      ></ImageCardDisplay>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};
