import React, { useState, useEffect } from "react";
// import { message } from "antd";
import { Button, Select, Space } from "@pai-ui/core";
import { Link } from "react-router-dom";
import FirstStepRevamp from "./components/tag-line-revamp/FirstStepRevamp";
import SecondStepRevamp from "./components/tag-line-revamp/SecondStepRevamp";
import ThirdStepRevamp from "./components/tag-line-revamp/ThirdStepRevamp";
// import { getSubRoute, getS3Data } from "../transformer";
import { WaveLoading } from "styled-spinkit";
import MaxAIServiceHelper from "./service-helper";
import { UserContext } from "@xai/providers/with-auth";
import "./style.css";

const TagLineExperimentRevamp = ({ setSelectedTemplate, selectedTemplate }) => {
  const [TemplateFormConfig, setTemplateFormConfig] = useState();
  const [current, setCurrent] = useState(0);
  const [DefaultFormData, setDefaultFormData] = useState();
  const [NewCourselList, setNewCourselList] = React.useState();
  // const [selectedControl, setSelectedControl] = useState([]);
  const [firstStepTagLinesList, setfirstStepTagLinesList] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [formData, setFormData] = useState();
  const [showList, setShowList] = useState(0);
  const [noList, setNoList] = useState(0);
  const [prevControl, setPrevControl] = useState();
  const [ExpDataNew, setExpDataNew] = useState();

  const [modalValues, setModalValues] = useState({});

  const token = React.useContext(UserContext);
  const service = new MaxAIServiceHelper(token);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const sum = firstStepTagLinesList
    .filter((item) => item.fav === true)
    .reduce((accumulator, object) => {
      return accumulator + object.weight;
    }, 0);

  // const subRoute = getSubRoute();

  const Load = async () => {
    // await getS3Data("TemplateFormConfig.json")
    await service
      .getMaxAITemplateFormConfig()
      .then(async (res) => {
        console.log("Data from S3 TemplateFormConfig.json", res);
        setTemplateFormConfig(res);
      })
      .catch((err) => {
        console.log("Error from S3 TemplateFormConfig.json", err);
      });

    // await getS3Data("TemplateDefaultFormData.json")
    await service
      .getMaxAITemplateDefaultFormData()
      .then(async (res) => {
        console.log(
          "Data from S3 TemplateDefaultFormData.json",
          res,
          res?.["Airline"]
        );
        setDefaultFormData(res);
        // let locFormData = { ...res?.["Generic"] };
        let locFormData = { ...res?.["Airline"] };
        locFormData.count.minCount = 30;
        locFormData.count.maxCount = 50;
        setFormData(locFormData);
        setModalValues(
          locFormData?.advanced?.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.title]: item.defaultValue }),
            {}
          )
        );
      })
      .catch((err) => {
        console.log("Error from S3 TemplateDefaultFormData.json", err);
      });
    // await getS3Data("tagline_config.json")
    await service
      .getMaxAITaglineConfig()
      .then(async (res) => {
        // console.log("Data from S3 tagline_config.json", res, res?.["Generic"]);
        console.log("Data from S3 tagline_config.json", res, res?.["Airline"]);
        setExpDataNew(res);
      })
      .catch((err) => {
        console.log("Error from S3 tagline_config.json", err);
      });
  };

  useEffect(() => {
    Load();
  }, []);

  const steps = [
    {
      title: "",
      content: (
        <FirstStepRevamp
          ExpData={ExpDataNew && ExpDataNew.sections[1]}
          NewCourselList={NewCourselList}
          setNewCourselList={setNewCourselList}
          forthStepData={ExpDataNew && ExpDataNew.sections[2]}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <SecondStepRevamp
          ExpData={ExpDataNew && ExpDataNew.sections[0]}
          firstStepTagLinesList={firstStepTagLinesList}
          setfirstStepTagLinesList={setfirstStepTagLinesList}
          // setSelectedControl={setSelectedControl}
          formData={formData}
          setFormData={setFormData}
          showList={showList}
          setShowList={setShowList}
          responseList={responseList}
          setResponseList={setResponseList}
          noList={noList}
          setNoList={setNoList}
          modalValues={modalValues}
          setModalValues={setModalValues}
        />
      ),
    },
    {
      title: "Last",
      content: (
        <ThirdStepRevamp
          ExpData={ExpDataNew && ExpDataNew.sections}
          formData={formData}
          firstStepTagLinesList={firstStepTagLinesList}
          setfirstStepTagLinesList={setfirstStepTagLinesList}
          prevControl={prevControl}
          setPrevControl={setPrevControl}
          sum={sum}
        />
      ),
    },
  ];

  const selectHandler = (v, property) => {
    switch (property) {
      case "industry": {
        const tempFormData = DefaultFormData[v];
        tempFormData.count.minCount = 30;
        tempFormData.count.maxCount = 50;
        setModalValues(
          tempFormData?.advanced?.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.title]: item.defaultValue }),
            {}
          )
        );
        setFormData(tempFormData);
        setResponseList([]);
        setfirstStepTagLinesList([]);
        break;
      }
      default:
        break;
    }
    // locObj.count.generateCount = v
  };

  return (
    <>
      {TemplateFormConfig && DefaultFormData && formData && ExpDataNew ? (
        <div>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3 style={{ marginLeft: "10px" }}>
                <Space>
                  <span
                    className="select-link"
                    onClick={() => {
                      setSelectedTemplate(null);
                    }}
                  >
                    Max AI Apps
                  </span>
                  <span>/</span>
                  <span>{selectedTemplate?.title}</span>
                </Space>
              </h3>
            </div>
            <div>
              <Select
                style={{ width: "200px" }}
                placeholder={
                  ExpDataNew.sections[0].elements.find(
                    (it) => it.itemKey === "industry"
                  ).placeholder
                }
                options={
                  ExpDataNew.sections[0].elements.find(
                    (it) => it.itemKey === "industry"
                  ).options
                }
                defaultValue={
                  ExpDataNew.sections[0].elements.find(
                    (it) => it.itemKey === "industry"
                  ).defaultOption
                }
                value={formData.metaData.industry}
                onChange={(e) => selectHandler(e, "industry")}
              />
            </div>
          </div>
          <div className="steps-content" style={{ minHeight: "86vh" }}>
            {steps[current].content}
          </div>
          <div
            className="steps-action"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
                disabled={
                  current === 1 &&
                  (firstStepTagLinesList
                    .filter((it) => it.fav === true)
                    .some((it) => it.name.length === 0) ||
                    firstStepTagLinesList
                      .filter((it) => it.fav === true)
                      .some((it) => it.edit === true))
                }
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={
                  (current === 1 &&
                    firstStepTagLinesList.filter((it) => it.fav).length < 2) ||
                  (current === 2 &&
                    (firstStepTagLinesList
                      .filter((it) => it.fav === true)
                      .some((it) => it.name.length === 0) ||
                      firstStepTagLinesList
                        .filter((it) => it.fav === true)
                        .some((it) => it.edit === true))) ||
                  (current === 2 &&
                    firstStepTagLinesList.filter((it) => it.control === true)
                      .length === 0)
                }
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              // <Link
              //   to={`${process.env.REACT_APP_BASE_SUB_ROUTE}/${subRoute}/testing/tests`}
              // >
              <Link to={`../../tests`}>
                <Button
                  type="primary"
                  onClick={() => setSelectedTemplate(null)}
                  disabled={sum !== 100}
                >
                  Done
                </Button>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <WaveLoading color="#15afd0" />
      )}
    </>
  );
};

export default TagLineExperimentRevamp;
