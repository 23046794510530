// import {
//   CheckOutlined,
//   DeleteOutlined,
//   PlusCircleOutlined,
//   PushpinOutlined,
// } from "@ant-design/icons";
import {
  // Button,
  // Card,
  Col,
  // Collapse,
  // Input,
  // Popconfirm,
  // Popover,
  Row,
  Spin,
  Tabs,
} from "antd";
import React from "react";
import { ImageCardDisplay } from "../../design-gen-ai-dynamic-images/components/image-card-display/ImageCardDisplay";
import { GenerateImagesSidebar } from "../../design-gen-ai-dynamic-images/molecules/generate-images-sidebar/GenerateImagesSidebar";
// import { WaveLoading } from "styled-spinkit";
import { useGenerateContext } from "../../design-gen-ai-dynamic-images/contexts/generate-context/GenerateContext";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import { Timer } from "@xai/pages/design-gen-ai-dynamic-images/demo";

export const ImageComponentSummary = (props) => {
  // const [loading, setLoading] = React.useState(false);

  // const selectedVariant = props.data.variants
  //   ? props.data.variants[props.data.selected]
  //   : undefined;

  const generateContext = useGenerateContext();

  React.useEffect(() => {
    if (props.generators[0].orientation === "landscape") {
      generateContext.aspectRatio.set("16:10");
    }
  }, [props.data.generators]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          margin: -25,
        }}
      >
        {props.generators.map((generator, index) => (
          <GenerateImagesSidebar
            key={index}
            callback={(images) => {
              console.log("images", images);
              let originalData = { ...props.data };
              console.log(
                "existing",
                originalData.variants[props.data.selected][generator.key]
              );
              originalData.variants[props.data.selected][generator.key] =
                images;

              props.updateEntry("variants", originalData.variants);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const ImageComponent = (props) => {
  const generatorData = props.data.variants
    ? props.data.variants[props.data.selected]
    : undefined;

  const generateContext = useGenerateContext();

  if (!generatorData) {
    return <></>;
  }

  return (
    <div style={{ padding: "0px 20px", margin: "-25px -10px 0px" }}>
      <Tabs
        activeKey={`tab_${props.data.selected}`}
        tabBarStyle={{
          position: "sticky",
          top: -30,
          zIndex: 99,
          backgroundColor: "#121419",
          paddingTop: 15,
        }}
        onChange={(key) => {
          props.updateEntry("selected", parseInt(key.split("_")[1]));
        }}
      >
        {props.allData.variants.variants
          .filter((v, i) =>
            props.allData.variants.variants.length > 1 ? i > 0 : true
          )
          .map((variant, selected) => {
            const currentIndex =
              props.allData.variants.variants.length > 1
                ? selected + 1
                : selected;
            return (
              <Tabs.TabPane tab={variant.name} key={`tab_${currentIndex}`}>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  {generateContext.loading && (
                    <Row gutter={[20, 20]}>
                      {new Array(4).fill(0).map((a, i) => (
                        <Col
                          key={i}
                          span={
                            24 /
                            (generateContext.aspectRatio.value === "16:10"
                              ? 2
                              : 4)
                          }
                        >
                          <AspectRatio
                            width={parseInt(
                              generateContext.aspectRatio.value.split(":")[0]
                            )}
                            height={parseInt(
                              generateContext.aspectRatio.value.split(":")[1]
                            )}
                          >
                            <div
                              style={{
                                height: "100%",
                                backgroundColor: "rgba(255,255,255,0.1)",
                                borderRadius: 20,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Spin />
                              <Timer />
                            </div>
                          </AspectRatio>
                        </Col>
                      ))}
                    </Row>
                  )}
                  {props.generators.map((generator, index) => (
                    <Row gutter={[20, 20]} key={index}>
                      {generatorData[generator.key].map((item, tIndex) => (
                        <Col
                          span={props.orientation === "portrait" ? 8 : 12}
                          key={tIndex}
                        >
                          <ImageCardDisplay
                            {...item}
                            selected={item.selected}
                            selectOnly
                            onSelect={() => {
                              let variants = { ...props.data.variants };
                              variants[props.data.selected][generator.key][
                                tIndex
                              ].selected =
                                !variants[props.data.selected][generator.key][
                                  tIndex
                                ].selected;
                              props.updateEntry(
                                "variants",
                                JSON.parse(JSON.stringify(variants))
                              );
                              console.log(variants);
                            }}
                          ></ImageCardDisplay>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </div>
              </Tabs.TabPane>
            );
          })}
      </Tabs>
    </div>
  );

  // return (
  //   <div style={{ padding: "20px 0px" }}>
  //     <Collapse bordered={false} defaultActiveKey={"0"}>
  //       {props.generators.map((generator, index) => (
  //         <Collapse.Panel
  //           key={index.toString()}
  //           header={
  //             <div style={{ display: "flex", alignItems: "center" }}>
  //               <div style={{ flex: 1 }}>
  //                 <h4 style={{ margin: 0 }}>{generator.name}</h4>
  //                 <p
  //                   style={{
  //                     margin: 0,
  //                     fontSize: "14px",
  //                     color: "rgba(255,255,255,0.5)",
  //                   }}
  //                 >
  //                   {props.data.prompts[generator.key]}
  //                 </p>
  //               </div>
  //               <Button
  //                 type="ghost"
  //                 style={{ marginRight: 10 }}
  //                 onClick={(e) => {
  //                   e.stopPropagation();
  //                 }}
  //               >
  //                 Edit Prompt
  //               </Button>
  //               <Button
  //                 type="primary"
  //                 style={{ marginRight: 20 }}
  //                 onClick={(e) => {
  //                   e.stopPropagation();
  //                 }}
  //               >
  //                 Generate
  //               </Button>
  //             </div>
  //           }
  //         >
  //           <div style={{ padding: 20 }}>
  //             <Row gutter={[20, 20]}>
  //               {generatorData[generator.key].map((item, tIndex) => (
  //                 <Col span={props.orientation === "portrait" ? 8 : 12}>
  //                   <ImageCardDisplay
  //                     {...item}
  //                     selected={item.selected}
  //                     selectOnly
  //                     onSelect={() => {
  //                       let variants = { ...props.data.variants };
  //                       variants[props.data.selected][generator.key][
  //                         tIndex
  //                       ].selected =
  //                         !variants[props.data.selected][generator.key][tIndex]
  //                           .selected;
  //                       props.updateEntry(
  //                         "variants",
  //                         JSON.parse(JSON.stringify(variants))
  //                       );
  //                       console.log(variants);
  //                     }}
  //                   ></ImageCardDisplay>
  //                 </Col>
  //               ))}
  //             </Row>
  //           </div>
  //         </Collapse.Panel>
  //       ))}
  //     </Collapse>
  //   </div>
  // );
};
