import React from "react";
import * as Comps from "./components";

const DesignExperiment = () => {

    return (
        <div>
            <Comps.CardLayout />
        </div>
    );
};

export default DesignExperiment;