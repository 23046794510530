import React, { useState, useEffect } from "react";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import { Input } from '@pai-ui/core/components/input';
import { RuleBuilder, RuleFieldType } from '@pai-ui/core/components/rule-builder';
import { Select } from '@pai-ui/core/components/select';
import {
  ZsSpace,
  ZsInput,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { WaveLoading } from "styled-spinkit";
import { Button } from "@pai-ui/core/components/button";

const config2 = [
  {
    key: 'id',
    id: 1,
    placeholder: 'Enter Variant Name',
    type: RuleFieldType.textField,
    width: "150px",

  },
  {
    key: 'testcontol',
    id: 3,

    type: RuleFieldType.select,
    options: [
      {
        id: 'test',
        name: 'Test',
      },
      {
        id: 'control',
        name: 'Control',
      }
    ]


  },
  {
    key: 'id1',
    id: 2,
    placeholder: 'Variant Percent',
    width: "150px",
    type: RuleFieldType.numberField,
    postfix: '%'
  }
]

const DrawerComponent = (item) => {
  console.log('aayushnoode', item.datad)
  const [value, setValue] = useState(1)
  const [technique, selectedTechnique] = useState()
  const [variable, setvariable] = useState()
  const [config, setConfig] = useState()
  const [vari, setvari] = useState([1, 1])
  const [variSum, setVariSum] = useState([])
  const [sum, setSum] = useState(0)
  useEffect(() => {


    console.log('ietemmm', item, item.templateSelected)
    if (item.type === "variant") {

      setValue(2)

    }
    if (item.view !== "new") {
      selectedTechnique(item.item.technique ? item.item.technique : 1)

      setvariable(item.item.variable ? item.item.variable : 1)
    }


  }, [])

  const checkIsChildVariant = (parent_id, parent_path, rawData = item.datad) => {
    if (rawData.id == parent_id) {
      return rawData.children
    } else if (parent_path) {
      let locArray = rawData.children[parent_path[0]].children[parent_path[1]].children
      return locArray
    } else if (rawData.children.findIndex(item => item.id == parent_id) != -1) {
      let locChildren = rawData.children[rawData.children.findIndex(item => item.id == parent_id)].children
      return locChildren
    }
  }
  const load = async () => {
    setConfig(item.templateSelected?.template_dendogram_rules_table?.template_dendogram_rules?.config)
  }
  useEffect(() => {
    load()
  }, []);

  const handleChange = (v, index) => {

    if (v.rules[0].value.id1 != undefined) {
      let varaintIndex = variSum.findIndex(it => it.id === index);
      if (varaintIndex !== -1) {
        let varArray = variSum;
        varArray[varaintIndex].value = v.rules[0].value.id1;
        setVariSum(varArray);
      }
      else {
        setVariSum([...variSum, { id: index, value: v.rules[0].value.id1 }])
      }
      let locsum = 0;
      variSum.forEach(item => locsum += Number(item.value));
      setSum(locsum)
    }
    console.log('v.ru', v.rules[0].value.id1, variSum, sum)
  }

  return (config ?
    <>
      {value == 1 ?
        (
          <>
            <span>Variant Groups</span>
            {
              item.item.parent_id && checkIsChildVariant(item.item.parent_id, item.item.parent_path) ?
                <>
                  {
                    checkIsChildVariant(item.item.parent_id, item.item.parent_path).map((i, index) => (
                      <>
                        <Row>
                          <Row style={{ paddingTop: "15px" }}>
                            <RuleBuilder
                              // ruleInputPlaceholder="Custom placeholder"
                              onChange={(v) => handleChange(v, index)}
                              withRelationshipInput={false}
                              withAddRuleButton={false}
                              generateRuleName={`V${index + 1}`}
                              defaultValue={item.view == "new" ? {
                                rules: [
                                  {
                                    name: `V${index + 1}`,
                                    value: {
                                      id: "Test",
                                      id1: 0,
                                      testcontol: "Test"
                                    }
                                  }
                                ]
                              } : i.value ?
                                { relationship: i.value.relationship, rules: i.value.rules.map((x) => { return { name: `V${index + 1}`, value: x } }) }
                                :
                                {

                                }}
                              config={config2}

                            />
                          </Row>
                          <Row style={{ paddingTop: "15px" }}>
                            <Col span={8} >
                              Randomization Technique
                            </Col>
                            <Col span={14} offset={2}>
                              <Select
                                placeholder="Select Randomization​ Technique"
                                value={technique}
                                options={[
                                  {
                                    id: 1,
                                    name: 'Stratified Sampling'
                                  },
                                  {
                                    id: 2,
                                    name: 'Weighted Sampling'
                                  },
                                  {
                                    id: 3,
                                    name: 'Simple Random Sampling'
                                  },

                                ]}
                                onChange={selectedTechnique}
                                style={{ width: "250px" }}
                              />

                            </Col>
                            {(technique == 1 || technique == 2) &&
                              <Row style={{ paddingTop: "15px" }}>
                                <Col span={8} >
                                  Sampling Variables
                                </Col>
                                <Col span={8} offset={4}>
                                  <Select
                                    placeholder="Select  Sampling Variables"
                                    value={variable}

                                    maxTagCount={"responsive"}
                                    options={[
                                      {
                                        id: 1,
                                        name: 'Age'
                                      },
                                      {
                                        id: 2,
                                        name: 'Gender'
                                      },
                                      {
                                        id: 3,
                                        name: 'City'
                                      },
                                      {
                                        id: 4,
                                        name: 'State'
                                      },
                                      {
                                        id: 5,
                                        name: "New vs Returning User"
                                      }

                                    ]}
                                    style={{ width: "250px" }}
                                    onChange={setvariable}
                                  />
                                </Col>

                              </Row>}
                            {technique == 2 &&
                              (<Row>
                                {variable == 1 &&
                                  <Col span={24}>
                                    <ZsSpace>
                                      {[
                                        {
                                          id: 1,
                                          label: "01-10",
                                          type: "Text",
                                        },
                                        {
                                          id: 2,
                                          label: "10-20",
                                          type: "Text",
                                        },
                                        {
                                          id: 3,
                                          label: "20-30",
                                          type: "Text",
                                        },
                                        {
                                          id: 4,
                                          label: "30-40",
                                          type: "Text",
                                        },
                                      ].map((b, i) => (
                                        <div style={{ display: "grid" }} key={i}>
                                          {!b.hideLabel && (
                                            <span style={{ color: "#0988ab" }}>{b.label}</span>
                                          )}
                                          <ZsInput
                                            style={{
                                              width: "90%",
                                            }}
                                            placeholder={"Enter %"}
                                            defaultValue={item.item.age ? item.item.age[i] : ""}
                                          />
                                        </div>
                                      ))}
                                    </ZsSpace>
                                  </Col>

                                }
                              </Row>
                              )}
                            {technique == 2 &&
                              (<Row>
                                {variable == 2 &&
                                  <Col span={24}>
                                    <ZsSpace>
                                      {[
                                        {
                                          id: 1,
                                          label: "Female",
                                          type: "Text",
                                        },
                                        {
                                          id: 2,
                                          label: "Male",
                                          type: "Text",
                                        },
                                        {
                                          id: 3,
                                          label: "Unknown",
                                          type: "Text",
                                        },

                                      ].map((b) => (
                                        <div style={{ display: "grid" }} key={i}>
                                          {!b.hideLabel && (
                                            <span style={{ color: "#0988ab" }}>{b.label}</span>
                                          )}
                                          <ZsInput
                                            style={{
                                              width: "90%",
                                            }}
                                            placeholder={"Enter %"}

                                          />
                                        </div>
                                      ))}
                                    </ZsSpace>
                                  </Col>

                                }
                              </Row>
                              )}

                          </Row>

                        </Row>
                      </>
                    ))
                  }
                  <Row style={{ paddingTop: "15px" }}>
                    <div>
                      <p style={{ color: "red" }}>{(sum !== 100) ? "Sum of Variant Percentage should be 100" : ""}</p>
                    </div>
                  </Row>
                </> :
                <>
                  <Row style={{ paddingTop: "15px" }}>
                    <Col span={8} >
                      Audience Name
                    </Col>
                    <Col span={12} offset={2}>
                      <Input value={item.view == "new" ? "" : item.item.name} />
                    </Col>

                  </Row>
                  <Row>
                    <Row style={{ paddingTop: "15px" }}>
                      {console.log('Rulessss', item.item.value.rules.map((x, i) => { return { name: 'R' + String(i + 1), value: x } }), item.item.value.rules.map((x, i) => { return { name: 'R' + String(i + 1), value: x } }))}
                      <RuleBuilder
                        // ruleInputPlaceholder="Custom placeholder"
                        defaultValue={item.view == "new" ? {} : item.item.value ?
                          { relationship: item.item.value.relationship, rules: item.item.value.rules.map((x, i) => { return { name: 'R' + String(i + 1), value: x } }) }
                          :
                          {

                          }}
                        config={config}

                      />
                    </Row>
                  </Row>
                </>}
          </>
        ) : (
          <>
            <span>Variant Groups</span>
            {
              vari.map((i, index) => (
                <div key={index}>
                  <Row>
                    <Row style={{ paddingTop: "15px" }}>
                      {console.log('Rulessss', item.item.value.rules)}
                      <RuleBuilder
                        // ruleInputPlaceholder="Custom placeholder"
                        generateRuleName={`V${index + 1}`}
                        config={config2}
                        withRelationshipInput={false}
                        onChange={(v) => handleChange(v, index)}
                        withAddRuleButton={false}
                        defaultValue={item.view == "new" ? {
                          rules: [index == 0 ?
                            {
                              name: `V${index + 1}`,
                              value: {
                                id: "Test",
                                id1: 0,
                                testcontol: "Test"
                              }
                            } : {
                              name: `V${index + 1}`,
                              value: {
                                id: "Control",
                                id1: 0,
                                testcontol: "Control"
                              }
                            }
                          ]
                        } : item.item.value ?
                          { relationship: item.item.value.relationship, rules: item.item.value.rules.map((x, i) => { return { name: 'R' + String(i + 1), value: x } }) }
                          :
                          {

                          }}

                      />
                    </Row>
                  </Row>
                  <Row style={{ paddingTop: "15px" }}>
                    <Col span={8} >
                      Randomization Technique
                    </Col>
                    <Col span={14} offset={2}>
                      <Select
                        placeholder="Select Randomization​ Technique"
                        value={technique}
                        options={[
                          {
                            id: 1,
                            name: 'Stratified Sampling'
                          },
                          {
                            id: 2,
                            name: 'Weighted Sampling'
                          },
                          {
                            id: 3,
                            name: 'Simple Random Sampling'
                          },

                        ]}
                        onChange={selectedTechnique}
                        style={{ width: "250px" }}
                      />
                    </Col>

                  </Row>
                  {(technique == 1 || technique == 2) &&
                    <Row style={{ paddingTop: "15px" }}>
                      <Col span={8} >
                        Sampling Variables
                      </Col>
                      <Col span={14} offset={2}>
                        <Select
                          placeholder="Select  Sampling Variables"
                          value={variable}

                          maxTagCount={"responsive"}
                          options={[
                            {
                              id: 1,
                              name: 'Age'
                            },
                            {
                              id: 2,
                              name: 'Gender'
                            },
                            {
                              id: 3,
                              name: 'City'
                            },
                            {
                              id: 4,
                              name: 'State'
                            },
                            {
                              id: 5,
                              name: "New vs Returning User"
                            }

                          ]}
                          style={{ width: "250px" }}
                          onChange={setvariable}
                        />
                      </Col>

                    </Row>}
                  {technique == 2 &&
                    (<Row>
                      {variable == 1 &&
                        <Col span={24}>
                          <ZsSpace>
                            {[
                              {
                                id: 1,
                                label: "01-10",
                                type: "Text",
                              },
                              {
                                id: 2,
                                label: "10-20",
                                type: "Text",
                              },
                              {
                                id: 3,
                                label: "20-30",
                                type: "Text",
                              },
                              {
                                id: 4,
                                label: "30-40",
                                type: "Text",
                              },
                            ].map((b, i) => (
                              <div style={{ display: "grid" }} key={i}>
                                {!b.hideLabel && (
                                  <span style={{ color: "#0988ab" }}>{b.label}</span>
                                )}
                                <ZsInput
                                  style={{
                                    width: "90%",
                                  }}
                                  placeholder={"Enter %"}
                                  defaultValue={item.item.age ? item.item.age[i] : ""}
                                />
                              </div>
                            ))}
                          </ZsSpace>
                        </Col>

                      }
                    </Row>
                    )}
                  {technique == 2 &&
                    (<Row>
                      {variable == 2 &&
                        <Col span={24}>
                          <ZsSpace>
                            {[
                              {
                                id: 1,
                                label: "Female",
                                type: "Text",
                              },
                              {
                                id: 2,
                                label: "Male",
                                type: "Text",
                              },
                              {
                                id: 3,
                                label: "Unknown",
                                type: "Text",
                              },

                            ].map((b) => (
                              <div style={{ display: "grid" }} key={i}>
                                {!b.hideLabel && (
                                  <span style={{ color: "#0988ab" }}>{b.label}</span>
                                )}
                                <ZsInput
                                  style={{
                                    width: "90%",
                                  }}
                                  placeholder={"Enter %"}

                                />
                              </div>
                            ))}
                          </ZsSpace>
                        </Col>

                      }
                    </Row>
                    )}
                </div>
              ))
            }
            <Row style={{ paddingTop: "15px" }}>
              <Button ghost onClick={() => { setvari([...vari, Number(vari + 1)]) }}>+ Add Variant</Button>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <div>
                <p style={{ color: "red" }}>{(sum !== 100) ? "Sum of Variant Percentage should be 100" : ""}</p>
              </div>
            </Row>
          </>
        )}
    </> : <WaveLoading />
  );
}
export default DrawerComponent;