import React from "react";
// import { getSubRoute } from "../../../transformer.js";
import "./index.css";

const TemplateCard = ({ templateData }) => {
  // const subRoute = getSubRoute();

  return (
    <div
      style={{ cursor: "pointer" }}
      className="max-aiapps-module-custom-card"
    >
      <div
        style={{
          position: "relative",
          // backgroundImage: `url(https://d3mcp2vyrro05u.cloudfront.net/gen_ai/Images/${templateData?.img})`,
          backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/img/data/${templateData?.img})`,
          backgroundSize: "cover",
          marginBottom: "20px",
          borderRadius: "20px",
          paddingBottom: "129%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "27px",
          }}
        >
          <span
            style={{
              fontSize: "19px",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {templateData?.title}
          </span>
          <br />
          <span
            style={{
              fontSize: "11px",
              marginBottom: "3px",
              textTransform: "uppercase",
              textAlign: "left",
              color: "#fff",
            }}
          >
            {templateData?.description}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
