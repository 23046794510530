/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "@xai/providers/with-auth";
import WaveLoader from "@xai/assets/generic-components/wave-loader";
import {
  ZsSuccessMessage,
  ZsErrorMessage,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { Button } from "@pai-ui/core/components/button";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { getFavoriteConfig } from "@xai/pages/measure-test-library/transformer";
import MeasureServiceHelper from "@xai/pages/measure-test-library/service-helper";

export const loadSelectedExperimentData = async (
  experiment,
  finalobj,
  dispatch
) => {
  console.log("loadSelectedExperimentData Result", experiment);
  if (
    // For these urls, we dont need to call the additional info required by result screen
    /**
     *  "/tests/list"
     *  "/design/variant-bank-approval"
     */
    location.pathname !== "/tests/list" &&
    location.pathname !== "/design/variant-bank-approval"
  ) {
    // const userState = React.useContext(UserContext);
    const service = new MeasureServiceHelper(
      "userState.token" /* userState.token */
    );

    // console.log("loadSelectedExperimentData Result");
    await service
      .getSelectedExperiment(experiment?.id)
      .then(async (result) => {
        console.log("getSelectedExperiment Result", result);
        finalobj = {
          ...finalobj,
          loading: false,
          selectedExperiment: {
            ...experiment,
            ...result?.items?.[0],
          },
        };
        await dispatch({
          type: "experimentModule",
          payload: finalobj,
        });
        console.log("with Exp", finalobj);
      })
      .catch((err) => {
        console.log("getSelectedExperiment Error", err);
        // setError(true);
      });
  } else {
    finalobj = {
      ...finalobj,
      loading: false,
      selectedExperiment: {
        ...experiment,
      },
    };
    await dispatch({
      type: "experimentModule",
      payload: finalobj,
    });
  }
};

const withExperiment = (Component) => (props) => {
  const userState = React.useContext(UserContext);
  const service = new MeasureServiceHelper(userState.token);

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [onMarkFavFinishLoad, setOnMarkFavFinishLoad] = useState(false);

  const load = async () => {
    // #region - api integration Start
    setLoading(true);
    setError(false);
    // console.log("userState.experimentModule", userState.experimentModule);
    let finalobj = {
      ...userState?.experimentModule,
      loading: false,
      // Reset state for below props when loading for first time or coming to listing screens
      analyze_screen_selected_cohort: null,
      analyze_screen_selected_task: null,
      result_screen_selected_cohort: null,
    };
    console.log(
      "userState.experimentModule inside load before update",
      userState.experimentModule.selectedExperiment
    );
    if (
      // TODO - update url path
      // location.pathname === "/design/experiment" || // load experimentList when landing screen is loaded
      location.pathname === "/tests/list" || // load experimentList when landing screen is loaded
      location.pathname === "/design/variant-bank-approval" || // load experimentList when variant-bank screen is loaded
      !userState?.experimentModule?.experimentList
    ) {
      console.log(
        "userState.experimentModule ,inside if; ",
        userState.experimentModule
      );
      await service
        .getAllExperiments()
        .then(async (res) => {
          // console.log("userState.experimentModule inside load before update",userState.experimentModule.selectedExperiment)
          //  Should reset & not retain selected Experiment

          finalobj = {
            ...finalobj,
            experimentList: res?.items,
          };
          // filteredExperimentList : Experiments with status : Completed, Active, Published
          let filteredExperimentList = res?.items?.filter((experiment) => {
            return [1, 3, 6].indexOf(experiment.status) > -1; // Experiment status : Completed, Active, Published
          })
          ?.sort((a, b) => a.name.localeCompare(b.name));
          await loadSelectedExperimentData(
            userState?.experimentModule?.selectedExperiment
              ? userState?.experimentModule?.selectedExperiment
              : filteredExperimentList?.length > 0
              ? filteredExperimentList?.[0]
              : res?.items?.[0],
            finalobj,
            userState.dispatch
          );
        })
        .catch((err) => {
          console.log("getAllExperimentsData Error", err);
          setError(true);
        });
    } else {
      await loadSelectedExperimentData(
        userState?.experimentModule?.selectedExperiment,
        finalobj,
        userState.dispatch
      );
    }
    await setLoading(false);

    // #endregion - api integration End
  };

  const handleSetFavorite = async (id, value) => {
    setOnMarkFavFinishLoad(true);
    const msg = [
      "Experiment favorite status updated!",
      "Unable to make changes",
      "Some records not updated!",
    ];

    const apiConfig = getFavoriteConfig(id, value);
    let status = 1;
    let result = false;
    await service
      .setFavorite(apiConfig)
      .then(async (response) => {
        const { is_favorite } = response;
        if (is_favorite === value) {
          status = 0;
          result = true;

          const experimentData = userState.experimentModule.experimentList;
          console.log("fav checkpoint", experimentData, value);

          // update the respective experiment
          let tempData = JSON.parse(JSON.stringify(experimentData));
          tempData.find((x) => x.id === id).is_favorite = value;
          console.log("fav updated data", tempData);
          let finalobj = {
            ...userState.experimentModule,
            experimentList: tempData,
          };
          console.log("withExperiment handleSetFavorite finalobj", finalobj);
          await userState.dispatch({
            type: "experimentModule",
            payload: finalobj,
          });
        }
      })
      .catch((err) => {
        console.log("loadSegmentImpactExplorerTabData Error", err);
      })
      .finally(() => {
        showToasterMessage(result, msg[status]);
        setOnMarkFavFinishLoad(false);
      });
  };

  const showToasterMessage = (result, msg) => {
    if (result) {
      ZsSuccessMessage(msg);
    } else {
      ZsErrorMessage(msg);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) return <WaveLoader />;

  if (error) {
    return (
      <LegacyNoData height={"95vh"} error={true}>
        <Button onClick={() => load()}>Try Again</Button>
      </LegacyNoData>
    );
  }

  return (
    <Component
      {...props}
      handleSetFavorite={handleSetFavorite}
      onMarkFavFinishLoad={onMarkFavFinishLoad}
    />
  );
};

export default withExperiment;
