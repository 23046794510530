/* eslint-disable no-unused-vars */
import React from "react";
import "./../style.css";

import { Tag } from '@pai-ui/core/components/tag';

export const ExpressionVariable = (props) => {
  return (
    <div className='expression-var-container'>
      {props.aggregator && (
        <div style={{ display: "block" }}>
          <div className="aggregator">{props.aggregator}</div>
        </div>
      )}
      <div>
        <VariableSelector {...props} />
      </div>
      <div>
        <div className="variant">{props.variantName}</div>
      </div>
    </div>

    // <Row style={{ width: "135px" }}>
    //   {props.aggregator && <Col span={5}>
    //     <div className="aggregator">{props.aggregator}</div>
    //   </Col>}
    //   <Col span={props.aggregator?15:20}>
    //     <VariableSelector {...props} />
    //   </Col>
    //   <Col span={4}>
    //     <div className="variant">{props.variantName}</div>
    //   </Col>
    // </Row>
  );
};

const VariableSelector = (props) => {
  return (
    <>
      <Tag className={`kpi-tag-${props.selected ? "selected" : "default"}`}>
        {props.label}
      </Tag>
    </>
  );
};
