const statusEnum = {
  1: { name: "Completed", color: "#01872a" },
  2: { name: "Inactive", color: "#920912" },
  3: { name: "Active", color: "#15afd0" },
  4: { name: "Draft", color: "#acacac" },
  5: { name: "Error", color: "#920912" },
  6: { name: "Published", color: "#01872a" },
  7: { name: "Processing", color: "#15afd0" },
  8: { name: "Queued", color: "#595959" },
  9: { name: "Deployed", color: "#01872a" },
  10: { name: "Deactivated", color: "#920912" }
};

export { statusEnum };
