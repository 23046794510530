import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { createClient } from "@supabase/supabase-js";
import { Button, Col, Row } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import ChartWrapper from "../../../../assets/generic-components/visualization/components/wrapper";
import { AspectRatio } from "../aspect-ratio/AspectRatio";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const supabase = createClient(
  "https://ewzgtygubgkswcptyrvu.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV3emd0eWd1Ymdrc3djcHR5cnZ1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM5MjY1OTAsImV4cCI6MTk4OTUwMjU5MH0.KO39_u_rym23UjbHVCAoNs4336nMlM3k4p2x2TirP6E"
);

export const ImageUploader = (props) => {
  const customRequest = ({ file, onSuccess, onError, onProgress }) => {
    const name = `uploads/${file.name.split(".")[0]}-${uuidv4()}.${file.name
      .split(".")
      .pop()}`;
    const upload = supabase.storage.from("gen-ai-images").upload(name, file, {
      onProgress: (event) => {
        const percent = Math.round((event.loaded / event.total) * 100);
        onProgress({ percent });
      },
    });

    upload
      .then(({ data, error }) => {
        if (error) {
          throw error;
        }
        onSuccess(data);
      })
      .catch((error) => {
        console.error(error);
        onError(error.message);
      });
  };

  return (
    <>
      {!!props.image && !props.multiple && (
        <div style={{ position: "relative" }}>
          <img src={props.image} style={{ width: "100%" }} />
          <div style={{ position: "absolute", top: 5, right: 5 }}>
            <Button
              onClick={() => {
                props.clear();
              }}
              icon={<DeleteOutlined />}
            >
              Clear
            </Button>
          </div>
        </div>
      )}
      {!!props.image && props.multiple && (
        <ChartWrapper>
          {({ width }) => (
            <Row gutter={[20, 20]} style={{ width }}>
              {props.image.map((image, keyIndex) => (
                <Col span={4} key={keyIndex}>
                  <div
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "rgba(255,255,255,0.05)",
                      borderRadius: 10,
                    }}
                  >
                    <AspectRatio width={5} height={5}>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${image})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    </AspectRatio>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={() => {
                          props.clear();
                        }}
                        icon={<DeleteOutlined />}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
              <Col span={6}>
                <Dragger
                  {...{
                    name: "file",
                    multiple: props.multiple,
                    customRequest,
                    onChange(info) {
                      const { status } = info.file;
                      if (status === "done") {
                        props.onComplete(
                          `https://ewzgtygubgkswcptyrvu.supabase.co/storage/v1/object/public/gen-ai-images/${info.file.response?.path}`
                        );
                      } else if (status === "error") {
                        //   message.error(`${info.file.name} file upload failed.`);
                      }
                    },
                  }}
                >
                  <div style={{ padding: 20 }}>
                    <p className="ant-upload-drag-icon">
                      <PlusOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag {props.multiple ? "files" : "a file"} to
                      upload
                    </p>
                  </div>
                </Dragger>
              </Col>
            </Row>
          )}
        </ChartWrapper>
      )}
      {!props.image && (
        <Dragger
          {...{
            name: "file",
            multiple: props.multiple,
            customRequest,
            onChange(info) {
              const { status } = info.file;
              if (status === "done") {
                props.onComplete(
                  `https://ewzgtygubgkswcptyrvu.supabase.co/storage/v1/object/public/gen-ai-images/${info.file.response?.path}`
                );
              } else if (status === "error") {
                //   message.error(`${info.file.name} file upload failed.`);
              }
            },
          }}
        >
          <div style={{ padding: 20 }}>
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag {props.multiple ? "files" : "a file"} to upload
            </p>
            <p className="ant-upload-hint">
              {props.multiple ? "" : "Supports single file only. "}Strictly
              prohibit from uploading company data or other band files
            </p>
          </div>
        </Dragger>
      )}
    </>
  );
};
