import React from "react";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import { Button } from '@pai-ui/core/components/button';
import { Loading } from '@pai-ui/core/components/loading';
import * as AntIcons from "@ant-design/icons";
import { UserContext } from "@xai/providers/with-auth";
import DesignServiceHelper from "@xai/pages/design-experiment/service-helper";
import * as Components from "./components";
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig.js";

const SubjectLineExperiment = () => {

    const [currentStep, setCurrentStep] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const [definition, setdefinition] = React.useState();

    const [data, setData] = React.useState({});

    const { token } = React.useContext(UserContext);
    const service = new DesignServiceHelper(token);
    const { config } = useSubjectLineFormConfig();

    React.useEffect(async () => {
        setLoading(true);
        await service.getSpecificSubjectLineConfig()
            .then(async (res) => {
                console.log('getSpecificSubjectLineConfig Data', res);
                setdefinition(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getSpecificSubjectLineConfig Error', err);
                setLoading(false);
            });
    }, []);

    const getDefaultData = (definition) => {
        let defaultData = {};

        definition.steps.forEach((step) => {
            defaultData[step.key] = step.defaultData;
        });

        return defaultData;
    };

    const updateEntry = (section, key, value) => {
        let newD = { ...data };
        if (!newD[section]) {
            newD[section] = {};
        }
        newD[section][key] = value;

        console.log('newD', newD, key, value);
        setData((d) => {
            let newD = { ...d };
            if (!newD[section]) {
                newD[section] = {};
            }
            newD[section][key] = value;
            return newD;
        });
    };

    React.useEffect(() => {
        if (definition) {
            setData(getDefaultData(definition));
        }
    }, [definition]);

    if (!definition) {
        return <></>;
    }

    const step = definition?.steps?.[currentStep];
    const sectionData = data?.[step?.key] || {};
    const MainComponent = Components?.[step.component];
    const SummaryComponent = Components?.[step.summaryComponent];

    console.log('stepdata', config);

    const props = {
        ...definition?.steps?.[currentStep]?.props,
        data: sectionData,
        allData: data,
        allProps: definition?.steps?.map((s) => ({ key: s?.key, props: s?.props })),
        updateEntry: (key, value) => {
            updateEntry(step?.key, key, value);
        },
        updateParentEntry: updateEntry,
        updateEntries: (keyValues) => {
            console.log("KeyValues", keyValues);
            updateEntries(step?.key, keyValues);
        },
        currentStep: currentStep,
        setCurrentStep: setCurrentStep
    };

    const updateEntries = (section, keyValues) => {
        setData((d) => {
            let newD = JSON.parse(JSON.stringify({ ...d }));
            if (!newD[section]) {
                newD[section] = {};
            }
            keyValues.forEach((kv) => {
                console.log(kv.key, kv.value);
                newD[section][kv.key] = kv.value;
            });
            return newD;
        });
    };

    return (
        <>
            {
                loading ?
                    <><Loading /></> :
                    <>
                        {/* <div
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    inset: 0,
                    padding: "0px 1.1888rem",
                    overflow: "hidden",
                    maxWidth: "100%",
                    height: "100%",
                }}
            >
                <Row style={{ height: "100%" }}>
                    <Col flex={"100px"} style={{ paddingTop: 25 }}>
                        {definition?.steps?.map((step, index) => {
                            const Icon = AntIcons?.[step.icon];
                            return (
                                <div
                                    key={index}
                                    style={{ marginBottom: 5, cursor: "pointer" }}
                                    onClick={() => {
                                        setCurrentStep(index);
                                    }}
                                >
                                    <AspectRatio width={1} height={0.9}>
                                        <div
                                            style={{
                                                background:
                                                    index === currentStep
                                                        ? "rgba(255,255,255,0.03)"
                                                        : "rgba(255,255,255,0)",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                position: "absolute",
                                                inset: 0,
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                color: index === currentStep ? "#15afd0" : undefined,
                                            }}
                                        >
                                            <Icon style={{ fontSize: "24px", marginTop: 5 }} />
                                            <p
                                                style={{
                                                    marginBottom: 0,
                                                    textAlign: "center",
                                                    marginTop: 5,
                                                }}
                                            >
                                                {step.title}
                                            </p>
                                        </div>
                                    </AspectRatio>
                                </div>
                            );
                        })}
                    </Col>
                    <Col
                        span={!MainComponent ? undefined : 6}
                        flex={!MainComponent ? 1 : undefined}
                        style={{
                            flexShrink: 1,
                            backgroundColor: "rgba(255,255,255,0.03)",
                        }}
                    >
                        {!!MainComponent && (
                            <ChartWrapper>
                                {({ height }) => (
                                    <div
                                        style={{
                                            height,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div style={{ flex: 1, overflow: "auto", padding: 25 }}>
                                            {SummaryComponent && <SummaryComponent {...props} />}
                                        </div>
                                    </div>
                                )}
                            </ChartWrapper>
                        )}
                        {!MainComponent && (
                            <ChartWrapper>
                                {({ height, width }) => (
                                    <div
                                        style={{
                                            height,
                                            width: width - 5,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "100%",
                                                overflow: "auto",
                                                padding: 25,
                                            }}
                                        >
                                            {SummaryComponent && <SummaryComponent {...props} />}
                                        </div>
                                    </div>
                                )}
                            </ChartWrapper>
                        )}
                    </Col>
                    {!!MainComponent && (
                        <Col
                            flex={1}
                            style={{
                                flexShrink: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    marginRight: "-1.188rem",
                                }}
                            >
                                <ChartWrapper>
                                    {({ height }) => (
                                        <div
                                            style={{
                                                overflow: "auto",
                                                height,
                                                padding: 25,
                                                paddingTop: 30,
                                            }}
                                        >
                                            {MainComponent && props && <MainComponent {...props} />}
                                        </div>
                                    )}
                                </ChartWrapper>
                            </div>
                            <div
                                style={{
                                    height: 70,
                                    flexShrink: 0,
                                    display: "block",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                    borderTop: "1px solid rgba(255,255,255,0.25)",
                                }}
                            >
                                {!!MainComponent && (
                                    <div
                                        style={{
                                            padding: "17px 25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setCurrentStep(currentStep - 1);
                                            }}
                                            type="ghost"
                                            style={{ marginRight: 20 }}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setCurrentStep(currentStep + 1);
                                            }}
                                            type="primary"
                                            style={{}}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </div> */}
                        <div>
                            <Row span={22}>
                                <Col span={2}>
                                    {definition?.steps?.map((step, index) => {
                                        const Icon = AntIcons?.[step.icon];
                                        return (
                                            <div
                                                key={index}
                                                style={{ marginBottom: 5, cursor: "pointer" }}
                                                onClick={() => {
                                                    setCurrentStep(index);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        background:
                                                            index === currentStep
                                                                ? "rgba(255,255,255,0.05)"
                                                                : "rgba(255,255,255,0)",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        color: index === currentStep ? "#15afd0" : undefined,
                                                        padding: "20px",
                                                        borderRadius: "15px"
                                                    }}
                                                >
                                                    <Icon style={{ fontSize: "24px", marginTop: 5 }} />
                                                    <p
                                                        style={{
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            marginTop: 5,
                                                        }}
                                                    >
                                                        {step.title}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Col>
                                {
                                    SummaryComponent && !MainComponent &&
                                    <Col span={22}>
                                        {SummaryComponent && <SummaryComponent {...props} />}
                                    </Col>
                                }
                                {
                                    !SummaryComponent && MainComponent &&
                                    <Col span={22}>
                                        {MainComponent && <MainComponent {...props} />}
                                    </Col>
                                }
                                {
                                    SummaryComponent && MainComponent &&
                                    <>
                                        <Col span={5}>
                                            {SummaryComponent && <SummaryComponent {...props} />}
                                        </Col>
                                        <Col span={16} offset={1}>
                                            {MainComponent && <MainComponent {...props} />}
                                        </Col>
                                    </>
                                }
                            </Row>
                            {
                                currentStep !== 0 &&
                                <Row justify={"end"}>
                                    <Col>
                                        <Button
                                            onClick={() => {
                                                setCurrentStep(currentStep - 1);
                                            }}
                                            type="ghost"
                                            style={{ marginRight: 20 }}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setCurrentStep(currentStep + 1);
                                            }}
                                            type="primary"
                                            style={{}}
                                        >
                                            Next
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </>
            }
        </>
    );
};

export default SubjectLineExperiment;