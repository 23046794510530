import React, { useState, useEffect, useContext } from "react";
import { UserPlus } from "@styled-icons/fa-solid";
import { Metric } from "@pai-ui/core/components/metric";
import { Button } from "@pai-ui/core/components/button";
import validateMessages from "@xai/services/form-validation-messages";
import { UserContext } from "@xai/providers/with-auth";
import * as Comps from "./components";
import AccessControlServiceHelper from "./services-helper";
import "./index.css";

const messages = validateMessages();

const AccessControl = () => {
  const { token: authToken } = useContext(UserContext);

  const [addUserShow, setAddUserShow] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [rawUserData, setRawUserData] = useState([]);
  const [stateChanged, setStateChanged] = useState(false);
  const [showNewUserAddButton, setShowNewUserAddButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const accessControlServiceHelper = new AccessControlServiceHelper(authToken);

  const loadAllUsersAndRoles = () => {
    setLoading(true);
    // TODO Make below apis async use - promise.all()
    accessControlServiceHelper.getAllRoles().then((roles) => {
      setRolesList(roles);
      setShowNewUserAddButton(false);
    });

    accessControlServiceHelper.getAllUsers().then((users) => {
      if (users.length > 0) {
        var data = [...users];
        setRawUserData(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadAllUsersAndRoles();
  }, []);

  const hideNewUserCard = () => {
    setAddUserShow(false);
  };

  const addNewUser = (user) => {
    setRawUserData((users) => {
      users.push({
        id: user.id,
        key: user.id,
        firstname: user.name,
        status: user.is_Active,
        role: user.userRoles,
        edit: null,
        checked: false,
        email: user.email,
      });
      return users;
    });
    setStateChanged((value) => !value);
    setLoading(false);
  };

  const updateUser = (updatedUser) => {
    if (typeof updatedUser === "object") {
      setRawUserData((users) => {
        users.forEach((user) => {
          // TODO use find -
          if (user.id === updatedUser.id) {
            console.log(user, updatedUser);
            user.email = updatedUser.email;
            user.status = updatedUser.is_active;
            user.firstname = updatedUser.name;
            user.role = updatedUser.userRoles;
          }
        });
        return users;
      });
    } else {
      setRawUserData((users) =>
        users.filter((user) => user.id !== updatedUser)
      );
    }
    setStateChanged((value) => !value);
  };

  return (
    <div>
      {addUserShow && (
        <Comps.NewUserCard
          authtoken={authToken}
          loading={loading}
          setLoadingFlag={(value) => {
            setLoading(value);
          }}
          allRoles={rolesList.length > 0 ? rolesList : null}
          messages={messages}
          setAddUserShow={(value) => {
            setAddUserShow(value);
          }}
          addNewUser={addNewUser}
          hideNewUserCard={hideNewUserCard}
        />
      )}

      <div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Metric
            title="Manage User Access"
            withBackground={false}
            highlightedElement="subTitle"
          />

          <Button
            type="primary"
            icon={<UserPlus size={15} />}
            onClick={() => {
              setAddUserShow(true);
            }}
            ghost
            disabled={showNewUserAddButton}
          >
            <span style={{ marginLeft: "10px" }}>New User</span>
          </Button>
        </div>

        <Comps.AccessControlUserTable
          userData={rawUserData}
          rolesList={rolesList}
          updateUser={updateUser}
          stateChanged={stateChanged}
          authToken={authToken}
          isLoading={loading}
          loadAllUsers={loadAllUsersAndRoles}
        ></Comps.AccessControlUserTable>
      </div>
    </div>
  );
};

export default AccessControl;
