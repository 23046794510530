// /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// /* eslint-disable jsx-a11y/alt-text */
// /* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-expressions */
import React from "react";
import './index.css';
import {
  AutoComplete,
  Button,
  Card,
  Carousel,
  Col,
  Input,
  Modal,
  Row,
  Tag,
  Typography,
  message,
} from "antd";
import Axios from "axios";
import {
  CloseOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  HeartOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  LinkOutlined,
  PlusOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import html2canvas from "html2canvas";
import { getSubRoute } from "../transformer.js";

const ItemCard = (props) => {
  return (
    <AspectRatio width={10} height={16}>
      <div
        onClick={() => {
          props.onClick(props.item);
        }}
        style={{
          height: "100%",
          backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
            props.item.image_url
          )}&w=640&q=75)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: config.radius,
          backgroundPosition: "center center",
          position: "relative",
        }}
        className="hover-tile"
      >
        <div
          className="hover-additional-info"
          style={{
            position: "absolute",
            left: -1,
            right: -1,
            top: -1,
            bottom: -1,
            background: "rgba(0,0,0,0.5)",
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              color: "#FFF",
            }}
          >
            <div style={{ fontSize: "14px", marginRight: 5 }}>
              {props.item.like_count}
            </div>
            <HeartOutlined />
          </div>
          <div style={{ marginTop: "auto" }}>
            <Typography.Paragraph
              ellipsis={{ rows: 1, expandable: false }}
              style={{
                textTransform: "capitalize",
                color: "#FFF",
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: 5,
              }}
            >
              {props.item.title}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: "16px",
                marginBottom: 0,
              }}
              ellipsis={{ rows: 2, expandable: false }}
            >
              {props.item.prompt}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </AspectRatio>
  );
};

const ItemDisplay = (props) => {
  return (
    <div style={{ borderRadius: config.radius, overflow: "hidden" }}>
      <div id="downloadableDiv">
        <AspectRatio
          width={props.item.aspect_ratio.split(":")[0]}
          height={props.item.aspect_ratio.split(":")[1]}
        >
          <div
            onClick={() => {
              props.onClick(props.item);
            }}
            style={{
              height: "100%",
              backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
                props.item.image_url
              )}&w=640&q=75)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            &nbsp;
          </div>
        </AspectRatio>
      </div>
    </div>
  );
};

export const config = {
  gutter: 15,
  radius: 15,
};

const CustomCarousel = (props) => {
  const ref = React.useRef();

  const [currentSlide, setCurrentSlide] = React.useState(props.currentSlide);

  React.useEffect(() => {
    if (currentSlide >= 0) {
      ref.current?.goTo(currentSlide, true);
      console.log("Test");
    }
  }, [currentSlide, ref.current]);

  React.useEffect(() => {
    setCurrentSlide(props.currentSlide);
  }, [props.currentSlide]);

  return (
    <div style={{ position: "relative" }}>
      <Carousel ref={ref} dots={false}>
        {props.currentBatch?.map((item) => (
          <div key={item.id}>
            <AspectRatio width={1} height={1}>
              <div
                style={{
                  height: "100%",
                  backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
                    item.image_url
                  )}&w=640&q=75)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </AspectRatio>
          </div>
        ))}
      </Carousel>
      {currentSlide > 0 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: 20,
            transform: "translateY(-50%)",
            width: 50,
            height: 50,
            backgroundColor: "#FFF",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => {
            setCurrentSlide((s) => (s > 0 ? s - 1 : 0));
          }}
        >
          <span style={{ marginLeft: -2 }}>◀</span>
        </div>
      )}
      {currentSlide < props.currentBatch.length - 1 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: 20,
            transform: "translateY(-50%)",
            width: 50,
            height: 50,
            backgroundColor: "#FFF",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => {
            setCurrentSlide((s) =>
              s < props.currentBatch.length - 1
                ? s + 1
                : props.currentBatch.length - 1
            );
          }}
        >
          <span style={{ marginLeft: 2 }}>▶</span>
        </div>
      )}
    </div>
  );
};

const getQueryStringParameter = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) || "";
};

const timer = (seconds) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });

export function PrintDiv(div) {
  html2canvas(div, { allowTaint: true, useCORS: true }).then((canvas) => {
    const fileName = "image";
    const link = document.createElement("a");
    link.download = fileName + ".png";
    console.log(canvas);
    canvas.toBlob(function (blob) {
      console.log(blob);
      link.href = URL.createObjectURL(blob);
      link.click();
    });
  });
}

// eslint-disable-next-line react/display-name
export default () => {
  const [images, setImages] = React.useState([]);

  const defaultId = getQueryStringParameter("id");

  const subRoute = getSubRoute();

  React.useEffect(() => {
    Axios.get(
      `https://paiweb.vercel.app/api/gen-ai/list_filtered?t=${new Date().getTime()}`, {
      skipInterceptors: true
    }
    ).then((r) => {
      setImages(r.data.data);
      if (defaultId) {
        setSelected(r.data.data.find((d) => d.id === defaultId));
      }
    });
  }, [defaultId]);

  const [selected, setSelected] = React.useState();

  const [search, setSearch] = React.useState("");

  const [selectedFilters, setSelectedFilter] = React.useState([]);

  const tags = Array.from(new Set(images.map((i) => i.tags).flat())).sort();

  const filteredImages = images?.filter(
    (i) =>
      (!search || i.prompt?.indexOf(search) !== -1) &&
      (!selectedFilters.length ||
        i.tags?.find((t) => selectedFilters?.find((s) => s === t)))
  );

  const [moreItems, setMoreItems] = React.useState([]);

  React.useEffect(() => {
    console.log("Selected", selected);
    if (selected) {
      Axios.get(
        `https://paiweb.vercel.app/api/gen-ai/list?t=${new Date().getTime(), {
          skipInterceptors: true
        }}`
      ).then((r) => {
        setMoreItems(
          r.data.data?.filter((d) => d.batch_id === selected.batch_id)
        );
      });
    }
  }, [selected]);
  const sortPhotosByType = (photos) => {
    // Sort photos by timestamp
    const sortedByTimestamp = photos.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    const landscapePhotos = sortedByTimestamp.filter(
      (photo) => photo.orientation === "landscape"
    );
    const portraitPhotos = sortedByTimestamp.filter(
      (photo) => photo.orientation === "portrait"
    );

    return [...portraitPhotos, ...landscapePhotos];
  };

  const currentLandscape =
    parseInt(selected?.aspect_ratio?.split(":")?.[0] || "0") >
    parseInt(selected?.aspect_ratio?.split(":")?.[1] || "0");

  const [showHowItWorks, setShowHowItWorks] = React.useState(false);

  return (
    <>
      <Modal
        width={"80%"}
        visible={showHowItWorks}
        onCancel={() => setShowHowItWorks(false)}
        bodyStyle={{
          maxHeight: "none",
          borderRadius: 20,
          overflow: "hidden",
          background: "#000",
        }}
        footer={null}
      >
        <div style={{ padding: 20 }}>
          <img
            src={`${process.env.REACT_APP_S3_BUCKET}/${subRoute}/viz_configs/img/data/gen-ai-how-it-works.gif`}
            width="100%"
          />
        </div>
      </Modal>
      <div
        style={{
          maxWidth: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 15,
        }}
      >
        <div
          style={{ marginBottom: 25, display: "flex", alignItems: "center" }}
        >
          <div style={{ width: 380, marginRight: 25 }}>
            <AutoComplete
              size="large"
              style={{ width: "100%" }}
              onSelect={(i) => {
                setSearch(i);
              }}
              dataSource={Array.from(
                new Set(images?.map((i) => i.prompt?.split(",")[0]))
              )}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              <Input
                allowClear
                size="large"
                onChange={(e) => {
                  if (!e.target.value) {
                    setSearch("");
                  }
                }}
                placeholder="Search Gallery"
              />
            </AutoComplete>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {tags.map((tag, index) => (
              <Button
                key={index}
                size="large"
                type={
                  selectedFilters.indexOf(tag) !== -1 ? "primary" : undefined
                }
                onClick={() => {
                  if (selectedFilters.indexOf(tag) === -1) {
                    setSelectedFilter([...selectedFilters, tag]);
                  } else {
                    setSelectedFilter(selectedFilters.filter((s) => s !== tag));
                  }
                }}
                style={{
                  marginRight: 10,
                  minWidth: 0,
                  textTransform: "capitalize",
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderRadius: 20,
                  backgroundColor:
                    selectedFilters.indexOf(tag) === -1
                      ? "rgba(255,255,255,0.1)"
                      : undefined,
                }}
              >
                {tag}
              </Button>
            ))}
          </div>
          <div
            style={{
              width: 320,
              marginLeft: 25,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              size="large"
              type="outline"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setShowHowItWorks(true);
              }}
              icon={<InfoCircleOutlined />}
            >
              How it works
            </Button>
            <Button
              size="large"
              type="primary"
              style={{ marginLeft: 15 }}
              onClick={() => {
                window.location.href = window.location.href.replace(
                  "gallery",
                  "new"
                );
              }}
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          </div>
        </div>
        <div className="photo-grid">
          {sortPhotosByType(
            filteredImages.map((f) => ({
              ...f,
              orientation:
                f.aspect_ratio === "10:16" ? "portrait" : "landscape",
            }))
          )?.map(({ orientation, ...i }) => (
            <div
              className={`photo-grid-item ${i.aspect_ratio === "10:16" ? "portrait" : "landscape"
                }`}
              key={i.id}
              onClick={() => {
                setSelected(i);
              }}
            >
              <div
                className="hover-tile"
                style={{
                  position: "relative",
                  borderRadius: 15,
                  overflow: "hidden",
                }}
              >
                <img
                  src={`https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
                    i.image_url
                  )}&w=640&q=75`}
                />
                <div
                  className="hover-additional-info"
                  style={{
                    position: "absolute",
                    left: -1,
                    right: -1,
                    top: -1,
                    bottom: -1,
                    background: "rgba(0,0,0,0.5)",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      color: "#FFF",
                    }}
                  >
                    <div style={{ fontSize: "14px", marginRight: 5 }}>
                      {i.like_count}
                    </div>
                    <HeartOutlined />
                  </div>
                  <div style={{ marginTop: "auto" }}>
                    <Typography.Paragraph
                      ellipsis={{ rows: 1, expandable: false }}
                      style={{
                        textTransform: "capitalize",
                        color: "#FFF",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: 5,
                      }}
                    >
                      {i.title}
                    </Typography.Paragraph>
                    <Typography.Paragraph
                      style={{
                        fontSize: "16px",
                        marginBottom: 0,
                      }}
                      ellipsis={{ rows: 2, expandable: false }}
                    >
                      {i.prompt}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          width={989}
          visible={!!selected}
          bodyStyle={{
            maxHeight: "none",
            borderRadius: 25,
            overflow: "hidden",
          }}
          onCancel={() => {
            setSelected(undefined);
          }}
          footer={null}
        >
          <Row>
            <Col span={currentLandscape ? 24 : 12}>
              <div style={{ padding: 30 }}>
                {selected && <ItemDisplay item={selected} />}
                <div style={{ display: "flex", marginTop: 20 }}>
                  <Button
                    style={{
                      backgroundColor: "rgba(255,255,255,0.1)",
                      marginRight: 10,
                    }}
                    icon={<HeartOutlined />}
                  >
                    {" "}
                    {selected?.like_count}
                  </Button>
                  <Button
                    icon={<DownloadOutlined />}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      const elem = document.getElementById("downloadableDiv");
                      PrintDiv(elem);
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.href.split("?")}?id=${selected?.id}`
                      );
                      message.success("Copied successfully");
                    }}
                    icon={<LinkOutlined />}
                  >
                    Copy Link
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={currentLandscape ? 24 : 12}>
              <div
                style={{
                  padding: currentLandscape ? 30 : 40,
                  paddingLeft: currentLandscape ? 30 : 5,
                  paddingTop: currentLandscape ? 0 : 40,
                  marginTop: currentLandscape ? 10 : 0,
                }}
              >
                <div
                  style={{
                    display: selected?.tags?.length ? "flex" : "none",
                    alignItems: "center",
                    marginBottom: 25,
                  }}
                >
                  {selected?.tags?.map((tag, index) => (
                    <Tag key={index} size="large" style={{ marginRight: 10 }}>
                      {tag}
                    </Tag>
                  ))}
                </div>
                <h1
                  style={{
                    textTransform: "capitalize",
                    lineHeight: "110%",
                    fontSize: "32px",
                    color: "#FFF",
                    marginBottom: 30,
                  }}
                >
                  {selected?.title}
                </h1>

                <h3
                  style={{
                    marginTop: 25,
                    marginBottom: 10,
                    lineHeight: "100%",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: "#69676e",
                  }}
                >
                  Prompt
                </h3>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#FFF",
                    marginBottom: 35,
                  }}
                >
                  {selected?.prompt}
                </p>

                {!!selected?.exclude && (
                  <>
                    <h3
                      style={{
                        marginBottom: 10,
                        lineHeight: "100%",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#69676e",
                      }}
                    >
                      Removed from image
                    </h3>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#FFF",
                        marginBottom: 35,
                      }}
                    >
                      {selected?.exclude}
                    </p>
                  </>
                )}
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(selected.prompt);
                      message.success("Copied successfully");
                    }}
                    icon={<CopyOutlined />}
                    style={{ marginRight: 10 }}
                  >
                    Copy Prompt
                  </Button>
                  <Button
                    icon={<RetweetOutlined />}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      window.location.href = `${window.location.href
                        .split("?")[0]
                        .replace("gallery", "new")}?prompt=${selected?.prompt
                        }&exclude=${selected?.exclude}`;
                    }}
                  >
                    Remix
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.href = `${window.location.href
                        .split("?")[0]
                        .replace(
                          "gallery",
                          "new"
                        )}?seed_image=${encodeURIComponent(
                          selected?.image_url
                        )}&prompt=${encodeURIComponent(
                          selected?.prompt
                        )}&exclude=${encodeURIComponent(selected?.exclude)}`;
                    }}
                    icon={<EditOutlined />}
                  >
                    Variations
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ padding: 30 }}>
            <h2 style={{ fontSize: "20px", marginBottom: 20 }}>
              More images like this
            </h2>
            <Row gutter={[config.gutter, config.gutter]}>
              {moreItems
                ?.filter((c) => c.id !== selected?.id)
                .map((c, index) => (
                  <Col key={index} span={8}>
                    <ItemCard item={c} onClick={setSelected} />
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      </div>
    </>
  );
};
