/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { Button } from "antd";
import axios from "axios";
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { msalConfig, loginRequest, graphConfig } from "./config";
import "./index.css";
import { Result, Select, Popover, Space, Avatar, Skeleton } from "antd";
import * as AuthService from "@xai/services/auth";
import { InteractionStatus } from "@azure/msal-browser";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, CardMeta } from "@pai-ui/core";
import { Typography } from "@xai/library/base";

const defaultUser = {
  username: "souvik.saha@zs.com",
  name: "John Doe",
};

const setUserInfoInHeaders = (account) => {
  // Method to set user info and pass through api headers.
  axios.interceptors.request.use(
    (config) => {
      console.log("config", config);
      if (config?.skipInterceptors) {
        return config;
      } else {
        // if (process.env.REACT_APP_PANERA_ZS_DEV) {
        config.headers["userid"] = account.username; // username from AD is accepted as userid in node solution
        config.headers["username"] = account.name; // name from AD is accepted as username in node solution
        //   config.headers.common["kubeflow-userid"] = "souvik.saha@zs.com";
        //   config.headers.common["x-request-id"] =
        //     "9c332de3-5bde-9778-871f-728719b9594c";
        // }
        return config;
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

export const UserContext = React.createContext();

// import { Login } from "./login-page";
// import { StyledLink } from "../withPageTitle";
// import { axiosCoreInstance } from "../../api-core";

const initialState = {
  user: undefined,
  identity: undefined,
  loading: true,
  error: undefined,
  token: undefined,
  role: undefined,
  format: undefined,
  status: undefined,
  genericOptions: undefined,
  experimentModule: {
    loading: true,
    experimentList: null,
    selectedExperiment: null,
    // Purpose of below 3 state variables are to handle date selection, when view feature triggered from task management screen.
    analyze_screen_selected_cohort: null,
    analyze_screen_selected_task: null,
    result_screen_selected_cohort: null,
  },
  currentScreen: null,
  subRoute: null
};

// const getPermissions = (role) => {
//   let permissions = role.permissions;
//   return permissions;
// };

// const selectRole = (user, roleId) => {
//   const allRoles = user.access;
//   const role = allRoles.find((r) => r.id === roleId);
//   return {
//     role: { id: role.id, name: role.name },
//     permissions: getPermissions(role),
//   };
// };

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      type="link"
      icon={<LogOut height={20} width={20} />}
      onClick={() => handleLogout(instance)}
    >
      Logout
    </Button>
  );
};

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}
function handleDefaultLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

const userReducer = (state, action) => {
  switch (action.type) {
    case "metaData":
      return {
        ...state,
        user: action.payload.user,
        format: action.payload.format,
        status: action.payload.status,
        genericOptions: action.payload.genericOptions,
        // country: action.payload.country,
        // currency: action.payload.currency,
        loading: false,
        error: undefined,
        token: action.payload.token,
      };
    case "experimentModule":
      return {
        ...state,
        experimentModule: {
          ...state.experimentModule,
          ...action.payload,
        },
      };
    case "screenRoute":
      return {
        ...state,
        currentScreen: action.payload.currentScreen,
        subRoute: action.payload.subRoute
      }
    default:
      return {
        ...state,
      };
  }
};

const ComponentForAuthentication = ({ children }) => {
  //   const [graphData, setGraphData] = useState(null);

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  let userData = {
    name: accounts?.[0]?.name,
    username: accounts?.[0]?.username,
  };

  setUserInfoInHeaders(userData);

  const requestProfileData = React.useCallback(
    function requestProfileData() {
      const request = {
        ...loginRequest,
        //scopes:[""]
        account: accounts[0],
      };
      // console.log("accessToken");

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          console.log("accessToken", response?.accessToken);
          //FIXME
          let metaData = { token: response?.accessToken, user: userData };
          window.localStorage.setItem("currUrl", instance?.config.auth.state);
          //#region - getuserId,username
          //   / /Not needed as getAPILogin is not in use
          //   const activeAccount = instance.getActiveAccount();
          //   let additionalHeaders = {
          //     userid: activeAccount.userName,
          //     username: activeAccount.name,
          //   };
          //#endregion - getuserId,username
          let apiData = await AuthService.getMetaData(
            response?.accessToken
            // additionalHeaders
          );
          metaData = { ...metaData, ...apiData };

          console.log("metadata", metaData, apiData);
          dispatch({
            type: "metaData",
            payload: metaData,
          });
          // setIsLoginSuccessful(isAuthenticated);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setIsLoginSuccessful(false);
          });
        });
    },
    [isAuthenticated]
  );

  useEffect(() => {
    if (isAuthenticated) requestProfileData();
  }, [isAuthenticated]);
  const activeAccount = instance.getActiveAccount();

  if (
    inProgress === InteractionStatus.None &&
    !isAuthenticated &&
    window.location.pathname.indexOf("logout") == -1
  ) {
    setTimeout(() => {
      if (accounts.length === 0) {
        // handleDefaultLogin(instance);
        handleLogin(instance);
      }
    }, 100);
  }

  const Component = () => {
    console.log("loading component", InteractionStatus, isAuthenticated);
    var Item = null;
    if (isAuthenticated) Item = <div>{children}</div>;
    else if (!isAuthenticated) {
      Item = (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Card
              size="large"
              style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
              bordered={false}
              bodyStyle={{ backgroundColor: "transparent" }}
              // loading={true}
              actions={[
                // eslint-disable-next-line react/no-unknown-property
                <div key={1} align="center" style={{ color: "#15afd0" }}>
                  {inProgress === InteractionStatus.None
                    ? "Redirecting for login"
                    : "Fetching Details"}
                </div>,
              ]}
            >
              <div>
                <Skeleton.Avatar active size="large" />
              </div>
              <div style={{ marginTop: "20px" }}>
                {" "}
                <Skeleton
                  active
                  shape="round"
                  paragraph={{ rows: 3, width: 250 }}
                />
              </div>
            </Card>
          </div>
        </div>
      );
    } else {
      Item = (
        <div className="login-component-wrapper">
          <Result
            status="403"
            title="Xperiment.AI-Panera"
            subTitle="Authentication Failed. Please Retry"
            extra={
              <Button type="primary" onClick={() => handleLogin(instance)}>
                Login
              </Button>
            }
          />
        </div>
      );
    }

    return Item;
  };

  console.log("auth useEffect", {
    isAuthenticated,
    activeAccount,
    instance,
    state,
  });
  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      {/* <Component /> */}
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {window.location.pathname.indexOf("logout") > -1 ? (
              <Card
                size="large"
                bodyStyle={{ backgroundColor: "transparent" }}
                bordered={false}
              >
                <Result
                  status="success"
                  title="You have successfully Logged out from Xperiment.AI-Panera"
                  subTitle="Please click below button to Login !"
                  extra={
                    <Button
                      type="primary"
                      onClick={() => handleLogin(instance)}
                    >
                      Login
                    </Button>
                  }
                />
              </Card>
            ) : (
              <Card
                size="large"
                style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
                bordered={false}
                bodyStyle={{ backgroundColor: "transparent" }}
                // loading={true}
                actions={[
                  // eslint-disable-next-line react/no-unknown-property
                  <div key={1} align="center" style={{ color: "#15afd0" }}>
                    {inProgress === InteractionStatus.None
                      ? "Redirecting for Login"
                      : "Fetching Details"}
                  </div>,
                ]}
              >
                <div>
                  <Skeleton.Avatar active size="large" />
                </div>
                <div style={{ marginTop: "20px" }}>
                  {" "}
                  <Skeleton
                    active
                    shape="round"
                    paragraph={{ rows: 3, width: 250 }}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>
      </UnauthenticatedTemplate>
    </UserContext.Provider>
  );
};

const ComponentWithoutAuthentication = ({ children }) => {
  const { /* instance, */ accounts /* , inProgress */ } = useMsal();
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  useEffect(async () => {
    let tempToken = "need-some-token-to-work-with-adding-dummy-text-for-now";
    let userData = {
      name: accounts?.[0]?.name || defaultUser?.name,
      username: accounts?.[0]?.username || defaultUser?.username,
    };
    setUserInfoInHeaders(userData);
    let metaData = { token: tempToken, user: userData };
    console.log("accessToken", metaData, accounts);
    let apiData = await AuthService.getMetaData(tempToken);
    metaData = { ...metaData, ...apiData };

    console.log("metadata", metaData, apiData);
    dispatch({
      type: "metaData",
      payload: metaData,
    });
  }, []);

  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      <>{children}</>
    </UserContext.Provider>
  );
};

export const withAuth = (Component) =>
  function Auth(props) {
    let newConfig = JSON.parse(JSON.stringify(msalConfig));
    newConfig.auth.state = window.location.pathname;
    const msalInstance = new PublicClientApplication(newConfig);
    // const msalInstance = new PublicClientApplication(msalConfig);
    console.log(
      "msalInstance",
      msalInstance.getAllAccounts(),
      msalInstance.getActiveAccount()
    );

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        msalInstance.setActiveAccount(event.payload.account);
      }
    });

    console.log(
      "msalInstance 2",
      { msalInstance },
      process.env.REACT_APP_IS_AD_REQUIRED
    );
    return (
      <MsalProvider instance={msalInstance}>
        {process.env.REACT_APP_IS_AD_REQUIRED?.toLowerCase() === "true" ? (
          <ComponentForAuthentication>
            <Component />
          </ComponentForAuthentication>
        ) : (
          <ComponentWithoutAuthentication>
            <Component />
          </ComponentWithoutAuthentication>
        )}
      </MsalProvider>
    );
  };
