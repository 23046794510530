import React from "react";
import numeral from "numeral";
import { Space } from "@pai-ui/core/components/space";

export const RangePercentage = ({
  secondaryValue,
  secondaryValueFormat,
  primaryValue,
  primaryValueFormat,
  fontSize = "11px",
  fontWeight = "inherit",
  lineHeight = "100%",
  color = "#9ba2a4"
}) => {
  return (
    <>
      <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{ marginRight: "6px" }}>{numeral(primaryValue).format(primaryValueFormat).toUpperCase()}</div>
        <div
          style={{
            fontSize: fontSize,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color
          }}
        >
          <Space>
            <div style={{ /* marginTop: "3px" */ }}>
              <div style={{ marginBottom: "-5px" }}>+</div><div style={{ /* marginTop: "-3px"  */}}>-</div>
            </div>
            <div style={{ marginLeft: "-7px"/* ,marginTop: "5px"  */}}>
              {numeral(secondaryValue).format(secondaryValueFormat).toUpperCase()}
            </div>
          </Space>
        </div>
      </div>
    </>
  );
};

export default RangePercentage;
