import { StaticRouteConfig } from "@xai/library/layouts/auth-layout/config";
import { createHierarchy } from "@pai-ui/core/utils/data";

export const SupersetFormConfig = () => {
  return {
    hasSteps: false,
    sectionsGrid: [["basic_details"]],
    sections: {
      basic_details: {
        sectionName: "Add Router Config",
        sectionFields: [
          {
            fieldName: "dashboard",
            label: "Dashboard",
            component: "select",
            validations: { required: true },
            optionsKey: "dashboards",
            componentProps: {
              placeholder: "Select Dashboard",
              disabled: "true",
            },
          },
          {
            fieldName: "route",
            label: "Screen",
            component: "cascader", //"select",
            validations: { required: true },
            componentProps: {
              placeholder: "Select route",
            },
            optionsKey: "routes",
          },
          {
            fieldName: "subrouteName",
            label: "Subscreen Name",
            component: "input",
            validations: {
              required: true,
            },
            componentProps: { placeholder: "Enter Subroute Name" },
            conditionalRender: [
              {
                dependentField: "route",
                operator: "!==",
                valueToCheck: ["Add as Report"]
              },
            ],
          },
          {
            fieldName: "reportName",
            label: "Report Name",
            component: "input",
            validations: {
              required: true,
            },
            componentProps: { placeholder: "Enter Report Name" },
            conditionalRender: [
              {
                dependentField: "route",
                operator: "===",
                valueToCheck: ["Add as Report"]
              },
            ],
          },
          {
            fieldName: "tags",
            label: "Tags",
            optionsKey: "tags",
            component: "select",
            validations: { required: true },
            componentProps: {
              placeholder: "Select tags",
              mode: "tags",
              maxTagCount: "responsive",
            },
            conditionalRender: [
              {
                dependentField: "route",
                operator: "===",
                valueToCheck: ["Add as Report"]
              },
            ],
          },
          {
            fieldName: "hideFilters",
            label: "Hide Filters",
            component: "switch",
            componentProps: { placeholder: "" },
            validations: { required: true },
            info: "Toggle this switch on to display the filters",
          },
        ],
      },
    },
  };
};

export const SupersetFormDefaultValue = (selectedDashboard) => {
  return {
    dashboard: selectedDashboard,
    route: [],
    subrouteName: "",
    reportName: "",
    hideFilters: false,
    tags: []
  };
};

const process = (items) => {
  return items.map((x) => ({
    value: x.id,
    label: x.name,
    children: x.children ? process(x.children) : undefined,
  }));
};

// export const SupersetFormDdnMaster = (d) =>{
//   return ({
//       dashboards: d || [{id: 1, name: 'Test1'},{id: 2, name: 'Test2'}],
//       routes: StaticRouteConfig.groups.map(x=>x.items.map(y=>y.linked)).flat().map(z=> ({id:z, name: z })) //[{id: 'dashboard', name: 'Dashboard'}, {id: 'businessinputs', name: 'Business Inputs'}],
//   })
// }

export const SupersetFormDdnMaster = (d) => {
  let allRoutes = StaticRouteConfig.groups
    .map((x) =>
      x.items.map((y) => ({
        type: "Add as Subscreen",
        route: x.name,
        subroute: y.linked,
      }))
    )
    .flat();
  let treeStructureOptions = createHierarchy(
    { data: [...allRoutes] },
    ["type", "route", "subroute"],
    { structureType: "processed" }
  ); // {"type": "Add as Report"}
  treeStructureOptions.push({ id: "Add as Report", name: "Add as Report" });
  treeStructureOptions = process(treeStructureOptions);
  console.log("treeStructureOptions", treeStructureOptions);
  return {
    dashboards: d || [
      { id: 1, name: "Test1" },
      { id: 2, name: "Test2" },
    ],
    routes: treeStructureOptions, //[{id: 'dashboard', name: 'Dashboard'}, {id: 'businessinputs', name: 'Business Inputs'}],
    tags: [
      { id: "Executive", name: 'Dashboard' }, 
      { id: "DataScience", name: 'Data Science' }, 
      { id: "Operationalization", name: 'Operationalization' },
      { id: "Administration", name: 'Administration' }
    ]
  };
};
