import React, { useEffect, useState } from "react";
import { Form } from "antd";
import {
  ZsSteps,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { getSteps, RenderItem } from "./utils";
import "./index.css";
import { WaveLoading } from "styled-spinkit";
import { useNavigate } from "react-router-dom";

const DefaultFormTemplate = ({ templateSelected, experimentToLoad }) => {
  const [formConfig, setFormConfig] = useState();
  const [formElements, setFormElements] = useState()
  const [forceRerender, setForceRerender] = useState(1);
  const [currentStep, setCurrentStep] = useState(0); //fix to reload validation while moving on steps
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [validationArray, setValidationArray] = useState([]);
  const [enabledSteps, setEnabledSteps] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const setErrorFieldsToState = (error) => {
    for (let i = 0; i < error?.errorFields?.length; i++) {
      if (error?.errorFields[i].errors?.length > 0) {
        setValidationArray([
          ...validationArray.filter((a) => a.stepId != currentStep),
          { stepId: currentStep, isValid: false },
        ]);
        break;
      }
    }
  };

  useEffect(() => {
    if (formElements) {
      const allSteps = getSteps(JSON.parse(JSON.stringify(formElements)), "all");
      const traversedSteps = [];
      allSteps.map((a, i) => {
        if (i == 0) traversedSteps.push({ id: a.name, isActive: true });
        else traversedSteps.push({ id: a.name, isActive: false });
      });
      setEnabledSteps(traversedSteps);
      var formElementsToSet = JSON.parse(JSON.stringify(formElements))
      if (experimentToLoad && experimentToLoad.length > 0) {

        formElementsToSet.sections.filter(x => x.id == 1)[0].elements.forEach(x => {

          x["value"] = experimentToLoad.filter(y => y.name == x.backendName)[0] ? experimentToLoad.filter(y => y.name == x.backendName)[0].value : ""
        })
        formElementsToSet.sections.filter(x => x.id == 15)[0].elements.forEach(x => {

          x["value"] = experimentToLoad.filter(y => y.name == x.backendName)[0] ? experimentToLoad.filter(y => y.name == x.backendName)[0].value : ""
        })
        formElementsToSet.sections.filter(x => x.id == 30)[0].elements.forEach(x => {

          x["value"] = experimentToLoad.filter(y => y.name == x.backendName)[0] ? experimentToLoad.filter(y => y.name == x.backendName)[0].value : ""
        })
      }

      console.log('aayushformElementsToSet', allSteps, formElementsToSet, templateSelected, experimentToLoad);
      setFormConfig(formElementsToSet);
    }
  }, [formElements]);

  useEffect(() => {
    if (enabledSteps.length > 0) {
      const traversedSteps = enabledSteps;
      traversedSteps[currentStep].isActive = true;
      setEnabledSteps(traversedSteps);
    }
  }, [enabledSteps, currentStep]);

  const load = async () => {
    if (templateSelected?.template_form_config_table?.template_form_config) {
      setFormElements(templateSelected?.template_form_config_table?.template_form_config);
    }
  }
  useEffect(() => {
    load()
  }, []);


  const onFinish = () => {
    setFormConfig(JSON.parse(JSON.stringify(formElements)));
    navigate(`/tests/list`);
  };

  useEffect(async () => {
    setVisitedSteps([
      ...visitedSteps.filter((a) => a != currentStep),
      currentStep,
    ]);
    if (visitedSteps.includes(currentStep)) {
      console.log(form.getFieldsValue());
      await form.validateFields().catch((error) => {
        setErrorFieldsToState(error);
      });
    }
    let count = 0;
    validationArray.map((a) => {
      if (!a.isValid) count++;
    });
    if (count > 0) setIsSubmitDisabled(true);
    else setIsSubmitDisabled(false);
  }, [currentStep]);

  console.log('formElements', formElements, formConfig, templateSelected);

  return (formElements && formConfig && Object.keys(formConfig).length > 0 ?
    <div style={{ maxHeight: "86vh" }} className="test-form-custom-new-div">
      <Form form={form} name={"test_9_experiment"}>
        {formConfig.sections.length > 0 && (
          <div style={{ margin: "20px 10px 0px" }}>
            <ZsSteps
              vertical={true}
              onFinish={onFinish}
              validationArray={validationArray}
              isSubmitDisabled={isSubmitDisabled}
              enabledSteps={enabledSteps}
              setValidationArray={setValidationArray}
              steps={getSteps(formConfig).map((e, i) => ({
                title: e.name,
                form: form,
                content: () => {
                  setCurrentStep(i);
                  return (<RenderItem templateSelected={templateSelected} elem={e} formConfig={formConfig} setFormConfig={setFormConfig} forceRerender={forceRerender} setForceRerender={setForceRerender} form={form} currentStep={currentStep}> </RenderItem>)

                },
              }))}
              finishText="Publish Experiment"
              finishMessage="Experiment Added Successfully"
            />
          </div>
        )}
      </Form>
    </div> : <WaveLoading color="#15afd0"></WaveLoading>
  );
};

export default DefaultFormTemplate;