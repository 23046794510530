/* eslint-disable react/jsx-key */
import React, { useEffect } from "react";

import { GlobalStyle, SpecialModalBackground } from "./loading-custom-style";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import { Typography } from "@xai/library/base";
import ErrorFallback from "../ErrorFallback";
import { UserContext } from "../../providers/with-auth";
import SupersetPlaceholder from '@xai/pages/superset-dashboard-placeholder';

/**
 * @pai-ui/core :
 *        ThemeProvider
 *        ConfigProvider
 */
import { ThemeProvider as PaiUiThemeProvider } from '@pai-ui/core/theme/theme-provider';
import { ConfigProvider as PaiUiConfigProvider } from '@pai-ui/core/config/config-provider';
import { ThemeType } from '@pai-ui/core/theme/types';


// to be changed
// import { Card  } from "antd";
import { Card, Skeleton, Avatar, CardMeta } from '@pai-ui/core'; //

import { LoadingOutlined } from "@ant-design/icons";
import AuthLayout from "./../../library/layouts/auth-layout/index";
import * as Pages from "./../index";
import { ErrorBoundary } from 'react-error-boundary';
import { useLayoutContext, useLayoutContextToUpdateMenu } from "@xai/providers/layout";

const LoadingCard = () => {
  const { menu } = useLayoutContext();
  const { updateMenu } = useLayoutContextToUpdateMenu();
  const userState = React.useContext(UserContext);
  const authToken = userState.token;

  const generateMappedPages = (menu) => {
    let mappedPages = {};
    menu?.groups?.forEach((module) => {
      module.items.forEach((item) => {
        item.subMenu.forEach((subModule) => {
          mappedPages[subModule.key] = React.lazy(() =>
            import("@xai/pages/" + subModule.path)
          );
        });
      });
    });
    return mappedPages;
  };

  const mappedPages = React.useMemo(() => {
    return generateMappedPages(menu);
  }, [menu]);

  useEffect(() => {
    updateMenu(authToken);
  }, []);

  console.log("loading card", mappedPages);

  return [
    <GlobalStyle key={4} />,
    <PaiUiThemeProvider
      type={ThemeType.dark}
      componentsStyles={{
        visualization: {
          root: {
            zoom: 1.1111111,
          },
          header: {
            zoom: 0.9,
          },
        },
      }}
    >
      <ThemeProvider
        key={5}
        theme={{
          gutter: {
            inner: 20,
          },
          colors: {
            primary: "#15afd0",
            secondary: "#15afd0",
            default: "#ffffff",
            success: "#00FF00",
            background: "#111419",
            muted: "#FFFFFF",
            error: "red",
          },
          borderRadius: 10,
          background: "#000",
          fontSizes: {
            xlarge: 36,
            large: 21,
            medium: 16,
            default: 14,
            small: 11,
          },
          modal: {
            background: "#181b20",
          },
        }}
      >
        {(!userState || userState.loading) && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>

              <Card
                size="large"
                style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
                bordered={false}
                bodyStyle={{ backgroundColor: "transparent" }}
                loading={!userState.identity}
                actions={
                  userState.error ? (
                    [
                      <div key={0} style={{ padding: "0px 10px" }}>
                        <Typography.Paragraph align="center" colorStyle="error">
                          {userState.error}
                        </Typography.Paragraph>
                      </div>,
                    ]
                  ) : !userState.identity ? (
                    [
                      <Typography.Paragraph
                        key={1}
                        align="center"
                        colorStyle="primary"
                      >
                        Identifying
                      </Typography.Paragraph>,
                    ]
                  ) : !userState.user ? (
                    [
                      <Typography.Paragraph
                        key={2}
                        align="center"
                        colorStyle="primary"
                      >
                        Fetching Tokens
                      </Typography.Paragraph>,
                    ]
                  ) : (
                    <Typography.Paragraph align="center" colorStyle="primary">
                      Login Successful
                    </Typography.Paragraph>
                  )
                }
              >
                {/* {console.log("userstate--->", userState)} */}
                <Skeleton loading={!userState.identity} avatar active>
                  {userState.identity && (
                    <React.Fragment>
                      <Avatar
                        size={65}
                        style={{ marginBottom: 20, backgroundColor: "#15afd0" }}
                        icon={<LoadingOutlined />}
                      ></Avatar>
                      <CardMeta
                        title={userState.identity.name}
                        description={userState.identity.userName}
                      />
                    </React.Fragment>
                  )}
                </Skeleton>
              </Card>
            </div>
          </div>
        )}
        {userState && !userState.loading && (
          <ModalProvider backgroundComponent={SpecialModalBackground}>
            <AuthLayout>
              {({ pageName, isSuperSet, dashboardId }) => {
                // console.log('inside page render', pageName, isSuperSet, dashboardId)
                if (isSuperSet) {
                  return <SupersetPlaceholder dashboardId={dashboardId} />;
                }

                const Page = Pages[pageName];
                // console.log('pages', Pages, )

                if (!Page) return <>Page Not found! {pageName} {isSuperSet}</>;

                return (
                  <PaiUiConfigProvider
                    visualization={{
                      resizing: {
                        eventName: 'customResize',
                        debounceMs: 500
                      }
                    }}
                    http={() => ({
                      commonRequestConfig: {
                        url: "/viz/GetData",
                        baseURL: process.env.REACT_APP_MICROSERVICE_HOST,
                        headers: {
                          "x-api-key": process.env.REACT_APP_API_KEY,
                          Authorization: "Bearer " + authToken,
                        },
                      },
                    })}
                    components={{

                      select: {
                        props: {
                          getPopupContainer: (triggerNode) => triggerNode.parentElement,
                        }
                      }
                    }}
                  >

                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <Page />
                    </ErrorBoundary>
                  </PaiUiConfigProvider>
                );
              }}
            </AuthLayout>
          </ModalProvider>
        )}
      </ThemeProvider>
    </PaiUiThemeProvider>,
  ];
};

export default LoadingCard;
