import { Button, Input } from "antd";
import { React } from 'react'
import { Typography } from "@xai/library/base";
import Dendogram from './dendogram.js';
import {
  ZsAvatar,
  ZsButton,
  ZsCheckCircleOutlined,
  ZsCol,
  ZsDatePicker,
  ZsDeleteOutlined,
  ZsInput,
  ZsMultiSelect,
  ZsPlusOutlined,
  ZsRadioButton,
  ZsRangePicker,
  ZsRow,
  ZsSelect,
  ZsSpace,
  ZsSwitch,
  ZsTag,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { Grid, GridItem } from "@xai/library/containers/grid";
import { FormItemRow } from "../components/FormItemRow";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FileCsv } from "@styled-icons/fa-solid/FileCsv";
import { Database } from "@styled-icons/fa-solid/Database";
import { FileExcel } from "@styled-icons/fa-solid/FileExcel";
import { FilePdf } from "@styled-icons/fa-solid/FilePdf";
import numeral from "numeral";
import { ZsCard } from "@xai/assets/generic-ant-d-components/zs-card";




const checkIfActive = (ele, data, componentType, componentParent) => {
  if (!ele.activeOnCondition) {
    return true;
  }
  if (ele.activeOnCondition.conditions[0].operator == "> length") {
    const constraintParentId = componentParent.elements.findIndex(
      (a) => a.id == ele.activeOnCondition.conditions[0].dependsOn
    );

    const value = ele.activeOnCondition.conditions[0].value;
    if (componentParent.elements[constraintParentId].value.length > value)
      return true;
    return false;
  }

  if (componentType == "steps") {
    const constraintParentId = data.sections.findIndex(
      (a) => a.sectionId == ele.activeOnCondition.conditions[0].parentId
    );
    const constraintId = data.sections[constraintParentId].elements.findIndex(
      (a) => a.id == ele.activeOnCondition.conditions[0].dependsOn
    );
    const value = ele.activeOnCondition.conditions[0].value;
    if (data.sections[constraintParentId].elements[constraintId].value == value)
      return true;
    return false;
  }

  if (componentParent) {
    const constraintParentId = componentParent.elements.findIndex(
      (a) => a.id == ele.activeOnCondition.conditions[0].dependsOn
    );

    const value = ele.activeOnCondition.conditions[0].value;
    if (
      componentParent.elements[constraintParentId].value == value &&
      ele.activeOnCondition.conditions.length == 1
    )
      return true;
    if (
      componentParent.elements[constraintParentId].value == value &&
      ele.activeOnCondition.conditions[1]
    ) {
      const constraintParentId = componentParent.elements.findIndex(
        (a) => a.id == ele.activeOnCondition.conditions[1].dependsOn
      );

      const value = ele.activeOnCondition.conditions[1].value;
      if (componentParent.elements[constraintParentId].value == value)
        return true;
    }
    return false;
  }

  if (componentType == "DynamicRowInput") {
    // console.log(ele, data);
    const parentIndex = data.findIndex(
      (a) =>
        Number.parseInt(a.id.split("_")[3]) ==
        ele.activeOnCondition.conditions[0].dependsOn
    );
    const value = ele.activeOnCondition.conditions[0].value;
    // console.log("kkkkkkkkkkkkkkkkkkkkkkkfffffffffffffff");
    // console.log(value);
    // console.log(data[parentIndex]);
    // console.log(value.includes(data[parentIndex].value));
    if (value.includes(data[parentIndex].value)) return true;
    return false;
  }

  const constraintParentId = data.sections.findIndex(
    (a) => a.sectionId == ele.activeOnCondition.conditions[0].parentId
  );
  const constraintId = data.sections[constraintParentId].elements.findIndex(
    (a) => a.id == ele.activeOnCondition.conditions[0].dependsOn
  );
  const value = ele.activeOnCondition.conditions[0].value;

  if (
    data.sections[constraintParentId].elements[constraintId].value == value &&
    ele.activeOnCondition.conditions.length == 1
  )
    return true;
  if (
    data.sections[constraintParentId].elements[constraintId].value == value &&
    ele.activeOnCondition.conditions[1]
  ) {
    const additionalConditionConstraintParentId = data.sections.findIndex(
      (a) => a.sectionId == ele.activeOnCondition.conditions[0].parentId
    );
    const additionalConditionConstraintId = data.sections[
      additionalConditionConstraintParentId
    ].elements.findIndex(
      (a) => a.id == ele.activeOnCondition.conditions[1].dependsOn
    );
    const additionalConditionValue = ele.activeOnCondition.conditions[1].value;
    return (
      data.sections[additionalConditionConstraintParentId].elements[
        additionalConditionConstraintId
      ].value == additionalConditionValue
    );
  }
};

export const getSteps = (data, param) =>
  param == "all"
    ? data.sections.filter((a) => a.type.toLowerCase() == "step")
    : data.sections
      .filter((a) => a.type.toLowerCase() == "step")
      .filter((a) => checkIfActive(a, data, "steps"));

export const RenderItem = (
  { elem,
    setFormConfig,
    formConfig,
    forceRerender,
    setForceRerender
  }
) => {

  const [ele, setEle] = useState(elem);
  useEffect(() => {
    setEle(elem);
  }, [elem, formConfig, forceRerender]);
  const sectionName = ele.name;
  if (ele.repeatElements) {
    return (
      <RepeatingObject
        ele={ele}
        setFormConfig={setFormConfig}
        formConfig={formConfig}
        forceRerender={forceRerender}
        setForceRerender={setForceRerender}
        label={ele.name}
      />
    );
  }
  if (ele.layout.type == "grid") {
    let rf = [];
    if (ele.layout.temp)
      rf = ele.elements.filter((a) => checkIfActive(a, formConfig));
    else rf = ele.elements;
    rf = ele.elements.filter((a) => checkIfActive(a, formConfig));
    const r = rf.map((a) => {
      if (a.linkedBackendKey) {
        console.log('formaayush', a.linkedBackendKey,)
        a.placeholder = formConfig.sections[0].elements.filter(x => x.backendName == a.linkedBackendKey)[0].value
      }
      // const Component = getComponent(a.questionType, a.value, a.options);
      const elementName = a.backendName;
      return a.isQuestionComplex ? (
        <div>
          {getComponent(
            null,
            null,
            sectionName,
            elementName,
            formConfig,
            setFormConfig,
            a.questionType,
            a.value,
            a.options,
            a.placeholder,
            a.props,
            forceRerender,
            setForceRerender,
            a.label
          )}
        </div>
      ) : (
        <GridItem area={a.itemKey}>
          <FormItemRow
            formItemReqd={true}
            text={a.label}
            itemName={a.backendName}
            itemSpan={a.props?.itemRowStyle && a.props?.itemRowStyle.itemSpan}
            offset={a.offset}
            rules={a.rules}
          >
            {getComponent(
              null,
              null,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              a.questionType,
              a.value,
              a.options,
              a.placeholder,
              a.props,
              forceRerender,
              setForceRerender,
              a.label,
              null,
              null,
              a.style ? a.style : {},
              formConfig.sections[0]
            )}
          </FormItemRow>
        </GridItem>
      );
    });
    return (
      <ZsCard
        key={ele.sectionId}
        bordered={false}
        bodyStyle={{ padding: "25px 25px" }}
        style={{
          height: "auto",
          maxHeight: "80vh",
          minHeight: "80vh",
          overflowY: "auto",
          marginBottom: "10px",
        }}
      >

        <Grid style={{ rowGap: "0px" }} template={ele.layout.template}>
          {r}
        </Grid>
      </ZsCard>
    );
  }
  if (ele.layout.type == "row-col") {
    const finalColGroup = [];
    ele.layout.template.map((rr) => {

      const colElements = ele.elements
        .filter((a) => checkIfActive(a, formConfig))
        .filter((a) => a.itemKey == rr[0])
        .map((a, i) =>
          a.isQuestionComplex ? (
            <div key={i}>
              {getComponent(
                null,
                null,
                sectionName,
                a.backendName,
                formConfig,
                setFormConfig,
                a.questionType,
                a.value,
                a.options,
                a.placeholder,
                a.props,
                forceRerender,
                setForceRerender,
                a.label
              )}
            </div>
          ) : (
            <FormItemRow
              key={i}
              formItemReqd={true}
              text={a.label}
              itemName={a.backendName}
              itemSpan={a.props?.itemRowStyle && a.props?.itemRowStyle.itemSpan}
              rules={a.rules}
            >
              {getComponent(
                null,
                null,
                sectionName,
                a.backendName,
                formConfig,
                setFormConfig,
                a.questionType,
                a.value,
                a.options,
                a.placeholder,
                a.props,
                forceRerender,
                setForceRerender,
                a.label
              )}
            </FormItemRow>
          )
        );
      finalColGroup.push(<ZsCol span={rr[1]}>{colElements}</ZsCol>);
    });
    return (
      <ZsCard
        key={ele.sectionId}
        bordered={false}
        bodyStyle={{ padding: "25px 25px" }}
        style={{
          height: "auto",
          maxHeight: "80vh",
          overflowY: "auto",
          marginBottom: "10px",
        }}
      >
        <ZsRow>{finalColGroup}</ZsRow>
      </ZsCard>
    );
  }
};

const RepeatingObject = ({
  ele,
  setFormConfig,
  formConfig,
  forceRerender,
  setForceRerender,
}) => {
  const template = ele.elementsTemplate;
  const sectionName = ele.name;
  const [values, setValues] = useState(ele.value);

  const remove = (id) => {
    setValues(values.filter((a) => a.id != id));
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );
    let temp = formConfig;
    temp.sections[sectionIndex].value = temp.sections[
      sectionIndex
    ].value.filter((a) => a.id != id);
    setFormConfig(temp);
  };

  const add = () => {
    const newObj = {
      id: values.length + 1,
      isRemovable: values.length == 0 ? false : true,
      elements: template.map((a, i) => {
        if (a.resetValue) {
          return {
            ...a,
            elementId: Number.parseInt(`${values.length + 1}${i + 1}`),
            backendName: `${values.length + 1}_${a.backendName}`,
            value: [],
          };
        }

        return {
          ...a,
          elementId: Number.parseInt(`${values.length + 1}${i + 1}`),
          backendName: `${values.length + 1}_${a.backendName}`,
        };
      }),
    };
    setValues([...values, newObj]);
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );
    let temp = formConfig;
    temp.sections[sectionIndex].value = [
      ...temp.sections[sectionIndex].value,
      newObj,
    ];
    setFormConfig(temp);
  };

  console.log(values);

  return (
    <>
      {values.map((e, i) => {
        const innerElementId = e.id;
        const r = e.elements
          .filter((a) => checkIfActive(a, formConfig, null, e))
          .map((a, j) => {
            // const Component = getComponent(a.questionType, a.value, a.options);
            const elementName = a.backendName;
            console.log(
              "ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss"
            );
            console.log(a);
            return a.isQuestionComplex ? (
              <GridItem key={`${innerElementId}_${i}_${j}`} area={a.itemKey}>
                {getComponent(
                  e,

                  innerElementId,
                  sectionName,
                  elementName,
                  formConfig,
                  setFormConfig,
                  a.questionType,
                  a.value,
                  a.options,
                  a.placeholder,
                  a.props,
                  forceRerender,
                  setForceRerender,
                  a.label
                )}
              </GridItem>
            ) : (
              <GridItem key={`${innerElementId}_${i}_${j}`} area={a.itemKey}>
                <FormItemRow
                  formItemReqd={true}
                  text={!a.hideLabel && a.label}
                  itemName={a.backendName}
                  itemSpan={
                    a.props?.itemRowStyle && a.props?.itemRowStyle.itemSpan
                  }
                  span={a.props?.itemRowStyle && a.props?.itemRowStyle.span}
                  offset={a.offset}
                  rules={a.rules}
                >
                  {getComponent(
                    e,
                    innerElementId,
                    sectionName,
                    elementName,
                    formConfig,
                    setFormConfig,
                    a.questionType,
                    a.value,
                    a.options,
                    a.placeholder,
                    a.props,
                    forceRerender,
                    setForceRerender,
                    a.label
                  )}
                </FormItemRow>
              </GridItem>
            );
          });
        return (
          <ZsCard
            key={innerElementId}
            bordered={false}
            bodyStyle={{ padding: "25px 25px" }}
            style={{
              height: "auto",
              maxHeight: "80vh",
              overflowY: "auto",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <ZsRow>
              <ZsCol span={22}>
                <Grid style={{ rowGap: "0px" }} template={ele.layout.template}>
                  {r}
                </Grid>
              </ZsCol>
              <ZsCol
                style={{ marginTop: "-10px", textAlign: "right" }}
                span={2}
              >
                {e.isRemovable && (

                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    style={{ marginTop: "4px", marginLeft: "8px" }}
                    onClick={() => remove(e.id)}
                  >
                    <ZsDeleteOutlined />
                  </Button>
                )}
              </ZsCol>
            </ZsRow>
          </ZsCard>
        );
      })}
      <div style={{ paddingTop: "10px" }}>
        {getComponent(
          null,
          null,
          null,
          null,
          null,
          null,
          ele.repeatStrategy.type,
          {
            ...ele.repeatStrategy,
            add,
          }
        )}
      </div>
    </>
  );
};

const onValueChange = (
  element,
  e,
  sectionName,
  elementName,
  formConfig,
  setFormConfig,
  forceRerender,
  setForceRerender,
  innerElementId,
  elementTypeForOnChange,
  backendName
) => {
  console.log(
    "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd"
  );
  console.log({
    element,
    e,
    sectionName,
    elementName,
    formConfig,
    setFormConfig,
    forceRerender,
    setForceRerender,
    innerElementId,
    elementTypeForOnChange,
    backendName,
  });
  if (typeof innerElementId == "string") {
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );

    const elementIndex = formConfig?.sections[sectionIndex]?.value?.findIndex(
      (a) => a.id == Number.parseInt(innerElementId.split("_")[1])
    );

    const innerElementIndex = formConfig?.sections[sectionIndex]?.value[
      elementIndex
    ]?.elements.findIndex((e) => e.backendName == elementName);

    const objIndex = formConfig?.sections[sectionIndex]?.value[
      elementIndex
    ]?.elements[innerElementIndex]?.value?.findIndex(
      (a) => a[0].id.split("_")[2] == innerElementId.split("_")[2]
    );

    const finalObjIndex = formConfig?.sections[sectionIndex]?.value[
      elementIndex
    ]?.elements[innerElementIndex]?.value[objIndex].findIndex(
      (a) => a.id == innerElementId
    );

    let newValues = formConfig;

    newValues.sections[sectionIndex].value[elementIndex].elements[
      innerElementIndex
    ].value[objIndex][finalObjIndex].value = e.target.value;
    setFormConfig(newValues);
    setForceRerender(forceRerender + 1);
  } else if (elementTypeForOnChange == "DynamicRowInput") {
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );
    console.log(sectionIndex);
    const elementIndex = formConfig?.sections[
      sectionIndex
    ]?.elements?.findIndex((a) => a.backendName == elementName);
    console.log(elementIndex);
    const innerElementIndex = formConfig?.sections[sectionIndex]?.elements[
      elementIndex
    ].value.findIndex((a) => a[0].elementId == element[0].elementId);
    const finalObjectIndex = formConfig?.sections[sectionIndex]?.elements[
      elementIndex
    ].value[innerElementIndex].findIndex((a) => a.backendName == backendName);
    console.log(
      formConfig?.sections[sectionIndex]?.elements[elementIndex].value[
      innerElementIndex
      ][finalObjectIndex]
    );
    let newValues = formConfig;
    newValues.sections[sectionIndex].elements[elementIndex].value[
      innerElementIndex
    ][finalObjectIndex].value = e.target.value;
    setFormConfig(newValues);
  } else if (element) {
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );
    const elementIndex = formConfig?.sections[sectionIndex]?.value?.findIndex(
      (a) => a.id == element.id
    );

    const innerElementIndex = formConfig?.sections[sectionIndex].value[
      elementIndex
    ]?.elements?.findIndex((a) => a.backendName == elementName);
    let newValues = formConfig;
    newValues.sections[sectionIndex].value[elementIndex].elements[
      innerElementIndex
    ].value = e.target.value;
    setFormConfig(newValues);
    setForceRerender(forceRerender + 1);
  } else {
    const sectionIndex = formConfig?.sections?.findIndex(
      (a) => a.name == sectionName
    );
    const elementIndex = formConfig?.sections[
      sectionIndex
    ]?.elements?.findIndex((a) => a.backendName == elementName);
    let newValues = formConfig;
    newValues.sections[sectionIndex].elements[elementIndex].value =
      e.target.value;
    setFormConfig(newValues);
    setForceRerender(forceRerender + 1);
  }
};

const getComponent = (
  element,
  innerElementId,
  sectionName,
  elementName,
  formConfig,
  setFormConfig,
  str,
  value,
  options,
  placeholder,
  propsFromConfig,
  forceRerender,
  setForceRerender,
  elementLabel,
  elementTypeForOnChange,
  backendName,
  style,
  elements
) => {
  console.log('getComponent', element,
    innerElementId,
    sectionName,
    elementName,
    formConfig,
    setFormConfig,
    str,
    value,
    options,
    placeholder,
    propsFromConfig,
    forceRerender,
    setForceRerender,
    elementLabel,
    elementTypeForOnChange,
    backendName,
    style,
    elements)
  let tempArray = []
  switch (str) {
    case "Text":
      return (
        <Input
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              e,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId,
              elementTypeForOnChange,
              backendName
            )
          }
          placeholder={placeholder}
          value={value}
          defaultValue={value}
        />
      );
    case "Text2":
      if (element) {
        if (element.id && element.id > 1) {
          tempArray.push({ id: element.id, name: `Audience Group ${element.id}` })
        }
      }
      console.log("getComponentsss", tempArray)
      return (
        <Input
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              e,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId,
              elementTypeForOnChange,
              backendName
            )
          }
          placeholder={placeholder}
          value={value}
          defaultValue={`${value} ${element?.id}`}
        />
      );
    case "TextRange":
      return (<>
        <Input
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              e,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId,
              elementTypeForOnChange,
              backendName
            )
          }
          placeholder={placeholder}
          value={value}
          defaultValue={value}
        />
        <span>to</span>
        <Input
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              e,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId,
              elementTypeForOnChange,
              backendName
            )
          }
          placeholder={placeholder}
          value={value}
          defaultValue={value}
        />
      </>
      );
    case "DateRange":
      return (
        <ZsRangePicker
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              { target: { value: e } },
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender
            )
          }
          value={value}
          defaultValue={value}
        />
      );
    case "Radio":
      return (
        <ZsRadioButton
          onChange={(e) =>
            onValueChange(
              element,
              e,
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender
            )
          }
          value={value}
          defaultValue={value}
          options={options.map((a) => ({ value: a.id, label: a.label }))}
        />
      );
    case "Date":
      return (
        <ZsDatePicker
          style={style}
          onChange={(e) =>
            onValueChange(
              element,
              { target: { value: e } },
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender
            )
          }
          value={value}
        />
      );
    case "Dropdown":
      return (
        <ZsSelect
          style={{ ...{ minWidth: "150px" }, ...style }}
          defaultValue={value}
          values={options.map((a) => ({ id: a.id, name: a.label }))}
          onChange={(e) =>
            onValueChange(
              element,
              { target: { value: e } },
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId
            )
          }
        />
      );
    case "Dropdown2":
      return (
        <ZsSelect
          style={{ ...{ minWidth: "150px" }, ...style }}
          defaultValue={value}
          values={options.length === 1 ? options.map((a) => ({ id: a.id, name: a.label })) : tempArray}
          onChange={(e) =>
            onValueChange(
              element,
              { target: { value: e } },
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender,
              innerElementId
            )
          }
        />
      );
    case "Switch":
      return (
        <>
          <Typography.Small>{options[0].label}</Typography.Small>
          &nbsp;
          <ZsSwitch
            defaultChecked={value}
            onChange={(e) =>
              onValueChange(
                element,
                { target: { value: e } },
                sectionName,
                elementName,
                formConfig,
                setFormConfig,
                forceRerender,
                setForceRerender
              )
            }
          />
          &nbsp;
          <Typography.Small>{options[1].label}</Typography.Small>
        </>
      );
    case "DynamicRowInput":
      return (
        <DynamicRowInput
          innerElementId={innerElementId}
          formConfig={formConfig}
          setFormConfig={setFormConfig}
          propsFromConfig={propsFromConfig}
          elementName={elementName}
          sectionName={sectionName}
          forceRerender={forceRerender}
          setForceRerender={setForceRerender}
          value={value}
          label={elementLabel}
        />
      );
    case "Button":
      // const { type, icon, label, add } = value;
      return (
        <ZsButton
          type="ghosted"
          onClick={() => value.add()}
          icon={getIcon(value.icon)}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "#0988ab",
            borderColor: "#0988ab",
            alignItems: "center",
          }}
        >
          {value.label}
        </ZsButton>
      );

    case "MultiSelect":
      return (
        <ZsMultiSelect
          onChange={(e) =>
            onValueChange(
              element,
              { target: { value: e } },
              sectionName,
              elementName,
              formConfig,
              setFormConfig,
              forceRerender,
              setForceRerender
            )
          }
          defaultValue={options.map((a) => (a.label))}
          values={options.map((a) => ({ id: a.id, name: a.label }))}
          style={style}
        />
      );
    // case "ABTestAnalyse":
    //   return (
    //     <ABTestAnalyse value={value}></ABTestAnalyse>
    //   )
    // case "ABTestAnalyse2":
    //   return (

    //     <ABTestAnalyse2 elements={elements} formConfig={formConfig}></ABTestAnalyse2>
    //   )
    // case "ABTestAnalyse3":
    //   return (

    //     <ABTestAnalyse3 elements={elements} formConfig={formConfig}></ABTestAnalyse3>
    //   )
    case "Series":
      return (
        <ZsSpace>
          {options.map((b, i) => (
            <div key={i} style={{ display: "grid" }}>
              {!b.hideLabel && (
                <span style={{ color: "#0988ab" }}>{b.label}</span>
              )}
              <ZsInput
                style={{
                  width: "90%",
                }}
                placeholder={b.label}
                value={b.value}
              />
            </div>
          ))}
        </ZsSpace>
      );
    case "Typo":
      return (
        <h4

          style={{ ...{ color: "#15afd0" }, ...style }}
        >{placeholder}</h4>
      )
    case "SearchTextWithResults":
      return (
        <SearchTextWithResults
          propsFromConfig={propsFromConfig}
          placeholder={placeholder}
          label={elementLabel}
        />
      );

    case "Dendogram":
      return (
        <div>
          <Dendogram data={value}></Dendogram>

        </div>
      );
    default:
      return <>default</>;
  }
};

const DynamicRowInput = ({
  innerElementId,
  propsFromConfig,
  sectionName,
  elementName,
  formConfig,
  setFormConfig,
  forceRerender,
  setForceRerender,
  value: objectValue,
  label,
}) => {
  const sectionIndex = formConfig?.sections?.findIndex(
    (a) => a.name == sectionName
  );

  const { itemRowStyle, template, incrementStrategy } = propsFromConfig;

  const [values, setValues] = useState(objectValue);

  const removeElement = (index) => {
    console.log("deleteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    console.log(formConfig);
    setValues(values.filter((a) => a[0].elementId != index));
    // const sectionIndex = formConfig?.sections?.findIndex(
    //   (a) => a.name == sectionName
    // );
    if (innerElementId) {
      const innerElementIndex = formConfig?.sections[
        sectionIndex
      ].value.findIndex((a) => a.id == innerElementId);
      const elementIndex = formConfig?.sections[sectionIndex]?.value[
        innerElementIndex
      ]?.elements?.findIndex((a) => a.backendName == elementName);
      let newValues = formConfig;
      newValues.sections[sectionIndex].value[innerElementIndex].elements[
        elementIndex
      ].value = newValues.sections[sectionIndex].value[
        innerElementIndex
      ].elements[elementIndex].value.filter((a) => a[0].elementId != index);

      setFormConfig(newValues);
      setForceRerender(forceRerender + 1);
    } else {
      const elementIndex = formConfig?.sections[
        sectionIndex
      ]?.elements?.findIndex((a) => a.backendName == elementName);
      let newValues = formConfig;
      newValues.sections[sectionIndex].elements[elementIndex].value =
        newValues.sections[sectionIndex].elements[elementIndex].value.filter(
          (a) => a[0].elementId != index
        );
      setFormConfig(newValues);
      setForceRerender(forceRerender + 1);
    }
  };

  const add = () => {
    console.log(innerElementId);
    console.log("values", values, template, innerElementId);
    if (innerElementId) {
      setValues([
        ...values,
        template.map((a, i) => ({
          id: `${sectionIndex}_${innerElementId}_${values.length + 1}_${i + 1
            }_${a.backendName}`,
          type: a.type,
          isRemovable: a.isRemovable,
          value: null,
          placeholder:
            a.backendName == "weight" ? "Weight" : `V${values.length}`,
          elementId: values.length + 1,
          options: a.options,
          rules: a.rules,
          backendName: innerElementId
            ? `${sectionIndex}_${innerElementId}_${values.length + 1}_${i + 1
            }_${a.backendName}`
            : a.backendName,
          activeOnCondition: a.activeOnCondition,
        })),
      ]);

      const innerElementIndex = formConfig?.sections[
        sectionIndex
      ].value.findIndex((a) => a.id == innerElementId);
      const elementIndex = formConfig?.sections[sectionIndex]?.value[
        innerElementIndex
      ]?.elements?.findIndex((a) => a.backendName == elementName);

      let newValues = formConfig;
      newValues?.sections[sectionIndex]?.value[innerElementIndex]?.elements[
        elementIndex
      ]?.value?.push(
        template.map((a, i) => ({
          id: `${sectionIndex}_${innerElementId}_${values.length + 1}_${i + 1
            }_${a.backendName}`,
          type: a.type,
          isRemovable: a.isRemovable,
          value: null,
          placeholder:
            a.backendName == "weight" ? "Weight" : `V${values.length}`,
          elementId: values.length + 1,
          options: a.options,
          activeOnCondition: a.activeOnCondition,
          rules: a.rules,
          backendName: innerElementId
            ? `${sectionIndex}_${innerElementId}_${values.length + 1}_${i + 1
            }_${a.backendName}`
            : a.backendName,
        }))
      );
      setFormConfig(newValues);
      setForceRerender(forceRerender + 1);
    } else {
      setValues([
        ...values,
        template.map((a, i) => ({
          id: values.length + i,
          type: a.type,
          isRemovable: a.isRemovable,
          value: 1,
          placeholder:
            a.backendName == "weight" ? "Weight" : `V${values.length}`,
          elementId: values.length + 1,
          options: a.options,
          backendName: innerElementId
            ? `${innerElementId}_${values.length + 1}_${i + 1}_${a.backendName}`
            : a.backendName,
          activeOnCondition: a.activeOnCondition,
          rules: a.rules,
        })),
      ]);

      const elementIndex = formConfig?.sections[
        sectionIndex
      ]?.elements?.findIndex((a) => a.backendName == elementName);
      let newValues = formConfig;
      newValues?.sections[sectionIndex]?.elements[elementIndex]?.value?.push(
        template.map((a, i) => ({
          id: values.length + i,
          type: a.type,
          isRemovable: a.isRemovable,
          value: 1,
          placeholder:
            a.backendName == "weight" ? "Weight" : `V${values.length}`,
          elementId: values.length + 1,
          options: a.options,
          backendName: a.backendName,
          activeOnCondition: a.activeOnCondition,
          rules: a.rules,
        }))
      );
      setFormConfig(newValues);
      setForceRerender(forceRerender + 1);
    }
    // finalItemToRender.push(<Input />);
  };

  useEffect(() => {
    setValues(objectValue);
    // const temp = [];
    // console.log(values);
    // values?.map((b, i) => {
    //   temp.push(
    //     <div
    //       key={`${sectionIndex}_${innerElementId}_${i}`}
    //       // style={{ padding: "10px 0px" }}
    //     >
    //       {itemRowStyle.showIndex && (
    //         <>
    //           <ZsAvatar style={{ marginRight: "10px", marginTop: "-2px" }}>{`${
    //             itemRowStyle.prefix
    //           }${i + 1}`}</ZsAvatar>
    //         </>
    //       )}
    //       <ZsSpace>
    //         {b
    //           ?.filter((a) => checkIfActive(a, b, "DynamicRowInput"))
    //           .map((a, j) => (
    //             <>
    //               <Form.Item
    //                 key={`${sectionIndex}_${innerElementId}_${i}_${j}_${Math.floor(
    //                   Math.random() * 10000
    //                 )}`}
    //                 name={`${sectionIndex}_${innerElementId}_${i}_${j}_${Math.floor(
    //                   Math.random() * 10000
    //                 )}`}
    //               >
    //                 {getComponent(
    //                   b,
    //                   a.id,
    //                   sectionName,
    //                   elementName,
    //                   formConfig,
    //                   setFormConfig,
    //                   a.type,
    //                   a.value,
    //                   a.options,
    //                   a.placeholder,
    //                   null,
    //                   forceRerender,
    //                   setForceRerender,
    //                   null,
    //                   "DynamicRowInput",
    //                   a.backendName
    //                 )}
    //               </Form.Item>
    //             </>
    //           ))}
    //         {b[0].isRemovable && (
    //           <Form.Item name={`${i}-${b[0].id}`}>
    //             <Button
    //               type="primary"
    //               shape="circle"
    //               size="small"
    //               style={{ marginTop: "4px", marginLeft: "8px" }}
    //             >
    //               <ZsDeleteOutlined
    //                 onClick={() => removeElement(b[0].elementId)}
    //               />
    //             </Button>
    //           </Form.Item>
    //         )}
    //       </ZsSpace>
    //     </div>
    //   );
    // });
    // setFinalItemToRender(temp);
  }, [values, propsFromConfig, forceRerender]);

  console.log(values);
  return (
    <ZsRow>
      <ZsCol span={24}>
        <ZsRow>
          <ZsCol span={itemRowStyle.span} style={{ color: "#15afd0 !important" }}>{label}</ZsCol>
          <ZsCol
            style={{ paddingBottom: "20px" }}
            offset={itemRowStyle.offset}
            span={itemRowStyle.itemSpan}
          >
            <div
              style={{
                maxHeight: itemRowStyle.maxHeight || "auto",
                overflow: itemRowStyle.maxHeight ? "scroll" : "hidden",
                overflowX: "hidden",
                marginBottom: "10px",
              }}
            >
              {values?.map((b, i) => {
                return (
                  <div
                    key={`${sectionIndex}_${innerElementId}_${i}`}
                  // style={{ padding: "10px 0px" }}
                  >
                    {itemRowStyle.showIndex && (
                      <>
                        <ZsAvatar
                          style={{ marginRight: "10px", marginTop: "-2px" }}
                        >{`${itemRowStyle.prefix}${i + 1}`}</ZsAvatar>
                      </>
                    )}
                    <ZsSpace>
                      {b
                        ?.filter((a) => checkIfActive(a, b, "DynamicRowInput"))
                        .map((a, j) => (
                          <>
                            <Form.Item
                              key={`${sectionIndex}_${innerElementId}_${i}_${j}_${a.backendName}`}
                              name={`${sectionIndex}_${innerElementId}_${i}_${j}_${a.backendName}`}
                              rules={a.rules}
                            >
                              {getComponent(
                                b,
                                a.id,
                                sectionName,
                                elementName,
                                formConfig,
                                setFormConfig,
                                a.type,
                                a.value,
                                a.options,
                                a.placeholder,
                                null,
                                forceRerender,
                                setForceRerender,
                                null,
                                "DynamicRowInput",
                                a.backendName
                              )}
                            </Form.Item>
                          </>
                        ))}
                      {b[0].isRemovable && (
                        <Form.Item name={`${i}-${b[0].id}`}>
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            style={{ marginTop: "4px", marginLeft: "8px" }}
                          >
                            <ZsDeleteOutlined
                              onClick={() => removeElement(b[0].elementId)}
                            />
                          </Button>
                        </Form.Item>
                      )}
                    </ZsSpace>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                marginLeft: itemRowStyle.marginLeft || "auto",
                marginTop: "-10px",
              }}
            >
              {getComponent(
                null,
                null,
                null,
                null,
                null,
                null,
                incrementStrategy.type,
                {
                  ...incrementStrategy,
                  add,
                }
              )}
            </div>
          </ZsCol>
        </ZsRow>
      </ZsCol>
    </ZsRow>
  );
};

const getIcon = (str) => {
  switch (str) {
    case "Add":
      return <ZsPlusOutlined />;
    default:
      break;
  }
};

const SearchTextWithResults = ({ propsFromConfig }) => {
  const [/* searchText, */ setSearchText] = useState(null);
  const [activeTarget, setActiveTarget] = useState(null);
  const {
    data: { rowsData },
  } = propsFromConfig;

  return (
    <>
      <Input
        prefix={<SearchOutlined className="custom-site-form-item-icon" />}
        placeholder="Search by name/ type/ domain"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ margin: "5px 0px 15px 0px", width: "50%" }}
      />
      <div style={{ width: "50%" }}>
        {rowsData.map((a, i) => (
          <div
            className={`target-list-custom-wrapper ${activeTarget == i
              ? `active-target-list-custom-wrapper`
              : `inactive-target-list-custom-wrapper`
              }`}
            key={i}
            style={{ marginBottom: "12px", padding: "8px" }}
            onClick={() => {
              setActiveTarget(i);
            }}
          >
            <Grid
              style={{ rowGap: "0px" }}
              template={[
                ["name", "name", "count", "control", "icon"],
                ["tags", "tags", "count-text", "control-text", "icon"],
              ]}
            >
              <GridItem style={{ fontWeight: "500" }} area="name">
                {a.name}
              </GridItem>
              <GridItem
                style={{ textAlign: "center", color: "#15afd0" }}
                area="count"
              >
                {numeral(a.no_of_records).format("0.00a")}
              </GridItem>
              <GridItem
                style={{
                  textAlign: "center",
                  fontSize: "11.2px",
                  color: "rgb(154, 155, 158)",
                }}
                area="count-text"
              >
                Number of records
              </GridItem>
              <GridItem
                style={{
                  textAlign: "center",
                  fontSize: "11.2px",
                  color: "rgb(154, 155, 158)",
                }}
                area="control-text"
              >
                Number of variables
              </GridItem>
              <GridItem
                style={{ textAlign: "center", color: "#15afd0" }}
                area="control"
              >
                {a.no_of_variables}
              </GridItem>
              <GridItem area="tags">
                <ZsTag style={{ marginTop: "2px", fontSize: "10px" }}>
                  {a.type}
                </ZsTag>
                <ZsTag
                  style={{
                    marginRight: "0px",
                    marginTop: "2px",
                    fontSize: "10px",
                  }}
                >
                  {a.domain}
                </ZsTag>
              </GridItem>
              <GridItem style={{ alignSelf: "center" }} area="icon">
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginBottom: "7px",
                    }}
                  >
                    {a.file_type == ".csv" ? (
                      <FileCsv />
                    ) : a.file_type == ".xlsx" ? (
                      <FileExcel />
                    ) : a.file_type == ".pdf" ? (
                      <FilePdf />
                    ) : a.file_type == "db" ? (
                      <Database />
                    ) : null}
                  </div>
                  {activeTarget == i && (
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        marginLeft: "25px",
                        marginBottom: "-5px",
                      }}
                    >
                      <ZsCheckCircleOutlined
                        size="large"
                        style={{
                          color: "#15afd0",
                          transform: "translateY(-12px)",
                          display: "block",
                        }}
                      />
                    </div>
                  )}
                </div>
              </GridItem>
              <GridItem area="control"></GridItem>
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
};