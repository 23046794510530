import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import lodash from "lodash";
import { Space } from "@pai-ui/core/components/space";
import { Metric } from "@pai-ui/core/components/metric";
import { Tag } from "@pai-ui/core/components/tag";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Popover } from "@pai-ui/core/components/popover";
import { UserContext } from "@xai/providers/with-auth";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import { statusEnum } from "@xai/assets/common/enums";
import { MetricClass } from "@xai/assets/common/cssClasses";
import { splitByDelimiterAndToStartCase } from "@xai/pages/transformer";
import {
  // ZsDeleteOutlined,
  ZsEyeOutlined,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import "./index.css";

export const ListItem = ({
  item,
  // handleDeleteExperiment,
}) => {
  const userState = React.useContext(UserContext);
  const { experimentModule } = React.useContext(UserContext);

  const navigate = useNavigate();

  const checkVaildStatus = ["Completed", "Published"];

  const viewHandler = () => {
    let selectedExp = experimentModule?.experimentList?.find(
      (experiment) => experiment.id === item.experiment_id
    );

    if (selectedExp) {
      let validStatus = checkVaildStatus.find(
        (status) => status === statusEnum[item.status].name
      );

      if (item.request_from_screen === "analyze") {
        let rangeVariable = item.prior_date_range
          ? "send_out_dates"
          : "custom_ranges";

        let finalobj = {
          ...experimentModule,
          selectedExperiment: selectedExp,
          analyze_screen_selected_cohort: validStatus
            ? [rangeVariable, item.id]
            : null,
          analyze_screen_selected_task: item,
        };
        userState.dispatch({
          type: "experimentModule",
          payload: finalobj,
        });
        // history.push({
        //   pathname: "/tests/analyze",
        // });
        navigate("/tests/analyze");
      } else if (item.request_from_screen === "results") {
        let rangeVariable = item.prior_date_range
          ? "send_out_dates"
          : "custom_ranges";

        let finalobj = {
          ...experimentModule,
          selectedExperiment: selectedExp,
          result_screen_selected_cohort: validStatus
            ? [rangeVariable, item.id]
            : null,
        };

        userState.dispatch({
          type: "experimentModule",
          payload: finalobj,
        });
        // history.push({
        //   pathname: "/measure/results",
        // });
        navigate("/measure/results")
      }
    }
  };

  return (
    <div className="task-management-row-section">
      <Row style={{ cursor: "pointer", marginBottom: 10 }}>
        <Col span={24} style={{ padding: "0px 0px 0px 0px" }}>
          <LegacyPanel className={"hover-section"} round disableOverflow>
            <LegacyPanel
              flexible
              background="rgba(255,255,255,0.03)"
              paddingX
              paddingY
            >
              <LegacyPanel flexible paddingY>
                <Row align="middle">
                  <Col span={5} style={{ alignSelf: "flex-start" }}>
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Experiment Name"
                      subTitle={`${item.experiment_name}`}
                    />
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */
                    style={{ /* paddingTop: "14px", */ textAlign: "center" }}
                  >
                    <Tag
                      style={{ width: 75 }}
                      color={statusEnum?.[item.status]?.color}
                    >
                      {lodash.upperFirst(statusEnum?.[item.status]?.name)}
                    </Tag>
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Screen"
                      subTitle={`${splitByDelimiterAndToStartCase(
                        item.request_from_screen
                      )}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={3}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Request type"
                      subTitle={`${splitByDelimiterAndToStartCase(
                        item.request_type
                      )}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={4}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Duration"
                      subTitle={`${moment(item.start_time_kpi).format(
                        // "MM/DD/YYYY"
                        "Do MMM YYYY"
                      )} - ${moment(item.end_time_kpi).format(
                        // "MM/DD/YYYY"
                        "Do MMM YYYY"
                      )}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Version"
                      subTitle={`V${item.version}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={3}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="User"
                      subTitle={`${item.created_by}`}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={2}
                    /* offset={1} */ style={{
                      /* paddingTop: "14px", */
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Metric
                      withBackground={false}
                      classes={MetricClass}
                      title="Requested on"
                      subTitle={moment(item.created).format("MM/DD/YYYY")}
                      textAlign="right"
                    />
                  </Col>
                  <Col
                    span={1}
                    // offset={1}
                    style={{
                      /* paddingTop: 5, */
                      textAlign: "right",
                    }}
                  >
                    {/* 
                    TODO : Delete and view functionalities to be discussed and confirmed
                    */}
                    <Space>
                      {/* <Popover
                        placement="top"
                        title={""}
                        content={"Delete Request"}
                        trigger="hover"
                      >
                        <ZsDeleteOutlined style={{ fontSize: 20 }} />
                      </Popover> */}
                      <div
                        // TODO - update url path
                        // to={`/tests/analyze`}
                        style={{
                          // Here Route link for Analyze is Disabled since Analyze screen is in Out of Scope
                          color: ((item.request_from_screen !== "analyze") && checkVaildStatus.find(
                            (status) => status === statusEnum[item.status].name
                          ))
                            ? "#15afd0"
                            : "gray",
                        }}
                        className={
                          (!checkVaildStatus.find(
                            (status) => status === statusEnum[item.status].name
                          ) || (item.request_from_screen === "analyze")) && "disable-link"
                        }
                        onClick={() => viewHandler()}
                      >
                        <Popover
                          placement="top"
                          title={""}
                          content={"View"}
                          trigger="hover"
                        >
                          <ZsEyeOutlined style={{ fontSize: 20 }} />
                        </Popover>
                      </div>
                    </Space>
                  </Col>
                </Row>
              </LegacyPanel>
            </LegacyPanel>
          </LegacyPanel>
        </Col>
      </Row>
    </div>
  );
};
