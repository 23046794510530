
export const GenerateSuperSetFormUpsertObject = (
    updatedValues,
    ddnMaster
  ) => {
    console.log("GenerateSuperSetFormUpsertObject", updatedValues, ddnMaster);
    let returner = {
        "input_json":{
          "route":updatedValues.route.join('|||||'),
          "subroute":updatedValues.subrouteName,
          "hide_filters":updatedValues.hideFilters,
          "is_active":true,
          "dashboard_id": updatedValues.dashboard,
          "tags": updatedValues.tags,
          "report_name": updatedValues.reportName
        }
    };
    console.log('returner', returner)
    return returner;
  };