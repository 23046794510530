import React, { useState, useEffect } from "react";
import { Button } from '@pai-ui/core/components/button';
import { UserContext } from "@xai/providers/with-auth";
import { Grid, GridItem } from "@xai/library/containers/grid";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import DesignServiceHelper from "@xai/pages/design-experiment/service-helper";
import ExperimentCard from "../card";
import FormLayout from "../form-layout";
import { convertRawExpData } from "../transformer";

const CardLayout = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [cardLayoutData, setCardLayoutData] = useState([]);
    const [templateGrid, setTemplateGrid] = useState([]);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [experimentToLoad, setExperimentToLoad] = useState(null);

    const { token } = React.useContext(UserContext);
    const service = new DesignServiceHelper(token);

    // console.log(templateGrid);

    const Load = async () => {
        setLoading(true);
        setError(false);
        await service.getAllDesignExperiments()
            .then(async (res) => {
                console.log('getAllDesignExperiments Data', res);
                setCardLayoutData(res);
                // setLineChartData(null);
                setLoading(false);
            })
            .catch((err) => {
                console.log('getAllDesignExperiments Error', err);
                setError(true);
            });
    };

    useEffect(() => {
        Load();
    }, []);

    useEffect(() => {
        if (cardLayoutData) {
            templateGridFunc();
        }
    }, [cardLayoutData]);

    const templateGridFunc = (split = 4) => {
        if (cardLayoutData?.items) {
            let tempgrid = cardLayoutData?.items?.map(card => `templateCard-${card.id}`)
            let tempArray = []
            let tempArray2 = []
            for (let i = 0; i < Math.ceil(tempgrid?.length / split); i++) {
                if (i === 0) {
                    tempArray2 = tempgrid.slice(0, split)
                } else {
                    tempArray2 = tempgrid.slice(i * split, (i * split) + split)
                }
                // condition to fill empty grids
                if (tempArray2.length < split){
                    const emptySpacesReqd = split-tempArray2.length;
                    for(let j = 0; j < emptySpacesReqd; j++) {
                        tempArray2.push(`emptyCard-${i}-${j}`)
                    }
                }
                tempArray.push(tempArray2)
            }
            console.log('templateGrid', tempArray)
            setTemplateGrid(tempArray)
        }
    }

    const handleTemplateSelection = (id) => {

        var currentTemplateData = null;
        var processedData = null;
        //1. Get Template data by api call or from local

        let template = cardLayoutData?.items?.find(
            (templateObj) => templateObj.id === (id)
        );
        // console.log("templateList", templateList, selectedType, id, actionItem,templateData.filter((x) => x.template_id === id)[0]);
        if (template.isBlank == true) {

            processedData = [];
        } else {
            //get the data for template
            console.log("templateList", id, currentTemplateData, cardLayoutData)
            currentTemplateData = template?.template_data_table?.template_data;
            //2. convert data into form required format
            console.log("templateList", id, currentTemplateData);
            processedData = convertRawExpData(currentTemplateData);
        }

        console.log(
            "processed data and list",
            processedData,
            template.form_elements,
            template
        );
        setExperimentToLoad(processedData);
        //setExperimentToLoad([]);
    };


    if (error) {
        return (
            <LegacyNoData height={"95vh"} error={true}>
                <Button onClick={() => Load()}>Try Again</Button>
            </LegacyNoData>
        );
    }

    return (
        <div>
            {
                loading ?
                    <LegacyNoData height={"95vh"} loading={true} />
                    :
                    <>
                        {templateSelected ?
                            <>
                                <FormLayout
                                    templateSelected={templateSelected}
                                    setTemplateSelected={setTemplateSelected}
                                    experimentToLoad={experimentToLoad}
                                />
                            </>
                            :
                            <>
                                {
                                    templateGrid && templateGrid?.length > 0 &&
                                    <Grid template={templateGrid} > 
                                        {
                                            cardLayoutData?.items?.map((card) => (
                                                <GridItem key={card?.id} area={`templateCard-${card.id}`}>
                                                    <ExperimentCard
                                                        cardData={card}
                                                        setTemplateSelected={setTemplateSelected}
                                                        handleTemplateSelection={handleTemplateSelection}
                                                    />
                                                </GridItem>
                                            ))
                                        }
                                    </Grid>
                                }

                            </>
                        }
                    </>
            }
        </div >
    );
};

export default CardLayout;