import axios from "axios";
import { dotNetApi } from "@xai/library/clients";

let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");

export const getAllExperimntTaskRequestlist = (authToken) =>
  new Promise((resolve, reject) => {
    if (mock) {
      // mock response for this api call
      axios.get(`${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetAllExperimntTaskRequestlist.json`,
        {
          skipInterceptors: true
        })
        .then((res) => {
          const { data } = res;
          console.log("GetAllExperimntTaskRequestlist data", data, authToken);
          // custom filter to exclude items with version>1 & screen = 'results';
          data.items = data?.items?.filter(
            (item) =>
              !(item.request_from_screen === "results" && item.version > 1)
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      console.log("GetAllExperimntTaskRequestlist dataConfig");
      dotNetApi
        .post(
          `/ExperimentTaskManagements`,
          {
            query: `{
              experimentTaskManagement(pageSize: 10000, page: 1) {
                items {
                  id
                  experiment_id: experimentId
                  experiment_name: experimentName
                  prior_date_range: priorDateRange
                  start_time_kpi: startTimeKpi
                  end_time_kpi: endTimeKpi
                  request_from_screen: requestFromScreen
                  request_type: requestType
                  status
                  is_active: isActive
                  created
                  created_by: createdBy
                  bias_correction_required: biasCorrectionRequired
                  version
                  bias_correction_report: biasCorrectionReport
                  send_out_date:sendOutDate
                  send_out_week:sendOutWeek
                }
              }
            }`,
          }
          // #TODO : use headers if required
          // {
          //   headers: {
          //     "x-api-key": process.env.REACT_APP_API_KEY,
          //     Authorization: "Bearer " + authToken,
          //   },
          // }
        )
        .then((res) => {
          const { experimentTaskManagement } = res?.data?.data;
          // experimentTaskManagement.experimentsTaskList =
          //   experimentTaskManagement.items;
          // delete experimentTaskManagement.items;

          console.log(
            "GetAllExperimntTaskRequestlist data",
            // res,
            experimentTaskManagement
          );
          // custom filter to exclude items with screen = 'results' & ( version>1 or prior_date_range !== "last_7_days")
          experimentTaskManagement.items =
            experimentTaskManagement?.items?.filter(
              (item) =>
                !(
                  item.request_from_screen === "results" &&
                  (item.version > 1 || item.prior_date_range !== "last_7_days")
                )
            );
          resolve(experimentTaskManagement);
        })
        .catch((error) => {
          reject(error);
        });
      // #TODO: Need to make associated changes across various files due to change in response structure
    }
  });
