import React, { createContext, useContext, useState } from "react";

const SubjectLineFormContext = createContext();

const defaultConfig = {
  basicName: "", // Experiment name should be empty
  objective: "Objective Name",
  frequency: "weekly",
  responseMetric: "opens",
  message: "info",
  variantAllocation: "random",
  selectedVariant: 0,
  simpleLayout: false,
  targetAudience: "Target Audience",
  campaignPerformance: "Campaign Performance",
  currentState: "Current State",
  variants: [
    {
      id: 0,
      audience: "",
      context: "",
      name: "Global",
      product: "",
      selectedFields: [
        "output_type",
        "name",
        "summary",
        "context",
        "product",
        "audience",
        "tone",
      ],
      summary: "",
      tone: "",
      output_type: "",
      metaData: {},
    },
  ],
  metaData: {},
};

const SubjectLineFormConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultConfig);
  const [basicFormValidStatus, setBasicFormValidStatus] = useState(false);
  const [selectedExperiment, setSelectedExperiment] = useState(null);

  const updateConfig = (newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  return (
    <SubjectLineFormContext.Provider
      value={{
        config,
        updateConfig,
        basicFormValidStatus,
        setBasicFormValidStatus,
        selectedExperiment,
        setSelectedExperiment,
      }}
    >
      {children}
    </SubjectLineFormContext.Provider>
  );
};

const useSubjectLineFormConfig = () => {
  const context = useContext(SubjectLineFormContext);
  if (!context) {
    throw new Error("context is not being used properly");
  }
  return context;
};

export { SubjectLineFormConfigProvider, useSubjectLineFormConfig };
