export const getEnterpriseLedgerConfig = (
  start_time,
  end_time
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config,
   *          experiment_variant_parameters_results,
   *          experiment_variant_meta_data
   * Props : experiment_id,
   *         start_time_kpi,
   *         end_time_kpi,
   *         group_view
   */

  let input_json = {
    start_time_kpi: start_time,
    end_time_kpi: end_time
  };

  return input_json;
};