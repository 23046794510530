import React from "react";
import { Tooltip } from '@pai-ui/core/components/tooltip';
import { Popover } from "@pai-ui/core/components/popover";
import { Divider } from '@pai-ui/core/components/divider';
// import ChartWrapper from "@xai/assets/generic-components/visualization/components/wrapper";
// import Carousel from 'react-gallery-carousel';
// import 'react-gallery-carousel/dist/index.css';

const ImageCarousel = ({ imageCarouselList, product, variantLabelCountLimit, fontSizeCardLabel }) => {

    const localProductLabel = product?.audience_group_variant_label;

    return (
        <>
            {/* <ChartWrapper>
                {({ width, height }) => (
                    <div style={{ height: 2.5 * height, width: width }} >
                        <Carousel
                            images={imageCarouselList?.map((imageObj) => {
                                return {
                                    src: imageObj?.image
                                }
                            })}
                            width={width}
                            height={height}
                            hasIndexBoard={false}
                            objectFit={"cover"}
                            objectFitAtMax={"contain"}
                            hasSizeButton={false}
                            zIndexAtMax={1000}
                        />
                    </div>

                )}
            </ChartWrapper> */}
            {
                imageCarouselList?.[0]?.image ?
                    <div style={{
                        height: "25vh",
                        display: "flex",
                        justifyContent: "space-around",
                    }}>
                        <img
                            src={imageCarouselList?.[0]?.image}
                            alt=""
                            style={{ height: "inherit" }}
                        />
                    </div>
                    :
                    <div style={{
                        height: "25vh",
                        display: "flex",
                        justifyContent: "space-around",
                        backgroundColor: "#11141994",
                        borderRadius: "25px",
                        padding: "0px 5px"
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: fontSizeCardLabel,
                            fontWeight: 400,
                            color: "#15afd0",
                            textAlign: "center",
                        }}>
                            {
                                localProductLabel?.includes('||||') ?
                                    <Popover
                                        placement="top"
                                        title={localProductLabel?.split('||||')?.[0]}
                                        content={`Body: ${localProductLabel?.split('||||')?.[1]}`}
                                        trigger="hover"
                                        overlayStyle={{
                                            maxWidth: "30%"
                                        }}
                                    >
                                        {
                                            localProductLabel?.length > variantLabelCountLimit ?
                                                <div>
                                                    <div>{localProductLabel?.split('||||')?.[0]?.slice(0, variantLabelCountLimit)?.concat("...")}</div>
                                                    <Divider orientation="left" style={{ margin: "5px" }} />
                                                    <div>{localProductLabel?.split('||||')?.[1]?.slice(0, variantLabelCountLimit)?.concat("...")}</div>
                                                </div> :
                                                <div>
                                                    <div>{localProductLabel?.split('||||')?.[0]}</div>
                                                    <Divider orientation="left" style={{ margin: "5px" }} />
                                                    <div>{localProductLabel?.split('||||')?.[1]}</div>
                                                </div>
                                        }
                                    </Popover>
                                    :
                                    <Tooltip
                                        title={localProductLabel}
                                        overlayStyle={{ zoom: 1.1111111 }}  // Popover tooltip positioning.
                                    >
                                        {
                                            localProductLabel?.length > variantLabelCountLimit ?
                                                localProductLabel?.slice(0, variantLabelCountLimit)?.concat("...")
                                                :
                                                localProductLabel
                                        }
                                    </Tooltip>
                            }
                        </div>
                    </div>
            }
        </>
    )
};

export default ImageCarousel;