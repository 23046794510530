import React, { useState, useEffect } from "react";
import {
  Visualization,
  TableConfig,
} from "@pai-ui/core/components/visualization";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import InputNumber from "antd/lib/input-number";
import Typography from "antd/lib/typography";
import { Button } from "@pai-ui/core/components/button";
import { palette } from "@pai-ui/core/theme/units/palette";
import { notification } from "@pai-ui/core/components/notification";
import { Popover } from "@pai-ui/core/components/popover";
import { Switch } from "@pai-ui/core/components/switch";
import { Select } from "@pai-ui/core/components/select";
import { Space } from "@pai-ui/core/components/space";
import {
  ZsEditOutlined,
  ZsDeleteOutlined,
  ZsSaveIcon,
  ZsCrossIcon,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import AccessControlServiceHelper from "../../services-helper";


const EditableCell = ({
  editing,
  dataKey,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber /> : <Input required={true} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataKey}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const AccessControlUserTable = ({
  userData = [],
  isLoading,
  rolesList = [],
  updateUser,
  stateChanged,
  authToken,
}) => {
  const [form] = Form.useForm();

  const [data, setData] = useState([...userData]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const [roles, setRoles] = useState(rolesList);
  const [loading, setLoading] = useState(isLoading);
  const [vizLoading, setVizLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cancel, setCancel] = useState(false);

  const accessControlServiceHelper = new AccessControlServiceHelper(authToken);

  let isStatusUpdated = false;
  let areRolesUpdated = false;
  const [editingKey, setEditingKey] = useState("");
  let updatedStatus;
  let updatedRoles = [];
  let searchInput;

  useEffect(() => {
    if (isError || cancel) {
      setEditingKey("");
      // setLoading(true);
      setVizLoading(true);

      // setData((userData) => {
      //   return [...userData];
      // });
      setTimeout(() => {
        setVizLoading(false);
      });
    }
    if (isError) setIsError(false);
    if (cancel) setCancel(false);
  }, [isError, cancel]);

  useEffect(() => {
    setData(userData);
    console.log("userdata updated", userData);
  }, [userData.length]);

  useEffect(() => {
    // TODO SS Validate
    setLoading(true);
    setData(userData);
    setLoading(false);
  }, [stateChanged]);

  useEffect(() => {
    setRoles(rolesList);
  }, [rolesList]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  // const cancel = () => {
  //   // TODO SS Validate
  //   setVizLoading(true);
  //   setTimeout(() => {
  //     setVizLoading(false);
  //   }, 0);
  //   setEditingKey("");
  // };

  const showToasterMessage = (type, text) => {
    var placement = "top";
    notification[type]({
      message: text,
      placement,
    });
  };

  const save = async (key) => {
    setLoading(true);
    try {
      let response;
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      const item = newData[index];

      if (index > -1) {
        const inputJson = {
          id: item.id,
          name: row["firstname"],
          email: row["email"],
          userRoles: areRolesUpdated
            ? roles.filter((role) => updatedRoles.includes(role.id))
            : item["role"],
          is_active: isStatusUpdated ? updatedStatus : item["status"],
        };

        try {
          // throw new Error("random error");
          response = await accessControlServiceHelper.upsertUser(inputJson);
          console.log("response from add user", response);
        } catch (error) {
          console.log("response from add user", error);
          setIsError(true);
          showToasterMessage("error", "Could not save the record");
        }
        if (response.data.error) {
          showToasterMessage("error", response.data.error);
        } else if (response.data.data.id) {
          updateUser(response["data"]["data"]);
          showToasterMessage("success", "User updated successfully.");
        } else {
          showToasterMessage("error", "An error occurred.");
        }

        //setData(newData);
        isStatusUpdated = false;
        areRolesUpdated = false;
        updatedRoles = [];
        updatedStatus;
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 0);
    setLoading(false);
  };

  const removeUser = async (record) => {
    setLoading(true);
    const index = data.findIndex((item) => record.id === item.id);
    const item = data[index];

    const payload = [
      {
        id: item.id,
        name: item.firstname,
        email: item.email,
        role: [0],
        activity: false,
      },
    ];

    try {
      var response = await accessControlServiceHelper.deleteUser(payload);

      console.log("response from add user", response);
    } catch (error) {
      console.log("response from add user", error);
    }
    if (response.data.error) {
      showToasterMessage("error", response.data.error);
    } else if (response.data.data.length) {
      updateUser(response["data"]["data"][0]);
      showToasterMessage("success", "User deleted successfully.");
    } else {
      showToasterMessage("error", "An error occurred.");
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block", width: "100%" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) => {
      return state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: palette().primary, padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: "Name",
      dataKey: "firstname",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      onHeaderCell: () => ({
        className: "customClassForHeaderCell",
      }),
      width: "25%",
      editable: true,
      ...getColumnSearchProps("firstname"),
    },
    {
      title: "Email",
      dataKey: "email",
      width: "25%",
      editable: true,
    },
    {
      title: "Status",
      dataKey: "status",
      render: (status, user) => {
        return [
          <div key="1" style={{ width: "2px" }}>
            <Switch
              // size="2px"
              size={"small"}
              checkedChildren={" Active "}
              unCheckedChildren={"Inactive"}
              onChange={(toggleStatus) => {
                updatedStatus = toggleStatus;
                isStatusUpdated = true;
              }}
              disabled={editingKey === user.id ? false : true}
              defaultChecked={status}
            />
          </div>,
        ];
      },
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
      width: "8%",
      editable: false,
    },
    {
      title: "Roles",
      dataKey: "roles",
      filters: roles.length
        ? roles?.map((role) => ({ text: role.role, value: role.key }))
        : null,
      onFilter: (value, record) =>
        record.role.filter((role) => role.id === value).length !== 0,
      render: (roles, user) => {
        return (
          <Select
            disabled={editingKey === user.id ? false : true}
            mode="multiple"
            options={rolesList.map((role) => ({
              id: role.id,
              name: role.role,
            }))}
            placeholder="Select Roles"
            allowClear
            bordered={editingKey === user.id ? true : false}
            onChange={(newRoles) => {
              updatedRoles = newRoles;
              areRolesUpdated = true;
            }}
            defaultValue={user.role.map((role) => role.id)}
            style={{
              marginBottom: "0px",
              padding: 0,
              marginLeft: -10,
              fontSize: "21px !important",
              width: "100%",
            }}
          />
        );
      },
      width: "25%",
      editable: false,
    },
    {
      title: "Action",
      dataKey: "operation",
      width: "7%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            {/* <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link> */}
            {/* <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => save(record.id)}
            > */}
            <span onClick={() => save(record.id)}>
              <Popover
                placement="top"
                title={""}
                content={"Save"}
                trigger="hover"
              >
                <ZsSaveIcon
                  width={20}
                  height={20}
                  style={{
                    color: "#15afd0",
                    marginRight: 20,
                    cursor: "pointer",
                  }}
                />
              </Popover>
            </span>
            {/* </Popconfirm> */}
            {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}> */}
            <span onClick={() => setCancel(true)}>
              <Popover
                placement="top"
                title={""}
                content={"Cancel"}
                trigger="hover"
              >
                <ZsCrossIcon
                  width={20}
                  height={20}
                  style={{ color: "#15afd0", cursor: "pointer" }}
                />
              </Popover>
            </span>
            {/* </Popconfirm> */}
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ marginRight: 12 }}
            >
              <Popover
                placement="top"
                title={""}
                content={"Edit"}
                trigger="hover"
              >
                <ZsEditOutlined style={{ fontSize: 20, marginRight: 10 }} />
              </Popover>
            </Typography.Link>
            {/* <Popconfirm
              title="Sure to delete?"
              onConfirm={() => removeUser(record)}
            > */}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => removeUser(record)}
            >
              <Popover
                placement="top"
                title={""}
                content={"Delete"}
                trigger="hover"
              >
                <ZsDeleteOutlined style={{ fontSize: 20, color: "#15afd0" }} />
              </Popover>
            </span>
            {/* </Popconfirm> */}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataKey === "age" ? "number" : "text",
        dataKey: col.dataKey,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        {console.log("rerendered")}
        <Visualization
          classes={{
            root: "access-control-viz-table ",
          }}
          height={600}
          loading={vizLoading}
          withCard={false}
          dataConfig={{
            structureType: "processed",
          }}
          viewConfig={
            new TableConfig({
              loading,
              pagination: {
                pageSize: 8,
              },
              idProperty: "id",
              horizontalBorder: false,
              verticalBorder: false,
              columns: mergedColumns,
              components: {
                body: {
                  cell: EditableCell,
                },
              },
            })
          }
          dataset={{ data: [...data] }}
        />
      </Form>
    </>
  );
};
