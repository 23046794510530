import React from "react";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Tag } from "@pai-ui/core/components/tag";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card } from "@pai-ui/core/components/card";
import { Metric } from "@pai-ui/core/components/metric";
import { MetricClass } from "@xai/assets/common/cssClasses";
import { UserContext } from "@xai/providers/with-auth";
// import { Button } from '@pai-ui/core/components/button';
// import { Dropdown } from '@pai-ui/core/components/dropdown';
// import { Menu } from '@pai-ui/core/components/menu';
// import { ThreeDots as ThreeDotsIcon } from "@styled-icons/bootstrap/ThreeDots";

// const ThreeDotsIcon_ = (
//   <ThreeDotsIcon
//     width={20}
//     height={20}
//     style={{ cursor: "pointer" }}
//     color="#15afd0"
//   />
// );

const subjectLineDiv = ({ id, ExperimentTagGroups }) => (
  <Metric
    withBackground={false}
    classes={MetricClass}
    title="Subject Line"
    subTitle={
      <>
        <div style={{ marginBottom: 5, padding: 0 }}>{id?.subjectLine}</div>
        {id?.variantTitleTags?.map((varTag, index) => {
          return (
            <Tag
              key={index}
              color={
                ExperimentTagGroups?.find(
                  (tagObj) =>
                    tagObj?.tag_group?.toLowerCase() ===
                    varTag?.tag_group?.toLowerCase()
                )?.tag_color
              }
              size="small"
            >
              {varTag?.name}
            </Tag>
          );
        })}
      </>
    }
  />
);

const bodyCopyDiv = ({ id, ExperimentTagGroups }) => (
  <Metric
    withBackground={false}
    classes={MetricClass}
    title="Body Copy"
    // style={{ width: "inherit" }}
    subTitle={
      <>
        <div style={{ marginBottom: 5, padding: 0 }}>{id?.bodyCopy}</div>
        {id?.variantBodyContentTags?.map((varTag, index) => {
          return (
            <Tag
              key={index}
              color={
                ExperimentTagGroups?.find(
                  (tagObj) =>
                    tagObj?.tag_group?.toLowerCase() ===
                    varTag?.tag_group?.toLowerCase()
                )?.tag_color
              }
              size="small"
            >
              {varTag?.name}
            </Tag>
          );
        })}
      </>
    }
  />
);

export function ItemComponent(props) {
  const { id } = props;

  const { experimentModule } = React.useContext(UserContext);
  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;

  return (
    <Card bordered={false} bodyStyle={{ padding: "15px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row span={24} align="middle" style={{ flex: "auto" }} gutter={16}>
          {["subjectlineonly"].indexOf(
            experimentTextVariantsType?.toLowerCase()
          ) > -1 && (
            <Col span={24}>{subjectLineDiv({ id, ExperimentTagGroups })}</Col>
          )}
          {["bodycopyonly"].indexOf(experimentTextVariantsType?.toLowerCase()) >
            -1 && (
            <Col span={24}>{bodyCopyDiv({ id, ExperimentTagGroups })}</Col>
          )}
          {["subjectlineandbodycopy"].indexOf(
            experimentTextVariantsType?.toLowerCase()
          ) > -1 && (
            <>
              <Col span={9}>{subjectLineDiv({ id, ExperimentTagGroups })}</Col>
              <Col span={15} >
                {bodyCopyDiv({ id, ExperimentTagGroups })}
              </Col>
            </>
          )}
        </Row>
        <div>{/* {ThreeDotsIcon_} */}</div>
      </div>
    </Card>
  );
}

export default function Item(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id.id });

  console.log({ itemProps: props });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ItemComponent id={props.id} />
    </div>
  );
}

export function UnassignedItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id.id });

  console.log({ itemProps: props });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <UnassignedItemComponent id={props.id} />
    </div>
  );
}

const UnassignedItemComponent = (props) => {
  const { experimentModule } = React.useContext(UserContext);
  const ExperimentTagGroups =
    experimentModule?.selectedExperiment?.experiment_data?.tag_groups;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;
  const { id } = props;

  return (
    <>
      <Card bordered={false} bodyStyle={{ padding: "15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "auto" }}>
            {["subjectlineonly", "subjectlineandbodycopy"].indexOf(
              experimentTextVariantsType?.toLowerCase()
            ) > -1 && <>{subjectLineDiv({ id, ExperimentTagGroups })}</>}
            {["subjectlineandbodycopy"].indexOf(
              experimentTextVariantsType?.toLowerCase()
            ) > -1 && (
              <div style={{ margin: "10px 0", border: "#607D8B 1px solid" }} />
            )}
            {["bodycopyonly", "subjectlineandbodycopy"].indexOf(
              experimentTextVariantsType?.toLowerCase()
            ) > -1 && (
              <>
                <>{bodyCopyDiv({ id, ExperimentTagGroups })}</>
              </>
            )}
          </div>
          <div>
            {/*
             * Commenting Below Piece due to below issue
             * Ref : https://github.com/clauderic/dnd-kit/issues/1204
             */}
            {/* 
            
            <Dropdown
              overlayStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
              overlay={
                <Menu
                  items={
                    // deploymentDates Prop contains options, might need transformation over here
                    [
                    {
                      id: 1, name: (<>
                        <div className={'DropDown-Test'} onClick={(e) => {
                          // e.preventDefault();
                          console.log("event123", e);
                        }}>
                          First
                        </div>
                      </>)
                    }
                  ]}
                />
              }
              // trigger={["click"]}
              placement="bottomRight"
            // onClick={(e) => {
            //   // e.preventDefault();
            //   console.log("event1234", e);
            // }}
            >
              {ThreeDotsIcon_}
            </Dropdown> 
            */}
          </div>
        </div>
      </Card>
    </>
  );
};
