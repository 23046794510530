import React from "react";
import { Space } from '@pai-ui/core/components/space';
import {
    default_experiment_unique_identifier_label
} from "../transformer.js";
import { TestExperiment } from "../default-form-template";
import './index.css';

const FormLayout = ({ templateSelected, setTemplateSelected, experimentToLoad }) => {

    return (
        <>
            <div>
                <h3 style={{ marginLeft: "10px", marginBottom: "15px" }}>
                    <Space>
                        <span
                            className="select-link"
                            onClick={() => { setTemplateSelected(null) }}>
                            Choose a template
                        </span>
                        <span>
                            /
                        </span>
                        <span>
                            Create an Optimization
                        </span>
                    </Space>
                </h3>
            </div>
            {
                templateSelected &&
                templateSelected?.template_identifier?.toLowerCase() === default_experiment_unique_identifier_label?.toLowerCase() &&
                <>
                    <TestExperiment
                        experimentToLoad={experimentToLoad}
                        templateSelected={templateSelected}
                    />
                </>
            }
        </>
    );
};

export default FormLayout;