import React from "react";
import { Row } from '@pai-ui/core/components/row';
import { Col } from '@pai-ui/core/components/col';
import { Button } from '@pai-ui/core/components/button';
import { TextArea } from '@pai-ui/core/components/text-area';
import { Select } from '@pai-ui/core/components/select';
import { Form } from 'antd';
import { useSubjectLineFormConfig } from "@xai/context/SubjectLineFormConfig.js";

export const BasicComponent = (props) => {

    const { config, updateConfig } = useSubjectLineFormConfig();
    const [form] = Form.useForm();
    console.log('BasicComponent Props', props, config);

    return (
        <>
            <Row span={24}>
                <Col span={24}>
                    <Form
                        name="subject_line_generator"
                        initialValues={
                            config
                        }
                        form={form}
                        style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}
                    >
                        <Row gutter={[25, 25]}>
                            <Col span={!props.data.simple ? 14 : 24}>
                                {props.inputs
                                    .map((element) => (
                                        <>
                                            <div style={{ padding: 10 }}>
                                                <div>
                                                    <p
                                                        style={{
                                                            fontSize: "1rem",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {element?.required && <span>*</span>}
                                                        {element?.label}
                                                    </p>
                                                    {
                                                        element?.help &&
                                                        <p
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                margin: "0px",
                                                                opacity: 0.75,
                                                                fontWeight: "300",
                                                            }}
                                                        >
                                                            {element.help}
                                                        </p>
                                                    }
                                                </div>

                                                {element.type === "textarea" && (
                                                    <div>
                                                        <Form.Item name={element.key} rules={element.rules} {...element.props}>
                                                            <TextArea
                                                                {...element.props}
                                                                style={element.simple ? element.props.style : {}}
                                                                onChange={(e) => {
                                                                    updateConfig({ ...config, [element.key]: e?.target?.value });
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}

                                {props.select
                                    .map((element) => (
                                        <>
                                            <div style={{ padding: 10 }}>
                                                <div>
                                                    <p
                                                        style={{
                                                            fontSize: "1rem",
                                                            fontWeight: "bold",
                                                            margin: "0px",
                                                        }}
                                                    >
                                                        {element?.required && <span>*</span>}
                                                        {element?.label}
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            margin: "0px",
                                                            opacity: 0.75,
                                                            fontWeight: "300",
                                                        }}
                                                    >
                                                        {element.help}
                                                    </p>
                                                </div>

                                                {element.type === "select" && (
                                                    <div>
                                                        <Form.Item name={element.key} rules={element.rules} {...element.props}>
                                                            <Select
                                                                {...element.props}
                                                                options={element.options}
                                                                style={element.simple ? element.props.style : {}}
                                                                // value={config?.[element.key]}
                                                                onChange={(value) => {
                                                                    updateConfig({ ...config, [element.key]: value });
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end"
            }}>
                <Button type="primary" htmlType="submit" onClick={() => {
                    form.validateFields()
                        .then(() => {
                            props.setCurrentStep(props.currentStep + 1);
                        });
                }}>
                    Next
                </Button>
            </div>
        </>
    );
}