import React from "react";
import { FileEarmarkText } from "@styled-icons/bootstrap/FileEarmarkText";
import { Space } from "@pai-ui/core/components/space";
import { PopConfirm } from "@pai-ui/core/components/pop-confirm";
import { UserContext } from "@xai/providers/with-auth";
import {
  ZsStarFilled,
  ZsStarOutlined,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import AnalyzeServiceHelper from "@xai/pages/measure-analyze/service-helper";
import BiasCorrectionReportModal from "../bias-correction-report-modal";
import { getMarkVersionConfig } from "../../transformer";

const ExperimentAnalyzeFeatureIcons = ({ selectedExperimentTask }) => {
  const [isReportOpen, setIsReportOpen] = React.useState(false);
  const {
    experimentModule,
    token,
    dispatch: dispatchMethod,
  } = React.useContext(UserContext);
  const service = new AnalyzeServiceHelper(token);

  const handleMarkVersionConfig = async () => {
    let dataconfig = getMarkVersionConfig(
      experimentModule.selectedExperiment.id,
      selectedExperimentTask?.version
    );
    await service
      .markExperimentDefaultVersionConfig(dataconfig)
      .then(async (res) => {
        console.log(res);
        // Update Experiment List and selected Experiment data in React context  post db update.
        let updatedExpList = [...experimentModule?.experimentList];
        if (updatedExpList.length > 0) {
          let updatedSelectedExperiment = updatedExpList.find(
            (exp) => exp.id === experimentModule?.selectedExperiment.id
          );
          updatedSelectedExperiment.default_version_config =
            dataconfig.default_version_config;
          let finalobj = {
            ...experimentModule,
            experimentList: updatedExpList,
            selectedExperiment: updatedSelectedExperiment,
          };
          // console.log("finalobj", finalobj);
          dispatchMethod({
            type: "experimentModule",
            payload: finalobj,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="analyze-screen-filter-section" style={{ margin: "auto" }}>
      {selectedExperimentTask?.bias_correction_report &&
        selectedExperimentTask?.bias_correction_report !== {} && (
          <BiasCorrectionReportModal
            isReportOpen={isReportOpen}
            setIsReportOpen={setIsReportOpen}
            reportData={selectedExperimentTask?.bias_correction_report}
          />
        )}
      <Space>
        {experimentModule?.selectedExperiment && selectedExperimentTask?.version ? (
          experimentModule?.selectedExperiment?.default_version_config ===
          selectedExperimentTask?.version ? (
            <ZsStarFilled style={{ fontSize: "25px", color: "#15afd0" }} />
          ) : selectedExperimentTask?.version ? (
            <PopConfirm
              title={"Save as default version config"}
              onConfirm={() => handleMarkVersionConfig()}
            >
              <ZsStarOutlined style={{ fontSize: "25px", color: "#15afd0" }} />
            </PopConfirm>
          ) : (
            <ZsStarOutlined style={{ fontSize: "25px", color: "gray" }} />
          )
        ) : (
          <ZsStarOutlined style={{ fontSize: "25px", color: "gray" }} />
        )}

        <FileEarmarkText
          style={{
            height: "22px",
            color: selectedExperimentTask?.bias_correction_report
              ? "#15afd0"
              : "gray",
            cursor: selectedExperimentTask?.bias_correction_report
              ? "pointer"
              : "",
          }}
          onClick={() =>
            selectedExperimentTask?.bias_correction_report &&
            setIsReportOpen(!isReportOpen)
          }
        />
      </Space>
    </div>
  );
};

export default ExperimentAnalyzeFeatureIcons;
