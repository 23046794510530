export const ComponentGenericConfig = {
  height: {
    chart: "35vh",
    kpi: "60px",
  },
};

export const InitialParamterData = {
  parameter_type: "Parameters",
  parameter_order: 0,
  is_chart: null,
  chart_type: null,
  chart_orientation: null,
  chart_variant: null,
  icon: "",
  data: [],
};

export const TransformComparisiondata = (data) => {
  let tempData = data;
  tempData.header = tempData.header.map((headerItem, headerIndex) => {
    return {
      ...headerItem,
      sort_order: headerIndex,
    };
  });
  tempData.parameters = [InitialParamterData, ...tempData.parameters];

  tempData.parameters = tempData.parameters.map((parameterObject) => {
    return {
      ...parameterObject,
      data: parameterObject.data.map((parameterData) => {
        return {
          ...parameterData,
          sort_order: tempData.header.find(
            (headerItem) =>
              headerItem.audience_group_variant_name ===
              parameterData.audience_group_variant_name
          ).sort_order,
        };
      }),
    };
  });

  return tempData;
};

export const ExcludeFieldsComparisionChart = {
  BarChartConfig: [
    "kpi_name",
    "kpi_series_name",
    "format_id",
    "time_metric",
    "is_targeted_response",
    "kpi_order"
  ],
  PieChartConfig: [
    "format_id",
    "kpi_order"
  ],
  LineChartConfig: [
    "kpi_name",
    "kpi_series_name",
    "format_id",
    "time_metric",
    "is_targeted_response",
    "kpi_order"
  ],
  CompositionChartConfig: [
    "format_id",
    "time_metric"
  ]
};

export const getAnalyzeComparisonTableConfig = (
  id,
  start_time,
  end_time,
  groupView,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config,
   *          experiment_variant_parameters_results,
   *          experiment_variant_meta_data
   * Props : experiment_id,
   *         start_time_kpi,
   *         end_time_kpi,
   *         group_view
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    group_view: groupView,
    version: version,
  };

  return input_json;
};

export const getAnalyzeResponseMetricChartConfig = (
  id,
  start_time,
  end_time,
  groupView,
  parameter_type,
  version
) => {
  /**
   * Tables : experiment_audience_group_variant,
   *          experiment_variant_parameters_table_view_config,
   *          experiment_variant_parameters_results,
   *          experiment_variant_meta_data
   * Props : experiment_id,
   *         start_time_kpi,
   *         end_time_kpi,
   */

  let input_json = {
    experiment_id: id,
    start_time_kpi: start_time,
    end_time_kpi: end_time,
    groupView: groupView,
    parameter_type: parameter_type,
    version: version,
    "screen_in_scope": "analyze"
  };

  return input_json;
};

export const getMarkVersionConfig = (id, default_version_config) => {
  let input_json = {
    experiment_id: id,
    default_version_config: default_version_config,
  };

  /**
   * Tables : experiment_master
   * Props : experiment_id,
   *         default_version_config
   */

  return input_json;
};

export const getAddExperimentTaskItemDataconfig = (
  selectedExperimentTaskList,
  selectedExperimentTask,
  currentScreen,
  userId
) => {
  /**
   * Tables : experiment_task_management
   * Props :
   * experiment_id,
   * experiment_id,
   * experiment_name,
   * start_time_kpi,
   * end_time_kpi,
   * prior_date_range,
   * request_type,
   * created,
   * created_by,
   * request_from_screen,
   * version
   */

  const {
    experiment_id,
    experiment_name,
    start_time_kpi,
    end_time_kpi,
    prior_date_range,
    request_type,
  } = selectedExperimentTask;

  let nextHighestversion =
    Math.max(...selectedExperimentTaskList.map((item) => item.version)) + 1;
  let input_json = {
    experiment_id,
    experiment_name,
    start_time_kpi: new Date(start_time_kpi).toISOString(),
    end_time_kpi: new Date(end_time_kpi).toISOString(),
    prior_date_range,
    request_type,
    created: new Date().toISOString(),
    created_by: userId,
    request_from_screen: currentScreen,
    version: nextHighestversion,
  };

  console.log(
    "getAddExperimentTaskItemDataconfig",
    selectedExperimentTaskList,
    selectedExperimentTask,
    currentScreen,
    "input_json",
    input_json
  );
  return input_json;
};
