import axios from "axios";
// import { dotNetApi } from "@xai/library/clients";

// let mock = true;
let mock = Boolean(process.env.REACT_APP_API_MOCK.toLowerCase() === "true");

// let baseUrl = "https://d3mcp2vyrro05u.cloudfront.net/gen_ai";

/** */

// const TransformTemplateListData = (experimentListData) => {
//   experimentListData?.items?.map((data) => {
//     data.template_form_config_table.template_form_config = JSON.parse(
//       data?.template_form_config_table?.template_form_config
//     );
//     data.template_data_table.template_data = JSON.parse(
//       data?.template_data_table?.template_data
//     );
//     data.template_dendogram_rules_table.template_dendogram_rules = JSON.parse(
//       data?.template_dendogram_rules_table?.template_dendogram_rules
//     );
//     return data;
//   });
// };

// export const getAllDesignExperiments = (authToken) =>
//   new Promise((resolve, reject) => {
//     if (mock) {
//       // mock response for this api call
//       axios({
//         method: "GET",
//         url: `${baseUrl}/TempData/GetDesignExperimentCardLayout.json`,
//       })
//         .then((res) => {
//           const { data } = res;
//           console.log("getAllDesignExperiments data", data, authToken);
//           resolve(data);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     } else {
//       console.log("getAllDesignExperiments dataConfig", authToken);
//       dotNetApi
//         .post(
//           `/TemplateMasters`,
//           {
//             query: `{
//                             templateMaster(pageSize: 10000, page: 1, sort: { field: id, type: ASC }) {
//                               items {
//                                 id
//                                 template_name: templateName
//                                 description: description
//                                 target_subject: targetSubject
//                                 template_identifier: templateIdentifier
//                                 template_form_config_table: templateFormConfig {
//                                   template_form_config: templateFormConfig
//                                 }
//                                 template_data_table: templateData {
//                                   template_data: templateData
//                                 }
//                                 template_dendogram_rules_table: templateDendogramRules {
//                                   template_dendogram_rules: templateDendogramRules
//                                 }
//                                 icon:icon
//                               }
//                             }
//                           }`,
//           }
//           // #TODO : use headers if required
//           // {
//           //   headers: {
//           //     "x-api-key": process.env.REACT_APP_API_KEY,
//           //     Authorization: "Bearer " + authToken,
//           //   },
//           // }
//         )
//         .then(async (res) => {
//           let { templateMaster } = res?.data?.data;
//           // experimentMaster.experiments = experimentMaster.items;
//           // delete experimentMaster.items;

//           console.log("getAllDesignExperiments data", res, templateMaster);
//           await TransformTemplateListData(templateMaster);
//           resolve(templateMaster);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//       // #TODO: Need to make associated changes across various files due to change in response structure
//     }
//   });

export const getDesignExperimentTagLines = (config, generateOpenAIResponse) =>
  new Promise((resolve, reject) => {
    if (!generateOpenAIResponse) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignExperimentTagLineExperiment.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((res) => {
          console.log("getDesignExperimentTagLines data", res);
          resolve(res.data.content);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // axios.post(`${process.env.REACT_APP_DOTNET_MICROSERVICE_HOST_CONTENT_GENERATOR}/ContentGenerator`,
      axios
        .post(
          `${process.env.REACT_APP_MICROSERVICE_HOST}/meta/GetOpenApiContent`,
          // {
          //     "taglines": [
          //         "Book your flight today!",
          //         "Don’t wait, book now!",
          //         "Hurry before it’s too late, book your flight now!"
          //     ],
          //     "count": {
          //         "minCount": 0,
          //         "maxCount": 20,
          //         "canContain": [
          //             "flight"
          //         ],
          //         "cantContain": [
          //             "hello"
          //         ]
          //     }
          // }
          config
        )
        .then((response) => {
          if (response?.data?.error) {
            reject({});
          }
          resolve(response.data.content);
        })
        .catch((err) => {
          console.log(err);
          reject({});
        });
    }
  });

// export const getAllDesignOptimizations = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios({
//       method: "GET",
//       url: `${baseUrl}/TempData/GetDesignOptimizationCardLayout.json`,
//     })
//       .then((res) => {
//         const { data } = res;
//         console.log("getAllDesignExperiments data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getDesignAISequencingData = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios({
//       method: "GET",
//       url: `${baseUrl}/TempData/GetDesignAISequencingListing.json`,
//     })
//       .then((res) => {
//         const { data } = res;
//         console.log("getAllDesignExperiments data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// export const getDesignAISequencingFormElements = (authToken) =>
//   new Promise((resolve, reject) => {
//     // mock response for this api call
//     axios({
//       method: "GET",
//       url: `${baseUrl}/TempData/GetDesignAISequencingFormElements.json`,
//     })
//       .then((res) => {
//         const { data } = res;
//         console.log("GetDesignAISequencingFormElements data", data, authToken);
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

export const getMaxAIApplicationsData = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetDesignMaxAIApplication.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetDesignAISequencingFormElements data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMaxAIGetTemplateTaglineResponse = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetTemplateTaglineResponse.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetTemplateTaglineResponse data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMaxAITemplateFormConfig = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITemplateFormConfig.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetTemplateTaglineResponse data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMaxAITemplateDefaultFormData = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITemplateDefaultFormData.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetTemplateTaglineResponse data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMaxAITaglineConfig = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMaxAITaglineConfig.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetTemplateTaglineResponse data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getGenAIPreFilters = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetGenAIPreFilters.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("GetGenAIPreFilters data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getGenAIMarketingCopyOptions = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    // axios({
    //   method: "GET",
    //   url: `./sample_data/experiment/GetMarketingCopyBase.json`,
    // })
    axios
    .get(
      `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetMarketingCopyBase.json`,
      {
        skipInterceptors: true,
      }
    )
    .then((res) => {
        const { data } = res;
        console.log("Get Marketing Copy Base Options data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getGenAIMarketingCopyDefinition = (authToken, program) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    // axios({
    //   method: "GET",
    //   url:
    //     program.indexOf("marketingcopy") === -1
    //       ? `${baseUrl}/marketing-copy-${program}.json`
    //       // : `./sample_data/experiment/${program}.json`,
    //       : `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/${program}.json`,

    // })
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/${program}.json`,
        {
          skipInterceptors: true,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log(`Get Marketing Copy ${program} data`, data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const newGetMaxAITagLines = (config) =>
  new Promise((resolve, reject) => {
    if (mock) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/generateTaglineVariants.json`,
          {
            skipInterceptors: true,
          }
        )
        .then((response) => {
          if (response?.data?.error) {
            reject({});
          }
          resolve(response.data.result);
        })
        .catch((err) => {
          console.log(err);
          reject({});
        });
    } else {
    axios
      .post(
        `${process.env.REACT_APP_MARKETING_BODY_COPY_API_URL}/genai/generate`,
        config,
        {
          headers: {
            userid: "souvik.saha@zs.com",
            username: "Souvik Saha",
          },
        }
      )
      .then((response) => {
        if (response?.data?.error) {
          reject({});
        }
        resolve(response.data.result);
      })
      .catch((err) => {
        console.log(err);
        reject({});
      });
    }
  });

export const getSpecificSubjectLineConfig = (authToken) =>
  new Promise((resolve, reject) => {
    // mock response for this api call
    axios
      .get(
        `${process.env.REACT_APP_CLOUDFRONT_CDN}/x.ai/panera/TempData/GetSpecificSubjectLineConfig.json`,
        {
          skipInterceptors: true,
        }
      )
      // axios({
      //     method: "GET",
      //     url: "./TempData/GetSpecificSubjectLineConfig.json"
      // })
      .then((res) => {
        const { data } = res;
        console.log("getSpecificSubjectLineConfig data", data, authToken);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
