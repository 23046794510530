import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button } from "@pai-ui/core/components/button";
import { Metric } from '@pai-ui/core/components/metric';
import { DateRangePicker } from '@pai-ui/core/components/date-range-picker';
import { Grid, GridItem } from "@xai/library/containers/grid";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";
import * as Comps from "./components";

const ExecutiveEnterpriseLedger = () => {

    const [localDateRangePickerValue, setLocalDateRangePickerValue] = useState([]);
    const [filters, setFilters] = useState({
        dateRange: []
    });
    const [datePickerOpened, setDatePickerOpen] = useState(false);
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

    const Load = () => {
        setFilters({
            ...filters,
            dateRange: [moment(), moment().subtract(6, 'months')]
        });
        setLocalDateRangePickerValue([moment(), moment().subtract(6, 'months')]);
    };

    useEffect(() => {
        Load();
    }, []);

    return (
        <div className={'opacity-0-55'}>
            <GenericModalWithResultComponent
                isRequestModalOpen={isRequestModalOpen}
                setIsRequestModalOpen={setIsRequestModalOpen}
            />
            <Grid template={[
                ["top-row", "top-row"],
                ["dendogram", "dendogram"],
                ["experiment-revenue-business-unit-correlation", "revenue-over-time"],
                ["experiment-revenue-correlation", "experiment-revenue-correlation"]
            ]}>
                <GridItem area="top-row">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Metric
                            title="Enterprise Ledger"
                            subTitle=""
                            highlightedElement="subTitle"
                            withBackground={false}
                        />
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <DateRangePicker
                                allowClear={false}
                                open={datePickerOpened}
                                onOpenChange={(isOpened) => {
                                    if (isOpened) {
                                        setDatePickerOpen(isOpened);
                                    }
                                }}
                                onChange={setLocalDateRangePickerValue}
                                value={localDateRangePickerValue}
                                renderExtraFooter={() =>
                                    <>
                                        <div style={{ display: "flex", justifyContent: "end", margin: "5px 0px" }}>
                                            <Button
                                                disabled={localDateRangePickerValue?.length != 2}
                                                label="OK"
                                                onClick={() => {
                                                    setFilters({
                                                        ...filters,
                                                        dateRange: localDateRangePickerValue
                                                    });
                                                    setDatePickerOpen(false);
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </GridItem>
                <GridItem area="dendogram">
                    <Comps.DendogramCollapse
                        filters={filters}
                    />
                </GridItem>
                <GridItem area="experiment-revenue-business-unit-correlation">
                    <Comps.ExperimentRevenueBusinessUnitCorrelation
                        filters={filters}
                    />
                </GridItem>
                <GridItem area="revenue-over-time">
                    <Comps.RevenueOverTime
                        filters={filters}
                    />
                </GridItem>
                <GridItem area="experiment-revenue-correlation">
                    <Comps.ExperimentRevenueCorrelation
                        filters={filters}
                    />
                </GridItem>
            </Grid>
        </div>
    );
};

export default ExecutiveEnterpriseLedger;