import React from "react";
import "antd/dist/antd.css";
import { Table } from "antd";

export const ZsTable = (props) => {
  if (props.customTitle) {
    return (
      <Table
        {...props}
        title={() => (props.customTitle ? props.customTitle : false)}
      />
    );
  }
  return <Table {...props} />;
};

export default ZsTable;
