/* eslint-disable no-unused-vars */
import React from "react";
import { Card } from '@pai-ui/core/components/card';
import { Row } from '@pai-ui/core/components/row';
import "./../style.css";

import SectionFields from "./sectionFields";
export const FormSection = (props) => {
  // console.log("form FormSection", props);
  const { stepsConfig } = props;
  return (
    <Card
      style={{ height: "78vh", overflowY: "auto", width: "100%" }}
      title={stepsConfig.sectionName}
    >
      
       <SectionFields {...props} fields={stepsConfig.sectionFields} /> 
    </Card>
  );
};

export default FormSection;
