import React from 'react'
import {ColorContainer, ColorBox, Color, Color_Stack, Color_Stack_Bundles, Color_Stack_4, Blue,Color_segment,
    Color_stack_ABTest
} from './styles'

const ColorSelector = ({ onSelect, activeIndex }) => (
  <ColorContainer>
    {Color.map((color, index) => (
      <ColorBox
        key={index}
        background={color}
        active={index === activeIndex}
        onClick={() => onSelect(index)}
      />
    ))}
  </ColorContainer>
);

const ColorStacks = {
    'default': Color,
    'stack-1': Color_Stack,
    'stack-2': Color_Stack_Bundles,
    'stack-3' : Color_Stack.slice(1),
    'stack-4' : Color_Stack_4,
    'blue-stack' : Blue,
    'stack-5' : Color_segment,
    'abtest-stack':Color_stack_ABTest
};

// export { Color, Color_Stack, Color_Stack_Bundles, ColorStacks, Blue };

export {
    Color, Color_Stack, Color_Stack_Bundles, ColorStacks,Blue, Color_stack_ABTest
}

export default ColorSelector
