import * as DesignService from "@xai/services/experiment/Design-MaxAI";
import * as GenAIService from "@xai/services/GenAI";

class GenAIServiceHelper {
  constructor(token) {
    this.authToken = token;
  }

  async loadArchive(params) {
    const result = await GenAIService.loadArchive(params);
    if (!result) return null;
    return result;
  }

  async getTags() {
    const result = await GenAIService.getTags();
    if (!result) return null;
    return result;
  }

  async loadGallery() {
    const result = await GenAIService.loadGallery();
    if (!result) return null;
    return result;
  }

  async generate(config) {
    const result = await GenAIService.generate(config);
    if (!result) return null;
    return result;
  }

  async getStatus(id) {
    const result = await GenAIService.getStatus(id);
    if (!result) return null;
    return result;
  }

  async updateImage(id, item) {
    const result = await GenAIService.updateImage(id, item);
    if (!result) return null;
    return result;
  }

  async deleteItem(id) {
    const result = await GenAIService.deleteItem(id);
    if (!result) return null;
    return result;
  }

  async deleteBatch(batch_id) {
    const result = await GenAIService.deleteBatch(batch_id);
    if (!result) return null;
    return result;
  }

  async getGenAIPreFilters(data) {
    const result = await DesignService.getGenAIPreFilters(data);
    if (!result) return null;
    return result;
  }

  async getGenAIMarketingCopyOptions(data) {
    const result = await DesignService.getGenAIMarketingCopyOptions(data);
    if (!result) return null;
    return result;
  }

  async getGenAIMarketingCopyDefinition(data, product) {
    const result = await DesignService.getGenAIMarketingCopyDefinition(
      data,
      product
    );
    if (!result) return null;
    return result;
  }

  async upload(file) {
    const result = await GenAIService.upload(file);
    return result;
  }

  async getCanvaAccounts() {
    const result = await GenAIService.getCanvaAccounts();
    return result;
  }
}

export default GenAIServiceHelper;
