/* eslint-disable no-unused-expressions */
import React from "react";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Tag,
  message,
} from "antd";
import Axios from "axios";
import {
  DeleteOutlined,
  DownloadOutlined,
  HeartOutlined,
  LinkOutlined,
  PushpinOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { PrintDiv } from "../design-gen-ai-gallery/demo";

export const AspectRatio = (props) => {
  return (
    <div
      style={{
        paddingBottom: `${(props.height * 100) / props.width}%`,
        position: "relative",
      }}
    >
      <div
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        {props.children}
      </div>
    </div>
  );
};

const ItemCard = (props) => {
  return (
    <AspectRatio
      width={props.item.aspect_ratio.split(":")[0]}
      height={props.item.aspect_ratio.split(":")[1]}
    >
      <div
        onClick={() => {
          props.onClick(props.item);
        }}
        style={{
          height: "100%",
          backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
            props.item.image_url
          )}&w=640&q=75)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: config.radius,
          backgroundPosition: "center center",
          position: "relative",
        }}
        className="hover-tile"
      >
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.item.tags?.map((tag, i) => (
            <Tag key={i} color="#15afd0" style={{ marginRight: 5 }}>
              {tag}
            </Tag>
          ))}
        </div>
      </div>
    </AspectRatio>
  );
};

const ItemDisplay = (props) => {
  return (
    <div style={{ borderRadius: config.radius }}>
      <div id="downloadableDiv">
        <AspectRatio
          width={props.item.aspect_ratio.split(":")[0]}
          height={props.item.aspect_ratio.split(":")[1]}
        >
          <div
            onClick={() => {
              props.onClick(props.item);
            }}
            style={{
              height: "100%",
              backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
                props.item.image_url
              )}&w=640&q=75)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",

              backgroundPosition: "center center",
            }}
          ></div>
        </AspectRatio>
      </div>
    </div>
  );
};

export const config = {
  gutter: 15,
  radius: 15,
};

// const CustomCarousel = (props) => {
//   const ref = React.useRef();

//   const [currentSlide, setCurrentSlide] = React.useState(props.currentSlide);

//   React.useEffect(() => {
//     if (currentSlide >= 0) {
//       ref.current?.goTo(currentSlide, true);
//       console.log("Test");
//     }
//   }, [currentSlide, ref.current]);

//   React.useEffect(() => {
//     setCurrentSlide(props.currentSlide);
//   }, [props.currentSlide]);

//   return (
//     <div style={{ position: "relative" }}>
//       <Carousel ref={ref} dots={false}>
//         {props.currentBatch?.map((item) => (
//           <div key={item.id}>
//             <AspectRatio width={1} height={1}>
//               <div
//                 style={{
//                   height: "100%",
//                   backgroundImage: `url(https://www.zspersonalize.ai/_next/image?url=${encodeURIComponent(
//                     item.image_url
//                   )}&w=640&q=75)`,
//                   backgroundSize: "cover",
//                   backgroundRepeat: "no-repeat",
//                 }}
//               ></div>
//             </AspectRatio>
//           </div>
//         ))}
//       </Carousel>
//       {currentSlide > 0 && (
//         <div
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: 20,
//             transform: "translateY(-50%)",
//             width: 50,
//             height: 50,
//             backgroundColor: "#FFF",
//             borderRadius: "50%",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             color: "black",
//             cursor: "pointer",
//           }}
//           onClick={() => {
//             setCurrentSlide((s) => (s > 0 ? s - 1 : 0));
//           }}
//         >
//           <span style={{ marginLeft: -2 }}>◀</span>
//         </div>
//       )}
//       {currentSlide < props.currentBatch.length - 1 && (
//         <div
//           style={{
//             position: "absolute",
//             top: "50%",
//             right: 20,
//             transform: "translateY(-50%)",
//             width: 50,
//             height: 50,
//             backgroundColor: "#FFF",
//             borderRadius: "50%",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             color: "black",
//             cursor: "pointer",
//           }}
//           onClick={() => {
//             setCurrentSlide((s) =>
//               s < props.currentBatch.length - 1
//                 ? s + 1
//                 : props.currentBatch.length - 1
//             );
//           }}
//         >
//           <span style={{ marginLeft: 2 }}>▶</span>
//         </div>
//       )}
//     </div>
//   );
// };

// const timer = (seconds) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, seconds * 1000);
//   });

// eslint-disable-next-line react/display-name
export default () => {
  const [images, setImages] = React.useState([]);

  React.useState(() => {
    Axios.get("https://paiweb.vercel.app/api/gen-ai/archive", {
      skipInterceptors: true
    }).then((r) => {
      const rawData = r.data.data;

      const uniqueBatches = Array.from(new Set(rawData.map((r) => r.batch_id)));

      setImages(
        uniqueBatches.map((b) => ({
          id: b,
          ...rawData.find((r) => r.batch_id === b),
          items: Array.from(
            new Set(
              rawData.filter((r) => r.batch_id === b).map((r) => r.image_url)
            )
          ).map((image_url) => rawData.find((r) => r.image_url === image_url)),
        }))
      );
    });
  }, []);

  const [selected, setSelected] = React.useState();

  const [search, setSearch] = React.useState("");

  const filteredImages = images?.filter(
    (i) => !search || i.prompt?.indexOf(search) !== -1
  );

  const currentLandscape =
    parseInt(selected?.aspect_ratio?.split(":")?.[0] || "0") >
    parseInt(selected?.aspect_ratio?.split(":")?.[1] || "0");

  const tags = Array.from(
    new Set(
      images
        .map((i) => i.items.map((item) => item.tags))
        .flat()
        .flat()
        .filter((i) => i)
    )
  ).map((i) => ({ value: i, label: i }));

  const [tempTags, setTempTags] = React.useState([]);

  return (
    <>
      <div
        style={{
          maxWidth: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 15,
        }}
      >
        <div
          style={{ marginBottom: 25, display: "flex", alignItems: "center" }}
        >
          <div style={{ width: 640, marginRight: 25 }}>
            <AutoComplete
              size="large"
              style={{ width: "100%" }}
              onSelect={(i) => {
                setSearch(i);
              }}
              dataSource={Array.from(
                new Set(images?.map((i) => i.prompt?.split(",")[0]))
              )}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              <Input
                allowClear
                size="large"
                onChange={(e) => {
                  if (!e.target.value) {
                    setSearch("");
                  }
                }}
                placeholder="Search Archive"
              />
            </AutoComplete>
          </div>
        </div>
        <Row gutter={[15, 15]}>
          {filteredImages?.map((i, ind) => (
            <Col key={ind} span={24}>
              <Card>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          color: "#15afd0",
                          fontSize: "14px",
                          marginBottom: 0,
                        }}
                      >
                        Prompt
                      </p>
                      <p style={{ fontSize: "18px", maxWidth: 480 }}>
                        {i.prompt}
                      </p>
                      <p
                        style={{
                          color: "#15afd0",
                          fontSize: "14px",
                          marginBottom: 0,
                        }}
                      >
                        Created at
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        {new Date(i.created_at).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}{" "}
                        {new Date(i.created_at).toLocaleTimeString("en-us")}
                      </p>
                      <div style={{ flex: 1 }}></div>
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <Popconfirm
                          title="Delete batch"
                          description="Are you sure to delete this batch?"
                          onConfirm={() => {
                            Axios.post(
                              `https://paiweb.vercel.app/api/gen-ai/batch/delete`,
                              {
                                batch_id: i.id,
                              },
                              {
                                skipInterceptors: true
                              }
                            ).then(() => {
                              message.success("Batch deleted successfully!");
                            });

                            setImages(
                              images.filter((batch) => batch.id !== i.id)
                            );
                          }}
                          onCancel={() => { }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            style={{ marginRight: 10 }}
                          >
                            Delete Batch
                          </Button>
                        </Popconfirm>
                        {!i.items.find((item) => item.tags?.length) && (
                          <Popover
                            title="Choose tags to promote this batch"
                            trigger="click"
                            content={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Select
                                  value={tempTags}
                                  mode="tags"
                                  options={tags}
                                  onChange={(v) => setTempTags(v)}
                                  style={{ width: 280, marginRight: 5 }}
                                />
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    Promise.all(
                                      i.items.map((item) => {
                                        return Axios.post(
                                          `https://paiweb.vercel.app/api/gen-ai/save`,
                                          {
                                            item: { tags: tempTags },
                                            id: item?.id,
                                          },
                                          {
                                            skipInterceptors: true
                                          }
                                        );
                                      })
                                    );
                                    setImages(
                                      images.map((batch) => ({
                                        ...batch,
                                        items:
                                          batch.id === i.id
                                            ? batch.items.map((item) => ({
                                              ...item,
                                              tags: tempTags,
                                            }))
                                            : batch.items,
                                      }))
                                    );
                                    setTempTags([]);
                                  }}
                                >
                                  Go
                                </Button>
                              </div>
                            }
                          >
                            <Button icon={<PushpinOutlined />}>
                              Promote to Gallery
                            </Button>
                          </Popover>
                        )}

                        {i.items.filter((item) => item.tags?.length).length <
                          i.items.length &&
                          i.items.filter((item) => item.tags?.length).length >
                          0 && (
                            <>
                              <Button
                                style={{
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  paddingRight: 10,
                                }}
                              >
                                {
                                  i.items.filter((item) => item.tags?.length)
                                    .length
                                }{" "}
                                in gallery
                              </Button>
                              <Popover
                                title="Choose tags to promote remaining"
                                trigger="click"
                                content={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Select
                                      value={tempTags}
                                      mode="tags"
                                      options={tags}
                                      onChange={(v) => setTempTags(v)}
                                      style={{ width: 280, marginRight: 5 }}
                                    />
                                    <Button
                                      type="primary"
                                      onClick={() => {
                                        Promise.all(
                                          i.items
                                            .filter((item) => !item.tags.length)
                                            .map((item) => {
                                              return Axios.post(
                                                `https://paiweb.vercel.app/api/gen-ai/save`,
                                                {
                                                  item: { tags: tempTags },
                                                  id: item?.id,
                                                },
                                                {
                                                  skipInterceptors: true
                                                }
                                              );
                                            })
                                        );
                                        setImages(
                                          images.map((batch) => ({
                                            ...batch,
                                            items:
                                              batch.id === i.id
                                                ? batch.items.map((item) => ({
                                                  ...item,
                                                  tags: item.tags.length
                                                    ? item.tags
                                                    : tempTags,
                                                }))
                                                : batch.items,
                                          }))
                                        );
                                        setTempTags([]);
                                      }}
                                    >
                                      Go
                                    </Button>
                                  </div>
                                }
                              >
                                <Button
                                  icon={<PushpinOutlined />}
                                  style={{
                                    borderLeft: 0,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    paddingLeft: 10,
                                  }}
                                >
                                  Promote remaining
                                </Button>
                              </Popover>
                            </>
                          )}

                        {!!i.items.find((item) => item.tags?.length) && (
                          <Button
                            style={{ marginLeft: 10 }}
                            icon={<PushpinOutlined />}
                            onClick={() => {
                              Promise.all(
                                i.items.map((item) => {
                                  return Axios.post(
                                    `https://paiweb.vercel.app/api/gen-ai/save`,
                                    {
                                      item: { tags: [] },
                                      id: item?.id,
                                    },
                                    {
                                      skipInterceptors: true
                                    }
                                  );
                                })
                              );
                              setImages(
                                images.map((batch) => ({
                                  ...batch,
                                  items:
                                    batch.id === i.id
                                      ? batch.items.map((item) => ({
                                        ...item,
                                        tags: [],
                                      }))
                                      : batch.items,
                                }))
                              );
                              setTempTags([]);
                            }}
                          >
                            Remove from Gallery
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[15, 15]}>
                      {i.items.map((item, ind) => (
                        <Col key={ind} span={6}>
                          <ItemCard onClick={setSelected} item={item} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        <Modal
          width={979}
          visible={!!selected}
          bodyStyle={{
            maxHeight: "none",
            borderRadius: 25,
            overflow: "hidden",
          }}
          onCancel={() => {
            setSelected(undefined);
          }}
          footer={null}
        >
          <Row>
            <Col span={currentLandscape ? 24 : 12}>
              <div style={{ padding: 30 }}>
                {selected && <ItemDisplay item={selected} />}
              </div>
            </Col>
            <Col span={currentLandscape ? 24 : 12}>
              <div
                style={{
                  padding: 40,
                  paddingLeft: currentLandscape ? 40 : 5,
                  paddingTop: currentLandscape ? 0 : 40,
                  marginTop: currentLandscape ? -10 : 0,
                }}
              >
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  style={{
                    fontSize: "24px",
                    border: 0,
                    fontWeight: "bold",
                    paddingLeft: 0,
                    textTransform: "capitalize",
                    outline: 0,
                    marginBottom: 15,
                  }}
                  value={selected?.title}
                  onChange={(e) => {
                    setSelected({
                      ...selected,
                      title: e.target.value,
                    });
                  }}
                />
                <h3
                  style={{
                    marginBottom: 10,
                    lineHeight: "100%",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: "#69676e",
                  }}
                >
                  Prompt
                </h3>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#FFF",
                    marginBottom: 35,
                  }}
                >
                  {selected?.prompt}
                </p>
                {!!selected?.exclude && (
                  <>
                    <h3
                      style={{
                        marginBottom: 10,
                        lineHeight: "100%",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#69676e",
                      }}
                    >
                      Removed from image
                    </h3>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#FFF",
                        marginBottom: 35,
                      }}
                    >
                      {selected?.exclude}
                    </p>
                  </>
                )}
                <h3
                  style={{
                    marginBottom: 10,
                    lineHeight: "100%",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: "#69676e",
                  }}
                >
                  Tags
                </h3>
                <Select
                  mode="tags"
                  placeholder="Add tags to categorize"
                  style={{ minWidth: 380 }}
                  options={tags}
                  value={selected?.tags}
                  onChange={(value) => {
                    setSelected({
                      ...selected,
                      tags: value,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          <div style={{ padding: 30, paddingTop: 0, marginTop: -30 }}>
            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  marginRight: 10,
                }}
                icon={<HeartOutlined />}
              >
                {" "}
                {selected?.like_count}
              </Button>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                  const elem = document.getElementById("downloadableDiv");
                  PrintDiv(elem);
                }}
                style={{ marginRight: 10 }}
              >
                Download
              </Button>
              <Button icon={<LinkOutlined />}>Copy Link</Button>
              <div style={{ flex: 1 }}></div>
              <Popconfirm
                title="Delete item"
                description="Are you sure to delete this item?"
                onConfirm={() => {
                  Axios.post(
                    `https://paiweb.vercel.app/api/gen-ai/item/delete`,
                    {
                      id: selected?.id,
                    },
                    {
                      skipInterceptors: true
                    }
                  ).then(() => {
                    message.success("Item deleted successfully!");
                  });

                  setImages(
                    images.map((batch) => ({
                      ...batch,
                      items: batch.items.filter(
                        (item) => item.id !== selected?.id
                      ),
                    }))
                  );
                  setSelected(undefined);
                }}
                onCancel={() => { }}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />}>Delete</Button>
              </Popconfirm>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  Axios.post(`https://paiweb.vercel.app/api/gen-ai/save`, {
                    item: selected,
                    id: selected?.id,
                  },
                    {
                      skipInterceptors: true
                    }).then(() => {
                      message.success("Item saved successfully!");
                    });
                  setImages(
                    images.map((batch) => ({
                      ...batch,
                      items: batch.items.map((item) => {
                        if (item.id === selected?.id) {
                          return selected;
                        }
                        return item;
                      }),
                    }))
                  );
                  setSelected(undefined);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
