import React, { } from 'react'
import './index.css'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { FetchSummaryData, Columns, getAdvancedData } from "./transformer2";
import ZsTable from "@xai/assets/generic-ant-d-components/zs-table.js";


import {
    ZsAvatar,
    ZsEditFilled
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";

const { Panel } = Collapse;

// const Color = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"]
const colors = ["#15afd0", "#97cdd7", "#c1ddea", "#60b3c6", "#FFFFFF"]

export const categories = [
    {
        name: "Designer",
        icon: "luxury",
        splitup: {
            'Group A': 27,
            'Control': 23
        }
    },
    {
        name: "Beauty Brands",
        icon: "beauty",
        splitup: {
            'Group A': 21,
            'Control': 17
        }
    },
    {
        name: "Apparel",
        icon: "apparel",
        splitup: {
            'Group A': 16,
            'Control': 14
        }
    },
    {
        name: "Shoes",
        icon: "footwear",
        splitup: {
            'Group A': 8,
            'Control': 12
        }
    },
    {
        name: "Bags",
        icon: "handbag",
        splitup: {
            'Group A': 10,
            'Control': 9
        }
    },
    {
        name: "Accessories",
        icon: "accessories",
        splitup: {
            'Group A': 5,
            'Control': 7
        }
    }
]

export const Metric = ({ title, value, align }) => (
    <div style={{ textAlign: align || "left", marginLeft: (align || "left") !== "left" ? 30 : 0, marginRight: (align || "left") !== "right" ? 30 : 0 }}>
        <h1 style={{ margin: 0, lineHeight: "100%", fontWeight: 400 }}>{value}</h1>
        <p style={{ margin: 0, color: colors[0], fontSize: '13px' }}>{title}</p>
    </div>
)
export const MetricSummary = ({ name, value, percentage, noBg, subValue }) => (

    <Panel flexible background={noBg ? "" : "rgba(255,255,255,0.03)"} paddingX round>

        <Panel flexible stack="horizontal" center paddingY>
            <Panel width="50px">

            </Panel>
            <Panel width="10px"></Panel>
            <Panel flexible>
                <h2 style={{ margin: 0, color: colors[0], fontSize: '28px', fontWeight: 400, lineHeight: "100%", textTransform: "uppercase", marginBottom: subValue ? -10 : 0 }}>
                    {value}
                    {subValue &&
                        <small style={{ color: colors[1], opacity: 0.75, /* fontSize: "50%", */ fontSize: '13px' }}> / {subValue}</small>
                    }
                </h2>
                <p style={{ margin: 0, width: "auto" }}>{name}</p>
            </Panel>
            {percentage &&
                <Panel width="10px">

                </Panel>
            }
        </Panel>
    </Panel>
)

// export const InlineInput = ({ label, placeholder }) => [
//     <Panel stack="horizontal">
//         <p style={{ margin: "0px 8px 0px", flexShrink: 0, color: colors[0] }}>{label[0]}</p>
//         <Input placeholder={placeholder} style={{ minWidth: "none", maxWidth: 45, marginTop: -5 }} />
//         <p style={{ margin: "0px 0px 0px 8px", color: colors[0], flexShrink: 0, }}>{label[1]}</p>
//     </Panel>,
//     <Panel height="7px" />
// ]

const ABTestAnalyse = (elements, formConfig) => {


    const columns = Columns();


    return (


        <div className="summary-container" style={{ marginBottom: "20px" }}>
            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
            >
                <Panel
                    header={<div>
                        Global Details{" "}
                        <ZsAvatar
                            size={20}
                            style={{
                                backgroundColor: "#15afd0",
                                cursor: "pointer",
                            }}

                            value={<ZsEditFilled />}
                        >
                            <ZsEditFilled />
                        </ZsAvatar>
                    </div>}
                    key="1" className="site-collapse-custom-panel"
                    bordered={true}
                >
                    <ZsTable
                        columns={columns}
                        dataSource={FetchSummaryData(elements, formConfig)}
                        size="small"
                        pagination={false}
                        showHeader={false}
                        bodered={true}
                    />
                </Panel>
                <Panel
                    header={<div>
                        Advanced Configuration{" "}
                        <ZsAvatar
                            size={20}
                            style={{
                                backgroundColor: "#15afd0",
                                cursor: "pointer",
                            }}

                            value={<ZsEditFilled />}
                        >
                            <ZsEditFilled />
                        </ZsAvatar>
                    </div>}
                    key="2" className="site-collapse-custom-panel"
                    bordered={true}
                >
                    <ZsTable
                        columns={columns}
                        dataSource={getAdvancedData(elements, formConfig)}
                        size="small"
                        pagination={false}
                        showHeader={false}
                        bodered={true}
                    />

                </Panel>


            </Collapse>
        </div>
    );




}

export default ABTestAnalyse