import { Card, Checkbox, Input, Row, Col } from "antd";
import React, { useEffect } from "react";
import { AspectRatio } from "@xai/pages/design-gen-ai-dynamic-images/components/aspect-ratio/AspectRatio";
import * as HTMLIcons from "../icons";

export const ContextComponentSummary = (props) => {
  return (
    <div style={{}}>
      {props.inputs.map((element, index) => (
        <div
          key={index}
          style={{
            padding: 10,
            paddingBottom: 20,
            paddingTop: 0,
            display: "flex",
            marginBottom: 10,
          }}
        >
          <div style={{ marginTop: 1, marginRight: 20 }}>
            <Checkbox
              disabled={element.required}
              checked={
                (props.data.selectedFields || []).indexOf(element.key) !== -1
              }
              onChange={
                (/* e */) => {
                  var currentFields = props.data.selectedFields || [];
                  var index = currentFields.indexOf(element.key);
                  if (index !== -1) {
                    currentFields.splice(index, 1);
                  } else {
                    currentFields.push(element.key);
                  }
                  props.updateEntry("selectedFields", currentFields);
                }
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ marginBottom: 0 }}>{element.label}</h3>
            <p style={{ marginBottom: 0, opacity: 0.75 }}>{element.help}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ContextComponent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [zoom, setZoom] = React.useState(
    localStorage.getItem("pai_collapsed") == "true" ? 0.95 : 0.75
  );
  const zoomHandler = () => {
    console.log("zoomHandler ", localStorage.getItem("pai_collapsed"));
  };

  useEffect(() => {
    window.addEventListener("customResize", zoomHandler, false);
    return () => {
      window.removeEventListener("customResize", zoomHandler);
    };
  }, []);
  return (
    <>
      {props.inputs
        .filter((input) => {
          return (props.data.selectedFields || []).indexOf(input.key) !== -1;
        })
        .map((element, index) => (
          <div style={{ marginBottom: 25 }} key={index}>
            <Card className="">
              <div>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    margin: "0px",
                  }}
                >
                  {element.label}
                </p>
                <p
                  style={{
                    fontSize: "0.8rem",
                    margin: "0px",
                    opacity: 0.75,
                    fontWeight: "300",
                  }}
                >
                  {element.help}
                </p>
              </div>
              {/* <>
            {element.type === "tile_select" && (
              <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
                {element.tiles?.map((it) => (
                  <Col span={24 / element.tiles.length}>
                    <AspectRatio width={1} height={1}>
                      <Card
                        className="card-section"
                        style={{
                          background:
                            formData?.basicInfo?.[element.key] === it.value
                              ? `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))`
                              : "",
                          textAlign: "center",
                          cursor: "pointer",
                          border: "1px solid rgba(67, 67, 67)",
                          height: "100%",
                        }}
                        onClick={() => {
                          changeHandler(it.value, element.key);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!it.component && (
                            <img
                              src={`${process.env.REACT_APP_S3_BUCKET}/${subRoute}/viz_configs/img/integrations/${it?.img}`}

                              alt=""
                            />
                          )}
                          {!!it.component && <>{HTMLIcons[it.component]}</>}
                          <p
                            style={{
                              marginTop: 10,
                              marginBottom: -10,
                            }}
                          >
                            {it.title}
                          </p>
                        </div>
                      </Card>
                    </AspectRatio>
                  </Col>
                ))}
              </Row>
            )}
          </> */}
              {element.type === "tile-select" && (
                <div style={{ marginTop: 20 }}>
                  <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
                    {element.props.tiles?.map((it, index) => (
                      <Col span={24 / element.tiles.length} key={index}>
                        <AspectRatio width={1} height={1}>
                          <Card
                            className="card-section"
                            style={{
                              background:
                                props.data?.[element.key] === it.value
                                  ? `linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148))`
                                  : "",
                              textAlign: "center",
                              cursor: "pointer",
                              border: "1px solid rgba(67, 67, 67)",
                              height: "100%",
                            }}
                            onClick={() => {
                              props.updateEntry(element.key, it.value);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!it.component && (
                                <img
                                  src={`${process.env.REACT_APP_S3_BUCKET}/viz_configs/img/integrations/${it?.img}`} //#TODO Change the image path
                                  alt=""
                                />
                              )}
                              {!!it.component && (
                                <div
                                  className="html-icon-wrapper"
                                  style={{
                                    zoom,
                                  }}
                                >
                                  {HTMLIcons[it.component]}
                                </div>
                              )}
                              <p
                                style={{
                                  marginTop: 10,
                                  marginBottom: -10,
                                }}
                              >
                                {it.title}
                              </p>
                            </div>
                          </Card>
                        </AspectRatio>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {element.type === "textarea" && (
                <div style={{ marginTop: 20 }}>
                  <Input.TextArea
                    {...element.props}
                    defaultValue={props.data[element.key]}
                    onChange={(e) => {
                      props.updateEntry(element.key, e.target.value);
                      //   setFormData({
                      //     ...formData,
                      //     basicInfo: {
                      //       ...formData.basicInfo,
                      //       [element.key]: e.target.value,
                      //     },
                      //   });
                    }}
                  />
                </div>
              )}
            </Card>
          </div>
        ))}
    </>
  );
};
