import React from "react";
import { WaveLoading } from 'styled-spinkit';
import { Delay } from 'react-delay-fallback';
import { ThumbLike } from '@styled-icons/fluentui-system-filled/ThumbLike';
import { Like } from '@styled-icons/boxicons-regular/Like';
import { Dislike as DislikeFill } from '@styled-icons/boxicons-solid/Dislike';
import { Dislike } from '@styled-icons/boxicons-regular/Dislike';
import { Row, Col } from 'antd';
import { Star } from '@styled-icons/bootstrap/Star';
import { StarFill } from '@styled-icons/bootstrap/StarFill';

const TabContent = ({ data, setfirstStepTagLinesList, showList, noList }) => {


    const firstStepListHandler = (variant, id) => {
        let locObj = [...data];
        let objToBeUpdated = locObj.find((x) => x.id === id);
        switch (variant) {
            case "fav":
                // locObj[id].fav = !locObj[id].fav
                objToBeUpdated.fav = !objToBeUpdated.fav;
                break;
            case "liked":
                if (objToBeUpdated.disliked) {
                    objToBeUpdated.disliked = !objToBeUpdated.disliked;
                    objToBeUpdated.liked = !objToBeUpdated.liked;
                } else {
                    objToBeUpdated.liked = !objToBeUpdated.liked;
                }
                break;
            case "disliked":
                if (objToBeUpdated.liked) {
                    objToBeUpdated.disliked = !objToBeUpdated.disliked;
                    objToBeUpdated.liked = !objToBeUpdated.liked;
                } else {
                    objToBeUpdated.disliked = !objToBeUpdated.disliked;
                }
                break;

            default:
                break;
        }

        setfirstStepTagLinesList(locObj);
    }

    return (
        <Row span={24}>
            <Col span={24} offset={0}>
                {
                    !showList ?
                        noList && data.length === 0 ?
                            <p>Please modify parameters and try again</p> :
                            data.map((i, index) => (
                                <Delay
                                    key={index}
                                    timeout={250 * (index + 1)}
                                    fallback={<div></div>} // <-- this will be rendered while page is loading
                                >
                                    <Row style={{ margin: "10px 0px", background: "rgba(255,255,255,0.02)", padding: "10px" }} key={`indx-${index}`}>
                                        <Col span={20}><div style={{ overflowWrap: "anywhere" }}>{i.name}</div></Col>
                                        <Col span={4} style={{ display: "flex", justifyContent: "space-around", margin: "auto" }}>
                                            {
                                                i.fav ?
                                                    <StarFill width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('fav', i.id)} /> :
                                                    <Star width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('fav', i.id)} />
                                            }
                                            {
                                                i.liked ?
                                                    <ThumbLike width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('liked', i.id)} /> :
                                                    <Like width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('liked', i.id)} />
                                            }
                                            {
                                                i.disliked ?
                                                    <DislikeFill width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('disliked', i.id)} /> :
                                                    <Dislike width={20} height={20} style={{ cursor: "pointer" }} color='#15afd0' onClick={() => firstStepListHandler('disliked', i.id)} />
                                            }
                                        </Col>
                                    </Row>
                                </Delay>
                            ))
                        :
                        <WaveLoading color='#15afd0' />
                }
            </Col>
        </Row>
    )
}

export default TabContent;