import styled from 'styled-components'

const paddingMap = (base) => ({
    "small": `${base/8}px ${base/4}px`,
    "large": `${base/3}px ${base/1.5}px`,
    "default": `${base/3}px ${base/2}px`
})

const fontSizeMap = (base) => ({
    "default": base,
    "small": base - 2,
    "large": base + 2
})

const Input = styled.input`
    padding: ${props => paddingMap(props.theme.gutter.inner)[props.size || "default"]};
    font-size: ${props => fontSizeMap(props.theme.fontSizes.default)[props.size || "default"]}px;
    line-height: ${props => fontSizeMap(props.theme.fontSizes.default)[props.size || "default"]}px;
    border: ${props => props.border ? `1px solid ${props.theme.colors[props.borderStyle || "default"]}` : "0px"};
    color: ${props => props.theme.colors[props.textStyle || "default"]};
    border-radius: ${props => props.theme.borderRadius || "0"}px;
    background-color: rgba(255,255,255,0.02);
    outline: 0px !important;
    display: block;
`

export default Input