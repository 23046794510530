import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Card } from "@pai-ui/core";
import { Button, Checkbox, TextArea } from '@pai-ui/core';
import { Input } from '@pai-ui/core/components/input';
import { Edit } from '@styled-icons/fluentui-system-filled/Edit'
import { Save } from '@styled-icons/feather/Save'
import { Cancel } from '@styled-icons/material-outlined/Cancel'
import { Delete } from '@styled-icons/fluentui-system-filled/Delete'
import './style.css';

const ThirdStepRevamp = ({ ExpData, formData, firstStepTagLinesList, setfirstStepTagLinesList, prevControl, setPrevControl, sum }) => {

    const [previewData, setPreviewData] = useState([]);
    const [addNewTagItem, setAddNewTagItem] = React.useState("")
    const [prevTagItem, setPrevTagItem] = React.useState("")

    const dataMapEnum = {
        keywords: "metaData",
        industry: "metaData",
        tone: "metaData",
        minCount: "count",
        maxCount: "count",
        response_metric: "basicInfo",
        message: "basicInfo",
        audience: "basicInfo",
        variant_allocation: "basicInfo",
        experimentName: "basicInfo",
        time_range: "basicInfo"
    }

    useEffect(() => {
        if (ExpData) {
            const tempPreviewData = ExpData?.map(section =>
                section?.elements?.map(element => {
                    if (element?.isActive) {
                        let filteredEnumValue = formData[dataMapEnum[element?.backendName]];
                        return {
                            label: element?.primary_label.replace("Enter", ""),
                            value: filteredEnumValue[element?.backendName] ? filteredEnumValue[element?.backendName] : "",
                            order: element.order
                        }
                    }

                }))
            setPreviewData([...tempPreviewData[0], ...tempPreviewData[1], ...tempPreviewData[2]].filter(item => item !== undefined));
        }
    }, [ExpData]);

    useEffect(() => {
        let locAry = [...firstStepTagLinesList]
        if (prevControl) {
            // locAry = locAry.map(item => {
            //     let locObj = { ...item }
            //     if(locObj.control === true){
            //         locObj.control = false
            //     }
            //     return locObj
            // })
            locAry[prevControl].control = true
        } else {
            locAry.filter(it => it.fav === true)[0].control = true
        }
        locAry = locAry.map((item) => {
            if (item.fav) {
                return {
                    ...item,
                    weight: 100 / locAry.filter(i => i.fav === true).length
                };
            } else {
                return item;
            }
        })
        console.log('locAry', locAry)
        setfirstStepTagLinesList(locAry)
    }, [])

    const addNewTagLineList = () => {
        let locAry = [...firstStepTagLinesList, {
            id: firstStepTagLinesList[firstStepTagLinesList.length - 1].id + 1,
            name: "",
            fav: true,
            liked: false,
            disliked: false,
            new: true,
            edit: true,
            control: false,
            weight: 0
        }]
        setfirstStepTagLinesList(locAry)
        setAddNewTagItem("")
    }

    const updateExistingTagLineData = (id) => {
        let locAry = [...firstStepTagLinesList]
        locAry[id].name = addNewTagItem
        locAry[id].edit = false;
        locAry = locAry.map((item) => {
            if (item.fav) {
                return {
                    ...item,
                    weight: 100 / locAry.filter(i => i.fav === true).length
                };
            } else {
                return item;
            }
        })
        setfirstStepTagLinesList(locAry)
        setAddNewTagItem("")
    }

    const editExistingTagLine = (id, inputExistingValue) => {
        let locAry = [...firstStepTagLinesList]
        setAddNewTagItem(inputExistingValue)
        setPrevTagItem(inputExistingValue)
        locAry[id].edit = true
        setfirstStepTagLinesList(locAry)
    }

    const deleteNewTagLinesdata = (id, newItem) => {
        let locAry = [...firstStepTagLinesList]
        if (newItem) {
            console.log('locAry.filter(it => it.id === id)', locAry.filter(it => it.id === id))
            locAry[id].edit = false
            locAry = locAry.filter(it => it.id !== id)
        } else {
            locAry[id].fav = false
            locAry[id].edit = false
        }
        locAry = locAry.map((item) => {
            if (item.fav) {
                return {
                    ...item,
                    weight: 100 / locAry.filter(i => i.fav === true).length
                };
            } else {
                return item;
            }
        })
        setfirstStepTagLinesList(locAry)
        setAddNewTagItem("")
    }

    const cancelExistingTagLines = (id) => {
        if (prevTagItem.length === 0) {
            setAddNewTagItem("")
            setPrevTagItem("")
        } else {
            let locAry = [...firstStepTagLinesList]
            locAry[id].edit = false
            setfirstStepTagLinesList(locAry)
            setPrevTagItem("")
        }
    }

    const updateControlTagLine = (id) => {
        let locAry = [...firstStepTagLinesList]
        locAry = locAry.map(item => {
            let locObj = { ...item }
            if (locObj.control === true) {
                locObj.control = false
            }
            return locObj
        })
        locAry[id].control = true
        setPrevControl(id)
        setfirstStepTagLinesList(locAry)
    }

    const editweightTagLine = (id, inputExistingValue) => {
        if (inputExistingValue) {
            let locAry = [...firstStepTagLinesList]
            locAry[id].weight = inputExistingValue
            setfirstStepTagLinesList(locAry)
        }
    }

    // console.log('previewData', previewData);
    return (
        <Row>
            <Col span={12}>
                <div style={{ marginRight: "20px" }}>
                    <Card className="custom-ant-card-no-padding">
                        <div style={{ marginBottom: "20px", padding: "20px", marginLeft: "20px", height: "74.75vh" }}>
                            <h2>Summary</h2>
                            {
                                previewData.sort((a, b) => a.order - b.order).map((elementRow) => (
                                    <>
                                        <div style={{ padding: "10px" }}>
                                            <Row span={24}>
                                                <Col span={6} style={{ opacity: 0.7 }}>
                                                    {elementRow?.label}:
                                                </Col>
                                                <Col span={17}>
                                                    {
                                                        elementRow?.label === "Test Duration" ?
                                                            `${elementRow?.value[0]} to ${elementRow?.value[1]}`
                                                            : elementRow?.value
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* {(elementRow.length - 1 !== index) && <Divider style={{ margin: "0px" }} orientation="left" />} */}
                                    </>
                                ))
                            }
                        </div>
                    </Card>
                </div>
            </Col>
            <Col span={12}>
                <Card className="custom-ant-card-no-padding">
                    <div style={{ marginBottom: "20px", padding: "20px", marginLeft: "20px", height: "74.75vh" }} >
                        <Row>
                            <h2>Finalize variants to test</h2>
                        </Row>
                        {firstStepTagLinesList?.length > 0 &&
                            <>
                                <Row>
                                    <Col span={8}>Control</Col>
                                    <Col span={2} offset={11}>Edit</Col>
                                    <Col span={2}>Ratio</Col>
                                </Row>
                                <Row span={24} style={{ height: "80%", overflowY: "auto" }}>
                                    <Col span={24} offset={0} >
                                        <div style={{ height: "95%" }}>
                                            {
                                                firstStepTagLinesList.filter(it => it.fav === true).map((i, index) => (
                                                    <Row className="row-section-finalize" style={{ margin: "10px 0px", border: "1px solid rgba(67, 67, 67)", borderRadius: "5px", padding: "10px" }} key={`indx-${index}`}>
                                                        <Col span={1} style={{ margin: "auto" }}>
                                                            <Checkbox
                                                                checked={i.control ? true : false}
                                                                onChange={(e) => { (e.target.checked && updateControlTagLine(i.id)) }} />
                                                        </Col>
                                                        {
                                                            i.edit ?
                                                                <Col span={18} offset={0} style={{ display: "flex", justifyContent: "space-around", margin: "auto" }}>
                                                                    <TextArea
                                                                        style={{ width: "87%" }}
                                                                        defaultValue={addNewTagItem}
                                                                        value={addNewTagItem}
                                                                        onChange={(e) => setAddNewTagItem(e.target.value)}
                                                                    />
                                                                    <Cancel
                                                                        width={25}
                                                                        height={25}
                                                                        style={{ cursor: addNewTagItem.length !== 0 && "pointer", margin: "auto 0px" }}
                                                                        color={addNewTagItem.length !== 0 && '#15afd0'}
                                                                        onClick={() => addNewTagItem.length === 0 ? '' : cancelExistingTagLines(i.id) /* setAddNewTagItem("") */}
                                                                    />
                                                                    <Delete
                                                                        width={25}
                                                                        height={25}
                                                                        style={{ cursor: "pointer", margin: "auto 0px" }}
                                                                        color={'#15afd0'}
                                                                        onClick={() => deleteNewTagLinesdata(i.id, i.new)}
                                                                    />
                                                                </Col>
                                                                :
                                                                <Col span={18} offset={0} style={{ overflowWrap: "anywhere", margin: "auto 0px", padding: "5px", borderRadius: "5px", }}>{i.name}</Col>
                                                        }

                                                        <Col span={1} offset={1} style={{ margin: "auto" }}>
                                                            {i.edit ?
                                                                <Save
                                                                    width={25}
                                                                    height={25}
                                                                    style={{ cursor: addNewTagItem.length !== 0 && "pointer", }}
                                                                    color={addNewTagItem.length !== 0 && '#15afd0'}
                                                                    onClick={() => addNewTagItem.length === 0 ? '' : updateExistingTagLineData(i.id)}
                                                                />
                                                                :
                                                                <Edit
                                                                    width={20}
                                                                    height={20}
                                                                    color={firstStepTagLinesList.some(it => it.edit === true) ? "" : '#15afd0'}
                                                                    style={{ cursor: firstStepTagLinesList.some(it => it.edit === true) ? "" : "pointer", }}
                                                                    onClick={() => firstStepTagLinesList.some(it => it.edit === true) ? "" : editExistingTagLine(i.id, i.name)}
                                                                />}
                                                        </Col>

                                                        <Col span={3}>
                                                            <Input disabled={i.edit} onChange={e => editweightTagLine(i.id, e)} value={i.weight} /* value={Math.round(i.weight * 10) / 10} */ style={{ width: "80%" }} suffix="%" /> %
                                                        </Col>
                                                    </Row>
                                                ))
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "10px", justifyContent: "space-between" }}>
                                    <Col>
                                        {sum !== 100 && /* firstStepTagLinesList.filter(it => it.fav === true).some(it => it.edit === true) && */
                                            (<div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    color: "#c00000"
                                                }}>
                                                {"Total adds up to " + + String(sum) + " %"}
                                            </div>)}
                                    </Col>
                                    <Col align="right">
                                        <Button
                                            ghost
                                            onClick={() => addNewTagLineList()}
                                            disabled={firstStepTagLinesList.filter(it => it.fav === true).some(it => it.name.length === 0) || firstStepTagLinesList.filter(it => it.fav === true).some(it => it.edit === true)}
                                        >
                                            + Manual Add
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default ThirdStepRevamp