import { notification } from '@pai-ui/core/components/notification';
import { message } from '@pai-ui/core/components/message';

export const showToastMessage = (msgType, msg) => {
  if (msgType === "success") {
    message.success(msg);
  } else if (msgType === "error") {
    message.error(msg);
  } else if (msgType === "warning") {
    message.warning(msg);
  }
};

export const showNotificationMessage = (placement, type, msg, description) => {
  notification[type]({
    message: msg,
    placement,
    description,
  });
};
