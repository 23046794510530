import React from "react";

import screenshot from "image-screenshot";
import GenAIServiceHelper from "@xai/pages/design-gen-ai-dynamic-images/service-helper";
import { message } from "antd";

const GenerateContext = React.createContext({});

const getQueryStringParameter = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) || "";
};

const filters = [
  "Normal",
  "1977",
  "Aden",
  "Amaro",
  "Ashby",
  "Brannan",
  "Brooklyn",
  "Charmes",
  "Clarendon",
  "Crema",
  "Dogpatch",
  "Earlybird",
  "Gingham",
  "Ginza",
  "Hefe",
  "Helena",
  "Hudson",
  "Inkwell",
  "Kelvin",
  "Kuno",
  "Lark",
  "Lo-Fi",
  "Ludwig",
  "Maven",
  "Mayfair",
  "Moon",
  "Nashville",
  "Perpetua",
  "Poprocket",
  "Reyes",
  "Rise",
  "Sierra",
  "Skyline",
  "Slumber",
  "Stinson",
  "Sutro",
  "Toaster",
  "Valencia",
  "Vesper",
  "Walden",
  "Willow",
  "X-Pro",
];

const timer = (seconds) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 3000);
  });

export const GenerateContextProvider = (props) => {
  const [images, setImages] = React.useState([]);

  const [prompt, setPrompt] = React.useState(getQueryStringParameter("prompt"));

  const [preFilters, setPreFilters] = React.useState([]);

  const [advanced, setAdvanced] = React.useState(false);

  const [seedImage, setSeedImage] = React.useState(
    getQueryStringParameter("seed_image")
  );

  const [defaultPreFilter, setDefaultPreFilter] = React.useState({
    name: "None",
    promptPrefix: "",
  });

  const token = "need-some-token-to-work-with-adding-dummy-text-for-now";
  const service = new GenAIServiceHelper(token);

  const loadPreFilters = async () => {
    await service
      .getGenAIPreFilters()
      .then(async (res) => {
        console.log("Data from S3 gen-ai-pre-filters.json", res);
        setPreFilters(res);
      })
      .catch((err) => {
        console.log("Error from S3 gen-ai-pre-filters.json", err);
      });
  };

  React.useEffect(() => {
    loadPreFilters();
  }, []);

  const updatePrompt = (value, index) => {
    setPrompt((p) =>
      p
        .split("|")
        .map((item, i) => {
          if (i === index) {
            return value;
          }
          return item;
        })
        .join("|")
    );
  };

  const validateFunction = () => {
    if (advanced) {
      return (
        !!prompt.split("|").filter((f) => f)?.length && !!count && !loading
      );
    }

    return !!prompt.length && !!count && !loading;
  };

  const pollTillReady = async (hash) => {
    const data = await service.getStatus(hash);

    if (data.data?.length) {
      return data.data;
    }

    await timer(5);

    return pollTillReady(hash);
  };

  const generateResults = async (callback) => {
    setLoading(true);
    const data = await service.generate({
      prompt: prompt.split("|").join(" ").trim(),
      image_count: count,
      aspect_ratio: aspectRatio,
      exclude: exclude,
      seed_image: seedImage,
      version: engine,
    });

    if (data.error) {
      message.error(
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            textAlign: "left",
          }}
        >
          <p style={{ marginBottom: 0, fontWeight: "bold" }}>
            Error processing request
          </p>
          <p style={{ marginBottom: 0 }}>{data.error}</p>
        </div>
      );
      setLoading(false);
      return;
    }

    const response2 = await pollTillReady(data.batch_id);
    setImages([response2, ...images]);
    callback && callback([response2, ...images].flat());
    setLoading(false);
  };

  const generate = async (callback) => {
    if (!validateFunction()) {
      return;
    }

    generateResults(callback);
  };

  const [edit, setEdit] = React.useState();
  const [currentFilter, setCurrentFilter] = React.useState("None");
  const [aspectRatio, setAspectRatio] = React.useState("10:16");

  const [engine, setEngine] = React.useState("--v 5.2");

  const [count] = React.useState(4);

  const [exclude, setExclude] = React.useState(
    getQueryStringParameter("exclude") +
      " text, logo, wordmark, writing, heading, signature"
  );

  const [loading, setLoading] = React.useState(false);

  const startEdit = (item) => {
    setEdit(item);
  };

  const closeEdit = () => {
    setEdit(undefined);
  };

  const reset = () => {
    setPrompt("");
    setDefaultPreFilter(preFilters?.[0]);
    setSeedImage("");
  };

  const downloadImage = () => {
    const elem = Array.from(
      document.querySelectorAll("#downloadableDiv")
    ).pop();
    screenshot(elem).download();
  };

  const updatePrefilter = (filter) => {
    setDefaultPreFilter(filter);
    setPrompt(
      `${filter.promptPrefix}, ${prompt
        .replace(defaultPreFilter.promptPrefix, "")
        .replace(defaultPreFilter.promptPostfix, "")
        .split(",")
        .filter((r) => r.trim())
        .join(", ")}, ${filter.promptPostfix}`
        .split(",")
        .filter((r) => r.trim())
        .join(",")
        .split(" ")
        .filter((r) => r.trim())
        .join(" ")
    );
  };

  const [selected, setSelected] = React.useState([]);

  return (
    <GenerateContext.Provider
      value={{
        images,
        prompt: {
          value: prompt,
          set: setPrompt,
          update: updatePrompt,
        },
        advanced: {
          value: advanced,
          set: setAdvanced,
        },
        exclude: {
          value: exclude,
          set: setExclude,
        },
        seedImage: {
          value: seedImage,
          set: setSeedImage,
        },
        currentFilter: {
          value: currentFilter,
          set: setCurrentFilter,
        },
        aspectRatio: {
          value: aspectRatio,
          set: setAspectRatio,
        },
        defaultPreFilter: {
          value: defaultPreFilter,
          set: updatePrefilter,
        },
        editing: {
          value: edit,
          startEdit,
          closeEdit,
          downloadImage,
        },
        engine: {
          value: engine,
          set: setEngine,
        },
        generate,
        preFilters,
        filters,
        loading,
        config: {
          radius: 10,
        },
        reset,
        count,
        canGenerate: () => validateFunction(),
        toggleItemSelect: (item) => {
          setSelected((s) => {
            if (s.findIndex((i) => i.id === item.id) === -1) {
              return [...s, item];
            }

            return s.filter((i) => i.id !== item.id);
          });
        },
        selected,
      }}
    >
      {props.children}
    </GenerateContext.Provider>
  );
};

export const useGenerateContext = () => {
  return React.useContext(GenerateContext);
};
