import * as React from 'react';

import { MenuConfig2 } from '@xai/library/layouts/auth-layout/config';

export const LayoutContext = React.createContext();

export const LayoutProvider = (props) => {
  const { children } = props;
  const [menu, setMenu] = React.useState({ groups: [] });

  return (
    <LayoutContext.Provider value={{ menu, setMenu }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return React.useContext(LayoutContext);
};

export const useLayoutContextToUpdateMenu = () => {
  const { setMenu } = useLayoutContext();

  const updateMenu = React.useCallback(async (authToken) => {
    try {
      const menu = await MenuConfig2(authToken);

      setMenu(menu);

      return menu;
    } catch (err) {
      console.error('failed to load menu', err);
      throw err;
    }
  }, [setMenu]);

  return {
    updateMenu
  };
};